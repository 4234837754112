import React, { PureComponent, createRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Form, Input, Select, Button, Message } from 'semantic-ui-react'
import { Mutation, withApollo } from 'react-apollo'
import moment from 'moment'

import { UserType } from '../../../enum'
import errorTypes from '../../../errorTypes'
import { matchErrorCode } from '../../../libs/errorHelper'
import { UPDATE_USER_PROFILE, UPDATE_USER_INFO } from '../../../graphql/mutations/user'
import CircularImage from '../../common/basic/CircularImage'
import { COLOR_BACKGROUND_GRAY } from '../../../constants/styles'
import T1 from '../../common/basic/T1'
import { MiniBar } from '../../common/basic/MiniBar'
import TextInputWithError from '../../common/basic/TextInputWithError'
import withMessage from '../../../higherComponents/withMessage'
import DescriptionText from '../../common/basic/DescriptionText'

/**
 * 사용자 프로필 수정
 */
class UserProfileEditView extends PureComponent {
	static propTypes = {
		name: PropTypes.string,
		phone: PropTypes.string,
		nickname: PropTypes.string,
		email: PropTypes.string,
		profileImage: PropTypes.string,
		position: PropTypes.string,
		institution: PropTypes.shape({
			address: PropTypes.string,
			name: PropTypes.string.isRequired,
		}),
		userType: PropTypes.string,
		username: PropTypes.string,
		onUpdate: PropTypes.func.isRequired,
		client: PropTypes.shape({
			mutate: PropTypes.func.isRequired,
		}).isRequired,
		isInstitution: PropTypes.bool.isRequired,
	}

	static defaultProps = {
		name: '',
		email: '',
		phone: '',
		nickname: '',
		profileImage: '',
		position: '',
		institution: null,
		userType: null,
		username: null,
	}

	constructor(props) {
		super(props)

		this.state = {
			phone: props.phone,
			nickname: props.nickname,
			email: props.email,
			profileImage: props.profileImage,
			errors: {
				nickname: null,
				email: null,
				phone: null,
			},
			errorMessage: '',
		}
	}

	handleChange = (e, { name, value }) => {
		this.setState({
			[name]: value,
		})
	}

	handleUploadFile = e => {
		const files = e.target.files
		const data = new FormData()
		data.append('file', files[0])
	}

	handleSubmit = updateUserProfile => async () => {
		const { phone, nickname, email, profileImage, errors } = this.state
		const { onUpdate, onShowToastMessage } = this.props

		try {
			await updateUserProfile({
				variables: {
					phone,
					nickname,
					email,
					profileImage,
				},
			})

			onShowToastMessage({
				content: '프로필 정보가 변경되었습니다.',
				autoDismiss: true,
				success: true,
			})
			onUpdate()
			this.setState({
				errors: {
					nickname: null,
					email: null,
					phone: null,
				},
				errorMessage: '',
			})
		} catch (e) {
			const errMatch = matchErrorCode(e, errorTypes.INVALID_VALUE_ERROR)

			if (errMatch) {
				const invalidItems = errMatch.invalidArgs
				if (invalidItems) {
					const errs = Object.assign({}, errors)
					invalidItems.forEach(err => {
						errs[err.field] = err
					})

					this.setState({
						errors: errs,
					})
				}
			} else {
				alert('오류가 발생했습니다.')
			}
		}
	}

	render() {
		const { isInstitution, client, name, position, institution, userType, username } = this.props

		const { phone, email, nickname, profileImage, errors, errorMessage } = this.state
		const formError = Object.values(errors).filter(item => item === true)

		return (
			<Mutation
				mutation={UPDATE_USER_PROFILE}
				onCompleted={({ updateUserProfile }) => {
					this.setState({
						...updateUserProfile,
					})

					client.mutate({
						mutation: UPDATE_USER_INFO,
						variables: {
							authPayload: {
								user: {
									...updateUserProfile,
								},
							},
						},
					})
				}}
			>
				{upload => {
					return (
						<div>
							{errorMessage && (
								<Message error header="잘못된 값이 있습니다." content={errorMessage} />
							)}
							<Form error={formError}>
								{isInstitution !== true ? (
									<React.Fragment>
										<ProfileInfo upload={upload} profileImage={profileImage} {...this.props} />
										<FormContainer>
											<FormWrapper>
												<Form.Field>
													<label>ID</label>
													<input readOnly value={username} style={{ background: '#eee' }} />
												</Form.Field>
												<Form.Field>
													<label>이름</label>
													<input readOnly value={name} style={{ background: '#eee' }} />
												</Form.Field>
												<Form.Field>
													<label>회원 종류</label>
													<Select
														options={Object.values(UserType)}
														value={userType}
														style={{ background: '#eee' }}
														readOnly
													/>
												</Form.Field>
												<Form.Field error={errors.nickname != null}>
													<label>닉네임</label>
													<TextInputWithError
														name="nickname"
														onChange={this.handleChange}
														value={nickname}
														error={errors.nickname}
													/>
												</Form.Field>
												{/* </Form.Field>
													control={Input}
													value={nickname}
													name="nickname"
													onChange={this.handleChange}
													label="닉네임"
													error={errors.nickname}
												/> */}
												<Form.Field error={errors.email != null}>
													<label>이메일</label>
													<TextInputWithError
														name="email"
														onChange={this.handleChange}
														value={email}
														error={errors.email}
													/>
												</Form.Field>
												<Form.Field error={errors.phone != null}>
													<label>휴대전화</label>
													<TextInputWithError
														name="phone"
														onChange={this.handleChange}
														value={phone}
														error={errors.phone}
													/>
												</Form.Field>
											</FormWrapper>
										</FormContainer>
									</React.Fragment>
								) : (
									<React.Fragment>
										<FormContainer>
											<Desc>
												<DescriptionText>
													소속기관 정보를 변경하시려면 페이지 하단의 문의하기 버튼을 눌러 직접
													문의해 주시기 바랍니다.
												</DescriptionText>
											</Desc>
											<FormWrapper>
												<Form.Field>
													<label>소속 기관 명</label>
													<input readOnly value={institution.name} style={{ background: '#eee' }} />
												</Form.Field>
												<Form.Field>
													<label>직위</label>
													<input readOnly value={position} style={{ background: '#eee' }} />
												</Form.Field>
												<Form.Field>
													<label>기관 주소</label>
													<input
														readOnly
														value={institution.address}
														style={{ background: '#eee' }}
													/>
												</Form.Field>
											</FormWrapper>
										</FormContainer>
									</React.Fragment>
								)}
								<SubmitButton
									primary
									size="big"
									disabled={isInstitution === true}
									onClick={this.handleSubmit(upload)}
								>
									변경 사항 저장
								</SubmitButton>
							</Form>
						</div>
					)
				}}
			</Mutation>
		)
	}
}

export default withMessage(withApollo(UserProfileEditView))

const fileInput = createRef()

/**
 * 사용자 기본 정보
 */
const ProfileInfo = ({ upload, name, createdAt, updatedAt, profileImage }) => {
	return (
		<ProfileContainer>
			<ProfileWrap>
				<ProfileContent>
					<input
						hidden
						ref={fileInput}
						id="usr_prf_file"
						type="file"
						onChange={({
							target: {
								validity,
								files: [file],
							},
						}) =>
							validity.valid &&
							upload({
								variables: { profileImage: file },
							})
						}
						onClick={event => {
							event.target.value = null
						}}
					/>
					<CircularImage
						onClick={() => {
							if (fileInput.current != null) fileInput.current.click()
						}}
						style={styles.profileImage}
						width={72}
						htmlFor="usr_prf_file"
						url={profileImage}
					/>
				</ProfileContent>
				<ProfileContent>
					<T1>{name}</T1>
					<RegisterInfo>
						회원가입 {moment(createdAt).format('YYYY.MM.DD')} <MiniBar />
						최근 업데이트 {moment(updatedAt).format('YYYY.MM.DD')}
					</RegisterInfo>
				</ProfileContent>
			</ProfileWrap>
		</ProfileContainer>
	)
}

ProfileInfo.propTypes = {
	upload: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	createdAt: PropTypes.string.isRequired,
	updatedAt: PropTypes.string.isRequired,
	profileImage: PropTypes.string.isRequired,
}

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 700;
	padding: 40px;
	padding-bottom: 20px;
`

const FormWrapper = styled.div`
	display: table;
	flex: 1;
	table-layout: fixed;
`

const ProfileContainer = styled.div`
	margin-bottom: -20px;
	padding: 32px;
	background: ${COLOR_BACKGROUND_GRAY};
`

const ProfileContent = styled.div`
	display: table-cell;
	vertical-align: middle;
`
const ProfileWrap = styled.div`
	display: table;
`

const RegisterInfo = styled.div`
	font-size: 12px;
	line-height: 1.33;
`

const SubmitButton = styled(Button)`
	margin-left: 40px !important;
	margin-bottom: 20px !important;
`

const Desc = styled.div`
	margin-bottom: 20px;
`

const styles = {
	imageWrap: {
		display: 'table-cell',
		verticalAlign: 'top',
		position: 'relative',
	},
	cameraButton: {
		position: 'absolute',
		right: 10,
		top: 150,
	},
	profileImage: {
		cursor: 'pointer',
		marginRight: 20,
	},
	form: {
		display: 'flex',
	},
}
