import gql from 'graphql-tag'
import { STAT_RESULT_FRAGMENT } from '../fragments/statistics'

export const GET_MY_STATISTICS = gql`
	query getMyStatistics($requests: [StatRequest]) {
		myStatistics(requests: $requests) {
			...StatResultParts
		}
	}
	${STAT_RESULT_FRAGMENT}
`
