import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Route, Switch } from 'react-router-dom'

import LoginForm from './LoginForm'
import PageContainer from '../../common/container/PageContainer'
import SignupForm from './SignupForm'

import LoginImage1 from '../../../assets/images/drawings/login-1.svg'
import LoginImage2 from '../../../assets/images/drawings/login-2.svg'
import { ROUTE_SIGNUP } from '../../../constants/routes'
import { ExceptMobile } from '../../common/basic/ResponsiveWrappers'

/**
 * 로그인 통합 컴퍼넌트
 *
 * 로그인 / 회원가입 라우팅 및 페이지 공통 틀
 */
const LoginView = props => {
	const {
		onLoginRequest,
		onSignupRequest,
		match: { url: baseUrl },
	} = props

	return (
		<PageContainer fixed align="center" hasMobile>
			<Container>
				<ExceptMobile inline>
					<IllustBox>
						<IllustImage src={LoginImage1} alt="Start lepius together!" />
					</IllustBox>
				</ExceptMobile>
				<Content>
					<Switch>
						<Route
							exact
							path={baseUrl}
							render={routeProps => <LoginForm onLoginRequest={onLoginRequest} {...routeProps} />}
						/>
						<Route
							path={ROUTE_SIGNUP}
							render={routeProps => (
								<SignupForm onSignupRequest={onSignupRequest} {...props} {...routeProps} />
							)}
						/>
					</Switch>
				</Content>
				<ExceptMobile inline>
					<IllustBox>
						<IllustImage src={LoginImage2} alt="Start lepius together!" />
					</IllustBox>
				</ExceptMobile>
			</Container>
		</PageContainer>
	)
}

LoginView.propTypes = {
	match: PropTypes.shape({
		url: PropTypes.string,
	}).isRequired,
	onLoginRequest: PropTypes.func.isRequired,
	onSignupRequest: PropTypes.func.isRequired,
}

export default LoginView

const Container = styled.div`
	margin-top: 40px;
	text-align: center;
`

const IllustBox = styled.div`
	display: inline-block;
	vertical-align: middle;
	width: 200px;
	margin-top: 150px;
`

const IllustImage = styled.img`
	width: 100%;
	height: 200px;
`

const Content = styled.div`
	display: inline-block;
	vertical-align: middle;

	@media (min-width: ${props => props.theme.PAGE_NOT_MOBILE_MIN_WIDTH}px) {
		padding: 0px 60px;
	}
	@media (max-width: ${props => props.theme.PAGE_MOBILE_MAX_WIDTH}px) {
		width: 100%;
		margin-bottom: 100px;
	}
`
