import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Query, graphql } from 'react-apollo'
import compose from 'lodash.flowright'
import { Container, Card, Grid, Menu, Header, Form } from 'semantic-ui-react'
import {
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	PieChart,
	Pie,
	Cell,
	Bar,
	BarChart,
} from 'recharts'

import { GET_RESEARCH_STATS } from '../graphql/queries/research'
import LoadingView from '../components/common/loader'
import SimpleInfoCard from '../components/common/basic/SimpleInfoCard'
import PageContainer from '../components/common/container/PageContainer'
import InputSearch from '../components/common/basic/InputSearch'
import LightH3 from '../components/common/basic/LightH3'
import PrepareView from '../components/common/error/PrepareView'

const styles = {
	chartTitle: {
		color: '#555',
		fontWeight: 600,
		marginTop: 60,
		marginBottom: 30,
		textAlign: 'center',
	},
}

const ResearchStatisticsSubjects = ({
	researchId,
	patientCount,
	recordCount,
	doctorCount,
	colors,
	renderCustomizedLabel,
}) => {
	return (
		<PageContainer fixed medium>
			{/* <LightH3>탐색하려는 필드를 검색해 주세요.</LightH3>
			<InputSearch fluid placeholder="필드명 검색" /> */}
			<PrepareView />
		</PageContainer>
		// <Query
		// 	query={GET_RESEARCH_STATS}
		// 	variables={{
		// 		researchId,
		// 		requests: [
		// 			{
		// 				type: 'PATIENT_GENDER_RATIO',
		// 			},
		// 			{
		// 				type: 'PATIENT_AVERAGE_AGE_SINGLE_VALUE',
		// 			},
		// 			{
		// 				type: 'PATIENT_GENERATION_RATIO',
		// 			},
		// 			{
		// 				type: 'PATIENT_MED_INFO_RATIO',
		// 				medInfoTypeId: 3650,
		// 			},
		// 			{
		// 				type: 'PATIENT_MED_INFO_RATIO',
		// 				medInfoTypeId: 3647,
		// 			},
		// 		],
		// 	}}
		// >
		// 	{({ loading, data }) => {
		// 		if (loading) {
		// 			return <LoadingView />
		// 		} else {
		// 			const { researchStatistics } = data
		// 			return (
		// 				<div>
		/* <Grid columns={2}>
								<Grid.Row>
									<Grid.Column>
										<h6 style={styles.chartTitle}>연구대상자 성별 분포</h6>
										<PieChart width={340} height={350}>
											<Pie
												data={researchStatistics[0].ratios}
												dataKey="value"
												cx={170}
												cy={170}
												innerRadius={40}
												outerRadius={90}
												fill="#8884d8"
												label={renderCustomizedLabel}
											>
												<Cell fill="#256bdb" />
												<Cell fill="#e2789b" />
											</Pie>
											<Tooltip />
										</PieChart>
									</Grid.Column>
									<Grid.Column>
										<h6 style={styles.chartTitle}>연구대상자 세대 분포</h6>
										<PieChart width={400} height={350}>
											<Pie
												data={researchStatistics[2].ratios}
												dataKey="value"
												cx={200}
												cy={170}
												innerRadius={40}
												outerRadius={90}
												fill="#8884d8"
												label={renderCustomizedLabel}
											>
												{researchStatistics[2].ratios.map(item => (
													<Cell key={item.name} fill="#256bdb" />
												))}
											</Pie>
											<Tooltip />
										</PieChart>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column>
										<h6 style={styles.chartTitle}>상병코드 분포</h6>
										<PieChart width={400} height={350}>
											<Pie
												data={researchStatistics[3].ratios}
												dataKey="value"
												cx={180}
												cy={170}
												innerRadius={40}
												outerRadius={90}
												fill="#8884d8"
												label={renderCustomizedLabel}
											>
												{researchStatistics[3].ratios.map((item, index) => (
													<Cell key={item.name} fill={colors[index]} />
												))}
											</Pie>
											<Tooltip />
										</PieChart>
									</Grid.Column>
									<Grid.Column>
										<h6 style={styles.chartTitle}>근골격계 수술시행여부</h6>
										<PieChart width={400} height={350}>
											<Pie
												data={researchStatistics[4].ratios}
												dataKey="value"
												cx={180}
												cy={170}
												innerRadius={40}
												outerRadius={90}
												fill="#8884d8"
												label={renderCustomizedLabel}
											>
												{researchStatistics[4].ratios.map((item, index) => (
													<Cell key={item.name} fill={colors[index]} />
												))}
											</Pie>
											<Tooltip />
										</PieChart>
									</Grid.Column>
								</Grid.Row>
							</Grid> */
		// 				</div>
		// 			)
		// 		}
		// 	}}
		// </Query>
	)
}

ResearchStatisticsSubjects.propTypes = {}

export default ResearchStatisticsSubjects
