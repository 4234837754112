import styled from 'styled-components'

const ImageButton = styled.img`
	display: inline-block;
	cursor: pointer;

	:hover {
		opacity: 0.4;
	}
`
export default ImageButton
