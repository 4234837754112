import React from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useQuery } from 'react-apollo'
import CaseFeed from './CaseFeed'
import { CaseListType, TAG_TYPES } from '../../enum'
import { GET_CASE_TAG } from '../../graphql/queries/case'
import ShowMore from '../common/basic/ShowMore'
import SimpleLabel from '../common/basic/SimpleLabel'

const TagCases = () => {
	const { tagId } = useParams()
	const { data: { caseTag } = {} } = useQuery(GET_CASE_TAG, {
		variables: {
			id: tagId,
		},
	})

	const texts = caseTag && caseTag.donguibogamLine && caseTag.donguibogamLine.titleTexts

	return (
		<div>
			<CaseFeed
				useAddButon={false}
				type={CaseListType.TAG.value}
				subItem={
					caseTag && (
						<div style={{ marginTop: 50 }}>
							{texts && texts.length > 0 && (
								<div>
									<div style={{ fontSize: 20, fontWeight: 'bold' }}>원문 보기</div>
									<TagBookText text={texts[0].text} showBorder={false} />
									{texts.length > 1 && (
										<ShowMore onTop={false}>
											{texts.slice(1).map(i => (
												<TagBookText key={`tag_text_${i.key}`} text={i.text} />
											))}
										</ShowMore>
									)}
								</div>
							)}
							<div style={{ fontSize: 20, fontWeight: 'bold', marginTop: 20, marginBottom: 20 }}>
								케이스 ({caseTag.caseCount})
							</div>
						</div>
					)
				}
				tagId={tagId}
				description={
					caseTag && (
						<div style={{ letterSpacing: 1.3 }}>
							<SimpleLabel style={{ marginRight: 10 }}>{TAG_TYPES[caseTag.tagType]}</SimpleLabel>
							{caseTag.description}
						</div>
					)
				}
				title={
					caseTag &&
					`${caseTag.textKorean}${caseTag.textChinese != null ? `(${caseTag.textChinese})` : ''}`
				}
			/>
		</div>
	)
}

TagCases.propTypes = {}

export default TagCases

const TagBookText = ({ text, showBorder = true }) => {
	return (
		<div
			style={{
				fontSize: 16,
				letterSpacing: 2,
				padding: '20px 60px',
				lineHeight: 1.7,
				...(showBorder === true && {
					borderTop: '1px solid #eee',
				}),
			}}
		>
			{text}
		</div>
	)
}
TagBookText.propTypes = {
	text: PropTypes.string,
	showBorder: PropTypes.bool,
}
