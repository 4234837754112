import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { COLOR_PRIMARY } from '../../../constants/styles'

const LinkColored = styled(Link)`
	color: ${COLOR_PRIMARY};

	:hover {
		color: ${COLOR_PRIMARY};
		opacity: 0.6;
	}

	:active {
		color: ${COLOR_PRIMARY};
		opacity: 0.4;
	}
`

export default LinkColored
