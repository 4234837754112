import gql from 'graphql-tag'

export const AUTH_PAYLOAD_FRAGMENT = gql`
	fragment AuthPayloadParts on AuthPayload {
		user {
			id
			name
			username
			nickname
			email
			userType
			profileImage
			institutionId
		}
		accessToken
		refreshToken
		termUpdated
	}
`
