import styled from 'styled-components'
import { Checkbox } from 'semantic-ui-react'

/**
 * Charting
 */

export const OptionContainer = styled.div`
	display: inline-block;
	margin-top: 5px;
	margin-left: 5px;
	margin-bottom: 8px;
`

export const StyledCheckbox = styled(Checkbox)`
	display: ${props => (props.isUsingNewLine === true ? 'block' : 'inline-block')} !important;
	margin-right: 10px;
	margin-bottom: 10px;
`

export const ReadOnlyValue = styled.span`
	padding: 10px;
	font-size: 16px;
	opacity: ${props => (props.disabled === true ? 0.3 : 1)};
	color: ${props => props.theme.COLOR_TEXT_DARKGRAY};
`

export const ReadOnlyValueNull = styled(ReadOnlyValue)`
	color: ${props => props.theme.COLOR_TEXT_LIGHTGRAY};
`

export const ReadOnlyOptionValue = styled.span`
	font-size: 16px;
	color: ${props => props.theme.COLOR_TEXT_GRAY};
	font-weight: 600;
	padding: 10px;
	padding-left: 3px;
`
