import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useHistory, useLocation, matchPath } from 'react-router-dom'
import { Icon, Button } from 'semantic-ui-react'

import {
	SideMenuContainer,
	Dimmer,
	BottomButtonContainer,
	BottomButtonWrapper,
	FullCenteredVerticalFlexDiv,
} from '../../common/basic/StyledBox'
import HeaderLoginButton from './HeaderLoginButton'
import useUserInfo from '../../../hooks/useUserInfo'
import UserInline from '../../common/UserInline'
import { ROUTE_CONTACT, ROUTE_MY_PROFILE_DEFAULT } from '../../../constants/routes'
import { BORDER_GRAY } from '../../../constants/styles'
import MyIcon from '../../common/basic/MyIcon'
import useLogout from '../../../hooks/useLogout'

import logo from '../../../assets/images/logo/lepius-logo-small.png'

/**
 * 모바일 사이드 바
 */
const HeaderSideBar = ({ menuItems }) => {
	const [isOpen, setOpen] = useState(false)
	const { noUser, user } = useUserInfo()
	const history = useHistory()
	const { pathname } = useLocation()

	const { profileImage, name, nickname } = user

	const onClickLink = useCallback(
		link => {
			history.push(link)
			setTimeout(() => {
				setOpen(false)
			}, 300)
		},
		[history]
	)

	const logout = useLogout()

	return (
		<>
			<SideMenuButton color="teal" name="bars" onClick={() => setOpen(r => !r)} />
			<SideMenuContainer isOpen={isOpen} left>
				{noUser === true ? (
					<FullCenteredVerticalFlexDiv style={{ marginTop: 50 }}>
						<Logo src={logo} />
						<HeaderLoginButton />
					</FullCenteredVerticalFlexDiv>
				) : (
					<>
						<ProfileInfo>
							<UserInline
								name={`${name} (${nickname})`}
								profileImage={profileImage}
								size={40}
								fontSize={16}
							/>
							<MyIcon name="settings" onClick={() => onClickLink(ROUTE_MY_PROFILE_DEFAULT)} />
						</ProfileInfo>
						<div>
							{menuItems.map(({ title, to }) => {
								const isActive =
									matchPath(pathname, {
										path: to,
									}) != null

								return (
									<MenuItem key={`hdsm-${to}`} isActive={isActive} onClick={() => onClickLink(to)}>
										{title}
									</MenuItem>
								)
							})}
						</div>
					</>
				)}

				<BottomButtonContainer>
					{noUser !== true && (
						<BottomButtonWrapper>
							<Button onClick={logout} size="large" fluid>
								로그아웃
							</Button>
						</BottomButtonWrapper>
					)}
					<BottomButtonWrapper>
						<Button
							onClick={() => onClickLink(ROUTE_CONTACT)}
							size="large"
							fluid
							primary={true}
							color="green"
						>
							문의하기
						</Button>
					</BottomButtonWrapper>
				</BottomButtonContainer>
			</SideMenuContainer>
			{isOpen === true && <Dimmer onClick={() => setOpen(false)} />}
		</>
	)
}

export default HeaderSideBar

HeaderSideBar.propTypes = {
	menuItems: PropTypes.arrayOf(
		PropTypes.shape({
			to: PropTypes.string,
			title: PropTypes.string,
		})
	),
}

/**
 * Styles
 */

const Logo = styled.img`
	margin-bottom: 20px;
	width: 120px;
	display: block;
`

const SideMenuButton = styled(Icon)`
	position: absolute;
	font-size: 22px !important;
	left: 10px;
	top: 14px;
`

const ProfileInfo = styled.div`
	padding: 20px;
	border-bottom: ${BORDER_GRAY};
	display: flex;
	align-items: center;
	justify-content: space-between;
`

const MenuItem = styled.div`
	padding: 20px;
	background-color: ${props =>
		props.isActive === true ? props.theme.COLOR_BACKGROUND_DARK_GRAY : 'white'};
	transition: background-color 0.3s linear;
`
