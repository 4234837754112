import { useMutation } from 'react-apollo'
import { ADD_TOAST_MESSAGE } from '../graphql/mutations/app'

/**
 * Toast Message를 component내에서 호출해야할 경우 사용
 */
const useToastMessage = () => {
	const [showToastMessage] = useMutation(ADD_TOAST_MESSAGE)

	return info => {
		showToastMessage({
			variables: info,
		})
	}
}

export default useToastMessage
