import React from 'react'
import PropTypes from 'prop-types'
import useCheckboxRadio from './useCheckboxRadio'
import { OptionContainer } from './Styled'
import useCheckboxInput from './useCheckboxInput'
import DataFieldCheckbox from './DataFieldCheckbox'

/**
 * Data Field Radio
 *
 * 라디오버튼
 */
const DataFieldCheckboxes = ({
	valueKey = 'checkedItems',
	readOnly,
	onUpdate,
	selectOptions,
	medInfoId,
	format,
	disabled,
	checkedItems = [],
}) => {
	const [onUpdateChecked, onUpdateFreeWriting] = useCheckboxInput(onUpdate, valueKey, checkedItems)
	const [isUsingNewLine] = useCheckboxRadio(format)

	return (
		<span>
			<OptionContainer className="df-checkbox">
				{selectOptions.map((item, index) => {
					const key = `${medInfoId}-${item.value || index}`
					const isChecked =
						checkedItems && checkedItems.findIndex(checked => checked.value === item.value) >= 0

					return (
						<DataFieldCheckbox
							key={key}
							readOnly={readOnly}
							disabled={disabled}
							isUsingNewLine={isUsingNewLine}
							label={item.text}
							value={item.value}
							freeWriting={item.freeWriting}
							checked={isChecked}
							onUpdate={onUpdateChecked}
							onUpdateFreeWriting={onUpdateFreeWriting}
							hasText={item.hasText}
						/>
					)
				})}
			</OptionContainer>
		</span>
	)
}

DataFieldCheckboxes.propTypes = {
	valueKey: PropTypes.string,
	checkedItems: PropTypes.arrayOf(PropTypes.object),
	onUpdate: PropTypes.func,
	readOnly: PropTypes.bool,
	selectOptions: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string,
			value: PropTypes.string,
		})
	),
	medInfoId: PropTypes.number,
	format: PropTypes.string,
	disabled: PropTypes.bool,
}

export default DataFieldCheckboxes
