import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { InputType } from '../../../../enum'
import DataFieldRadio from './DataFieldRadio'
import DataFieldSelect from './DataFieldSelect'
import DataFieldTextInput from './DataFieldTextInput'
import DataFieldTextArea from './DataFieldTextArea'
import DataFieldCheckboxes from './DataFieldCheckboxes'
import DataFieldSearch from './DataFieldSearch'
import DataFieldDate from './DataFieldDate'
import { DataFieldNumber } from './DataFieldNumber'
import DataFieldSearchMany from './DataFieldSearchMany'
import DataFieldNoEditString from './DataFieldNoEditString'
import DataFieldImage from './DataFieldImage'
import DataFieldTextInputHover from './DataFieldTextInputHover'

const DataFieldInput = props => {
	const {
		options,
		checkedItems,
		entityType,
		selectOptions,
		inputType,
		placeholder,
		format,
		value,
		optionValue,
		readOnly,
		unit,
		gridCell,
		medInfoId,
		onUpdate,
		onUpdateMany,
		onUploadFile,
		defaultValue,
		templateMode,
		disabled,
		medInfoEntityId,
	} = props

	const InputComponent = useMemo(() => {
		return getInputComponent(inputType, templateMode)
	}, [inputType, templateMode])

	return (
		<InputComponent
			readOnly={readOnly}
			disabled={disabled}
			checkedItems={checkedItems}
			entityType={entityType}
			selectOptions={selectOptions}
			placeholder={placeholder}
			format={format}
			options={options}
			value={value}
			optionValue={optionValue}
			unit={unit}
			gridCell={gridCell}
			medInfoId={medInfoId}
			onUpdate={onUpdate}
			onUpdateMany={onUpdateMany}
			medInfoEntityId={medInfoEntityId}
			// onKeydown={onKeydown}
			defaultValue={defaultValue}
			templateMode={templateMode}
			onUploadFile={onUploadFile}
		/>
	)
}

DataFieldInput.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
		})
	),
	checkedItems: PropTypes.array,
	entityType: PropTypes.string,
	selectOptions: PropTypes.array,
	inputType: PropTypes.string,
	placeholder: PropTypes.string,
	format: PropTypes.string,
	value: PropTypes.string,
	optionValue: PropTypes.string,
	readOnly: PropTypes.bool,
	unit: PropTypes.string,
	gridCell: PropTypes.bool,
	medInfoId: PropTypes.number,
	disabled: PropTypes.bool,
	onUpdate: PropTypes.func,
	onUpdateMany: PropTypes.func,
	onUploadFile: PropTypes.func,
	defaultValue: PropTypes.string,
	templateMode: PropTypes.bool,
	medInfoEntityId: PropTypes.number,
}

export default DataFieldInput

const getInputComponent = (inputType, templateMode) => {
	switch (inputType) {
		case InputType.SHORT_FREE_TEXT.value:
			return DataFieldTextInput
		case InputType.LONG_FREE_TEXT.value:
			return DataFieldTextArea
		case InputType.NUMBER.value:
			return DataFieldNumber
		case InputType.SELECT.value:
			return DataFieldSelect
		case InputType.RADIO.value:
			return DataFieldRadio
		case InputType.CHECKBOX.value:
			return DataFieldCheckboxes
		case InputType.SEARCH_TEXT.value: ///추가하기
			return DataFieldSearch
		case InputType.SEARCH_SELECT_MULTI.value:
			return DataFieldSearchMany
		case InputType.IMAGE.value:
			return DataFieldImage
		case InputType.NO_EDIT_STRING.value:
			if (templateMode === true) return DataFieldTextInputHover
			return DataFieldNoEditString
		case InputType.DATE.value:
			return DataFieldDate
		default:
			return React.Fragment
	}
}
