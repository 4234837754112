import { useQuery } from 'react-apollo'
import { GET_SUB_SIDE_MENU_OPEN } from '../graphql/queries/app'

const useSubSideMenuState = () => {
	const {
		data: { isSubSideMenuOpen },
	} = useQuery(GET_SUB_SIDE_MENU_OPEN)

	return isSubSideMenuOpen
}

export default useSubSideMenuState
