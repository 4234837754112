import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Dropdown, Search } from 'semantic-ui-react'

import { InputType } from '../../../enum'

const SearchResult = ({ id, name, inputType }) => (
	<div>
		<div style={styles.title}>{name}</div>
		<div style={styles.description}>{InputType[inputType] && InputType[inputType].text}</div>
	</div>
)

export default class AddFieldDropdown extends PureComponent {
	static propTypes = {
		onOpenTypeModal: PropTypes.func,
		onCreateMedInfo: PropTypes.func,
		trigger: PropTypes.object.isRequired,
		style: PropTypes.object,
		onSearch: PropTypes.func,
		parentName: PropTypes.string.isRequired,
		className: PropTypes.string,
		isSection: PropTypes.bool,
	}

	static defaultProps = {
		isSection: false,
	}

	state = {
		results: [],
		value: '',
	}

	handleSearchChange = async (e, { value }) => {
		this.setState({
			value,
		})

		const { onSearch, isSection } = this.props
		if (value) {
			const searchResult = await onSearch(value)
			if (isSection === true) {
				this.setState({
					results: searchResult.filter(i => i.inputType === InputType.SECTION.value),
				})
			} else {
				this.setState({
					results: searchResult,
				})
			}
		}
	}

	handleSearchSelect = async (e, { result }) => {
		const { onCreateMedInfo } = this.props
		onCreateMedInfo({ medInfoTypeId: result.id })
		this.setState({
			results: [],
			value: '',
		})
	}

	render() {
		const {
			className,
			onOpenTypeModal,
			trigger,
			style,
			onCreateMedInfo,
			parentName,
			isSection,
		} = this.props
		const { results, value } = this.state
		return (
			<StyledDropdown
				className={className}
				style={style}
				pointing="top"
				icon={null}
				trigger={trigger}
				closeOnChange={false}
			>
				<Dropdown.Menu className="add-field-menu">
					<StyledSearch
						resultRenderer={SearchResult}
						style={styles.searchBar}
						onResultSelect={this.handleSearchSelect}
						onSearchChange={this.handleSearchChange}
						results={results}
						onClick={e => e.stopPropagation()}
						placeholder="필드 검색"
						value={value}
					/>
					<Dropdown.Divider />
					<Dropdown.Item
						text={isSection === false ? '새로운 필드 만들기' : '새로운 섹션 만들기'}
						icon="add"
						value="add"
						onClick={() => onOpenTypeModal({ title: parentName, onSubmit: onCreateMedInfo })}
					/>
				</Dropdown.Menu>
			</StyledDropdown>
		)
	}
}

const StyledSearch = styled(Search)`
	input {
		width: 300px !important;
		border: none !important;
		background: white !important;
	}
`
const StyledDropdown = styled(Dropdown)`
	.menu.transition.add-field-menu {
		left: 50%;
		margin-left: -150px;
	}
`

const styles = {
	searchBar: {
		margin: 5,
	},
	title: {
		fontWeight: 600,
		fontSize: 16,
		marginBottom: 5,
	},
	description: {
		fontSize: 14,
		fontWeight: 400,
		color: '#777',
	},
}
