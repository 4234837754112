import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { InputType, InputTypeCategory } from '../../enum'
import PatientSurveyVas from './fields/PatientSurveyVas'
import PatientSurveyText from './fields/PatientSurveyText'
import PatientSurveySelect from './fields/PatientSurveySelect'
import PatientSurveyDate from './fields/PatientSurveyDate'
// eslint-disable-next-line import/no-cycle
import PatientSurveyBlock from './fields/PatientSurveyBlock'
import PatientSurveyNumber from './fields/PatientSurveyNumber'

const PatientSurveyQuestion = props => {
	const { medInfo, inBlock } = props
	const { medInfoType } = medInfo
	const { inputType } = medInfoType

	const InputComponent = useMemo(() => {
		const inputTypeInfo = InputType[inputType]

		if (inputTypeInfo.category === InputTypeCategory.SELECT.value) {
			return PatientSurveySelect
		} else if (inputType === InputType.SHORT_FREE_TEXT.value) {
			return PatientSurveyText
		} else if (inputType === InputType.NUMBER.value) {
			return PatientSurveyNumber
		} else if (inputType === InputType.VAS_ONLY.value) {
			return PatientSurveyVas
		} else if (inputType === InputType.DATE.value) {
			return PatientSurveyDate
		} else if (inputType === InputType.BLOCK.value) {
			return PatientSurveyBlock
		} else {
			return () => <div />
		}
	}, [inputType])

	return (
		<Container>
			<InputComponent medInfo={medInfo} inBlock={inBlock} />
		</Container>
	)
	// const {
	// 	isActive,
	// 	currentIndex,
	// 	index,
	// 	medInfoId,
	// 	medInfoType,
	// 	value,
	// 	checkedItems,
	// 	optionValue,
	// 	toNext,
	// 	medInfos,
	// 	idx,
	// } = props
	// const {
	// 	name,
	// 	inputType,
	// 	options,
	// 	selectOptions,
	// 	unit,
	// 	format,
	// 	conditions,
	// 	description,
	// } = medInfoType

	// const inputRef = useRef()
	// const isText =
	// 	inputType === InputType.SHORT_FREE_TEXT.value || inputType === InputType.NUMBER.value

	// useEffect(() => {
	// 	if (idx === 0 && isActive === true && inputRef.current != null) {
	// 		inputRef.current.focus()
	// 		inputRef.current.setSelectionRange(0, 9999)
	// 	}
	// }, [idx, isActive])

	// let InputComponent = useMemo(() => {
	// 	const inputTypeInfo = InputType[inputType]

	// 	if (inputTypeInfo.category === InputTypeCategory.SELECT.value) {
	// 		return PatientSurveySelect
	// 	} else if (isText === true) {
	// 		return PatientSurveyText
	// 	} else if (inputType === InputType.VAS_ONLY.value) {
	// 		return PatientSurveyVas
	// 	} else if (inputType === InputType.DATE.value) {
	// 		return PatientSurveyDate
	// 	}
	// }, [inputType, isText])

	// const [localMedInfo, update] = useLocalMedInfo(medInfoId)
	// const { value: mdv } = localMedInfo
	// const [stateValue, onChange, onUpdateStateValue] = useTextInput(update, 'value', mdv)

	// if (InputComponent == null) return <div />

	// return (
	// 	<Container>
	// 		<Title>{name}</Title>
	// 		{description != null && <Description>{description}</Description>}
	// 		<InputBox>
	// 			<InputComponent
	// 				inputRef={inputRef}
	// 				medInfoId={medInfoId}
	// 				inputType={inputType}
	// 				conditions={conditions}
	// 				options={options}
	// 				unit={unit}
	// 				format={format}
	// 				value={value}
	// 				stateValue={stateValue}
	// 				onUpdate={update}
	// 				onChangeStateValue={onChange}
	// 				onUpdateStateValue={onUpdateStateValue}
	// 				checkedItems={checkedItems}
	// 				optionValue={optionValue}
	// 			/>
	// 		</InputBox>
	// 	</Container>
	// )
}

PatientSurveyQuestion.propTypes = {
	medInfo: PropTypes.object,
	inBlock: PropTypes.bool,
}

export default PatientSurveyQuestion

// const Container = styled(VerticalFlex)`
// 	padding: 30px 50px;
// 	margin-bottom: 16px;
// 	background: linear-gradient(180deg, #409ada, #cd70e8);
// 	color: white;
// 	border-radius: 25px;
// `

// const Title = styled.div`
// 	font-size: 30px;
// 	font-weight: 600;
// 	line-height: 35px;
// `
// const Description = styled.div`
// 	margin-top: 10px;
// 	font-size: 16px;
// `

// const InputBox = styled(FullCenteredVerticalFlexDiv)`
// 	flex: 1;
// 	background: white;
// 	border-radius: 20px;
// 	margin-top: 30px;
// 	color: ${props => props.theme.COLOR_TEXT};
// 	overflow: hidden;
// `

const Container = styled.div`
	display: flex;
	flex: 1 0 auto;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
`
