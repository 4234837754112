import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import ReactSVG from 'react-svg'
import DateIcon from '../../../assets/images/icons/cal.svg'
import { InputErrorMessage } from './StyledTexts'

/**
 * 날짜 입력 인풋
 */
const DateInput = ({ error, inline, style, placeholder, isCompact, ...props }) => {
	return (
		<div className="ui form" style={{ display: inline === true ? 'inline-block' : 'block' }}>
			<Wrapper style={style} isCompact={isCompact}>
				<DatePicker {...props} placeholderText={placeholder || '클릭하여 선택'} />
				<CalIcon isCompact={isCompact} />
			</Wrapper>
			{error != null && <InputErrorMessage>* {error.message}</InputErrorMessage>}
		</div>
	)
}

DateInput.propTypes = {
	error: PropTypes.shape({
		message: PropTypes.string,
	}),
	style: PropTypes.shape(),
	inline: PropTypes.bool,
	placeholder: PropTypes.string,
	isCompact: PropTypes.bool,
}

DateInput.defaultProps = {
	style: null,
	placeholder: null,
	isCompact: false,
	error: null,
}

export default DateInput

const Wrapper = styled.div.attrs({ className: 'ui input' })`
	width: 100%;
	max-width: 300px;

	${props =>
		props.isCompact === true
			? `
		max-width: 150px;
		input {
			font-size: 13px !important;
			padding: 5px 3px !important;
		}
	`
			: ''};
`

const CalIcon = styled(ReactSVG).attrs({ src: DateIcon })`
	position: absolute;
	left: 10px;
	top: 50%;
	margin-top: -12px;
	${props => (props.isCompact === true ? `transform: scale(0.9);` : '')};
`
