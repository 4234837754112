import styled from 'styled-components'
import {
	BORDER_RADIUS,
	COLOR_SUCCESS,
	PROGRESS_GRADIENT,
	PROGRESS_GRADIENT_ERROR,
	PROGRESS_GRADIENT_SUCCESS,
} from '../../../constants/styles'

const ProgressLoader = styled.div`
	border-radius: ${BORDER_RADIUS}px;
	background-color: ${props => props.color || COLOR_SUCCESS};
	background-image: ${props =>
		props.isError
			? PROGRESS_GRADIENT_ERROR
			: props.isSuccess
			? PROGRESS_GRADIENT_SUCCESS
			: PROGRESS_GRADIENT};
	flex: 1;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 24px;
	position: relative;

	:after {
		width: ${props => props.width || '100%'};
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;

		${props =>
			props.isLoading === true
				? `
		background-image: linear-gradient(
			-45deg,
			rgba(255, 255, 255, 0.2) 25%,
			transparent 25%,
			transparent 50%,
			rgba(255, 255, 255, 0.2) 50%,
			rgba(255, 255, 255, 0.2) 75%,
			transparent 75%,
			transparent
		);
		z-index: 1;
		-webkit-background-size: 50px 50px;
		-moz-background-size: 50px 50px;
		background-size: 50px 50px;
		-webkit-animation: move 2s linear infinite;
		-moz-animation: move 2s linear infinite;
		-webkit-border-top-right-radius: 8px;
		-webkit-border-bottom-right-radius: 8px;
		-moz-border-radius-topright: 8px;
		-moz-border-radius-bottomright: 8px;
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
		-webkit-border-top-left-radius: 20px;
		-webkit-border-bottom-left-radius: 20px;
		-moz-border-radius-topleft: 20px;
		-moz-border-radius-bottomleft: 20px;
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
    overflow: hidden;
    `
				: ''}
	}

	@keyframes move {
		0% {
			background-position: 0 0;
		}

		100% {
			background-position: 50px 50px;
		}
	}
`

export default ProgressLoader
