import gql from 'graphql-tag'
import { NOTIFICATION_FRAGMENT } from '../fragments/notification'

// 읽음 처리
export const MARK_NOTIF_AS_READ = gql`
	mutation markNotificationReadStatus($id: Int!, $readStatus: ReadStatus!) {
		markNotificationReadStatus(id: $id, readStatus: $readStatus) {
			...NotificationParts
		}
	}
	${NOTIFICATION_FRAGMENT}
`
