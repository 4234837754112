import React, { PureComponent, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import compose from 'lodash.flowright'
import { graphql } from 'react-apollo'
import { withRouter, Route } from 'react-router-dom'

import moment from 'moment'
import 'moment/locale/ko'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-rangeslider/lib/index.css'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { isMobile } from 'react-device-detect'

import ErrorView from './components/common/error/ErrorView'

import { GET_USER_INFO } from './graphql/queries/user'
import { UPDATE_USER_INFO, RESET_USER_INFO, UPDATE_NO_USER_INFO } from './graphql/mutations/user'
import { detectIe } from './libs/valueHelper'

import { isMatchingRoutes, isMatchingExactRoutes } from './libs/routeHelper'
import {
	ROUTES_SIDE_MENU,
	ROUTES_NO_FOOTER,
	ROUTES_FULL_WIDTH_CONTENT,
	ROUTE_HOME,
	ROUTES_SUB_SIDE_MENU,
	ROUTES_NO_HEADER_MENU,
	ROUTE_ABOUT,
	ROUTES_HEADER_SIDE_CONTENT,
	ROUTE_SURVEY,
	ROUTE_CASES,
} from './constants/routes'
import { Header } from './components/frame/header/Header'
import { Footer } from './components/frame/Footer'
import { SideMenu } from './components/frame/SideMenu'
import PropRoute from './components/common/route/PropRoute'
import { FOOTER_HEIGHT } from './constants/styles'

import SubSideMenu from './components/frame/SubSideMenu'
import Main from './components/frame/Main'
import { USER_TYPES } from './constants'
import ToastMessages from './components/app/ToastMessages'
import { ExceptMobile } from './components/common/basic/ResponsiveWrappers'
import { UserType } from './enum'
import PatientSurvey from './containers/PatientSurvey'
import Survey from './containers/Survey'
import CasesHome from './components/cases/CasesHome'

moment.locale('ko')

/**
 * 전체 앱 컴퍼넌트
 */
class App extends PureComponent {
	static propTypes = {
		location: PropTypes.shape({
			pathname: PropTypes.string.isRequired,
		}).isRequired,
		userInfoError: PropTypes.shape(),
		currentAuthPayload: PropTypes.shape({
			user: PropTypes.object,
			termUpdated: PropTypes.bool,
		}).isRequired,
	}

	static defaultProps = {
		userInfoError: null,
	}

	constructor(props) {
		super(props)

		this.isIE = detectIe()
	}

	render() {
		const {
			currentAuthPayload,
			currentAuthPayload: {
				user: { userType },
			},
			location: { pathname },
			userInfoError,
		} = this.props

		if (userInfoError) return <ErrorView />

		if (isMatchingRoutes(pathname, [ROUTE_CASES])) {
			return <PropRoute component={CasesHome} authRequired hasMobile props={this.props} />
		}

		if (isMatchingExactRoutes(pathname, [ROUTE_SURVEY])) {
			return <Route path={ROUTE_SURVEY} component={Survey} />
		}

		if (userType === UserType.PATIENT.value) {
			return <PatientSurvey />
		}

		const hasSideMenu = isMatchingRoutes(pathname, ROUTES_SIDE_MENU) && isMobile !== true
		const hasSubSideMenu = isMatchingRoutes(pathname, ROUTES_SUB_SIDE_MENU) && isMobile !== true
		const shouldNotDisplayFooter = isMatchingRoutes(pathname, ROUTES_NO_FOOTER)
		const hasFullWidthContent =
			isMatchingRoutes(pathname, ROUTES_FULL_WIDTH_CONTENT) ||
			isMatchingExactRoutes(pathname, [ROUTE_HOME, ROUTE_ABOUT])
		const isWithoutHeaderMenu = isMatchingRoutes(pathname, ROUTES_NO_HEADER_MENU)
		const hasHeaderSideContent = isMatchingRoutes(pathname, ROUTES_HEADER_SIDE_CONTENT)

		return (
			<DndProvider backend={HTML5Backend}>
				<Container>
					<Content
						hasSubSideMenu={hasSubSideMenu}
						hasSideMenu={hasSideMenu}
						hasFullWidthContent={hasFullWidthContent}
						currentAuthPayload={currentAuthPayload}
						isWithoutHeaderMenu={isWithoutHeaderMenu}
						hasHeaderSideContent={hasHeaderSideContent}
						shouldNotDisplayFooter={shouldNotDisplayFooter}
					/>
					{shouldNotDisplayFooter === false && (
						<ExceptMobile>
							<Footer />
						</ExceptMobile>
					)}
				</Container>
				<ToastMessages />
			</DndProvider>
		)
	}
}

export default compose(
	withRouter,
	graphql(GET_USER_INFO, {
		props: ({ data }) => {
			const { userInfo, refetch, error } = data

			return {
				currentAuthPayload: userInfo,
				userInfoError: error,
				refetchUserInfo: refetch,
			}
		},
	}),
	graphql(UPDATE_USER_INFO, {
		name: 'updateUserInfo',
	}),
	graphql(UPDATE_NO_USER_INFO, {
		name: 'updateNoUserInfo',
	}),
	graphql(RESET_USER_INFO, {
		name: 'resetUserInfo',
	})
)(App)

/**
 * Sub Components
 */

/**
 * Footer 제외한 내용
 */
const Content = props => {
	const {
		hasSideMenu,
		hasSubSideMenu,
		hasFullWidthContent,
		isWithoutHeaderMenu,
		hasHeaderSideContent,
		...otherProps
	} = props
	const {
		currentAuthPayload: { user },
	} = otherProps
	const isUserLoggedIn = user.id !== 0
	const headerRef = useRef(null)
	const subSideMenuRef = useRef(null)
	const isUserResearcher = user.userType === USER_TYPES.RESEARCHER

	return (
		<ContentContainer hasSideMenu={hasSideMenu} isMobile={isMobile}>
			{hasSideMenu === true && <SideMenu isResearcher={isUserResearcher} />}
			{hasSubSideMenu === true && <SubSideMenu {...otherProps} />}

			<ContentWrapper hasSideMenu={hasSideMenu} hasSubSideMenu={hasSubSideMenu} isMobile={isMobile}>
				<Header
					isMinimal={isWithoutHeaderMenu}
					isFloating={hasSideMenu}
					isUserLoggedIn={isUserLoggedIn}
					isLogoVisible={hasSideMenu === false}
					isMenuVisible={hasSideMenu === false && isWithoutHeaderMenu === false}
					userImage={user.profileImage}
					userName={user.name}
					userNickname={user.nickname}
					isResearcher={isUserResearcher}
					hasSideContent={hasHeaderSideContent}
				/>
				<Main
					{...props}
					headerRef={headerRef}
					onToggleSubSideMenu={shouldOpen => {
						if (subSideMenuRef.current != null && subSideMenuRef.current.handleToggle) {
							subSideMenuRef.current.handleToggle(shouldOpen)
						}
					}}
				/>
			</ContentWrapper>
		</ContentContainer>
	)
}

Content.propTypes = {
	hasSideMenu: PropTypes.bool.isRequired,
	hasSubSideMenu: PropTypes.bool.isRequired,
	hasFullWidthContent: PropTypes.bool.isRequired,
	currentAuthPayload: PropTypes.shape().isRequired,
	isWithoutHeaderMenu: PropTypes.bool.isRequired,
	hasHeaderSideContent: PropTypes.bool.isRequired,
}

/**
 * Styles
 */

/**
 * 전체 감싸는 Wrapper
 */
const Container = styled.div`
	height: 100%;
	text-align: center;
`

/**
 * 사이드메뉴 & 헤더 & 내용
 */
const ContentContainer = styled.div`
	min-height: 100%;
	height: ${props => (props.hasSideMenu === true ? '100%' : 'auto')};
	display: flex;
	flex-direction: row;
	overflow: ${props => props.isMobile === true && 'hidden'};

	@media (max-width: 768px) {
		/* height: 100%; */
	}
`

/**
 * 사이드메뉴 제외한 헤더 & 내용
 */
const ContentWrapper = styled.div`
	overflow: ${props => props.isMobile === true && 'auto'};
	display: flex;
	flex: 1;
	flex-direction: column;
	padding-bottom: ${props => (props.hasSideMenu === false ? FOOTER_HEIGHT : 0)}px;
`
