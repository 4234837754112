import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useMutation } from 'react-apollo'
import { Button } from 'semantic-ui-react'
import { ADD_MED_INFO_MEDIA } from '../../../../graphql/mutations/medInfo'
import { FILE_ACCEPT_OPTIONS } from '../../../../constants'
import { InlineLoader } from '../../../common/loader/InlineLoader'
import MyIcon from '../../../common/basic/MyIcon'
import { UISize } from '../../../../enum'

/**
 * 이미지 업로드를 위한 DataFieldInput
 */
const DataFieldImage = ({ templateMode, value, medInfoEntityId, researchId, onUpdate }) => {
	const [uploadedUri, setUploadedUri] = useState(null)

	const [uploadFile, { loading }] = useMutation(ADD_MED_INFO_MEDIA, {
		onError: () => {
			alert('오류가 발생했습니다.')
		},
		onCompleted: ({ addMedInfoMedia: { filePath, fileUri } }) => {
			onUpdate('value', filePath)
			setUploadedUri(fileUri)
		},
	})

	const fileRef = useRef(null)

	const imageUri = uploadedUri != null ? uploadedUri : value

	const onChange = ({
		target: {
			validity,
			files: [file],
		},
	}) => {
		if (validity.valid) {
			uploadFile({
				variables: {
					medInfoEntityId,
					researchId,
					mediaFile: file,
				},
			})
		}
	}

	const onClick = () => {
		if (templateMode === true) {
			alert('양식 수정 중에는 이미지를 업로드할 수 없습니다.')
		} else if (fileRef.current != null) fileRef.current.click()
	}

	let content
	if (loading === true) {
		content = <InlineLoader />
	} else if (imageUri != null) {
		content = (
			<>
				<Toolset>
					<Button primary basic onClick={onClick}>
						<MyIcon name="image" size={UISize.MINI} />
						이미지 변경
					</Button>
					<Button
						onClick={() => {
							onUpdate('value', null)
							setUploadedUri(null)
						}}
					>
						삭제
					</Button>
				</Toolset>
				<ImageView>
					<Image src={imageUri} />
				</ImageView>
			</>
		)
	} else {
		content = (
			<Empty onClick={onClick}>
				<MyIcon name="image" />
				클릭하여 이미지 파일을 업로드해 주세요.
			</Empty>
		)
	}

	return (
		<Container>
			<input
				type="file"
				accept={FILE_ACCEPT_OPTIONS.image}
				hidden
				ref={fileRef}
				onChange={onChange}
			/>
			{content}
		</Container>
	)
}

DataFieldImage.propTypes = {
	value: PropTypes.string,
	medInfoEntityId: PropTypes.number,
	researchId: PropTypes.number,
	onUpdate: PropTypes.func,
}

export default DataFieldImage

const Container = styled.div`
	flex: 1;
`

const ImageView = styled.div`
	display: flex;
	align-content: center;
	justify-content: center;
	margin-top: 10px;
	border: ${props => props.theme.BORDER_GRAY};
`
const Image = styled.img`
	max-width: 100%;
	max-height: 500px;
`

const Empty = styled.div`
	font-size: 14px;
	padding: 20px;
	border-radius: 2px;
	text-align: center;
	border: ${props => props.theme.BORDER_GRAY};
	color: ${props => props.theme.COLOR_TEXT_GRAY};
	margin: 20px;
	cursor: pointer;

	:hover {
		opacity: 0.4;
	}
`

const Toolset = styled.div`
	padding: 10px;
	width: 100%;
`
