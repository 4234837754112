import gql from 'graphql-tag'
import { NOTIFICATION_FRAGMENT } from '../fragments/notification'

export const GET_USER_NOTIFICATIONS = gql`
	query getUserNotifications($pagination: PatientPagination!, $filter: ListFilter) {
		userNotifications(pagination: $pagination, filter: $filter) {
			items {
				...NotificationParts
			}
			totalCount
			perPage
			page
		}
	}
	${NOTIFICATION_FRAGMENT}
`

export const GET_USER_UNREAD_NOTIFICATION_COUNT = gql`
	query getUserUnreadNotificationCount {
		userUnreadNotificationCount

		userInfo @client {
			user {
				id
			}
		}
	}
`
