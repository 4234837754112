import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import moment from 'moment'

import { ResearchStatus } from '../../../enum'
import T1 from '../../common/basic/T1'
import StatusLabel from '../../common/StatusLabel'
import { COLOR_TEXT_LIGHTGRAY, COLOR_TEXT_YELLOW } from '../../../constants/styles'
import { truncateString } from '../../../libs/valueHelper'
import { MiniBar } from '../../common/basic/MiniBar'

const TITLE_MAX_LEN = 16

function ResearchListItem({
	title,
	updatedAt,
	description,
	organizedBy,
	doctorCount,
	patientCount,
	startDate,
	endDate,
	estSubjects,
	status,
}) {
	return (
		<>
			<StatusLabel set={ResearchStatus} status={status} />
			<Info>
				{organizedBy.name} <MiniBar />
				기간 {startDate} ~ {endDate}
			</Info>
			<Title>{truncateString(title, TITLE_MAX_LEN)}</Title>
			<Description>{description}</Description>
			<Info>
				참여 의료인 {doctorCount} <MiniBar />
				{estSubjects ? (
					<span>대상자 규모 {estSubjects}</span>
				) : (
					<span>현재 케이스 {patientCount}</span>
				)}
			</Info>
			<UpdatedDate>
				최근 업데이트
				{moment(updatedAt).calendar()}
			</UpdatedDate>
			{/* <div>
        <InstitutionLinks
          style={styles.institution}
          institutions={[organizedBy]}
          researchId={researchId}
        />
      </div>
      <div style={styles.description}>
        <div style={styles.descriptionText} className="block-with-text">
          {description}
        </div>
      </div>
      <div>
        <Icon name="circle outline" style={styles.subInfoIcon} />
        참여 의료인&nbsp;&nbsp;&nbsp;&nbsp;<b>{doctorCount}</b> 명
      </div>
      {estSubjects ? (
        <div>
          <Icon name="circle outline" style={styles.subInfoIcon} />
          대상자 규모&nbsp;&nbsp;&nbsp;&nbsp;약 <b>{estSubjects}</b> 명
        </div>
      ) : (
        <div>
          <Icon name="circle outline" style={styles.subInfoIcon} />
          현재 케이스&nbsp;&nbsp;&nbsp;&nbsp;<b>{patientCount}</b>
        </div>
      )}

      <div style={styles.lastUpdate}>
        최근 업데이트
        <br />
        {moment(updatedAt).fromNow()}
      </div> */}
		</>
	)
}

ResearchListItem.propTypes = {
	title: PropTypes.string.isRequired,
	updatedAt: PropTypes.string.isRequired,
	description: PropTypes.string,
	organizedBy: PropTypes.object.isRequired,
	doctorCount: PropTypes.number,
	patientCount: PropTypes.number.isRequired,
	startDate: PropTypes.string.isRequired,
	endDate: PropTypes.string.isRequired,
	estSubjects: PropTypes.number,
	status: PropTypes.string,
}

export default ResearchListItem

const Info = styled.div`
  margin-top: 8px;
  margin-bottom: 4px;
  color:${COLOR_TEXT_LIGHTGRAY}
  font-size: 12px;
`

const Title = styled(T1)`
	line-height: 1.5;
	letter-spacing: -0.65px;
	height: 30px;
`

const Description = styled.div`
	letter-spacing: -0.65px;
	margin-top: 8px;
`

const UpdatedDate = styled.div`
	position: absolute;
	right: 9px;
	top: 8px;
	color: ${COLOR_TEXT_YELLOW};
`
