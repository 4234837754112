import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Card } from 'semantic-ui-react'
import { useDrag } from 'react-dnd'
import { DRAG_ITEM_TYPES } from '../../../constants'

const PartialTemplateItem = props => {
	const { id, title, isDragging } = props

	return (
		<DragCard
			onClick={() => {
				/**preview */
			}}
			isDragging={isDragging}
		>
			<Card.Content>{title}</Card.Content>
		</DragCard>
	)
}

PartialTemplateItem.propTypes = {}

export default PartialTemplateItem

const DragCard = styled(Card)`
	width: 266px;
	${props =>
		props.isDragging === true
			? `
			visibility: hidden !important;
	`
			: ''};
`
