import { useState, useCallback } from 'react'

/**
 * 체크박스 type의 개별 checkbox에 사용되는 hooks
 */
const useCheckbox = (onUpdate, onUpdateFreeWritingProp, value, isChecked, freeWriting) => {
	const [stateFreeWriting, setStateFreeWriting] = useState(freeWriting)

	const onChange = (e, { checked }) => {
		onUpdate(value, checked)
	}

	const onChangeFreeWriting = (e, { value }) => {
		setStateFreeWriting(value)
	}

	const onUpdateFreeWriting = useCallback(() => {
		onUpdateFreeWritingProp(value, freeWriting)
	}, [freeWriting, onUpdateFreeWritingProp, value])

	return [onChange, stateFreeWriting, onChangeFreeWriting, onUpdateFreeWriting]
}

export default useCheckbox
