import React from 'react'
import PropTypes from 'prop-types'

import { Table } from 'semantic-ui-react'
import { COLOR_TEXT_LIGHTGRAY } from '../../../constants/styles'

const EmptyTable = ({ onClick, content, emptyMessage }) => {
	return (
		<Table.Row style={{ height: 300, textAlign: 'center' }} onClick={onClick}>
			<Table.Cell colSpan={99}>
				<div style={{ color: COLOR_TEXT_LIGHTGRAY }}>{emptyMessage || '항목이 없습니다.'}</div>
				{content}
			</Table.Cell>
		</Table.Row>
	)
}

EmptyTable.propTypes = {
	emptyMessage: PropTypes.string,
	content: PropTypes.element,
}

EmptyTable.defaultProps = {
	emptyMessage: null,
}

export default EmptyTable
