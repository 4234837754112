/**
 * [Component] 기록 수정 Modal
 *
 * 진료일자 변경
 */

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Mutation } from 'react-apollo'
import { Modal, Button, Header, Form } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'

import moment from 'moment'
import { UPDATE_RECORD_INFO } from '../../graphql/mutations/record'

import 'moment/locale/ko'

export default class RecordEditModal extends PureComponent {
	static propTypes = {
		visitedDate: PropTypes.string,
		open: PropTypes.bool,
		onClose: PropTypes.func,
	}

	constructor(props) {
		super(props)

		this.state = {
			visitedDate: props.visitedDate,
		}
	}

	handleChangeDate = item =>
		this.setState({
			visitedDate: item.format('YYYY-MM-DD HH:mm'),
		})

	handleSave = onUpdate => async () => {
		const { recordId, onClose } = this.props

		try {
			await onUpdate({
				variables: {
					recordId,
					recordInfo: {
						visitedDate: this.state.visitedDate,
					},
				},
			})

			alert('변경이 완료되었습니다.')
			onClose()
		} catch (e) {
			alert('변경 중 오류가 발생했습니다.')
		}
	}

	render() {
		const { open, onClose } = this.props
		const { visitedDate } = this.state

		return (
			<Mutation mutation={UPDATE_RECORD_INFO}>
				{onUpdate => (
					<Modal open={open}>
						<Modal.Header>진료일 수정</Modal.Header>
						<Modal.Content>
							<Header as="h4">진료일을 선택해주세요.</Header>
							<Form>
								<Form.Field>
									<div className="df-container" style={{ marginTop: 20 }}>
										<DatePicker
											locale="ko"
											selected={visitedDate && moment(visitedDate)}
											showMonthDropdown
											showYearDropdown
											showTimeSelect
											scrollableYearDropdown
											disable
											placeholderText="클릭해서 날짜선택"
											dateFormat="YYYY-MM-DD HH:mm"
											dropdownMode="select"
											dateFormatCalendar="YYYY MMMM"
											onChange={this.handleChangeDate}
											maxDate={moment()}
										/>
									</div>
								</Form.Field>
							</Form>
						</Modal.Content>
						<Modal.Actions>
							<Button onClick={onClose}>취소</Button>
							<Button primary onClick={this.handleSave(onUpdate)}>
								저장하기
							</Button>
						</Modal.Actions>
					</Modal>
				)}
			</Mutation>
		)
	}
}
