import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Modal } from 'semantic-ui-react'
import { CenteredRowFlexDiv } from '../common/basic/StyledBox'
import useLogout from '../../hooks/useLogout'

/**
 * [Presentational] 환자 설문 페이지 헤더
 */
const PatientSurveyHeader = React.memo(({ logoImage }) => {
	const logout = useLogout()

	return (
		<FixedHeader>
			<Modal
				size="tiny"
				closeIcon
				content="로그아웃 하시겠습니까? 환자 설문 시스템이 종료됩니다."
				actions={[
					'취소',
					{
						key: 'logout',
						content: '로그아웃',
						positive: true,
						primary: true,
						onClick: logout,
					},
				]}
				trigger={<LogoutButton>로그아웃</LogoutButton>}
			/>
			<HospitalLogo src={logoImage} />
		</FixedHeader>
	)
})

PatientSurveyHeader.propTypes = {
	logoImage: PropTypes.string,
}

PatientSurveyHeader.defaultProps = {
	logoImage: null,
}

export default PatientSurveyHeader

const FixedHeader = styled(CenteredRowFlexDiv)`
	position: fixed;
	padding: 20px;
	left: 0px;
	top: 0px;
	right: 0px;
	height: 100px;
`

const HospitalLogo = styled.img`
	max-width: 600px;
	max-height: 50px;
`

const LogoutButton = styled(Button)`
	position: absolute;
	left: 30px;
	top: 35px;

	border-radius: 20px !important;
	border: 1px solid ${props => props.theme.COLOR_SURVEY_BLUE} !important;
	background: white !important;
	color: ${props => props.theme.COLOR_SURVEY_BLUE} !important;
`
