import gql from 'graphql-tag'

export const GET_USER_RECORD_TEMPLATES = gql`
	query getUserRecordTemplates($pagination: Pagination, $filter: ListFilter) {
		userRecordTemplates(pagination: $pagination, filter: $filter) {
			totalCount
			perPage
			perPage
			items {
				id
				title
				createdAt
				description
			}
		}
	}
`
