import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Icon, Form, TextArea, Button } from 'semantic-ui-react'
import { useMutation } from 'react-apollo'
import { useHistory } from 'react-router-dom'
import useToggle from '../../../hooks/useToggle.ts'
import useInputReducer from '../../../hooks/useInputReducer'
import { GET_PATIENT_DETAIL_BY_ID } from '../../../graphql/queries/patient'
import useToastMessage from '../../../hooks/useToastMessage'
import { DROP_RESEARCH_PATIENT } from '../../../graphql/mutations/patient'
import useResearchTemplates from '../../../hooks/useResearchTemplates'
import { TemplateJob } from '../../../enum'
import { ADD_RECORD } from '../../../graphql/mutations/record'
import { getTodayString } from '../../../libs/dateHelper'
import { replaceParams } from '../../../libs/routeHelper'
import { ROUTE_PROJECT_DETAIL_DATA_DETAIL_DEFAULT } from '../../../constants/routes'

// 연구 대상자 드롭 처리
const ResearchPatientDropModal = ({
	isOpen,
	onClose,
	patientId,
	researchId,
	onUpdate,
	patientResearchCode,
	patientName,
}) => {
	const history = useHistory()
	const { loading: isLoadingTemplates, templates = [] } = useResearchTemplates(researchId)
	const finishTemplate = templates.find(
		t => t.isPartial !== true && t.job === TemplateJob.FINISH.value
	)

	const [dropModalLoading, toggleDropModalLoading] = useToggle(false)
	const [state, onChange] = useInputReducer({
		dropModalReason: '',
	})
	const { dropModalReason } = state
	const onShowToastMessage = useToastMessage()
	const [onCreateFinishRecord] = useMutation(ADD_RECORD, {
		variables: {
			patientId,
			researchId,
			isUsingLastRecord: false,
			visitedDate: getTodayString(),
		},
		refetchQueries: [
			{
				query: GET_PATIENT_DETAIL_BY_ID,
				variables: {
					id: patientId,
					researchId: researchId,
				},
			},
		],
		onCompleted: ({ addRecord: { id: recordId } }) => {
			onUpdate()
			history.push(
				replaceParams(ROUTE_PROJECT_DETAIL_DATA_DETAIL_DEFAULT, {
					id: researchId,
					subId: patientId,
					recordId,
				})
			)
		},
	})

	const [onDropPatient] = useMutation(DROP_RESEARCH_PATIENT, {
		onCompleted: () => {
			toggleDropModalLoading(false)
			onClose()
			onChange(undefined, { name: 'dropModalReason', value: '' })
			onShowToastMessage({
				id: `ALERT_DROP_PATIENT-${patientResearchCode}`,
				content: `연구대상자 ${patientResearchCode}(${patientName}) 님이 참여 중단(Drop) 처리되었습니다.`,
				success: true,
				error: false,
			})

			if (finishTemplate == null) {
				onUpdate()
			} else {
				onCreateFinishRecord()
			}
		},
	})

	const handleSubmitDropModal = async () => {
		toggleDropModalLoading(false)

		try {
			await onDropPatient({
				variables: {
					patientId,
					researchId,
					reason: dropModalReason,
				},

				refetchQueries: [
					{
						query: GET_PATIENT_DETAIL_BY_ID,
						variables: {
							id: patientId,
							researchId: researchId,
						},
					},
				],
			})
		} catch (e) {
			alert('오류가 발생했습니다.')
			toggleDropModalLoading(false)
		}
	}

	return (
		<Modal size="small" open={isOpen} onClose={onClose}>
			<Modal.Header>
				<Icon name="minus circle" color="red" />
				&nbsp; 대상자 탈락 처리 (Drop Subject)
			</Modal.Header>
			<Modal.Content>
				<div style={{ fontSize: 16, textAlign: 'center', marginBottom: 12 }}>
					<b>{`${patientResearchCode}(${patientName})`}</b> 님의 프로젝트 참여를 중단합니다.
				</div>
				<Form>
					<Form.Field
						control={TextArea}
						value={dropModalReason}
						name="dropModalReason"
						onChange={onChange}
						placeholder="참여 중단 사유를 적어주세요."
					/>
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={onClose}>취소</Button>
				{isLoadingTemplates !== true && (
					<Button loading={dropModalLoading} color="red" onClick={handleSubmitDropModal}>
						중단
					</Button>
				)}
			</Modal.Actions>
		</Modal>
	)
}

ResearchPatientDropModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	patientId: PropTypes.number.isRequired,
	researchId: PropTypes.number.isRequired,
	onUpdate: PropTypes.func.isRequired,
	patientResearchCode: PropTypes.string.isRequired,
	patientName: PropTypes.string,
}

ResearchPatientDropModal.defaultValues = {
	patientName: '익명',
}

export default ResearchPatientDropModal
