import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Input, Button } from 'semantic-ui-react'

/**
 * 양식 저장 모달
 */
const TemplateSaveModal = ({ open, onClose, onChangeTitle, onSave }) => {
	return (
		<Modal size="tiny" open={open} onClose={onClose}>
			<Modal.Header>새로운 템플릿 저장</Modal.Header>
			<Modal.Content>
				<Input
					style={{ width: '100%' }}
					placeholder="템플릿 제목을 입력하세요!"
					onChange={onChangeTitle}
				/>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={onClose}>취소</Button>
				<Button color="green" onClick={onSave}>
					저장
				</Button>
			</Modal.Actions>
		</Modal>
	)
}

TemplateSaveModal.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onChangeTitle: PropTypes.func.isRequired,
}

export default TemplateSaveModal
