import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form, Grid, Button } from 'semantic-ui-react'
import moment from 'moment'
import { useMutation, useApolloClient } from 'react-apollo'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import useStateInput from '../../common/form/useStateInput'
import DateInput from '../../common/basic/DateInput'
import {
	ResearchCohortType,
	ResearchSamplingPlan,
	ResearchInstitutionInvolve,
	ResearchType,
	ResearchTemplateType,
	ResearchScreeningOptions,
} from '../../../enum'
import ResearchNewFormOption from './ResearchNewFormOption'
import useStateWithProp from '../../common/form/useStateWithProp'
import ShowMore from '../../common/basic/ShowMore'
import { SubmitButtonBox } from '../../common/basic/StyledBox'
import { CREATE_RESEARCH } from '../../../graphql/mutations/research'
import { replaceParams } from '../../../libs/routeHelper'
import { ROUTE_PROJECT_DETAIL_DEFAULT } from '../../../constants/routes'
import {
	GET_USER_ACTIVE_RESEARCHES,
	GET_USER_ACTIVE_RESEARCHES_FOR_LIST,
} from '../../../graphql/queries/research'
import useToastMessage from '../../../hooks/useToastMessage'
import { ALERT_BASIC_ERROR, ALERT_RESEARCH_LIMIT_EXCEED_ERROR } from '../../../constants/alerts'
import { validateFields } from '../../../libs/validationHelper'
import TextInputWithError from '../../common/basic/TextInputWithError'
import { SET_RESEARCH_SIDE_MENU } from '../../../graphql/mutations/app'
import { matchErrorCode } from '../../../libs/errorHelper'
import errorTypes from '../../../errorTypes'
import useUpdateResearchSideMenu from '../../../hooks/userUpdateResearchSideMenu'

const FIELD_VALIDATION = {
	title: {
		required: true,
		length: {
			min: 4,
			max: 30,
		},
	},
	description: {
		required: false,
	},
	startDate: {
		required: true,
	},
	endDate: {
		required: true,
	},
	samplingPlan: {
		required: true,
	},
	institutionInvolve: {
		required: true,
	},
	templateType: {
		required: true,
	},
	cohortType: {
		required: true,
	},
	hasScreening: {
		required: true,
	},
}

/**
 * 연구 생성시 정보 입력 양식
 */
const ResearchNewForm = ({
	name,
	description: templateDesc,
	type: typeProp,
	cohortType: cohortTypeProp,
	samplingPlan: samplingPlanProp,
	templateType: templateTypeProp,
	requiresAgreement,
	agreementCheckType,
	isOfficial,
	caseType,
	purpose,
	hasScreening: hasScreeningProp,
	institutionInvolve: institutionInvolveProp,
	isFree,
	hashPatient,
}) => {
	const [shouldShowDetail, setShowDetail] = useState(false)

	const [title, setTitle] = useStateInput('')
	const [description, setDescription] = useStateInput('')
	const [estSubjects, setEstSubjects] = useStateInput(null)
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)

	const [cohortType, setCohortType] = useStateWithProp(cohortTypeProp)
	const [samplingPlan, setSamplingPlan] = useStateWithProp(samplingPlanProp)
	const [institutionInvolve, setInstitutionInvolve] = useStateWithProp(institutionInvolveProp)
	const [type, setType] = useStateWithProp(typeProp)
	const [templateType, setTemplateType] = useStateWithProp(templateTypeProp)
	const [hasScreening, setHasScreening] = useStateWithProp(hasScreeningProp)

	const showToastMessage = useToastMessage()
	const updateResearchSideMenu = useUpdateResearchSideMenu()
	const isTimeSeries = samplingPlan === ResearchSamplingPlan.TIME_SERIES.value

	const history = useHistory()

	const [isLoading, setLoading] = useState(false)
	const [errors, setErrors] = useState({})

	useEffect(() => {
		setShowDetail(false)
	}, [name])

	useEffect(() => {
		if (isFree === true) {
			setShowDetail(true)
		}
	}, [isFree])

	const [createResearch] = useMutation(CREATE_RESEARCH, {
		variables: {
			title,
			description,
			startDate,
			endDate,
			cohortType,
			type,
			samplingPlan,
			institutionInvolve,
			templateType,
			hasScreening,
			requiresAgreement,
			agreementCheckType,
			isOfficial,
			caseType,
			purpose,
			hashPatient,
		},
		onCompleted: async ({ createResearch: { id } }) => {
			setLoading(false)
			await updateResearchSideMenu(id, true, history)
		},
		onError: e => {
			if (matchErrorCode(e, errorTypes.RESEARCH_LIMIT_EXCEED_ERROR)) {
				showToastMessage(ALERT_RESEARCH_LIMIT_EXCEED_ERROR)
			} else {
				showToastMessage(ALERT_BASIC_ERROR)
			}
			setLoading(false)
		},
	})

	const onSubmit = useCallback(() => {
		setLoading(true)
		const validationErrors = validateFields(
			{
				title,
				description,
				startDate,
				endDate,
				cohortType,
				type,
				samplingPlan,
				institutionInvolve,
				templateType,
				hasScreening,
				requiresAgreement,
				agreementCheckType,
				isOfficial,
				caseType,
				purpose,
			},
			FIELD_VALIDATION
		)

		if (_.isEmpty(validationErrors) === true) {
			createResearch()
		} else {
			setErrors(validationErrors)
			setLoading(false)
		}
	}, [
		agreementCheckType,
		caseType,
		cohortType,
		createResearch,
		description,
		endDate,
		hasScreening,
		institutionInvolve,
		isOfficial,
		purpose,
		requiresAgreement,
		samplingPlan,
		startDate,
		templateType,
		title,
		type,
	])

	return (
		<Form id="new-research-form">
			<h6>{name}</h6>
			<div>{templateDesc}</div>
			<FormFields>
				<Options>
					<Grid columns={2}>
						<Grid.Column>
							<ResearchNewFormOption
								enums={ResearchCohortType}
								label="데이터 수집 방식"
								value={cohortType}
								onChange={setCohortType}
								error={errors.cohortType}
							/>
						</Grid.Column>
						<Grid.Column>
							<ResearchNewFormOption
								enums={ResearchInstitutionInvolve}
								label="참여 기관"
								value={institutionInvolve}
								onChange={setInstitutionInvolve}
								error={errors.institutionInvolve}
							/>
						</Grid.Column>
						<Grid.Column>
							<ResearchNewFormOption
								enums={ResearchSamplingPlan}
								label="기록 방식"
								value={samplingPlan}
								onChange={val => {
									setSamplingPlan(val)
									if (val === ResearchSamplingPlan.ONE_OFF.value) {
										setType(ResearchType.GATHERING.value)
									}
								}}
								error={errors.samplingPlan}
							/>
						</Grid.Column>
						<Grid.Column>
							{isTimeSeries && (
								<ResearchNewFormOption
									enums={ResearchType}
									label="일정 차수"
									value={type}
									onChange={setType}
									error={errors.type}
								/>
							)}
						</Grid.Column>
					</Grid>
					<ShowMore
						isOpen={shouldShowDetail}
						onToggle={() => setShowDetail(t => !t)}
						text="자세히 보기"
						height={100}
					>
						<Grid columns={2}>
							<Grid.Column>
								<ResearchNewFormOption
									enums={ResearchTemplateType}
									label="양식 설정"
									value={templateType}
									onChange={setTemplateType}
									error={errors.templateType}
								/>
							</Grid.Column>
							<Grid.Column>
								<ResearchNewFormOption
									enums={ResearchScreeningOptions}
									label="스크리닝 사용"
									value={hasScreening}
									onChange={setHasScreening}
									error={errors.hasScreening}
								/>
							</Grid.Column>
						</Grid>
					</ShowMore>
				</Options>
				<TextInputWithError
					error={errors.title}
					label="제목"
					placeholder="제목을 입력해 주세요."
					value={title}
					onChange={setTitle}
				/>
				<TextInputWithError
					error={errors.description}
					label="간단한 설명"
					placeholder="프로젝트에 대한 간단한 설명을 적어주세요."
					value={description}
					onChange={setDescription}
				/>
				<TextInputWithError
					error={errors.estSubjects}
					label="목표 대상자 수"
					value={estSubjects}
					onChange={setEstSubjects}
					placeholder="목표 대상자 수를 입력해 주세요. 없을 경우 입력하지 않으셔도 됩니다."
					type="number"
				/>
				<Form.Group>
					<Form.Field inline>
						<label>시작일</label>
						<DateInput
							error={errors.startDate}
							selected={startDate}
							onChange={item => setStartDate(moment(item))}
						/>
					</Form.Field>
					<Form.Field inline>
						<label>종료일</label>
						<DateInput
							error={errors.startDate}
							selected={endDate}
							onChange={item => setEndDate(moment(item))}
						/>
					</Form.Field>
				</Form.Group>
			</FormFields>
			<SubmitButtonBox>
				<Button size="large" loading={isLoading} primary onClick={onSubmit}>
					프로젝트 생성
				</Button>
			</SubmitButtonBox>
		</Form>
	)
}

ResearchNewForm.propTypes = {
	name: PropTypes.string,
	description: PropTypes.string,
	type: PropTypes.string,
	templateType: PropTypes.string,
	requiresAgreement: PropTypes.bool,
	agreementCheckType: PropTypes.string,
	cohortType: PropTypes.string,
	samplingPlan: PropTypes.string,
	institutionInvolve: PropTypes.string,
	hasScreening: PropTypes.bool,
	caseType: PropTypes.string,
	purpose: PropTypes.string,
	isOfficial: PropTypes.bool,
	isFree: PropTypes.bool,
	hashPatient: PropTypes.bool,
}

export default ResearchNewForm

const FormFields = styled.div`
	margin-top: 30px;
`
const Options = styled.div`
	margin-bottom: 30px;
`
