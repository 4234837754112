import React from 'react'
import styled from 'styled-components'
import { Progress } from 'semantic-ui-react'

const ProgressBar = ({ descLeft, descRight, data }) => {
	return (
		<Container>
			<Desc>
				<DescLeft>{descLeft}</DescLeft>
				<DescRight>
					<DescRightInner>{descRight}</DescRightInner>
				</DescRight>
			</Desc>

			{data.map(d => (
				<Progress key={d.key} {...d} />
			))}
			{/* <Progress {...props} /> */}
		</Container>
	)
}

export default ProgressBar

const Container = styled.div`
	padding: 20px;
`

const Desc = styled.div`
	display: flex;
	padding-bottom: 20px;
`
const DescLeft = styled.div`
	width: 50%;
`

const DescRight = styled.div`
	text-align: right;
	flex: 1;
`

const DescRightInner = styled.div`
	margin-left: auto;
`
