import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Input } from 'semantic-ui-react'
import useCheckbox from './useCheckbox'
import { StyledCheckbox } from './Styled'

/**
 * Data Field Checkbox
 *
 * 체크박스 타입의 개별 체크박스 처리용 컴퍼넌트
 */
const DataFieldCheckbox = ({
	value,
	readOnly,
	onUpdate,
	disabled,
	checked,
	freeWriting,
	label,
	hasText,
	isUsingNewLine,
	onUpdateFreeWriting: onUpdateFreeWritingProp,
}) => {
	const [onChange, stateFreeWriting, onChangeFreeWriting, onUpdateFreeWriting] = useCheckbox(
		onUpdate,
		onUpdateFreeWritingProp,
		value,
		checked,
		freeWriting
	)

	return (
		<span>
			<StyledCheckbox
				readOnly={readOnly}
				disabled={disabled}
				isUsingNewLine={isUsingNewLine}
				label={label}
				value={value}
				checked={checked}
				onChange={onChange}
			/>
			{hasText && checked && (
				<FreeInput
					readOnly={readOnly}
					disabled={disabled}
					labelPosition="left"
					onChange={onChangeFreeWriting}
					onBlur={onUpdateFreeWriting}
					value={stateFreeWriting}
				/>
			)}
		</span>
	)
}

DataFieldCheckbox.propTypes = {
	value: PropTypes.string,
	onUpdate: PropTypes.func,
	readOnly: PropTypes.bool,
	checked: PropTypes.bool,
	freeWriting: PropTypes.string,
	label: PropTypes.string,
	hasText: PropTypes.bool,
	isUsingNewLine: PropTypes.bool,
	disabled: PropTypes.bool,
	onUpdateFreeWriting: PropTypes.func,
}

export default DataFieldCheckbox

const FreeInput = styled(Input)`
	padding: 5px;
	width: 213px;
`
