import React from 'react'
import styled from 'styled-components'
import { Placeholder as SemanticPlaceholder } from 'semantic-ui-react'
import PropTypes from 'prop-types'

/**
 * 배경색 placeholder
 */
const Placeholder = ({ backgroundColor, children, ...props }) => {
	return (
		<PlaceholderWrapper backgroundColor={backgroundColor} {...props}>
			<SemanticPlaceholder>{children}</SemanticPlaceholder>
		</PlaceholderWrapper>
	)
}

Placeholder.propTypes = {
	backgroundColor: PropTypes.string,
	children: PropTypes.arrayOf(PropTypes.element).isRequired,
}

Placeholder.defaultProps = {
	backgroundColor: '#fff',
}

export default Placeholder

const PlaceholderWrapper = styled.div`
	.ui.placeholder, 
	.ui.placeholder >: before, 
	.ui.placeholder .image.header: after,
	.ui.placeholder .line, 
	.ui.placeholder .line: after {
		background-color: ${props => props.backgroundColor};
	}
`
