import { useApolloClient, useMutation } from 'react-apollo'
import { GET_USER_ACTIVE_RESEARCHES_FOR_LIST } from '../graphql/queries/research'
import { SET_RESEARCH_SIDE_MENU } from '../graphql/mutations/app'
import { ROUTE_PROJECT_DETAIL_DEFAULT } from '../constants/routes'
import { replaceParams } from '../libs/routeHelper'

/**
 * 유저 현재 연구 목록 불러오는 Hooks
 */
const useUpdateResearchSideMenu = () => {
	const client = useApolloClient()
	const [setResearchSideMenu] = useMutation(SET_RESEARCH_SIDE_MENU)

	return async (id, shouldNavigate, history) => {
		const { data: { userResearches } = {} } = await client.query({
			query: GET_USER_ACTIVE_RESEARCHES_FOR_LIST,
			fetchPolicy: 'network-only',
		})

		let researches
		if (userResearches.activeResearches == null) return

		researches = userResearches.activeResearches

		await setResearchSideMenu({
			variables: {
				researches,
				currentId: id,
			},
			update: () => {
				if (shouldNavigate === true && history != null) {
					history.push(
						replaceParams(ROUTE_PROJECT_DETAIL_DEFAULT, {
							id,
						})
					)
				}
			},
		})
	}
}

export default useUpdateResearchSideMenu
