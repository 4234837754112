import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import parseHtml from 'react-html-parser'
import { Icon, Checkbox } from 'semantic-ui-react'
import { COLOR_TEXT_LIGHTGRAY } from '../../../constants/styles'

/**
 * 동의서 확인
 */
const PatientAgreementCheck = ({ hasAgreed, onChangeAgreed, hasCheckbox, message }) => {
	return (
		<Warning
			onClick={() => {
				if (hasCheckbox === true) onChangeAgreed(i => !i)
			}}
		>
			{hasCheckbox === true ? <Checkbox checked={hasAgreed} /> : <Icon name="warning circle" />}
			<WarningText>{parseHtml(message)}</WarningText>
		</Warning>
	)
}

PatientAgreementCheck.propTypes = {
	hasAgreed: PropTypes.bool,
	onChangeAgreed: PropTypes.func,
	message: PropTypes.string,
	hasCheckbox: PropTypes.bool,
}

export default PatientAgreementCheck

const Warning = styled.div`
	font-size: 14px;
	line-height: 15px;
	align-items: center;
	display: flex;
	cursor: pointer;

	:hover .ui.checkbox .box::before,
	:hover .ui.checkbox label::before {
		box-shadow: 0px 0px 0px 6px rgba(0, 0, 0, 0.03);
	}

	:hover {
		color: ${COLOR_TEXT_LIGHTGRAY};
	}
`

const WarningText = styled.div`
	margin-left: 20px;
`
