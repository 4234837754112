import React from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import { GET_PATIENT_STATS } from '../../../graphql/queries/patient'
import PatientStatView from '../../statistics/PatientStatView'

/**
 * 환자 상세 정보
 */
const PatientStats = props => {
	const { patientId } = props
	return (
		<Query query={GET_PATIENT_STATS} variables={{ patientId }}>
			{({ loading, refetch, error, data }) => {
				let result
				if (data != null) {
					;({ patientSymptomProgress: result } = data)
				}

				return (
					<PatientStatView
						{...props}
						loading={loading}
						refetch={refetch}
						result={result}
						error={error}
					/>
				)
			}}
		</Query>
	)
}

PatientStats.propTypes = {
	patientId: PropTypes.number.isRequired,
}

export default PatientStats
