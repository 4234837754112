import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { DragSource, DropTarget } from 'react-dnd'
import { Label } from 'semantic-ui-react'
import { DRAG_ITEM_TYPES } from '../../../constants'
import DataFieldEditDropdown from '../control/DataFieldEditDropdown'
import DataFieldDiv from './DataFieldDiv'
import { COLOR_TEXT_LIGHTERGRAY, COLOR_TEXT } from '../../../constants/styles'

const makeTargetInfo = props => {
	return {
		columnIndex: props.columnIndex,
		medInfoTypeId: props.type.id,
	}
}

const dataFieldTarget = {
	drop(props, monitor) {
		if (monitor.isOver() === true) {
			return makeTargetInfo(props)
		}
	},
}

function collectTarget(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver({ shallow: true }),
		droppedItem: monitor.getItem(),
	}
}

const dataFieldSource = {
	beginDrag(props) {
		return {
			columnIndex: props.columnIndex,
			medInfoTypeId: props.type.id,
		}
	},
	canDrag(props) {
		return props.templateMode === true
	},
	endDrag(props, monitor) {
		if (!monitor.didDrop()) {
			return
		}

		const source = monitor.getItem()
		const target = monitor.getDropResult()

		if (source.medInfoTypeId === target.medInfoTypeId) {
			return
		}

		props.onChangeColumnOrder(source, target)
	},
}

function collectSource(connect, monitor) {
	return {
		connectDragSource: connect.dragSource(),
		connectDragPreview: connect.dragPreview(),
		isDragging: monitor.isDragging(),
	}
}

/**
 * 차트 테이블 헤더 개별 셀
 */
const DataFieldGridHeadCell = props => {
	const {
		type,
		disabled,
		templateMode,
		onClickEditMenu,
		connectDropTarget,
		connectDragSource,
	} = props
	const wrap = templateMode === true ? c => connectDropTarget(connectDragSource(c)) : c => c
	const elemId = `t-${type.id}`
	return wrap(
		<div>
			<Container noHover={disabled} medInfoId={elemId}>
				<Title disabled={disabled}>{type.name}</Title>
				{templateMode === true ? (
					<DataFieldEditDropdown
						medInfoId={elemId}
						style={{
							marginLeft: 10,
							verticalAlign: 'middle',
							padding: '0px 10px',
							position: 'absolute',
							right: 5,
						}}
						noCopy
						onClickMenu={onClickEditMenu(type)}
					/>
				) : null}
			</Container>
		</div>
	)
}

DataFieldGridHeadCell.propTypes = {
	type: PropTypes.object,
	templateMode: PropTypes.bool,
	onClickEditMenu: PropTypes.func,
}

export default DropTarget(DRAG_ITEM_TYPES.TABLE_COLUMN, dataFieldTarget, collectTarget)(
	DragSource(DRAG_ITEM_TYPES.TABLE_COLUMN, dataFieldSource, collectSource)(DataFieldGridHeadCell)
)

const Container = styled(DataFieldDiv)`
	line-height: 13px;
	font-size: 13px;
	min-height: 40px;
	padding-top: 12px;
	padding-bottom: 12px;
`

const Title = styled.span`
	vertical-align: middle;

	color: ${props => (props.disabled === true ? COLOR_TEXT_LIGHTERGRAY : COLOR_TEXT)};
`
