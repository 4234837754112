import moment from 'moment'
import { GRID_ADD_ROWS_SIMPLE } from './graphql/queries/library'

export const UISize = Object.freeze({
	MINI: 'mini',
	TINY: 'tiny',
	SMALL: 'small',
	LARGE: 'large',
	BIG: 'big',
	HUGE: 'huge',
	MASSIVE: 'massive',
})

export const MENU_ITEM_TYPES = Object.freeze({
	IMAGE_ICON: 'image-icon',
	IMAGE_ICON_ONLY: 'image-icon-only',
	TEXT: 'text',
})

export const RecordType = {
	CHARTING: {
		key: 'CHARTING',
		value: 'CHARTING',
		icon: 'clipboard outline',
		color: 'blue',
		text: '차팅',
	},
	DROP: {
		key: 'DROP',
		value: 'DROP',
		icon: 'minus circle',
		color: 'red',
		text: '중단',
	},
}

export const TemplateJob = {
	CHARTING: {
		key: 'CHARTING',
		value: 'CHARTING',
		text: '기록',
		description: '환자 데이터 입력을 위한 양식입니다.',
	},
	SCREENING: {
		key: 'SCREENING',
		value: 'SCREENING',
		text: '스크리닝',
		description: '환자 스크리닝을 위한 양식입니다.',
	},
	SURVEY: {
		key: 'SURVEY',
		value: 'SURVEY',
		text: '설문조사',
	},
	ECRF: {
		key: 'ECRF',
		value: 'ECRF',
		text: '양식(eCRF)',
	},
	FINISH: {
		key: 'FINISH',
		value: 'FINISH',
		text: '종료',
	},
	// FREEZE: {
	//   key: 'FREEZE',
	//   icon: 'snowflake',
	//   color: 'blue',
	//   text: '확정'
	// },
}

export const RecordChangeType = {
	INIT: {
		key: 'INIT',
		value: 'INIT',
		text: '생성',
		color: 'green',
	},
	UPDATE: {
		key: 'UPDATE',
		value: 'UPDATE',
		text: '수정',
		color: 'blue',
	},
	ROLLBACK: {
		key: 'ROLLBACK',
		value: 'ROLLBACK',
		text: '되돌림',
		color: 'yellow',
	},
	REQUEST: {
		key: 'REQUEST',
		value: 'REQUEST',
		text: '수정 요청',
		color: 'purple',
	},
	FREEZE: {
		key: 'FREEZE',
		value: 'FREEZE',
		text: '동결',
		color: 'teal',
	},
}

export const RecordChangeStatus = {
	ACCEPTED: {
		key: 'ACCEPTED',
		text: '적용',
		color: 'green',
		icon: 'check',
	},
	DENIED: {
		key: 'DENIED',
		text: '거부',
		color: 'red',
		icon: 'close',
	},
	WAITING: {
		key: 'WAITING',
		text: '대기',
		color: 'yellow',
		icon: 'exclamation',
	},
}

export const InputTypeCategory = {
	LAYOUT: {
		value: 'LAYOUT',
		text: '레이아웃',
	},
	INPUT: {
		value: 'INPUT',
		text: '입력',
	},
	SEARCH: {
		value: 'SEARCH',
		text: '검색',
	},
	SELECT: {
		value: 'SELECT',
		text: '선택하기',
	},
	MEDIA: {
		value: 'MEDIA',
		text: '미디어',
	},
	DISPLAY: {
		value: 'DISPLAY',
		text: '보여주기',
	},
}

export const InputType = {
	SHORT_FREE_TEXT: {
		key: 'SHORT_FREE_TEXT',
		value: 'SHORT_FREE_TEXT',
		text: '글자입력',
		hasPlaceholder: true,
		vasScale: true,
		options: true,
		isOnPatientSurvey: true,
		icon: 'textbox.png',
		category: InputTypeCategory.INPUT.value,
		isGridColumn: true,
	},
	LONG_FREE_TEXT: {
		key: 'LONG_FREE_TEXT',
		value: 'LONG_FREE_TEXT',
		text: '문단입력',
		hasPlaceholder: true,
		isOnPatientSurvey: true,
		icon: 'textarea.png',
		category: InputTypeCategory.INPUT.value,
		isGridColumn: true,
	},
	NUMBER: {
		key: 'NUMBER',
		value: 'NUMBER',
		text: '숫자',
		hasPlaceholder: true,
		isOnPatientSurvey: true,
		units: true,
		icon: 'number-input.png',
		category: InputTypeCategory.INPUT.value,
		isGridColumn: true,
	},
	DATE: {
		key: 'DATE',
		value: 'DATE',
		text: '날짜/시간',
		hasPlaceholder: true,
		isOnPatientSurvey: true,
		pastDate: true,
		options: true,
		timeOnly: true,
		selectTime: true,
		icon: 'calendar.png',
		category: InputTypeCategory.INPUT.value,
		isGridColumn: true,
	},
	SEARCH_TEXT: {
		key: 'SEARCH_TEXT',
		value: 'SEARCH_TEXT',
		text: '자동완성',
		hasPlaceholder: true,
		search: true,
		icon: 'search-input.png',
		category: InputTypeCategory.SEARCH.value,
		isGridColumn: true,
	},
	SEARCH_SELECT_MULTI: {
		key: 'SEARCH_SELECT_MULTI',
		value: 'SEARCH_SELECT_MULTI',
		text: '검색(다수 선택)',
		hasPlaceholder: true,
		search: true,
		icon: 'search-many-input.png',
		category: InputTypeCategory.SEARCH.value,
		isGridColumn: true,
	},
	VAS_ONLY: {
		key: 'VAS_ONLY',
		value: 'VAS_ONLY',
		text: '슬라이더',
		hasPlaceholder: false,
		isOnPatientSurvey: true,
		icon: 'slider.png',
		category: InputTypeCategory.INPUT.value,
		isGridColumn: true,
	},
	SELECT: {
		key: 'SELECT',
		value: 'SELECT',
		text: '콤보박스',
		hasPlaceholder: true,
		isOnPatientSurvey: true,
		select: true,
		icon: 'dropdown.png',
		category: InputTypeCategory.SELECT.value,
		isGridColumn: true,
	},
	RADIO: {
		key: 'RADIO',
		value: 'RADIO',
		text: '단일선택',
		hasPlaceholder: false,
		isOnPatientSurvey: true,
		select: true,
		newline: true,
		icon: 'radio.png',
		category: InputTypeCategory.SELECT.value,
		isGridColumn: true,
	},
	CHECKBOX: {
		key: 'CHECKBOX',
		value: 'CHECKBOX',
		text: '체크박스',
		hasPlaceholder: false,
		isOnPatientSurvey: true,
		select: true,
		newline: true,
		icon: 'checkbox.png',
		category: InputTypeCategory.SELECT.value,
		isGridColumn: true,
	},
	NO_EDIT_STRING: {
		key: 'NO_EDIT_STRING',
		value: 'NO_EDIT_STRING',
		text: '텍스트',
		hasPlaceholder: false,
		isNotInput: true,
		icon: 'text.png',
		category: InputTypeCategory.DISPLAY.value,
		isGridColumn: true,
	},
	TOGGLE: {
		key: 'TOGGLE',
		value: 'TOGGLE',
		text: '스위치',
		hasPlaceholder: false,
		icon: 'toggle.png',
		notSelectable: true,
	},
	SECTION: {
		key: 'SECTION',
		value: 'SECTION',
		text: '섹션',
		hasChild: true,
		hasPlaceholder: false,
		isNotInput: true,
		isOnPatientSurvey: true,
		isLayout: true,
		icon: 'section.png',
		category: InputTypeCategory.LAYOUT.value,
	},
	BLOCK: {
		key: 'BLOCK',
		value: 'BLOCK',
		text: '블록',
		hasChild: true,
		isGroup: true,
		hasPlaceholder: false,
		isNotInput: true,
		isLayout: true,
		icon: 'block.png',
		category: InputTypeCategory.LAYOUT.value,
		isOnPatientSurvey: true,
	},
	GRID: {
		key: 'GRID',
		value: 'GRID',
		text: '표',
		hasChild: true,
		isGroup: true,
		hasPlaceholder: false,
		grid: true,
		isNotInput: true,
		icon: 'grid.png',
		category: InputTypeCategory.LAYOUT.value,
	},
	GRID_ROW: {
		key: 'GRID_ROW',
		value: 'GRID_ROW',
		text: '그리드 행',
		hasChild: true,
		hasPlaceholder: true,
		isNotInput: true,
		notSelectable: true,
	},
	GRID_INDI_COLUMN: {
		key: 'GRID_INDI_COLUMN',
		value: 'GRID_INDI_COLUMN',
		text: '그리드 열(독립 필드)',
		hasChild: false,
		isGroup: false,
		hasPlaceholder: false,
		isNotInput: true,
		icon: 'single-column.png',
		notSelectable: true,
		isGridColumn: true,
	},
	IMAGE: {
		key: 'IMAGE',
		value: 'IMAGE',
		text: '사진',
		icon: 'image.png',
		category: InputTypeCategory.MEDIA.value,
	},
	// VIDEO: {
	// 	key: 'VIDEO',
	// 	value: 'VIDEO',
	// 	text: '비디오',
	// 	icon: 'video.png',
	// 	category: InputTypeCategory.MEDIA.value,
	// },
}

export const DataFieldFormat = {
	FIXED: {
		key: 'fixed',
		value: '고정',
	},
	LABELED: {
		key: 'labeled',
		value: '라벨',
	},
	COMPACT: {
		key: 'compact',
		value: '내용에 맞춤',
	},
	NEWLINE: {
		key: 'newline',
		value: '새 라인',
	},
	SUM: {
		key: 'sum',
		value: '합계',
	},
	SCORE: {
		key: 'score',
		value: '점수 계산',
	},
}

export const SectionSize = {
	SMALL: {
		value: 'SMALL',
		text: '33 %',
		widescreen: 4,
		largeScreen: 8,
		computer: 8,
		tablet: 8,
		mobile: 16,
	},
	MEDIUM: {
		value: 'MEDIUM',
		text: '50 %',
		widescreen: 8,
		largeScreen: 8,
		computer: 16,
		tablet: 16,
		mobile: 16,
	},
	LARGE: {
		value: 'LARGE',
		text: '100 %',
		widescreen: 16,
		largeScreen: 16,
		computer: 16,
		tablet: 16,
		mobile: 16,
	},
}

export const ResearchSubjectStatus = {
	ACTIVE: {
		key: 'ACTIVE',
		value: 'ACTIVE',
		text: '참여중',
		color: 'green',
		selectable: true,
	},
	DROPPED: {
		key: 'DROPPED',
		value: 'DROPPED',
		text: '탈락',
		color: 'red',
		selectable: true,
	},
	SCREEN_WAIT: {
		key: 'SCREEN_WAIT',
		value: 'SCREEN_WAIT',
		text: '준비중',
		color: 'yellow',
		textColor: 'grey',
		selectable: false,
	},
	SCREEN_FAIL: {
		key: 'SCREEN_FAIL',
		value: 'SCREEN_FAIL',
		text: '부적합',
		color: 'red',
		selectable: false,
	},
}

export const InputSize = {
	SMALL: {
		value: 'SMALL',
		text: '33%',
		className: 'small',
		sectionSize: {
			SMALL: {
				widescreen: 16,
				largeScreen: 16,
				computer: 16,
				tablet: 16,
				mobile: 16,
			},
			MEDIUM: {
				largeScreen: 8,
				computer: 8,
				tablet: 8,
				mobile: 16,
			},
			LARGE: {
				widescreen: 5,
				largeScreen: 5,
				computer: 5,
				tablet: 8,
				mobile: 16,
			},
		},
		widthRatio: 1 / 3,
		cellRatio: 1,
		cellWidth: 80,
	},
	MEDIUM: {
		value: 'MEDIUM',
		text: '50%',
		className: 'medium',
		sectionSize: {
			SMALL: {
				width: 16,
			},
			MEDIUM: {
				width: 16,
			},
			LARGE: {
				widescreen: 8,
				largeScreen: 16,
				computer: 16,
			},
		},
		widthRatio: 1 / 2,
		cellRatio: 2,
		cellWidth: 150,
	},
	LARGE: {
		value: 'LARGE',
		text: '100%',
		className: 'large',
		sectionSize: {
			SMALL: {
				widescreen: 16,
				largeScreen: 16,
				computer: 16,
				tablet: 16,
				mobile: 16,
			},
			MEDIUM: {
				widescreen: 16,
				largeScreen: 16,
				computer: 16,
			},
			LARGE: {
				widescreen: 16,
				largeScreen: 16,
				computer: 16,
				tablet: 16,
				mobile: 16,
			},
		},
		widthRatio: 1,
		cellRatio: 3,
		cellWidth: 300,
	},
}

export const ResearchStatus = {
	ACTIVE: {
		key: 'ACTIVE',
		color: 'green',
		text: '진행중',
	},
	FINISHED: {
		key: 'ACTIVE',
		color: 'blue',
		text: '완료',
	},
	STOPPED: {
		key: 'ACTIVE',
		color: 'red',
		text: '중단',
	},
	PREPARING: {
		key: 'ACTIVE',
		color: 'yellow',
		text: '연구 준비중',
	},
	IRB_WAITING: {
		key: 'ACTIVE',
		color: 'brown',
		text: 'IRB 심사중',
	},
}

// text, value 필수로 들어가기
export const ResearcherStatus = {
	ACTIVE: {
		value: 'ACTIVE',
		color: 'green',
		text: '참여중',
	},
	INACTIVE: {
		value: 'INACTIVE',
		color: 'red',
		text: '일시 중지',
	},
	LEFT: {
		value: 'LEFT',
		color: 'yellow',
		text: '연구 하차',
	},
}

export const ResearchScope = {
	PUBLIC: {
		key: 'PUBLIC',
		value: 'PUBLIC',
		text: '공개',
	},
	PRIVATE: {
		key: 'PRIVATE',
		value: 'PRIVATE',
		text: '비공개',
	},
}

export const ResearcherRole = {
	DOCTOR: {
		key: 'DOCTOR',
		value: 'DOCTOR',
		text: '임상의',
		icon: 'user md',
		level: 1,
	},
	RESEARCHER: {
		key: 'RESEARCHER',
		value: 'RESEARCHER',
		text: '연구원',
		icon: 'user',
		level: 1,
	},
	MANAGER: {
		key: 'MANAGER',
		value: 'MANAGER',
		text: '연구관리자',
		icon: 'cogs',
		isManager: true,
		color: 'blue',
		level: 2,
	},
	DIRECTOR: {
		key: 'DIRECTOR',
		value: 'DIRECTOR',
		text: '연구책임자(PI)',
		icon: 'key',
		color: 'red',
		isManager: true,
		level: 3,
	},
}

export const ResearcherRoleStatus = {
	DOCTOR: {
		key: 'DOCTOR',
		value: 'DOCTOR',
		text: '임상의',
		icon: 'user md',
	},
	RESEARCHER: {
		key: 'RESEARCHER',
		value: 'RESEARCHER',
		text: '연구원',
		icon: 'user',
	},
	MANAGER: {
		key: 'MANAGER',
		value: 'MANAGER',
		text: '연구관리자',
		color: 'blue',
	},
	NOT_MANAGER: {
		key: 'DIRECTOR',
		value: 'DIRECTOR',
		text: '연구책임자(PI)',
		icon: 'key',
		isManager: true,
	},
}

export const ResearcherRight = {
	canAccess: {
		text: '데이터 열람',
	},
	canEdit: {
		text: '데이터 수정',
	},
	canFixTemplate: {
		text: '양식 설정',
	},
	canInput: {
		text: '데이터 입력',
	},
	canChangeSettings: {
		text: '프로젝트 관리',
	},
}

export const UserType = {
	DOCTOR: {
		key: 'DOCTOR',
		value: 'DOCTOR',
		text: '의료인',
		canSignUp: true,
	},
	RESEARCHER: {
		key: 'RESEARCHER',
		value: 'RESEARCHER',
		text: '연구자',
		canSignUp: true,
	},
	PATIENT: {
		key: 'PATIENT',
		value: 'PATIENT',
		text: '환자 사용',
		canSignUp: false,
	},
}

export const DoctorType = {
	DOCTOR: {
		key: 'DOCTOR',
		value: 'DOCTOR',
		text: '의사',
		category: 'WM',
	},
	TCM: {
		key: 'TCM',
		value: 'TCM',
		text: '한의사',
		category: 'KM',
		isTraditional: true,
	},
	DENTAL: {
		key: 'DENTAL',
		value: 'DENTAL',
		text: '치과의사',
		category: 'DM',
	},
}

export const CertType = {
	LICENSED: {
		key: 'LICENSED',
		value: 'LICENSED',
		text: '일반의',
	},
	BOARD_CERTIFIED: {
		key: 'BOARD_CERTIFIED',
		value: 'BOARD_CERTIFIED',
		text: '전문의',
	},
}

export const InstitutionType = {
	HOSPITAL: 'HOSPITAL',
	RESEARCH_INSTITUTE: 'RESEARCH_INSTITUTE',
}

export const TemplateType = {
	FIXED: {
		key: 'FIXED',
		value: 'FIXED',
		text: '고정',
	},
	FREE: {
		key: 'FREE',
		value: 'FREE',
		text: '자유',
	},
}

export const Operator = {
	EQ: {
		key: 'EQ',
		text: '같음',
		compare: (value1, value2) => value1 === value2,
	},
	NOT: {
		key: 'NOT',
		text: '참조값 아님',
		compare: (value1, value2) => value1 !== value2,
	},
	LT: {
		key: 'LT',
		text: '참조값보다 작음',
		compare: (value1, value2) => {
			return value1 != null && value2 != null && value1 != '' && value2 != '' && value1 < value2
		},
	},
	LTE: {
		key: 'LTE',
		text: '참조값보다 작거나 같음',
		compare: (value1, value2) => {
			if (value1 !== null) return value1 <= value2
		},
	},
	GT: {
		key: 'GT',
		text: '참조값보다 큼',
		compare: (value1, value2) => {
			return value1 != null && value2 != null && value1 != '' && value2 != '' && value1 > value2
		},
	},
	GTE: {
		key: 'GTE',
		text: '참조값보다 크거나 같음',
		compare: (value1, value2) => {
			return value1 != null && value2 != null && value1 != '' && value2 != '' && value1 >= value2
		},
	},
	HAS_VALUE: {
		key: 'HAS_VALUE',
		text: '값이 있음',
		compare: value1 => {
			return value1 !== null && value1 !== undefined && value1 !== ''
		},
	},
	EMPTY: {
		key: 'EMPTY',
		text: '값이 비어있음',
		compare: value1 => value1 === null || value1 === undefined || value1 === '',
	},
	DATE_MIN_DURATION: {
		key: 'DATE_MIN_DURATION',
		text: '현재로부터 참조값(일)보다 이전 날짜',
		compare: (value1, value2) => {
			return moment().diff(value1, 'days') > value2
		},
	},
	NOT_CHECKED: {
		key: 'NOT_CHECKED',
		text: '체크되지 않음',
		compare: (_, value, checkedItems) => {
			const idx = checkedItems.findIndex(item => item.value === value)
			return idx < 0
		},
	},
	CHECKED: {
		key: 'CHECKED',
		text: '체크됨',
		compare: (_, value, checkedItems) => {
			const idx = checkedItems.findIndex(item => item.value === value)
			return idx >= 0
		},
	},
	AS_IS: {
		key: 'AS_IS',
		text: '그대로',
		compare: () => true,
	},
}

export const ConditionAction = {
	ENABLE: {
		key: 'ENABLE',
		text: '필드 활성화',
		action: (condition, isActive) => child => {
			let disabled
			let found
			let hasOrs
			let hasMatchingOrs

			if (child.medInfoType.targetConditions) {
				child.medInfoType.targetConditions = child.medInfoType.targetConditions.map(cond => {
					if (cond.id === condition.id) {
						cond.isActive = isActive
						found = true
						if (cond.isRequired === false) {
							hasOrs = true
							if (isActive === true) hasMatchingOrs = true
						} else if (isActive === false) {
							disabled = true
						} else if (disabled === undefined) {
							disabled = false
						}
					} else if (cond.action === 'ENABLE') {
						if (cond.isRequired === false) {
							hasOrs = true
							if (cond.isActive === true) hasMatchingOrs = true
						} else if (cond.isActive === false) {
							disabled = true
						}
					}

					return cond
				})

				if (!found) {
					child.medInfoType.targetConditions.push({
						id: condition.id,
						action: 'ENABLE',
						isActive,
						isRequired: condition.isRequired,
					})

					if (condition.isRequired === false) {
						hasOrs = true
						if (isActive === true) hasMatchingOrs = true
					} else if (isActive === false) {
						disabled = true
					} else if (disabled === undefined) {
						disabled = false
					}
				}
			} else {
				child.medInfoType.targetConditions = [
					{
						id: condition.id,
						action: 'ENABLE',
						isActive,
						isRequired: condition.isRequired,
					},
				]

				disabled = !isActive
			}

			if (child.children) {
				child.children = child.children.map(child_ =>
					ConditionAction.ENABLE.action(condition, isActive)(child_)
				)
			}

			if (!disabled && hasOrs === true) {
				if (hasMatchingOrs === true) {
					disabled = false
				} else {
					disabled = true
				}
			}

			if (!disabled) disabled = false

			return {
				...child,
				disabled,
			}
		},
		actionProp: { disabled: false },
	},
	SET: {
		key: 'SET',
		text: '다른 필드 값 설정',
		action: (condition, value) => child => {
			const cond = child.medInfoType.conditions.find(item => item.action === 'LISTEN')
			if (cond == null) return child

			const numval = parseFloat(value)
			cond.values = { ...cond.values, [condition.value]: numval, _CURRENT: child.value }

			const hasReturn = cond.value.includes('return ')

			try {
				if (hasReturn === false) {
					const resultCalc = new Function(...Object.keys(cond.values), `return ${cond.value}`)

					return {
						...child,
						value: resultCalc(...Object.values(cond.values)).toString(),
					}
				}
				const resultCalc = new Function('values', cond.value)
				return {
					...child,
					value: resultCalc(cond.values).toString(),
				}
			} catch (e) {
				return child
			}
		},
	},
	LISTEN: {
		key: 'LISTEN',
		text: '다른 필드 값 참조하여 설정',
	},
	ADD_ROWS: {
		key: 'ADD_ROWS',
		text: '테이블 행 추가',
		action: (condition, client, generateMedInfo, entityId) => async child => {
			if (!entityId) return child

			const query = GRID_ADD_ROWS_SIMPLE

			const result = await client.query({
				query,
				variables: {
					id: entityId,
					entityType: condition.value,
				},
			})

			const rows = []
			const rows_ = result.data && result.data.libraryAddedRows
			const { childTypes } = child.medInfoType

			for (const row of rows_) {
				const row_ = await generateMedInfo(
					{
						entityId: row.entityId,
						medInfoType: childTypes.find(item => item.inputType === InputType.GRID_ROW.key),
					},
					child.id
				)

				const children = []
				for (const cell of row.cells) {
					const medInfoType = childTypes.find(item => item.typeName === cell.typeName)
					const child = await generateMedInfo(
						{
							value: cell.value,
							optionValue: cell.optionValue,
							entityId: cell.entityId,
							medInfoType,
						},
						row_.id
					)

					children.push(child)
				}

				row_.children = children
				rows.push(row_)
			}
			// const rows = await generateAddingRowData(condition.value, generateMedInfo, result, child)

			return {
				...child,
				children: rows,
			}
		},
	},

	DISABLE: {
		key: 'DISABLE',
		actionProp: {
			disabled: true,
			value: null,
			vasValue: null,
			checkedItems: [],
			optionValue: null,
		},
	},
	SCREEN_FAIL: {
		key: 'SCREEN_FAIL',
		text: '스크리닝 실패 처리',
	},
	ENABLE_BELOW_ALL: {
		key: 'ENABLE_BELOW_ALL',
		text: '아래의 모든 필드 활성화',
		actionProp: { disabled: false },
	},
	DISABLE_BELOW_ALL: {
		key: 'DISABLE_BELOW_ALL',
		actionProp: {
			disabled: true,
			value: null,
			vasValue: null,
			checkedItems: [],
			optionValue: null,
		},
	},
	ENABLE_BELOW_PARENT: {
		key: 'ENABLE_BELOW_PARENT',
		text: '아래의 모든 필드 활성화(부모 필드 내)',
		action: (condition, isActive) => child => {
			let disabled = !isActive
			if (child.medInfoType.targetConditions) {
				child.medInfoType.targetConditions = child.medInfoType.targetConditions.map(cond => {
					if (cond.id === condition.id) {
						cond.isActive = isActive
						if (isActive === false) disabled = true
					} else if (cond.action === 'ENABLE' && cond.isActive === false) {
						disabled = true
					}

					return cond
				})

				return {
					...child,
					disabled,
					...(disabled === true && {
						value: null,
						vasValue: null,
						checkedItems: [],
					}),
				}
			}
		},
		actionProp: { disabled: false },
	},
	DISABLE_BELOW_SECTION: {
		key: 'DISABLE_BELOW_SECTION',
		actionProp: {
			disabled: true,
			value: null,
			vasValue: null,
			checkedItems: [],
			optionValue: null,
		},
	},
	WARNING: {
		key: 'WARNING',
		text: '경고',
		action: (condition, isActive) => child => {
			if (isActive)
				return {
					...child,
					value: null,
				}

			return child
		},
	},
}

export const ConditionScope = {
	CURRENT_RECORD: {
		key: 'CURRENT_RECORD',
		text: '현재 기록 내 정보',
	},
	RECORD_INFO: {
		key: 'RECORD_INFO',
		text: '기록 관련 정보',
	},
	PATIENT_RESEARCH: {
		key: 'PATIENT_RESEARCH',
		text: '환자 연구대상자 정보',
	},
	RESEARCH_RECORDS: {
		key: 'RESEARCH_RECORDS',
		text: '환자 연구 기록 전체 내',
	},
	PATIENT_INFO: {
		key: 'PATIENT_INFO',
		text: '환자 정보',
	},
}

export const MedInfoValue = {
	value: {
		text: '값',
	},
	vasValue: {
		text: 'VAS 값',
	},
	optionValue: {
		text: '옵션값',
	},
}

export const TermType = {
	SERVICE: {
		value: 'SERVICE',
	},
	PRIVACY: {
		value: 'PRIVACY',
	},
	PRIVACY_POLICY: {
		value: 'PRIVACY_POLICY',
	},
}

export const ObjectType = {
	PATIENT: {
		value: 'PATIENT',
		text: '환자',
	},
	RECORD: {
		value: 'RECORD',
		text: '기록',
	},
	TEMPLATE: {
		value: 'TEMPLATE',
		text: '양식',
	},
}

export const MedInfoQueryStatus = {
	UNRESOLVED: {
		value: 'UNRESOLVED',
		color: 'yellow',
		text: '미해결',
	},
	RESOLVED: {
		value: 'RESOLVED',
		color: 'green',
		text: '해결됨',
	},
	DECLINED: {
		value: 'DECLINED',
		color: 'red',
		text: '거절됨',
	},
}

export const StatisticsRequestType = {
	/* ratio */
	PATIENT_GENDER_RATIO: {
		title: '환자 성비',
		type: 'PATIENT_GENDER_RATIO',
		callback: 'patientGenderRatio',
	},
	PATIENT_GENERATION_RATIO: {
		title: '환자 연령층',
		type: 'PATIENT_GENERATION_RATIO',
		callback: 'patientGenerationRatio',
	},
	PATIENT_SYMPTOM_TYPE_RATIO: {
		title: '증상 분류',
		type: 'PATIENT_SYMPTOM_TYPE_RATIO',
		callback: 'patientSymptomTypeRatio',
	},
	PATIENT_MED_INFO_RATIO: {
		type: 'PATIENT_MED_INFO_RATIO',
		mustInclude: ['medInfoTypeId'],
		callback: 'patientMedInfoRatio',
	},

	/* average */
	PATIENT_AVERAGE_AGE_SINGLE_VALUE: {
		title: '평균 연령',
		type: 'PATIENT_AVERAGE_AGE_SINGLE_VALUE',
		callback: 'patientAverageAge',
		floorDecimal: 1,
	},

	/* count */
	PAITENT_COUNT_SINGLE_VALUE: {
		title: '누적 케이스',
		type: 'PAITENT_COUNT_SINGLE_VALUE',
		callback: 'patientTotalCount',
	},

	RECORD_COUNT_SINGLE_VALUE: {
		title: '진료기록 수',
		type: 'RECORD_COUNT_SINGLE_VALUE',
		callback: 'recordTotalCount',
	},

	/* top */
	TREATMENT_TOP_TABLE_DATA: {
		type: 'TREATMENT_TOP_TABLE_DATA',
		callback: 'treatmentTop',
	},
	PRESCRIPTION_TOP_TABLE_DATA: {
		type: 'PRESCRIPTION_TOP_TABLE_DATA',
		callback: 'prescriptionTop',
	},
	ACUPUNCTURE_TOP_TABLE_DATA: {
		type: 'ACUPUNCTURE_TOP_TABLE_DATA',
		callback: 'acupunctureTop',
	},

	SYMPTOM_TOP_TABLE_DATA: {
		type: 'SYMPTOM_TOP_TABLE_DATA',
		callback: 'symptomTop',
	},
}

export const TableStatsKeys = {
	ITEM_NAME: 'value',
	ITEM_COUNT: 'count',
}

export const CommentEntityType = {
	RECORD: {
		value: 'RECORD',
	},
	PATIENT: {
		value: 'PATIENT',
	},
	ISSUE: {
		value: 'ISSUE',
	},
	MED_INFO_QUERY: {
		value: 'MED_INFO_QUERY',
	},
	CASE: {
		value: 'CASE',
	},
}

export const ScheduleStatus = {
	WAITING: {
		value: 'WAITING',
		text: '예정',
	},
	DONE: {
		value: 'DONE',
		text: '완료',
	},
	CANCELLED: {
		value: 'CANCELLED',
		text: '취소',
	},
	MISSED: {
		value: 'MISSED',
		text: '놓침',
	},
}

export const DataTypes = {
	DATE: 'DATE',
	STRING: 'STRING',
}

export const RecordCompleteStatus = {
	COMPLETE: {
		text: '입력 완료',
		color: 'green',
	},
	INCOMPLETE: {
		text: '입력 미완료',
		color: 'red',
	},
}

export const RecordUsingLatestTemplateStatus = {
	RECENT: {
		text: '최신 양식 사용',
		color: 'green',
	},
	NOT_RECENT: {
		text: '양식 업데이트 필요',
		color: 'red',
	},
}

export const ReservationDateStatus = {
	DONE: {
		value: 'DONE',
		text: '완료',
		color: 'green',
	},
	CANCELED: {
		value: 'CANCELED',
		text: '취소',
		color: 'red',
	},
	WAITING: {
		value: 'WAITING',
		text: '대기',
		color: 'yellow',
	},
	TODAY: {
		value: 'TODAY',
		text: '오늘',
		color: 'blue',
	},
	FUTURE: {
		value: 'FUTURE',
		text: '예정',
		color: 'yellow',
	},
	PAST: {
		value: 'PAST',
		text: '초과',
		color: 'red',
	},
	NEAR: {
		value: 'NEAR',
		text: '3일 내',
		color: 'blue',
		days: 4,
	},
}

export const NotificationEventEntityType = {
	ResearchInvitation: {
		value: 'ResearchInvitation',
		sentence: '프로젝트에 초대하였습니다.',
		entityKey: 'research',
	},
	Comment: {
		value: 'Comment',
		sentence: '의견을 남겼습니다.',
		entityKey: 'comment',
	},
	Issue: {
		value: 'Issue',
		sentence: '프로젝트에 초대하였습니다.',
	},
	MedInfoQuery: {
		value: 'MedInfoQuery',
		sentence: '수정 요청을 남겼습니다.',
	},
}

export const NotificationEntityType = {
	Record: {
		value: 'Record',
		sentence: '진료기록에 ',
		entityKey: 'research',
	},
	Issue: {
		value: 'Issue',
		sentence: '게시글에 ',
	},
	Patient: {
		value: 'Patient',
		sentence: '환자에 ',
	},
	MedInfoQuery: {
		value: 'MedInfoQuery',
		sentence: '수정요청에 ',
	},
	User: {
		value: 'User',
		sentence: '',
	},
	Comment: {
		value: 'Comment',
		sentence: '댓글에 ',
	},
	SimpleCase: {
		value: 'Case',
		sentence: '케이스에 ',
	},
}
export const NotificationReadStatus = {
	READ: {
		value: 'READ',
		text: '읽음',
	},
	UNREAD: {
		value: 'UNREAD',
		text: '안읽음',
	},
}

export const InvitationStatus = {
	INVITED: {
		value: 'INVITED',
		text: '초대요청',
	},
	ACCEPTED: {
		value: 'ACCEPTED',
		text: '수락함',
	},
	DECLINED: {
		value: 'DECLIEND',
		text: '거절함',
	},
}

export const ResearchType = {
	GATHERING: {
		key: 'GATHERING',
		value: 'GATHERING',
		text: '일정 없음',
		description: '별도의 고정된 일정이 없습니다.',
	},
	SCHEDULED: {
		key: 'SCHEDULED',
		value: 'SCHEDULED',
		text: '일정별 조사',
		description: '고정된 차수별 조사 일정이 존재합니다.',
	},
}

export const ResearchTemplateType = {
	FIXED: {
		key: 'FIXED',
		value: 'FIXED',
		text: '고정 양식 사용',
		description: '데이터 입력자가 양식을 임의로 선택할 수 없습니다.',
	},
	SELECT: {
		key: 'SELECT',
		value: 'SELECT',
		text: '양식 선택 가능',
		description: '데이터 입력자가 상황에 따라 입력 양식을 선택하게 됩니다.',
	},
	// FREE: {
	// 	key: 'FREE',
	// 	value: 'FREE',
	// 	text: '자유기록',
	// 	description: '별도의 정해진 양식 없이 자유롭게 내용을 수집합니다.'
	// },
}

export const ResearchCohortType = {
	PROSPECTIVE: {
		value: 'PROSPECTIVE',
		text: '전향적',
		description: '연구 개시 후 대상자 등록을 시작합니다.',
	},
	RETROSPECTIVE: {
		value: 'RETROSPECTIVE',
		text: '후향적',
		description: '기존의 차트 기록이나 조사 내역을 바탕으로 연구합니다.',
	},
}

export const ResearchCaseType = {
	PATIENT: {
		value: 'PATIENT',
		text: '환자 데이터',
		description: '임상 환자 대상의 연구',
	},
	PAPER_REVIEW: {
		value: 'PAPER_REVIEW',
		text: '논문 고찰',
		description: '논문 정리',
		disabled: true,
	},
	IN_VITRO: {
		value: 'IN_VITRO',
		text: '실험실(In Vitro)',
		disabled: true,
	},
}

export const ResearchSamplingPlan = {
	ONE_OFF: {
		value: 'ONE_OFF',
		text: '일회성 기록',
		description: '환자 한명에 대해 하나의 기록만 가지는 설계일 경우 선택해주세요.',
	},
	TIME_SERIES: {
		value: 'TIME_SERIES',
		text: '연속적 기록',
		description: '시간에 따라 지속적인 기록이 필요할 경우 선택해 주세요.',
	},
}

export const ResearchInstitutionInvolve = {
	SINGLE: {
		value: 'SINGLE',
		text: '단일 기관',
		description: '하나의 기관에서 데이터를 수집합니다.',
	},
	MULTIPLE: {
		value: 'MULTIPLE',
		text: '다기관',
		description: '여러 기관에서 함께 참여하여 데이터를 수집합니다.',
	},
}

export const ResearchPurpose = {
	ACADEMIC: {
		value: 'ACADEMIC',
		text: '학술적 교류 / 데이터 탐색',
		description:
			'참여 의료인/연구자 간에 케이스 데이터를 기반으로 소통하고, 자유로운 시도를 통해 데이터를 구축해보고 탐색해 나가는 프로젝트',
	},
	PAPER: {
		value: 'PAPER',
		text: '연구 논문 작성',
		description: '연구 논문 작성을 위한 일반적인 임상 연구 프로젝트',
	},
	HOSPITAL: {
		value: 'HOSPITAL',
		text: '병원 데이터 구축',
		description:
			'진료 내용, 의료기기 데이터 등 여러 종류의 의학적 기록과 결합된 병원 데이터 구축을 통해 여러 인사이트를 획득하세요. 병원 데이터 구축은 문의를 통해 진행하실 수 있습니다.',
	},
}

export const ResearchScreeningOptions = {
	HAS_SCREENING: {
		value: true,
		text: '스크리닝 필요',
		description: '대상자 스크리닝을 위한 양식을 사용합니다.',
	},
	NO_SCREENING: {
		value: false,
		text: '스크리닝 불필요',
		description: '별도의 대상자 스크리닝 관리가 필요하지 않습니다.',
	},
}

export const ResearchApplicationType = {
	SCHEDULED: {
		value: 'SCHEDULED',
		text: '레지스트리 연구',
	},
	GATHERING: {
		value: 'GATHERING',
		text: '후향적/데이터 구축',
	},
	FREE: {
		value: 'FREE',
		text: '자유',
	},
	ETC: {
		value: 'ETC',
		text: '기타',
	},
}

export const ResearchAgreementCheckType = {
	WARNING: {
		value: 'WARNING',
		text: '경고',
	},
	PAPER_IMAGE: {
		value: 'ACTUAL',
		text: '동의서 업로드',
	},
	SIGNATURE: {
		value: 'SIGNATURE',
		text: '직접 서명',
	},
	CHECK: {
		value: 'CHECK',
		text: '확인',
	},
}

export const RecordTemplateChangeType = {
	INIT: {
		key: 'INIT',
		value: 'INIT',
		color: '#fdc64b',
	},
	UPDATE: {
		key: 'UPDATE',
		value: 'UPDATE',
		color: '#5699e5',
	},
	ROLLBACK: {
		key: 'ROLLBACK',
		value: 'ROLLBACK',
		color: '#6200ee',
	},
}

export const SharingScopes = {
	GROUP: {
		key: 'CASE_SHARE_GROUP',
		value: 'GROUP',
		text: '그룹 공개',
		icon: 'users',
	},
	PRIVATE: {
		key: 'CASE_SHARE_PRIVATE',
		value: 'PRIVATE',
		text: '나만 보기',
		icon: 'lock',
	},
	PUBLIC: {
		key: 'CASE_SHARE_PUBLIC',
		value: 'PUBLIC',
		text: '전체 공개',
		icon: 'globe',
	},
}

export const CaseListType = {
	GROUP: {
		key: 'CASE_LIST_TYPE_GROUP',
		value: 'GROUP',
		text: '그룹 케이스',
	},
	MY: {
		key: 'CASE_LIST_TYPE_MY',
		value: 'MY',
		text: '내 케이스',
	},
	FEED: {
		key: 'CASE_LIST_TYPE_FEED',
		value: 'FEED',
		text: '뉴스피드',
	},
	TAG: {
		key: 'CASE_LIST_TYPE_TAG',
		value: 'TAG',
		text: '태그',
	},
	SEARCH: {
		key: 'CASE_LIST_TYPE_SEARCH',
		value: 'SEARCH',
		text: '검색',
	},
}

export const TAG_TYPES = {
	SYMPTOM: '증상',
	NEW: '신규',
	PULSE: '맥상',
	REGULAR: '동의보감 목차',
	PRESC: '처방',
	HERB: '본초',
	ACU: '경혈',
}
