import React, { useMemo } from 'react'

import { useLocation } from 'react-router-dom'
import { PAGES_BASIC } from '../../../constants/pages'
import HeaderPageTitle from '../../common/basic/HeaderPageTitle'

/**
 * 헤더 페이지 기본 정보
 */
const DefaultHeader = () => {
	const { pathname } = useLocation()

	const title = useMemo(() => {
		const pageinfo = PAGES_BASIC.find(i => i.route === pathname)
		if (pageinfo) return pageinfo.title

		return undefined
	}, [pathname])

	return <HeaderPageTitle>{title}</HeaderPageTitle>
}

export default DefaultHeader
