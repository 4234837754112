import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'

/**
 * Route Option의 Array를 route로 rendering
 */
const MenuRouters = ({ basicProps, menuItems, defaultItem }) => {

	return (
		<Switch>
			{menuItems.map(item => {
				const ItemContent = item.component

				return (
					<Route
						key={item.key}
						path={item.to}
						exact={item.exact}
						render={rProps => <ItemContent {...rProps} {...basicProps} {...item.props} />}
					/>
				)
			})}
			{defaultItem != null && <Redirect to={defaultItem.to} />}
		</Switch>
	)
}

MenuRouters.propTypes = {
	basicProps: PropTypes.object,
	menuItems: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string,
			to: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.array
			]),
			exact: PropTypes.bool,
		})
	),
	defaultItem: PropTypes.shape({
		to: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.array
		]
	)}),
}

export default MenuRouters
