import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'semantic-ui-react'
import { useMutation } from 'react-apollo'
import { PARSE_EXCEL_XBODY } from '../../../graphql/mutations/record'
import FileUploadBox from '../../common/FileUploadBox'

const RecordAddFileUpload = ({ setParsedData, templateId, parseExcel }) => {
	const [requestParseExcel] = useMutation(PARSE_EXCEL_XBODY, {
		onCompleted(data) {
			const { parseExcel_xbody: medInfos } = data
			setParsedData(medInfos)
		},
	})
	const onChangeFile = useCallback(
		file => {
			requestParseExcel({
				variables: {
					recordTemplateId: templateId,
					upload: file,
				},
			})
		},
		[requestParseExcel, templateId]
	)
	return (
		<Form.Field>
			<label>파일 업로드</label>
			<FileUploadBox
				onChangeFile={onChangeFile}
				description="클릭하여 엑셀 파일(.xlsx)을 업로드할 수 있습니다. "
			/>
		</Form.Field>
	)
}

RecordAddFileUpload.propTypes = {
	setParsedData: PropTypes.func,
	templateId: PropTypes.number,
}

export default RecordAddFileUpload
