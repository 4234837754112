import React from 'react'
import PropTypes from 'prop-types'

const ConditionResultRenderer = ({ text }) => <span>{text}</span>

ConditionResultRenderer.propTypes = {
	text: PropTypes.string,
}

export default ConditionResultRenderer
