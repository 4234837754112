import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Image, Button, Icon, Label } from 'semantic-ui-react'
import moment from 'moment'

import { ResearcherRole, ResearcherRight } from '../../../../enum'
import { replaceParams } from '../../../../libs/routeHelper'
import { ROUTE_PROJECT_DETAIL_SETTINGS_MEMBERS_DETAIL_DEFAULT } from '../../../../constants/routes'

/**
 * 프로젝트 멤버 목록 행
 */
function ResearchMemberRow({ researchId, onMoveToSettings, user, researcherInfo, invitation }) {
	const { id: invitationId, email, createdAt, onCancle } = invitation || {}
	const { id, name, position, institution, profileImage } = user || {}
	const { role, description } = researcherInfo || {}

	const rights = Object.keys(ResearcherRight).filter(key => researcherInfo[key] === true)
	return (
		<Contianer invitation={invitation != null}>
			<div style={styles.containerWrap}>
				<div style={styles.profileWrap}>
					{invitation != null && invitation.email != null && profileImage == null ? (
						<Icon style={styles.mailIcon} name="mail" size="big" />
					) : (
						<Image as="a" wrapped style={styles.profileImage} circular src={profileImage} />
					)}

					<div style={styles.profileInfo}>
						<div>
							{invitation == null ? (
								<Link
									className="hover-underline"
									to={replaceParams(ROUTE_PROJECT_DETAIL_SETTINGS_MEMBERS_DETAIL_DEFAULT, {
										id: researchId,
										subId: id,
									})}
									style={styles.name}
								>
									{name}{' '}
								</Link>
							) : (
								<span style={styles.name}>{name || email}&nbsp;</span>
							)}
							{institution && (
								<a
									className="hover-underline"
									href={`/institutions/${institution.id}`}
									style={styles.subInfo}
								>
									{institution.name}
								</a>
							)}
							<span style={styles.subInfo}>{position}</span>
						</div>

						{(description || (invitation != null && ResearcherRole[role] != null)) && (
							<div style={styles.description}>
								{invitation != null ? (
									<>
										<strong>{ResearcherRole[role].text}</strong>로 초대됨
										<span style={styles.createdAt}>{moment(createdAt).fromNow()}</span>
									</>
								) : description.length > 15 ? (
									description.slice(0, 15) + '...'
								) : (
									description
								)}
							</div>
						)}
					</div>
				</div>
				<div style={styles.researcherInfo}>
					<div>
						{invitation == null ? (
							<>
								<div style={styles.subContainer}>
									{/* {isBlinded && <Label>눈가림</Label>} */}
									<span style={styles.role}>
										{ResearcherRole[role] && ResearcherRole[role].text}
									</span>
								</div>
								<Button
									style={styles.settingsBtn}
									size="large"
									onClick={() => onMoveToSettings(id)}
								>
									<Icon name="settings" />
									설정
								</Button>
							</>
						) : (
							<Button
								basic
								size="medium"
								color="red"
								onClick={() => {
									onCancle({ id: invitationId, name: name || email })
								}}
								style={{ fontWeight: 'normal' }}
							>
								초대 취소
							</Button>
						)}
					</div>
				</div>
			</div>
			<div style={styles.rightContainer}>
				{rights.map(key => (
					<Label style={styles.label} key={key} color="teal">
						{ResearcherRight[key].text}
					</Label>
				))}
			</div>
		</Contianer>
	)
}

ResearchMemberRow.propTypes = {
	onMoveToSettings: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	researcherInfo: PropTypes.object.isRequired,
	researchId: PropTypes.number.isRequired,
	invitation: PropTypes.object,
}

export default ResearchMemberRow

const Contianer = styled.div`
	border-bottom: 1px solid #eee;
	padding: 10px;
	background: ${props => props.invitation === true && props.theme.COLOR_BACKGROUND_GRAY};
	box-shadow: ${props => props.invitation === true && props.theme.BOX_SHADOW_NORMAL} inset;
`

const styles = {
	containerWrap: {
		display: 'table',
		width: '100%',
		marginBottom: 5,
	},
	profileImage: {
		width: 50,
		height: 50,
		marginRight: 10,
		verticalAlign: 'middle',
	},
	mailIcon: {
		width: 50,
		marginRight: 10,
	},
	profileInfo: {
		verticalAlign: 'middle',
		display: 'inline-block',
	},
	name: {
		fontSize: 16,
	},
	subInfo: {
		fontSize: 13,
		paddingLeft: 5,
		color: '#999',
	},
	role: {
		fontSize: 14,
		color: '#999',
		width: 160,
		display: 'inline-block',
		textAlign: 'center',
	},
	subContainer: {
		display: 'inline-block',
	},
	settingsBtn: {
		marginLeft: 12,
	},
	rightLabel: {
		display: 'inline-block',
		marginRight: 10,
		marginTop: 10,
		fontSize: 12,
		fontWeight: '600',
		color: '#555',
	},
	researcherInfo: {
		display: 'table-cell',
		verticalAlign: 'middle',
		textAlign: 'right',
	},
	blinded: {
		fontSize: 14,
		color: '#999',
		width: 60,
		textAlign: 'center',
		display: 'inline-block',
	},
	description: {
		marginTop: 10,
	},
	noRight: {
		fontSize: 14,
		color: '#999',
		width: 60,
		textAlign: 'center',
		display: 'inline-block',
	},
	label: {
		fontSize: 11,
		padding: 4,
		fontWeight: '400',
	},
	createdAt: {
		marginLeft: 8,
		color: '#999',
	},
}
