import React, { useRef, useCallback, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'

import { useApolloClient, useQuery } from 'react-apollo'
import { isEmpty } from 'lodash'
import PropRoute from '../common/route/PropRoute'

import PatientDetail from '../../containers/PatientDetail'
import Home from '../../containers/Home'
import Login from '../../containers/Login'
import MyPatients from '../../containers/MyPatients'
import MyStatistics from '../../containers/MyStatistics'
import Researches from '../../containers/Researches'
import ForgotAccount from '../../containers/UserForgotAccount'
import Terms from '../../containers/Terms'
import Blog from '../../containers/Blog'
import DDMIntroduce from '../../containers/DDMIntroduce'
import Cases from '../../containers/Cases'
import ChartSettings from '../../containers/ChartSettings'
import SignupForm from '../user/login/SignupForm'
import UserProfileEdit from '../../containers/UserProfileEdit'
import Faq from '../../containers/Faq'

import {
	ROUTE_ABOUT,
	ROUTE_HOME,
	ROUTE_MY_PATIENTS,
	ROUTE_MY_SETTINGS,
	ROUTE_LOGIN,
	ROUTE_SIGNUP,
	ROUTE_TERMS,
	ROUTE_PATIENT_DETAIL,
	ROUTE_MY_PROFILE,
	ROUTE_PROJECTS,
	ROUTE_MY_STATISTICS,
	ROUTE_LOGIN_FORGOT,
	ROUTE_CONTACT,
	ROUTE_EMAIL_VERIFICATION,
	ROUTE_TEST,
	ROUTE_CONTACT_DOC,
	ROUTE_FAQ,
} from '../../constants/routes'
import { PAGE_DEFAULT_SIDE_PADDING, HEADER_SCROLL_HEIGHT } from '../../constants/styles'
import ScrollToTopComp from '../app/ScrollToTopComp'
import Contact from '../../containers/Contact'
import Page404 from '../../containers/Page404'
import ErrorBoundary from '../common/ErrorBoundary'
import { SET_SCROLL_STATUS } from '../../graphql/mutations/app'
import { TOGGLE_NOTICE_MODAL_OPEN, SET_READ_NOTICES } from '../../graphql/mutations/notice'
import { GET_NOTICE_MODAL_OPEN, GET_NOTICES, GET_READ_NOTICE } from '../../graphql/queries/notice'
import { GET_USER_INFO_NO_USER } from '../../graphql/queries/user'
import EmailVerification from '../user/login/EmailVerification'
import Test from '../../containers/Test'
import { MobileOnly } from '../common/basic/ResponsiveWrappers'
import { Footer } from './Footer'
import NoticesModal from '../common/modal/NoticesModal'

const MAIN_ROUTES = [
	{
		key: 'ABOUT',
		exact: true,
		path: [ROUTE_ABOUT, ROUTE_HOME],
		authRequired: false,
		component: Home,
		hasMobile: true,
	},
	{
		key: 'MY_PATIENTS',
		title: '내 데이터',
		exact: true,
		path: ROUTE_MY_PATIENTS,
		authRequired: true,
		component: MyPatients,
	},
	{
		key: 'STATISTICS',
		title: '통계',
		path: ROUTE_MY_STATISTICS,
		authRequired: true,
		component: MyStatistics,
	},
	{
		key: 'MY_PROJECTS',
		title: '내 프로젝트',
		path: ROUTE_PROJECTS,
		authRequired: true,
		component: Researches,
	},
	{
		key: 'MY_SETTIGNS',
		title: '설정',
		path: ROUTE_MY_SETTINGS,
		authRequired: true,
		component: ChartSettings,
	},
	{
		key: 'LOGIN',
		path: ROUTE_LOGIN,
		authRequired: false,
		component: Login,
		hasMobile: true,
	},
	{
		key: 'SIGNUP',
		path: ROUTE_SIGNUP,
		authRequired: false,
		component: SignupForm,
		hasMobile: true,
	},
	{
		key: 'LOGIN_FORGOT',
		path: ROUTE_LOGIN_FORGOT,
		authRequired: false,
		component: ForgotAccount,
		hasMobile: true,
	},
	{
		key: 'EMAIL_VERIFICATION',
		path: ROUTE_EMAIL_VERIFICATION,
		authRequired: false,
		component: EmailVerification,
		hasMobile: true,
	},
	{
		key: 'TERMS',
		path: ROUTE_TERMS,
		authRequired: false,
		component: Terms,
		hasMobile: true,
	},
	{
		key: 'PATIENT_DETAIL',
		path: ROUTE_PATIENT_DETAIL,
		authRequired: true,
		component: PatientDetail,
	},
	{
		key: 'PROFILE',
		title: '내 프로필 관리',
		path: ROUTE_MY_PROFILE,
		authRequired: true,
		component: UserProfileEdit,
	},
	{
		key: 'CONTACT',
		title: '문의하기',
		path: [ROUTE_CONTACT, ROUTE_CONTACT_DOC],
		component: Contact,
		authRequired: false,
		hasMobile: true,
	},
	{
		key: 'TEST',
		title: '테스트',
		path: ROUTE_TEST,
		component: Test,
		hasMobile: true,
	},
	{
		key: 'FAQ',
		title: '자주하는 질문',
		authRequired: false,
		path: ROUTE_FAQ,
		component: Faq,
		hasMobile: true,
	},
]

/**
 * 페이지 본문 내용 관리
 */
const Main = ({
	hasSideMenu,
	hasSubSideMenu,
	hasFullWidthContent,
	isWithoutHeaderMenu,
	shouldNotDisplayFooter,
	...otherProps
}) => {
	const content = useRef()
	const scrolled = useRef(false)
	const client = useApolloClient() // useMutation을 사용하면 앱 전체가 rerender 되어버림.

	// 스크롤을 헤더 높이 (50) 이하로 내리거나 올릴
	const onScroll = useCallback(() => {
		const cur = content.current

		if (cur != null) {
			if (cur.scrollTop > HEADER_SCROLL_HEIGHT && scrolled.current === false) {
				client.mutate({
					mutation: SET_SCROLL_STATUS,
					variables: {
						isDown: true,
					},
				})
				scrolled.current = true
			} else if (cur.scrollTop < HEADER_SCROLL_HEIGHT && scrolled.current === true) {
				client.mutate({
					mutation: SET_SCROLL_STATUS,
					variables: {
						isDown: false,
					},
				})
				scrolled.current = false
			}
		}
	}, [client])

	const {
		data: { isNoticeModalOpen },
	} = useQuery(GET_NOTICE_MODAL_OPEN)
	const {
		data: { readNoticesId },
	} = useQuery(GET_READ_NOTICE)
	const { data: { activeNotices } = {}, refetch: noticeRefetch } = useQuery(GET_NOTICES) || {}

	const filteredNotices = useMemo(
		() =>
			activeNotices != null
				? activeNotices
						.filter(notice => !readNoticesId.includes(notice.id)) // 읽지 않은 공지만 필터
						.sort((a, b) => {
							if (a.startDate > b.startDate) return -1
							if (a.startDate === b.startDate) return 0
							return 1
						}) // startDate 기준 내림차순 정렬
				: [],
		[activeNotices, readNoticesId]
	)
	const {
		data: {
			userInfo: { noUser },
		},
	} = useQuery(GET_USER_INFO_NO_USER)

	const handleNoticeModal = useCallback(
		(shouldOpen, noMore) => {
			if (noMore === true) {
				client.mutate({
					mutation: SET_READ_NOTICES,
					variables: {
						noticesId: filteredNotices.map(notice => notice.id),
					},
				})
			}
			client.mutate({
				mutation: TOGGLE_NOTICE_MODAL_OPEN,
				variables: {
					shouldOpen,
				},
			})
		},
		[client, filteredNotices]
	)
	useEffect(() => {
		noticeRefetch() // noUser 상태가 변경되면 공지 데이터 refetch
		if (!isEmpty(filteredNotices)) handleNoticeModal(true)
	}, [noUser, handleNoticeModal, filteredNotices, noticeRefetch])

	return (
		<ScrollToTopComp contentRef={content}>
			<ErrorBoundary>
				<MainContainer
					onScroll={hasSideMenu === true ? onScroll : null}
					ref={content}
					hasSideMenu={hasSideMenu}
					hasSubSideMenu={hasSubSideMenu}
					hasFullPageContent={hasFullWidthContent}
				>
					<NoticesModal
						notices={filteredNotices}
						isOpen={isNoticeModalOpen}
						onClickClose={() => handleNoticeModal(false)}
						onClickNoMore={() => handleNoticeModal(false, true)}
					/>
					<Switch>
						{MAIN_ROUTES.map(routeInfo => (
							<PropRoute key={routeInfo.key} props={otherProps} {...routeInfo} />
						))}
						<Route path="*" component={Page404} />
					</Switch>
					{shouldNotDisplayFooter !== true && (
						<MobileOnly>
							<Footer />
						</MobileOnly>
					)}
				</MainContainer>
			</ErrorBoundary>
		</ScrollToTopComp>
	)
}

Main.propTypes = {
	headerRef: PropTypes.shape({
		current: PropTypes.object,
	}),
	hasSideMenu: PropTypes.bool.isRequired,
	hasSubSideMenu: PropTypes.bool.isRequired,
	hasFullWidthContent: PropTypes.bool.isRequired,
	isWithoutHeaderMenu: PropTypes.bool.isRequired,
	shouldNotDisplayFooter: PropTypes.bool.isRequired,
}

export default Main

/**
 * Styles
 */

/**
 * 본문 내용
 */
const MainContainer = styled.main`
	text-align: center;
	padding: ${props =>
		props.hasFullPageContent === true ? 0 : `0px ${PAGE_DEFAULT_SIDE_PADDING}`}px;
	width: 100%;
	height: 100%;
	overflow: ${props => (props.hasSideMenu === true ? 'auto' : 'visible')};
	margin: auto;
	flex: 1;
	background: white;
	padding-top: ${props => (props.hasSideMenu === true ? '20px' : '0')};

	${props =>
		props.hasSideMenu !== true
			? `
		@media (max-width: 768px) {
			padding: 0px;
			padding-top: 50px;
		}
	`
			: ''}
`
