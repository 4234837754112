import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useMutation } from 'react-apollo'
import ResearchCondition from '../components/research/templates/ResearchCondition'

import { UPDATE_RESEARCH_CONDITIONS } from '../graphql/mutations/record'
import { ResearchType } from '../enum'

import ResearchLibraryManager from './ResearchLibraryManager'
import {
	ROUTE_PROJECT_DETAIL_TEMPLATES_DEFAULT,
	ROUTE_PROJECT_DETAIL_TEMPLATES_FORMS_DEFAULT,
	ROUTE_PROJECT_DETAIL_TEMPLATES_CONDITIONS_DEFAULT,
	ROUTE_PROJECT_DETAIL_TEMPLATES_LIBRARY_DEFAULT,
	ROUTE_PROJECT_DETAIL_TEMPLATES_FIELDS_DEFAULT,
} from '../constants/routes'
import PageContainer from '../components/common/container/PageContainer'
import MenuContainer from '../components/common/container/MenuContainer'
import ResearchTemplateList from '../components/research/templates/ResearchTemplateList'
import { isMatchingRoutes } from '../libs/routeHelper'
import ResearchFields from '../components/research/templates/ResearchFields'

/**
 * 세부 메뉴 정보
 */
const RESEARCH_TEMPLATE_MENU = Object.freeze([
	{
		key: 'RESEARCH_TEMPLATES',
		value: null,
		to: ROUTE_PROJECT_DETAIL_TEMPLATES_DEFAULT,
		exact: true,
		title: '입력 양식',
		component: ResearchTemplateList,
	},
	{
		key: 'RESEARCH_TEMPLATES_FORMS',
		value: null,
		to: ROUTE_PROJECT_DETAIL_TEMPLATES_FORMS_DEFAULT,
		title: '부분 양식',
		component: ResearchTemplateList,
	},
	{
		key: 'RESEARCH_TEMPLATES_CONDITIONS',
		value: null,
		to: ROUTE_PROJECT_DETAIL_TEMPLATES_CONDITIONS_DEFAULT,
		title: '조건식',
		component: ResearchCondition,
	},
	{
		key: 'RESEARCH_TEMPLATES_FIELDS',
		value: null,
		to: ROUTE_PROJECT_DETAIL_TEMPLATES_FIELDS_DEFAULT,
		title: '필드정보',
		component: ResearchFields,
	},
	{
		key: 'RESEARCH_TEMPLATES_LIBRARY',
		value: null,
		to: ROUTE_PROJECT_DETAIL_TEMPLATES_LIBRARY_DEFAULT,
		title: '라이브러리',
		component: ResearchLibraryManager,
	},
])

const PAGE_TITLE = '입력 양식'

/**
 * 프로젝트 양식 관리 페이지
 *
 * 기본 탭 메뉴와 라우팅 처리
 */

const ResearchTemplates = props => {
	const { hasPartialTemplate, breadCrumbs, researchId, match, type } = props
	const activeMenu = match.params.menu
	const { pathname } = useLocation()

	const isScheduled = type === ResearchType.SCHEDULED.value
	const isPartial = activeMenu === 'forms'

	const [onUpdateConditions] = useMutation(UPDATE_RESEARCH_CONDITIONS)

	const isFluid = useMemo(() => {
		return isMatchingRoutes(pathname, [ROUTE_PROJECT_DETAIL_TEMPLATES_CONDITIONS_DEFAULT])
	}, [pathname])

	return (
		<PageContainer fixed={!isFluid} fluid={isFluid} title={PAGE_TITLE} breadCrumbs={breadCrumbs}>
			<MenuContainer
				// isBox
				menuItems={
					hasPartialTemplate !== true
						? RESEARCH_TEMPLATE_MENU.filter(r => r.key !== 'RESEARCH_TEMPLATES_FORMS')
						: RESEARCH_TEMPLATE_MENU
				}
				menuOptions={{
					pointing: true,
					secondary: true,
				}}
				replaceParam={{
					id: researchId,
				}}
				basicProps={{
					researchId,
					isScheduled,
					isPartial,
					onUpdateConditions,
					...props,
				}}
			/>
		</PageContainer>
	)
}

ResearchTemplates.propTypes = {
	researchId: PropTypes.number.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			menu: PropTypes.string,
		}).isRequired,
	}).isRequired,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
	type: PropTypes.string,
	breadCrumbs: PropTypes.array,
	hasPartialTemplate: PropTypes.bool,
}

export default ResearchTemplates
