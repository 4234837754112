import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'semantic-ui-react'
import { useMutation } from 'react-apollo'
import TemplateOptions from '../TemplateOptions'
import {
	CREATE_RECORD_TEMPLATE,
	UPDATE_RECORD_TEMPLATE_OPTIONS,
} from '../../../graphql/mutations/record'
import { TemplateJob, TemplateType } from '../../../enum'
import {
	GET_RESEARCH_RECORD_TEMPLATES,
	GET_RECORD_TEMPLATE_INFO,
} from '../../../graphql/queries/record'
import useToastMessage from '../../../hooks/useToastMessage'

/**
 * 양식 생성을 위한 모달
 */
const TemplateAddModal = ({
	isPartial,
	researchId,
	open,
	onClose,
	isSchedule,
	templateName,
	isEdit,
	defaultTitle,
	defaultDescription,
	defaultJob,
	defaultDays,
	defaultDaysRange,
	defaultShouldReserve,
	templateId,
	hasScreening,
}) => {
	const [title, setTitle] = useState(defaultTitle || '')
	const [description, setDescription] = useState(defaultDescription || '')
	const [job, setJob] = useState(defaultJob || TemplateJob.CHARTING.key)
	const [days, setDays] = useState(defaultDays || '')
	const [daysRange, setDaysRange] = useState(defaultDaysRange || '')
	const [shouldReserveExist, setShouldReserveExist] = useState(defaultShouldReserve !== null)
	const [shouldReserve, setShouldReserve] = useState(defaultShouldReserve || '')
	const showToastMessage = useToastMessage()
	const onChange = useCallback(
		key => (e, { value, checked }) => {
			if (key === 'days') setDays(value)
			else if (key === 'title') setTitle(value)
			else if (key === 'description') setDescription(value)
			else if (key === 'day') setDays(value)
			else if (key === 'daysRange') setDaysRange(value)
			else if (key === 'shouldReserve') setShouldReserve(value)
			else if (key === 'shouldReserveExist') setShouldReserveExist(checked)
		},
		[]
	)

	const handleJob = useCallback(value => {
		setJob(value)
	}, [])

	const [onSubmit] = useMutation(CREATE_RECORD_TEMPLATE, {
		variables: {
			researchId,
			title,
			description,
			type: TemplateType.FIXED.value,
			...(isPartial === true
				? {
						job: TemplateJob.ECRF.key,
						isPartial: true,
						isSchedule: false,
				  }
				: {
						days: parseInt(days),
						daysRange: parseInt(daysRange),
						job,
						isSchedule: true,
						hasECRFs: true,
				  }),
		},
		refetchQueries: [
			{
				query: GET_RESEARCH_RECORD_TEMPLATES,
				variables: {
					researchId,
					...(isPartial === true && {
						job: TemplateJob.ECRF.key,
					}),
				},
			},
		],
		onCompleted: () => {
			onClose()
		},
	})

	const [onUpdate] = useMutation(UPDATE_RECORD_TEMPLATE_OPTIONS, {
		variables: {
			templateId,
			title,
			description,
			...(isSchedule === true
				? {
						days: parseInt(days),
						daysRange: parseInt(daysRange),
						shouldReserve: shouldReserveExist === true ? shouldReserve : null,
				  }
				: {}),
		},
		refetchQueries: [
			{
				query: GET_RECORD_TEMPLATE_INFO,
				variables: {
					templateId,
				},
			},
		],
		onCompleted: () => {
			onClose()
			showToastMessage({
				id: 'ALERT_SUCESS_EDIT_TEMPLATE_INFO',
				content: '양식 정보가 수정되었습니다.',
				autoDismiss: true,
				success: true,
			})
		},
	})

	return (
		<Modal closeIcon open={open} onClose={onClose} size="small">
			<Modal.Header>{isEdit === true ? '양식 정보 수정' : `새로운 ${templateName}`}</Modal.Header>
			<Modal.Content>
				<TemplateOptions
					title={title}
					isSchedule={isSchedule}
					description={description}
					onChange={onChange}
					days={days}
					daysRange={daysRange}
					templateName={templateName}
					isEdit={isEdit}
					job={job}
					onClickJob={handleJob}
					shouldReserveExist={shouldReserveExist}
					shouldReserve={shouldReserve}
					hasScreening={hasScreening}
				/>
			</Modal.Content>
			<Modal.Actions>
				<Button primary onClick={isEdit === true ? onUpdate : onSubmit}>
					{isEdit === true ? '저장' : `${templateName} 등록`}
				</Button>
			</Modal.Actions>
		</Modal>
	)
}

TemplateAddModal.propTypes = {
	isPartial: PropTypes.bool,
	researchId: PropTypes.number,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	isSchedule: PropTypes.bool,
	templateName: PropTypes.string,
	isEdit: PropTypes.bool,
	defaultTitle: PropTypes.string,
	defaultDescription: PropTypes.string,
	defaultJob: PropTypes.string,
	defaultDays: PropTypes.number,
	defaultDaysRange: PropTypes.number,
	defaultShouldReserve: PropTypes.string,
	templateId: PropTypes.number,
	hasScreening: PropTypes.bool,
}

export default TemplateAddModal
