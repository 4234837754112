import React from 'react'
import PropTypes from 'prop-types'
import { URL_DEFAULT_PROFILE_IMAGE } from '../../constants/urls'
import { Avatar } from './Avatar'

const InlineProfile = props => {
	const { size, profileImage, name } = props
	let height, fontSize
	if (size === 'small') {
		height = 40
		fontSize = 15
	} else if (size === 'tiny') {
		height = 30
		fontSize = 14
	} else if (size === 'mini') {
		height = 20
		fontSize = 13
	} else if (size === 'large') {
		height = 50
		fontSize = 16
	}

	return (
		<div style={{ display: 'inline-block' }}>
			<div style={{ height, fontSize }}>
				<Avatar
					image={profileImage}
					width={height + 2}
					style={{
						marginRight: height / 2,
					}}
				/>
				<b>{name}</b>
			</div>
		</div>
	)
}

InlineProfile.defaultProps = {
	size: 'small',
	profileImage: URL_DEFAULT_PROFILE_IMAGE,
}

InlineProfile.propTypes = {
	size: PropTypes.oneOf(['mini', 'tiny', 'small']),
	profileImage: PropTypes.string,
	name: PropTypes.string,
}

export default InlineProfile
