import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import useLogout from '../../../hooks/useLogout'

/**
 * 로그인 사용자 드롭다운 메뉴
 */
export const UserStatusDropdown = ({ name, nickname, trigger }) => {
	const logout = useLogout()

	return (
		<Dropdown direction="left" icon={null} trigger={trigger}>
			<Dropdown.Menu>
				<Dropdown.Item>
					<b>
						{nickname} ({name})
					</b>
				</Dropdown.Item>
				<Dropdown.Divider />
				<Dropdown.Item as={Link} to="/profile">
					<span>프로필 설정</span>
				</Dropdown.Item>
				<Dropdown.Item as={Link} to="/profile/account">
					<span>비밀번호 변경</span>
				</Dropdown.Item>
				<Dropdown.Item onClick={logout}>
					<span>로그아웃</span>
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	)
}

UserStatusDropdown.propTypes = {
	trigger: PropTypes.element.isRequired,
	name: PropTypes.string,
	nickname: PropTypes.string,
}
