import * as userInfo from './client/userInfo'
import * as subSideMenu from './client/subSideMenu'
import * as scroll from './client/scroll'
import * as alerts from './client/alerts'
import * as patientSurvey from './client/patientSurvey'
import * as readNotices from './client/readNotices'
import * as change from './client/change'
import * as common from './client/common'

export const defaults = {
	...userInfo.defaults,
	...subSideMenu.defaults,
	...alerts.defaults,
	...scroll.defaults,
	...patientSurvey.defaults,
	...readNotices.defaults,
	...change.defaults,
	...common.defaults,
}

export const typeDefs = {
	...patientSurvey.typeDefs,
}

export const resolvers = {
	Mutation: {
		...userInfo.resolvers.Mutation,
		...subSideMenu.resolvers.Mutation,
		...scroll.resolvers.Mutation,
		...alerts.resolvers.Mutation,
		...patientSurvey.resolvers.Mutation,
		...readNotices.resolvers.Mutation,
		...change.resolvers.Mutation,
		...common.resolvers.Mutation,
	},
	Query: {
		...subSideMenu.resolvers.Query,
		...patientSurvey.resolvers.Query,
	},
}
