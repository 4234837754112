import React, { PureComponent, createRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import 'moment/locale/ko'

import compose from 'lodash.flowright'
import { graphql } from 'react-apollo'
import CommentItemView from './CommentItemView'
import { DELETE_COMMENT } from '../../graphql/mutations/community'

/**
 * 개별 댓글 아이템
 */
class CommentItem extends PureComponent {
	static propTypes = {
		id: PropTypes.number,
		text: PropTypes.string,
		writer: PropTypes.object,
		replyTo: PropTypes.object,
		parentId: PropTypes.number,
		createdAt: PropTypes.string,
		user: PropTypes.object,
		useNick: PropTypes.bool,
		anchorOpenComment: PropTypes.number,
	}

	container = createRef()

	didScroll = false

	constructor(props) {
		super(props)

		this.state = {
			isReplying: false,
			isEditing: false,
		}
	}

	componentDidMount() {
		const { id, anchorOpenComment } = this.props
		if (this.didScroll === false && anchorOpenComment === id) {
			this.container.current.scrollIntoView()
			this.didScroll = true
		}
	}

	componentWillUnmount() {
		this.didScroll = false
	}

	toggleIsReply = () => {
		this.setState(p => ({
			isReplying: !p.isReplying,
		}))
	}

	toggleIsEditing = () => {
		this.setState(p => ({
			isEditing: !p.isEditing,
		}))
	}

	handleSubmitReply = async () => {
		this.setState({
			isReplying: false,
		})
	}

	handleSubmitEdit = async () => {
		this.setState({
			isEditing: false,
		})
	}

	handleDelete = async () => {
		const { id, deleteComment, onUpdate } = this.props
		await deleteComment({
			variables: {
				commentId: id,
			},
		})

		onUpdate()
	}

	render() {
		const { id, writer, parentId, createdAt, user, onUpdate } = this.props
		const { isReplying, isEditing } = this.state

		if (!writer) {
			return <div>삭제된 의견입니다.</div>
		}

		const moment_ca = moment(createdAt)
		const moment_mgo = moment().subtract(1, 'months')
		const moment_ygo = moment().subtract(1, 'year')

		let dstr
		if (moment_ca.isBefore(moment_ygo)) {
			dstr = moment_ca.format('YYYY년 MM월 DD일')
		} else if (moment_ca.isBefore(moment_mgo)) {
			dstr = moment_ca.format('MM월 DD일')
		} else {
			dstr = moment_ca.fromNow()
		}

		const isOwner = writer.id === user.id
		const isReply = typeof parentId === 'number'

		return (
			<CommentItemView
				{...this.props}
				isOwner={isOwner}
				isReply={isReply}
				isReplying={isReplying}
				isEditing={isEditing}
				toggleEdit={this.toggleIsEditing}
				toggleReply={this.toggleIsReply}
				onEdit={this.handleSubmitEdit}
				onDelete={this.handleDelete}
				onReply={this.handleSubmitReply}
				onUpdate={onUpdate}
				date={dstr}
				commentId={id}
				containerRef={this.container}
			/>
		)
	}
}

export default compose(
	graphql(DELETE_COMMENT, {
		name: 'deleteComment',
	})
	// graphql(EDIT_COMMENT_NAME, {
	//   name: 'editCommentName'
	// }),
	// graphql(EDIT_COMMENT_NICK, {
	//   name: 'editCommentNick'
	// }),
	// graphql(WRITE_COMMENT_NAME, {
	//   name: 'writeCommentName'
	// }),
	// graphql(WRITE_COMMENT_NICK, {
	//   name: 'writeCommentNick'
	// }),
)(CommentItem)
