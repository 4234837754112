import React, { useReducer, useState, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Input, Transition, Button, Form } from 'semantic-ui-react'
import { useMutation } from 'react-apollo'
import SegmentToggle from '../common/basic/SegmentToggle'
import { PATIENT_GENDER_OPTIONS, PATIENT_IN_OUT_OPTIONS } from '../../constants'
import {
	FullScreenBox,
	CenteredRowFlexDiv,
	FullCenteredVerticalFlexDiv,
} from '../common/basic/StyledBox'
import { ADD_PATIENT } from '../../graphql/mutations/patient'
import TextInputWithError from '../common/basic/TextInputWithError'
import { validateFields } from '../../libs/validationHelper'
import { PATIENT_SURVEY_FIELDS } from '../../fields'
import { UPDATE_PATIENT_SIGN } from '../../graphql/mutations/app'
import PatientSignModal from './PatientSignModal'

const INITIAL_STATE = {
	name: '',
	sexMale: true,
	bornYear: 1990,
	inOut: 0,
}

const patientReducer = (state, action) => {
	return {
		...state,
		[action.key]: action.value,
	}
}

/**
 * 환자 생성을 위한 전체화면 모달
 */
const PatientSurveyNewPatient = ({ onSelectPatient, researchId, patientCode, isOpen, onClose }) => {
	const [state, dispatch] = useReducer(patientReducer, INITIAL_STATE)

	const { sexMale, name, bornYear, inOut } = state
	const [errors, setErrors] = useState({})

	const onChange = (e, { value, name }) => {
		dispatch({
			key: name,
			value,
		})
	}

	const [addPatient] = useMutation(ADD_PATIENT, {
		onCompleted: ({ addPatient: { id } }) => {
			onClose()
			onSelectPatient(id)
		},
	})

	const [updatePatientSign] = useMutation(UPDATE_PATIENT_SIGN)

	const [hasAgreed, setHasAgreed] = useState(false)

	const submit = useCallback(
		variables => {
			const validErrs = validateFields(
				{
					name,
					bornYear: bornYear,
				},
				PATIENT_SURVEY_FIELDS
			)

			if (validErrs != null) {
				setErrors(validErrs)
				return
			}

			const result = {
				name,
				sexMale,
				birthDate: `${bornYear}-01-01`,
				inOut,
				patientCode,
			}

			addPatient({
				variables: {
					researchId,
					...result,
					isUsingLastRecord: false,
					...variables,
				},
			})
		},
		[addPatient, bornYear, inOut, name, patientCode, researchId, sexMale]
	)

	//TODO 서버와 연동
	const openPatientSign = () => {
		updatePatientSign({
			variables: {
				isOpen: true,
			},
		})
	}

	return (
		<>
			<Transition animation="slide up" visible={isOpen} duration={200}>
				<FullScreenBox>
					<FullCenteredVerticalFlexDiv>
						<CloseButton onClick={onClose}>취소</CloseButton>
						<Content>
							<Title>
								<h3>새로운 환자 등록</h3>
								<h6>차트번호 {patientCode} </h6>
							</Title>
							<Form>
								<TextInputWithError
									control={Input}
									label="이름"
									placeholder="초성을 입력해 주세요. 예) ㄱㄴㄷ"
									value={name}
									name="name"
									onChange={onChange}
									error={errors.name}
								/>
								<TextInputWithError
									control={Input}
									label="출생년도"
									placeholder="예) 1996"
									value={bornYear}
									name="bornYear"
									onChange={onChange}
									error={errors.bornYear}
								/>
								<Form.Field>
									<label>성별</label>
									<SegmentToggle
										fluid
										items={PATIENT_GENDER_OPTIONS}
										onChange={value => {
											dispatch({
												key: 'sexMale',
												value,
											})
										}}
										active={sexMale}
									/>
								</Form.Field>
								<Form.Field>
									<label>외래 / 입원</label>
									<SegmentToggle
										fluid
										items={PATIENT_IN_OUT_OPTIONS}
										onChange={value => {
											dispatch({
												key: 'inOut',
												value,
											})
										}}
										active={inOut}
									/>
								</Form.Field>
							</Form>
							<CenteredRowFlexDiv>
								<SubmitButton
									primary
									size="huge"
									onClick={openPatientSign}
									// disabled={hasAgreed !== true}
								>
									환자 등록
								</SubmitButton>
							</CenteredRowFlexDiv>
						</Content>
					</FullCenteredVerticalFlexDiv>
				</FullScreenBox>
			</Transition>
			<PatientSignModal onSubmit={submit} researchId={researchId} />
		</>
	)
}

PatientSurveyNewPatient.propTypes = {
	onSelectPatient: PropTypes.func.isRequired,
	researchId: PropTypes.number.isRequired,
	patientCode: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
}

export default PatientSurveyNewPatient

const CloseButton = styled.div`
	position: absolute;
	right: 50px;
	top: 50px;
	font-weight: 600;
	color: ${props => props.theme.COLOR_PRIMARY};

	:active {
		opacity: 0.3;
	}
`

const Content = styled.div`
	max-width: 600px;
`

const Title = styled.div`
	margin-bottom: 40px;
`

const SubmitButton = styled(Button)`
	margin: auto !important;
	margin-top: 60px !important;
`
