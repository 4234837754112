import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { COLOR_BACKGROUND_GRAY, COLOR_BACKGROUND_LIGHT_GRAY } from '../../constants/styles'

export const ListItem = styled(Link)`
	:hover {
		background: rgba(0, 0, 0, 0.03);
		color: black;
	}
	display: block;
	border-bottom: 1px solid #eee;
	padding: 13px 20px;
	color: black;
`
