import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Label, Modal } from 'semantic-ui-react'
import { useQuery } from 'react-apollo'
import InputSearch from '../common/basic/InputSearch'
import useInputText from '../../hooks/useInputText'
import {
	GET_RESEARCH_PATIENTS,
	GET_RESEARCH_PATIENTS_FOR_SURVEY,
	GET_INSTITUTION_RESEARCH_PATIENTS,
} from '../../graphql/queries/patient'
import LoadingView from '../common/loader'
import ErrorView from '../common/error/ErrorView'
import EmptyResult from '../common/empty/EmptyResult'
import { UISize } from '../../enum'
import { CenteredRowFlexDiv, FlexDiv, FlexFill, VerticalFlex } from '../common/basic/StyledBox'
import { convertBirthDate, convertGender, getAge, getAgeRange } from '../../libs/valueHelper'
import { MiniBar } from '../common/basic/MiniBar'
import DescriptionText from '../common/basic/DescriptionText'
import T1 from '../common/basic/T1'

/**
 * 태블릿 - 환자 검색
 */
const PatientSearch = ({ templateId, researchId, onCreatePatient, onSelectPatient }) => {
	const inputRef = useRef()
	useEffect(() => {
		setTimeout(() => {
			if (inputRef.current) {
				inputRef.current.focus()
			}
		}, 100)
	}, [])

	const [searchQuery, onChangeSearch] = useInputText('')
	return (
		<div>
			<LargeSearch
				inputRef={inputRef}
				autoFocus
				fluid
				placeholder="차트 번호를 검색하세요."
				value={searchQuery}
				onChange={onChangeSearch}
			/>
			<Patients
				onSelectPatient={onSelectPatient}
				onCreatePatient={onCreatePatient}
				researchId={researchId}
				searchQuery={searchQuery}
			/>
		</div>
	)
}

PatientSearch.propTypes = {}

export default PatientSearch

/**
 * 환자 검색결과 목록
 */
const Patients = ({ researchId, searchQuery, onCreatePatient, onSelectPatient }) => {
	const {
		loading,
		data: { institutionPatients: { items: patients, totalCount } = {} } = {},
		error,
	} = useQuery(GET_INSTITUTION_RESEARCH_PATIENTS, {
		variables: {
			researchId,
			pagination: {
				page: 1,
				perPage: 5,
			},
			filter: {
				sortBy: 'createdAt',
				sortOrder: 'DESC',
				// fields: [
				// 	{
				// 		name: 'researchId',
				// 		value: researchId.toString(),
				// 	},
				// ],
				search: {
					query: searchQuery,
				},
			},
		},
		fetchPolicy: 'network-only',
		skip: searchQuery === '',
	})

	if (error != null) {
		return <ErrorView error={error} />
	}

	if (loading === true) {
		return <LoadingView />
	}

	return (
		<ItemsContainer>
			{(patients == null || searchQuery == null || searchQuery === '') && (
				<Empty>
					<EmptyResult size={UISize.LARGE} message="차트번호를 입력해 주세요" />
				</Empty>
			)}
			{patients != null && patients.length === 0 && searchQuery !== '' && (
				<NewPatient onClick={() => onCreatePatient(searchQuery)}>
					새로운 환자 등록
					<NewCode>{searchQuery}</NewCode>
				</NewPatient>
			)}
			{patients != null && patients.length > 0 && (
				<FlexFill>
					{patients.map(patient => {
						return (
							<PatientItem
								key={`pops-${patient.id}`}
								{...patient}
								onClick={() => onSelectPatient(patient.id)}
							/>
						)
					})}
				</FlexFill>
			)}
		</ItemsContainer>
	)
}

Patients.propTypes = {
	researchId: PropTypes.number.isRequired,
	searchQuery: PropTypes.string,
	onCreatePatient: PropTypes.func.isRequired,
	onSelectPatient: PropTypes.func.isRequired,
}

/**
 * 환자 검색결과 개별 항목
 */
const PatientItem = ({ patientCode, sexMale, birthDate, onClick }) => {
	return (
		<PatientItemContainer onClick={onClick}>
			<FlexFill>
				<T1>{patientCode}</T1>
			</FlexFill>
			<DescriptionText>
				{convertGender(sexMale)}
				<MiniBar />
				{getAgeRange(birthDate)}
			</DescriptionText>
		</PatientItemContainer>
	)
}

PatientItem.propTypes = {
	patientCode: PropTypes.string,
	sexMale: PropTypes.bool,
	birthDate: PropTypes.string,
	onClick: PropTypes.func.isRequired,
}

/**
 *Styles
 */

const ItemsContainer = styled(CenteredRowFlexDiv)`
	margin-top: 50px;
`

const Empty = styled(CenteredRowFlexDiv)`
	height: 200px;
`

const PatientItemContainer = styled(CenteredRowFlexDiv)`
	padding: 15px 20px;
	width: 100%;
	border-bottom: ${props => props.theme.BORDER_GRAY};
`

const NewPatient = styled.div`
	border-radius: 20px;
	font-size: 20px;
	background: linear-gradient(#e66465, #9198e5);
	padding: 30px;
	color: white;
	display: inline-block;
	text-align: center;
	margin: auto;
	margin-top: 100px;
	transition: box-shadow 0.2s;
	cursor: pointer;

	:active {
		box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.4);
		opacity: 0.8;
	}
`

const NewCode = styled.div`
	margin-top: 20px;
	font-weight: 600;
	font-size: 22px;
`

const LargeSearch = styled(InputSearch)`
	font-size: 18px !important;
`
