import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { Checkbox, Placeholder } from 'semantic-ui-react'

const TermAgreementView = ({ loading, text, onToggle, agreed, noAgreement }) => {
	if (loading) {
		return (
			<div>
				<Placeholder>
					<Placeholder.Line />
					<Placeholder.Line />
					<Placeholder.Line />
					<Placeholder.Line />
					<Placeholder.Line />
					<Placeholder.Line />
				</Placeholder>
			</div>
		)
	}

	return (
		<div>
			<div style={{ maxHeight: 250, padding: 10, overflowY: 'scroll', textAlign: 'left' }}>
				<ReactMarkdown className="markdown-body markdown-small" source={text} />
			</div>
			{!noAgreement && (
				<div
					style={{ marginTop: 5, paddingTop: 15, textAlign: 'center', borderTop: '1px solid #eee' }}
				>
					<Checkbox
						onChange={onToggle}
						checked={agreed}
						label="위 약관에 동의합니다."
						style={{ fontWeight: '600' }}
					/>{' '}
					<span />
				</div>
			)}
		</div>
	)
}

TermAgreementView.propTypes = {
	text: PropTypes.string,
	onToggle: PropTypes.func,
	agreed: PropTypes.bool,
	loading: PropTypes.bool,
	noAgreement: PropTypes.bool,
}
export default TermAgreementView
