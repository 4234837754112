import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Placeholder, PlaceholderLine } from 'semantic-ui-react'
import VasChart from './VasChart'
import ErrorView from '../common/error/ErrorView'
import ModalSubHeader from '../common/basic/ModalSubHeader'

const PatientStatView = props => {
	const { loading, error, result } = props
	if (loading === true) {
		return (
			<Placeholder>
				<PlaceholderLine />
				<PlaceholderLine />
				<PlaceholderLine />
				<PlaceholderLine />
			</Placeholder>
		)
	}

	if (error != null) {
		return <ErrorView />
	}

	return (
		<div>
			<ModalSubHeader>증상 경과 추적</ModalSubHeader>
			<Description>
				환자가 호소한 증상별 VAS Scale, 증상 정도 측정 스코어 등의 변화를 추적합니다.
			</Description>
			<VasChart progress={result} />
		</div>
	)
}

PatientStatView.propTypes = {
	loading: PropTypes.bool.isRequired,
	error: PropTypes.shape({}),
	result: PropTypes.arrayOf().isRequired,
}

export default PatientStatView

const Description = styled.div`
	margin-top: 10px;
	margin-bottom: 20px;
	font-size: 14px;
`
