import React from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import 'moment/locale/ko'

import ResearchRecordChangeItem from '../data/ResearchRecordChangeItem'
import LoadingView from '../../common/loader'

import { GET_RECORD_TEMPLATE_CHANGES } from '../../../graphql/queries/record'
import { PAGE_CONTENT_SMALL_WIDTH } from '../../../constants/styles'

const styles = {
	containerWrap: { width: PAGE_CONTENT_SMALL_WIDTH, margin: 'auto', textAlign: 'left' },
}

/**
 * 수정내역 목록
 */
const ResearchTemplateHistoryList = props => {
	const { researchId, id, match } = props

	return (
		<Query query={GET_RECORD_TEMPLATE_CHANGES} variables={{ templateId: id, researchId }}>
			{({ loading, data, error }) => {
				if (loading) {
					return <LoadingView />
				} else {
					const { researchRecordTemplateChanges: changes } = data

					return (
						<div style={{ marginTop: 10, ...styles.containerWrap }}>
							{changes.map(item => (
								<ResearchRecordChangeItem
									key={`${item.id}`}
									templateId={id}
									researchId={researchId}
									template
									itemType={match.params.menu}
									{...item}
								/>
							))}
						</div>
					)
				}
			}}
		</Query>
	)
}

ResearchTemplateHistoryList.propTypes = {}

export default ResearchTemplateHistoryList
