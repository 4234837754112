import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'

import styled from 'styled-components'
import { useQuery, useMutation } from 'react-apollo'
import {
	COLOR_BORDER_PRIMARY,
	SUB_SIDE_BAR_WIDTH,
	SUB_SIDE_BAR_PADDING_RIGHT,
	COLOR_LIGHT_BACKGROUND,
} from '../../constants/styles'
import LeftIcon from '../../assets/images/icons/left.svg'
import RightIcon from '../../assets/images/icons/right.svg'
import {
	ROUTE_PROJECTS,
	ROUTE_STATISTICS,
	ROUTE_PATIENT_DETAIL,
	ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_DEFAULT,
} from '../../constants/routes'
import ResearchSideMenu from '../research/ResearchSideMenu'
import StatsSideMenu from '../statistics/StatsSideMenu'
import PatientSideMenu from '../patient/detail/PatientSideMenu'
import { TOGGLE_SUB_SIDE_MENU } from '../../graphql/mutations/app'
import { GET_SUB_SIDE_MENU_OPEN } from '../../graphql/queries/app'
import useSubSideMenuControl from '../../hooks/useSubSideMenuControl'

const ROUTES = [
	{
		key: 'SSM-R-PR-PT',
		path: ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_DEFAULT,
		component: PatientSideMenu,
	},
	{
		key: 'SSM-R-PR',
		path: ROUTE_PROJECTS,
		component: ResearchSideMenu,
	},
	{
		key: 'SSM-R-ST',
		path: ROUTE_STATISTICS,
		component: StatsSideMenu,
	},
	{
		key: 'SSM-R-PT',
		path: ROUTE_PATIENT_DETAIL,
		component: PatientSideMenu,
	},
]

/**
 * 서브 사이드 메뉴(페이지별 상세 서브메뉴, 여닫이 가능)
 */

const SubSideMenu = React.memo(() => {
	const {
		data: { isSubSideMenuOpen: isOpen },
	} = useQuery(GET_SUB_SIDE_MENU_OPEN)
	const toggle = useSubSideMenuControl()
	return (
		<SubSideMenuContainer isVisible={isOpen}>
			<ToggleButtonContainer onClick={() => toggle()}>
				<ToggleButton className="ssm-toggle-button" isOpen={isOpen} />
			</ToggleButtonContainer>
			<SidebarContent>
				<SidebarContentWrap className="side-bar-content" isVisible={isOpen}>
					<Switch>
						{ROUTES.map(route => {
							const { key, path, component: C } = route
							return <Route key={key} path={path} component={C} />
						})}
					</Switch>
				</SidebarContentWrap>
			</SidebarContent>
		</SubSideMenuContainer>
	)
})

export default SubSideMenu

/**
 * 메뉴 열고 닫는 토글 버튼
 */
const ToggleButton = ({ isOpen }) => {
	return (
		<ToggleButtonBox>
			<img alt="toggle menu" src={isOpen === true ? LeftIcon : RightIcon} />
		</ToggleButtonBox>
	)
}

ToggleButton.propTypes = {
	isOpen: PropTypes.bool.isRequired,
}

/**
 * Styled Components
 */

const SubSideMenuContainer = styled.div`
	position: relative;
	z-index: 10;
	height: 100vh;
	flex-shrink: 0;
	width: ${SUB_SIDE_BAR_WIDTH}px;
	transition: margin-left 100ms ease-out, transform 100ms ease-out;
	margin-left: ${props => (props.isVisible === true ? '0' : -(SUB_SIDE_BAR_WIDTH - 15))}px;
	background: ${COLOR_LIGHT_BACKGROUND};
`

const ToggleButtonContainer = styled.div`
	position: absolute;
	right: -20px;
	top: 20px;
	cursor: pointer;
	width: 40px;
	height: 40px;
	justify-content: center;
	align-items: center;
	display: flex;
`

const ToggleButtonBox = styled.div`
	width: 32px;
	height: 32px;
	background: white;
	border: 1px solid ${COLOR_BORDER_PRIMARY};
	border-radius: 30px;
	padding: 7px 5px;

	:hover {
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
	}
`

const SidebarContent = styled.div`
	/* margin-right: 16px; */
	height: 100%;
	width: ${SUB_SIDE_BAR_WIDTH - SUB_SIDE_BAR_PADDING_RIGHT};
	background: ${COLOR_LIGHT_BACKGROUND};
	padding-top: 64px;
	box-shadow: 1px 0 0 0 #e8f1f1;
	display: flex;
	flex-direction: row;
	overflow: hidden;
`
const SidebarContentWrap = styled.div`
	display: ${props => (props.isVisible === false ? 'none' : 'block')};
	width: 100%;
	overflow-y: auto;
`
