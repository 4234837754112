import React from 'react'
import PrescriptionManager from './PrescriptionManager'
import { PrescriptionTypes } from '../constants/types'

const AcupunctureManager = () => {
  return <PrescriptionManager type={PrescriptionTypes.acupuncture.key} />
}

AcupunctureManager.propTypes = {}

export default AcupunctureManager
