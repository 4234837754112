import React, { PureComponent, createRef } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Query, withApollo } from 'react-apollo'

import {
	GET_RECORD_DETAIL_WITH_TEMPLATE,
	GET_RESEARCH_RECORD_DETAIL_WITH_TEMPLATE,
} from '../../graphql/queries/record'
import ErrorView from '../common/error/ErrorView'
import RecordUpdateTemplateView from './RecordUpdateTemplateView'
import LoadingView from '../common/loader'

/**
 * 양식 업데이트 비교
 */
class RecordUpdateTemplate extends PureComponent {
	static propTypes = {
		isOnResearchPage: PropTypes.bool,
	}

	static defaultProps = {
		isOnResearchPage: false,
	}

	updatingChartRef = createRef()

	handleSubmit = () => {
		this.updatingChartRef.current.handleSubmit()
	}

	render() {
		const { isOnResearchPage, code, recordId, researchId } = this.props
		let query, dataKey, variables
		if (code == null) return <LoadingView />
		if (isOnResearchPage === true) {
			query = GET_RESEARCH_RECORD_DETAIL_WITH_TEMPLATE
			dataKey = 'researchRecordDetail'
			variables = {
				recordCode: code,
				researchId,
			}
		} else {
			query = GET_RECORD_DETAIL_WITH_TEMPLATE
			dataKey = 'recordDetail'
			variables = {
				recordId,
			}
		}

		return (
			<Query query={query} variables={variables}>
				{({ data, loading, error }) => {
					if (error) return <ErrorView error={error} />
					if (loading) return <LoadingView />

					let id, visitedDate, medInfos, templateMedInfos, lastTemplateChangeId
					if (data != null && data[dataKey] != null && data[dataKey].record != null) {
						const { template } = data[dataKey].record
						;({ id, medInfos } = data[dataKey].record)
						templateMedInfos = template.medInfos
						lastTemplateChangeId = template.lastChange.id
						visitedDate = data[dataKey].record.visitedDate
					} else {
						return <ErrorView />
					}

					return (
						<RecordUpdateTemplateView
							medInfos={medInfos}
							templateMedInfos={templateMedInfos}
							lastTemplateChangeId={lastTemplateChangeId}
							loading={loading}
							recordId={id}
							ref={this.updatingChartRef}
							onSubmit={this.handleSubmit}
							refetchQuery={{
								query,
								variables,
							}}
							visitedDate={visitedDate}
							{...this.props}
						/>
					)
				}}
			</Query>
		)
	}
}

export default withRouter(withApollo(RecordUpdateTemplate))
