/**
 * [Component] 환자 목록
 *
 * 환자 목록 페이지네이션 & 테이블 뷰
 */

import React, { PureComponent } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { Table, Pagination, Dropdown } from 'semantic-ui-react'

import PatientListItem from './PatientListItem'
import TableLoader from '../../common/loader/TableLoader'

import { GET_DOCTOR_PATIENTS } from '../../../graphql/queries/patient'

import './PatientList.css'
import { RESEARCH_TAB_ID_ALL } from '../../../constants'
import TableError from '../../common/error/TableError'
import InputSearch from '../../common/basic/InputSearch'

import { COLOR_BORDER_GRAY } from '../../../constants/styles'
import TableSearch from '../../common/TableSearch'
import EmptyTable from '../../common/empty/EmptyTable'
import { ResearchSamplingPlan } from '../../../enum'
import SingleRecordModal from '../../record/SingleRecordModal'

const SEARCH_OPTIONS = [
	{
		key: 'patientInfo',
		value: 'patientInfo',
		text: '차트번호 및 이름',
	},
	{
		key: 'summary',
		value: 'summary',
		text: '진료 내용',
	},
]

const SEARCH_OPTION_PATIENT_INFO = SEARCH_OPTIONS[0].value

/**
 * 내 환자 보기 환자목록
 */
class PatientList extends PureComponent {
	static propTypes = {
		history: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
		filter: PropTypes.shape({
			search: PropTypes.object,
			fields: PropTypes.array,
		}),
		perPage: PropTypes.number.isRequired,
		research: PropTypes.shape({
			id: PropTypes.number,
			title: PropTypes.string,
			hashPatient: PropTypes.bool,
		}),
		researchTabId: PropTypes.number.isRequired,
		isPrivacyHashed: PropTypes.bool,
		error: PropTypes.shape(),
		onSetPatientCount: PropTypes.func,
		onClickAddPatient: PropTypes.func,
		onOpenSingleRecordModal: PropTypes.func.isRequired,
	}

	static defaultProps = {
		filter: null,
		error: null,
	}

	constructor(props) {
		super(props)

		this.state = {
			searchText: '',
			searchOption: SEARCH_OPTION_PATIENT_INFO,
			searchName: '',
			searchPatientCode: '',
		}
	}

	refetch = () => {
		if (this.refetch) this.refetch()
	}

	handleClickPatient = ({ id, lastRecordId, patientResearches }) => e => {
		e.stopPropagation()

		const { history, onOpenSingleRecordModal } = this.props
		const research = _.isEmpty(patientResearches) ? null : patientResearches[0].research

		if (research != null && research.samplingPlan === ResearchSamplingPlan.ONE_OFF.value) {
			onOpenSingleRecordModal(lastRecordId)
		} else {
			history.push(`/patients/${id}${lastRecordId != null ? `/records/${lastRecordId}` : ''}`)
		}
	}

	handleSearchChange = (e, { value }) => {
		this.setState({ searchText: value })
	}

	handleSearchOptionChange = (e, { value }) => {
		this.setState({ searchOption: value })
	}

	handleSearchPatientChange = (e, { name, value }) => {
		this.setState({
			[name]: value,
		})
	}

	handlePageChange = fetchMore => (e, { activePage }) => {
		const { perPage } = this.props
		const filter = this.createFilter()

		fetchMore({
			variables: {
				pagination: {
					page: activePage,
					perPage,
				},
				filter,
			},
			updateQuery: (prev, { fetchMoreResult }) => {
				if (!fetchMoreResult) return prev

				return fetchMoreResult
			},
		})
	}

	createFilter = () => {
		const { searchText, searchName, searchPatientCode, searchOption } = this.state
		const { researchTabId, isPrivacyHashed } = this.props

		return {
			...(searchText && {
				search: {
					query: searchText,
					...(isPrivacyHashed === true && {
						fields: [searchOption],
					}),
				},
			}),
			...(researchTabId !== RESEARCH_TAB_ID_ALL && {
				fields: [
					{
						// 필터링할 필드
						name: 'researchId',
						value: typeof researchTabId === 'number' ? researchTabId.toString() : '0',
					},
					...(searchOption === SEARCH_OPTION_PATIENT_INFO
						? [
								...(searchName
									? [
											{
												name: 'name',
												value: searchName,
												isHashed: isPrivacyHashed,
											},
									  ]
									: []),
								...(searchPatientCode
									? [
											{
												name: 'patientCode',
												value: searchPatientCode,
											},
									  ]
									: []),
						  ]
						: []),
				],
			}),
		}
	}

	/**
	 * 테이블 헤더 렌더링
	 */
	renderTableHeader = () => {
		const { research } = this.props

		let hashPatient
		if (research != null) {
			;({ hashPatient } = research)
		}
		return (
			<Table.Row textAlign="center" style={{ height: '60px', border: '1px solid #f2f2f2' }}>
				{/* {hashPatient !== true && ( */}
				<Table.HeaderCell style={{ width: 120 }}>차트번호</Table.HeaderCell>
				{/* )} */}
				{hashPatient !== true && <Table.HeaderCell style={{ width: 90 }}>이름</Table.HeaderCell>}
				<Table.HeaderCell style={{ width: 70 }}>성별</Table.HeaderCell>
				<Table.HeaderCell style={{ width: 120 }}>나이</Table.HeaderCell>
				<Table.HeaderCell style={{ minWidth: 150 }}>
					{research != null ? '등록 번호/설명' : '프로젝트'}
				</Table.HeaderCell>
				<Table.HeaderCell style={{ width: 200 }}>최근방문</Table.HeaderCell>
			</Table.Row>
		)
	}

	/**
	 * 테이블 행 렌더링
	 */
	renderTableRow = patient => {
		const { research } = this.props

		return (
			<Table.Row
				textAlign="center"
				onClick={this.handleClickPatient(patient)}
				style={{ cursor: 'pointer', border: '1px solid #f2f2f2', height: '70.6px' }}
				key={`${patient.id}`}
				verticalAlign="middle"
			>
				<PatientListItem research={research} {...patient} />
			</Table.Row>
		)
	}

	render() {
		const {
			perPage,
			error: researchError,
			research,
			onSetPatientCount,
			onClickAddPatient,
		} = this.props

		const { searchText, searchOption, searchName, searchPatientCode } = this.state

		const filter = this.createFilter()

		// 환자 이름/차트번호 암호화 여부
		let isPrivacyHashed
		if (research) {
			isPrivacyHashed = research.hashPatient
		} else {
			isPrivacyHashed = false
		}

		return (
			<Query
				query={GET_DOCTOR_PATIENTS}
				variables={{
					pagination: {
						page: 1,
						perPage,
					},
					filter,
				}}
				fetchPolicy="network-only"
				onCompleted={({ doctorPatients: { totalCount } }) => {
					onSetPatientCount(totalCount)
				}}
			>
				{({ loading, data, error, fetchMore, refetch }) => {
					this.update = refetch

					let content, totalCount, page, perPage, items

					if (loading === true) content = <TableLoader size="tall" />
					else if (error != null || researchError != null)
						content = <TableError isMini height={400} />
					else {
						;({
							doctorPatients: { items, totalCount, page, perPage },
						} = data)

						if (items.length === 0) {
							content = (
								<EmptyTable
									onClick={onClickAddPatient}
									emptyMessage="새로운 데이터를 등록해 보세요!"
								/>
							)
						} else {
							content = items.map(patient => this.renderTableRow(patient))
						}
					}

					return (
						<React.Fragment>
							<SearchBox>
								<TableSearch>
									{isPrivacyHashed === true && searchOption === SEARCH_OPTION_PATIENT_INFO && (
										<SearchHashedPrivacy
											name={searchName}
											patientCode={searchPatientCode}
											onChange={this.handleSearchPatientChange}
										/>
									)}

									{(isPrivacyHashed === false || searchOption !== SEARCH_OPTION_PATIENT_INFO) && (
										<SearchContainer>
											<InputSearch
												// style={{ marginRight: 18 }}
												value={searchText}
												onChange={this.handleSearchChange}
												fluid
												placeholder="이름, 성별, 코드, 증상 등"
											/>
										</SearchContainer>
									)}

									{isPrivacyHashed === true && (
										<SearchOptionDropdown
											value={searchOption}
											onChange={this.handleSearchOptionChange}
										/>
									)}
								</TableSearch>
							</SearchBox>
							<Table
								style={styles.table}
								unstackable
								selectable
								verticalAlign="middle"
								textAlign="center"
							>
								<Table.Header>{this.renderTableHeader()}</Table.Header>
								<Table.Body style={{ padingRight: 0, width: '100%', border: '1px solid #f2f2f2' }}>
									{content}
								</Table.Body>
							</Table>
							<PaginationBox>
								<Pagination
									style={{
										margin: '40px auto',
									}}
									activePage={page || 1}
									totalPages={totalCount ? Math.ceil(totalCount / perPage) : 1}
									onPageChange={this.handlePageChange(fetchMore)}
								/>
							</PaginationBox>
						</React.Fragment>
					)
				}}
			</Query>
		)
	}
}

export default withRouter(PatientList)

/**
 * 비식별화 환자 검색
 */
const SearchHashedPrivacy = ({ name, patientCode, onChange }) => {
	return (
		<HashedSearchBox>
			{/* <HashedSearchDesc>환자명과 차트번호를 입력해 검색하세요.</HashedSearchDesc> */}
			<InputSearch
				style={styles.searchInputName}
				name="searchName"
				value={name}
				placeholder="환자명"
				onChange={onChange}
			/>
			<InputSearch
				onChange={onChange}
				style={styles.searchInput}
				name="searchPatientCode"
				placeholder="차트번호"
				value={patientCode}
			/>
		</HashedSearchBox>
	)
}

SearchHashedPrivacy.propTypes = {
	name: PropTypes.string,
	patientCode: PropTypes.string,
	onChange: PropTypes.func.isRequired,
}

SearchHashedPrivacy.defaultProps = {
	name: '',
	patientCode: '',
}

/**
 * 검색 옵션
 */
const SearchOptionDropdown = ({ value, onChange }) => {
	return (
		<Dropdown
			value={value}
			onChange={onChange}
			style={styles.searchOptions}
			inline
			options={SEARCH_OPTIONS}
			defaultValue={SEARCH_OPTION_PATIENT_INFO}
		/>
	)
}

SearchOptionDropdown.propTypes = {
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
}

const SearchContainer = styled.div`
	flex: 1;
`

const PaginationBox = styled.div`
	position: absolute;
	width: 100%;
	bottom: -120px;
	text-align: center;
`

const HashedSearchBox = styled.div`
	display: inline-block;
`

const SearchBox = styled.div`
	border-top: ${props => props.theme.BORDER_GRAY};
	border-left: ${props => props.theme.BORDER_GRAY};
	border-right: ${props => props.theme.BORDER_GRAY};
`

const styles = {
	totalCount: {
		marginLeft: 20,
		marginRight: 20,
		marginBottom: 20,
		marginTop: 20,
		textAlign: 'left',
		fontWeight: 'bold',
		display: 'inline-block',
		verticalAlign: 'top',
	},
	tools: {
		textAlign: 'left',
		marginBottom: 30,
	},
	searchBasic: { marginRight: 18, marginTop: 8 },
	searchInput: {
		display: 'inline-block',
		width: 180,
	},
	searchInputName: {
		display: 'inline-block',
		width: 120,
		marginRight: 10,
	},
	searchOptions: {
		verticalAlign: 'top',
		marginTop: 9,
		marginLeft: 20,
	},
	table: {
		marginTop: 0,
		marginBottom: 0,
		borderRadius: 0,
	},
}
