/**
 * Express API 로 Fetch 보낼 수 있는 기능 제공
 */

import React, { PureComponent } from 'react'
import { Query, graphql } from 'react-apollo'
import compose from 'lodash.flowright'
import { GET_USER_INFO } from '../graphql/queries/user'
import { REQUEST_ACCESS_TOKEN, UPDATE_USER_INFO } from '../graphql/mutations/user'
import { BASE_URL } from '../config'

const withFetch = WrappedComponent => {
	class WithFetchComponent extends PureComponent {
		handleRequestAccessToken = async (after, params) => {
			try {
				const { refreshToken, updateUserInfo, requestAccessToken, userId } = this.props

				const {
					data: {
						requestAccessToken: { accessToken },
					},
				} = await requestAccessToken({
					variables: {
						refreshToken,
						userId,
					},
				})

				updateUserInfo({
					variables: {
						accessToken,
					},
				})

				return after(accessToken)
			} catch (e) {
				alert('오류가 발생했습니다.')
				window.location.href = BASE_URL
				// TOOD: logout
			}
		}

		/**
		 * 리퀘스트 전송
		 * @param params.url url
		 * @param params.url method
		 * @param params.url newAccessToken
		 */
		handleFetch = async params => {
			const { url, contentType, method, newAccessToken, body } = params
			const { accessToken } = this.props

			const fetchItem = async token => {
				return fetch(url, {
					method: method || 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
						// 'Access-Control-Allow-Origin': 'null',
						'Content-Type': contentType,
					},
					body,
					// mode: 'cors',
				})
			}
			if (accessToken != null) {
				try {
					const response = await fetchItem(newAccessToken || accessToken)

					if (response.ok !== true) {
						if (response.status === 401 || response.status === 410) {
							return this.handleRequestAccessToken(fetchItem, params)
						}
						alert('오류가 발생했습니다.')
					} else {
						return response
					}
				} catch (e) {
					if (newAccessToken == null && (e.status === 410 || e.status === 401)) {
						return this.handleRequestAccessToken(fetchItem, params)
					}
				}
			} else {
				window.location.href = BASE_URL
			}
		}

		render() {
			return <WrappedComponent {...this.props} onFetch={this.handleFetch} />
		}
	}

	return compose(
		graphql(REQUEST_ACCESS_TOKEN, {
			name: 'requestAccessToken',
		}),
		graphql(UPDATE_USER_INFO, {
			name: 'updateUserInfo',
		}),
		graphql(GET_USER_INFO, {
			props: ({ data }) => {
				const {
					userInfo: {
						user: { id },
						accessToken,
						refreshToken,
					},
				} = data

				return {
					userId: id,
					accessToken,
					refreshToken,
				}
			},
		})
	)(WithFetchComponent)
}

export default withFetch
