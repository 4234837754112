import React, { PureComponent } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Form, Header, Input, Button, Message } from 'semantic-ui-react'
import compose from 'lodash.flowright'
import { graphql } from 'react-apollo'
import { UPDATE_USER_ACCOUNT } from '../graphql/mutations/user'
import { matchErrorCode } from '../libs/errorHelper'
import errorTypes from '../errorTypes'
import SubContainer from '../components/common/container/SubContainer'

const INITIAL_STATE = {
	password: '',
	newPassword: '',
	newPasswordRepeat: '',
	errors: {
		password: false,
		newPassword: false,
		newPasswordRepeat: false,
	},
	message: '',
}

class UserProfileEditAccount extends PureComponent {
	static propTypes = {
		updateUserAccount: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = INITIAL_STATE
	}

	handleChange = (e, { name, value }) => {
		this.setState({
			[name]: value,
		})
	}

	handleSubmit = async () => {
		const { password, newPassword, newPasswordRepeat } = this.state
		const { updateUserAccount } = this.props

		if (!password || !newPassword || !newPasswordRepeat) {
			return this.setState({
				message: '모든 항목을 채워주세요.',
				errors: {
					password: !password,
					newPassword: !newPassword,
					newPasswordRepeat: !newPasswordRepeat,
				},
			})
		}

		if (newPassword !== newPasswordRepeat) {
			return this.setState({
				message: '비밀번호 확인이 비밀번호와 일치하지 않습니다.',
				errors: {
					password: false,
					newPassword: false,
					newPasswordRepeat: true,
				},
			})
		}

		try {
			await updateUserAccount({
				variables: {
					password,
					newPassword,
				},
			})

			alert('비밀번호가 변경되었습니다.')

			this.setState({
				...INITIAL_STATE,
				errors: {
					newPassword: false,
					password: false,
					newPasswordRepeat: false,
				},
			})
		} catch (e) {
			const errMatch = matchErrorCode(e, errorTypes.UNAUTHORIZED_ERROR)

			if (errMatch) {
				this.setState({
					message: '현재 비밀번호가 잘못되었습니다.',
					errors: {
						password: true,
						newPassword: false,
						newPasswordRepeat: false,
					},
				})
			} else {
				const errMatch = matchErrorCode(e, errorTypes.INVALID_VALUE_ERROR)

				if (errMatch) {
					const invalidItems = errMatch.invalidArgs
					if (invalidItems) {
						const errs = this.state.errors

						const msgs = invalidItems.map(err => {
							errs[err.field] = true
							return `* ${err.message}`
						})

						this.setState({
							errors: errs,
							message: msgs.join('\n'),
						})
					}
				} else {
					alert('오류가 발생했습니다.')
				}
			}
		}
	}

	render() {
		const { password, newPassword, newPasswordRepeat, message, errors } = this.state

		return (
			<SubContainer>
				{message && <Message error content={message} />}
				<Form style={{ width: 500 }} autocomplete="false">
					<Form.Field
						label="현재 비밀번호"
						control={Input}
						value={password}
						error={errors.password}
						type="password"
						name="password"
						placeholder="현재 비밀번호"
						onChange={this.handleChange}
						autoComplete="false"
					/>
					<Form.Field
						label="새 비밀번호"
						control={Input}
						type="password"
						value={newPassword}
						error={errors.newPassword}
						name="newPassword"
						placeholder="새 비밀번호"
						onChange={this.handleChange}
						autoComplete="false"
					/>
					<Form.Field
						label="새 비밀번호 확인"
						control={Input}
						type="password"
						value={newPasswordRepeat}
						error={errors.newPasswordRepeat}
						name="newPasswordRepeat"
						placeholder="새 비밀번호 확인"
						onChange={this.handleChange}
						autoComplete="false"
					/>
					<SubmitButton size="big" primary content="비밀번호 변경" onClick={this.handleSubmit} />
				</Form>
			</SubContainer>
		)
	}
}

export default compose(
	graphql(UPDATE_USER_ACCOUNT, {
		name: 'updateUserAccount',
	})
)(UserProfileEditAccount)

const SubmitButton = styled(Button)`
	margin-top: 25px !important;
`
