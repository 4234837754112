import React from 'react'
import PropTypes from 'prop-types'
import { Segment } from 'semantic-ui-react'
import PageHeader from '../common/PageHeader'

/**
 * 프로젝트 탈락 처리용 레코드 뷰
 */
const DropRecordView = props => {
	const { description, createdAt } = props
	return (
		<Segment style={{ padding: 30 }}>
			<h6 style={{ marginBottom: 10 }}>탈락 사유</h6>
			{description}
		</Segment>
	)
}

DropRecordView.propTypes = { description: PropTypes.string }
DropRecordView.defaultProps = { description: null }

export default DropRecordView
