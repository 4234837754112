import React from 'react'
import PropTypes from 'prop-types'
import { Segment, Label } from 'semantic-ui-react/dist/commonjs'
import moment from 'moment'
import 'moment/locale/ko'
import { assembleMedInfos } from '../../../libs/medInfoHelper'

import ChartingBoard from '../../charting/ChartingBoard'

const styles = {
	containerWrap: { minWidth: 1080, maxWidth: 1600, margin: 'auto', textAlign: 'left' },
}

moment.locale('ko')

const ResearchTemplateHistoryDetailView = props => {
	const { message, user, medInfos, createdAt, versionCode, template } = props
	return (
		<div style={{ marginTop: 50, ...styles.containerWrap }}>
			<Segment.Group>
				{/* <Segment style={{minHeight: 70,backgroundColor: 'rgb(237, 240, 241)'}}>
          <div style={{fontWeight: '600', fontSize: 16, }}>{message} </div>
        </Segment> */}
				<Segment style={{ backgroundColor: 'rgb(237, 240, 241)', textAlign: 'right' }}>
					by <b>{user.name}</b>,{' '}
					<span style={{ color: '#999' }}>{moment(createdAt).calendar()} </span>
				</Segment>
			</Segment.Group>
			<div
				style={{
					padding: '15px 15px 50px',
					marginBottom: 50,
					borderRadius: 10,
					border: '1px solid #ddd',
				}}
			>
				<ChartingBoard
					{...props}
					onUpdateMedInfo={() => {}}
					onGenerateMedInfo={() => {}}
					onOpenSectionModal={() => {}}
					medInfos={assembleMedInfos(JSON.parse(JSON.stringify(medInfos)))}
					researches={[]}
					templateMode={template}
					readOnly={!template}
				/>
			</div>
		</div>
	)
}

ResearchTemplateHistoryDetailView.propTypes = {
	user: PropTypes.object,
}

export default ResearchTemplateHistoryDetailView
