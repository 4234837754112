export const defaults = {
	isScrolledDown: false,
}

export const resolvers = {
	Mutation: {
		// 헤더 높이 이하로 스크롤 내려갔는지 여부 설정
		setScrollStatus(_, { isDown }, { cache }) {
			cache.writeData({
				data: {
					isScrolledDown: isDown,
				},
			})

			return isDown
		},
	},
}
