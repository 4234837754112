import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'semantic-ui-react'
import SegmentToggle from '../../common/basic/SegmentToggle'

/**
 * 연구 설정 토글 개별 필드
 */
const ResearchNewFormOption = ({ error, label, enums, value, onChange }) => {
	const items = Object.values(enums)

	const passError = value == null ? error : null
	return (
		<Form.Field error={passError}>
			<label>{label}</label>
			<SegmentToggle onChange={onChange} error={passError} items={items} active={value} />
		</Form.Field>
	)
}

ResearchNewFormOption.propTypes = {
	label: PropTypes.string,
	enums: PropTypes.object,
	value: PropTypes.string,
	onChange: PropTypes.func,
	error: PropTypes.object,
}

ResearchNewFormOption.defaultProps = {
	error: null,
}

export default ResearchNewFormOption
