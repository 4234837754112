import styled from 'styled-components'
import { COLOR_TEXT_LIGHTGRAY } from '../../../constants/styles'

const DescriptionText = styled.div`
  color: ${COLOR_TEXT_LIGHTGRAY}
  font-size: 12px;
  font-weight: 300;
  display: ${props => (props.inline === true ? 'inline-block' : 'block')};
`

export default DescriptionText
