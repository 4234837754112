import gql from 'graphql-tag'
import { FRAGMENT_RESEARCH_SIDE_MENU_ITEM } from '../fragments/app'
import { MED_INFO_FRAGMENT } from '../fragments/medInfo'

/**
 * 문의 보내기
 */
export const SUBMIT_CONTACT = gql`
	mutation submitContact($contactInput: ContactInput) {
		submitContact(contactInput: $contactInput)
	}
`

/**
 * 최근 약관 동의 내역 수정하기
 */
export const UPDATE_LATEST_TERMS = gql`
	mutation updateLatestTerms($serviceTermId: Int!, $privacyTermId: Int!) {
		updateLatestTerms(serviceTermId: $serviceTermId, privacyTermId: $privacyTermId) @client {
			serviceTermId
			privacyTermId
		}
	}
`

/**
 * 토스트 메시지 추가하기
 */
export const ADD_TOAST_MESSAGE = gql`
	mutation addToastMessage(
		$id: String!
		$content: String
		$autoDismiss: Boolean
		$success: Boolean
		$error: Boolean
	) {
		addToastMessage(
			id: $id
			content: $content
			autoDismiss: $autoDismiss
			success: $success
			error: $error
		) @client {
			id
		}
	}
`

/**
 * 스크롤 내려갈 경우 업데이트
 */
export const SET_SCROLL_STATUS = gql`
	mutation setScrollStatus($isDown: Boolean) {
		setScrollStatus(isDown: $isDown) @client
	}
`

/**
 * 서브 사이드메뉴 열고 닫기
 */
export const TOGGLE_SUB_SIDE_MENU = gql`
	mutation toggleSubSideMenu($shouldOpen: Boolean) {
		toggleSubSideMenu(shouldOpen: $shouldOpen) @client
	}
`

/**
 * 프로젝트 페이지 서브 사이드 메뉴 내용 설정하기
 */
export const SET_RESEARCH_SIDE_MENU = gql`
	mutation setResearchSideMenu($researches: [Object], $currentId: Int) {
		setResearchSideMenu(researches: $researches, currentId: $currentId) @client {
			id
			items {
				...ResearchSideMenuItemParts
			}
		}
	}
	${FRAGMENT_RESEARCH_SIDE_MENU_ITEM}
`

/**
 * 서브 사이드 메뉴 개별 프로젝트 항목 열고 닫기
 */
export const TOGGLE_OPEN_RESEARCH_SIDE_MENU_ITEM = gql`
	mutation toggleResearchSideMenuItem($id: Int) {
		toggleResearchSideMenu(id: $id) @client {
			...ResearchSideMenuItemParts
		}
	}
	${FRAGMENT_RESEARCH_SIDE_MENU_ITEM}
`

/**
 * 프로젝트 페이지 이동 시 서브 사이드 상태 설정하기
 */
export const HANDLE_PAGE_CHANGE_RESEARCH_SIDE_MENU = gql`
	mutation handleResearchPageChange($currentId: Int) {
		handleResearchPageChange(currentId: $currentId) @client {
			id
			items {
				...ResearchSideMenuItemParts
			}
		}
	}
	${FRAGMENT_RESEARCH_SIDE_MENU_ITEM}
`

/**
 * 환자 설문 진행중 상태 변경
 */
export const TOGGLE_DOING_SURVEY = gql`
	mutation($shouldStop: Boolean, $isPatient: Boolean) {
		toggleDoingSurvey(shouldStop: $shouldStop, isPatient: $isPatient) @client
	}
`

/**
 * 환자 설문 진행중 상태 변경
 */
export const SET_PATIENT_SURVEY_STATUS = gql`
	mutation($input: PatientSurveyStatusInput!, $isPatient: Boolean) {
		setPatientSurveyStatus(input: $input, isPatient: $isPatient) @client {
			recordId
			researchId
			patientId
			patientName
			patientCode
			templateName
			medInfos {
				...MedInfoParts
			}
		}

		toggleDoingSurvey(shouldOpen: true, isPatient: $isPatient) @client
	}

	${MED_INFO_FRAGMENT}
`

/**
 * 환자 설문 종료 처리(초기화)
 */
export const FINISH_PATIENT_SURVEY = gql`
	mutation($input: PatientSurveyStatusInput!) {
		resetPatientSurveyStatus @client {
			recordId
			researchId
			patientId
			patientName
			patientCode
			templateName
			templateDescription
			medInfos {
				...MedInfoParts
			}
		}

		toggleDoingSurvey(shouldOpen: false) @client
	}

	${MED_INFO_FRAGMENT}
`

/**
 * 단계 변경
 */

export const SET_PATIENT_SURVEY_STAGE = gql`
	mutation($index: Int!, $recordId: Int!) {
		setPatientSurveyStage(index: $index, recordId: $recordId) @client
	}
`

/**
 * 서명 처리
 */
export const UPDATE_PATIENT_SIGN = gql`
	mutation(
		$patientId: Int
		$patientName: String
		$recordId: Int
		$researchId: Int
		$didAgree: Boolean
		$isOpen: Boolean
	) {
		updatePatientSign(
			patientId: $patientId
			patientName: $patientName
			recordId: $recordId
			researchId: $researchId
			didAgree: $didAgree
			isOpen: $isOpen
		) @client {
			patientId
			patientName
			recordId
			researchId
			isOpen
			didAgree
		}
	}
`

/**
 * 내 데이터 화면 연구 선택 항목 변경
 */
export const SET_RESEARCH_TAB_ID = gql`
	mutation($id: Int) {
		setResearchTabId(id: $id) @client
	}
`
