import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Form, TextArea, Button } from 'semantic-ui-react'
import compose from 'lodash.flowright'
import styled from 'styled-components'
import { graphql } from 'react-apollo'
import {
	EDIT_COMMENT_NAME,
	EDIT_COMMENT_NICK,
	WRITE_COMMENT_NAME,
	WRITE_COMMENT_NICK,
} from '../../graphql/mutations/community'
import { isTooMuchString, isEmptyString } from '../../libs/stringHelper'
import { Avatar } from '../common/Avatar'
import { COLOR_PRIMARY, PAGE_MOBILE_MAX_WIDTH } from '../../constants/styles'

/**
 * 댓글 작성을 위한 폼
 * 프로필 정보와 저장 버튼. 대댓글 달기 & 수정 가능.
 */
class CommentWrite extends PureComponent {
	static propTypes = {
		user: PropTypes.object,
		useNick: PropTypes.bool,
		noProfile: PropTypes.bool,
		edit: PropTypes.bool,
		text: PropTypes.string,
	}

	constructor(props) {
		super(props)

		this.state = {
			text: props.edit ? props.text : '',
		}
	}

	handleChange = (e, { value }) => this.setState({ text: value })

	handleSubmit = async () => {
		const {
			edit,
			useNick,
			entityType,
			entityId,
			parentId,
			onUpdate,
			commentId,
			replyToId,
			editCommentName,
			editCommentNick,
			writeCommentName,
			writeCommentNick,
			onEdit,
			onReply,
		} = this.props
		const { text } = this.state
		if (isEmptyString(text)) {
			alert('댓글은 한글자 이상 입력해 주셔야 등록됩니다.')
		} else if (isTooMuchString(text)) {
			alert('댓글은 천글자 미만으로 입력해 주시기 바랍니다')
		} else {
			let onSubmit
			if (edit) {
				if (useNick) {
					onSubmit = editCommentNick
				} else {
					onSubmit = editCommentName
				}

				await onSubmit({
					variables: {
						text,
						commentId,
					},
				})
				onEdit()
			} else {
				if (useNick) {
					onSubmit = writeCommentNick
				} else {
					onSubmit = writeCommentName
				}

				await onSubmit({
					variables: {
						commentInput: {
							text,
							entityType,
							entityId,
							parentId,
							replyToId,
						},
					},
				})
				if (replyToId) onReply()
			}

			this.setState({
				text: '',
			})

			onUpdate()
		}
	}

	render() {
		const { user, useNick, noProfile, edit } = this.props
		const { text } = this.state

		return (
			<div>
				{!noProfile && (
					<div style={{ marginRight: 20, marginTop: 20 }}>
						<Avatar image={user.profileImage} width={35} style={{ marginRight: 15 }} />
						<b style={{ fontSize: 16 }}>{useNick ? user.nickname : user.name}</b>
					</div>
				)}
				{
					<Form style={{ display: 'flex', marginTop: 10 }}>
						<TextArea
							value={text}
							onChange={this.handleChange}
							style={{ flex: 1, minHeight: 80, borderRadius: 2, borderColor: '#aaa' }}
							placeholder="의견을 남겨주세요. 환자를 식별할 수 있는 개인정보(실명, 주소, 전화번호 등)가 노출되지 않도록 주의해 주세요. "
						/>
						<CommentButton onClick={this.handleSubmit}>{edit ? '수정' : '작성'}</CommentButton>
					</Form>
				}
			</div>
		)
	}
}

export default compose(
	graphql(EDIT_COMMENT_NAME, {
		name: 'editCommentName',
	}),
	graphql(EDIT_COMMENT_NICK, {
		name: 'editCommentNick',
	}),
	graphql(WRITE_COMMENT_NAME, {
		name: 'writeCommentName',
	}),
	graphql(WRITE_COMMENT_NICK, {
		name: 'writeCommentNick',
	})
)(CommentWrite)

const CommentButton = styled(Button)`
	width: 80px;
	height: auto;
	margin-left: 20px !important;
	margin-top: 10px;
	color: ${COLOR_PRIMARY} !important;
	font-weight: 700 !important;

	@media (max-width: ${PAGE_MOBILE_MAX_WIDTH}px) {
		width: 45px;
		height: auto;
		margin-left: 5px !important;
		padding: 0px !important;
	}
`
