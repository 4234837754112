import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid } from 'semantic-ui-react'
import { InputSize } from '../../enum'
import { ChartingSection } from './ChartingSection'
import ErrorView from '../common/error/ErrorView'
import ChartDragLayer from './ChartDragLayer'
import ChartAddButtonView from './field/ChartAddButtonView'
import useDimensions from '../../hooks/useDimensions'
import useSubSideMenuState from '../../hooks/useSubSideMenuState'
import AddFieldDropdown from './control/AddFieldDropdown'

const ChartContent = React.memo(
	props => {
		const isOpen = useSubSideMenuState()
		const [dimensionRef, { width }] = useDimensions(null, isOpen)
		const {
			templateMode,
			medInfos,
			forPrint,
			onOpenSectionModal,
			onSearchMedInfoTypes,
			onCreateSectionFromType,
		} = props
		const isEditable = templateMode === true

		const contentWidth = width + (isOpen !== true && 220)

		try {
			return (
				<>
					<div ref={dimensionRef}>
						<Grid
							stackable
							style={{
								marginLeft: '-0.5rem',
								marginBottom: '-0.5rem',
								marginRight: '-0.5rem',
								marginTop: '-0.5rem',
							}}
						>
							{medInfos.map((section, idx) => {
								if (section.medInfoType == null) {
									throw new Error()
								}
								const { size } = section.medInfoType
								let sizeInfo, sectionWidth

								if (forPrint === true) {
									sizeInfo = {
										width: 16,
									}

									sectionWidth = contentWidth
								} else {
									const sInfo = size != null ? InputSize[size] : InputSize.MEDIUM

									sizeInfo = {
										className: sInfo.className,
									}

									sectionWidth = contentWidth * sInfo.widthRatio
									// const { value, text, ...sinfo } = SectionSize[size]
									// sizeInfo = sinfo
								}

								return (
									<Grid.Column
										key={`${section.id}`}
										style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}
										{...sizeInfo}
									>
										<ChartingSection
											{...props}
											width={sectionWidth}
											key={`${section.id}`}
											medInfo={section}
											medInfoId={section.id}
											name={section.medInfoType.name}
											description={section.medInfoType.description}
											medInfoType={section.medInfoType}
											medInfos={section.children}
											medInfoIndex={idx}
											medInfoTypes={section.medInfoType.subTypes}
										/>
									</Grid.Column>
								)
							})}
							{isEditable === true && props.isPartial !== true && (
								<Grid.Column style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }} mobile={16}>
									<SectionAddButton>
										<AddFieldDropdown
											onSearch={onSearchMedInfoTypes}
											trigger={<ChartAddButtonView text="새로운 섹션" />}
											isSection
											onCreateMedInfo={onCreateSectionFromType}
											onOpenTypeModal={() => onOpenSectionModal()}
										/>
									</SectionAddButton>
								</Grid.Column>
							)}
						</Grid>
					</div>
					<ChartDragLayer />
				</>
			)
		} catch (e) {
			return <ErrorView />
		}
	},
	(prev, next) => {
		return prev.medInfos == next.medInfos && prev.readOnly === next.readOnly
	}
)

ChartContent.propTypes = {
	medInfos: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			medInfoType: PropTypes.shape({
				descriptin: PropTypes.string,
				name: PropTypes.string.isRequired,
			}).isRequired,
		})
	).isRequired,
	forPrint: PropTypes.bool,
	templateMode: PropTypes.bool,
	onOpenSectionModal: PropTypes.func,
	isPartial: PropTypes.bool,
	onSearchMedInfoTypes: PropTypes.func,
	onCreateSectionFromType: PropTypes.func,
}

ChartContent.defaultProps = {
	forPrint: null,
}

export default ChartContent

const SectionAddButton = styled.div`
	text-align: center;
	:hover {
		border: ${props => props.theme.BORDER_GRAY};
		box-shadow: ${props => props.theme.BOX_SHADOW_HOVER};
	}
`
