import React from 'react'

const ContentContainer = ({ style, children, className }) => {
	return (
		<div
			className={className}
			style={{
				marginTop: 20,
				// padding: '20px 60px',
				...style,
			}}
		>
			{children}
		</div>
	)
}

export default ContentContainer
