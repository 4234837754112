import styled from 'styled-components'
import { COLOR_TEXT } from '../../../constants/styles'

const LightH3 = styled.h3`
	font-weight: 400;
	color: ${COLOR_TEXT};
	margin-bottom: 30px;

	transition: font-size 0.5s;

	animation: fadein 0.5s;
	@keyframes fadein {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`

export default LightH3
