import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Table, Button, Input } from 'semantic-ui-react'
import { InputType } from '../../enum'
import useLocalMedInfo from '../../hooks/useLocalMedInfo'
import NextButton from './NextButton'

import DataFieldDate from '../charting/field/inputs/DataFieldDate'
import { findEmptyMedInfo } from '../../libs/medInfoHelper'
import { ALERT_SAVE_SURVEY_SUCCESS } from '../../constants/alerts'
import useToastMessage from '../../hooks/useToastMessage'

export default function PatientSurveyQuickPage({
	medInfos,
	patientName,
	templateName,
	onSubmit,
	onOpenModal,
}) {
	const showToastMessage = useToastMessage()
	const [loading, setLoading] = useState(false)

	const hasNullValue = useMemo(() => findEmptyMedInfo(medInfos), [medInfos])

	return (
		<Container>
			<Wrapper>
				<Title>
					<TitleText>
						{patientName != null && `${patientName} 님의 `}
						{templateName}
					</TitleText>
				</Title>
				{medInfos.map(section => (
					<Section key={`${section.id}`} title={section.medInfoType.name}>
						{section.children}
					</Section>
				))}
			</Wrapper>
			<NextButtonWrapper>
				<NextButton
					readOnly={hasNullValue}
					loading={loading}
					onClick={
						hasNullValue === true
							? () => {}
							: () => {
									onOpenModal()
									setLoading(true)
									onSubmit().then(() => {
										showToastMessage(ALERT_SAVE_SURVEY_SUCCESS)
										setLoading(false)
									})
							  }
					}
				>
					마침
				</NextButton>
			</NextButtonWrapper>
		</Container>
	)
}

PatientSurveyQuickPage.propTypes = {
	medInfos: PropTypes.array,
	patientName: PropTypes.string,
	templateName: PropTypes.string,
	onSubmit: PropTypes.func,
	onOpenModal: PropTypes.func,
}

const Section = ({ title, children }) => {
	return (
		<SurveyTable basic="very">
			<Table.Header>
				<Table.Row>
					<SectionTitle colSpan={2}>{title}</SectionTitle>
				</Table.Row>
			</Table.Header>

			<Table.Body>
				{children.map(medInfo => (
					<Question key={`${medInfo.id}`} medInfo={medInfo} />
				))}
			</Table.Body>
		</SurveyTable>
	)
}

Section.propTypes = {
	title: PropTypes.string,
	children: PropTypes.array,
}

const Question = ({ medInfo }) => {
	const [localMedInfo, onUpdate] = useLocalMedInfo(medInfo.id)
	const { medInfoType, value, checkedItems } = localMedInfo || {}
	const { name, inputType } = medInfoType || {}

	const Input = useMemo(() => {
		if (
			inputType === InputType.RADIO.value ||
			inputType === InputType.CHECKBOX.value ||
			inputType === InputType.SELECT.value
		) {
			return (
				<SelectInput
					multiplex={inputType === InputType.CHECKBOX.value}
					onUpdate={onUpdate}
					value={value}
					checkedItems={checkedItems}
					{...medInfoType}
				/>
			)
		} else if (
			inputType === InputType.SHORT_FREE_TEXT.value ||
			inputType === InputType.NUMBER.value ||
			inputType === InputType.VAS_ONLY.value
		) {
			return (
				<TextInput
					isNumber={inputType === InputType.NUMBER.value || inputType === InputType.VAS_ONLY.value}
					onUpdate={onUpdate}
					value={value}
				/>
			)
		} else if (inputType === InputType.DATE.value) {
			return <DataFieldDate onUpdate={onUpdate} value={value} unit={medInfoType.unit} />
		}
	}, [inputType, value, onUpdate, medInfoType, checkedItems])
	if (inputType === InputType.BLOCK.value)
		return (
			<>
				<Table.Row>
					<GroupTitle colSpan={2}>{name}</GroupTitle>
				</Table.Row>
				{medInfo.children.map(child => (
					<Question key={`${child.id}`} medInfo={child} />
				))}
			</>
		)

	return (
		<Table.Row>
			<QuestionText>{name}</QuestionText>
			<InputWrapper>{Input}</InputWrapper>
		</Table.Row>
	)
}

Question.propTypes = {
	medInfo: PropTypes.object,
}

const SelectInput = ({ multiplex, selectOptions, checkedItems, onUpdate, value }) => {
	return (
		<>
			{selectOptions.map(option => {
				const isActive =
					multiplex === true
						? checkedItems.findIndex(item => item.value === option.value) !== -1
						: option.value === value
				return (
					<SelectButton
						key={`${option.value}`}
						active={isActive}
						onClick={() => {
							if (multiplex) {
								onUpdate(
									'checkedItems',
									isActive === true
										? checkedItems.filter(item => item.value !== option.value)
										: checkedItems.concat({
												value: option.value,
												freeWriting: null,
										  })
								)
							} else {
								onUpdate('value', option.value)
							}
						}}
					>
						{option.value}
					</SelectButton>
				)
			})}
		</>
	)
}

SelectInput.propTypes = {
	multiplex: PropTypes.bool,
	selectOptions: PropTypes.array,
	checkedItems: PropTypes.array,
	onUpdate: PropTypes.func,
	value: PropTypes.string,
}

const TextInput = ({ isNumber, onUpdate, value }) => {
	const [stateValue, setStateValue] = useState(value)

	return (
		<QuestionInput
			type={isNumber === true ? 'number' : 'text'}
			value={stateValue}
			onChange={e => setStateValue(e.target.value)}
			onBlur={() => onUpdate('value', stateValue)}
		/>
	)
}

TextInput.propTypes = {
	isNumber: PropTypes.bool,
	onUpdate: PropTypes.func,
	value: PropTypes.string,
}

const Container = styled.div`
	padding: 10vh 4vw 0px 4vw;
	height: 100%;
	overflow-y: auto;

	&::-webkit-scrollbar {
		display: none;
	}
`

const Wrapper = styled.div``

const Title = styled.div`
	margin-top: 50px !important;
	padding: 0px 20px;
`

const SectionTitle = styled(Table.HeaderCell)`
	&&& {
		font-size: 5vmin;
		padding-left: 10px !important;
	}
	font-weight: bold !important;
`
const GroupTitle = styled(Table.Cell)`
	&&& {
		font-size: 4vmin;
		padding-left: 20px !important;
	}
	font-weight: bold !important;
`

const InputWrapper = styled(Table.Cell)`
	border-left: 1px solid #e4e4e4;
	width: 1%;
	white-space: nowrap;
`

const TitleText = styled.h2`
	word-break: all;
	font-size: 6vmin;
`

const SurveyTable = styled(Table)`
	border-bottom: 1px solid #e4e4e4 !important;
`

const QuestionText = styled(Table.Cell)`
	padding-left: 10px !important;
`

const SelectButton = styled(Button)`
	width: 46px;
	height: 46px;
	color: ${props => (props.active === true ? 'white' : '#666666')} !important;
	background-color: ${props => (props.active === true ? '#33b4f3' : 'white')} !important;
	font-weight: bold !important;
	border: ${props => props.active === true && 'none'} !important;
	border-radius: 50% !important;
	margin: 0 10px !important;
	padding: 0 !important;
`

const QuestionInput = styled(Input)`
	width: 100%;
	& > input {
		background: white !important;
	}
`

const NextButtonWrapper = styled.div`
	text-align: center;
	margin: 24px 0px;
`
