import { getReadNoticesFromCookie, setReadNoticesCookie } from '../../libs/cookieHelper'
import { GET_NOTICE_MODAL_OPEN, GET_READ_NOTICE } from '../../graphql/queries/notice'

// 쿠키에서 읽은 공지사항 ID를 가져옴
const cookieReadNoticesId = getReadNoticesFromCookie() || []

const readNoticesId = [...cookieReadNoticesId]

export const defaults = {
	readNoticesId,
	isNoticeModalOpen: false,
}

export const resolvers = {
	Mutation: {
		toggleNoticeModalOpen: (_, { shouldOpen }, { cache }) => {
			try {
				const { isNoticeModalOpen: prev } = cache.readQuery({
					query: GET_NOTICE_MODAL_OPEN,
				})

				const toStatus = shouldOpen != null ? shouldOpen : !prev

				cache.writeQuery({
					query: GET_NOTICE_MODAL_OPEN,
					data: {
						isNoticeModalOpen: toStatus,
					},
				})

				return toStatus
			} catch (e) {
				throw Error(e)
			}
		},
		setReadNotices: (_, { noticesId }, { cache }) => {
			try {
				const { readNoticesId: prev } = cache.readQuery({
					query: GET_READ_NOTICE,
				})

				const toStatus = [...prev, ...noticesId]

				setReadNoticesCookie(toStatus)

				cache.writeQuery({
					query: GET_READ_NOTICE,
					data: {
						readNoticesId: toStatus,
					},
				})

				return toStatus
			} catch (e) {
				throw Error(e)
			}
		},
	},
}
