import gql from 'graphql-tag'

export const GET_CASE_FEEDS = gql`
	query getCaseFeeds(
		$type: CaseListType
		$tagId: ID
		$groupId: Int
		$search: String
		$chartNum: String
	) {
		simpleCases(
			type: $type
			groupId: $groupId
			tagId: $tagId
			search: $search
			chartNum: $chartNum
		) {
			totalCount
			items {
				id
				title
				createdAt
				updatedAt
				tags
				chartNum
				sex
				age
				sharingScope
				commentCount
				creator {
					id
					nickname
					profileImage
				}
				group {
					id
					title
				}
			}
		}
	}
`

export const GET_MY_CASE_GROUPS = gql`
	query getMyCaseGroups {
		myCaseGroups {
			id
			title
		}
	}
`

export const GET_CASE = gql`
	query getCase($id: Int!) {
		simpleCase(id: $id) {
			id
			title
			text
			tags
			createdAt
			updatedAt
			age
			sex
			charts
			chartNum
			sharingScope
			commentCount
			creator {
				id
				nickname
				profileImage
			}
			groupId
			group {
				id
				title
			}
		}
	}
`

export const GET_CASE_TAG = gql`
	query caseTag($id: ID!) {
		caseTag(id: $id) {
			id
			name
			description
			textKorean
			textChinese
			textEnglish
			caseCount
			tagType
			donguibogamLine {
				id
				key
				titleTexts {
					id
					key
					text
				}
			}
		}
	}
`
