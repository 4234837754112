import React, { PureComponent, createRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal, Button } from 'semantic-ui-react'
import ReactToPrint from 'react-to-print'
import {
	BORDER_GRAY,
	COLOR_BACKGROUND_DARK_GRAY,
	BORDER_DARK_GRAY,
} from '../../../constants/styles'

/**
 * 인쇄 모달
 *
 * 인쇄할 내용을 children으로 받음. 다양한 인쇄용 모달 컴퍼넌트 내에 껍데기로 사용하며,
 * react-to-print 라이브러리를 사용해 Component를 인쇄 처리
 */
export default class PrintModal extends PureComponent {
	static propTypes = {
		isOpen: PropTypes.bool,
		onClose: PropTypes.func,
		children: PropTypes.arrayOf(PropTypes.node),
	}

	content = createRef()

	render() {
		const { isOpen, onClose, children } = this.props
		return (
			<Modal open={isOpen} onClose={onClose} closeIcon>
				<Modal.Header>인쇄하기</Modal.Header>
				<Modal.Content>
					<Preview>
						<PrintedBox>
							<Printed className="printed-preview" ref={this.content}>
								{children}
							</Printed>
						</PrintedBox>
					</Preview>
				</Modal.Content>
				<Modal.Actions>
					<ReactToPrint
						trigger={() => <Button primary>인쇄</Button>}
						content={() => {
							if (this.content.current) {
								return this.content.current
							} else {
								alert('인쇄할 내용을 찾지 못했습니다.')
							}
						}}
					/>
				</Modal.Actions>
			</Modal>
		)
	}
}

const Preview = styled.div`
	height: 80vh;
	border-radius: 2px;
	border: ${BORDER_GRAY};
	overflow-y: scroll;
	margin: -20px -40px 0px -40px;
	padding: 30px 0px;
	background: ${COLOR_BACKGROUND_DARK_GRAY};
`

const Printed = styled.div`
	padding: 40px 60px;
`

const PrintedBox = styled.div`
	max-width: 90%;
	margin: auto;
	border: ${BORDER_DARK_GRAY};
	background: white;
	pointer-events: none;
`
