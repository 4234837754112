export const ALERT_UNKNOWN_ERROR = {
	id: 'ALERT_UNKNOWN_ERROR',
	content: '알 수 없는 오류가 발견되었습니다. 관리자에게 문의 바랍니다.',
	success: false,
	error: true,
}

export const ALERT_RESEARCH_INQUIERY_SUCCESS = {
	id: 'RESEARCH_INQUIERY_SUCCESS',
	content: '문의가 성공적으로 접수되었습니다.',
	success: true,
}

export const ALERT_RESEARCH_INQUIERY_FAILED = {
	id: 'RESEARCH_INQUIERY_FAILED',
	content: '오류가 발생해 문의 접수에 실패했습니다. tesser@tesser.co.kr 로 직접 문의 바랍니다.',
	success: false,
	error: true,
}

export const ALERT_BASIC_ERROR = {
	id: 'BASIC_ERROR',
	content: '오류가 발생했습니다. 관리자에게 문의 바랍니다.',
	success: false,
	error: true,
}

export const ALERT_SUCESS_EDIT_RECORD_DATE = {
	id: 'ALERT_SUCESS_EDIT_RECORD_DATE',
	content: '조사일이 변경되었습니다.',
	success: true,
	error: false,
}

export const ALERT_WRONG_PASSWORD = {
	id: 'ALERT_WRONG_PASSWORD',
	content: '비밀번호가 잘못되었습니다.',
	success: false,
	error: true,
}

export const ALERT_DELETE_LOCKED_RECORD = {
	id: 'ALERT_DELETE_LOCKED_RECORD',
	content: '잠금 처리된 연구 데이터가 있어 삭제할 수 없습니다.',
	success: false,
	error: true,
}

export const ALERT_DELETE_NOT_LAST_RECORD = {
	id: 'ALERT_DELETE_NOT_LAST_RECORD',
	content:
		'이 기록 이후에 생성된 기록이 있습니다. 이후 기록을 모두 삭제한 후 이 기록을 삭제할 수 있습니다.',
	success: false,
	error: true,
}

export const ALERT_DELETE_REFERENCED_RECORD = {
	id: 'ALERT_DELETE_REFERENCED_RECORD_TEMPLATE',
	content: '이미 양식이 사용중입니다. 해당 기록 삭제 처리 후 양식을 삭제해 주세요.',
	success: false,
	error: true,
}

export const ALERT_DELETE_REFERENCED_RECORD_TEMPLATE = {
	id: 'ALERT_DELETE_REFERENCED_RECORD',
	content:
		'이 부분 양식은 다른 양식의 일부로 포함되어 사용되고 있습니다. 이 양식을 더 이상 포함하지 않도록 변경 후 삭제해 주세요.',
	success: false,
	error: true,
}

export const ALERT_SAVE_SURVEY_SUCCESS = {
	id: 'ALERT_SAVE_SURVEY_SUCCESS',
	content: '설문이 저장되었습니다.',
	success: true,
	error: false,
}

export const ALERT_RESEARCH_LIMIT_EXCEED_ERROR = {
	id: 'ALERT_RESEARCH_LIMIT_EXCEED_ERROR',
	content:
		'프로젝트를 더 생성하시려면  정식 서비스를 신청하셔야 합니다. 문의를 통해 진행하실 수 있습니다.',
	success: false,
	error: true,
}

export const ALERT_SEND_MMS = {
	id: 'ALERT_SEND_MMS',
	content: '문자가 전송되었습니다.',
	success: true,
	error: false,
}

export const FAIL_SEND_MMS = {
	id: 'FAIL_SEND_MMS',
	content: '문자가 전송을 실패했습니다.',
	success: false,
	error: true,
}

export const SUCCESS_CASE_UPLOAD_MMS = {
	id: 'SUCCESS_CASE_UPLOAD',
	content: '케이스가 공유되었습니다.',
	success: true,
	error: false,
}

export const SUCCESS_CASE_UPDATE_MMS = {
	id: 'SUCCESS_CASE_UPDATE',
	content: '케이스가 업데이트되었습니다.',
	success: true,
	error: false,
}

export const FAIL_CASE_UPLOAD_MMS = {
	id: 'FAIL_CASE_UPLOAD',
	content: '케이스 업로드 중 오류가 발생했습니다.',
	success: false,
	error: true,
}

export const FAIL_CASE_UPLOAD_NO_TITLE = {
	id: 'FAIL_CASE_UPLOAD_NO_TITLE',
	content: '케이스 제목을 입력해 주세요.',
	success: false,
	error: true,
}

export const FAIL_CASE_UPLOAD_NO_TEXT = {
	id: 'FAIL_CASE_UPLOAD_NO_TEXT',
	content: '차트 내용 중 빠진 것이 있습니다. 모두 입력해 주세요.',
	success: false,
	error: true,
}

export const FAIL_CASE_UPLOAD_NO_DATE = {
	id: 'FAIL_CASE_UPLOAD_NO_DATE',
	content: '차트 날짜를 모두 정확하게 입력해 주세요.',
	success: false,
	error: true,
}

export const FAIL_CASE_UPLOAD_CHART_NUM = {
	id: 'FAIL_CASE_UPLOAD_CHART_NUM',
	content: '색인을 위한 차트 번호를 입력해 주세요.',
	success: false,
	error: true,
}

export const FAIL_CASE_UPLOAD_NO_SEX = {
	id: 'FAIL_CASE_UPLOAD_NO_SEX',
	content: '성별을 선택해 주세요.',
	success: false,
	error: true,
}

export const FAIL_CASE_UPLOAD_NO_AGE = {
	id: 'FAIL_CASE_UPLOAD_NO_AGE',
	content: '나이를 입력해 주세요.',
	success: false,
	error: true,
}

export const FAIL_CASE_UPLOAD_WRONG_AGE = {
	id: 'FAIL_CASE_UPLOAD_WRONG_AGE',
	content: '나이를 정확하게 입력해 주세요.',
	success: false,
	error: true,
}

export const SUCCESS_CASE_DELETE = {
	id: 'SUCCESS_CASE_DELETE',
	content: '케이스가 삭제되었습니다.',
	success: true,
	error: false,
}
