import React from 'react'
import { useQuery } from 'react-apollo'
import PatientSurveyHeader from './PatientSurveyHeader'
import { GET_USER_INFO } from '../../graphql/queries/user'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import PatientSurveyContent from './PatientSurveyContent'

/**
 * 환자 직접 설문
 */

const PatientSurveyStart = () => {
	const {
		data: {
			userInfo: {
				user: { profileImage, institutionId },
			},
		},
	} = useQuery(GET_USER_INFO)

	return (
		<>
			<PatientSurveyHeader logoImage={profileImage} />
			<PatientSurveyContent institutionId={institutionId} />
		</>
	)
}

export default PatientSurveyStart
