import React from 'react'
import styled from 'styled-components'
import { Breadcrumb as SemanticBreadcrumb } from 'semantic-ui-react'

const Breadcrumb = props => {
	return (
		<Box>
			<SemanticBreadcrumb {...props} divider={<BreadcrumbDivider />} />
		</Box>
	)
}
export default Breadcrumb

const BreadcrumbDivider = () => {
	return <Container>&gt;</Container>
}

const Box = styled.div`
	height: 30px;
`

const Container = styled.div`
	margin: 0px 10px;
	width: 12px;
	height: 12px;
`
