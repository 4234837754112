import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
// import { Breadcrumb } from 'semantic-ui-react'
import { COLOR_PRIMARY } from '../../constants/styles'
import Breadcrumb from './basic/Breadcrumb'

/**
 * 페이지 제목
 */
const PageHeader = ({ breadCrumbs, hasSubSideMenu, title, number }) => {
	return (
		<Container hasSubSideMenu={hasSubSideMenu}>
			<BreadCrumbContainer>
				{breadCrumbs != null && <Breadcrumb sections={breadCrumbs} />}
			</BreadCrumbContainer>
			<h5>
				{title}
				{number != null && <TitleNumber>{number} </TitleNumber>}
			</h5>
		</Container>
	)
}

PageHeader.propTypes = {
	breadCrumbs: PropTypes.arrayOf(
		PropTypes.shape({
			active: PropTypes.bool,
			link: PropTypes.bool,
			key: PropTypes.string,
			content: PropTypes.string,
			number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		})
	),
	title: PropTypes.string.isRequired,
	hasSubSideMenu: PropTypes.bool,
	number: PropTypes.number,
}

PageHeader.defaultProps = {
	breadCrumbs: null,
	hasSubSideMenu: false,
	number: null,
}

export default PageHeader

const Container = styled.div`
	margin-bottom: 30px;
	padding-top: ${props => (props.hasSubSideMenu === true ? '80px' : 'auto')};
	text-align: left;
`
const BreadCrumbContainer = styled.div`
	height: 30px;
`

const TitleNumber = styled.span`
	color: ${COLOR_PRIMARY};
	margin-left: 10px;
`
