import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Checkbox, Modal } from 'semantic-ui-react'
import { LinkColoredText } from '../../common/basic/StyledTexts'
import TermContact from './TermContact'

const ContactTermChecker = ({ setAgreed, hasAgreed }) => {
	const [isOpen, setOpen] = useState(false)
	return (
		<>
			<Form.Field>
				<Checkbox
					style={{ padding: '15px 0px' }}
					checked={hasAgreed}
					onChange={() => setAgreed(r => !r)}
					label="문의 상담에 필요한
				개인정보 수집이용에 동의합니다."
				/>
				<LinkColoredText style={{ display: 'block', marginLeft: 30 }} onClick={() => setOpen(true)}>
					자세히 보기
				</LinkColoredText>
			</Form.Field>
			<Modal
				open={isOpen}
				header="서비스 이용관련 문의 개인정보수집이용 동의(필수)"
				content={<TermContact />}
				closeIcon
				onClose={() => setOpen(false)}
			/>
		</>
	)
}

ContactTermChecker.propTypes = {
	setAgreed: PropTypes.func,
	hasAgreed: PropTypes.bool,
}

export default ContactTermChecker
