import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Slider from 'rc-slider/lib/Slider'
import { Input } from 'semantic-ui-react/dist/commonjs'
import 'rc-slider/assets/index.css'

/**
 * Slider
 */
export default class VasSlider extends PureComponent {
	static propTypes = {
		vasValue: PropTypes.number,
		updateVasValue: PropTypes.func.isRequired,
		step: PropTypes.number,
		disabled: PropTypes.bool,
		maxValue: PropTypes.number,
		readOnly: PropTypes.bool,
	}

	static defaultProps = {
		step: 1,
		maxValue: 10,
	}

	constructor(props, context) {
		super(props, context)
		this.state = {
			volume: props.vasValue || 0,
		}
	}

	handleOnChange = value => {
		const { readOnly } = this.props
		if (!readOnly) {
			this.setState({
				volume: parseInt(value, 10),
			})
		}
	}

	handleOnChangeText = (e, { value }) => {
		const { readOnly } = this.props
		if (!readOnly) {
			this.setState({
				volume: parseInt(value, 10),
			})
		}
	}

	handleOnChangeComplete = () => {
		const { readOnly, updateVasValue } = this.props
		const { volume } = this.state
		if (!readOnly) updateVasValue(volume)
	}

	render() {
		const { step, disabled, maxValue, readOnly } = this.props
		let { volume } = this.state
		return (
			<React.Fragment>
				<Slider
					className="custom-slider"
					disabled={disabled}
					step={step}
					value={volume}
					railStyle={styles.rail}
					trackStyle={styles.track}
					handleStyle={styles.handle}
					max={maxValue || 100}
					onChange={this.handleOnChange}
					onAfterChange={this.handleOnChangeComplete}
				/>
				<div style={styles.inputBox} className="custom-slider-input-box">
					{readOnly ? (
						<span style={styles.readOnly}>{volume}</span>
					) : (
						<Input
							disabled={disabled}
							type="number"
							input={{ style: styles.input }}
							max={maxValue || 100}
							min={0}
							value={volume}
							onChange={this.handleOnChangeText}
							onBlur={this.handleOnChangeComplete}
						/>
					)}
				</div>
			</React.Fragment>
		)
	}
}

const styles = {
	input: { width: 55, padding: 5, marginTop: 7 },
	inputBox: {
		width: 50,
		height: 40,
		marginLeft: 15,
		marginTop: 3,
		display: 'inline-block',
	},
	rail: {
		height: 10,
	},
	track: {
		height: 10,
	},
	handle: {
		width: 20,
		height: 20,
	},
	readOnly: { marginTop: 10, display: 'inline-block' },
}
