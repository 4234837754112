import React from 'react'
import PropTypes from 'prop-types'
import compose from 'lodash.flowright'
import { graphql } from 'react-apollo'

import { Form, TextArea } from 'semantic-ui-react'
import InlineProfile from '../../../common/InlineProfile'
import { GET_USER_INFO } from '../../../../graphql/queries/user'
import { BORDER_GRAY } from '../../../../constants/styles'

const MedInfoQueryForm = props => {
	const { user, message, onChange } = props

	return (
		<div>
			<InlineProfile {...user} size="small" />
			<Form style={{ display: 'flex', marginTop: 10 }}>
				<TextArea
					value={message}
					onChange={onChange}
					style={{ flex: 1, minHeight: 120, borderRadius: 2, border: BORDER_GRAY }}
					placeholder="메시지를 남겨주세요."
				/>
			</Form>
		</div>
	)
}

MedInfoQueryForm.propTypes = {
	user: PropTypes.shape(),
	message: PropTypes.string,
	onChange: PropTypes.func.isRequired,
}

MedInfoQueryForm.defaultProps = {
	user: null,
	message: null,
}

export default compose(
	graphql(GET_USER_INFO, {
		props: ({
			data: {
				userInfo: { user },
			},
		}) => ({
			user,
		}),
	})
)(MedInfoQueryForm)
