/**
 * Apollo 세팅
 * API 서버와 Authorization Header, Expired Token Handling, Local State 설정
 */

import { print } from 'graphql/language/printer'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { createApolloFetch } from 'apollo-fetch'
import { createUploadLink } from 'apollo-upload-client'

import introspectionQueryResultData from './fragmentTypes.json'

import promiseToObservable from '../libs/promiseToObservable'
import { defaults, resolvers } from './clientState'
import { APP_SERVER, DEV_MODE } from '../config'
import {
	getUserInfoFromCookie,
	setUserInfoCookie,
	deleteUserInfoCookie,
} from '../libs/cookieHelper'
import { REQUEST_ACCESS_TOKEN } from '../graphql/mutations/user'

import ERROR_TYPES from '../errorTypes'
import { ROUTE_LOGIN } from '../constants/routes'
import { getStoragePatientSurvey } from '../libs/localStorageHelper'
import { GET_PATIENT_SURVEY_STATUS } from '../graphql/queries/app'

const fragmentMatcher = new IntrospectionFragmentMatcher({
	introspectionQueryResultData,
})

const cache = new InMemoryCache({ fragmentMatcher })

// 인증 링크 처리
const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	const userInfo = getUserInfoFromCookie()

	// return the headers to the context so httpLink can read them
	if (!headers || !headers.authorization) {
		if (userInfo && userInfo.accessToken) {
			return {
				headers: {
					...headers,
					authorization: `Bearer ${userInfo.accessToken}`,
				},
			}
		}
	} else {
		return {
			headers,
		}
	}
})

//
const apolloFetch = createApolloFetch({
	uri: APP_SERVER,
})

// 새로운 AccessToken 요청이 필요할 경우
// ApolloFetch를 사용해 받아온 후 다시 Request 보냄
const requestAccessToken = async (refreshToken, userId) => {
	const { data } = await apolloFetch({
		query: print(REQUEST_ACCESS_TOKEN),
		variables: { refreshToken, userId },
	})

	return data.requestAccessToken
}

export default new ApolloClient({
	cache,
	resolvers,
	link: ApolloLink.from([
		onError(({ graphQLErrors, networkError, operation, forward }) => {
			if (graphQLErrors) {
				// 액세스 토큰 만료시 새로운 토큰을 서버에 요청
				const errorCode = graphQLErrors[0].extensions.code
				if (errorCode === ERROR_TYPES.EXPIRED_ACCESS_TOKEN_ERROR.code) {
					const userInfo = getUserInfoFromCookie()

					if (userInfo && userInfo.refreshToken && userInfo.user) {
						return promiseToObservable(
							requestAccessToken(userInfo.refreshToken, userInfo.user.id)
						).flatMap(userInfo_ => {
							setUserInfoCookie(userInfo_)

							operation.setContext(({ headers }) => {
								return {
									headers: {
										...headers,
										authorization: `Bearer ${userInfo_.accessToken}`,
									},
								}
							})
							return forward(operation)
						})
					}
				} else if (errorCode === ERROR_TYPES.EXPIRED_REFRESH_TOKEN_ERROR.code) {
					// 리프레시 토큰이 만료되었을 경우, 로그인 페이지로 이동
					deleteUserInfoCookie()

					const currentLocation = window.location.pathname
					window.location.href = '/login?redirect=' + currentLocation
				} else if (errorCode === ERROR_TYPES.UNAUTHORIZED_ERROR.code) {
					// 사용자 인증이 없을 경우, 로그인 화면으로 이동
					const userInfo = getUserInfoFromCookie()
					if (!userInfo) {
						const currentLocation = window.location.pathname
						if (currentLocation !== ROUTE_LOGIN) {
							window.location.href = '/login?redirect=' + currentLocation
						}
					}
				} else {
					if (DEV_MODE) console.log(graphQLErrors)
				}
			} else if (networkError) {
				if (networkError.extensions) {
					const errorCode = networkError.extensions.code
					if (errorCode === ERROR_TYPES.EXPIRED_ACCESS_TOKEN_ERROR.code) {
						const userInfo = getUserInfoFromCookie()
						if (userInfo && userInfo.refreshToken && userInfo.user) {
							return promiseToObservable(
								requestAccessToken(userInfo.refreshToken, userInfo.user.id)
							).flatMap(userInfo_ => {
								setUserInfoCookie(userInfo_)
								operation.setContext(({ headers }) => {
									return {
										headers: {
											...headers,
											authorization: `Bearer ${userInfo_.accessToken}`,
										},
									}
								})
								return forward(operation)
							})
						}
					}
				} else {
					if (DEV_MODE) console.log('networkError', networkError)
				}

				if (DEV_MODE) console.log(networkError)
			}
		}),
		authLink,
		// Multipart 파일 업로드 가능하도록
		// apollo-upload-client 라이브러리를 적용
		createUploadLink({
			uri: APP_SERVER,
			credentials: 'same-origin',
		}),
	]),
})

cache.writeData({
	data: defaults,
})

const currentStorageData = getStoragePatientSurvey()
if (currentStorageData != null) {
	cache.writeQuery({
		query: GET_PATIENT_SURVEY_STATUS,
		data: currentStorageData,
	})
}
