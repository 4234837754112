/* eslint camelcase:0 */

/**
 * [HOC] 차트 보드 기능 Higer Order Component
 * 차팅 처리 관련 공동함수 제공. TemplateView와 ChartView 등 차트 열람 페이지에서 사용
 */

import React, { PureComponent, createRef } from 'react'
import PropTypes from 'prop-types'
import { withApollo } from 'react-apollo'
import { withRouter, Prompt } from 'react-router-dom'

import { SEARCH_MED_INFO_TYPES, GET_PUBLIC_MED_INFO_TYPE } from '../graphql/queries/medInfo'
import { InputType } from '../enum'
import {
	assembleMedInfos,
	mutateMedInfo,
	updateChild,
	cleanMedInfos,
	extractMedInfoInputs,
	assembleMedInfoTypes,
} from '../libs/medInfoHelper'
import { handleConditionLoop, handleConditions } from '../libs/conditionHelper'
import { getQueryValue } from '../libs/routeHelper'

export default (WrappedComponent, options) => {
	class WithChart extends PureComponent {
		static propTypes = {
			onSaveTemplate: PropTypes.func,
			onSaveChart: PropTypes.func,
			recordId: PropTypes.number,
			medInfos: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.number,
					value: PropTypes.string,
				})
			),
			conditionRefMedInfos: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.number,
					value: PropTypes.string,
				})
			),
			medInfoTypes: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.number,
					name: PropTypes.string,
				})
			),
			client: PropTypes.shape({
				query: PropTypes.func,
			}),
			templateMode: PropTypes.bool,
			visitedDate: PropTypes.string,
			createdAt: PropTypes.string,
			location: PropTypes.shape({
				search: PropTypes.string,
			}).isRequired,
			researches: PropTypes.array.isRequired,
		}

		static defaultProps = {
			templateMode: false,
			medInfoTypes: null,
			recordId: null,
			medInfos: [],
			onSaveChart: () => {},
			onSaveTemplate: () => {},
			conditionRefMedInfos: [],
			client: null,
			visitedDate: '',
			createdAt: '',
		}

		inputRefs = {}

		submitting = false

		newInputIndex = -1

		newTypeIndex = -1

		constructor(props) {
			super(props)

			const medInfos = assembleMedInfos(JSON.parse(JSON.stringify(props.medInfos)))

			this.setupRefs(props.medInfos)

			if (props.medInfoTypes) {
				assembleMedInfoTypes(JSON.parse(JSON.stringify(props.medInfoTypes)), medInfos)
			}

			this.state = {
				medInfos,
				newInputIndex: -1,
				newTypeIndex: -1,
				hasChanges: false,
			}

			this.anchorOpenQuery = getQueryValue('query', props.location.search, 'number')
			this.anchorOpenComment = getQueryValue('comment', props.location.search, 'number')

			if (props.templateMode !== true) this.processConditions(medInfos)
		}

		componentWillUnmount() {
			// unregister onbeforeunload event handler
			window.onbeforeunload = null
		}

		// medInfos state에서 index에 해당하는 medInfo 가져오기
		getMedInfo = ({ sectionIndex, medInfoIndex, groupIndex }) => {
			const { medInfos } = this.state
			if (typeof groupIndex === 'number') {
				return medInfos[sectionIndex].children[groupIndex].children[medInfoIndex]
			}
			return medInfos[sectionIndex].children[medInfoIndex]
		}

		setMedInfos = medInfos => {
			this.setState({
				medInfos,
			})
		}

		setupRefs = medInfos => {
			for (const medInfo of medInfos.filter(
				item => InputType[item.medInfoType.inputType].isNotInput !== true
			)) {
				this.inputRefs[medInfo.id] = createRef()
			}
		}

		// 새로운 MedInfo Object 생성하기
		makeNewMedInfo = ({
			parentId,
			newTypeIndex,
			copyItem,
			medInfoType,
			notAddMedInfoType,
			value,
			newInputIndex,
			optionValue,
		}) => {
			const { templateMode } = this.props
			medInfoType = copyItem ? Object.assign({}, copyItem.medInfoType) : medInfoType

			let optionValue_
			if (optionValue === undefined) {
				optionValue_ =
					medInfoType.options && medInfoType.options.length > 0
						? medInfoType.options[0].value
						: null
			} else {
				optionValue_ = optionValue
			}

			const vasValue = medInfoType.hasVAS ? 0 : -1

			// MedInfoIndex와 typeIndex에 새로운 값을 적용
			let inputIdx = newInputIndex
			let typeIdx = newTypeIndex
			// TODO: 그리드 처리
			if (templateMode && !notAddMedInfoType) {
				medInfoType.id = newTypeIndex
				// Grid Child Types 추가
				if (medInfoType.childTypes) {
					for (const childType of medInfoType.childTypes) {
						childType.parentTypeId = typeIdx
						childType.id = typeIdx - 1
						typeIdx = childType.id
					}
				}
			}

			const children =
				copyItem && copyItem.children
					? copyItem.children.map(child => {
							inputIdx -= 1
							typeIdx -= 1
							return this.makeNewMedInfo({
								copyItem: child,
								newInputIndex: inputIdx,
								newTypeIndex: typeIdx,
							})
					  })
					: []

			return {
				__typename: '',
				id: newInputIndex,
				optionValue: optionValue_,
				vasValue,
				parentId,
				medInfoType,
				children,
				checkedItems: [],
				value: value || null,
			}
		}

		// 전체 condition을 로드된 medInfos값에 따라 적용
		processConditions = async medInfos => {
			try {
				const { conditionRefMedInfos, visitedDate, createdAt, client } = this.props
				for (const idx in medInfos) {
					await handleConditionLoop(
						medInfos[idx],
						idx,
						medInfos,
						conditionRefMedInfos,
						{
							visitedDate,
							createdAt,
						},
						client,
						this.handleGenerateMedInfo
					)
				}

				this.setState({
					medInfos: medInfos.slice(0),
				})
			} catch (e) {
				alert('오류가 발생했습니다.')
			}
		}

		// ChartingSection 생성
		handleCreateSection = ({ title, color, typeName, size, description, format }) => {
			this.setState(prevState => ({
				medInfos: [
					...prevState.medInfos,
					{
						id: prevState.newInputIndex,
						value: null,
						vasValue: null,
						optionValue: null,
						children: [],
						medInfoType: {
							id: prevState.newTypeIndex,
							color,
							name: title,
							size,
							description,
							typeName,
							subTypes: [],
							inputType: InputType.SECTION.value,
							format,
						},
					},
				],
				newInputIndex: prevState.newInputIndex - 1,
				newTypeIndex: prevState.newTypeIndex - 1,
			}))
		}
		// 새로운 MedInfo 생성
		handleCreateSectionFromType = async props => {
			const medInfo = await this.handleGenerateMedInfo(props, null)

			this.setState(prevState => ({
				medInfos: [...prevState.medInfos, medInfo],
			}))
		}
		// 새로운 MedInfo 생성하기
		handleGenerateMedInfo = async (props, parentId) => {
			const { templateMode } = this.props

			let copyItem = props.copyItem
			let medInfoType
			if (typeof props.medInfoTypeId === 'number') {
				const fieldInfo = await this.handleRequestMedInfoType(props.medInfoTypeId)

				const {
					medInfo: { children, ...medInfo },
				} = fieldInfo
				copyItem = assembleMedInfos([{ ...medInfo, parentId: null }, ...children])[0]
			}

			const medInfo = this.makeNewMedInfo({
				...props,
				copyItem,
				newInputIndex: this.newInputIndex,
				newTypeIndex: this.newTypeIndex,
				parentId,
				...(medInfoType && { medInfoType }),
			})

			const addedItemCount =
				1 + (copyItem != null && copyItem.children ? copyItem.children.length : 0)

			this.newInputIndex = this.newInputIndex - addedItemCount
			if (templateMode) {
				this.newTypeIndex =
					this.newTypeIndex -
					addedItemCount -
					(props.medInfoType && props.medInfoType.childTypes
						? props.medInfoType.childTypes.length
						: 0)
			}

			return medInfo
		}

		// Grid의 ChildType 만들기
		handleGenerateMedInfoType = async medInfoType => {
			medInfoType.id = this.newTypeIndex
			this.newTypeIndex = this.newTypeIndex - 1

			return medInfoType
		}

		// 모든 MedInfos 업데이트
		handleMedInfosGlobalUpdate = (medInfos, toUpdate) => {
			return medInfos.map(medInfo => {
				medInfo = {
					...medInfo,
					...toUpdate,
				}

				if (medInfo.children) {
					medInfo.children = this.handleMedInfosGlobalUpdate(medInfo.children, toUpdate)
				}

				return medInfo
			})
		}

		// 페이지 나갈 때 앨러트로 묻는 Browser Prompt 비활성화
		handleOnBeforeUnload = e => {
			e.preventDefault()
			return false
		}

		// MedInfo 값 변경 처리
		handleUpdateMedInfo = async (data, sectionIndex, conditionOption) => {
			const { medInfos } = this.state
			const { conditionRefMedInfos, visitedDate, createdAt, client } = this.props

			let updated = medInfos.map((section, index) => {
				if (index === sectionIndex) {
					return data
				}

				return section
			})

			await this.setState({
				medInfos: updated,
				hasChanges: true,
			})

			if (conditionOption) {
				const { conditions, option } = conditionOption

				updated = await handleConditions(
					updated,
					conditions,
					option,
					conditionRefMedInfos,
					{
						visitedDate,
						createdAt,
					},
					client,
					this.handleGenerateMedInfo
				)

				this.setState({
					medInfos: updated.slice(0),
				})
			}

			window.onbeforeunload = this.handleOnBeforeUnload
		}

		// MedInfo 한개를 Id로 찾아서 삭제
		handleUpdateSingleMedInfoById = async (medInfoId, toChange) => {
			const { medInfos } = this.state
			const m = await updateChild(
				medInfos,
				mi => mi.id === medInfoId,
				mi => ({
					...mi,
					...toChange,
				})
			)

			this.setState({ medInfos: m })
		}

		// MedInfoType 수정하기
		handleEditMedInfoType = (sectionIndex, { medInfoType }, groupIndex, medInfoIndex) => {
			this.setState(prevState => ({
				medInfos: prevState.medInfos.map((section, index) => {
					if (index === sectionIndex) {
						if (typeof groupIndex === 'number') {
							return {
								...section,
								children: section.children.map((group, index_) => {
									if (index_ === groupIndex) {
										return {
											...group,
											children: group.children.map((child, index__) => {
												if (index__ === medInfoIndex) {
													return {
														...child,
														medInfoType,
													}
												}

												return child
											}),
										}
									}

									return group
								}),
							}
						}
						return {
							...section,
							children: section.children.map((child, index_) => {
								if (index_ === medInfoIndex) {
									return {
										...child,
										medInfoType,
									}
								}

								return child
							}),
						}
					}

					return section
				}),
			}))
		}

		// ChartingSection 삭제
		handleDeleteSection = sectionIndex => {
			this.setState(prevState => ({
				medInfos: prevState.medInfos.filter((item, index) => {
					return index !== sectionIndex
				}),
			}))
		}

		// ChartingSection 수정
		handleEditSection = ({ title, color, typeName, size, sectionIndex, description, format }) => {
			this.setState(prevState => ({
				medInfos: prevState.medInfos.map((section, index) => {
					if (index === sectionIndex) {
						return {
							...section,
							medInfoType: {
								...section.medInfoType,
								name: title,
								color,
								size,
								typeName,
								description,
								format,
							},
						}
					}

					return section
				}),
			}))
		}

		// MedInfo 삭제
		// Block(Group) 내 MedInfo일 경우와 아닐 경우 구분
		handleDeleteMedInfo = ({ sectionIndex, groupIndex, medInfoIndex }) => {
			const { medInfos } = this.state
			const hasGroupIndex = typeof groupIndex === 'number'

			this.setState({
				medInfos: medInfos.map((section, index) => {
					if (index === sectionIndex) {
						if (hasGroupIndex === true) {
							section.children[groupIndex].children = section.children[groupIndex].children.filter(
								(medInfo, idx) => {
									return idx !== medInfoIndex
								}
							)
						} else {
							section.children = section.children.filter((medInfo, idx) => {
								return idx !== medInfoIndex
							})
						}
					}

					return section
				}),
			})
		}

		// 차트 변경사항을 서버에 저장
		handleSaveChart = async (options = {}) => {
			const { onSaveChart, templateMode } = this.props
			// setTimeout(async () => {

			const { medInfos: rawMedInfos } = this.state

			const medInfos = extractMedInfoInputs({
				medInfos: rawMedInfos,
				templateMode,
			})

			await this.setState({
				hasChanges: false,
			})

			window.onbeforeunload = null

			if (options && options.template) {
				const { onSaveTemplate } = this.props
				await onSaveTemplate(options, medInfos)
			} else {
				const { medInfos: pMedInfos } = this.props
				options.originalMedInfos = cleanMedInfos({
					medInfos: JSON.parse(JSON.stringify(pMedInfos)),
					templateMode,
				})

				await onSaveChart(medInfos, options)
			}
		}

		// ChartingSection을 Drag and drop으로 이동하기
		handleMoveSectionWithId = (sectionId, toIndex) => {
			const { medInfos } = this.state

			const idx = medInfos.findIndex(i => i.id === sectionId)
			this.handleMoveSection(idx, toIndex)
		}

		// ChartingSection을 Drag and drop으로 이동하기
		handleMoveSection = (sectionIndex, toIndex) => {
			const { medInfos } = this.state

			const item = medInfos[sectionIndex]
			const sorted = medInfos.filter((temp, index) => {
				return index !== sectionIndex
			})
			sorted.splice(toIndex, 0, item)

			this.setState({
				medInfos: sorted,
			})
		}

		// MedInfo를 Drag and drop으로 이동하기
		// 타겟 위치에 이동할 항목을 복사하고 기존 항목을 삭제
		handleMoveMedInfo = (source, target, parent) => {
			const { medInfos } = this.state

			const mapped = medInfos.map(section => {
				if (section.id === parent.id) return parent
				return section
			})

			let mutate
			let matcher

			if (target.isAdding === true) {
				mutate = children => children.concat(source.medInfo)
				matcher = item => item.id === target.medInfoId
			} else {
				mutate = children => {
					const cld = children.slice(0)
					cld.splice(target.medInfoIndex, 0, source.medInfo)
					return cld
				}
				matcher = item =>
					item.children && item.children.findIndex(item_ => item_.id === target.medInfoId) >= 0
			}

			mutateMedInfo(mapped, matcher, {
				key: 'children',
				mutate,
			})

			this.setState({
				medInfos: mapped,
			})
		}

		// MedInfoType 검색하기
		handleSearchMedInfoTypes = async searchString => {
			try {
				const { client, researchId } = this.props

				const { data } = await client.query({
					query: SEARCH_MED_INFO_TYPES,
					variables: {
						searchString,
						researchId,
					},
				})

				return data.searchMedInfoTypes
			} catch (e) {
				return []
			}
		}

		// MedInfoType 상세정보 요청
		handleRequestMedInfoType = async medInfoTypeId => {
			try {
				const { client } = this.props
				const { data } = await client.query({
					query: GET_PUBLIC_MED_INFO_TYPE,
					variables: {
						medInfoTypeId: medInfoTypeId,
					},
				})

				return data.field
			} catch (e) {
				console.log(e)
				return {}
			}
		}

		// 진료기록 업데이트시 반영
		UNSAFE_componentWillReceiveProps(nextProps) {
			const { medInfos } = this.props

			if (nextProps.medInfos && nextProps.medInfos !== medInfos) {
				const assembled = assembleMedInfos(JSON.parse(JSON.stringify(nextProps.medInfos)))

				if (nextProps.medInfoTypes) {
					assembleMedInfoTypes(JSON.parse(JSON.stringify(nextProps.medInfoTypes)), assembled)
				}

				if (nextProps.templateMode !== true) this.processConditions(assembled, {})
			}
		}

		render() {
			const { hasChanges, medInfos } = this.state
			const { forwardedRef } = this.props

			return (
				<React.Fragment>
					<Prompt
						when={hasChanges}
						message="변경 사항이 있습니다. 저장하지 않고 진행하시겠습니까?"
					/>
					<WrappedComponent
						{...this.props}
						ref={forwardedRef}
						anchorOpenQuery={this.anchorOpenQuery}
						anchorOpenComment={this.anchorOpenComment}
						onSearchMedInfoTypes={this.handleSearchMedInfoTypes}
						onUpdateMedInfo={this.handleUpdateMedInfo}
						onUpdateSingleMedInfo={this.handleUpdateSingleMedInfoById}
						onDeleteMedInfo={this.handleDeleteMedInfo}
						onDeleteSection={this.handleDeleteSection}
						onEditSection={this.handleEditSection}
						onCreateSection={this.handleCreateSection}
						onCreateSectionFromType={this.handleCreateSectionFromType}
						onGenerateMedInfo={this.handleGenerateMedInfo}
						onGenerateMedInfoType={this.handleGenerateMedInfoType}
						onUpdateMedInfoType={this.handleEditMedInfoType}
						onMoveSection={this.handleMoveSection}
						onMoveSectionWithId={this.handleMoveSectionWithId}
						onMoveMedInfo={this.handleMoveMedInfo}
						onSaveChart={this.handleSaveChart}
						conditionHandlers={this.conditionHandlers}
						medInfos={medInfos}
						resetChanges={this.handleResetChanges}
						inputRefs={this.inputRefs}
						setMedInfos={this.setMedInfos}
					/>
				</React.Fragment>
			)
		}
	}

	if (options != null && options.withRef === true) {
		const forwardRef = (props, ref) => {
			const Wrapped = withRouter(withApollo(WithChart, { withRef: ref != null }), {
				withRef: ref != null,
			})
			return <Wrapped {...props} forwardedRef={ref} />
		}

		return React.forwardRef(forwardRef)
	} else {
		return withRouter(withApollo(WithChart))
	}
}
