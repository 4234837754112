import React from 'react'
import PropTypes from 'prop-types'

import { Table, Loader } from 'semantic-ui-react'

const TableLoader = ({ size }) => {
	let height
	if (size === 'tall') height = 500
	else if (size === 'medium') height = 300
	else height = 200

	return (
		<React.Fragment>
			<Table.Row style={{ height, textAlign: 'center' }}>
				<Table.Cell colSpan={99}>
					<Loader active inline style={{ margin: 'auto', marginTop: -20, top: '50%' }} />
				</Table.Cell>
			</Table.Row>
		</React.Fragment>
	)
}

TableLoader.propTypes = {
	size: PropTypes.oneOf(['tall', 'small', 'medium']),
}

TableLoader.defaultProps = {
	size: 'small',
}

export default TableLoader
