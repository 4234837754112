import React from 'react'
import PropTypes from 'prop-types'

import { ResearchType, ResearchSubjectStatus, ObjectType } from '../enum'
import { ROUTE_PROJECT_DETAIL_PATIENT_DETAIL } from '../constants/routes'
import { replaceParams } from '../libs/routeHelper'
import { toCommonDateString } from '../libs/dateHelper'
import DateInfo from '../components/common/basic/DateInfo'
import DataTable from '../components/common/DataTable'
import PageContainer from '../components/common/container/PageContainer'

import useDeleteObject from '../hooks/useDeleteObject'
import StatusLabel from '../components/common/StatusLabel'
import PatientCode from '../components/common/PatientCode'
import { getAgeRange } from '../libs/valueHelper'
import {
	HEADER_CELLS_SCHEDULED_RESEARCH_PATIENT_LIST,
	HEADER_CELLS_DATA_GATHERING_PATIENT_LIST,
} from '../constants'
import { GET_RESEARCH_PATIENTS } from '../graphql/queries/patient'
import DeleteObjectModal from '../components/common/modal/DeleteObjectModal'

const PAGE_TITLE = '연구대상자'
const COLUMNS = [
	{
		text: '상태',
		key: 'status',
		value: 'status',
		isSortable: true,
		uiOptions: {
			textAlign: 'center',
			style: { width: 100 },
		},
		render: ({ patientResearches }) => {
			const status = patientResearches[0].status
			return <StatusLabel set={ResearchSubjectStatus} key={status} status={status} />
		},
	},
	{
		text: '성별',
		key: 'sexMale',
		value: 'sexMale',
		isSortable: true,
		uiOptions: {
			textAlign: 'center',
			style: { width: 60 },
		},
		render: ({ sexMale }) => {
			return sexMale ? '남' : '여'
		},
	},
	{
		text: '나이',
		key: 'birthDate',
		value: 'birthDate',
		isSortable: true,
		uiOptions: {
			textAlign: 'center',
			style: { width: 90 },
		},
		render: ({ birthDate }) => {
			return getAgeRange(birthDate)
		},
	},
	{
		text: '코드',
		key: 'patientResearchCode',
		value: 'patientResearchCode',
		isSortable: true,
		uiOptions: {
			textAlign: 'center',
			style: { width: 160 },
		},
		render: ({ patientResearches }) => {
			return <PatientCode code={patientResearches[0].code} />
		},
	},
	{
		text: '환자명',
		key: 'name',
		value: 'name',
		isSortable: true,
		uiOptions: {
			textAlign: 'center',
			style: { width: 200 },
		},
		render: ({ name }) => {
			return <b>{name}</b>
		},
	},
	{
		text: '진행상태',
		key: 'patientResearch.phase',
		value: 'phase',
		uiOptions: {
			textAlign: 'center',
		},
		getter: ({ patientResearches, summary }) => {
			if (patientResearches[0].phase != null) return patientResearches[0].phase.title
			else return summary
		},
	},
	{
		text: '진료기관',
		key: 'registerer.institution.name',
		value: 'institutionName',
		isSortable: true,
		uiOptions: {
			textAlign: 'center',
			style: { width: 250 },
		},
	},
	{
		text: '등록일',
		key: 'createdAt',
		value: 'createdAt',
		isSortable: true,
		uiOptions: {
			textAlign: 'center',
			style: { width: 140 },
		},
		render: ({ createdAt }) => {
			return <DateInfo>{toCommonDateString(createdAt)}</DateInfo>
		},
	},
]

/**
 * 연구 대상자 목록
 */
const ResearchPatientList = props => {
	const { researchId, type, userInfo, breadCrumbs, history } = props
	const { handleOpen, ...deleteModalProps } = useDeleteObject()

	const isScheduled = type === ResearchType.SCHEDULED.key

	const colList = (isScheduled === true
		? HEADER_CELLS_SCHEDULED_RESEARCH_PATIENT_LIST
		: HEADER_CELLS_DATA_GATHERING_PATIENT_LIST
	).map(i => i.key)

	const cols = COLUMNS.filter(i => colList.indexOf(i.value) >= 0)

	return (
		<>
			<DataTable
				celled
				hasSearch
				searchPlaceholder="검색"
				query={GET_RESEARCH_PATIENTS}
				defaultVars={{
					researchId,
				}}
				rowHeight={50}
				queryName="researchPatients"
				emptyMessage="등록된 대상자 없습니다."
				onClickRow={({ id }) => {
					try {
						history.push(
							replaceParams(ROUTE_PROJECT_DETAIL_PATIENT_DETAIL, {
								id: researchId,
								subId: id,
							})
						)
					} catch (e) {
						alert('데이터가 잘못되었습니다.')
					}
				}}
				render={({ rendered, totalCount }) => {
					return (
						<PageContainer
							fixed
							title={PAGE_TITLE}
							breadCrumbs={breadCrumbs}
							titleNumber={totalCount}
						>
							{rendered}
						</PageContainer>
					)
				}}
				columns={cols}
				ellipsisMenu={({ registerer, id, refetch }) => {
					return registerer.id === userInfo.id
						? {
								options: [
									{
										text: '삭제',
										key: 'delete',
										value: 'delete',
										onClick: () =>
											handleOpen(ObjectType.PATIENT.value, id, {
												onComplete: refetch,
											}),
									},
								],
						  }
						: null
				}}
			/>
			<DeleteObjectModal {...deleteModalProps} />
		</>
	)
}

export default ResearchPatientList

ResearchPatientList.propTypes = {
	researchId: PropTypes.number,
	history: PropTypes.shape({
		push: PropTypes.func,
	}).isRequired,
	type: PropTypes.string,
	userInfo: PropTypes.shape({
		id: PropTypes.number,
	}),
	onOpenDeleteModal: PropTypes.func,
	breadCrumbs: PropTypes.array,
}
