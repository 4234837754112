import gql from 'graphql-tag'

import { RECORD_MED_INFOS } from '../fragments'

export const GET_PATIENT_SURVEY_INFO = gql`
	query getPatientSurveyInfo($secretCode: String!) {
		getPatientSurveyInfo(secretCode: $secretCode) {
			patientId
			record {
				id
				medInfos {
					...RecordMedInfoParts
				}
				template {
					id
					title
					description
				}
				creator {
					id
				}
				researches {
					id
				}
			}
		}
	}
	${RECORD_MED_INFOS}
`
