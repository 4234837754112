import gql from 'graphql-tag'

export const TOGGLE_NOTICE_MODAL_OPEN = gql`
	mutation toggleNoticeModalOpen($shouldOpen: Boolean) {
		toggleNoticeModalOpen(shouldOpen: $shouldOpen) @client
	}
`

export const SET_READ_NOTICES = gql`
	mutation setReadNotices($noticesId: [Number]) {
		setReadNotices(noticesId: $noticesId) @client
	}
`
