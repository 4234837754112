import gql from 'graphql-tag'
import { RECORD_FRAGMENT, PATIENT_RECORD_FRAGMENT } from '../fragments/record'
import { RESEARCHER_FRAGMENT } from '../fragments/research'
import { RECORD_MED_INFOS, SIMPLE_RECORD_MED_INFO_FRAGMENT } from '../fragments'

// 기록 추가하기
export const ADD_RECORD = gql`
	mutation addRecord(
		$patientId: Int!
		$templateId: Int
		$visitedDate: String!
		$isUsingLastRecord: Boolean!
		$researchId: Int
		$medInfos: [MedInfoInput]
	) {
		addRecord(
			patientId: $patientId
			templateId: $templateId
			visitedDate: $visitedDate
			isUsingLastRecord: $isUsingLastRecord
			researchId: $researchId
			medInfos: $medInfos
		) {
			...RecordParts
			patient {
				id
				lastRecordId
			}
		}
	}
	${RECORD_FRAGMENT}
`

// 환자 설문용 기록 추가하기
export const ADD_RECORD_FOR_SURVEY = gql`
	mutation addRecord($patientId: Int!, $templateId: Int, $visitedDate: String!, $researchId: Int) {
		addRecord(
			patientId: $patientId
			templateId: $templateId
			visitedDate: $visitedDate
			isUsingLastRecord: false
			researchId: $researchId
		) {
			id
			medInfos {
				...RecordMedInfoParts
			}
			template {
				id
				description
				title
			}
			patient {
				id
				name
				patientCode
				lastRecordId
			}
		}
	}
	${RECORD_MED_INFOS}
`

export const UPDATE_RECORD_MED_INFOS = gql`
	mutation updateRecordMedInfos(
		$recordId: Int!
		$medInfos: [MedInfoInput]
		$screeningValid: Boolean
		$nextDate: String
		$originalMedInfos: [MedInfoInput]
		$screeningFailReason: String
		$recordTemplateChangeId: Int
		$secretCode: String
	) {
		updateRecordMedInfos(
			recordId: $recordId
			medInfos: $medInfos
			screeningValid: $screeningValid
			nextDate: $nextDate
			originalMedInfos: $originalMedInfos
			screeningFailReason: $screeningFailReason
			recordTemplateChangeId: $recordTemplateChangeId
			secretCode: $secretCode
		) {
			...PatientRecordParts
			medInfos {
				...RecordMedInfoParts
			}
		}
	}
	${PATIENT_RECORD_FRAGMENT}
	${RECORD_MED_INFOS}
`

export const UPDATE_RECORD_INFO = gql`
	mutation updateRecordInfo($recordId: Int!, $recordInfo: RecordInfoInput!) {
		updateRecordInfo(recordId: $recordId, recordInfo: $recordInfo) {
			id
			visitedDate
		}
	}
`

export const CREATE_RECORD_TEMPLATE = gql`
	mutation createRecordTemplate(
		$medInfos: [MedInfoInput]
		$title: String
		$researchId: Int
		$description: String
		$days: Int
		$daysRange: Int
		$job: RecordTemplateJob
		$isPartial: Boolean
		$isSchedule: Boolean
		$type: RecordTemplateType
	) {
		createRecordTemplate(
			medInfos: $medInfos
			researchId: $researchId
			title: $title
			description: $description
			days: $days
			daysRange: $daysRange
			isPartial: $isPartial
			isSchedule: $isSchedule
			job: $job
			type: $type
		) {
			id
			title
		}
	}
`

export const UPDATE_RECORD_TEMPLATE = gql`
	mutation updateRecordTemplate(
		$medInfos: [MedInfoInput]
		$templateId: Int
		$message: String
		$researchId: Int
	) {
		updateRecordTemplate(
			medInfos: $medInfos
			templateId: $templateId
			researchId: $researchId
			message: $message
		) {
			id
			title
		}
	}
`

export const UPDATE_RECORD_TEMPLATE_OPTIONS = gql`
	mutation updateRecordTemplateOptions(
		$title: String
		$description: String
		$days: Int
		$daysRange: Int
		$templateId: Int!
		$shouldReserve: String
	) {
		updateRecordTemplateOptions(
			title: $title
			description: $description
			days: $days
			daysRange: $daysRange
			templateId: $templateId
			shouldReserve: $shouldReserve
		) {
			id
			title
		}
	}
`

// 연구용 기록 수정 요청
export const RESEARCH_RECORD_UPDATE_REQUEST = gql`
	mutation requestUpdateResearchRecord(
		$recordCode: String
		$researchId: Int
		$message: String
		$medInfos: [MedInfoInput]
	) {
		requestUpdateRecord(
			recordCode: $recordCode
			researchId: $researchId
			message: $message
			medInfos: $medInfos
		) {
			...RecordParts
		}
	}
	${RECORD_FRAGMENT}
`

export const UPDATE_RESEARCH_CONDITIONS = gql`
	mutation updateConditions($researchId: Int!, $conditions: [ConditionEcrfInput]!) {
		updateResearchConditions(researchId: $researchId, conditions: $conditions) {
			id
			value
			operator
			scope
			isRequired
			action
			message
			subjectId
			targetId
		}
	}
`

// 현재 캐시 내 Record 업데이트하기
export const UPDATE_CURRENT_RECORD = gql`
	mutation updateCurrentRecord($record: Record) {
		updateCurrentRecord(record: $record) @client {
			...RecordParts
		}
	}
	${RECORD_FRAGMENT}
`

// 연구원관리 기본설정 업데이트하기
export const UPDATE_RESEARCHER_INFO = gql`
	mutation updateResearcherInfo(
		$researchId: Int!
		$userId: Int!
		$researcherInfo: ResearcherInfoInput!
	) {
		updateResearcherInfo(
			researchId: $researchId
			userId: $userId
			researcherInfo: $researcherInfo
		) {
			...ResearcherParts
		}
	}
	${RESEARCHER_FRAGMENT}
`
// 연구원관리 기관별권한 업데이트하기
export const UPDATE_RESEARCH_INSTITUTION_PERMISSION = gql`
	mutation updateResearcherInstitutionPermission(
		$researchId: Int!
		$userId: Int!
		$canEdit: Boolean
		$canAccess: Boolean
		$institutionId: Int
	) {
		updateResearcherInstitutionPermission(
			researchId: $researchId
			userId: $userId
			canEdit: $canEdit
			canAccess: $canAccess
			institutionId: $institutionId
		) {
			institution {
				id
				name
			}
			canAccess
			canEdit
		}
	}
`

export const DELETE_RECORD = gql`
	mutation deleteRecord($recordId: Int!, $password: String!) {
		deleteRecord(recordId: $recordId, password: $password)
	}
`

/*-------UNUSED -----*/

// 차트 Record에 입력값 필드 추가하기
export const ADD_MED_INFO = gql`
	mutation addMedInfo(
		$id: Int!
		$medInfoType: MedInfoType
		$value: String
		$vasValue: Int
		$optionValue: String
	) {
		addMedInfo(
			id: $id
			medInfoType: $medInfoType
			value: $value
			vasValue: $vasValue
			optionValue: $optionValue
		) @client {
			...RecordParts
		}
	}
	${RECORD_FRAGMENT}
`

// 차트 Record에 입력값 필드 제거하기
export const DELETE_MED_INFO = gql`
	mutation deleteMedInfo($index: Int, $infoType: String) {
		deleteMedInfo(index: $index, infoType: $infoType) @client {
			...RecordParts
		}
	}
	${RECORD_FRAGMENT}
`

// 차트 Record에 입력값 필드 제거하기
export const EDIT_MED_INFO = gql`
	mutation editMedInfo($index: Int!, $vasValue: Int, $value: String) {
		editMedInfo(index: $index, vasValue: $vasValue, value: $value) @client {
			...RecordParts
		}
	}
	${RECORD_FRAGMENT}
`

// parse excel file
export const PARSE_EXCEL_XBODY = gql`
	mutation parseExcel_xbody($recordTemplateId: Int, $upload: Upload) {
		parseExcel_xbody(recordTemplateId: $recordTemplateId, upload: $upload) {
			...RecordMedInfoDataParts
		}
	}

	${SIMPLE_RECORD_MED_INFO_FRAGMENT}
`

export const DELETE_RECORD_TEMPLATE = gql`
	mutation deleteRecordTemplate($templateId: Int!, $password: String!) {
		deleteRecordTemplate(templateId: $templateId, password: $password)
	}
`
