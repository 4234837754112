import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useHistory, Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react/dist/commonjs'

import { TITLE_HOME } from '../lang'
import PageContainer from '../components/common/container/PageContainer'
import StyledSVG from '../components/common/basic/StyledSVG'

import TopImageSource from '../assets/images/drawings/main-1.svg'
import TopScreenshotSource from '../assets/images/drawings/screenshot.jpg'
import DrawingMulticenter from '../assets/images/drawings/multicenter.png'
import DrawingAI from '../assets/images/drawings/ai-data.png'
import DrawingHospital from '../assets/images/drawings/hospital-data.png'
import DrawingOthers from '../assets/images/drawings/others.png'
import DrawingStudy from '../assets/images/drawings/study.png'
import DrawingCloudData from '../assets/images/drawings/cloud-data.png'

import Feature1Image from '../assets/images/pictures/data-sort-img.png'
import Feature2Image from '../assets/images/pictures/stats-img.png'
import Feature3Image from '../assets/images/pictures/data-manage-img.jpg'

import SecurityImageSource from '../assets/images/drawings/safety.png'
import NCloudLogo from '../assets/images/pictures/nl.png'
import JoinImageSource from '../assets/images/drawings/main-5.svg'

import { COLOR_PRIMARY, COLOR_TEXT_DARKGRAY, COLOR_LIGHT_PINK } from '../constants/styles'
import T1 from '../components/common/basic/T1'
import { ROUTE_SIGNUP, ROUTE_CONTACT } from '../constants/routes'
import { ExceptMobile, MobileOnly } from '../components/common/basic/ResponsiveWrappers'
import { FlexFill, AlignCenteredFlexDiv } from '../components/common/basic/StyledBox'
import useUserInfo from '../hooks/useUserInfo'

/**
 * 홈화면 페이지
 */
const Home = () => {
	const history = useHistory()

	useEffect(() => {
		document.title = TITLE_HOME
	}, [])

	const userInfo = useUserInfo()
	const hasUser = userInfo.user.id !== 0
	return (
		<PageContainer fluid align="center" hasMobile>
			<FixedSection>
				<TopSection>
					<TopTitle>
						임상 관찰 연구,
						<NoBold>
							편리하게 데이터를
							<br />
							수집해 보세요.
						</NoBold>
						<ExceptMobile>
							<Button style={{ marginTop: 20 }} primary size="huge" as={Link} to={ROUTE_CONTACT}>
								더 알아보기
							</Button>
						</ExceptMobile>
					</TopTitle>
					<TopImageWrapper>
						<TopScreenshot src={TopScreenshotSource} />
						<TopImage />
					</TopImageWrapper>
				</TopSection>
				<MobileOnly>
					<Button
						style={{ marginBottom: 50, marginTop: -10 }}
						fluid
						primary
						size="large"
						as={Link}
						to={ROUTE_CONTACT}
					>
						지금 문의하기
					</Button>
				</MobileOnly>
			</FixedSection>
			<FixedSection>
				<FeatureSection>
					<FeatureTitle>편리해진 환자 데이터 수집</FeatureTitle>
					<SubTitle>
						<ExceptMobile>
							쉽고 편하게 데이터를 수집하고 관리할 수 있는 서비스를 제공합니다.
						</ExceptMobile>
						<MobileOnly>
							쉽고 편하게 임상 데이터를 수집하고
							<br />
							관리할 수 있는 서비스를 제공합니다.
						</MobileOnly>
					</SubTitle>
					<FeatureItems>
						<FeatureItem
							image={Feature1Image}
							title="다기관 데이터 관리"
							text={
								<div>
									여러 의료인이 함께 참여해
									<br />
									<br />
									파일이 오고갈 필요 없이, 클라우드 상에서 편리하게 데이터를 관리하세요.
									<br />
									<br />
									데이터 모니터링과 멤버간 소통까지, 모든 작업을 한 곳에서. 모든 내역을 보관하고
									언제든 확인해 볼 수 있습니다.
								</div>
							}
						/>
						<FeatureItem
							image={Feature2Image}
							title="모바일 데이터 수집"
							text={
								<div>
									언제까지 종이 설문지를 이용해야 하나요?
									<br />
									<br />
									환자의 스마트폰에 직접 설문을 전송하고, 원내의 다양한 스마트기기를 이용해 보세요.
									<br />
									<br />
									환자 동의와 설문 내용 수집을 한번에. <br />
									그리고 시각화된 데이터를 확인해 보세요.
								</div>
							}
						/>
						<FeatureItem
							image={Feature3Image}
							title="데이터 분석, 통계"
							text={
								<div>
									통계와 데이터 시각화도 간편하게.
									<br />
									<br />
									별도의 분석 도구나 코딩 전문가 없이도, 편리하게 실시간으로 분석결과를 확인하고,
									다양한 시각화 도구를 사용해 논문을 위한 멋진 자료를 만드세요.
									<br />
									<br />
									데이터를 수집하고, 간단한 요청을 통해 원하는 형태의 보고서를 받아보세요.
								</div>
							}
						/>
					</FeatureItems>
				</FeatureSection>
			</FixedSection>
			<FixedSection>
				<UseCaseSection>
					<UseCaseTitle>
						<ExceptMobile>레피어스, 이렇게 사용하세요.</ExceptMobile>
						<MobileOnly>
							레피어스, <br /> 이렇게 사용하세요.
						</MobileOnly>
					</UseCaseTitle>
					<UseCaseRow>
						<UseCaseItem
							image={DrawingMulticenter}
							title="다기관 레지스트리 연구"
							text="다기관 레지스트리 연구를 보다 편리하게. 환자를 등록하고, 동의서를 관리하며, 데이터를 검수하고, 수집된 데이터의 실시간 통계처리까지. 팔로우업까지도 지속적으로. 이제 한 곳에서, 저렴한 비용으로 모두 해결하세요."
						/>
						<UseCaseItem
							image={DrawingCloudData}
							title="차트리뷰 데이터 관리"
							text="논문을 위한 차트 리뷰 데이터를 클라우드 상에서 편리하게 관리하고 분석하세요. 데이터 정리부터 통계와 시각화까지 손쉽게 해결해보세요."
						/>
					</UseCaseRow>
					<UseCaseRow>
						<UseCaseItem
							image={DrawingHospital}
							title="환자 설문 자료 수집"
							text="태블릿PC 등을 이용해, 원내 환자에게서 지속적으로 다양한 설문 인덱스를 활용해 Score 정보를 수집해 보세요. Score 개선을 통해 치료 성능을 평가하고, 환자와의 소통에 활용하세요."
						/>
						<UseCaseItem
							image={DrawingOthers}
							title="모바일 환자 설문"
							text="스마트폰을 이용해 환자 설문을 전송하고, 동의와 설문 내용을 함께 수집하세요. 부담없는 설문 참여와 리워드 전달까지 한번에 수행하세요."
						/>
					</UseCaseRow>
					<UseCaseRow>
						<UseCaseItem
							image={DrawingStudy}
							title="임상 케이스 데이터 구축"
							text="병원이나 학회 내에서 임상 케이스를 공유하고 편리하게 스터디를 진행해 보세요. 특정 질환 사례를 공유하며 동료들과 함께 최적의 치료법을 발견해보세요. 또 링크, 이미지 등 다양한 자료를 게시판에 공유하며 댓글로 소통할 수 있습니다."
						/>
						{/* <UseCaseItem
							image={DrawingAI}
							title="이미지 데이터 레이블링"
							text="AI 연구를 위한 영상데이터 레이블링도 편리하게. 클라우드 상에서 벌크 데이터를 업로드하고, 피드백을 기록하며 상태를 관리하세요. 보다 편리하게 협업하고, 최적의 데이터를 뽑아보세요."
						/> */}
					</UseCaseRow>
				</UseCaseSection>
			</FixedSection>

			<FixedSection>
				<SecuritySection>
					<ExceptMobile>
						<SecurityImage src={SecurityImageSource} />
					</ExceptMobile>
					<FlexFill>
						<SecurityTitle>IRB는 문제 없나요?</SecurityTitle>
						<MobileOnly>
							<SecurityImage src={SecurityImageSource} />
						</MobileOnly>
						<SecurityWrapper>
							<SecurityItem>
								<h6>IRB 승인</h6>
								<RegularText>
									공용 IRB, 그리고 다수 병원의 기관 윤리 위원회(Institutional Review Board, IRB) 의
									승인을 받고 현재 다수의 연구가 진행중입니다.
									<br />
									<br />
									문의 메일을 통해 요청해 주시면, IRB를 위한 시스템 설명서를 보내드립니다. 심사 서류
									제출 시에 별지로 첨부해 주세요.
								</RegularText>
							</SecurityItem>
							<SecurityItem>
								<h6>가장 안전한 의료데이터 클라우드</h6>

								<ServerDesc>
									<ServerDescText>powered by</ServerDescText>
									<img src={NCloudLogo} style={{ width: 150 }} />
								</ServerDesc>
								<RegularText>
									의료데이터 보관을 위한 각종 기준을 준수하며, 다수의 클라우드 기반 의료 서비스 및
									정부출연과제가 행해지고 있는
									<b>네이버 클라우드 플랫폼</b> 상에서 운영됩니다.
								</RegularText>
							</SecurityItem>
						</SecurityWrapper>
					</FlexFill>
				</SecuritySection>
			</FixedSection>

			<JoinUsSection>
				<DottedSection>
					<JoinUsWrapper>
						<JoinUsTitle>
							레피어스와 함께
							<br />
							부담없이 연구를 시작하세요.
						</JoinUsTitle>
						{hasUser !== true && (
							<JoinUsButton
								primary
								onClick={() => {
									history.push(ROUTE_SIGNUP)
								}}
							>
								시작하기
							</JoinUsButton>
						)}
						<JoinUsButton
							primary
							onClick={() => {
								history.push(ROUTE_CONTACT)
							}}
						>
							문의하기
						</JoinUsButton>
						<JoinUsImageWrapper>
							<JoinUsImage src={JoinImageSource} />
						</JoinUsImageWrapper>
					</JoinUsWrapper>
				</DottedSection>
			</JoinUsSection>
		</PageContainer>
	)
}

export default Home

const TopImage = () => {
	return (
		<Section1Drawing
			src={TopImageSource}
			css={`
				width: 100%;
			`}
		/>
	)
}

const FeatureItem = ({ image, title, text }) => {
	return (
		<FeatureItemContainer>
			<FeatureImageWrapper>
				<FeatureImage src={image} />
			</FeatureImageWrapper>
			<FeatureHeader>{title}</FeatureHeader>
			<RegularText>{text}</RegularText>
		</FeatureItemContainer>
	)
}

FeatureItem.propTypes = {
	image: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	text: PropTypes.object.isRequired,
}

const UseCaseItem = ({ image, title, text }) => {
	return (
		<UseCaseItemContainer>
			<UseCaseImageWrapper>
				<UseCaseImage src={image} />
			</UseCaseImageWrapper>
			<UseCaseHeader>{title}</UseCaseHeader>
			<UseCaseText>{text}</UseCaseText>
		</UseCaseItemContainer>
	)
}

UseCaseItem.propTypes = {
	image: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
}

/**
 * Styles
 */

/**
 * Common
 */
const FixedSection = styled.section`
	position: relative;
	width: ${props => props.theme.PAGE_DEFAULT_MIN_WIDTH}px;
	padding: 0px ${props => props.theme.PAGE_DEFAULT_SIDE_PADDING}px;
	margin: auto;
	text-align: left;

	@media (max-width: 768px) {
		width: 100%;
		padding: 0px ${props => props.theme.PAGE_DEFAULT_MOBILE_PADDING}px;
	}
`
const DottedSection = styled.section`
	background: url('/images/background/dot-pattern.png');
	background-repeat: repeat;
	background-size: 400px;

	@media (max-width: 768px) {
		width: 100%;
	}
`

const NoBold = styled.div`
	font-weight: 400;
`

const RegularText = styled.div`
	font-size: 16px;
	letter-spacing: normal;
	line-height: 1.45;
`

/**
 * TopSection
 */
const TopSection = styled.div`
	margin-top: 65px;
	margin-bottom: 100px;

	@media (max-width: 768px) {
		margin-top: 20px;
		margin-bottom: 20px;
	}
`

const TopTitle = styled.h4`
	font-style: normal;
	font-stretch: normal;
	line-height: 1.2;
	letter-spacing: -0.5px;
	margin-top: 100px !important;
	display: inline-block;
	vertical-align: top;

	@media (max-width: 768px) {
		margin-top: 60px !important;
		font-size: 24px !important;
		margin-bottom: 60px !important;
	}
`

const TopImageWrapper = styled.div`
	display: inline-block;
	position: relative;

	@media (max-width: 768px) {
		margin-top: 20px;
		margin-bottom: 50px;
	}
`

const TopScreenshot = styled.img`
	@media (min-width: 769px) {
		position: absolute;
		top: -45px;
		right: 23px;
		width: 607px;
		height: 418px;
		border: 1px solid #ececec;
	}
	@media (max-width: 768px) {
		position: absolute;
		top: 0%;
		right: 10%;
		width: 72.5%;
		/* height: 60%; */
	}
`

const Section1Drawing = styled(StyledSVG)`
	position: relative;

	@media (min-width: 769px) {
		width: 820px;
		display: inline-block;
		margin: -40px;
	}

	@media (max-width: 768px) {
		width: 100%;
		svg {
			height: auto;
		}
	}
`

// /**
//  * Intro Section
//  */
// const IntroSection = styled.div`
// 	height: 800px;
// 	text-align: center;
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: center;
// 	align-items: center;
// `

// const IntroImage = styled.img`
// 	@media (max-width: 768px) {
// 		width: 50%;
// 	}
// `

// const IntroTitle = styled.h4`
// 	font-weight: 600;
// 	color: ${COLOR_PRIMARY};
// 	margin-bottom: 40px !important;
// 	margin-top: 40px;

// 	@media (max-width: 768px) {
// 		line-height: 1.2;
// 		margin-top: 50px;
// 		margin-bottom: 50px !important;
// 		width: 100%;
// 		padding: 0px 40px;
// 		text-align: left;
// 		font-size: 22px;
// 	}
// `

// const IntroText = styled.div`
// 	width: 740px;
// 	line-height: 1.5;
// 	letter-spacing: 1.5px;
// 	font-size: 24px;
// 	margin-bottom: 110px !important;
// 	text-align: left;

// 	@media (max-width: 768px) {
// 		font-size: 16px;
// 		width: 100%;
// 		padding: 0px 40px;
// 		margin-bottom: 60px !important;
// 	}
// `

/**
 * Features Section
 */
const FeatureSection = styled.div`
	text-align: center;
	padding-top: 90px;

	@media (max-width: 768px) {
		padding-top: 50px;
	}
`

const FeatureTitle = styled.h3`
	@media (max-width: 768px) {
		font-size: 22px;
	}
`
const SubTitle = styled(T1)`
	text-align: center;
	letter-spacing: -0.5px;
	font-weight: 400 !important;
	margin-bottom: 72px;

	@media (max-width: 768px) {
		margin-top: 20px !important;
	}
`
const FeatureItems = styled.div`
	justify-content: space-between;
	text-align: left;
	display: flex;
	margin-top: 72px;

	@media (max-width: 768px) {
		flex-direction: column;
	}
`

const FeatureItemContainer = styled.div`
	width: 340px;
	display: inline-block;

	@media (max-width: 768px) {
		width: 100%;
		display: block;
		padding: 30px 0px;
	}
`

const FeatureImageWrapper = styled.div`
	overflow: hidden;
	width: 100%;
	height: 210px;
	text-align: center;
	margin: auto;
	margin-bottom: 23px;
`

const FeatureImage = styled.img`
	width: 100%;
`

const FeatureHeader = styled.h6`
	line-height: 2;
	letter-spacing: -0ch.3px;
	height: 48px;
	margin-bottom: 10px;
`

/**
 * SecuritySection
 */

const SecuritySection = styled(AlignCenteredFlexDiv)`
	margin: auto;
	text-align: left;
	margin-top: 150px;
	padding: 100px 120px;

	@media (max-width: ${props => props.theme.PAGE_MOBILE_MAX_WIDTH}px) {
		flex-direction: column;
		width: 100%;
		padding: 0px;
		margin-top: 90px;
		margin-bottom: 90px;
	}
`
const SecurityTitle = styled.h3`
	@media (max-width: ${props => props.theme.PAGE_MOBILE_MAX_WIDTH}px) {
		font-size: 22px;
		margin-bottom: 30px;
		text-align: center;
	}
`

const SecurityImage = styled.img`
	width: 350px;
	margin-right: 70px;

	@media (max-width: ${props => props.theme.PAGE_MOBILE_MAX_WIDTH}px) {
		width: 80%;
		max-width: 350px;
		margin-right: 0px;
		margin: auto;
	}
`

const SecurityWrapper = styled.div`
	margin-top: 50px;

	@media (max-width: ${props => props.theme.PAGE_MOBILE_MAX_WIDTH}px) {
		margin-top: 20px;
	}
`

const SecurityItem = styled.div`
	margin-top: 24px;
	padding-top: 24px;
	border-top: ${props => props.theme.BORDER_GRAY};

	:first-child {
		border-top: none;
	}

	h6 {
		margin-bottom: 20px;
	}
`

const ServerDesc = styled.div`
	letter-spacing: 1px;
	font-size: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 25px;
	margin-top: 30px;
	/* color: #999; */
`

const ServerDescText = styled.div`
	margin-right: 20px;
	color: #5a5a5a;
`

/**
 * Use Case Section
 */
const UseCaseSection = styled.div`
	padding-top: 277px;

	@media (max-width: 768px) {
		padding-top: 50px;
	}
`

const UseCaseTitle = styled.h3`
	text-align: center;
	margin-bottom: 60px;
`

const UseCaseRow = styled.div`
	justify-content: space-between;
	text-align: left;
	display: flex;
	margin-bottom: 60px;

	@media (max-width: 768px) {
		flex-direction: column;
		margin-bottom: 0px;
	}
`

const UseCaseItemContainer = styled.div`
	width: 540px;
	padding: 30px 50px;
	display: inline-block;
	height: 340px;
	border: 1px solid #ececec;
	border-radius: 2px;
	font-size: 14px;

	:hover {
		box-shadow: 0px 2px 4px 0px #f5f5f5;
	}

	@media (max-width: 768px) {
		width: 100%;
		display: block;
		margin-bottom: 20px;
		height: auto;
		padding: 30px 20px;
	}
`

const UseCaseImageWrapper = styled.div`
	overflow: hidden;
	width: 164px;
	height: 164px;
	margin-bottom: 24px;

	@media (max-width: 768px) {
		margin-bottom: 12px;
	}
`

const UseCaseHeader = styled(T1)`
	margin-bottom: 4px;
	letter-spacing: -0.63px;
	line-height: 1.4;
`

const UseCaseText = styled.p`
	color: ${COLOR_TEXT_DARKGRAY};
	letter-spacing: -0.5px;
	line-height: 1.75;
`

const UseCaseImage = styled.img`
	width: 164px;
	height: 164px;
`

/**
 * Join Us Section
 */
const JoinUsSection = styled.section`
	background: ${COLOR_LIGHT_PINK};
	margin-bottom: 220px;

	@media (max-width: 768px) {
		margin-top: 70px;
		margin-bottom: 0px;
	}
`
const JoinUsWrapper = styled.div`
	text-align: center;
	padding-top: 114px;
	height: 613px;

	@media (max-width: 768px) {
		padding-top: 60px;
	}
`

const JoinUsButton = styled(Button)`
	padding: 20px 50px !important;
	font-size: 14px;
	font-weight: 500;
	line-height: normal;
	letter-spacing: -0.5px;
	margin-bottom: 50px;
	position: relative;
`

const JoinUsImageWrapper = styled.div`
	margin-top: -55px;
`

const JoinUsImage = styled.img`
	@media (max-width: 768px) {
		width: 100%;
		max-width: 600px;
		padding: 20px;
	}
`

const JoinUsTitle = styled.h3`
	font-weight: 400;
	margin-bottom: 30px;
	color: ${COLOR_PRIMARY};

	@media (max-width: 768px) {
		font-size: 24px;
	}
`
