import React from 'react'
import PropTypes from 'prop-types'

import { Loader } from 'semantic-ui-react'
import { UISize } from '../../../enum'

export const InlineLoader = ({ height, size }) => {
	return (
		<div style={{ height }}>
			<Loader
				size={size}
				active
				inline="centered"
				style={{ top: height ? height / 2 : 0, marginTop: -20 }}
			/>
		</div>
	)
}

InlineLoader.propTypes = {
	height: PropTypes.number,
	size: PropTypes.oneOf(Object.values(UISize)),
}

InlineLoader.defaultProps = {
	height: 50,
	size: UISize.SMALL,
}
