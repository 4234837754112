import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'semantic-ui-react'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import compose from 'lodash.flowright'
import { graphql } from 'react-apollo'
import withConfirmDialogue from '../../../higherComponents/withConfirmDialogue'
import BottomButtons from '../../common/basic/BottomButtons'

import { UPDATE_RESEARCH_OVERVIEW } from '../../../graphql/mutations/research'
/**
 * 양식 수정 화면
 */
class ResearchOverviewEdit extends PureComponent {
	static propTypes = {
		onUpdateResearch: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = {
			editorState: EditorState.createWithContent(convertFromRaw(markdownToDraft(props.overview))),
			isButtonLoading: false,
		}
	}

	handleEditorStateChange = editorState => {
		this.setState({
			editorState,
		})
	}

	handleSubmit = async () => {
		const { onUpdateResearch, researchId, onStopEditing } = this.props
		const { editorState } = this.state

		this.setState({
			isButtonLoading: true,
		})

		try {
			await onUpdateResearch({
				variables: {
					researchId,
					overview: draftToMarkdown(convertToRaw(editorState.getCurrentContent())),
				},
			})

			onStopEditing()
		} catch (e) {
			alert('오류가 발생했습니다.')
		}

		this.setState({
			isButtonLoading: false,
		})
	}

	handleStopEditing = () => {
		const { onStopEditing } = this.props
		onStopEditing()
	}

	render() {
		const { editorState, isButtonLoading } = this.state
		return (
			<div>
				<Editor
					wrapperClassName="overviewWrapper"
					editorClassName="overviewEditor markdown-body"
					editorStyle={{
						border: '1px solid #eee',
						padding: 20,
						minHeight: 500,
						maxHeight: 500,
					}}
					onEditorStateChange={this.handleEditorStateChange}
					editorState={editorState}
				/>
				<BottomButtons>
					<Button size="big" loading={isButtonLoading} onClick={this.handleStopEditing}>
						취소
					</Button>
					<Button primary size="big" loading={isButtonLoading} onClick={this.handleSubmit}>
						저장
					</Button>
				</BottomButtons>
			</div>
		)
	}
}

export default compose(
	withConfirmDialogue,
	graphql(UPDATE_RESEARCH_OVERVIEW, {
		name: 'onUpdateResearch',
	})
)(ResearchOverviewEdit)
