import _ from 'lodash'
import { useState, useCallback } from 'react'

const useSearch = (options, initValue = { text: '', value: null }) => {
	const [value, setValue] = useState(initValue.value)
	const [text, setText] = useState(initValue.text)
	const [loading, setLoading] = useState(false)
	const [results, setResults] = useState([])
	const handleChange = useCallback(
		(e, { value }) => {
			setText(value)
			setLoading(true)
			if (value.length < 1) {
				setText('')
				setResults([])
				setValue(null)
			} else {
				const re = new RegExp(_.escapeRegExp(value), 'i')
				const isMatch = result => re.test(result.text)
				setResults(_.filter(options, isMatch))
			}
			setLoading(false)
		},
		[options]
	)

	const handleSelect = useCallback((e, { result = { text: '', value: null } }) => {
		setText(result.text)
		setValue(result.value)
	}, [])

	return { text, value, loading, results, handleChange, handleSelect }
}

export default useSearch
