import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/ko'

import ResearchTemplateHistory from './ResearchTemplateHistory'
import ResearchTemplateSettings from './ResearchTemplateSettings'
import TemplateView from '../../template/TemplateView'

import {
	ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_DEFAULT,
	ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_HISTORY,
	ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_SETTINGS,
	ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_CHANGES,
	ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_CHANGE_DETAIL,
} from '../../../constants/routes'
import PageContainer from '../../common/container/PageContainer'
import { PAGE_CONTENT_FIXED_WIDTH, PAGE_CONTENT_MAX_WIDTH_EXTRA } from '../../../constants/styles'
import MenuRouters from '../../common/route/MenuRouters'

moment.locale('ko')

const MENU_ITEMS = [
	{
		key: 'PR_MN_TEMPLATE',
		value: null,
		to: [
			ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_DEFAULT,
			ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_CHANGES,
			ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_CHANGE_DETAIL,
		],
		exact: true,
		title: '양식',
		component: TemplateView,
	},
	{
		key: 'PR_MN_TEMPLATE_HISTORY',
		value: 'history',
		to: ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_HISTORY,
		title: '수정내역',
		component: ResearchTemplateHistory,
	},
	{
		key: 'PR_MN_TEMPLATE_SETTINGS',
		value: 'settings',
		to: ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_SETTINGS,
		title: '설정',
		component: ResearchTemplateSettings,
	},
]

/**
 * 양식 세부 화면
 */
class ResearchTemplateDetail extends PureComponent {
	static propTypes = {
		researchId: PropTypes.number.isRequired,
		onUpdateOptions: PropTypes.func,
		updateRecordTemplate: PropTypes.func.isRequired,
	}

	handleSaveTemplate = async (medInfos, { templateId, message }) => {
		const { updateRecordTemplate, researchId } = this.props

		const res = await updateRecordTemplate({
			variables: { medInfos, templateId, researchId, message },
		})
		if (res) {
			alert('저장되었습니다.')
		}
	}

	render() {
		const { onUpdateOptions } = this.props

		return (
			<PageContainer
				minMax
				minWidth={PAGE_CONTENT_FIXED_WIDTH}
				maxWidth={PAGE_CONTENT_MAX_WIDTH_EXTRA}
				padded
			>
				<MenuRouters
					menuItems={MENU_ITEMS}
					basicProps={{
						...this.props,
						onSaveChart: this.handleSaveTemplate,
						onUpdateOptions: onUpdateOptions,
					}}
				/>
			</PageContainer>
		)
	}
}

export default withRouter(ResearchTemplateDetail)
