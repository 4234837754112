import { useRef, useEffect } from 'react'

/**
 * 인풋 로드시 포커스
 */
const useInputFocus = condition => {
	const ref = useRef()

	useEffect(() => {
		if (condition === true && ref.current != null) {
			ref.current.focus()
		}
	}, [condition])

	return [ref]
}

export default useInputFocus
