import React from 'react'
import PropTypes from 'prop-types'
import { Image, Placeholder, PlaceholderLine } from 'semantic-ui-react'

const UserInline = props => {
	const { profileImage, name, size, loading, fontSize, fontWeight, marginRight } = props
	if (loading === true) {
		return (
			<Placeholder style={{ width: 30 }} inline>
				<PlaceholderLine />
			</Placeholder>
		)
	}

	return (
		<span style={{ marginRight: marginRight === true && '48px' }}>
			<Image avatar style={{ width: size, height: size, position: 'static' }} src={profileImage} />
			&nbsp;<b style={{ fontSize, fontWeight }}>{name}</b>
		</span>
	)
}

UserInline.propTypes = {
	profileImage: PropTypes.string,
	name: PropTypes.string,
	size: PropTypes.number,
	loading: PropTypes.bool,
	fontSize: PropTypes.any,
	fontWeight: PropTypes.any,
	marginRight: PropTypes.bool,
}

UserInline.defaultProps = {
	profileImage: null,
	name: '',
	size: 30,
	loading: false,
}

export default UserInline
