import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { ROUTE_PROJECT_DETAIL_DOCTORS_DEFAULT } from '../constants/routes'
import ResearchDoctorList from './ResearchDoctorList'

const ResearchDoctors = props => {
	return (
		<Switch>
			<Route
				path={ROUTE_PROJECT_DETAIL_DOCTORS_DEFAULT}
				component={p => <ResearchDoctorList {...props} {...p} />}
			/>
		</Switch>
	)
}

export default ResearchDoctors
