import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { graphql } from 'react-apollo'
import compose from 'lodash.flowright'
import Chart from './Chart'
import MenuRouters from '../components/common/route/MenuRouters'
import {
	ROUTE_PATIENT_DETAIL_RECORD,
	ROUTE_PATIENT_DETAIL_STATS_DEFAULT,
} from '../constants/routes'
import PatientStats from '../components/patient/detail/PatientStats'
import { GET_PATIENT_DETAIL_BY_ID } from '../graphql/queries/patient'

const ROUTES = [
	{
		key: 'PD_RD',
		component: Chart,
		to: ROUTE_PATIENT_DETAIL_RECORD,
	},
	{
		key: 'PD_ST',
		component: PatientStats,
		to: ROUTE_PATIENT_DETAIL_STATS_DEFAULT,
	},
]

/**
 * 환자 상세 페이지
 */
class PatientDetail extends PureComponent {
	static propTypes = {
		match: PropTypes.shape({
			params: PropTypes.shape({
				id: PropTypes.string,
			}),
		}),
	}

	render() {
		const {
			match: {
				params: { id: patientId },
			},
		} = this.props
		return (
			<MenuRouters
				menuItems={ROUTES}
				basicProps={{ ...this.props, patientId: parseInt(patientId, 10) }}
			/>
		)
	}
}

export default compose(
	withRouter,
	graphql(GET_PATIENT_DETAIL_BY_ID, {
		options: props => {
			const {
				match: {
					params: { id },
				},
			} = props
			return {
				variables: {
					id: parseInt(id, 10),
				},
			}
		},
		props: props => {
			const {
				data: { loading, error, refetch, patient },
				ownProps: {
					match: {
						params: { id },
					},
				},
			} = props

			return {
				loadingPatient: loading,
				errorPatient: error,
				updatePatient: refetch,
				patient,
				patientId: parseInt(id, 10),
			}
		},
	})
)(PatientDetail)
