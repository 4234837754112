import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Prompt, useLocation } from 'react-router-dom'
import { useQuery } from 'react-apollo'
import PatientSurveyStart from '../components/patient-survey/PatientSurveyStart'
import PatientSurveyQuestions from '../components/patient-survey/PatientSurveyQuestions'
import { GET_PATIENT_SURVEY_OPEN } from '../graphql/queries/app'
import ToastMessages from '../components/app/ToastMessages'
import { isMatchingRoutes } from '../libs/routeHelper'
import { ROUTE_PATIENT_ONLY_SURVEY } from '../constants/routes'

/**
 * 환자 전용 설문 기본 페이지
 */
const PatientSurvey = ({ isPatient, secretCode, onSubmitSign }) => {
	const {
		data: { isDoingSurvey },
	} = useQuery(GET_PATIENT_SURVEY_OPEN)

	const { pathname } = useLocation()

	const isSurveyPage = isMatchingRoutes(pathname, [ROUTE_PATIENT_ONLY_SURVEY])

	// 새로고침 등 페이지 빠져나올 때 Dialog로 재확인
	useEffect(() => {
		if (isSurveyPage === true) {
			window.onbeforeunload = function() {
				return '레피어스 환자 설문 시스템에서 빠져나가시겠습니까? 입력중이던 데이터는 저장되지 않습니다.'
			}

			return () => {
				window.onbeforeunload = null
			}
		}
	}, [isSurveyPage])

	return (
		<Content>
			{/* 뒤로가기 버튼 누를 경우 Dialog로 재확인 */}
			<Prompt
				when={isSurveyPage === true}
				message="레피어스 환자 설문 시스템에서 빠져나가시겠습니까? 입력중이던 데이터는 저장되지 않습니다."
			/>
			{isDoingSurvey[isPatient ? 'patient' : 'researcher'] === false ? (
				<PatientSurveyStart />
			) : (
				<PatientSurveyQuestions
					isPatient={isPatient}
					secretCode={secretCode}
					onSubmitSign={onSubmitSign}
				/>
			)}
			{/* <Switch>
				<Route path={ROUTE_HOME} exact component={PatientSurveyStart} />
				<Route path={ROUTE_PATIENT_ONLY_SURVEY} exact component={PatientSurveyQuestions} />
			</Switch> */}
			{/* <Logo /> */}
			<ToastMessages />
		</Content>
	)
}

PatientSurvey.propTypes = {
	isPatient: PropTypes.bool,
	secretCode: PropTypes.string,
	onSubmitSign: PropTypes.func,
}

export default PatientSurvey

const Content = styled.div`
	height: 100%;
	width: 100%;
	max-width: ${props => props.theme.PAGE_TABLET_MAX_WIDTH}px;
	margin: auto;
	overflow: hidden;

	min-height: 0px;
	min-width: 0px;
`
