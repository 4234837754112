import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { Query, graphql } from 'react-apollo'
import compose from 'lodash.flowright'

import ResearchTemplateDetail from '../components/research/templates/ResearchTemplateDetail'

import { GET_RECORD_TEMPLATE } from '../graphql/queries/record'
import { UPDATE_RECORD_TEMPLATE, UPDATE_RECORD_TEMPLATE_OPTIONS } from '../graphql/mutations/record'
import { InlineLoader } from '../components/common/loader/InlineLoader'
import ErrorView from '../components/common/error/ErrorView'

/**
 * 연구 양식 세부 페이지
 */
const ResearchTemplate = props => {
	const { match } = props

	const templateId = parseInt(match.params.subId)
	if (templateId == null) return <Redirect to="/404" />

	return (
		<Query query={GET_RECORD_TEMPLATE} variables={{ templateId }}>
			{({ loading, data, error, refetch }) => {
				if (loading) return <InlineLoader height={400} />

				if (error) return <ErrorView />

				return (
					<ResearchTemplateDetail
						{...props}
						{...data.recordTemplate}
						templateMode
						onUpdate={refetch}
					/>
				)
			}}
		</Query>
	)
}

ResearchTemplate.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			subId: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
}

export default compose(
	graphql(UPDATE_RECORD_TEMPLATE, {
		name: 'updateRecordTemplate',
	}),
	graphql(UPDATE_RECORD_TEMPLATE_OPTIONS, {
		name: 'onUpdateOptions',
	})
)(ResearchTemplate)
