import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import {
	DEPARTMENT_SECTION,
	PATIENT_SECTION,
	PROJECT_SECTION,
	TEMPLATE_SECTION,
} from './PatientSurveySection'
import React, { useState } from 'react'

import { FullCenteredVerticalFlexDiv } from '../common/basic/StyledBox'
import PatientSurveyDepartment from './PatientSurveyDepartment'
import PatientSurveyPatient from './PatientSurveyPatient'
import PatientSurveyProject from './PatientSurveyProject'
import PatientSurveyTemplate from './PatientSurveyTemplate'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const PatientSurveyContent = ({ institutionId }) => {
	const isDepartment = parseInt(localStorage.getItem('department'), 10) || null

	const [step, setStep] = useState(isDepartment ? PROJECT_SECTION : DEPARTMENT_SECTION)
	const [selectedDepartment, setSelectedDepartment] = useState(isDepartment)
	const [researchId, setResearchId] = useState()
	const [researchCount, setResearchCount] = useState(0)
	const [selectedTemplate, setSelectedTemplate] = useState(null)

	const handleSection = SECTION => {
		switch (SECTION) {
			case DEPARTMENT_SECTION:
				localStorage.removeItem('department')
				setStep(DEPARTMENT_SECTION)
				setSelectedDepartment(null)
				setSelectedTemplate(null)
				setResearchId(null)
				break
			case PROJECT_SECTION:
				setStep(PROJECT_SECTION)
				setSelectedTemplate(null)
				setResearchId(null)
				break
			case TEMPLATE_SECTION:
				setStep(TEMPLATE_SECTION)
				setSelectedTemplate(null)
				break
			case PATIENT_SECTION:
				setStep(PATIENT_SECTION)
				break
			default:
				break
		}
	}

	return (
		<Container>
			<Projects>
				{step === DEPARTMENT_SECTION && (
					<SelectWrap>
						<PatientSurveyDepartment
							institutionId={institutionId}
							setDepartment={setSelectedDepartment}
							setStep={setStep}
							handleSection={handleSection}
						/>
					</SelectWrap>
				)}
				{step === PROJECT_SECTION && (
					<SelectWrap>
						<PatientSurveyProject
							researchId={researchId}
							researchCount={researchCount}
							setResearchId={setResearchId}
							setResearchCount={setResearchCount}
							setSelectedTemplate={setSelectedTemplate}
							handleSection={handleSection}
						/>
					</SelectWrap>
				)}
				{step === TEMPLATE_SECTION && (
					<SelectWrap>
						<PatientSurveyTemplate
							researchId={researchId}
							researchCount={researchCount}
							selectedTemplate={selectedTemplate}
							setSelectedTemplate={setSelectedTemplate}
							handleSection={handleSection}
							isDepartment={isDepartment}
						/>
					</SelectWrap>
				)}
				{step === PATIENT_SECTION && (
					<SelectWrap>
						<PatientSurveyPatient
							templateId={selectedTemplate.id}
							researchId={researchId}
							handleSection={handleSection}
						/>
					</SelectWrap>
				)}
			</Projects>
		</Container>
	)
}

PatientSurveyContent.propTypes = {
	institutionId: PropTypes.number,
}

export default PatientSurveyContent

const Projects = styled.div`
	width: 100%;
	height: 100%;
	text-align: left;
`

const Container = styled(FullCenteredVerticalFlexDiv)`
	position: relative;
	top: 100px;
	height: calc(100% - 100px);
`

const SelectWrap = styled.div`
	padding: 0 50px;
	margin-bottom: 50px;
	height: 100%;
`
