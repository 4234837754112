import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Popup, Icon, Input, Button } from 'semantic-ui-react'

const ExportDataPasswordModal = props => {
	const [password, setPassword] = useState('')
	const { isOpen, onClose, onSubmit } = props
	return (
		<Modal open={isOpen} onClose={onClose} size="small">
			<Modal.Header>보안키 확인</Modal.Header>
			<Modal.Content>
				<div className="ExportDataPasswordModal__container">
					<div className="ExportDataPasswordModal__wrapper">
						프로젝트 보안 키를 입력해주세요.
						<Popup
							content="프로젝트 설정 페이지에서 보안키를 설정할 수 있습니다. 잊어버리셨다면 아래 버튼을 눌러 초기화해 주세요."
							trigger={<Icon name="info circle" />}
						/>
					</div>
					<Input
						placeholder="프로젝트 보안키"
						className="ExportDataPasswordModal__input"
						value={password}
						onChange={(e, { value }) => {
							setPassword(value)
						}}
						type="password"
					/>
					<div className="ExportDataPasswordModal__btnWrapper">
						<Button
							primary
							size="large"
							onClick={() => {
								onSubmit(password)
							}}
						>
							확인
						</Button>
					</div>
					<div className="link-like-text ExportDataPasswordModal__forgot">
						보안 키를 잊어버렸어요.
					</div>
				</div>
			</Modal.Content>
		</Modal>
	)
}

ExportDataPasswordModal.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
}

export default ExportDataPasswordModal
