import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Header, Input, Button, Form, Checkbox, Popup, Icon } from 'semantic-ui-react'
import { Mutation, withApollo, useMutation } from 'react-apollo'
import SubContainer from '../components/common/container/SubContainer'
import FieldsContainer from '../components/common/container/FieldsContainer'
import { UPDATE_RESEARCH_PASSWORD } from '../graphql/mutations/research'
import { matchErrorCode } from '../libs/errorHelper'
import errorTypes from '../errorTypes'
import { COLOR_PRIMARY, COLOR_TEXT_LIGHTGRAY, COLOR_TEXT_DARKGRAY } from '../constants/styles'
import T1 from '../components/common/basic/T1'

// TODO: submit event

const ResearchSettingsSecurity = props => {
	const { client, researchId, usePassword } = props

	const [willUsePassword, setWillUsePassword] = useState(usePassword)
	const [currentPassword, setCurrentPassword] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [repeatPassword, setRepeatPassword] = useState('')

	const [submit, { data }] = useMutation(UPDATE_RESEARCH_PASSWORD, {
		client,
	})

	const hasChanges = willUsePassword !== usePassword || willUsePassword === true

	return (
		<SubContainer>
			<FieldsContainer>
				{<Title>보안키 변경</Title>}
				<Desc>
					보안키를 적용하면, 수집 데이터 다운로드 시에 반드시 보안키를 사용한 2차 인증을 거치도록
					하여 보다 안전하게 데이터를 관리할 수 있습니다.
				</Desc>
				<Form>
					<div>
						<Form.Field>
							<Checkbox
								style={{ marginTop: 20 }}
								checked={willUsePassword === true}
								label="보안키를 사용합니다."
								onChange={() => {
									setWillUsePassword(!willUsePassword)
								}}
							/>
							{/* <Popup
								trigger={
									<PopupIcon>
									<Icon name="info circle" size="large" color="gray" />
									</PopupIcon>
								}
								content="보안키를 적용하면, 수집 데이터 다운로드 시에 반드시 보안키를 사용한 2차 인증을 거치도록 하여 보다 안전하게 데이터를 관리할 수 있습니다."
							/> */}
						</Form.Field>
					</div>
					<br />
					{/* {willUsePassword === true && <SubTitle>보안키 변경</SubTitle>} */}
					{willUsePassword === true && usePassword === true && (
						<Form.Field
							control={Input}
							placeholder="현재 보안 키"
							type="password"
							value={currentPassword}
							onChange={(e, { value }) => setCurrentPassword(value)}
						/>
					)}
					{willUsePassword === true && (
						<React.Fragment>
							<Form.Field
								control={Input}
								placeholder="변경할 보안 키"
								type="password"
								value={newPassword}
								onChange={(e, { value }) => setNewPassword(value)}
							/>
							<Form.Field
								control={Input}
								placeholder="확인"
								name="security-key"
								type="password"
								value={repeatPassword}
								onChange={(e, { value }) => setRepeatPassword(value)}
							/>
						</React.Fragment>
					)}
				</Form>
			</FieldsContainer>

			{hasChanges === true && (
				<Button
					size="big"
					primary
					onClick={async () => {
						if (newPassword !== repeatPassword) {
							alert('변경할 보안키가 서로 일치하지 않습니다.')
							return
						}

						if (
							newPassword == '' ||
							repeatPassword == '' ||
							(usePassword === true && currentPassword == '')
						) {
							alert('입력되지 않은 항목이 있습니다.')
							return
						}

						try {
							await submit({
								variables: {
									researchId,
									oldPassword: currentPassword,
									newPassword,
								},
							})

							alert('보안키 설정이 변경되었습니다.')
							setCurrentPassword('')
							setNewPassword('')
							setRepeatPassword('')
						} catch (e) {
							if (matchErrorCode(e, errorTypes.ACCESS_FORBIDDEN_ERROR)) {
								alert('현재 키가 잘못되었습니다. 다시 확인해 주세요.')
							} else {
								alert('오류가 발생했습니다.')
							}
						}
					}}
				>
					변경 사항 저장
				</Button>
			)}
		</SubContainer>
	)
}

export default withApollo(ResearchSettingsSecurity)

// const PopupIcon = styled.div`
// 	margin-left: 20px;
// 	display: inline-block;
// `

const Title = styled.h6`
	margin-bottom: 10px;
`

const Desc = styled.div`
	color: ${COLOR_TEXT_DARKGRAY}
	font-size: 13px;
	margin-bottom: 20px;
`

const SubTitle = styled(T1)`
	margin-top: 10px !important;
	margin-bottom: 20px !important;
`
