import { useRef, useState, useEffect } from 'react'

const useDimensions = (pRef, monitor) => {
	const ref = useRef()

	const [dimensions, setDimensions] = useState({})

	let rRef = pRef || ref

	const cx = rRef.current
	useEffect(() => {
		if (dimensions.width === undefined && rRef.current != null) {
			setDimensions(rRef.current.getBoundingClientRect().toJSON())
		}
	}, [cx, dimensions.width, rRef, setDimensions, monitor])

	return [rRef, dimensions]
}

export default useDimensions
