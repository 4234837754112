import { useApolloClient } from 'react-apollo'
import { RESET_USER_INFO } from '../graphql/mutations/user'
import { deleteUserInfoCookie } from '../libs/cookieHelper'
import { URL_APP } from '../constants/urls'

/**
 * 로그아웃 처리
 */

const useLogout = () => {
	const client = useApolloClient()
	return () =>
		client.mutate({
			mutation: RESET_USER_INFO,
			update: () => {
				deleteUserInfoCookie()
				window.location.href = URL_APP
			},
		})
}

export default useLogout
