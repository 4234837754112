import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withRouter, Switch, Route, Redirect } from 'react-router-dom'
import { Segment } from 'semantic-ui-react'
import ChartContainer from './ChartContainer'
import ContentContainer from './ContentContainer'
import { RouteMenu } from '../RouteMenu'
import { COLOR_BORDER_GRAY } from '../../../constants/styles'
import MenuRouters from '../route/MenuRouters'

const MenuContainer = ({
	isVertical,
	hasChart,
	defaultMenu,
	menuItems,
	menuOptions,
	renderMenuItem,
	basicProps,
	activeParam,
	replaceParam,
	isBox,
}) => {
	let Container
	if (hasChart === true) {
		Container = ChartContainer
	} else if (isBox === true) {
		Container = Segment.Group
	} else {
		Container = ContentContainer
	}

	let Wrapper

	if (isVertical === true) Wrapper = VerticalWrapper
	else if (isBox === true) Wrapper = BoxWrapper
	else Wrapper = RegularWrapper

	const defaultItem = menuItems.find(i => i.value === defaultMenu)
	return (
		<Container
			style={{
				display: isVertical ? 'flex' : 'block',
			}}
		>
			<RouteMenu
				activeParam={activeParam}
				menuItems={menuItems}
				menuOptions={menuOptions}
				renderMenuItem={renderMenuItem}
				replaceParam={replaceParam}
				style={isBox === true && styles.boxMenu}
			/>
			<Wrapper>
				<MenuRouters basicProps={basicProps} menuItems={menuItems} defaultItem={defaultItem} />
				{/* <Switch>
					{menuItems.map(item => {
						const ItemContent = item.component
						return (
							<Route
								key={item.key}
								path={item.to}
								exact={item.exact}
								component={rProps => <ItemContent {...rProps} {...basicProps} {...item.props} />}
							/>
						)
					})}
					{defaultItem != null && <Redirect to={defaultItem.to} />}
				</Switch> */}
			</Wrapper>
		</Container>
	)
}

MenuContainer.propTypes = {
	isVertical: PropTypes.bool,
	hasChart: PropTypes.bool,
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
	defaultMenu: PropTypes.string,
	menuItems: PropTypes.arrayOf(
		PropTypes.shape({
			active: PropTypes.bool,
			value: PropTypes.string,
			text: PropTypes.string,
			to: PropTypes.string,
		})
	).isRequired,
	basicProps: PropTypes.shape(),
}

MenuContainer.defaultProps = {
	isVertical: false,
	hasChart: false,
	defaultMenu: null,
	basicProps: null,
}

export default withRouter(MenuContainer)

const VerticalWrapper = styled.div`
	width: 100%;
`

const RegularWrapper = styled.div`
	width: auto;
	border-top: 1px solid ${COLOR_BORDER_GRAY};
	padding-top: 40px;
`
const BoxWrapper = styled(Segment)`
	padding: 0px !important;
`

const styles = {
	boxMenu: {
		marginLeft: 20,
		marginTop: 18,
	},
}
