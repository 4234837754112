import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { InlineLoader } from '../loader/InlineLoader'
import { ROUTE_LOGIN_REDIRECT, ROUTE_LOGIN } from '../../../constants/routes'

const AuthRequired = ({ render: Component, currentAuthPayload, routeProps, ...others }) => {
	if (currentAuthPayload.user.id === 0) {
		if (currentAuthPayload.noUser === true) {
			const currentLocation = window.location.pathname
			const currentQueryString = window.location.search

			if (currentLocation !== ROUTE_LOGIN) {
				return <Redirect to={`${ROUTE_LOGIN_REDIRECT}${currentLocation}${currentQueryString}`} />
			}
		} else {
			return <InlineLoader height={500} />
		}
	} else {
		return <Component currentUser={currentAuthPayload.user} {...others} {...routeProps} />
	}
}

AuthRequired.propTypes = {
	render: PropTypes.func,
	currentAuthPayload: PropTypes.shape({
		user: PropTypes.shape({
			id: PropTypes.number.isRequired,
		}),
		noUser: PropTypes.bool.isRequired,
	}),
}

export default AuthRequired
