import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-apollo'

import { PatientSurveySectionTitle } from '../common/basic/StyledTexts'
import { PROJECT_SECTION } from './PatientSurveySection'
import { GET_INSTITUTION_DEPARTMENTS } from '../../graphql/queries/institution'
import ErrorView from '../common/error/ErrorView'
import LoadingView from '../common/loader'

const PatientSurveyDepartment = ({ institutionId, setDepartment, handleSection }) => {
	const { loading, data: { institutionDepartments } = {}, error } = useQuery(
		GET_INSTITUTION_DEPARTMENTS,
		{
			variables: { institutionId },
		}
	)

	if (error != null) return <ErrorView error={error} />

	if (loading === true) {
		return <LoadingView />
	}

	return (
		<>
			<DepartmentSectionTitle>진료 과를 선택하세요.</DepartmentSectionTitle>
			<DepartmentItemWrpper>
				<DepartmentItemContainer>
					{institutionDepartments != null && institutionDepartments.length > 0
						? institutionDepartments.map(department => (
								<PatientSurveyDepartmentItem
									key={department.id}
									department={department}
									setDepartment={setDepartment}
									handleSection={handleSection}
								/>
						  ))
						: '진료과가 없습니다.'}
				</DepartmentItemContainer>
			</DepartmentItemWrpper>
		</>
	)
}

const PatientSurveyDepartmentItem = ({
	department: { id, name },
	setDepartment,
	handleSection,
}) => (
	<DepartmentItem
		onClick={() => {
			setDepartment(id)
			localStorage.setItem('department', id)
			handleSection(PROJECT_SECTION)
		}}
	>
		{name}
	</DepartmentItem>
)

PatientSurveyDepartment.propTypes = {
	institutionId: PropTypes.number.isRequired,
	setDepartment: PropTypes.func.isRequired,
	handleSection: PropTypes.func.isRequired,
}

PatientSurveyDepartmentItem.propTypes = {
	department: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
	}),
	setDepartment: PropTypes.func.isRequired,
	handleSection: PropTypes.func.isRequired,
}

export default PatientSurveyDepartment

const DepartmentSectionTitle = styled(PatientSurveySectionTitle)`
	margin-bottom: 30px;
	color: ${props => props.theme.COLOR_TEXT_DRAK_GRAY};
`

const DepartmentItemWrpper = styled.div`
	height: calc(100% - 90px);
	overflow-y: auto;
	padding: 10px;
`

const DepartmentItemContainer = styled.ul`
	display: grid;
	/* grid-template-columns: repeat(3, 1fr); */
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	gap: 20px 20px;
	padding: 0;
`

const DepartmentItem = styled.li`
	min-width: 250px;
	min-height: 150px;
	line-height: 150px;
	vertical-align: middle;
	text-align: center;
	border-radius: 15px;
	background-color: #65c5d8;
	color: white;
	font-size: 25px;
`
