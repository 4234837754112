import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Accordion, Icon, Transition } from 'semantic-ui-react'
import { MobileOnly, ExceptMobile } from '../../common/basic/ResponsiveWrappers'

const Item = ({ title, category, children, date, active, onClose, ...props }) => {
	return (
		<>
			<Accordion.Title active={active} {...props}>
				<ExceptMobile>
					<Category>{category}</Category>
					<Title>{title}</Title>
					<Date>{date}</Date>
				</ExceptMobile>
				<MobileOnly>
					<Title>{title}</Title>
					<Category>{category}</Category>
					<Date>{date}</Date>
				</MobileOnly>
			</Accordion.Title>
			<Accordion.Content active={active}>
				<Transition visible={active} duration={500}>
					{children}
				</Transition>
				<FoldButton onClick={() => onClose()}>
					접기 <Icon name="angle up" />
				</FoldButton>
			</Accordion.Content>
		</>
	)
}

Item.propTypes = {
	title: PropTypes.string.isRequired,
	category: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	date: PropTypes.string.isRequired,
	active: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
}

const Category = styled.span`
	font-size: 13px;
	font-weight: normal;
	color: #389fa2;
	padding: 4px 10px;
	border-radius: 20px;
	border: solid 1px #389fa2;
	margin-right: 24px;
	@media (max-width: 768px) {
		font-size: 10px;
		padding: 2px 8px;
	}
`

const Title = styled.span`
	@media (max-width: 768px) {
		display: block;
		margin-bottom: 5px;
	}
`

const Date = styled.span`
	float: right;
	font-size: 13px;
	font-weight: normal;
	color: #b3b3b3;
	margin-right: 24px;
	@media (max-width: 768px) {
		margin-right: 0px;
	}
`

const FoldButton = styled.div`
	float: right;
	color: #389fa2;
	cursor: pointer;
`
export default Item
