import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'

const StyledSVG = ({ css, ...props }) => {
	return (
		<ReactSVG
			{...props}
			beforeInjection={svg => {
				svg.setAttribute('style', css)
			}}
		/>
	)
}

StyledSVG.propTypes = {
	css: PropTypes.string,
}

StyledSVG.defaultProps = {
	css: '',
}

export default StyledSVG
