import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withApollo } from 'react-apollo'

import ResearchSettingsBasic from './ResearchSettingsBasic'
import ResearchSettingsMemebers from './ResearchSettingsMemebers'
import ResearchSettingsSecurity from './ResearchSettingsSecurity'
import ResearchSettingsDetailForm from '../components/research/settings/ResearchSettingsDetailForm'
import {
	ROUTE_PROJECT_DETAIL_SETTINGS_DEFAULT,
	ROUTE_PROJECT_DETAIL_SETTINGS_DETAIL,
	ROUTE_PROJECT_DETAIL_SETTINGS_MEMBERS,
	ROUTE_PROJECT_DETAIL_SETTINGS_SECURITY,
} from '../constants/routes'
import MenuContainer from '../components/common/container/MenuContainer'
import PageContainer from '../components/common/container/PageContainer'
import { PAGE_CONTENT_MEDIUM_WIDTH } from '../constants/styles'

const MENU_ITEMS = [
	{
		key: 'BASIC_SETTINGS',
		value: null,
		to: ROUTE_PROJECT_DETAIL_SETTINGS_DEFAULT,
		exact: true,
		title: '기본 설정',
		component: ResearchSettingsBasic,
	},
	{
		key: 'DETAIL_SETTINGS',
		value: 'detail',
		to: ROUTE_PROJECT_DETAIL_SETTINGS_DETAIL,
		title: '상세 설정',
		component: ResearchSettingsDetailForm,
	},
	{
		key: 'MEMBERS_SETTINGS',
		value: 'members',
		to: ROUTE_PROJECT_DETAIL_SETTINGS_MEMBERS,
		title: '멤버 관리',
		component: ResearchSettingsMemebers,
	},
	{
		key: 'SECURITY_SETTINGS',
		value: 'security',
		to: ROUTE_PROJECT_DETAIL_SETTINGS_SECURITY,
		title: '보안 설정',
		component: ResearchSettingsSecurity,
	},
]

const PAGE_TITLE = '설정'

class ResearchSettings extends PureComponent {
	static propTypes = {
		researchId: PropTypes.number.isRequired,
	}

	render() {
		const { researchId, breadCrumbs } = this.props

		return (
			<PageContainer
				title={PAGE_TITLE}
				breadCrumbs={breadCrumbs}
				fixed
				width={PAGE_CONTENT_MEDIUM_WIDTH}
			>
				<MenuContainer
					isBox
					menuItems={MENU_ITEMS}
					menuOptions={{
						pointing: true,
						secondary: true,
					}}
					replaceParam={{
						id: researchId,
					}}
					basicProps={{
						...this.props,
					}}
				/>
			</PageContainer>
		)
	}
}

export default withApollo(withRouter(ResearchSettings))
