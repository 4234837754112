import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import NextButton from './NextButton'

export default function SectionIntro({ medInfo, toNext }) {
	const { medInfoType } = medInfo
	const { name, description } = medInfoType
	return (
		<Container>
			<Title>{name}</Title>
			<Description>{description}</Description>
			<NextButton onClick={() => toNext()}>시작</NextButton>
		</Container>
	)
}

SectionIntro.propTypes = {
	medInfo: PropTypes.object,
	toNext: PropTypes.func,
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	padding: 0px 10%;
	padding-top: 10vh;
	padding-bottom: 10%;
	height: 100%;
	overflow-y: auto;

	&::-webkit-scrollbar {
		display: none;
	}
`

const Title = styled.div`
	width: 80%;
	font-size: 40px;
	line-height: 48px;
	font-weight: bold;
	color: ${props => props.theme.COLOR_TEXT_NAVY};
	margin-bottom: 20px;
`

const Description = styled.div`
	width: 80%;
	font-size: 20px;
	line-height: 28px;
	margin-bottom: 20px;
`
