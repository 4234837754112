import React from 'react'
import styled from 'styled-components'
import { COLOR_TEXT_LIGHTGRAY } from '../../constants/styles'

const EmptyChart = props => {
	return <EmptyChartBox {...props}>입력된 항목이 없습니다.</EmptyChartBox>
}

export default EmptyChart

const EmptyChartBox = styled.div`
	display: flex;
	height: ${props => props.height + 'px' || '100%'};
	width: ${props => props.width + 'px' || '100%'};
	justify-content: center;
	align-items: center;
	color: ${COLOR_TEXT_LIGHTGRAY};
	font-size: 16px;
`
