import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid } from 'semantic-ui-react'

import { BORDER_GRAY, BOX_SHADOW_LIGHT } from '../../../constants/styles'
import AddFieldDropdown from '../control/AddFieldDropdown'
import ChartAddButtonView from './ChartAddButtonView'

/**
 * 필드 추가 버튼
 */
const DataFieldAddButton = ({
	onCreateMedInfo,
	onInsertMedInfo,
	onOpenTypeModal,
	onSearchMedInfoTypes,
	name,
}) => {
	return (
		<AddButtonColumn mobile={16}>
			<StyledAddFieldDropdown
				className="add-field-dropdown"
				trigger={<ChartAddButtonView />}
				parentName={name}
				onCreateMedInfo={onCreateMedInfo}
				onInsertMedInfo={onInsertMedInfo}
				onOpenTypeModal={onOpenTypeModal}
				onSearch={onSearchMedInfoTypes}
			/>
		</AddButtonColumn>
	)
}

DataFieldAddButton.propTypes = {
	onCreateMedInfo: PropTypes.func.isRequired,
	onInsertMedInfo: PropTypes.func.isRequired,
	onOpenTypeModal: PropTypes.func.isRequired,
	onSearchMedInfoTypes: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
}

export default DataFieldAddButton

const AddButtonColumn = styled(Grid.Column)`
	text-align: left;

	.add-field-dropdown.active,
	.add-field-dropdown:hover {
		border: ${BORDER_GRAY};
		box-shadow: ${BOX_SHADOW_LIGHT};
	}
`

const StyledAddFieldDropdown = styled(AddFieldDropdown)`
	width: 100%;
	height: 100%;
`
