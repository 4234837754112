import React, { useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from 'react-apollo'
import styled from 'styled-components'
import _ from 'lodash'
import {
	Table,
	Icon,
	Search,
	Checkbox,
	Input,
	Form,
	Modal,
	Button,
	Dropdown,
} from 'semantic-ui-react'
import { Operator, ConditionAction, ConditionScope } from '../../../enum'
import ConditionRow from './ConditionRow'
import useSearch from '../../../hooks/useSearch'
import {
	ADD_RESEARCH_CONDITION,
	DELETE_RESEARCH_CONDITION,
} from '../../../graphql/mutations/research'
import ConditionButton from './ConditionButton'
import ConditionResultRenderer from './ConditionResultRenderer'
import { GET_CONDITIONS } from '../../../graphql/queries/record'

const opArray = Object.values(Operator).map(op => ({
	key: op.key,
	value: op.key,
	text: op.text,
}))

const actionArray = Object.values(ConditionAction)
	.filter(act => act.text != null)
	.map(act => ({
		value: act.key,
		text: act.text,
	}))

const scopeArray = Object.values(ConditionScope).map(scp => ({
	value: scp.key,
	text: scp.text,
}))

/**
 * 프로젝트 조건식 테이블 뷰
 */
export default function ConditionView({ conditions, medInfoTypes, researchId }) {
	const medArray = medInfoTypes.map(med => ({
		value: med.id,
		text: med.name,
	}))

	const {
		value: sourceValue,
		text: sourceText,
		loading: sourceLoading,
		results: sourceResults,
		handleChange: handleSourceChange,
		handleSelect: handleSourceSelect,
	} = useSearch(medArray)
	const {
		value: targetValue,
		text: targetText,
		loading: targetLoading,
		results: targetResults,
		handleChange: handleTargetChange,
		handleSelect: handleTargetSelect,
	} = useSearch(medArray)

	const [operator, setOperator] = useState(opArray[0].value)
	const [action, setAction] = useState(actionArray[0].value)
	const [scope, setScope] = useState(scopeArray[0].value)
	const [value, setValue] = useState('')
	const [isRequired, setRequired] = useState(false)

	const [addResearchCondition] = useMutation(ADD_RESEARCH_CONDITION, {
		variables: {
			condition: {
				value,
				isRequired,
				operator,
				scope,
				action,
				subjectId: sourceValue,
				targetId: targetValue,
			},
			researchId,
		},
		refetchQueries: [
			{
				query: GET_CONDITIONS,
				variables: {
					researchId,
				},
			},
		],
	})

	const [error, setError] = useState({})
	const sourceRef = useRef()

	const handleCreate = useCallback(() => {
		if (sourceValue == null) {
			setError({
				source: true,
			})
			sourceRef.current.focus()
		} else {
			setError({})
			handleSourceSelect(null, {
				result: {
					text: '',
					value: null,
				},
			})
			handleTargetSelect(null, {
				result: {
					text: '',
					value: null,
				},
			})
			setOperator(opArray[0].value)
			setAction(actionArray[0].value)
			setScope(scopeArray[0].value)
			setValue('')
			setRequired(false)

			addResearchCondition()
		}
	}, [sourceValue, addResearchCondition, handleSourceSelect, handleTargetSelect])

	const [openDeleteModal, setDeleteModal] = useState(false)
	const [deleteId, setDeleteId] = useState(null)

	const handleOpenDeleteModal = id => {
		setDeleteId(id)
		setDeleteModal(true)
	}

	return (
		<Form>
			<Table singleLine>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell textAlign="center">참조 필드</Table.HeaderCell>
						<Table.HeaderCell textAlign="center">영향 받는 필드</Table.HeaderCell>
						<Table.HeaderCell textAlign="center">활성화 조건</Table.HeaderCell>
						<Table.HeaderCell textAlign="center">참조값</Table.HeaderCell>
						<Table.HeaderCell textAlign="center">참조값 범위</Table.HeaderCell>
						<Table.HeaderCell textAlign="center">동작</Table.HeaderCell>
						<Table.HeaderCell textAlign="center">필수여부</Table.HeaderCell>
						<Table.HeaderCell textAlign="center"></Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					<AddFormRow>
						<Table.Cell>
							<Form.Field error={error.source}>
								<Search
									fluid
									onSearchChange={_.debounce(handleSourceChange, 500, {
										leading: true,
									})}
									onResultSelect={handleSourceSelect}
									value={sourceText}
									results={sourceResults}
									resultRenderer={ConditionResultRenderer}
									loading={sourceLoading}
									input={{ ref: sourceRef }}
									noResultsMessage="필드를 찾지 못했습니다."
								/>
							</Form.Field>
						</Table.Cell>
						<Table.Cell>
							<Search
								fluid
								onSearchChange={_.debounce(handleTargetChange, 500, {
									leading: true,
								})}
								onResultSelect={handleTargetSelect}
								value={targetText}
								results={targetResults}
								resultRenderer={ConditionResultRenderer}
								loading={targetLoading}
								noResultsMessage="필드를 찾지 못했습니다."
							/>
						</Table.Cell>
						<Table.Cell>
							<StyledDropdown
								fluid
								options={opArray}
								value={operator}
								onChange={(e, { value }) => {
									setOperator(value)
								}}
							/>
						</Table.Cell>
						<Table.Cell>
							<Input fluid value={value} onChange={(e, { value }) => setValue(value)} />
						</Table.Cell>
						<Table.Cell>
							<StyledDropdown
								fluid
								options={scopeArray}
								value={scope}
								onChange={(e, { value }) => {
									setScope(value)
								}}
							/>
						</Table.Cell>
						<Table.Cell>
							<StyledDropdown
								fluid
								options={actionArray}
								value={action}
								onChange={(e, { value }) => {
									setAction(value)
								}}
							/>
						</Table.Cell>
						<Table.Cell textAlign="center">
							<Checkbox checked={isRequired} onChange={() => setRequired(prev => !prev)} />
						</Table.Cell>
						<Table.Cell textAlign="center">
							<ConditionButton onClick={handleCreate}>
								<Icon name="add circle" />
							</ConditionButton>
						</Table.Cell>
					</AddFormRow>
					{conditions.map(condition => (
						<Table.Row key={condition.id}>
							<ConditionRow
								{...condition}
								operators={opArray}
								actions={actionArray}
								medInfoTypes={medArray}
								scopes={scopeArray}
								researchId={researchId}
								onOpenDeleteModal={handleOpenDeleteModal}
							/>
						</Table.Row>
					))}
				</Table.Body>
			</Table>
			<DeleteModal
				open={openDeleteModal}
				onClose={() => setDeleteModal(false)}
				researchId={researchId}
				id={deleteId}
			/>
		</Form>
	)
}

ConditionView.propTypes = {
	conditions: PropTypes.array,
	medInfoTypes: PropTypes.array,
	researchId: PropTypes.number,
}

const AddFormRow = styled(Table.Row)`
	background: ${props => props.theme.COLOR_BACKGROUND_GRAY};
`

const DeleteModal = ({ open, onClose, researchId, id }) => {
	const [deleteResearchCondition] = useMutation(DELETE_RESEARCH_CONDITION, {
		variables: {
			researchId,
			condition: {
				id,
			},
		},
		refetchQueries: [
			{
				query: GET_CONDITIONS,
				variables: {
					researchId,
				},
			},
		],
	})

	return (
		<Modal open={open} size="mini" onClose={onClose}>
			<Modal.Content>선택한 항목을 삭제하시겠습니까?</Modal.Content>
			<Modal.Actions>
				<Button onClick={onClose}>취소</Button>
				<Button
					color="red"
					onClick={() => {
						deleteResearchCondition()
						onClose()
					}}
				>
					삭제
				</Button>
			</Modal.Actions>
		</Modal>
	)
}

DeleteModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	researchId: PropTypes.number,
	id: PropTypes.number,
}

const StyledDropdown = styled(Dropdown)`
	display: flex !important;
`
