import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Table, Checkbox } from 'semantic-ui-react'
import { InlineLoader } from '../../../common/loader/InlineLoader'

/**
 * 연구자 기관별 권한 개별 행 컴퍼넌트
 */
class ResearcherInstitutionViewRow extends PureComponent {
	static propTypes = {
		canAll: PropTypes.shape({
			canAccessAll: PropTypes.bool,
			canEditAll: PropTypes.bool,
		}).isRequired,
		canAccess: PropTypes.bool,
		canEdit: PropTypes.bool,
		editDisabled: PropTypes.bool.isRequired,
		readDisabled: PropTypes.bool.isRequired,
		onUpdateResearchInstitutionPermission: PropTypes.func,
		match: PropTypes.shape({
			params: PropTypes.shape({
				id: PropTypes.string,
				subId: PropTypes.string,
			}),
		}),
		institution: PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string,
		}),
		onUpdate: PropTypes.func,
	}

	constructor(props) {
		super(props)

		this.state = {
			loading: {
				canAccess: false,
				canEdit: false,
			},
		}
	}

	handleCheck = key => async (e, { checked }) => {
		const {
			onUpdateResearchInstitutionPermission,
			onUpdate,
			match,
			institution,
			canAll,
		} = this.props
		const CHOOSETEXT = { canAccess: '열람', canEdit: '데이터 관리' }

		if (canAll[key + 'All'] === true) {
			alert(
				`[개별 ${CHOOSETEXT[key]}] 권한을 설정하시려면 [전체 기관 ${CHOOSETEXT[key]}] 권한을 먼저 해제해 주세요.`
			)
			return
		}

		this.setState({
			loading: {
				[key]: true,
			},
		})

		try {
			await onUpdateResearchInstitutionPermission({
				variables: {
					researchId: parseInt(match.params.id, 10),
					userId: parseInt(match.params.subId, 10),
					[key]: checked,
					institutionId: institution.id,
				},
			})
			onUpdate()
		} catch (e) {
			alert('저장에 실패하였습니다.')
		}

		this.setState({
			loading: {
				[key]: false,
			},
		})
	}

	render() {
		const { canAll, canAccess, canEdit, institution, editDisabled, readDisabled } = this.props
		const { canAccessAll, canEditAll } = canAll

		const {
			loading: { canAccess: canAccessLoading, canEdit: canEditLoading },
		} = this.state
		return (
			<Table.Row textAlign="center">
				<Table.Cell>{institution != null && institution.name}</Table.Cell>
				<Table.Cell>
					{canAccessLoading === true ? (
						<InlineLoader />
					) : (
						<Checkbox
							style={styles.checkboxStyle}
							disabled={readDisabled}
							toggle
							checked={canAccess || canAccessAll}
							onChange={this.handleCheck('canAccess')}
						/>
					)}
				</Table.Cell>
				<Table.Cell>
					{canEditLoading === true ? (
						<InlineLoader />
					) : (
						<Checkbox
							style={styles.checkboxStyle}
							disabled={editDisabled}
							toggle
							checked={canEdit || canEditAll}
							onChange={this.handleCheck('canEdit')}
						/>
					)}
				</Table.Cell>
			</Table.Row>
		)
	}
}

export default ResearcherInstitutionViewRow

const styles = {
	checkboxStyle: {
		marginBottom: 0,
	},
}
