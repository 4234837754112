import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { UISize } from '../../../enum'
import { Avatar } from '../../common/Avatar'
import { ExceptMobile } from '../../common/basic/ResponsiveWrappers'
import { HeaderNotification } from './HeaderNotification'
import { UserStatusDropdown } from './UserStatusDropdown'

/**
 * 사용자 로그인 정보
 */
export const UserStatus = React.memo(({ image, name, nickname, isSmaller }) => {
	return (
		<UserStatusContainer>
			<HeaderNotification />
			<ExceptMobile style={{ marginLeft: 21 }}>
				<UserStatusDropdown
					trigger={<Avatar image={image} size={isSmaller === true ? UISize.TINY : UISize.SMALL} />}
					name={name}
					nickname={nickname}
				/>
			</ExceptMobile>
		</UserStatusContainer>
	)
})

UserStatus.displayName = 'UserStatus'

UserStatus.propTypes = {
	image: PropTypes.string.isRequired,
	isSmaller: PropTypes.bool.isRequired,
	name: PropTypes.string,
	nickname: PropTypes.string,
}

const UserStatusContainer = styled.div`
	text-align: right;
	display: flex;
	@media (max-width: 768px) {
		position: absolute;
		right: 12px;
		top: 8px;
	}

	@media (min-width: 769px) {
		width: 100px;
	}
`
