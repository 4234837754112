import { useReducer, useCallback } from 'react'

const reducer = (state, action) => {
	if (action.name === 'INIT_REDUCER') {
		return {
			...action.value,
		}
	}

	return {
		...state,
		[action.name]: action.value,
	}
}

/**
 * 인풋 값을 컨트롤하는 리듀서
 *
 * 값을 변경하거나 초기상태로 되돌릴 수 있음.
 */
const useInputReducer = fields => {
	const [state, dispatch] = useReducer(reducer, fields)

	const onChange = useCallback((e, { name, value }) => {
		dispatch({
			name,
			value,
		})
	}, [])

	const onRollBack = useCallback(() => {
		dispatch({
			name: 'INIT_REDUCER',
			value: fields,
		})
	}, [fields])

	return [state, onChange, onRollBack]
}

export default useInputReducer
