import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Table, Button, Icon } from 'semantic-ui-react'
import { InputSize, DataFieldFormat } from '../../../enum'
import AddFieldDropdown from '../control/AddFieldDropdown'
import DataFieldGridHeadCell from './DataFieldGridHeadCell'
import { BOX_SHADOW_BUTTON, BOX_SHADOW_BUTTON_HOVER, BORDER_GRAY } from '../../../constants/styles'

/**
 * 차트 테이블 헤더
 */
const DataFieldGridHead = props => {
	const {
		hasLabel,
		columns,
		templateMode,
		deletable,
		onSearchMedInfoTypes,
		onCreateMedInfo,
		onOpenAddModal,
		onCreateMedInfoType,
		disabled,
	} = props

	const cellRatios = columns.reduce((total, item) => {
		if (item.size && (!item.format || !item.format.includes(DataFieldFormat.COMPACT.key))) {
			total = total + InputSize[item.size].cellRatio
		}

		return total
	}, 0)

	return (
		<Table.Header>
			<Table.Row>
				{hasLabel ? <Table.HeaderCell style={{ width: 30 }} /> : null}
				{columns.map(type => {
					return (
						<Table.HeaderCell
							key={type.id}
							style={{
								textAlign: 'center',
								...(type.format && type.format.includes('compact')
									? {
											whiteSpace: 'nowrap',
									  }
									: {
											width: `${(InputSize[type.size].cellRatio / cellRatios) * 100}%`,
									  }),
							}}
						>
							<DataFieldGridHeadCell disabled={disabled} type={type} {...props} />
						</Table.HeaderCell>
					)
				})}
				{(templateMode || deletable) &&
					(templateMode ? (
						<Table.HeaderCell style={{ width: 60, textAlign: 'center' }}>
							<StyledAddFieldDropdown
								style={styles.btn}
								onSearch={onSearchMedInfoTypes}
								onCreateMedInfo={onCreateMedInfoType}
								onOpenTypeModal={onOpenAddModal}
								trigger={
									<StyledButton className="add-button" basic icon circular compact size="medium">
										<Icon name="add" />
									</StyledButton>
								}
							/>
						</Table.HeaderCell>
					) : (
						<Table.HeaderCell style={{ width: 60 }} />
					))}
			</Table.Row>
		</Table.Header>
	)
}

DataFieldGridHead.propTypes = {
	hasLabel: PropTypes.bool,
	columns: PropTypes.array,
	templateMode: PropTypes.bool,
	deletable: PropTypes.bool,
	onSearchMedInfoTypes: PropTypes.func,
	onCreateMedInfo: PropTypes.func,
	onOpenAddModal: PropTypes.func,
}

export default DataFieldGridHead

const StyledAddFieldDropdown = styled(AddFieldDropdown)`
	vertical-align: middle;
	height: 40px;

	:hover .add-button {
		border: ${BORDER_GRAY};
		box-shadow: ${BOX_SHADOW_BUTTON_HOVER} !important;
	}
`

const StyledButton = styled(Button)`
	margin-top: 10px !important;
	border: none !important;
	box-shadow: none !important;
`

const styles = {
	btn: {
		verticalAlign: 'middle',
		marginTop: -4,
	},
	title: {
		marginTop: 5,
		position: 'relative',
	},
	description: {
		fontSize: 13,
		marginBottom: 25,
		whiteSpace: 'pre-line',
	},
	// table label styles
	label: {
		minWidth: 70,
		textAlign: 'center',
		display: 'inline-block',
		cursor: 'pointer',
		marginRight: 10,
		padding: '6px 10px',
		fontSize: '14px',
		background: 'rgba(0,0,0,0.07)',
		border: '1px solid #eee',
		borderRadius: 10,
		fontWeight: 600,
		lineHeight: '1.4285em',
		color: '#4a4b4c',
	},
}
