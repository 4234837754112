import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Switch, Route, Link } from 'react-router-dom'

import { useQuery } from 'react-apollo'

import {
	ROUTE_ABOUT,
	ROUTE_PROJECT_DETAIL,
	ROUTE_PATIENT_DETAIL,
	ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_DEFAULT,
	ROUTE_NEW_PROJECT,
	ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL,
	ROUTE_PATIENT_DETAIL_RECORD_CHANGE_DETAIL,
	ROUTE_PATIENT_DETAIL_RECORD_CHANGES,
	ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL_CHANGE_DETAIL,
	ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL_CHANGES,
} from '../../../constants/routes'
import { HeaderMenu } from './HeaderMenu'

import logo from '../../../assets/images/logo/lepius-logo-small.png'
import {
	PAGE_DEFAULT_MIN_WIDTH,
	BORDER_GRAY,
	PAGE_CONTENT_FIXED_WIDTH,
} from '../../../constants/styles'

import ResearchHeader from './ResearchHeader'
import PatientHeader from './PatientHeader'
import DefaultHeader from './DefaultHeader'
import { GET_SUB_SIDE_MENU_OPEN, GET_SCROLL_STATUS } from '../../../graphql/queries/app'
import HeaderLoginButton from './HeaderLoginButton'
import { UserStatus } from './UserStatus'

/**
 * 페이지 상단 헤더
 */
export const Header = React.memo(props => {
	const {
		isFloating,
		isMenuVisible,
		isLogoVisible,
		isUserLoggedIn,
		isResearcher,
		userImage = '',
		userName = '',
		userNickname = '',
		isMinimal,
		hasSideContent,
	} = props

	const {
		data: { isSubSideMenuOpen },
	} = useQuery(GET_SUB_SIDE_MENU_OPEN)
	const {
		data: { isScrolledDown },
	} = useQuery(GET_SCROLL_STATUS)

	const isSmaller = isMenuVisible === false
	const hasBorder = hasSideContent || isScrolledDown
	const isContentVisible = hasSideContent || isScrolledDown

	const path = [
		ROUTE_PATIENT_DETAIL_RECORD_CHANGE_DETAIL,
		ROUTE_PATIENT_DETAIL_RECORD_CHANGES,
		ROUTE_PATIENT_DETAIL,
		ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL_CHANGE_DETAIL,
		ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL_CHANGES,
		ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL,
		ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_DEFAULT,
	]

	return (
		<Container
			isMinimal={isMinimal}
			hasBorder={hasBorder}
			isFloating={isFloating}
			isSmaller={isSmaller}
			hasSideContent={hasSideContent}
		>
			<InnerContainer isSmaller={isSmaller}>
				{isLogoVisible === true && <Logo isSmaller={isSmaller} />}
				{isMenuVisible === true ? (
					<>
						<HeaderMenu hasLoginButton={!isUserLoggedIn} isResearcher={isResearcher} />
					</>
				) : (
					<HeaderContentWrap>
						<HeaderContent isVisible={isContentVisible === true}>
							<Switch>
								<Route
									path={path}
									render={p => <PatientHeader {...p} isSubSideMenuOpen={isSubSideMenuOpen} />}
								/>
								<Route path={ROUTE_NEW_PROJECT} component={DefaultHeader} />
								<Route
									path={ROUTE_PROJECT_DETAIL}
									render={p => <ResearchHeader {...p} isSubSideMenuOpen={isSubSideMenuOpen} />}
								/>
								<Route component={DefaultHeader} />
							</Switch>
						</HeaderContent>
					</HeaderContentWrap>
				)}
				{isUserLoggedIn === true ? (
					<UserStatus
						image={userImage}
						name={userName}
						nickname={userNickname}
						isSmaller={isMenuVisible === false}
					/>
				) : (
					<ResponsiveWrapper>{isMinimal !== true && <HeaderLoginButton />}</ResponsiveWrapper>
				)}
			</InnerContainer>
		</Container>
	)
})

Header.displayName = 'Header'

Header.propTypes = {
	isMinimal: PropTypes.bool.isRequired,
	isLogoVisible: PropTypes.bool.isRequired,
	isFloating: PropTypes.bool.isRequired,
	isMenuVisible: PropTypes.bool.isRequired,
	isUserLoggedIn: PropTypes.bool.isRequired,
	isResearcher: PropTypes.bool.isRequired,
	userImage: PropTypes.string,
	userName: PropTypes.string,
	userNickname: PropTypes.string,
	hasSideContent: PropTypes.bool.isRequired,
}

/**
 * 로고
 */
const Logo = ({ isSmaller }) => {
	return (
		<LogoContainer to={ROUTE_ABOUT}>
			<LogoImage isSmaller={isSmaller} src={logo} alt="Lepius" />
		</LogoContainer>
	)
}

Logo.propTypes = {
	isSmaller: PropTypes.bool.isRequired,
}

/**
 * Styled Components
 */

/**
 * 전체 Wrapper
 */
const Container = styled.header`
	background: white;
	flex-shrink: 0;
	border-bottom: ${props =>
		props.isSmaller === true && props.hasBorder === true ? BORDER_GRAY : 'none'};
	${props => `
				width: ${props.isMinimal === true ? `${PAGE_CONTENT_FIXED_WIDTH.toString}px` : 'auto'};
				margin: ${props.isMinimal === true ? 'auto' : 0};
				height: ${props.isSmaller === true ? 80 : 96}px;
		`}

	${props =>
		props.hasSideContent !== true
			? `
				@media (max-width: 768px) {
					width: 100%;
					height: 50px;
					position: fixed;
					z-index: 100;
				}
			`
			: ''}
`

/**
 * 내부 Wrapper
 */
const InnerContainer = styled.div`
	height: 100%;

	margin: auto;
	text-align: left;
	display: flex;
	align-items: center;

	@media (max-width: 768px) {
		width: 100%;
	}

	@media (min-width: 769px) {
		width: ${props => (props.isSmaller ? '100%' : `${PAGE_DEFAULT_MIN_WIDTH}px`)};
		padding: 0px ${props => (props.isSmaller === true ? '13' : '40')}px;
	}
`

const HeaderContentWrap = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 100%;
	flex: 1;
`
const HeaderContent = styled.div`
	display: ${props => (props.isVisible === true ? 'flex' : 'none')};
	visibility: ${props => (props.isVisible === true ? 'visible' : 'hidden')};
	opacity: ${props => (props.isVisible === true ? 1 : 0)};
	margin-bottom: ${props => (props.isVisible === true ? 0 : -70)}px;
	transition: opacity 0.7s, margin 0.7s;
	flex-shrink: 1;
	flex: 1;
	padding: 0px 20px;
	height: 100%;
	align-items: center;
`

const LogoContainer = styled(Link)`
	@media (max-width: 768px) {
		margin: auto;
	}
	@media (min-width: 769px) {
		padding-left: 7.6px;
	}
`

const LogoImage = styled.img`
	@media (max-width: 768px) {
		height: 22px;
		padding-left: ${props => (props.isSmaller === true ? 30 : 0)}px;
	}
	@media (min-width: 769px) {
		width: ${props =>
			props.isSmaller === false ? props.theme.LOGO_LARGE_WIDTH : props.theme.LOGO_SMALL_WIDTH}px;
	}
`

const ResponsiveWrapper = styled.div`
	@media (max-width: ${props => props.theme.PAGE_MOBILE_MAX_WIDTH}px) {
		.button {
			color: ${props => props.theme.COLOR_PRIMARY} !important;
			border: 1px solid ${props => props.theme.COLOR_PRIMARY};
			background: white !important;
			padding: 6px 10px !important;
			position: absolute;
			right: 6px;
			top: 6px;
		}
	}
`
