import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Query, graphql } from 'react-apollo'
import compose from 'lodash.flowright'

import { GET_RESEARCH_MEMBERS } from '../graphql/queries/research'
import { SEARCH_USERS } from '../graphql/queries/user'
import {
	UPDATE_RESEARCHER,
	INVITE_RESEARCH_USER,
	REMOVE_RESEARCHER,
} from '../graphql/mutations/research'

import ReserachMembersView from '../components/research/settings/members/ResearchMembersView'
import ErrorView from '../components/common/error/ErrorView'
import PlaceholderContent from '../components/common/loader/PlaceholderContents'

/**
 * 프로젝트 참가자 관리
 */
class ResearchSettingsMemebers extends PureComponent {
	static propTypes = {
		researchId: PropTypes.number.isRequired,
		client: PropTypes.shape({
			query: PropTypes.func.isRequired,
		}).isRequired,
	}

	handleSearchUsers = async value => {
		if (value) {
			const { client } = this.props
			const result = await client.query({
				query: SEARCH_USERS,
				variables: {
					searchString: value,
				},
			})

			if (result.data) {
				return result.data.searchUsers
			}
		}

		return []
	}

	render() {
		const { researchId } = this.props
		return (
			<Query query={GET_RESEARCH_MEMBERS} variables={{ id: researchId }}>
				{({ data, loading, error, refetch }) => {
					if (loading) {
						return <PlaceholderContent />
					} else if (error || !data.researchDetail) {
						return <ErrorView />
					}

					return (
						<ReserachMembersView
							{...this.props}
							loading={loading}
							onUpdate={refetch}
							onSearchUsers={this.handleSearchUsers}
							{...data.researchDetail}
							invitedResearchers={data.invitedResearchers}
						/>
					)
				}}
			</Query>
		)
	}
}

export default compose(
	graphql(UPDATE_RESEARCHER, {
		name: 'onUpdateResearcher',
	}),
	graphql(INVITE_RESEARCH_USER, {
		name: 'onInviteUser',
	}),
	graphql(REMOVE_RESEARCHER, {
		name: 'onRemoveResearcher',
	})
)(ResearchSettingsMemebers)
