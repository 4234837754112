import { REGEX_DATE, PATIENT_INFO_FIELDS } from '../constants'

export const validatePatient = (requiredFields, valueObj) => {
	if (valueObj.birthDate) {
		if (REGEX_DATE.test(valueObj.birthDate) === false) {
			alert('생년월일을 올바르게 입력해 주세요')
			return false
		}
	}

	for (const key of requiredFields) {
		if (key) {
			if (valueObj[key] === null || valueObj[key] === undefined || valueObj[key] === '') {
				alert(`다음 필드를 올바르게 입력해 주세요: ${PATIENT_INFO_FIELDS[key].text}`)
				return false
			}
		}
	}

	return true
}
