import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useQuery } from 'react-apollo'
import { Transition } from 'semantic-ui-react'
import { GET_RESEARCH_RECORD_TEMPLATES } from '../../graphql/queries/record'
import { TemplateJob } from '../../enum'
import LoadingView from '../common/loader'
import EmptyResult from '../common/empty/EmptyResult'
import ErrorView from '../common/error/ErrorView'
import DescriptionText from '../common/basic/DescriptionText'
import T1 from '../common/basic/T1'
import { FlexFill, CenteredRowFlexDiv } from '../common/basic/StyledBox'
import { LinkColoredText } from '../common/basic/StyledTexts'

/**
 * 환자 직접 설문 -  양식 선택 뷰
 */
const ResearchTemplateSelectView = ({
	researchId,
	selectTemplate,
	selectedTemplate,
	isDepartment,
}) => {
	const { data: { researchRecordTemplates } = {}, loading, error } = useQuery(
		GET_RESEARCH_RECORD_TEMPLATES,
		{
			variables: {
				researchId,
				job: TemplateJob.SURVEY.value,
			},
		}
	)

	if (loading === true) return <LoadingView />

	if (error != null) return <ErrorView error={error} />

	if (
		loading !== true &&
		error == null &&
		(researchRecordTemplates == null || researchRecordTemplates.length === 0)
	) {
		return <EmptyResult message="등록된 설문 양식이 없습니다!" />
	}

	return (
		<Container>
			<Transition.Group
				animation={selectedTemplate != null ? 'fade up' : 'fade down'}
				duration={200}
			>
				{researchRecordTemplates
					.filter(
						template => template.department === null || template.department.id === isDepartment
					)
					.map(i => {
						const { id, title, description } = i || {}
						if (selectedTemplate != null && id !== selectedTemplate.id) {
							return null
						}
						return (
							<div key={`rtsv-${id}`}>
								<TemplateItem
									title={title}
									description={description}
									onClick={
										selectedTemplate == null ? () => selectTemplate(i) : () => selectTemplate(null)
									}
									canCancel={selectedTemplate != null}
								/>
							</div>
						)
					})}
			</Transition.Group>
		</Container>
	)
}

ResearchTemplateSelectView.propTypes = {
	researchId: PropTypes.number,
	selectTemplate: PropTypes.func,
	selectedTemplate: PropTypes.shape({
		id: PropTypes.number,
	}),
}

export default ResearchTemplateSelectView

/**
 * 개별 양식 항목
 */
const TemplateItem = ({ onClick, title, description, canCancel }) => {
	return (
		<TemplateItemContainer onClick={onClick}>
			<FlexFill>
				<T1>{title}</T1>
				<DescriptionText>{description}</DescriptionText>
			</FlexFill>
			<CenteredRowFlexDiv>
				{canCancel === true && <LinkColoredText>취소</LinkColoredText>}
			</CenteredRowFlexDiv>
		</TemplateItemContainer>
	)
}

TemplateItem.propTypes = {
	onClick: PropTypes.func,
	title: PropTypes.string,
	description: PropTypes.string,
	canCancel: PropTypes.bool,
}

const Container = styled.div`
	height: 100%;
	overflow-y: auto;
	padding: 10px;
`

const TemplateItemContainer = styled.div`
	padding: 20px;
	box-shadow: ${props => props.theme.BOX_SHADOW_NORMAL};
	margin-bottom: 20px;
	cursor: pointer;
	transition: box-shadow 0.2s;
	border-radius: 10px;
	display: flex;
	width: 100%;

	:active {
		box-shadow: 0px 0px 10px 0 rgba(230, 183, 52, 0.4);
	}
`
