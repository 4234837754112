/**
 * [Component] 환자/기록 삭제 Modal
 *
 * 비밀번호 입력 후 삭제 요청 처리.
 * 비밀번호 틀리거나 삭제할 수 없는 경우 알림 표시.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button, Icon, Input, Form } from 'semantic-ui-react'
import { appendJosa } from '../../../libs/stringHelper'

const DeleteObjectModal = ({
	open,
	handleClose,
	objectText,
	password,
	handleChange,
	handleDelete,
}) => {
	const headerText = `정말로 ${appendJosa(objectText, '을/를')} 삭제하시겠습니까?`
	const menuText = `${objectText} 삭제`

	return (
		<Modal open={open} closeIcon size="tiny" onClose={handleClose}>
			<Modal.Header>{headerText}</Modal.Header>
			<Modal.Content>
				<div style={{ marginBottom: 10, fontWeight: 600 }}>
					<Icon name="exclamation triangle" />
					삭제된 데이터는 복원이 어려울 수 있습니다. 신중하게 선택해 주세요.
				</div>
				<div style={{ marginBottom: 10 }}>삭제하시려면 비밀번호를 다시 한번 입력해 주세요.</div>
				<Form onSubmit={handleDelete}>
					<Input
						fluid
						placeholder="비밀번호"
						name="password"
						type="password"
						value={password}
						onChange={handleChange}
					/>
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button size="big" color="red" onClick={handleDelete}>
					<Icon name="trash" />
					{menuText}
				</Button>
			</Modal.Actions>
		</Modal>
	)
}

DeleteObjectModal.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func.isRequired,
	objectText: PropTypes.string.isRequired,
	password: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleDelete: PropTypes.func.isRequired,
}

export default DeleteObjectModal
