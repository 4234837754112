import React, { useRef, useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import { useQuery, useMutation } from 'react-apollo'
import Slider from 'react-slick'
import { Button, Modal, Icon, Dropdown } from 'semantic-ui-react'
import { GET_PATIENT_SURVEY_STATUS } from '../../graphql/queries/app'
import useUserInfo from '../../hooks/useUserInfo'
import { UPDATE_RECORD_MED_INFOS } from '../../graphql/mutations/record'
import {
	assembleMedInfos,
	extractMedInfoInputs,
	filterSurveyQuestions,
} from '../../libs/medInfoHelper'
import { LOGIN } from '../../graphql/mutations/user'
import {
	FINISH_PATIENT_SURVEY,
	SET_PATIENT_SURVEY_STAGE,
	UPDATE_PATIENT_SIGN,
} from '../../graphql/mutations/app'
import useInputFocus from '../../hooks/useInputFocus'
import errorTypes from '../../errorTypes'
import { matchErrorCode } from '../../libs/errorHelper'
import useToastMessage from '../../hooks/useToastMessage'
import { ALERT_WRONG_PASSWORD, ALERT_UNKNOWN_ERROR } from '../../constants/alerts'
import { TabletInput } from '../common/basic/StyledInput'
import PatientSurveyPage from './PatientSurveyPage'
import useToggle from '../../hooks/useToggle.ts'
import PatientSurveyQuickPage from './PatientSurveyQuickPage'
import NextButton from './NextButton'
import ProgressBar from './ProgressBar'
import surveyPeopleImage from '../../assets/images/drawings/survey-people.png'
import SectionIntro from './SectionIntro'
import PatientSignModal from './PatientSignModal'
import { BASIC_COLORS } from '../../constants/styles'
import useLogout from '../../hooks/useLogout'
import { ReactComponent as SuccessIcon } from '../../assets/images/icons/success.svg'

/**
 * 환자 설문 메인 컴퍼넌트
 *
 * 슬라이더로 첫 페이지와 마지막 페이지, 그리고 각각 설문을 동시에 로드.
 */
const PatientSurveyQuestions = ({ isPatient, secretCode, onSubmitSign }) => {
	const {
		user: { id, profileImage },
	} = useUserInfo()
	const isUserLoggedIn = id !== 0
	const logout = useLogout()
	const {
		data: {
			surveyStatus: {
				[isPatient ? 'patient' : 'researcher']: {
					medInfos,
					recordId,
					researchId,
					patientName,
					templateName,
					templateDescription,
				} = {},
			} = {},
			patientSurveyCurrent,
		} = {},
	} = useQuery(GET_PATIENT_SURVEY_STATUS)

	const [isQuick, setIsQuick] = useToggle(false)
	const [setCurrentStage] = useMutation(SET_PATIENT_SURVEY_STAGE)
	const [updatePatientSign] = useMutation(UPDATE_PATIENT_SIGN)

	const currentIndex = useMemo(() => {
		const finded = patientSurveyCurrent.find(item => item.id === recordId)
		if (finded) return finded.index
	}, [patientSurveyCurrent, recordId])

	useEffect(() => {
		if (sliderRef.current != null) {
			sliderRef.current.slickGoTo(currentIndex)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (isPatient === true && currentIndex === 0)
			updatePatientSign({
				variables: {
					isOpen: true,
				},
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const sliderRef = useRef()

	const filteredMedInfos = useMemo(() => filterSurveyQuestions(medInfos), [medInfos])

	const assembledMedInfos = useMemo(() => {
		return assembleMedInfos(_.cloneDeep(filteredMedInfos)).filter(i => i.disabled !== true)
	}, [filteredMedInfos])

	const [update] = useMutation(UPDATE_RECORD_MED_INFOS)
	const onSubmit = () => {
		const cleaned = extractMedInfoInputs({ medInfos: assembledMedInfos, templateMode: false })

		return update({
			variables: {
				medInfos: cleaned,
				recordId,
				secretCode,
			},
		})
	}
	const toNext = () => {
		if (sliderRef.current != null) {
			sliderRef.current.slickNext()
		}
	}

	const toPrev = () => {
		if (sliderRef.current != null) {
			sliderRef.current.slickPrev()
		}
	}

	const [getOut] = useMutation(FINISH_PATIENT_SURVEY)
	const showToastMessage = useToastMessage()
	const [isModalOpen, setModalOpen] = useState(false)
	const [login] = useMutation(LOGIN, {
		onCompleted: () => {
			getOut()
		},
		onError: e => {
			if (matchErrorCode(e, errorTypes.AUTHENTICATION_FAILED_ERROR)) {
				showToastMessage(ALERT_WRONG_PASSWORD)
			} else {
				showToastMessage(ALERT_UNKNOWN_ERROR)
			}
		},
	})

	const finishIndex = useMemo(() => {
		let i = 1
		assembledMedInfos.forEach(md => {
			i++
			md.children.forEach(() => {
				i++
			})
		})
		return i
	}, [assembledMedInfos])

	return (
		<>
			<Content>
				{isQuick === true ? (
					<PatientSurveyQuickPage
						medInfos={assembledMedInfos}
						patientName={patientName}
						templateName={templateName}
						onSubmit={onSubmit}
						onOpenModal={() => {
							setModalOpen(true)
						}}
					/>
				) : (
					<FullHeightSlider
						infinite={false}
						arrows={false}
						ref={sliderRef}
						adaptiveHeight
						draggable={false}
						isFirstQuestion={currentIndex === 1}
						swipe={false}
						afterChange={index => {
							setCurrentStage({
								variables: {
									index,
									recordId,
								},
							})
						}}
					>
						<PatientSurveyIntro
							toNext={toNext}
							patientName={patientName}
							templateName={templateName}
							templateDescription={templateDescription}
							questionCount={filteredMedInfos.length}
							logoImage={profileImage}
						/>

						{assembledMedInfos.map((section, sectionIndex) => {
							const arr = [<SectionIntro key={`${section.id}`} medInfo={section} toNext={toNext} />]
							const chilren = section.children.map((field, fieldIndex) => (
								<PatientSurveyPage
									key={field.id}
									medInfo={field}
									toNext={toNext}
									toPrev={toPrev}
									logoImage={profileImage}
									sectionIndex={sectionIndex}
									fieldIndex={fieldIndex}
									sectionLength={assembledMedInfos.length}
									fieldLength={section.children.length}
								/>
							))
							return arr.concat(chilren)
						})}
						<PatientSurveyFinish
							isActive={finishIndex === currentIndex}
							onSubmit={onSubmit}
							onOpenModal={() => {
								setModalOpen(true)
							}}
							isPatient={isPatient}
						/>
					</FullHeightSlider>
				)}

				<FinishModal
					isOpen={isModalOpen}
					onClose={() => {
						setModalOpen(false)
					}}
					onLogin={login}
				/>
			</Content>
			{/* <PatientSurveyFooter
				currentPage={currentIndex}
				totalPages={assembledMedInfos.length}
				logoImage={profileImage}
				patientCode={patientCode}
				onOpenCloseModal={() => {
					setModalOpen(true)
				}}
				isQuick={isQuick}
				onChangeView={() => setIsQuick()}
			/> */}
			<Header>
				{isUserLoggedIn && (
					<MenuButton>
						<Dropdown icon="bars">
							<Dropdown.Menu direction="left">
								<Dropdown.Item
									text={`${isQuick ? '일반' : '빠른'} 입력`}
									onClick={() => {
										setIsQuick(prev => !prev)
									}}
								/>
								<Dropdown.Item
									text="로그아웃"
									style={{
										color: BASIC_COLORS.red,
									}}
									onClick={logout}
								/>
							</Dropdown.Menu>
						</Dropdown>
					</MenuButton>
				)}
				<HospitalLogo src={profileImage} />
				{currentIndex !== 0 && (
					<ProgressBar medInfos={assembledMedInfos} currentIndex={currentIndex} />
				)}
			</Header>
			<PatientSignModal noClose onSubmit={onSubmitSign} researchId={researchId} />
		</>
	)
}

PatientSurveyQuestions.propTypes = {
	isPatient: PropTypes.bool,
	secretCode: PropTypes.string,
	onSubmitSign: PropTypes.func,
}

export default PatientSurveyQuestions

/**
 * 설문 첫 페이지
 */
const PatientSurveyIntro = ({ toNext, templateName, questionCount }) => {
	return (
		<IntroWrapper>
			<InfoWrapper>
				<TemplateInfoTitle>{templateName}</TemplateInfoTitle>
				<TemplateInfoSubTitle>설문을 시작하겠습니다. 각 문항에 답해주세요.</TemplateInfoSubTitle>
				<PatientSurveyIntroLabels
					items={[
						{
							description: '예상소요시간',
							value: `${Math.ceil(questionCount * 0.1)}분`,
						},
						{
							description: '문항',
							value: `${questionCount}문항`,
						},
					]}
				/>
			</InfoWrapper>
			<SurveyPeople src={surveyPeopleImage} />
			<StartButton onClick={() => toNext()}>시작</StartButton>
		</IntroWrapper>
	)
}

PatientSurveyIntro.propTypes = {
	toNext: PropTypes.func,
	templateName: PropTypes.string,
	questionCount: PropTypes.number,
}

const PatientSurveyIntroLabels = ({ items }) => {
	return (
		<LabelsWrapper>
			{items.map((item, index) => (
				<React.Fragment key={item.description}>
					<div>
						<LabelDescription>{item.description}</LabelDescription>
						<LabelValue>{item.value}</LabelValue>
					</div>
					{index !== items.length - 1 && (
						<div style={{ background: '#ececec', width: 1, height: '6vmin' }} />
					)}
				</React.Fragment>
			))}
		</LabelsWrapper>
	)
}

PatientSurveyIntroLabels.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			description: PropTypes.string,
			value: PropTypes.string,
		})
	),
}

/**
 * 설문 마지막 페이지
 */
const PatientSurveyFinish = ({ onOpenModal, isPatient }) => {
	return (
		<FinishWrapper>
			<FinishTitleContainer>
				<DoneIcon>
					<SuccessIcon />
				</DoneIcon>
				<Title>설문을 모두 마쳤습니다.</Title>
				<Hello>
					{`수고하셨습니다.${
						isPatient !== true
							? ' 아래 버튼을 누르지 않은 상태로, 이 기기를 담당 선생님께 돌려주세요.'
							: ''
					}`}
				</Hello>
			</FinishTitleContainer>
			<NextButton
				onClick={
					isPatient === true
						? () => {
								window.opener = null
								window.open('', '_self')
								window.close()
						  }
						: onOpenModal
				}
			>
				종료
			</NextButton>
		</FinishWrapper>
	)
}

PatientSurveyFinish.propTypes = {
	onOpenModal: PropTypes.func,
	isPatient: PropTypes.bool,
}

/**
 * 설문 종료 모달
 */
const FinishModal = ({ isOpen, onLogin, onClose }) => {
	const [ref] = useInputFocus(isOpen === true)
	const [password, setPassword] = useState('')
	const { user: { username } = {} } = useUserInfo()

	const onSubmit = () => {
		onLogin({
			variables: {
				username,
				password,
			},
		})
	}
	return (
		<Modal
			centered={false}
			size="tiny"
			open={isOpen}
			onClose={() => {
				setPassword('')
				onClose()
			}}
		>
			<Modal.Header>비밀번호 확인</Modal.Header>
			<Modal.Content>
				<PasswordWrapper>
					<Description>
						환자 설문을 완전히 종료하려면 비밀번호를 확인해 주세요.
						<br />
						<br /> <b>* 병원 관계자만 사용할 수 있습니다.</b>
					</Description>
					<TabletInput
						ref={ref}
						type="password"
						value={password}
						placeholder="비밀번호 입력"
						autoComplete="off"
						onChange={e => {
							setPassword(e.target.value)
						}}
					/>
					<FinishButton onClick={onSubmit}>확인</FinishButton>
				</PasswordWrapper>
			</Modal.Content>
		</Modal>
	)
}

FinishModal.propTypes = {
	isOpen: PropTypes.bool,
	onLogin: PropTypes.func,
	onClose: PropTypes.func,
}

/**
 * Styles
 */

const Content = styled.div`
	height: 100%;
`

const FullHeightSlider = styled(Slider)`
	height: 100% !important;

	.slick-list,
	.slick-track,
	.slick-slide,
	.slick-slide > div {
		height: 100% !important;
	}

	.slick-disabled.slick-prev {
		opacity: 0;
	}

	.slick-prev {
		left: 10px;
		z-index: 999;
		${props =>
			props.isFirstQuestion === true
				? `
			display: none !important;
		`
				: ''}
	}

	.slick-prev::before {
		font-size: 30px;
		color: ${props => props.theme.COLOR_TEXT_LIGHTGRAY};
	}

	.slick-slider input {
		user-select: all !important;
	}
`

const IntroWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: center;
	align-items: center;
	padding-top: 12vh;
`

const FinishWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	height: 100%;
	padding-top: 12vh;
	padding-bottom: 10%;
`

const FinishTitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: auto;
	flex: 1;
`

const Title = styled.div`
	font-size: 40px;
	height: 86px;
	font-weight: 600;
	text-align: center;
	line-height: 1;
	margin: 10px 32px;
`

const Hello = styled.div`
	font-size: 20px;
	line-height: 28px;
	font-weight: 500;
	text-align: center;
	margin: 10px 32px;
`

const TemplateInfoTitle = styled.h1`
	font-size: 40px;
	font-weight: 600;
	color: ${props => props.theme.COLOR_TEXT_NAVY};
	margin-bottom: 2vmin;
	text-align: center;
`
const TemplateInfoSubTitle = styled.h4`
	font-size: 20px;
	text-align: center;
	font-weight: 500;
	color: ${props => props.theme.COLOR_TEXT_NAVY};
`

const IntroButton = styled(Button)`
	background: linear-gradient(180deg, #c77add, #81aae8) !important;
	width: 600px !important;
	padding: 40px !important;
	color: white !important;
	border-radius: 50px !important;
	font-size: 30px !important;
`

const PasswordWrapper = styled.div`
	padding: 10px;
	width: 100%;
	height: 300px;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
`

const Description = styled.div`
	font-size: 16px;
	margin-bottom: 20px;
`

const FinishButton = styled(IntroButton)`
	padding: 20px !important;
	width: 300px !important;
	font-size: 22px !important;
	margin-top: 50px !important;
`

const LabelDescription = styled.h4`
	font-size: 14px;
	line-height: 20px;
	color: ${props => props.theme.COLOR_TEXT_NAVY};
	font-weight: normal;
`

const LabelValue = styled.span`
	font-size: 32px;
	font-family: 'DM Sans', 'Noto Sans Light';
	color: ${props => props.theme.COLOR_TEXT_NAVY};
	font-weight: 500;
	line-height: 1;
`

const LabelsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 80px 0;
	& > div {
		margin-left: 3vmin;
		margin-right: 3vmin;
		:first-child {
			margin-left: 0px;
		}
	}
`

const InfoWrapper = styled.div`
	margin: 32px;
	width: 80%;
`

const HospitalLogo = styled.img`
	position: absolute;
	top: 2vh;
	left: 50%;
	transform: translateX(-50%);
	width: auto;
	height: auto;
	max-width: 80%;
	max-height: 32px;
`

const DoneIcon = styled.div`
	text-align: center;
	line-height: 1;
`

const StartButton = styled(NextButton)`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	margin: 0 !important;
	height: 80px;
	border-radius: 0px !important;
`

const SurveyPeople = styled.img`
	align-self: flex-start;
	height: 40%;
	margin-left: 12px;
`

const Header = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	background: #ffffff;
	width: 100%;
	height: 12vh;
`

const MenuButton = styled.div`
	position: absolute;
	top: 2vh;
	right: 0;
	margin: 0 2vmin;
	font-size: 24px;
	cursor: pointer;
`
