import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { PAGE_MOBILE_MAX_WIDTH, PAGE_NOT_MOBILE_MIN_WIDTH } from '../../../constants/styles'

export const MobileOnly = ({ flex, inline, children, style }) => {
	let display
	if (inline === true) display = 'inline-block'
	else if (flex === true) display = 'flex'
	else display = 'block'

	return (
		<Responsive
			style={{
				...style,
			}}
			display={display}
			maxWidth={PAGE_MOBILE_MAX_WIDTH}
		>
			{children}
		</Responsive>
	)
}

MobileOnly.propTypes = {
	children: PropTypes.node,
	style: PropTypes.object,
	inline: PropTypes.bool,
	flex: PropTypes.bool,
}

export const ExceptMobile = ({ flex, inline, children, style }) => {
	let display
	if (inline === true) display = 'inline-block'
	else if (flex === true) display = 'flex'
	else display = 'block'

	return (
		<Responsive
			style={{
				...style,
			}}
			display={display}
			minWidth={PAGE_NOT_MOBILE_MIN_WIDTH}
		>
			{children}
		</Responsive>
	)
}

ExceptMobile.propTypes = {
	children: PropTypes.node,
	style: PropTypes.object,
	inline: PropTypes.bool,
	flex: PropTypes.bool,
}

const Responsive = styled.div`
	display: none;
	${props =>
		props.minWidth != null
			? `
		@media (min-width:${props.minWidth}px) {
			display: ${props.display};
		}
	`
			: ''}

	${props =>
		props.maxWidth != null
			? `
		@media (max-width:${props.maxWidth}px) {
			display: ${props.display};
		}
	`
			: ''}
`
