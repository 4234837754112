import gql from 'graphql-tag'
import {
	RESEARCH_BASIC_FRAGMENT,
	RESEARCH_BASIC_INFO_FRAGMENT,
	RESEARCHER_INFO_FRAGMENT,
	RESEARCHER_FRAGMENT,
	RESEARCH_SIMPLE_INFO_FRAGMENT,
} from '../fragments/research'
import { MED_INFO_TYPE_FRAGMENT } from '../fragments/medInfo'
import { TEMPLATE_BASIC_INFO } from '../fragments/record'

/**
 * 사용자 참여한 연구 목록 가져오기
 */
export const GET_RESEARCHES = gql`
	{
		userResearches {
			activeResearches {
				...ResearchBasicInfoParts
				updatedAt
			}

			finishedResearches {
				...ResearchBasicInfoParts
				updatedAt
			}
		}
	}

	${RESEARCH_BASIC_INFO_FRAGMENT}
`

/**
 * 연구 기타 정보 목록
 */
export const GET_OTHER_RESEARCHES = gql`
	query getOtherResearches($perPage: Int!, $page: Int!) {
		otherResearches(perPage: $perPage, page: $page) {
			totalCount
			perPage
			page
			items {
				...ResearchBasicInfoParts
			}
		}
	}

	${RESEARCH_BASIC_INFO_FRAGMENT}
`

/**
 * 현재 사용자의 활성화된 연구 목록 가져오기
 */
export const GET_USER_ACTIVE_RESEARCHES = gql`
	query {
		userResearches {
			activeResearches {
				...ResearchBasicInfoParts
			}
		}
	}

	${RESEARCH_BASIC_INFO_FRAGMENT}
`

/**
 * 현재 사용자의 활성화된 연구 목록 가져오기(기본정보만)
 */
export const GET_USER_ACTIVE_RESEARCHES_FOR_LIST = gql`
	{
		userResearches {
			activeResearches {
				...ResearchSimpleInfoParts
			}
		}
	}

	${RESEARCH_SIMPLE_INFO_FRAGMENT}
`

/**
 * 현재 사용자의 활성화된 연구 목록 가져오기(완전 기본정보만)
 */
export const GET_USER_ACTIVE_RESEARCHES_SIMPLE = gql`
	{
		userResearches {
			activeResearches {
				id
				title
				type
				templateType
				patientBasicInfo
			}
		}
	}
`

/**
 * 연구 기본 정보 가져오기
 */
export const GET_RESEARCH_BASIC_INFO = gql`
	query getResearchBasicInfo($id: Int!) {
		researchDetail(id: $id) {
			...ResearchBasicParts
			patientCount
			doctorCount
			recordCount
			usePassword
		}

		userResearcherInfo(researchId: $id) {
			...ResearcherInfoParts
		}
	}

	${RESEARCHER_INFO_FRAGMENT}
	${RESEARCH_BASIC_FRAGMENT}
`

/**
 * 연구 개요 내용
 */
export const GET_RESEARCH_OVERVIEW = gql`
	query getResearchOverview($id: Int!) {
		researchDetail(id: $id) {
			...ResearchBasicParts
			overview
			estSubjects
			director {
				id
				profileImage
				name
				institution {
					name
					id
				}
			}
		}
	}

	${RESEARCH_BASIC_FRAGMENT}
`

/**
 * 연구자 정보 보기
 */
export const GET_RESEARCH_INFO = gql`
	query getResearchSettingsInfo($id: Int!) {
		researchDetail(id: $id) {
			...ResearchBasicParts
			scope
			# registrationCode
			# screeningCode
			templateType
			hasScreening
			# isOfficial
			projectId
			overview
			description
			director {
				id
				name
			}
		}
	}

	${RESEARCH_BASIC_FRAGMENT}
`

/**
 * 연구 참여 멤버 목록 불러오기
 */
export const GET_RESEARCH_MEMBERS = gql`
	query getResearchMembers($id: Int!) {
		researchDetail(id: $id) {
			...ResearchBasicParts
			researchers {
				...ResearcherParts
			}
		}
		invitedResearchers(researchId: $id) {
			id
			research {
				id
			}
			user {
				id
				name
				profileImage
				institution {
					id
					name
				}
			}
			email
			role
			createdAt
		}
	}
	${RESEARCHER_FRAGMENT}
	${RESEARCH_BASIC_FRAGMENT}
`

/**
 * 연구 통계 정보 불러오기(기본)
 */
export const GET_RESEARCH_STATS = gql`
	query getResearchStats($researchId: Int!, $requests: [StatRequest]) {
		researchStatistics(researchId: $researchId, requests: $requests) {
			type
			... on RatioData {
				ratios {
					value
					percentage
					name
				}
			}
			... on SingleValue {
				value {
					... on FloatValue {
						floatValue
					}
				}
			}
		}
	}
`

/**
 * 연구 내의 section type 목록(?)
 */
export const GET_RESEARCH_SECTIONS = gql`
	query getResearchSections($researchId: Int!) {
		MedInfoType {
			...MedInfoTypeParts
		}
	}
	${MED_INFO_TYPE_FRAGMENT}
`

/**
 * 연구 내 이슈(게시판) 글 목록 보기
 */
export const GET_RESEARCH_ISSUES = gql`
	query getResearchIssues($id: Int!, $pagination: IssuePagination!) {
		issues(objectId: $id, objectType: RESEARCH, pagination: $pagination) {
			totalCount
			page
			perPage
			items {
				title
				id
				text
				updatedAt
				isClosed
				writer {
					name
					profileImage
				}
				comments {
					totalCount
				}
				type {
					type
					name
				}
			}
		}
	}
`

/**
 * 연구 일정 목록(날짜 범위별로)
 */
export const GET_RESEARCH_SCHEDULES = gql`
	query getResearchSchedules(
		$researchId: Int
		$year: Int
		$month: Int
		$day: Int
		$startDate: String
		$endDate: String
	) {
		researchSchedules(
			researchId: $researchId
			year: $year
			month: $month
			day: $day
			startDate: $startDate
			endDate: $endDate
		) {
			patient {
				id
				name
				summary
				code
			}
			phase {
				title
				description
				days
				daysRange
				title
				shouldReserve
			}
			status
			reservedDate
		}
	}
`

/**
 * 연구 이슈 분류 목록
 */
export const GET_ISSUES_TYPES = gql`
	query getIssueTypes($researchId: Int!) {
		issueTypes(researchId: $researchId) {
			id
			type
			name
		}
	}
`

/**
 * 연구 참여 의사 목록
 */
export const GET_RESEARCH_DOCTORS = gql`
	query getResearchDoctors($researchId: Int!, $pagination: Pagination, $filter: ListFilter) {
		researchDoctors(researchId: $researchId, pagination: $pagination, filter: $filter) {
			totalCount
			page
			perPage
			items {
				user {
					name
					id
					institution {
						id
						name
					}
					position
				}
				researcherInfo {
					patientCount
					createdAt
				}
			}
		}
	}
`

/**
 * 연구 참여 의사 목록
 */
export const GET_RESEARCH_TEMPLATES = gql`
	query getResearchTemplates($researchId: Int!) {
		researchDetail(id: $researchId) {
			id
			recordTemplates {
				...TemplateBasicInfo
			}
		}
	}

	${TEMPLATE_BASIC_INFO}
`

/**
 * 연구 내 MedInfoQuery 목록
 */
export const GET_RESEARCH_QUERIES = gql`
	query getResearchQueries($researchId: Int!, $pagination: Pagination, $filter: ListFilter) {
		researchMedInfoQueries(researchId: $researchId, pagination: $pagination, filter: $filter) {
			totalCount
			perPage
			page
			items {
				id
				writer {
					id
					name
				}
				record {
					patient {
						patientResearches {
							code
						}
					}
					template {
						title
					}
					creator {
						id
						name
					}
					code
				}
				medInfo {
					id
					medInfoEntityId
					medInfoType {
						name
					}
				}
				message
				status
				createdAt
			}
		}
	}
`

/**
 * 프로젝트 템플릿 목록 불러오기
 */
export const GET_RESEARCH_OPITON_COMBINATION_LIST = gql`
	query getResearchOptionCombinations {
		researchOptionCombinations(pagination: { page: 1, perPage: 20 }) {
			items {
				id
				name
				description
				type
				image
				hasScreening
				templateType
				patientBasicInfo
				requiresAgreement
				agreementCheckType
				isOfficial
				hideName
				hashPatient
				hasPartialTemplate
				isTraditional
				cohortType
				caseType
				samplingPlan
				purpose
				institutionInvolve
			}
		}
	}
`

/**
 * 리서치에 사용된 MedInfoTypes
 */
export const GET_RESEARCH_MED_INFO_TYPES = gql`
	query getResearchMedInfoTypes($researchId: Int!) {
		researchMedInfoTypes(researchId: $researchId) {
			...MedInfoTypeParts
			createdAt
		}
	}
	${MED_INFO_TYPE_FRAGMENT}
`

export const GET_RESEARCH_TERM = gql`
	query getResearchTerm($researchId: Int!) {
		researchTerm(researchId: $researchId) {
			id
			text
			type
			version
			researchId
			isDefault
			createdAt
			patientBasicInfo
		}
	}
`
