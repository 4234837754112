import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from 'semantic-ui-react'

import TemplateOptions from '../../template/TemplateOptions'
import { PAGE_CONTENT_SMALL_WIDTH } from '../../../constants/styles'

const styles = {
	containerWrap: {
		width: PAGE_CONTENT_SMALL_WIDTH,
		margin: 'auto',
		textAlign: 'left',
	},
}

export default class ResearchTemplateSettings extends PureComponent {
	static propTypes = {
		title: PropTypes.string,
		description: PropTypes.string,
		days: PropTypes.number,
		daysRange: PropTypes.number,
	}

	constructor(props) {
		super(props)

		this.state = {
			title: props.title,
			description: props.description,
			days: props.days,
			daysRange: props.daysRange,
			optionsLoading: false,
		}
	}

	handleChangeOptions = key => (e, { value }) => {
		this.setState({ [key]: value })
	}

	handleUpdateOptions = async () => {
		const { title, description, days, daysRange } = this.state
		const { onUpdateOptions, onUpdate, onShowHeaderMessage, id } = this.props
		this.setState({
			optionsLoading: true,
		})

		const validated = { title, description, templateId: id }

		try {
			validated.days = parseInt(days)
			validated.daysRange = parseInt(daysRange)
		} catch (e) {
			return alert('<진행시기>, <허용범위> 값은 숫자(0과 자연수)만 입력하실 수 있습니다.')
		}

		try {
			await onUpdateOptions({
				variables: validated,
			})
			this.setState({
				optionsLoading: false,
			})
			onUpdate()
			onShowHeaderMessage('양식 수정 저장됨', '변경이 완료되었습니다.', 'green')
		} catch (e) {
			this.setState({
				optionsLoading: false,
			})

			alert('오류가 발생했습니다.')
		}
	}

	render() {
		const { optionsLoading } = this.state
		return (
			<div style={styles.containerWrap}>
				<TemplateOptions
					title={this.state.title}
					description={this.state.description}
					days={this.state.days}
					daysRange={this.state.daysRange}
					onChange={this.handleChangeOptions}
				/>
				<div style={{ marginTop: 30, display: 'flex' }}>
					<div style={{ textAlign: 'left', flex: 1 }}>
						<Button size='big' color="red">
							<Icon name="trash" />
							양식 삭제(비활성화)
						</Button>
					</div>
					<div style={{ textAlign: 'right', flex: 1 }}>
						<Button size='big' loading={optionsLoading} primary onClick={this.handleUpdateOptions}>
							변경 사항 저장
						</Button>
					</div>
				</div>
			</div>
		)
	}
}
