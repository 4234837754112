import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from 'react-apollo'
import { Placeholder, PlaceholderLine, Segment, TextArea, Form, Button } from 'semantic-ui-react'

import { GET_ISSUE } from '../../../graphql/queries/issue'
import PageContainer from '../../common/container/PageContainer'
import { PAGE_CONTENT_SMALL_WIDTH } from '../../../constants/styles'
import { toLocalizedDateString } from '../../../libs/dateHelper'
import UserInline from '../../common/UserInline'
import EllipsisDropdown from '../../common/EllipsisDropdown'
import { WRITE_COMMENT_NAME } from '../../../graphql/mutations/community'
import { CommentEntityType } from '../../../enum'
import ErrorView from '../../common/error/ErrorView'
import { newLineBreak } from '../../../libs/stringHelper'

const ResearchIssue = ({ breadCrumbs, userInfo }) => {
	const { subId } = useParams()
	const issueId = parseInt(subId)

	const { data: { issueDetail } = {}, loading, error } =
		useQuery(GET_ISSUE, {
			variables: {
				issueId,
			},
		}) || {}
	const { comments, title, createdAt, writer, text } = issueDetail || {}
	const { items: commentItems, totalCount: commentCount } = comments || {}

	const isUserWriter = useMemo(() => {
		if (writer != null) {
			return userInfo.id === writer.id
		}
		return false
	}, [userInfo, writer])
	if (error != null) {
		return <ErrorView error={error} />
	}
	return (
		<PageContainer
			fixed
			width={PAGE_CONTENT_SMALL_WIDTH}
			breadCrumbs={[
				...breadCrumbs,
				{
					active: true,
					link: true,
					content: '게시판',
					key: 'PG_BC_PR_IS',
				},
			]}
		>
			{loading === true ? (
				<Placeholder fluid>
					<PlaceholderLine />
				</Placeholder>
			) : (
				<h5>{title}</h5>
			)}
			<SubInfo>
				<UserInline {...writer} loading={loading} />
				<DateBox>{toLocalizedDateString(createdAt)} 등록</DateBox>
				{isUserWriter === true && (
					<EllipsisDropdown
						options={[
							{
								key: 'EDIT',
								text: '수정',
								onClick: () => {},
							},
							{
								key: 'DELETE',
								text: '삭제',
								onClick: () => {},
							},
						]}
					/>
				)}
			</SubInfo>
			<Content>{newLineBreak(text)}</Content>
			<Replies>
				<h6>
					답글 <Count>{commentCount}</Count>
				</h6>
				{commentItems != null &&
					commentItems.map(comment => (
						<Segment key={`comment-${comment.id}`}>
							<IssueComment {...comment} userInfo={userInfo} />
						</Segment>
					))}
			</Replies>
			<WriteComment issueId={issueId} userInfo={userInfo} />
		</PageContainer>
	)
}

ResearchIssue.propTypes = {
	breadCrumbs: PropTypes.array,
	userInfo: PropTypes.object,
}

const IssueComment = ({ id, text, writer, createdAt, userInfo }) => {
	const isUserWriter = userInfo.id === writer.id
	return (
		<CommentContainer>
			<SubInfo>
				<UserInline {...writer} />
				<DateBox>{toLocalizedDateString(createdAt)} 등록</DateBox>
				<CommentMenuContainer>
					<EllipsisDropdown
						isHorizontal={false}
						options={[
							...(isUserWriter === true
								? [
										{
											key: 'EDIT',
											text: '수정',
											onClick: () => {},
										},
										{
											key: 'DELETE',
											text: '삭제',
											onClick: () => {},
										},
								  ]
								: []),
							{
								key: 'REPLY',
								text: '답글',
								onClick: () => {},
							},
						]}
					/>
				</CommentMenuContainer>
			</SubInfo>
			<Content>{newLineBreak(text)}</Content>
		</CommentContainer>
	)
}

IssueComment.propTypes = {
	id: PropTypes.number,
	text: PropTypes.string,
	writer: PropTypes.object,
	createdAt: PropTypes.string,
	userInfo: PropTypes.object,
}

const WriteComment = ({ issueId, userInfo }) => {
	const [text, setText] = useState('')
	const [onWrite] = useMutation(WRITE_COMMENT_NAME, {
		variables: {
			commentInput: {
				text,
				entityType: CommentEntityType.ISSUE.value,
				entityId: issueId,
			},
		},
		refetchQueries: [
			{
				query: GET_ISSUE,
				variables: {
					issueId,
				},
			},
		],
	})
	const handleWrite = () =>
		onWrite().then(() => {
			setText('')
		})

	return (
		<Segment>
			<WriteContainer>
				<SubInfo>
					<UserInline {...userInfo} size={30} fontSize={16} />
					<WriteTitle>&nbsp;님, 새로운 의견을 남겨주세요!</WriteTitle>
				</SubInfo>
				<Form>
					<Form.Field
						control={TextArea}
						onChange={(e, { value }) => setText(value)}
						value={text}
						placeholder="주제에 대한 의견을 남겨보세요."
					/>
				</Form>
				<ButtonContainer>
					<Button primary size="large" onClick={handleWrite}>
						등록
					</Button>
				</ButtonContainer>
			</WriteContainer>
		</Segment>
	)
}

WriteComment.propTypes = {
	issueId: PropTypes.number,
	userInfo: PropTypes.object,
}

/**
 * Styled components
 */
const CommentContainer = styled.div`
	padding: 10px 20px;
`
const CommentMenuContainer = styled.div`
	float: right;
`

const SubInfo = styled.div`
	margin-bottom: 20px;
	margin-top: 10px;
	font-size: 14px;
`

const DateBox = styled.div`
	display: inline-block;
	margin-left: 30px;
	color: ${props => props.theme.COLOR_TEXT_LIGHTGRAY};
	font-weight: 300;
	font-size: 14px;
`

const Content = styled.div`
	padding-top: 40px;
	border-top: 1px solid ${props => props.theme.COLOR_BORDER_GRAY};
`

const Replies = styled.div`
	margin-top: 50px;
`

const Count = styled.h6`
	color: ${props => props.theme.COLOR_PRIMARY};
	display: inline-block;
`

const WriteTitle = styled.div`
	font-size: 16px;
	font-weight: 300;
	margin-bottom: 5px;
	display: inline-block;
	margin-right: 20px;
`

const WriteContainer = styled.div`
	padding: 10px 20px;
`

const ButtonContainer = styled.div`
	padding-top: 10px;
	text-align: right;
`

export default ResearchIssue
