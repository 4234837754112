import moment from 'moment'
import { ConditionScope, InputType, ConditionAction } from '../enum'
import { updateChildBelow, updateChild, compareValues } from './medInfoHelper'

export const getConditions = medInfos => {
	return medInfos.reduce((conditions, medInfo) => {
		return conditions.concat(medInfo.medInfoType.conditions)
	}, [])
}

export const updateMedInfoArraySingle = async (medInfos, targetId, actionCb) => {
	for (const i in medInfos) {
		if (medInfos[i].medInfoType.id === targetId) {
			medInfos[i] = await actionCb(medInfos[i])
		}
	}
}

export const updateMedInfoArrayBelowIndex = async (medInfos, targetId, actionCb) => {
	const index = medInfos.findIndex(i => i.medInfoType.id === targetId)
	for (const i in medInfos) {
		if (i > index) {
			medInfos[i] = await actionCb(medInfos[i])
		}
	}
}

export const updateMedInfoArrayBelowParentIndex = async (medInfos, targetId, actionCb) => {
	const index = medInfos.findIndex(i => i.medInfoType.id === targetId)
	const md = medInfos[index]
	const nestedParents = [md.parentId]
	for (const i in medInfos) {
		if (index === i) {
			continue
		}

		if (nestedParents.indexOf(medInfos[i].parentId) >= 0) {
			if (InputType[medInfos[i].medInfoType.inputType].hasChild === true) {
				nestedParents.push(medInfos[i].id)
			}

			if (i > index) {
				medInfos[i] = await actionCb(medInfos[i])
			}
		}
	}
}

export const conditionHandlerArray = async medInfos => {
	for (const i of medInfos) {
		const conds = i.medInfoType.conditions
		if (conds != null) {
			for (const condition of conds) {
				const isValid = compareValues(i.value, condition.value, condition.operator, i.checkedItems)
				switch (condition.action) {
					case ConditionAction.ENABLE.key: {
						const actionCb = ConditionAction.ENABLE.action(condition, isValid)
						await updateMedInfoArraySingle(medInfos, condition.targetId, actionCb)
					}
				}
			}
		}
	}
}

// 한 MedInfo 이하의 모든 MedInfo에 적용(immutable)
export const updateMedInfosBelowIndex = async (medInfos, targetId, actionCb) => {
	const res = await updateChildBelow(medInfos, targetId, actionCb)

	return res || medInfos
}

// 한 MedInfo 이하의 모든 MedInfo에 적용(not immutable)
export const updateMedInfosBelowIndexIter = async (medInfos, targetId, actionCb) => {
	await updateChildBelow(medInfos, targetId, actionCb)
	return medInfos
}

// MedInfo 한개를 type으로 찾아서 업데이트(immutable 하지 않을 때)
export const updateSingleMedInfoByTypeIter = async (medInfos, id, actionCb) => {
	await updateChild(medInfos, info => info.medInfoType.id === id, actionCb)
	return medInfos
}

// MedInfo 한개를 Type으로 찾아서 업데이트함(immutable할 경우)
export const updateSingleMedInfoByType = async (medInfos, id, actionCb) => {
	const res = await updateChild(medInfos, info => info.medInfoType.id === id, actionCb)

	return res || medInfos
}

// 부모노드에서 index 이하의 child에 action 함수를 적용(not immutable)
export const updateMedInfosBelowIndexParentIter = async (
	medInfos,
	targetId,
	childIndex,
	actionCb
) => {
	await updateChild(
		medInfos,
		info => info.medInfoType.id === targetId,
		async info => {
			const children = []

			for (const index in info.children) {
				let child = info.children[index]

				if (index > childIndex) {
					child = await actionCb(child)
				}

				children.push(child)
			}

			info.children = children
			return info
		}
	)

	return medInfos
}

// 부모노드에서 index 이하의 child에 action 함수를 적용(immutable)
export const updateMedInfosBelowIndexParent = async (medInfos, targetId, childIndex, actionCb) => {
	const res = await updateChild(
		medInfos,
		info => info.medInfoType.id === targetId,
		async info => {
			const children = []
			for (const index in info.children) {
				let child = info.children[index]

				if (index > childIndex) {
					child = await actionCb(child)
				}

				children.push(child)
			}

			return {
				...info,
				children,
			}
		}
	)

	return res || medInfos
}

// Condition 적용 (immutable하지 않을 경우 loop로 값 치환 처리)
export const handleConditionLoop = async (
	medInfo,
	pIdx,
	medInfos,
	conditionRefMedInfos,
	recordInfo,
	client,
	onCreateMedInfo
) => {
	if (medInfo.children) {
		for (const index in medInfo.children) {
			const child = medInfo.children[index]

			if (child.medInfoType.conditions) {
				let refCons = []
				if (
					child.medInfoType.targetConditions != null &&
					child.medInfoType.targetConditions.length > 0
				) {
					refCons = child.medInfoType.targetConditions.filter(
						c => c.scope === ConditionScope.RESEARCH_RECORDS.key
					)
				}

				await handleConditions(
					medInfos,
					[...child.medInfoType.conditions, ...refCons],
					{
						value: child.value,
						checkedItems: child.checkedItems,
						entityId: child.entityId,
						childIndex: index,
						parentIndex: pIdx,
						iterate: true,
					},
					conditionRefMedInfos,
					recordInfo,
					client,
					onCreateMedInfo
				)
			}

			if (child.children && child.children.length > 0) {
				await handleConditionLoop(
					child,
					index,
					medInfos,
					conditionRefMedInfos,
					recordInfo,
					client,
					onCreateMedInfo
				)
			}
		}
	}
}

// 의료정보 변경 시 조건식 실행
// 조건식 종류에 따라 다른 작업 수행
export const conditionHandlers = async (
	condition,
	isValid,
	medInfos,
	options,
	client,
	createMedInfo
) => {
	const { iterate } = options
	const { targetId } = condition

	switch (condition.action) {
		case ConditionAction.ENABLE.key: {
			const actionCb = ConditionAction.ENABLE.action(condition, isValid)
			if (iterate === true) {
				await updateSingleMedInfoByTypeIter(medInfos, targetId, actionCb)
				return medInfos
			}

			return updateSingleMedInfoByType(medInfos, targetId, actionCb)
		}
		case ConditionAction.WARNING.key: {
			const actionCb = ConditionAction.WARNING.action(condition, isValid)
			if (iterate === true) {
				await updateSingleMedInfoByTypeIter(medInfos, targetId, actionCb)
				return medInfos
			}

			if (isValid) alert(condition.message)
			return updateSingleMedInfoByType(medInfos, targetId, actionCb)
		}
		case ConditionAction.ENABLE_BELOW_ALL.key: {
			const actionCb = ConditionAction.ENABLE.action(condition, isValid)
			if (iterate === true) {
				await updateMedInfosBelowIndexIter(medInfos, targetId, actionCb)
				return medInfos
			}

			return updateMedInfosBelowIndex(medInfos, targetId, actionCb)
		}

		case ConditionAction.ENABLE_BELOW_PARENT.key: {
			const { childIndex } = options
			const actionCb = ConditionAction.ENABLE.action(condition, isValid)
			if (iterate) {
				await updateMedInfosBelowIndexParentIter(medInfos, targetId, childIndex, actionCb)
				return medInfos
			}

			return updateMedInfosBelowIndexParent(medInfos, targetId, childIndex, actionCb)
		}
		case ConditionAction.SET.key: {
			const actionCb = ConditionAction.SET.action(condition, options.value)
			if (iterate === true) {
				return medInfos
			}

			return updateSingleMedInfoByType(medInfos, targetId, actionCb)
		}
		case ConditionAction.ADD_ROWS.key: {
			const actionCb = ConditionAction.ADD_ROWS.action(
				condition,
				client,
				createMedInfo,
				options.entityId
			)
			if (iterate === true) {
				return medInfos
			}

			return updateSingleMedInfoByType(medInfos, targetId, actionCb)
		}
		default: {
			return medInfos
		}
	}
}

// Condition 적용(immutable한 경우)
export const handleConditions = async (
	medInfos,
	conditions,
	options,
	conditionRefMedInfos,
	recordInfo,
	client,
	onCreateMedInfo
) => {
	let medInfosUpdate = medInfos

	for (const cKey in Object.keys(conditions)) {
		const condition = conditions[cKey]

		let toCompare
		let orval = options.value

		const { [condition.value]: rValue } = recordInfo

		if (condition.scope === ConditionScope.RECORD_INFO.key) {
			if (condition.value === 'visitedDate') {
				toCompare = moment(rValue).format('YYYY-MM-DD')
			} else {
				toCompare = rValue
			}
		} else if (condition.scope === ConditionScope.RESEARCH_RECORDS.key) {
			if (conditionRefMedInfos && conditionRefMedInfos.length > 0) {
				const vinfo = conditionRefMedInfos.find(
					item => item.medInfoTypeEntityId === parseInt(condition.value, 10)
				)
				if (vinfo) toCompare = vinfo.value

				if (options.inputType === InputType.DATE.key) {
					orval = Date.parse(options.value)
					toCompare = Date.parse(toCompare)
				}
			}
		} else {
			toCompare = condition.value
		}

		const isValid = compareValues(orval, toCompare, condition.operator, options.checkedItems)

		if (isValid === true || isValid === false) {
			medInfosUpdate = await conditionHandlers(
				condition,
				isValid,
				medInfosUpdate,
				{
					...options,
				},
				client,
				onCreateMedInfo
			)
		}
	}

	return medInfosUpdate
}
