import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
	COLOR_TEXT_LIGHTGRAY,
	COLOR_BORDER_GRAY,
	COLOR_PRIMARY,
	COLOR_TEXT_DARKGRAY,
	COLOR_BACKGROUND_GRAY,
} from '../../../constants/styles'

const ThumbnailSelect = props => {
	const { options, active, style, onChange } = props

	return (
		<Container style={style}>
			{options != null &&
				options.map(option => {
					const { key, value } = option
					const isActive = active === value
					return (
						<ThumbnailSelectItem
							{...option}
							onClick={onChange}
							isActive={isActive}
							key={key}
							isNeutral={active == null}
						/>
					)
				})}
		</Container>
	)
}

ThumbnailSelect.propTypes = {}

export default ThumbnailSelect

const ThumbnailSelectItem = props => {
	const { isActive, isNeutral, onClick, value, content } = props

	return (
		<ItemContainer
			value={value}
			onClick={() => onClick(value)}
			isActive={isActive}
			isNeutral={isNeutral}
		>
			{isActive === true && <ActiveFlag />}
			{content}
		</ItemContainer>
	)
}

const ActiveFlag = styled.div`
	position: absolute;
	top: -12px;
	right: -12px;
	width: 24px;
	height: 24px;
	background: url(/images/icons/checked.svg);
`

const Container = styled.div``

const ItemContainer = styled.div`
	margin-right: 10px;
	height: 40px;
	font-size: 16px;
	line-height: 16px;
	min-width: 196px;
	padding: 10px 50px;
	cursor: pointer;
	position: relative;
	display: inline-block;
	text-align: center;
	color: ${props =>
		props.isActive === true
			? COLOR_PRIMARY
			: props.isNeutral === true
			? COLOR_TEXT_DARKGRAY
			: COLOR_TEXT_LIGHTGRAY};
	border: 1px solid ${props => (props.isActive === true ? COLOR_PRIMARY : COLOR_BORDER_GRAY)};

	:hover {
		background: ${COLOR_BACKGROUND_GRAY};
		color: ${COLOR_PRIMARY};
		border: 1px solid ${COLOR_PRIMARY};
	}
`
