import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useQuery } from 'react-apollo'
import { useHistory, Link, useLocation } from 'react-router-dom'
import { Icon, Loader } from 'semantic-ui-react'
import {
	PAGE_NOT_MOBILE_MIN_WIDTH,
	PAGE_MOBILE_MAX_WIDTH,
	COLOR_BACKGROUND_GRAY,
	COLOR_BACKGROUND_DARKER_GRAY,
	COLOR_TEXT,
} from '../../constants/styles'
import { MobileOnly } from '../common/basic/ResponsiveWrappers'
import InputSearch from '../common/basic/InputSearch'
import { GET_DONGUIBOGAM_TITLE_LIST } from '../../graphql/queries/library'
import LineItem from './LineItem'
import MyIcon from '../common/basic/MyIcon'
import {
	ROUTE_CASES,
	ROUTE_CASE_BOOKS,
	ROUTE_CASE_GROUP,
	ROUTE_CASE_SEARCH,
	ROUTE_CASE_SEARCH_TYPE,
	ROUTE_CASE_USER,
} from '../../constants/routes'
import { isMatchingExactRoutes, isMatchingRoutes, replaceParams } from '../../libs/routeHelper'
import useUserInfo from '../../hooks/useUserInfo'
import { GET_MY_CASE_GROUPS } from '../../graphql/queries/case'

const CaseSideMenu = ({ isMobilePopperOpen, toggleMobilePopper }) => {
	const userInfo = useUserInfo()
	const history = useHistory()
	const { user: currentUser } = userInfo || {}

	const { data: { donguibogamTitleList } = {}, loading: isLoadingLines } = useQuery(
		GET_DONGUIBOGAM_TITLE_LIST
	)
	const { data: { myCaseGroups } = {} } = useQuery(GET_MY_CASE_GROUPS)
	const { pathname } = useLocation()

	const shouldShowLines = !!(isLoadingLines === false && donguibogamTitleList != null)
	const [search, setSearch] = useState('')
	return (
		<div>
			<MobileSidebar isOpen={isMobilePopperOpen}>
				<MobileOnly>
					<div style={{ padding: 10 }}>
						<MyIcon onClick={toggleMobilePopper} name="close" />
					</div>
				</MobileOnly>
				<TopView>
					<div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
						<Link
							to={ROUTE_CASES}
							style={{ flex: 1, display: 'block', fontSize: 30, marginBottom: 20 }}
						>
							<span role="img" aria-label="hah">
								&#127794;
							</span>{' '}
							<span style={{ fontSize: 20, fontWeight: 300, letterSpacing: 5 }}>Forest.</span>
						</Link>
						<Link
							target="_blank"
							style={{ width: 70, height: 40, textAlign: 'right', paddingTop: 2 }}
							to={{
								pathname: 'https://tesser.notion.site/Forest-011dc7df584743d989bf074c42bab02b',
							}}
						>
							<Icon size="large" name="info circle" />
						</Link>
					</div>

					<InputSearch
						placeholder="케이스 검색"
						fluid
						value={search}
						onKeyDown={event => {
							if (event.keyCode === 13) {
								history.push(
									`${replaceParams(ROUTE_CASE_SEARCH_TYPE, {
										searchType: 'case',
									})}?q=${search}`
								)
							}
						}}
						onChange={(_, { value }) => {
							setSearch(value)
						}}
					/>
				</TopView>
				<div style={{ flex: 1, overflowY: 'auto' }}>
					<SideMenu>
						<SideMenuItem to={ROUTE_CASES} active={isMatchingExactRoutes(pathname, [ROUTE_CASES])}>
							<Icon name="home" size="large" /> 피드
						</SideMenuItem>
					</SideMenu>
					<SideMenu>
						<SideMenuItem
							to={replaceParams(ROUTE_CASE_USER, { userId: currentUser.id })}
							active={isMatchingRoutes(pathname, [ROUTE_CASE_USER])}
						>
							<Icon name="user circle" size="large" /> 내 케이스
						</SideMenuItem>
					</SideMenu>
					<SideMenu>
						{myCaseGroups && (
							<>
								{myCaseGroups.map(caseGroup => {
									return (
										<SideMenuItem
											key={`case_menu_${caseGroup.id}`}
											to={replaceParams(ROUTE_CASE_GROUP, { groupId: caseGroup.id })}
											active={isMatchingRoutes(pathname, [
												replaceParams(ROUTE_CASE_GROUP, { groupId: caseGroup.id }),
											])}
										>
											<Icon name="users circle" size="large" /> {caseGroup.title}
										</SideMenuItem>
									)
								})}
							</>
						)}
					</SideMenu>
					<SideMenu>
						<SideMenuItem>
							<Icon name="book" size="large" /> 동의보감
						</SideMenuItem>
						<SidebarWrapper>
							<MobileSidebarContent>
								{/* <div style={{ fontSize: 13, marginBottom: 12 }}>동의보감 목차</div> */}
								{isLoadingLines === true && <Loader />}
								{shouldShowLines === true &&
									donguibogamTitleList.map(item => <LineItem key={item.key} {...item} />)}
							</MobileSidebarContent>
						</SidebarWrapper>
					</SideMenu>
				</div>
			</MobileSidebar>
		</div>
	)
}

CaseSideMenu.propTypes = {
	isMobilePopperOpen: PropTypes.bool.isRequired,
	toggleMobilePopper: PropTypes.func.isRequired,
}

export default CaseSideMenu

const MobileSidebar = styled.div`
	display: none;
	background: ${COLOR_BACKGROUND_GRAY};
	-webkit-transition: all 0.1s ease;
	-moz-transition: all 0.1s ease;
	-ms-transition: all 0.1s ease;
	-o-transition: all 0.1s ease;
	transition: all 0.1s ease;
	flex-shrink: 0;
	z-index: 35;

	/** Not Mobile */
	@media (min-width: ${PAGE_NOT_MOBILE_MIN_WIDTH}px) {
		display: flex;
		flex-direction: column;
		left: 0px;
		top: 0px;
		position: fixed;
		height: 100vh;
		width: 320px;
	}

	/** Mobile */
	@media (max-width: ${PAGE_MOBILE_MAX_WIDTH}px) {
		display: block;
		top: ${props => (props.isOpen === true ? '0px' : '100%')};
		left: 0px;
		position: fixed;
		height: 100%;
		width: 100%;
	}
`

const TopView = styled.div`
	padding: 20px;
`

const SidebarWrapper = styled.div`
	/* margin-right: 16px; */
	margin-top: 10px;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: row;
	overflow: hidden;
	padding-left: 25px;
	padding-bottom: 60px;
`

const MobileSidebarContent = styled.div`
	width: 100%;
	overflow-y: auto;
`

const SideMenu = styled.div`
	/* margin-top: 10px; */
	border-bottom: 1px solid #ddd;
`
const SideMenuItem = styled(Link)`
	display: block;
	padding: 10px 20px;
	border-radius: 15px;
	margin: 10px 10px;
	cursor: pointer;
	font-size: 15px;
	:hover {
		color: ${COLOR_TEXT};
		background: ${COLOR_BACKGROUND_DARKER_GRAY};
	}

	${props =>
		props.active === true && {
			background: COLOR_BACKGROUND_DARKER_GRAY,
		}}

	i.icon {
		padding-right: 35px;
	}
`
