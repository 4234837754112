/**
 * 차트상에서 MedInfo를 나타내는 개별 필드
 *
 * 체크박스, 텍스트박스, 드롭다운 등 다양한 종류의 MedInfo를
 * 입력할 수 있는 인풋 필드를 제공함.
 *
 * Input 은 별도의 Component로 분리됨(DataFieldInput.js)
 */

import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Form, Button, Checkbox, Icon, Label } from 'semantic-ui-react/dist/commonjs'

import ReactSVG from 'react-svg'
import { MED_INFO_TYPE_FORMATS } from '../../../constants'

import InfoIcon from '../../../assets/images/icons/info.svg'
import { COLOR_TEXT_LIGHTGRAY } from '../../../constants/styles'
import { InputLabel } from './DataFieldLabel'
import DataFieldDiv from './DataFieldDiv'
import DataFieldInput from './inputs/DataFieldInput'
import DataFieldSelect from './inputs/DataFieldSelect'
import DataFieldEditDropdown from '../control/DataFieldEditDropdown'
import { InputType } from '../../../enum'
import VasSlider from './inputs/VasSlider'
import { ReadOnlyOptionValue } from './inputs/Styled'

const DataField = React.memo(
	({
		templateMode,
		defaultDisabled,
		disabled: propDisabled,
		onUpdateMedInfo,
		medInfoIndex,
		checkedItems,
		canWriteQuery,
		value,
		optionValue,
		options,
		readOnly,
		onOpenQueryModal,
		onOpenWriteQueryModal,
		medInfoId,
		name,
		isAdded,
		isRemoved,
		medInfoEntityId,
		gridCell,
		gridCellIndi,
		isFixed,
		medInfoQueryCount,
		unresolvedMedInfoQueryCount,
		description,
		entityType,
		selectOptions,
		inputType,
		placeholder,
		format,
		unit,
		defaultValue,
		onClickSettingMenu,
		hasVAS,
		vasValue,
		vasMax,
		onResize,
	}) => {
		const values = {
			value,
			optionValue,
			checkedItems,
		}

		// 비활성화 여부 확인
		const optionDisabled = useMemo(() => {
			const curOption =
				options != null
					? options.find(
							opt => optionValue != null && optionValue.split('|').indexOf(opt.value) >= 0
					  )
					: null
			return (
				curOption &&
				curOption.format &&
				curOption.format.includes(MED_INFO_TYPE_FORMATS.disablesInput)
			)
		}, [options, optionValue])

		const disabled = useMemo(() => {
			if (templateMode === true) {
				return false
			} else {
				if (propDisabled == null && optionDisabled == null) {
					return defaultDisabled
				}

				return propDisabled || optionDisabled
			}
		}, [propDisabled, templateMode, defaultDisabled, optionDisabled])

		// Input 값을 withCharts HOC의 중앙 state로 업데이트 (한개 값)
		const handleUpdateValue = useCallback(
			(key, value) => {
				if (values[key] !== value) {
					onUpdateMedInfo({ [key]: value }, medInfoIndex)
				}
			},
			[medInfoIndex, onUpdateMedInfo, values]
		)

		// Input 값을 withCharts HOC의 중앙 state로 업데이트 (여러개의 값, value vasValue 여러개 같이)
		const handleUpdateValues = useCallback(
			items => {
				const data = {}

				for (const item of items) {
					const { key, value } = item
					data[key] = value
				}

				onUpdateMedInfo(data, medInfoIndex)
			},
			[medInfoIndex, onUpdateMedInfo]
		)

		const handleUpdateOptionCheckbox = useCallback(
			option => (event, { checked }) => {
				const sp = optionValue != null ? optionValue.split('|') : []

				let toOptionVal
				if (checked === true) toOptionVal = sp.concat(option.value)
				else toOptionVal = sp.filter(item => item !== option.value)

				const disableOpts =
					option.format && option.format.includes(MED_INFO_TYPE_FORMATS.disablesInput)

				let optionValueString = toOptionVal.join('|')

				if (disableOpts) {
					handleUpdateValues([
						{
							key: 'optionValue',
							value: optionValueString,
						},
						{
							key: 'value',
							value: null,
						},
					])
				} else handleUpdateValue('optionValue', optionValueString)
			},
			[handleUpdateValue, handleUpdateValues, optionValue]
		)

		// 쿼리 모달 열기
		const handleOpenQueryModal = useCallback(() => {
			onOpenQueryModal({
				medInfoTypeName: name,
				medInfoId,
			})
		}, [medInfoId, name, onOpenQueryModal])

		// 쿼리 작성 모달 열기
		const handleOpenWriteQueryModal = useCallback(() => {
			onOpenWriteQueryModal({
				medInfoTypeName: name,
				medInfoId,
			})
		}, [medInfoId, name, onOpenWriteQueryModal])

		const hasEditMenu =
			(isFixed === false || templateMode === true) && (gridCell !== true || gridCellIndi === true)
		// option 값 설정
		const isOptionCheckbox = useMemo(() => {
			return format && format.includes(MED_INFO_TYPE_FORMATS.optionIsCheckbox)
		}, [format])
		const shouldShowOptions = (options && options.length > 1 && !readOnly) || isOptionCheckbox
		const shouldShowReadOnlyOption = readOnly && !disabled

		const selectedOption = useMemo(() => {
			if (options != null) {
				return options.find(opt => opt.value === optionValue)
			}
		}, [optionValue, options])

		const isVasInput = inputType === InputType.VAS_ONLY.value

		return (
			<div id={'md-' + medInfoEntityId}>
				<DataFieldDiv
					disabled={disabled}
					medInfoId={medInfoId}
					className={`df-container 
          ${isAdded === true ? 'df-container-change df-container-added' : ''} 
          ${isRemoved === true ? 'df-container-change df-container-removed' : ''}
					${gridCell === true ? 'df-grid-cell' : ''}
					${gridCellIndi === true ? 'df-grid-cell-indi' : ''}
				`}
					noHover={(gridCell === true && gridCellIndi !== true) || disabled}
				>
					{gridCell !== true && isAdded === true && <AddedSign />}
					{gridCell !== true && isRemoved === true && <RemovedSign />}
					<div
						className={isFixed || templateMode ? 'df-wrap' : 'df-wrap-inline'}
						style={styles.wrap}
					>
						{!gridCell && (
							<TitleBox>
								<InputLabel disabled={disabled}>{name}</InputLabel>
								{medInfoQueryCount > 0 && (
									<MedInfoQueryButton
										onClick={handleOpenQueryModal}
										unresolvedMedInfoQueryCount={unresolvedMedInfoQueryCount}
										medInfoQueryCount={medInfoQueryCount}
									/>
								)}
								{/* {medInfoQueryCount === 0 && canWriteQuery === true && (
									<WriteQueryButton onClick={handleOpenWriteQueryModal} />
								)} */}
							</TitleBox>
						)}
						{description && (
							<Description>
								<ReactSVG style={styles.infoIcon} src={InfoIcon} />
								<DescriptionText>{description}</DescriptionText>
							</Description>
						)}
						<InputBox isGridCell={gridCell}>
							{
								<DataFieldInput
									checkedItems={checkedItems}
									entityType={entityType}
									selectOptions={selectOptions}
									inputType={inputType}
									placeholder={placeholder}
									format={format}
									options={options}
									value={value}
									optionValue={optionValue}
									readOnly={readOnly}
									unit={unit}
									gridCell={gridCell}
									medInfoId={medInfoId}
									disabled={disabled}
									onUpdate={handleUpdateValue}
									onUpdateMany={handleUpdateValues}
									defaultValue={defaultValue}
									templateMode={templateMode}
									defaultDisabled={defaultDisabled}
									medInfoEntityId={medInfoEntityId}
								/>
							}
							{shouldShowOptions && (
								<span style={styles.optionWrap}>
									{isOptionCheckbox ? (
										<Form.Group style={{ marginTop: 8, marginLeft: 8 }}>
											{options.map(option => (
												<Checkbox
													style={{ marginRight: 10 }}
													key={option.value}
													label={option.text}
													value={option.value}
													checked={optionValue && optionValue.includes(option.value) === true}
													onChange={handleUpdateOptionCheckbox(option)}
													option={option}
												/>
											))}
										</Form.Group>
									) : (
										<DataFieldSelect
											button
											valueKey="optionValue"
											className="options-dropdown"
											disabled={disabled}
											selectOptions={options}
											value={optionValue}
											onUpdate={handleUpdateValue}
										/>
									)}
								</span>
							)}
							{shouldShowReadOnlyOption && (
								<ReadOnlyOptionValue>{selectedOption && selectedOption.text}</ReadOnlyOptionValue>
							)}
						</InputBox>
					</div>
					{hasVAS && (
						<div style={styles.vasScale}>
							<VasSlider
								disabled={disabled}
								readOnly={readOnly}
								updateVasValue={async value => {
									if (isVasInput) {
										handleUpdateValues([{ key: 'value', value }, { key: 'vasValue', value }])
									} else {
										handleUpdateValue('vasValue', value)
									}
								}}
								vasValue={vasValue}
								maxValue={vasMax}
								isVasInput={isVasInput}
							/>
						</div>
					)}
					{hasEditMenu && (
						<DataFieldEditDropdown
							medInfoId={medInfoId}
							className="data-field-edit"
							onClickMenu={onClickSettingMenu}
							onResize={onResize}
							noDelete={gridCellIndi}
							noCopy={gridCellIndi}
						/>
					)}
				</DataFieldDiv>
			</div>
		)
	}
)

DataField.propTypes = {
	onClickSettingMenu: PropTypes.func,
	onResize: PropTypes.func,
	onUpdateMedInfo: PropTypes.func.isRequired,
	onOpenWriteQueryModal: PropTypes.func.isRequired,
	onOpenQueryModal: PropTypes.func.isRequired,
	medInfoIndex: PropTypes.number.isRequired,
	medInfo: PropTypes.shape({}).isRequired,
	canWriteQuery: PropTypes.bool,
	entityType: PropTypes.string,
	value: PropTypes.string,
	vasValue: PropTypes.number,
	checkedItems: PropTypes.arrayOf(PropTypes.shape({})),
	placeholder: PropTypes.string,
	unit: PropTypes.string,
	description: PropTypes.string,
	format: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.shape({})),
	name: PropTypes.string.isRequired,
	selectOptions: PropTypes.arrayOf(PropTypes.shape({})),
	gridCell: PropTypes.bool,
	defaultValue: PropTypes.string,
	optionValue: PropTypes.string,
	hasVAS: PropTypes.bool,
	templateMode: PropTypes.bool,
	vasMax: PropTypes.number,
	inputType: PropTypes.string.isRequired,
	isFixed: PropTypes.bool,
	disabled: PropTypes.bool,
	defaultDisabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	gridCellIndi: PropTypes.bool,
	unresolvedMedInfoQueryCount: PropTypes.number,
	medInfoQueryCount: PropTypes.number,
	inputRef: PropTypes.shape({
		current: PropTypes.shape(),
	}),
	isRemoved: PropTypes.bool,
	isAdded: PropTypes.bool,
	inputRefs: PropTypes.shape({}),
	medInfoId: PropTypes.number.isRequired,
	medInfoEntityId: PropTypes.number.isRequired,
}

DataField.defaultProps = {
	selectOptions: [],
	readOnly: false,
	disabled: false,
	defaultDisabled: false,
	isAdded: false,
	inputRefs: null,
	inputRef: null,
	isRemoved: false,
	medInfoQueryCount: 0,
	unresolvedMedInfoQueryCount: 0,
	gridCellIndi: null,
	isFixed: false,
	canWriteQuery: null,
	entityType: null,
	value: null,
	vasValue: null,
	checkedItems: null,

	placeholder: '',
	unit: null,
	description: null,
	format: null,
	options: [],

	gridCell: null,
	defaultValue: null,
	optionValue: null,
	hasVAS: null,
	templateMode: null,
	vasMax: null,
}

// class DataField1 extends PureComponent {
// 	static propTypes = {}

// 	static defaultProps = {}

// 	fileInput = createRef()

// 	constructor(props) {
// 		super(props)

// 		// // 현재선택된 옵션값
// 		// const curOpt =
// 		// 	props.optionValue &&
// 		// 	props.options.find(item => props.optionValue.split('|').indexOf(item.value) >= 0)
// 		// this.state = {
// 		// 	value:
// 		// 		props.value != null
// 		// 			? props.value.slice(0)
// 		// 			: props.defaultValue != null
// 		// 			? props.defaultValue
// 		// 			: '',
// 		// 	optionValue:
// 		// 		props.optionValue != null
// 		// 			? props.optionValue.slice(0)
// 		// 			: props.options && props.options.length > 0
// 		// 			? props.options[0].value
// 		// 			: '',
// 		// 	vasValue: props.vasValue != null ? props.vasValue : 0,
// 		// 	placeholder:
// 		// 		props.value != null && props.value !== '' ? props.value.slice(0) : props.placeholder,
// 		// 	disabled:
// 		// 		curOpt && curOpt.format
// 		// 			? curOpt.format.includes(MED_INFO_TYPE_FORMATS.disablesInput)
// 		// 			: false,
// 		// }
// 	}

// 	// // Input Value 값 수정
// 	// handleUpdateInputValue = (e, { value }) => {
// 	// 	this.handleUpdateValue('value', value)
// 	// }

// 	// // 텍스트 값 변경내용 상위 컴퍼넌트에 전달
// 	// handleUpdateTextValue = () => {
// 	// 	const { value } = this.state
// 	// 	const { value: propValue } = this.props

// 	// 	if (value !== propValue) {
// 	// 		this.handleUpdateValue('value', value)
// 	// 	}
// 	// }

// 	// // Datafield 자체 state 변경(빠른 변경이 필요할 경우)
// 	// handleChangeStateValue = (e, { value }) => {
// 	// 	this.setState({
// 	// 		value,
// 	// 	})
// 	// }

// 	// // 선택항목일 경우
// 	// handleChangeSelectValue = (e, { value }) => {
// 	// 	this.setState({
// 	// 		value,
// 	// 	})

// 	// 	this.handleUpdateValue('value', value)
// 	// }

// 	// // 옵션 값 텍스트 변경
// 	// handleChangeOptionTextValue = (e, { value }) => {
// 	// 	this.setState({
// 	// 		optionValue: value,
// 	// 	})
// 	// }

// 	// hanldeUpdateOptionTextValue = () => {
// 	// 	const { optionValue } = this.state
// 	// 	this.handleUpdateValue('optionValue', optionValue)
// 	// }

// 	// // 자동완성 검색 결과 선택
// 	// handleSearchSelect = (value, itemId) => {
// 	// 	this.handleUpdateValues([{ key: 'value', value }, { key: 'entityId', value: itemId }])
// 	// }

// 	// // 체크박스 체크함
// 	// handleUpdateCheckedValue = item => (e, { checked }) => {
// 	// 	const { checkedItems } = this.props

// 	// 	if (checked === false) {
// 	// 		this.handleUpdateValue(
// 	// 			'checkedItems',
// 	// 			checkedItems.filter(checked => checked.value !== item.value)
// 	// 		)
// 	// 	} else {
// 	// 		this.handleUpdateValue(
// 	// 			'checkedItems',
// 	// 			checkedItems.concat({
// 	// 				value: item.value,
// 	// 				freeWriting: '',
// 	// 			})
// 	// 		)
// 	// 	}
// 	// }

// 	// // 체크박스 체크함
// 	// handleUpdateMultiselect = (e, { checkedItems }) => {
// 	// 	this.handleUpdateValue('checkedItems', checkedItems)
// 	// }

// 	// // 옵션 값 변경
// 	// handleUpdateOptionValue = (e, { value }) => {
// 	// 	this.setState({
// 	// 		optionValue: value,
// 	// 	})
// 	// 	this.handleUpdateValue('optionValue', value)
// 	// }

// 	// // 옵션이 체크박스일 경우
// 	// handleUpdateOptionCheckbox = option => (e, { value, checked }) => {
// 	// 	const { optionValue: prevOp } = this.state
// 	// 	const sp = prevOp.split('|')
// 	// 	if (checked) optionValue = sp.concat(value)
// 	// 	else optionValue = sp.filter(item => item !== value)

// 	// 	const disableOpts = option.format && option.format.includes(MED_INFO_TYPE_FORMATS.disablesInput)

// 	// 	let optionValue = optionValue.join('|')
// 	// 	this.setState({
// 	// 		optionValue,
// 	// 		...(disableOpts && {
// 	// 			disabled: checked,
// 	// 		}),
// 	// 	})

// 	// 	if (disableOpts)
// 	// 		this.handleUpdateValues([
// 	// 			{
// 	// 				key: 'optionValue',
// 	// 				value: optionValue,
// 	// 			},
// 	// 			{
// 	// 				key: 'value',
// 	// 				value: null,
// 	// 			},
// 	// 		])
// 	// 	else this.handleUpdateValue('optionValue', optionValue)
// 	// }

// 	// 쿼리 모달 열기
// 	handleOpenQueryModal = () => {
// 		const { onOpenQueryModal, medInfo } = this.props
// 		onOpenQueryModal(medInfo)
// 	}

// 	// 쿼리 작성 모달 열기
// 	handleOpenWriteQueryModal = () => {
// 		const { onOpenWriteQueryModal, medInfo } = this.props
// 		onOpenWriteQueryModal(medInfo)
// 	}

// 	// 키보드 이벤트 처리
// 	handleKeydown = e => {
// 		if (e.keyCode === 13) {
// 			// 엔터키 누를 경우 다음 항목으로 넘어가도록
// 			// const { inputRefs, medInfoId } = this.props
// 			// const refKeys = Object.keys(inputRefs)
// 			// const index = refKeys.indexOf(medInfoId.toString())
// 			// if (index >= 0 && index !== refKeys.length - 1) {
// 			//   const nextId = refKeys[index + 1]
// 			//   const nextRef = inputRefs[nextId].current
// 			//   if (nextRef != null&& nextRef.focus != null) {
// 			//     nextRef.focus()
// 			//   }
// 			// }
// 		}
// 	}

// 	handleUploadFile = file => {
// 		const { client } = this.props

// 		client.mutate({
// 			mutation: UPLOAD_MED_INFO_IMAGE,
// 			variables: {
// 				image: file,
// 			},
// 			onCompleted: ({ data }) => {
// 				if (data != null && data.uploadMedInfoImage) {
// 					this.handleUpdateInputValue(null, { value: data.uploadMedInfoImage })
// 				}
// 			},
// 		})
// 	}

// 	// VAS 값 변경시 업데이트 처리
// 	updateVasValue = async value => {
// 		const { inputType } = this.props
// 		const vasOnly = inputType === InputType.VAS_ONLY.key
// 		await this.setState({
// 			vasValue: value,
// 			...(vasOnly && {
// 				value,
// 			}),
// 		})

// 		if (vasOnly) {
// 			this.handleUpdateValues([{ key: 'value', value }, { key: 'vasValue', value }])
// 		} else {
// 			this.handleUpdateValue('vasValue', value)
// 		}
// 	}

// 	// VAS 값 텍스트 변경시
// 	updateVasInputValue = (e, { value }) => {
// 		this.updateVasValue(value)
// 	}

// 	// Input 렌더링
// 	renderInput = disabled => {}

// 	render() {
// 		const {
// 			onDeleteMedInfo,
// 			medInfoIndex,
// 			name,
// 			format,
// 			options,
// 			templateMode,
// 			hasVAS,
// 			description,
// 			connectDragSource,
// 			onClickSettingMenu,
// 			onResize,
// 			isFixed,
// 			disabled: disabled_,
// 			defaultDisabled,
// 			readOnly,
// 			gridCellIndi,
// 			vasMax,
// 			unresolvedMedInfoQueryCount,
// 			medInfoQueryCount,
// 			canWriteQuery,
// 			gridCell,
// 			isRemoved,
// 			isAdded,
// 			medInfoEntityId,
// 			medInfoId,
// 			value,
// 			optionValue,
// 			checkedItems,
// 			unit,
// 			inputType,
// 			defaultValue,
// 			placeholder,
// 			selectOptions,
// 			entityType,
// 			inputRefs,
// 		} = this.props

// 		// 비활성화 여부 확인
// 		const optionDisabled = useMemo(() => {
// 			const curOption = options.find(
// 				opt => optionValue != null && optionValue.split('|').indexOf(opt.value) >= 0
// 			)
// 			return (
// 				curOption &&
// 				curOption.format &&
// 				curOption.format.includes(MED_INFO_TYPE_FORMATS.disablesInput)
// 			)
// 		}, [options, optionValue])

// 		// const disabled = useMemo(() => {
// 		// 	if (templateMode === true) {
// 		// 		return false
// 		// 	} else {
// 		// 		if (propDisabled == null && optionDisabled == null) {
// 		// 			return defaultDisabled
// 		// 		}

// 		// 		return propDisabled || optionDisabled
// 		// 	}
// 		// }, [propDisabled, templateMode, defaultDisabled, optionDisabled])

// 		const {
// 			// optionValue: stateOptionValue,
// 			// disabled: stateDisabled,
// 			// vasValue: stateVasValue,
// 		} = this.state

// 		// 비활성 여부 확인
// 		// - 양식 수정일 경우 반드시 활성
// 		// - 기본 비활성 여부 체크
// 		// - 컨디션으로 인한 비활성 여부 체크
// 		const disabled =
// 			(!templateMode ? (disabled_ !== undefined ? disabled_ : defaultDisabled) : false) ||
// 			stateDisabled

// 		// const input = this.renderInput(disabled)

// 		let selectedOption
// 		if (options && options.length >= 1) {
// 			selectedOption = options.find(item => item.value === stateOptionValue)
// 		}

// 		// option 값 설정
// 		const isOptionCheckbox = format && format.includes(MED_INFO_TYPE_FORMATS.optionIsCheckbox)
// 		const showOptions = (options && options.length > 1 && !readOnly) || isOptionCheckbox
// 		const showReadOnlyOption = readOnly && !disabled && !showOptions

// 		// Drag Source Wrapping
// 		const wrapper = gridCell !== true ? connectDragSource : r => r

// 		return wrapper(
// 			<div id={'md-' + medInfoEntityId}>
// 				<DataFieldDiv
// 					medInfoId={medInfoId}
// 					className={`df-container
//           ${isAdded === true ? 'df-container-change df-container-added' : ''}
//           ${isRemoved === true ? 'df-container-change df-container-removed' : ''}
// 					${gridCell === true ? 'df-grid-cell' : ''}
// 					${gridCellIndi === true ? 'df-grid-cell-indi' : ''}
// 				`}
// 					noHover={gridCell === true && gridCellIndi !== true}
// 				>
// 					{gridCell !== true && isAdded === true && <AddedSign />}
// 					{gridCell !== true && isRemoved === true && <RemovedSign />}
// 					<div
// 						className={isFixed || templateMode ? 'df-wrap' : 'df-wrap-inline'}
// 						style={styles.wrap}
// 					>
// 						{!gridCell && (
// 							<TitleBox>
// 								<InputLabel disabled={disabled}>{name}</InputLabel>
// 								{medInfoQueryCount > 0 && (
// 									<MedInfoQueryButton
// 										onClick={this.handleOpenQueryModal}
// 										unresolvedMedInfoQueryCount={unresolvedMedInfoQueryCount}
// 										medInfoQueryCount={medInfoQueryCount}
// 									/>
// 								)}
// 								{/* {medInfoQueryCount === 0 && canWriteQuery === true && (
// 									<WriteQueryButton onClick={this.handleOpenWriteQueryModal} />
// 								)} */}
// 							</TitleBox>
// 						)}
// 						{description && (
// 							<Description>
// 								<ReactSVG style={styles.infoIcon} src={InfoIcon} />
// 								<DescriptionText>{description}</DescriptionText>
// 							</Description>
// 						)}
// 						<InputBox isGridCell={gridCell}>
// 							{/* <span style={{ ...styles.inputWrap, ...(gridCell && { justifyContent: 'center' }) }}> */}
// 							{
// 								<DataFieldInput
// 									checkedItems={checkedItems}
// 									entityType={entityType}
// 									selectOptions={selectOptions}
// 									inputType={inputType}
// 									placeholder={placeholder}
// 									format={format}
// 									options={options}
// 									value={value}
// 									optionValue={optionValue}
// 									readOnly={readOnly}
// 									unit={unit}
// 									gridCell={gridCell}
// 									inputRefs={inputRefs}
// 									medInfoId={medInfoId}
// 									disabled={disabled}
// 									onUpdate={this.handleUpdateValue}
// 									onUpdateMany={this.handleUpdateValues}
// 									onKeydown={this.handleKeydown}
// 									defaultValue={defaultValue}
// 									templateMode={templateMode}
// 									defaultDisabled={defaultDisabled}
// 									onUploadFile={this.handleUploadFile}
// 								/>
// 							}
// 							{showOptions && (
// 								<span style={styles.optionWrap}>
// 									{isOptionCheckbox ? (
// 										<Form.Group style={{ marginTop: 8, marginLeft: 8 }}>
// 											{options.map(option => (
// 												<Checkbox
// 													style={{ marginRight: 10 }}
// 													key={option.value}
// 													label={option.text}
// 													value={option.value}
// 													checked={stateOptionValue && stateOptionValue.includes(option.value)}
// 													onChange={this.handleUpdateOptionCheckbox(option)}
// 													option={option}
// 												/>
// 											))}
// 										</Form.Group>
// 									) : (
// 										<Dropdown
// 											button
// 											className="options-dropdown"
// 											disabled={disabled}
// 											options={options}
// 											value={stateOptionValue}
// 											onChange={!readOnly && this.handleUpdateOptionValue}
// 										/>
// 									)}
// 								</span>
// 							)}
// 							{showReadOnlyOption && (
// 								<span style={{ marginLeft: 10 }}>{selectedOption && selectedOption.text}</span>
// 							)}
// 							{/* {!isFixed && !templateMode && !gridCell && (
// 								<Button
// 									style={styles.optionBtn}
// 									circular
// 									icon="close"
// 									size="tiny"
// 									onClick={() => onDeleteMedInfo(medInfoIndex)}
// 									defaultValue="page"
// 								/>
// 							)} */}
// 							{/* </span> */}
// 						</InputBox>
// 					</div>
// 					{hasVAS && (
// 						<div style={styles.vasScale}>
// 							<VasSlider
// 								disabled={disabled}
// 								readOnly={readOnly}
// 								style={styles.vasSlider}
// 								updateVasValue={this.updateVasValue}
// 								vasValue={stateVasValue}
// 								maxValue={vasMax}
// 							/>
// 						</div>
// 					)}
// 					{(isFixed === false || templateMode === true) &&
// 						(gridCell !== true || gridCellIndi === true) && (
// 							<DataFieldEditDropdown
// 								medInfoId={medInfoId}
// 								className="data-field-edit"
// 								onClickMenu={onClickSettingMenu}
// 								onResize={onResize}
// 								noDelete={gridCellIndi}
// 								noCopy={gridCellIndi}
// 							/>
// 						)}
// 				</DataFieldDiv>
// 			</div>
// 		)
// 	}
// }

// export default withApollo(DataField)

export default DataField
const TitleBox = styled.div`
	display: block !important;
	white-space: nowrap;
`

// const InputLabel = styled.div`
//   letter-spacing: -0.65px;
//   color: ${props => (props.disabled !== true ? '#484848' : '#eaeaea')};
// `

const AddedSign = () => {
	return (
		<span className="df-container-added df-container-sign">
			<Icon name="plus" />
		</span>
	)
}

const RemovedSign = () => {
	return (
		<span className="df-container-removed df-container-sign">
			<Icon name="minus" />
		</span>
	)
}

const MedInfoQueryButton = ({ onClick, medInfoQueryCount, unresolvedMedInfoQueryCount }) => {
	return (
		<Button style={styles.queryButton} onClick={onClick}>
			<Icon name="comment outline" size="small" />
			{unresolvedMedInfoQueryCount > 0 ? (
				<Label size="tiny" circular color="red" style={{ marginLeft: -5, fontSize: 10 }}>
					{unresolvedMedInfoQueryCount}
				</Label>
			) : (
				medInfoQueryCount > 0 && (
					<Label
						size="mini"
						circular
						icon="check"
						color="green"
						style={{ marginLeft: -5, fontSize: 10 }}
					/>
				)
			)}
		</Button>
	)
}

MedInfoQueryButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	unresolvedMedInfoQueryCount: PropTypes.number.isRequired,
	medInfoQueryCount: PropTypes.number,
}

const WriteQueryButton = ({ onClick }) => {
	return (
		<Button style={styles.writeButton} onClick={onClick}>
			<Icon name="pencil" />
		</Button>
	)
}

WriteQueryButton.propTypes = {
	onClick: PropTypes.func.isRequired,
}

const Description = styled.div`
	font-size: 13px;
	letter-spacing: -0.65px;
	line-height: 1.33;
	margin-top: 2px;
	padding-right: 32px;
	padding-left: 40px;
	margin-bottom: 8px;
	color: ${COLOR_TEXT_LIGHTGRAY};
`

const DescriptionText = styled.p`
	display: inline-block;
	margin-top: 4px;
	white-space: pre-line;
	vertical-align: top;
`

const InputBox = styled.span`
	display: flex;
	flex: 1;
	margin-top: ${props => (props.isGridCell === true ? 0 : 10)}px;
	justify-content: ${props => (props.isGridCell === true ? 'center' : 'auto')};

	.input {
		flex: 1;
	}

	.input.df-number {
		max-width: 300px;
	}
`

const styles = {
	infoIcon: {
		display: 'inline-block',
		position: 'absolute',
		left: 15,
	},
	wrap: {
		textAlign: 'left',
		display: 'flex',
		flexWrap: 'nowrap',
		width: '100%',
	},
	optsWrap: {
		display: 'inline-block',
		marginTop: 5,
		marginLeft: 5,
		marginBottom: 8,
	},
	optionWrap: {
		display: 'inline-block',
		marginLeft: 5,
	},
	optionBtn: {
		position: 'absolute',
		top: 10,
		right: 5,
	},
	checkbox: {
		display: 'inline-block',
		marginRight: 10,
		marginBottom: 10,
	},
	textArea: {
		padding: 8,
		borderRadius: 5,
		borderColor: 'rgba(34,36,38,.15)',
		minHeight: 50,
	},
	input: {
		width: '100%',
	},
	selectCell: {
		minWidth: '100%',
	},
	selectNormal: {
		display: 'flex',
		flex: 1,
	},
	vasScale: {
		display: 'flex',
		maxWidth: 350,
		width: '100%',
		alignItems: 'center',
		marginTop: -5,
	},
	number: {
		maxWidth: 300,
	},
	writeButton: {
		background: 'none',
		padding: 0,
		color: COLOR_TEXT_LIGHTGRAY,
		marginLeft: 10,
		position: 'absolute',
		right: 20,
		bottom: 10,
		border: 'none',
		fontSize: 16,
	},
	queryButton: {
		background: 'none',
		padding: 0,
		color: COLOR_TEXT_LIGHTGRAY,
		marginLeft: 10,
		position: 'absolute',
		right: 20,
		bottom: 10,
		border: 'none',
		fontSize: 25,
	},
}
