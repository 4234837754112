import React from 'react'
import PropTypes from 'prop-types'
import CaseFeed from './CaseFeed'
import { CaseListType } from '../../enum'

const NewsFeed = () => {
	return (
		<div>
			<CaseFeed type={CaseListType.FEED.value} title="피드" />
		</div>
	)
}

NewsFeed.propTypes = {}

export default NewsFeed
