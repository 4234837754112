import React from 'react'
import PropTypes from 'prop-types'
import { withRouter, Switch, Route } from 'react-router-dom'
import ResearchDetail from './ResearchDetail'
import MyResearches from './MyResearches'

import { ROUTE_MY_PROJECTS, ROUTE_PROJECT_DETAIL, ROUTE_NEW_PROJECT } from '../constants/routes'
import ResearchNew from '../components/research/new/ResearchNew'

/**
 * 프로젝트 페이지 라우팅 컴퍼넌트
 */

const Researches = props => {
	return (
		<Switch>
			<Route exact path={ROUTE_MY_PROJECTS} component={() => <MyResearches {...props} />} />
			<Route exact path={ROUTE_NEW_PROJECT} component={() => <ResearchNew {...props} />} />
			<Route path={ROUTE_PROJECT_DETAIL} component={() => <ResearchDetail {...props} />} />
		</Switch>
	)
}

Researches.propTypes = {
	match: PropTypes.shape({
		url: PropTypes.string.isRequired,
		params: PropTypes.shape({
			id: PropTypes.string,
		}),
	}).isRequired,
}

export default withRouter(Researches)
