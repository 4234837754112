import gql from 'graphql-tag'

export const STAT_VALUE_FRAGMENT = gql`
	fragment DataValueParts on DataValue {
		... on FloatValue {
			floatValue
		}
		... on IntValue {
			intValue
		}
		... on StringValue {
			stringValue
		}
	}
`

export const STAT_RESULT_FRAGMENT = gql`
	fragment StatResultParts on StatResult {
		type
		... on RatioData {
			ratios {
				value
				percentage
				name
				color
			}
		}
		... on SingleValue {
			value {
				...DataValueParts
			}
		}
		... on TableData {
			columns {
				name
				type
			}
			rows {
				values {
					...DataValueParts
				}
			}
		}
	}

	${STAT_VALUE_FRAGMENT}
`
