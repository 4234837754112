import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { useQuery } from 'react-apollo'
import { Grid } from 'semantic-ui-react'
import { GET_RESEARCH_OPITON_COMBINATION_LIST } from '../../../graphql/queries/research'
import LoadingView from '../../common/loader'
import ErrorView from '../../common/error/ErrorView'
import ResearchOptionCombination from './ResearchOptionCombination'
import { CARD_COLORS, COLOR_BACKGROUND_DARK } from '../../../constants/styles'

/**
 * 프로젝트 템플릿 선택 옵션
 */
const ResearchOptionCombinationList = ({ onSelect, selected }) => {
	const { data, loading, error } = useQuery(GET_RESEARCH_OPITON_COMBINATION_LIST)

	return (
		<div>
			{loading === true && <LoadingView />}
			{error != null && <ErrorView error={error} />}
			{data != null && data.researchOptionCombinations && (
				<CardGrid columns={3}>
					{data.researchOptionCombinations.items
						.sort((a, b) => (a.id < b.id ? -1 : 1))
						.map((i, index) => (
							<Grid.Column key={`r-opt-${i.id}`}>
								<ResearchOptionCombination
									isSelected={selected != null && selected.id === i.id}
									researchInfo={i}
									onSelect={onSelect}
									color={CARD_COLORS[index]}
								/>
							</Grid.Column>
						))}
					<Grid.Column>
						<ResearchOptionCombination
							isSelected={selected != null && selected.id === -1}
							researchInfo={{
								name: '기타',
								id: -1,
								description: '자유롭게 연구 프로젝트를 설정해 보세요.',
								isFree: true,
								hashPatient: false,
								requiresAgreement: true,
							}}
							onSelect={onSelect}
							color={COLOR_BACKGROUND_DARK}
						/>
					</Grid.Column>
				</CardGrid>
			)}
		</div>
	)
}

ResearchOptionCombinationList.propTypes = {
	onSelect: PropTypes.func,
	selected: PropTypes.shape({
		id: PropTypes.number,
	}),
}

export default ResearchOptionCombinationList

const CardGrid = styled(Grid)`
	margin-left: -8px !important;
	margin-right: -8px !important;
	margin-top: -8px !important;
	margin-bottom: -8px !important;

	.column {
		padding-top: 8px !important;
		padding-bottom: 8px !important;
		padding-left: 8px !important;
		padding-right: 8px !important;
	}
`
