import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Switch, withRouter } from 'react-router-dom'
import { Menu, Container, Header } from 'semantic-ui-react'
import ChartContainer from '../components/common/container/ChartContainer'
import MenuContainer from '../components/common/container/MenuContainer'
import ChartSettingsTemplates from './ChartSettingsTemplates'
import PrescriptionManager from './PrescriptionManager'
import AcupunctureManager from './AcupunctureManager'
import PageContainer from '../components/common/container/PageContainer'
import PrepareView from '../components/common/error/PrepareView'
import HeaderPageTitle from '../components/common/basic/HeaderPageTitle'

const MENU_ITEMS = [
	{
		key: 'CHART-ST-TEMPLATES',
		value: 'templates',
		to: '/settings/templates',
		title: '양식 설정',
		component: ChartSettingsTemplates,
	},
	// {
	// 	value: 'prescriptions',
	// 	to: '/settings/prescriptions',
	// 	title: '처방 관리',
	// 	component: PrescriptionManager,
	// },
	// {
	// 	value: 'acupuncture',p
	// 	to: '/settings/acupuncture',
	// 	title: '침구 관리',
	// 	component: AcupunctureManager,
	// },
]

const ChartSettings = props => {
	const { headerRef } = props
	useEffect(() => {
		// Update the document title using the browser API
		if (headerRef.current != null) {
			headerRef.current.handleSetContent(<HeaderPageTitle>차팅 설정</HeaderPageTitle>)
			headerRef.current.handleSetLeftContent(null)
		}
	})

	return (
		<PageContainer title="설정">
			{/* <MenuContainer menuItems={MENU_ITEMS} defaultMenu="templates" basicProps={props} /> */}
			<PrepareView message="다양한 개인 저장용 양식을 설정하고 양식별 데이터를 기록하고 관리할 수 있습니다." />
		</PageContainer>
	)
}

export default withRouter(ChartSettings)
