import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, withRouter, Link } from 'react-router-dom'
import compose from 'lodash.flowright'
import { graphql } from 'react-apollo'

import ResearchPatientDetail from '../components/research/patients/ResearchPatientDetail'

import { DROP_RESEARCH_PATIENT } from '../graphql/mutations/patient'
import { ADD_RECORD } from '../graphql/mutations/record'

import ResearchPatientList from './ResearchPatientList'
import {
	ROUTE_PROJECT_DETAIL_PATIENT_DETAIL,
	ROUTE_PROJECT_DETAIL_PATIENTS_DEFAULT,
	ROUTE_PROJECT_DETAIL_DATA_DETAIL,
} from '../constants/routes'
import { replaceParams } from '../libs/routeHelper'
import ResearchRecordDetail from '../components/research/data/ResearchRecordDetail'

const PAGE_TITLE = '연구대상자'

const ResearchPatients = props => {
	const {
		match: {
			params: { subId },
		},
		researchId,
		breadCrumbs,
	} = props

	const newBreadCrumb = {
		active: true,
		content: '연구대상자',
		link: true,
		as: Link,
		to: replaceParams(ROUTE_PROJECT_DETAIL_PATIENTS_DEFAULT, {
			id: researchId,
		}),
		key: 'PG_BC_PR_PATIENT',
	}

	const subCrumbs = [...breadCrumbs, newBreadCrumb]

	const patientId = parseInt(subId, 10)

	const basicProps = {
		breadCrumbs: subCrumbs,
		pageTitle: PAGE_TITLE,
		patientId,
	}

	return (
		<Switch>
			<Route
				exact
				path={ROUTE_PROJECT_DETAIL_PATIENTS_DEFAULT}
				component={p => <ResearchPatientList {...props} {...p} {...basicProps} />}
			/>
			<Route
				path={ROUTE_PROJECT_DETAIL_DATA_DETAIL}
				component={p => <ResearchRecordDetail {...props} {...p} {...basicProps} />}
			/>
			<Route
				path={ROUTE_PROJECT_DETAIL_PATIENT_DETAIL}
				component={p => <ResearchPatientDetail {...props} {...p} {...basicProps} />}
			/>
		</Switch>
	)
}

ResearchPatients.propTypes = {
	researchId: PropTypes.number.isRequired,
	userResearcherInfo: PropTypes.shape({}),
	loadingResearchInfo: PropTypes.bool,
	match: PropTypes.shape({
		params: PropTypes.shape({
			subId: PropTypes.string,
		}),
	}),
	breadCrumbs: PropTypes.arrayOf(PropTypes.shape({})),
}

export default compose(
	withRouter,
	graphql(ADD_RECORD, {
		name: 'onAddRecord',
	})
)(ResearchPatients)
