/**
 * [Component] 환자 정보 수정 Modal
 */

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'semantic-ui-react'
import moment from 'moment'

import { Mutation } from 'react-apollo'
import { PATIENT_INFO_FIELDS } from '../../../constants'
import { validatePatient } from '../../../libs/simpleValidations'
import { UPDATE_PATIENT } from '../../../graphql/mutations/patient'
import PatientForm from '../PatientForm'

const BASIC_REQUIRED_FIELDS = Object.keys(PATIENT_INFO_FIELDS).filter(
	key => PATIENT_INFO_FIELDS[key].basicRequired === true
)

export default class PatientEditModalContent extends PureComponent {
	static propTypes = {
		open: PropTypes.bool,
		researchPatientInfo: PropTypes.string,
		onClose: PropTypes.func,
		submitLoading: PropTypes.bool,
		isTest: PropTypes.bool,
		onDelete: PropTypes.func,
		isHashed: PropTypes.bool,
		patientId: PropTypes.number,
		patientResearches: PropTypes.arrayOf(PropTypes.shape({})),
	}

	static defaultProps = {
		submitLoading: false,
	}

	constructor(props) {
		super(props)

		this.state = Object.keys(PATIENT_INFO_FIELDS).reduce((state, key) => {
			state[key] = PATIENT_INFO_FIELDS[key].dateOnly
				? props[key]
					? moment(props[key]).format('YYYY-MM-DD')
					: moment().subtract({ years: 30 })
				: props[key]

			if (props.isHashed === true && key === 'name') {
				state[key] = ''
			}
			return state
		}, {})
	}

	handleChange = (e, { name, value }) => this.setState({ [name]: value })

	handleChangeBirthDate = (e, { value }) => {
		this.setState({
			birthDate: `${value}-01-01`,
		})
	}

	handleSubmit = updatePatient => async () => {
		const { isHashed } = this.props

		this.setState({
			submitLoading: true,
		})

		const { researchPatientInfo, onClose, patientId, isTest } = this.props
		let researchFields

		if (researchPatientInfo) {
			researchFields = researchPatientInfo.split('|')
		}

		let requiredFields = [...BASIC_REQUIRED_FIELDS, ...(researchFields ? researchFields : [])]

		if (isHashed === true) {
			requiredFields = requiredFields.filter(item => item.isHashable !== true)
		}

		if (isTest) {
			requiredFields = requiredFields.filter(item => item !== 'patientCode')
		}

		const isValid = validatePatient(requiredFields, this.state)
		if (!isValid) {
			this.setState({
				submitLoading: false,
			})

			return
		}

		try {
			await updatePatient({
				variables: {
					patientId,
					...this.state,
				},
			})

			onClose()
		} catch (e) {
			alert('오류가 발생했습니다.')
		}

		this.setState({
			submitLoading: false,
		})
	}

	render() {
		const { patientResearches, isHashed } = this.props
		const researchFields = [
			...new Set(
				patientResearches
					.map(pr => pr.research.patientBasicInfo)
					.join('|')
					.split('|')
					.filter(i => i !== '')
			),
		]

		return (
			<React.Fragment>
				<Modal.Content>
					<PatientForm
						{...this.state}
						isHashed={isHashed}
						onChange={this.handleChange}
						onChangeBirthDate={this.handleChangeBirthDate}
						researchFields={researchFields}
					/>
					{/* <Form> */}
					{/* <Form.Field
							control={Input}
							name="name"
							label={PATIENT_INFO_FIELDS.name.text}
							value={this.state.name}
							placeholder={
								isHashed === true && '이름은 암호화 처리되어 기록됩니다. 변경할 값을 입력해 주세요.'
							}
							onChange={this.handleChange}
						/>
						<Form.Field
							fluid
							compact
							name="sexMale"
							value={this.state.sexMale}
							onChange={this.handleChange}
						>
							<label>성별</label>
							<Form.Group style={{ marginTop: 12 }}>
								<Form.Field
									control={Radio}
									inline
									name="sexMale"
									label="남성"
									value
									onChange={this.handleChange}
									checked={this.state.sexMale === true}
								/>
								<Form.Field
									control={Radio}
									inline
									name="sexMale"
									label="여성"
									value={false}
									onChange={this.handleChange}
									checked={this.state.sexMale === false}
								/>
							</Form.Group>
						</Form.Field>
						{!isTest && (
							<Form.Field
								control={Input}
								name="patientCode"
								label={PATIENT_INFO_FIELDS.patientCode.text}
								value={this.state.patientCode}
								onChange={this.handleChange}
								placeholder={
									isHashed === true &&
									'차트번호는 암호화 처리되어 기록됩니다. 변경할 값을 입력해 주세요.'
								}
							/>
						)}
						{(!researchFields || researchFields.indexOf('birthDateReal') < 0) && (
							<Form.Field>
								<label>출생연도</label>
								<Select
									options={_.range(moment().year(), 1900, -1).map(item => {
										const v = `${item}`
										return {
											key: v,
											value: v,
											text: v,
										}
									})}
									name="birthDate"
									value={this.state.birthDate.slice(0, 4)}
									onChange={this.handleChangeBirthDate}
								/>
							</Form.Field>
						)}
						<PatientResearchForm
							researchFields={researchFields}
							values={this.state}
							onChange={this.handleChange}
							onChangeBirthDate={this.handleChangeBirthDate}
						/>
					</Form> */}
				</Modal.Content>
				<Modal.Actions>
					<Mutation mutation={UPDATE_PATIENT}>
						{update => (
							<Button
								primary
								loading={this.state.submitLoading}
								onClick={this.handleSubmit(update)}
							>
								수정하기
							</Button>
						)}
					</Mutation>
				</Modal.Actions>
			</React.Fragment>
		)
	}
}
