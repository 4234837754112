import React from 'react'
import styled from 'styled-components'

import NotificationPopup from '../../user/my/NotificationPopup'

/**
 * 헤더 상단 알림
 */
export const HeaderNotification = () => {
	return (
		<Notification>
			<NotificationPopup />
		</Notification>
	)
}

const Notification = styled.div`
	width: 36px;
	height: 36px;
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	position: relative;
`
