import React from 'react'
import PropTypes from 'prop-types'

import { withRouter, matchPath, NavLink } from 'react-router-dom'

import { Menu } from 'semantic-ui-react'

import { MENU_ITEM_TYPES, UISize } from '../../enum'
import { ImageIconButtonContent, ImageIconOnlyButton } from './basic/ImageIconButton'
import { replaceParams } from '../../libs/routeHelper'

/**
 * 메뉴에 따라 페이지를 전환하는 RouteMenu 컴퍼넌트
 */
const RouteMenuComponent = ({
	menuOptions,
	menuItems,
	menuItemType = 'text',
	renderMenuItem,
	activeParam,
	location: { pathname },
	replaceParam,
	keyPrefix,
	match: { params },
	className,
}) => {
	let checkActive

	if (activeParam != null) {
		checkActive = item => {
			return item.key === params[activeParam]
		}
	} else {
		checkActive = (item, uri) => {
			return (
				matchPath(pathname, {
					path: uri,
					exact: item.exact,
				}) != null
			)
		}
	}

	return (
		<Menu className={className} {...menuOptions}>
			{menuItems.map(item => {
				// 링크 URI 파라미터 교체
				const toURI = replaceParam != null ? replaceParams(item.to, replaceParam) : item.to
				const isActive = checkActive(item, toURI)
				let content

				if (renderMenuItem != null) {
					content = renderMenuItem(item, isActive)
				} else if (menuItemType === MENU_ITEM_TYPES.IMAGE_ICON && item.imageIcon != null) {
					content = (
						<ImageIconButtonContent
							size={UISize.SMALL}
							text={item.title}
							imageIcon={item.imageIcon}
							isActive={isActive}
						/>
					)
				} else if (menuItemType === MENU_ITEM_TYPES.IMAGE_ICON_ONLY && item.imageIcon != null) {
					content = (
						<ImageIconOnlyButton
							isActive={isActive}
							size={UISize.SMALL}
							text={item.title}
							imageIcon={item.imageIcon}
						/>
					)
				} else {
					content = item.title
				}

				return (
					<Menu.Item
						as={NavLink}
						key={keyPrefix + item.key}
						active={isActive}
						exact={item.exact}
						to={toURI}
					>
						<span>{content}</span>
					</Menu.Item>
				)
			})}
		</Menu>
	)
}

export const RouteMenu = withRouter(RouteMenuComponent)

RouteMenuComponent.propTypes = {
	menuOptions: PropTypes.shape(),
	menuItems: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string,
			to: PropTypes.string.isRequired,
		})
	).isRequired,
	menuItemType: PropTypes.string,
	renderMenuItem: PropTypes.func,
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired,
	}).isRequired,
	replaceParam: PropTypes.shape(),
	activeParam: PropTypes.string,
	match: PropTypes.shape({
		params: PropTypes.object,
	}).isRequired,
	keyPrefix: PropTypes.string,
	className: PropTypes.string,
}

RouteMenuComponent.defaultProps = {
	menuOptions: {
		pointing: true,
		secondary: true,
	},
	menuItemType: '',
	renderMenuItem: null,
	replaceParam: null,
	activeParam: null,
	keyPrefix: '',
	className: null,
}
