import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Dropdown } from 'semantic-ui-react'
import useSelectInput from './useSelectInput'

/**
 * Data Field Dropdown
 *
 * 드롭다운 인풋
 */
const DataFieldSelect = ({
	valueKey = 'value',
	value,
	readOnly,
	onUpdate,
	selectOptions,
	...options
}) => {
	const [stateValue, onChange] = useSelectInput(onUpdate, valueKey, value, selectOptions)

	return (
		<StyledDropdown
			{...options}
			selection
			options={selectOptions}
			onChange={readOnly != true ? onChange : () => {}}
			value={stateValue}
			placeholder={'선택해 주세요.' || options.placeholder}
			defaultValue={selectOptions != null ? selectOptions[0].value : null}
			clearable
		/>
	)
}

DataFieldSelect.propTypes = {
	valueKey: PropTypes.string,
	value: PropTypes.string,
	defaultValue: PropTypes.string,
	onUpdate: PropTypes.func,
	readOnly: PropTypes.bool,
	selectOptions: PropTypes.array,
	options: PropTypes.array,
}

export default DataFieldSelect

const StyledDropdown = styled(Dropdown)`
	${props => (props.isGrid === true ? 'min-width: 100%' : 'display: flex; flex: 1;')};
`
