import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import DataTable from '../components/common/DataTable'
import PatientCode from '../components/common/PatientCode'
import StatusDotLabel from '../components/common/StatusDotLabel'

import { GET_RESEARCH_QUERIES } from '../graphql/queries/research'
import { DataTypes, MedInfoQueryStatus } from '../enum'

const COLUMNS = [
	{
		key: 'writer.name',
		text: '작성자',
		uiOptions: {
			textAlign: 'center',
			style: {
				width: 150,
			},
		},
	},
	{
		key: 'record.patient.patientResearches.code',
		text: '환자 코드',
		getter: row => row.record.patient.patientResearches[0].code,
		uiOptions: {
			textAlign: 'center',
			style: {
				width: 120,
			},
		},
		render: ({ value }) => {
			return <PatientCode code={value} />
		},
	},
	{
		key: 'record.template.title',
		text: '일정',
		getter: row => row.record.template.title,
		uiOptions: {
			textAlign: 'center',
		},
	},
	{
		key: 'message',
		text: '내용',
		uiOptions: {
			textAlign: 'left',
		},
	},
	{
		key: 'status',
		text: '상태',
		render: ({ status }) => {
			const statusInfo = MedInfoQueryStatus[status]
			return <StatusDotLabel {...statusInfo} />
		},
		uiOptions: {
			textAlign: 'center',
			width: 140,
		},
	},
	{
		key: 'createdAt',
		text: '생성일',
		dataType: DataTypes.DATE,
		uiOptions: {
			textAlign: 'center',
		},
	},
]

const ResearchQueries = ({ researchId, history }) => {
	return (
		<div>
			<DataTable
				query={GET_RESEARCH_QUERIES}
				queryName="researchMedInfoQueries"
				defaultVars={{
					researchId,
					pagination: {
						page: 1,
						perPage: 10,
					},
				}}
				columns={COLUMNS}
				onClickRow={({ record, medInfo }) => {
					if (record != null) {
						const { code } = record
						const { medInfoEntityId } = medInfo
						history.push(`/researches/${researchId}/data/${code}?query=${medInfoEntityId}`)
					}
				}}
			/>
		</div>
	)
}

ResearchQueries.propTypes = {
	researchId: PropTypes.number.isRequired,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
}

export default withRouter(ResearchQueries)
