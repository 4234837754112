import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Sticky } from 'semantic-ui-react'
import LogoImage from '../../assets/images/logo/lepius-logo-white-small.png'
import { RouteMenu } from '../common/RouteMenu'
import { HEADER_MENU } from '../../constants/menus'
import { MENU_ITEM_TYPES, UISize } from '../../enum'
import {
	SIDE_BAR_WIDTH,
	SIDE_BAR_RIGHT_MARGIN,
	COLOR_PRIMARY,
	COLOR_BACKGROUND_DARK,
} from '../../constants/styles'
import { ROUTE_ABOUT } from '../../constants/routes'
import { ImageIconOnlyButton } from '../common/basic/ImageIconButton'

/**
 * 페이지 좌측 사이드 메뉴(헤더 메뉴와 같은 내용)
 */
export const SideMenu = ({ isResearcher }) => {
	return (
		<Container className="main-side-menu">
			<Logo />
			<RouteMenu
				menuOptions={{ vertical: true, text: true }}
				menuItemType={MENU_ITEM_TYPES.IMAGE_ICON_ONLY}
				menuItems={isResearcher ? [HEADER_MENU[2]] : HEADER_MENU}
				renderMenuItem={(item, isActive) => {
					return (
						<React.Fragment>
							<ImageIconOnlyButton
								isActive={isActive}
								size={UISize.HUGE}
								text={item.title}
								imageIcon={item.imageIcon}
							/>
							<span className="active-marker" />
						</React.Fragment>
					)
				}}
			/>
		</Container>
	)
}

const Logo = () => {
	return (
		<Link to={ROUTE_ABOUT}>
			<LogoImageTag src={LogoImage} />
		</Link>
	)
}

const Container = styled.div`
  background:${COLOR_BACKGROUND_DARK}
  /* position: fixed; */
  /* left: 0px; */
  /* top: 0px; */
  width: ${SIDE_BAR_WIDTH}px;
	height: 100vh;
	z-index: 12;
`

const LogoImageTag = styled.img`
	margin-top: 25px;
	width: 60px;
	margin-bottom: 10px;
`
