import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal, Button } from 'semantic-ui-react/dist/commonjs'
import scrollToElement from 'scroll-to-element'

import MedInfoTypeModal from './modals/MedInfoTypeModal/MedInfoTypeModal'

import MedInfoQueryModal from './modals/queries/MedInfoQueryModal'
import MedInfoQueryWriteModal from './modals/queries/MedInfoQueryWriteModal'
import { findOneMedInfo } from '../../libs/medInfoHelper'
import ChartContent from './ChartContent'
import './chart.css'
import ErrorBoundary from '../common/ErrorBoundary'
import { COLOR_BACKGROUND_GRAY } from '../../constants/styles'
import { ShadowedBox } from '../common/basic/StyledBox'
import ChartDragLayer from './ChartDragLayer'

// 차트 데이터 입력 판
class ChartingBoard extends PureComponent {
	static propTypes = {
		medInfos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		menuButtons: PropTypes.arrayOf(PropTypes.shape({})),
		onDeleteMedInfo: PropTypes.func.isRequired,
		onMoveSection: PropTypes.func.isRequired,
		onUpdateSingleMedInfo: PropTypes.func.isRequired,
		medInfoCategories: PropTypes.arrayOf(PropTypes.shape({})),
		researches: PropTypes.arrayOf(PropTypes.shape({})),
		templateMode: PropTypes.bool,
		canWriteQuery: PropTypes.bool,
		anchorOpenQuery: PropTypes.number,
		anchorOpenComment: PropTypes.bool,
	}

	static defaultProps = {
		templateMode: false,
		anchorOpenQuery: null,
		anchorOpenComment: null,
		menuButtons: null,
		medInfoCategories: null,
		researches: null,
		canWriteQuery: false,
	}

	scrolled = false

	constructor(props) {
		super(props)

		this.state = {
			addTypeModalIsEdit: false,
			addTypeModalOpen: false,
			addTypeModalTitle: '',
			addTypeModalMedInfoIndex: null,
			addTypeModalMedInfoType: {},
			addTypeModalParentType: {},
			addTypeModalSubmit: () => {},
			deleteFieldModalOpen: false,
			deleteFieldModalTitle: '',
			deleteFieldModalSubmit: () => {},
			medInfoQueryModalOpen: false,
			medInfoQueryModalMedInfo: null,
			medInfoQueryWriteModalOpen: false,
			medInfoQueryWriteModalMedInfo: null,
			// ...openQuery,
		}
		// setTimeout(
		// 	function() {
		// 		scrollToElement('#md-' + anchorOpenQuery, {
		// 			offset: -100,
		// 		})
		// 	}.bind(this),
		// 	1500
		// )
	}

	componentDidMount() {
		const { anchorOpenQuery, medInfos } = this.props

		if (this.scrolled === false && anchorOpenQuery != null && medInfos.length > 0) {
			scrollToElement('#md-' + anchorOpenQuery, {
				offset: -100,
			})

			this.scrolled = false

			this.setState({
				medInfoQueryModalOpen: true,
				medInfoQueryModalMedInfo: findOneMedInfo(medInfos, m => {
					return m.medInfoEntityId == anchorOpenQuery
				}),
			})
		}
	}

	handleOpenAddTypeModal = ({ title, onSubmit, parentType, medInfoIndex, medInfoType }) => {
		this.setState({
			addTypeModalSubmit: onSubmit,
			addTypeModalOpen: true,
			addTypeModalTitle: title,
			addTypeModalMedInfoType: medInfoType,
			addTypeModalParentType: parentType,
			addTypeModalMedInfoIndex: medInfoIndex,
			addTypeModalIsEdit: medInfoType ? true : false,
		})
	}

	handleCloseAddTypeModal = () =>
		this.setState({
			addTypeModalOpen: false,
		})

	handleOpenDeleteFieldModal = (title, index, onSubmit) => {
		this.setState({
			deleteFieldModalOpen: true,
			deleteFieldModalTitle: title,
			deleteFieldModalSubmit: onSubmit,
		})
	}

	handleCloseDeleteFieldModal = () =>
		this.setState({
			deleteFieldModalOpen: false,
			deleteFieldModalSubmit: () => {},
			deleteFieldModalTitle: '',
		})

	handleSubmitdeleteFieldModal = () => {
		const { deleteFieldModalSubmit } = this.state
		deleteFieldModalSubmit()
		this.handleCloseDeleteFieldModal()
	}

	handleOpenMedInfoQueryModal = medInfo => {
		this.setState({
			medInfoQueryModalOpen: true,
			medInfoQueryModalMedInfo: medInfo,
		})
	}

	handleCloseMedInfoQueryModal = () => {
		this.setState({
			medInfoQueryModalOpen: false,
			medInfoQueryModalMedInfo: null,
		})
	}

	handleOpenMedInfoQueryWriteModal = medInfo => {
		this.setState({
			medInfoQueryWriteModalOpen: true,
			medInfoQueryWriteModalMedInfo: medInfo,
		})
	}

	handleCloseMedInfoQueryWriteModal = () => {
		this.setState({
			medInfoQueryWriteModalOpen: false,
			medInfoQueryWriteModalMedInfo: null,
		})
	}

	render() {
		const { templateMode, canWriteQuery, onUpdateSingleMedInfo } = this.props
		const {
			addTypeModalOpen,
			addTypeModalTitle,
			addTypeModalIsEdit,
			addTypeModalMedInfoType,
			addTypeModalMedInfoIndex,
			addTypeModalParentType,
			addTypeModalSubmit,
			deleteFieldModalOpen,
			deleteFieldModalTitle,
			medInfoQueryModalOpen,
			medInfoQueryModalMedInfo,
			medInfoQueryWriteModalOpen,
			medInfoQueryWriteModalMedInfo,
		} = this.state

		return (
			<ChartBox id="content-chart-1">
				<ErrorBoundary>
					<ChartContent
						{...this.props}
						onOpenTypeModal={this.handleOpenAddTypeModal}
						onOpenDeleteModal={this.handleOpenDeleteFieldModal}
						onOpenQueryModal={this.handleOpenMedInfoQueryModal}
						onOpenWriteQueryModal={this.handleOpenMedInfoQueryWriteModal}
					/>
				</ErrorBoundary>
				{/* <Grid stackable style={{ margin: '0.2rem', width: '100%' }}>
					{medInfos.map((section, idx) => {
						const { size } = section.medInfoType
						let sizeInfo

						if (size) {
							const { value, text, ...sinfo } = SectionSize[size]
							sizeInfo = sinfo
						} else {
							sizeInfo = {
								widescreen: 4,
								largeScreen: 8,
								computer: 8,
								tablet: 8,
								mobile: 16,
							}
						}

						return (
							<Grid.Column
								key={`${section.id}`}
								style={{ paddingLeft: '0.2rem', paddingRight: '0.2rem' }}
								{...sizeInfo}
							>
								<ChartingSection
									{...others}
									key={`${section.id}`}
									medInfo={section}
									medInfoId={section.id}
									name={section.medInfoType.name}
									description={section.medInfoType.description}
									fieldColor={section.medInfoType.color}
									medInfoType={section.medInfoType}
									medInfos={section.children}
									medInfoIndex={idx}
									medInfoTypes={section.medInfoType.subTypes}
									onOpenTypeModal={this.handleOpenAddTypeModal}
									onOpenDeleteModal={this.handleOpenDeleteFieldModal}
									onOpenQueryModal={this.handleOpenMedInfoQueryModal}
									onOpenWriteQueryModal={this.handleOpenMedInfoQueryWriteModal}
									templateMode={templateMode}
								/>
							</Grid.Column>
						)
					})} */}
				{templateMode && addTypeModalOpen && (
					<MedInfoTypeModal
						open={addTypeModalOpen}
						onClose={this.handleCloseAddTypeModal}
						isEdit={addTypeModalIsEdit}
						onSubmit={addTypeModalSubmit}
						title={addTypeModalTitle}
						medInfoType={addTypeModalMedInfoType}
						medInfoIndex={addTypeModalMedInfoIndex}
						parentType={addTypeModalParentType}
					/>
				)}
				{!templateMode && (
					<MedInfoQueryModal
						open={medInfoQueryModalOpen}
						onClose={this.handleCloseMedInfoQueryModal}
						canWriteQuery={canWriteQuery}
						onUpdateSingleMedInfo={onUpdateSingleMedInfo}
						onOpenWriteModal={this.handleOpenMedInfoQueryWriteModal}
						{...medInfoQueryModalMedInfo}
					/>
				)}
				{!templateMode && canWriteQuery && (
					<MedInfoQueryWriteModal
						open={medInfoQueryWriteModalOpen}
						onClose={this.handleCloseMedInfoQueryWriteModal}
						onUpdateSingleMedInfo={onUpdateSingleMedInfo}
						{...medInfoQueryWriteModalMedInfo}
					/>
				)}
				<Modal open={deleteFieldModalOpen}>
					<Modal.Header>&apos;{deleteFieldModalTitle}&apos; 필드를 삭제하시겠습니까?</Modal.Header>
					<Modal.Actions>
						<Button onClick={this.handleCloseDeleteFieldModal} content="취소" />
						<Button
							onClick={this.handleSubmitdeleteFieldModal}
							color="red"
							content="삭제"
							icon="trash"
						/>
					</Modal.Actions>
				</Modal>
			</ChartBox>
		)
	}
}

export default ChartingBoard

const ChartBox = styled(ShadowedBox)`
	padding: 10px 30px;
	border-radius: 2px;
	background: ${COLOR_BACKGROUND_GRAY};
	min-height: 100%;
`
