/**
 * [HOC] MedInfo Children을 가지는 Higer Order Component
 *
 * Children MedInfo 처리 관련 공동함수 제공. ChartingSection & DataFieldGroup
 */

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { InputSize, DataFieldFormat, InputType } from '../enum'
import { DEFAULT_MED_INFO_TYPE } from '../constants'

export default WrappedComponent => {
	class withMedInfos extends PureComponent {
		static propTypes = {
			medInfoId: PropTypes.number,
			medInfos: PropTypes.array,
			medInfoIndex: PropTypes.number,
			medInfo: PropTypes.object,
			format: PropTypes.string,
			onGenerateMedInfo: PropTypes.func,
			onGenerateMedInfoType: PropTypes.func,
			onUpdateMedInfo: PropTypes.func,
			onMoveMedInfo: PropTypes.func,
		}
		// 새로운 MedInfo 생성
		handleCreateMedInfo = async props => {
			const { onGenerateMedInfo, medInfoId } = this.props
			const medInfo = await onGenerateMedInfo(props, medInfoId)

			this.handleInsertMedInfos(medInfo)
		}

		// 새로운 MedInfos 생성
		handleCreateMedInfos = async items => {
			const { onGenerateMedInfo, medInfoId } = this.props
			let medInfos = items.map(async props => {
				return await onGenerateMedInfo(props, medInfoId)
			})

			await this.handleInsertMedInfos(medInfos)
		}

		// 생성된 MedInfo 추가
		handleInsertMedInfos = async (medInfo, index, isFront) => {
			const { medInfos } = this.props
			let children
			if (typeof index === 'number') {
				children = medInfos.slice(0)
				children.splice(index + 1, 0, medInfo)
			} else {
				if (isFront) children = [medInfo, ...medInfos]
				else children = medInfos.concat(medInfo)
			}

			await this.handleUpdateMedInfos(children)
		}

		// MedInfo 삭제하기
		handleDeleteMedInfo = async index => {
			const { medInfos } = this.props
			const children = medInfos.slice(0)
			children.splice(index, 1)

			await this.handleUpdateMedInfos(children)
		}

		// MedInfo 변경하기
		handleUpdateChild = async (props, index, conditionOption) => {
			const { templateMode, medInfos, medInfoIndex } = this.props
			const children = medInfos.slice(0)
			children[index] = {
				...children[index],
				...props,
			}

			if (templateMode !== true) {
				if (conditionOption) {
					await this.handleUpdateMedInfos(children, conditionOption)
				} else if (children[index].medInfoType.conditions) {
					if (
						props.value !== undefined ||
						children[index].medInfoType.inputType === InputType.CHECKBOX.value
					) {
						await this.handleUpdateMedInfos(children, {
							conditions: children[index].medInfoType.conditions,
							option: {
								value: props.value,
								entityId: props.entityId,
								checkedItems: children[index].checkedItems,
								childIndex: index,
								parentIndex: medInfoIndex,
								inputType: children[index].medInfoType.inputType,
							},
						})
					} else {
						await this.handleUpdateMedInfos(children)
					}
				}
			} else {
				await this.handleUpdateMedInfos(children)
			}
		}

		// MedInfo 업데이트
		handleUpdateMedInfos = async (children, conditionOption) => {
			const { onUpdateMedInfo, medInfo, medInfoIndex, format } = this.props

			await onUpdateMedInfo(
				{
					...medInfo,
					children,
					value:
						format && format.includes(DataFieldFormat.SUM.key) // 합계처리 필요할 경우
							? children.reduce((pv, child) => {
									const nvalue = Number(child.value)
									if (isNaN(nvalue) === false) {
										pv = pv + nvalue
									}

									return pv
							  }, 0)
							: medInfo.value,
				},
				medInfoIndex,
				conditionOption
			)
		}

		//하위 MedInfoType 생성(Grid Column)
		handleCreateMedInfoType = async columnInfo => {
			const {
				medInfo,
				medInfos,
				onGenerateMedInfo,
				onGenerateMedInfoType,
				medInfoIndex,
				onUpdateMedInfo,
			} = this.props

			const created = await onGenerateMedInfoType(columnInfo.medInfoType)
			created.parentTypeId = medInfo.medInfoType.id

			const children = medInfos.slice(0)
			for (const row of children) {
				row.children = row.children.concat(
					await onGenerateMedInfo(
						{
							notAddMedInfoType: true,
							medInfoType: columnInfo.medInfoType,
						},
						medInfo.id
					)
				)
			}

			onUpdateMedInfo(
				{
					...medInfo,
					medInfoType: {
						...medInfo.medInfoType,
						childTypes: [
							...(medInfo.medInfoType.childTypes ? medInfo.medInfoType.childTypes : []),
							created,
						],
					},
					children,
				},
				medInfoIndex
			)
		}

		// 하위 MedInfoType 삭제(GridColumn)
		handleDeleteChildType = async id => {
			const { onUpdateMedInfo, medInfos, medInfo, medInfoIndex } = this.props

			const children = medInfos.slice(0)
			for (const row of children) {
				row.children = row.children.filter(i => i.medInfoType.id !== id)
			}

			onUpdateMedInfo(
				{
					...medInfo,
					medInfoType: {
						...medInfo.medInfoType,
						childTypes: medInfo.medInfoType.childTypes.filter(type => type.id !== id),
					},
					children,
				},
				medInfoIndex
			)
		}

		// 하위 MedInfoType 수정(GridColumn)
		handleUpdateChildType = ({ medInfoType }) => {
			const { onUpdateMedInfo, medInfo, medInfos, medInfoIndex, onGenerateMedInfoType } = this.props

			const children = medInfos.slice(0)

			for (const row of children) {
				row.children = row.children.map(i => {
					if (i.medInfoType.id === medInfoType.id) {
						if (medInfoType.inputType !== InputType.GRID_INDI_COLUMN.value) {
							i.medInfoType = medInfoType
						} else {
							i.medInfoType = onGenerateMedInfoType(DEFAULT_MED_INFO_TYPE)
						}
					}

					return i
				})
			}

			onUpdateMedInfo(
				{
					...medInfo,
					medInfoType: {
						...medInfo.medInfoType,
						childTypes: medInfo.medInfoType.childTypes.map(type => {
							if (type.id === medInfoType.id) {
								return medInfoType
							}

							return type
						}),
					},
					children,
				},
				medInfoIndex
			)
		}

		//MedInfo 이동
		handleMoveMedInfo = (source, target, parent) => {
			const { onMoveMedInfo, medInfo, medInfos } = this.props

			if (!parent) {
				onMoveMedInfo(source, target, {
					...medInfo,
					children: medInfos.filter(item => item.id !== source.medInfoId),
				})
			} else {
				onMoveMedInfo(source, target, {
					...medInfo,
					children: medInfos.map(item => {
						if (item.id === parent.id) return parent
						return item
					}),
				})
			}
		}

		// 사이즈 값 결정
		decideSizeInfo = (size, parentSize) => {
			let sizeInfo

			if (size != null) {
				sizeInfo = {
					className: InputSize[size].className,
				}
			} else {
				sizeInfo = {
					className: InputSize[size].className,
				}
			}
			// if (size && parentSize) {
			// 	const { value, text, ...sinfo } = InputSize[size].sectionSize[parentSize]
			// 	sizeInfo = sinfo
			// } else {
			// 	sizeInfo = {
			// 		widescreen: 8,
			// 		largeScreen: 8,
			// 		computer: 16,
			// 		tablet: 16,
			// 		mobile: 16,
			// 	}
			// }

			return sizeInfo
		}

		render() {
			return (
				<WrappedComponent
					{...this.props}
					onUpdateSelf={this.props.onUpdateMedInfo}
					onCreateMedInfo={this.handleCreateMedInfo}
					onInsertMedInfo={this.handleInsertMedInfos}
					onDeleteMedInfo={this.handleDeleteMedInfo}
					onUpdateMedInfo={this.handleUpdateChild}
					onUpdateMedInfos={this.handleUpdateMedInfos}
					onDecideSizeInfo={this.decideSizeInfo}
					onMoveMedInfo={this.handleMoveMedInfo}
					onCreateMedInfoType={this.handleCreateMedInfoType}
					onUpdateChildType={this.handleUpdateChildType}
					onDeleteChildType={this.handleDeleteChildType}
				/>
			)
		}
	}

	return withMedInfos
}
