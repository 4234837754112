import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { withApollo, graphql } from 'react-apollo'
import compose from 'lodash.flowright'

import ResearchRecordChartView from '../components/research/data/ResearchRecordChartView'
import LoadingView from '../components/common/loader'

import { GET_RESEARCH_RECORD_DETAIL } from '../graphql/queries/record'
import { UPDATE_RECORD_MED_INFOS, CREATE_RECORD_TEMPLATE } from '../graphql/mutations/record'

import ErrorView from '../components/common/error/ErrorView'
import { ROUTE_PROJECT_DETAIL_DATA_DETAIL } from '../constants/routes'
import { replaceParams } from '../libs/routeHelper'
import HeaderPageTitle from '../components/common/basic/HeaderPageTitle'
import DescriptionText from '../components/common/basic/DescriptionText'
import { toCommonDateString } from '../libs/dateHelper'
import { getConditions } from '../libs/conditionHelper'

class ResearchRecordChart extends PureComponent {
	handleSaveChart = async (medInfos, options = {}) => {
		const { record, researchId, history, updateRecordMedInfos, refetch } = this.props

		const { screeningValid, screeningFailReason, nextDate, originalMedInfos, onFinish } = options
		try {
			if (originalMedInfos) {
				if (screeningValid !== undefined) {
					try {
						const result = await updateRecordMedInfos({
							variables: {
								recordId: record.id,
								medInfos,
								originalMedInfos,
								screeningValid,
								screeningFailReason,
							},
						})
						await refetch()
						if (screeningValid === true) {
							if (result.data) {
								history.push(
									replaceParams(ROUTE_PROJECT_DETAIL_DATA_DETAIL, {
										id: researchId,
										subId: record.code,
									})
								)
							} else {
								throw new Error()
							}
						} else {
							onFinish()
							alert('저장되었습니다.')
						}
					} catch (e) {
						alert('저장 중 오류가 발생했습니다. 담당자에게 문의 바랍니다.')
					}
				} else if (nextDate) {
					try {
						await updateRecordMedInfos({
							variables: {
								recordId: record.id,
								medInfos,
								originalMedInfos: originalMedInfos,
								nextDate: nextDate,
							},
							update: () => {
								if (onFinish) onFinish()
								alert('저장되었습니다.')
							},
						})
					} catch (e) {
						alert('저장 중 오류가 발생했습니다. 담당자에게 문의 바랍니다.')
					}
				} else {
					await updateRecordMedInfos({
						variables: {
							recordId: record.id,
							medInfos,
							originalMedInfos,
						},
						update: () => {
							if (onFinish) onFinish()
							alert('저장되었습니다.')
						},
					})
				}
			} else {
				await updateRecordMedInfos({
					variables: { recordId: record.id, medInfos },
					update: () => {
						if (onFinish) onFinish()
						alert('저장되었습니다.')
					},
				})
			}
		} catch (e) {
			alert('저장중 오류가 발생했습니다. 관리자에게 문의해주세요.')
		}
	}

	render() {
		const { record, nextSchedule, reservation, loading, error } = this.props

		if (error) {
			return <ErrorView />
		}

		if (record == null && loading === true) {
			return <LoadingView />
		}

		return (
			<div>
				<div>
					<ResearchRecordChartView
						{...this.props}
						{...record}
						conditions={getConditions(record.medInfos)}
						nextSchedule={nextSchedule}
						reservation={reservation}
						onSaveChart={this.handleSaveChart}
					/>
				</div>
			</div>
		)
	}
}

export default compose(
	graphql(GET_RESEARCH_RECORD_DETAIL, {
		options: ({
			match: {
				params: { recordId },
			},
			researchId,
		}) => ({
			variables: {
				recordId: parseInt(recordId),
				researchId,
			},
		}),
		props: ({
			data: { recordDetail, loading, error, refetch },
			ownProps: {
				match: {
					params: { recordId },
				},
				headerRef,
			},
		}) => {
			try {
				if (headerRef.current != null && recordDetail != null) {
					const {
						record: {
							template: { title },
							visitedDate,
						},
					} = recordDetail
					headerRef.current.handleSetLeftContent(
						<div>
							<HeaderPageTitle>{title}</HeaderPageTitle>
							<div>
								<DescriptionText>{toCommonDateString(visitedDate)} </DescriptionText>
							</div>
						</div>
					)
				}
			} catch (e) {
				alert('오류가 발생했습니다.')
			}

			return {
				loading,
				error,
				refetch,
				recordId: parseInt(recordId),
				...recordDetail,
			}
		},
	}),
	graphql(UPDATE_RECORD_MED_INFOS, {
		name: 'updateRecordMedInfos',
	}),
	graphql(CREATE_RECORD_TEMPLATE, {
		name: 'createRecordTemplate',
	})
)(withApollo(withRouter(ResearchRecordChart)))
