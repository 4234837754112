/**
 * [Container] 로그인 화면
 */

import React from 'react'
import PropTypes from 'prop-types'

import { graphql, withApollo } from 'react-apollo'
import compose from 'lodash.flowright'
import { withRouter } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import LoginView from '../components/user/login/LoginView'

import { LOGIN, ADD_USER, UPDATE_USER_INFO } from '../graphql/mutations/user'
import { setUserInfoCookie } from '../libs/cookieHelper'
import { matchErrorCode } from '../libs/errorHelper'
import errorTypes from '../errorTypes'
import { getQueryValue } from '../libs/routeHelper'
import { UserType } from '../enum'
import {
	ROUTE_MY_PATIENTS,
	ROUTE_MY_PROJECTS,
	ROUTE_HOME,
	ROUTE_PATIENT_ONLY_SURVEY,
} from '../constants/routes'

class Login extends React.Component {
	static propTypes = {
		loginRequest: PropTypes.func.isRequired,
		history: PropTypes.shape({
			push: PropTypes.func,
		}).isRequired,
		updateUserInfoRequest: PropTypes.func.isRequired,
		location: PropTypes.shape({
			search: PropTypes.string,
		}).isRequired,
		signUpRequest: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = {
			validationErrors: {},
		}
	}

	handleLoginRequest = async variables => {
		const { loginRequest, history, updateUserInfoRequest, location } = this.props
		try {
			const { data } = await loginRequest({ variables })

			if (data && data.login.user) {
				const redirect = getQueryValue('redirect', location.search)

				await setUserInfoCookie(data.login)

				let redir
				if (!redirect) {
					if (isMobile === true) {
						redir = ROUTE_HOME // TODO: 모바일 환경에서는 우선 홈화면으로 Redirect
					} else if (data.login.user.userType === UserType.DOCTOR.value) {
						redir = ROUTE_MY_PATIENTS
					} else if (data.login.user.userType === UserType.PATIENT.value) {
						redir = ROUTE_PATIENT_ONLY_SURVEY
					} else {
						redir = ROUTE_MY_PROJECTS
					}
				} else {
					redir = redirect
				}

				await updateUserInfoRequest({ variables: { authPayload: data.login } })
				history.push(redir)
			} else {
				alert('아이디 또는 비밀번호가 잘못되었습니다.')
			}
		} catch (error) {
			if (matchErrorCode(error, errorTypes.AUTHENTICATION_FAILED_ERROR)) {
				alert('아이디 또는 비밀번호가 잘못되었습니다.')
			} else if (matchErrorCode(error, errorTypes.ACCOUNT_VALIDATION_WAITING_ERROR)) {
				alert('현재 가입 승인을 대기중입니다. 승인요청은 가입 신청 24시간 이내에 처리됩니다.')
			} else if (matchErrorCode(error, errorTypes.ACCOUNT_VALIDATION_FAILED_ERROR)) {
				alert(
					"입력하신 정보로 신원이 확인되지 않아 사용자 인증에 실패하였습니다. 가입일로부터 일주일 후 입력하신 정보는 삭제됩니다. 자세한 문의는 이메일 또는 화면 최하단의 '문의' 메뉴를 통해 해주세요."
				)
			} else {
				alert('오류가 발생했습니다.')
			}
		}
	}

	handleSignupRequest = async userInfo => {
		const { signUpRequest } = this.props

		try {
			const { data } = await signUpRequest({ variables: userInfo })
			if (data && data.addUser) {
				return true
			}
			return false
		} catch (e) {
			throw e
		}
	}

	render() {
		const { validationErrors } = this.state
		return (
			<LoginView
				onLoginRequest={this.handleLoginRequest}
				onSignupRequest={this.handleSignupRequest}
				validationErrors={validationErrors}
				{...this.props}
			/>
		)
	}
}

export default compose(
	withRouter,
	withApollo,
	graphql(LOGIN, { name: 'loginRequest' }),
	graphql(ADD_USER, { name: 'signUpRequest' }),
	graphql(UPDATE_USER_INFO, { name: 'updateUserInfoRequest' })
)(Login)
