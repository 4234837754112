import React from 'react'
import PropTypes from 'prop-types'
import { CASE_SEX } from '../../constants'

const PatientLabel = ({ sex, age, title }) => {
	return (
		<div
			style={{
				fontSize: title === true ? 16 : 13,
				backgroundColor: '#eee',
				display: 'inline-block',
				padding: title === true ? 10 : 6,
				borderRadius: title === true ? 12 : 5,
				marginRight: title === true ? 20 : 10,
			}}
		>
			{CASE_SEX[sex]} {age}세
		</div>
	)
}

PatientLabel.propTypes = {
	sex: PropTypes.bool,
	age: PropTypes.number,
	title: PropTypes.bool,
}

export default PatientLabel
