/**
 * [Component] 프로필 사진 등에 사용되는 라운드 이미지
 * [Functional]
 *
 * img 태그 아닌 backgroundImage 사용
 */

import React from 'react'
import PropTypes from 'prop-types'

const CircularImage = props => {
  const { url, width, onClick, style } = props
  return (
    <div
      onClick={onClick}
      style={{
        backgroundImage: `url(${url})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'none',
        backgroundSize: 'cover',
        width,
        height: width,
        borderRadius: width,
        ...style,
      }}
    />
  )
}

CircularImage.propTypes = {
  url: PropTypes.string.isRequired,
  width: PropTypes.number,
  style: PropTypes.object,
}

export default CircularImage
