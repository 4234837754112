import { GET_RESEARCH_TAB_ID } from '../../graphql/queries/app'

export const defaults = {
	researchTabId: null,
}

export const resolvers = {
	Mutation: {
		setResearchTabId(_, { id }, { cache }) {
			cache.writeQuery({ query: GET_RESEARCH_TAB_ID, data: { researchTabId: id } })
			return id
		},
	},
}
