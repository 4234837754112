import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import compose from 'lodash.flowright'
import { graphql } from 'react-apollo'

import LoadingView from '../components/common/loader'
import ResearchList from '../components/research/researches/ResearchList'

import { GET_RESEARCHES } from '../graphql/queries/research'
import PageContainer from '../components/common/container/PageContainer'
import SegmentToggle from '../components/common/basic/SegmentToggle'
import InputSearch from '../components/common/basic/InputSearch'
import { searchObject } from '../libs/valueHelper'
import { ROUTE_MY_PROJECTS, ROUTE_NEW_PROJECT } from '../constants/routes'

const SEGMENT_ITEMS = [
	{
		value: 'ACTIVE',
		text: '현재 진행중인',
		itemKey: 'activeResearches',
		key: 'ACTIVE',
	},
	{
		value: 'INACTIVE',
		text: '완료된',
		itemKey: 'finishedResearches',
		key: 'INACTIVE',
	},
]

const BASE_PATH = ROUTE_MY_PROJECTS

/**
 * 내 프로젝트 전체 목록 보기
 */
class MyResearches extends PureComponent {
	static propTypes = {
		history: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
		loading: PropTypes.bool,
		userResearches: PropTypes.shape({
			activeResearches: PropTypes.array,
			finishedResearches: PropTypes.array,
		}),
	}

	state = {
		displayOnly: SEGMENT_ITEMS[0].value,
		searchQuery: '',
	}

	handleSelectFilter = value => {
		this.setState({ displayOnly: value })
	}

	handleChangeSearchQuery = (e, { value }) => {
		this.setState({ searchQuery: value })
	}

	handleResearchClick = id => () => {
		const { history } = this.props
		history.push(`${BASE_PATH}/${id}`)
	}

	handleAddButtonClick = () => {
		const { history } = this.props
		history.push(ROUTE_NEW_PROJECT)
	}

	renderList = userResearches => {
		if (userResearches == null) {
			return <div />
		}
		const { searchQuery, displayOnly } = this.state
		const { itemKey } = SEGMENT_ITEMS.find(i => i.value === displayOnly)
		let researchList = userResearches[itemKey]

		if (searchQuery != '') {
			researchList = searchObject(searchQuery, researchList, ['title', 'organizedBy.name'])
		}
		return (
			<ResearchList
				hasAddButton={displayOnly === 'ACTIVE'}
				onResearchClick={this.handleResearchClick}
				onAddButtonClick={this.handleAddButtonClick}
				researches={researchList}
			/>
		)
	}

	render() {
		const { displayOnly, searchQuery } = this.state
		const { loading, userResearches } = this.props

		let totalCount
		if (userResearches != null) {
			const l1 = userResearches.activeResearches.length
			const l2 = userResearches.finishedResearches.length
			totalCount = l1 + l2
		}

		return (
			<PageContainer fixed small title="내 프로젝트" titleNumber={totalCount}>
				<div>
					<InputSearch
						fluid
						name="search"
						style={styles.InputSearch}
						value={searchQuery}
						onChange={this.handleChangeSearchQuery}
					/>
				</div>
				<SegmentToggleBox>
					<SegmentToggle
						active={displayOnly}
						items={SEGMENT_ITEMS}
						onChange={this.handleSelectFilter}
						itemStyle={styles.segmentToggleItem}
					/>
				</SegmentToggleBox>
				<div>{loading ? <LoadingView /> : <div>{this.renderList(userResearches)}</div>}</div>
			</PageContainer>
		)
	}
}

export default compose(
	withRouter,
	graphql(GET_RESEARCHES, {
		props: ({ data: { error, userResearches, refetch, loading } }) => {
			return {
				loading,
				error,
				userResearches,
				refetch,
			}
		},
	})
)(MyResearches)

const SegmentToggleBox = styled.div`
	text-align: center;
	margin-top: 32px;
	margin-bottom: 32px;
`

const styles = {
	container: {
		marginTop: 10,
		padding: '20px 60px',
	},
	segmentToggleItem: {
		width: 192,
		padding: '10px 0',
	},
	InputSearch: {
		height: 40,
	},
}
