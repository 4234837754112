import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

export default class DDMIntroduce extends PureComponent {
  static propTypes = {}

  render() {
    return <div></div>
  }
}
