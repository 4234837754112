import Hashids from 'hashids'

const hashids = new Hashids()

export const makeRandomKey = length => {
	var result = ''
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@-_!#$%^&*@'
	var charactersLength = characters.length
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength))
	}
	return result
}

export const createHashId = originalId => {
	return hashids.encode(originalId)
}
