import React, { useMemo, useState, useCallback } from 'react'
import styled from 'styled-components'
import { Table, Button } from 'semantic-ui-react'
import { useQuery } from 'react-apollo'
import moment from 'moment'

import PaddedTable from '../../common/basic/PaddedTable'
import { GET_RESEARCH_MED_INFO_TYPES } from '../../../graphql/queries/research'
import TableError from '../../common/error/TableError'
import TableLoader from '../../common/loader/TableLoader'
import { InputType } from '../../../enum'
import InputSearch from '../../common/basic/InputSearch'
import MedInfoTypeModal from '../../charting/modals/MedInfoTypeModal'

export default function ResearchFields(props) {
	const { researchId } = props

	const [isModalOpen, setModalOpen] = useState(false)
	const [modalMedInfoType, setModalMedInfoType] = useState(null)
	const [query, setQuery] = useState('')

	const { data: { researchMedInfoTypes } = {}, loading, error } = useQuery(
		GET_RESEARCH_MED_INFO_TYPES,
		{
			variables: {
				researchId,
			},
		}
	)

	const handleModalOpen = useCallback(medInfoType => {
		setModalOpen(true)
		setModalMedInfoType(medInfoType)
	}, [])

	const handleModalClose = useCallback(() => {
		setModalOpen(false)
		setModalMedInfoType(null)
	}, [])

	const rows = useMemo(() => {
		if (error != null) {
			return <TableError error={error} />
		} else if (loading === true) {
			return <TableLoader />
		} else if (researchMedInfoTypes != null) {
			return researchMedInfoTypes
				.filter(medInfoType => {
					const isInput = InputType[medInfoType.inputType].isNotInput !== true
					const isMatch =
						query === ''
							? true
							: medInfoType.name.includes(query) || medInfoType.typeName.includes(query)
					return isInput && isMatch
				})
				.map(medInfoType => (
					<Table.Row key={`${medInfoType.id}`}>
						<Table.Cell>{medInfoType.name}</Table.Cell>
						<Table.Cell>{medInfoType.typeName}</Table.Cell>
						<Table.Cell textAlign="center">
							<InputTypeContainer>
								{InputType[medInfoType.inputType] && InputType[medInfoType.inputType].text}
							</InputTypeContainer>
						</Table.Cell>
						<Table.Cell textAlign="center">
							<Button primary size="large" onClick={() => handleModalOpen(medInfoType)}>
								보기
							</Button>
						</Table.Cell>
						<Table.Cell textAlign="center">
							<Date>{moment(medInfoType.createdAt).format('YYYY. MM. DD')}</Date>
						</Table.Cell>
					</Table.Row>
				))
		}
	}, [researchMedInfoTypes, loading, error, handleModalOpen, query])

	return (
		<div>
			<SearchBar fluid value={query} onChange={e => setQuery(e.target.value)} />
			<PaddedTable selectable fluid fixed>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell textAlign="center">필드명</Table.HeaderCell>
						<Table.HeaderCell textAlign="center">변수명</Table.HeaderCell>
						<Table.HeaderCell textAlign="center">종류</Table.HeaderCell>
						<Table.HeaderCell textAlign="center">상세 정보</Table.HeaderCell>
						<Table.HeaderCell textAlign="center">등록일</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>{rows}</Table.Body>
			</PaddedTable>
			{modalMedInfoType != null && (
				<MedInfoTypeModal
					open={isModalOpen}
					onClose={handleModalClose}
					medInfoType={modalMedInfoType}
					readOnly
				/>
			)}
		</div>
	)
}

const Date = styled.span`
	color: ${props => props.theme.COLOR_TEXT_LIGHTGRAY};
`

const SearchBar = styled(InputSearch)`
	margin-top: 28px;
	max-width: 300px;
`

const InputTypeContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${props => props.theme.COLOR_PRIMARY};
	border: 1px solid ${props => props.theme.COLOR_PRIMARY};
	border-radius: 2px;
	height: 40px;
`
