import gql from 'graphql-tag'

export const ADD_USER_PRESCRIPTION_TREATMENT = gql`
  mutation addPrescriptionTreatment($name: String, $description: String) {
    addPrescriptionTreatment(name: $name, description: $description, scope: USER) {
      id
      name
      nameChinese
      sourceBookName
      description
    }
  }
`

export const UPDATE_USER_PRESCRIPTION_TREATMENT = gql`
  mutation updatePrescriptionTreatment(
    $id: Int!
    $name: String
    $description: String
    $sourceBookName: String
    $herbs: [HerbInput]
  ) {
    updatePrescriptionTreatment(
      id: $id
      name: $name
      description: $description
      sourceBookName: $sourceBookName
      herbs: $herbs
    ) {
      id
      name
      nameChinese
      sourceBookName
      description
    }
  }
`

export const ADD_USER_ACUPUNCTURE_TREATMENT = gql`
  mutation addAcupunctureTreatment($name: String, $description: String) {
    addAcupunctureTreatment(name: $name, description: $description, scope: USER) {
      id
      name
      description
    }
  }
`

export const UPDATE_USER_ACUPUNCTURE_TREATMENT = gql`
  mutation updateAcupunctureTreatment(
    $id: Int
    $name: String
    $description: String
    $sourceBookName: String
  ) {
    updateAcupunctureTreatment(id: $id, name: $name, description: $description, scope: USER) {
      id
      name
      nameChinese
      sourceBookName
      description
    }
  }
`
