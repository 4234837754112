import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-apollo'
import { GET_CASE } from '../../graphql/queries/case'
import CaseWriter from './CaseWriter'
import LoadingView from '../common/loader'
import { destructCaseText } from '../../libs/caseHelper'

const CaseUpdater = () => {
	const { caseId } = useParams()
	const caseIdNum = parseInt(caseId, 10)
	const { data: { simpleCase } = {}, loading } = useQuery(GET_CASE, {
		variables: {
			id: caseIdNum,
		},
	})

	if (loading === true || simpleCase == null) {
		return '..'
	}

	const charts = JSON.parse(simpleCase.charts)

	return (
		<CaseWriter
			isEditing
			caseId={caseIdNum}
			title={simpleCase.title}
			charts={charts}
			groupId={simpleCase.groupId}
			sex={simpleCase.sex}
			chartNum={simpleCase.chartNum}
			tags={JSON.parse(simpleCase.tags)}
			age={simpleCase.age && simpleCase.age.toString()}
			sharingScope={simpleCase.sharingScope}
		/>
	)
}

CaseUpdater.propTypes = {}

export default CaseUpdater
