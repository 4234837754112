import React, { PureComponent } from 'react'

import { Form, Input, TextArea, Button, Header, Checkbox } from 'semantic-ui-react'
import { ResearcherRole, ResearcherRight, ResearcherStatus } from '../../../../enum'

const styles = {
	inlineLabel: {
		width: 100,
		verticalAlign: 'top',
	},
	inlineContent: {
		display: 'inline-block',
	},
	inlineInput: {
		width: 110,
		verticalAlign: 'middle',
	},
	header: {
		marginBottom: 20,
		paddingBottom: 10,
		borderBottom: '1px solid #eee',
	},
	label: {
		fontWeight: 600,
	},
	rightsContainer: {
		marginTop: 10,
		marginBottom: 15,
	},
	checkbox: {
		marginRight: 10,
		marginBottom: 10,
	},
}

export default class ResearchMemberBasicSettingForm extends PureComponent {
	constructor(props) {
		super(props)
		const { researcherInfo, user } = props
		let role,
			isBlinded,
			description,
			canAccess,
			canChangeSettings,
			canEdit,
			canFixTemplate,
			canInput,
			status

		if (researcherInfo != null) {
			;({
				role,
				isBlinded,
				description,
				canAccess,
				canChangeSettings,
				canEdit,
				canFixTemplate,
				canInput,
				status,
			} = researcherInfo)
		}

		this.state = {
			role,
			isBlinded,
			description,
			canAccess,
			canChangeSettings,
			canEdit,
			canFixTemplate,
			canInput,
			status,
		}
	}
	handleChange = key => (event, { value }) => {
		this.setState({
			[key]: value,
		})
		const { onChange } = this.props
		onChange(key, value)
	}

	handleCheck = key => (event, { checked }) => {
		this.setState({
			[key]: checked,
		})
		const { onChange } = this.props
		onChange(key, checked)
	}
	render() {
		const { canAccess, status, role, isBlinded, description } = this.state
		const { user } = this.props

		const ROLES = Object.values(ResearcherRole).filter(item => item.key !== 'DIRECTOR')
		const STATUS = Object.values(ResearcherStatus)
		const isNotDirector = role !== 'DIRECTOR'
		return (
			<React.Fragment>
				<Form>
					<Form.Select
						inline
						id="research-member-role"
						selection
						options={STATUS}
						value={status}
						placeholder="상태를 선택해주세요"
						label="상태"
						onChange={this.handleChange('status')}
					/>

					{isNotDirector ? (
						<Form.Select
							inline
							id="settings-user-role"
							selection
							options={ROLES}
							value={role}
							placeholder="역할을 선택해주세요."
							label="역할"
							onChange={this.handleChange('role')}
						/>
					) : (
						<Form.Field value="연구 책임자(PI)" disabled control={Input} />
					)}
					<Form.Field
						label="담당 업무"
						control={Input}
						value={description}
						onChange={this.handleChange('description')}
						placeholder="연구 내에서 담당하는 업무에 대한 간단한 설명을 적어주세요."
					/>
					<label style={styles.label}>권한</label>
					<div style={styles.rightsContainer}>
						{Object.keys(ResearcherRight).map(key => {
							let disabled, checked
							;({ [key]: checked } = this.state)

							if (
								(key === 'canEdit' && canAccess !== true) ||
								(isNotDirector === false && key === 'canChangeSettings')
							) {
								disabled = true
								if (key === 'canEdit') {
									checked = false
								}
							}

							return (
								<Checkbox
									key={key}
									style={styles.checkbox}
									onChange={this.handleCheck(key)}
									checked={checked}
									disabled={disabled}
									label={ResearcherRight[key].text}
								/>
							)
						})}
					</div>
					{/* <Checkbox
						style={styles.checkbox}
						onChange={this.handleCheck('isBlinded')}
						checked={isBlinded}
						label="눈가림 설정"
					/> */}
				</Form>
			</React.Fragment>
		)
	}
}
