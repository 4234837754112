import React from 'react'
import { Pagination as SemanticPagination } from 'semantic-ui-react'
import LeftIcon from '../../../assets/images/icons/left-pagination.svg'
import RightIcon from '../../../assets/images/icons/right-pagination.svg'
// import LeftIcon from '../../../assets/images/icons/left.svg'
// import LeftIcon from '../../../assets/images/icons/left.svg'

const Pagination = props => {
  return (
    <SemanticPagination
      firstItem={{
        content: <img alt="left" src={LeftIcon} style={{ width: 12 }} />,
      }}
      lastItem={{
        content: <img alt="left" src={RightIcon} style={{ width: 12 }} />,
      }}
      prevItem={null}
      nextItem={null}
      boundaryRange={0}
      siblingRange={15}
      {...props}
    />
  )
}

export default Pagination
