import styled from 'styled-components'
import { Modal } from 'semantic-ui-react'
import { BORDER_GRAY, ELLIPSIS_VERTICAL_WIDTH } from '../../../constants/styles'

/**
 * Full Screen
 */

export const FullScreenBox = styled.div`
	position: fixed;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	background: white;
`

export const ImageBox = styled.div

/**
 * Flex Boxes
 */
export const FlexDiv = styled.div`
	display: flex;
	width: 100%;
	align-items: stretch;
`

export const FlexFill = styled.div`
	flex: 1;
`

export const AlignCenteredFlexDiv = styled(FlexDiv)`
	align-items: center;
`

export const VerticalFlex = styled.div`
	display: flex;
	flex-direction: column;
`

export const FullVerticalFlex = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
`

export const FullCenteredVerticalFlexDiv = styled(FullVerticalFlex)`
	align-items: center;
	justify-content: center;
`

export const CenteredRowFlexDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`

export const JustifyFlexEnd = styled.div`
	justify-content: flex-end;
	display: flex;
`

/**
 * Side Box
 */

export const CloseIconBox = styled.div`
	right: 0px;
	top: 0px;
	padding: 10px;
	position: absolute;
`

/**
 * Side Menu Container
 */

export const SideMenuContainer = styled(VerticalFlex)`
	position: fixed;
	top: 0px;
	bottom: 0px;
	height: 100vh;
	transition: left 0.3s, right 0.3s;
	width: ${props => props.width || 250}px;
	background: white;
	${props =>
		props.left === true
			? `
			left: ${props.isOpen === true ? 0 : -250}px;
			border-right: ${BORDER_GRAY};
			`
			: `
			border-left: ${BORDER_GRAY};
			right: ${props.isOpen === true ? 0 : -250}px;
	`};
	z-index: 400;
`

export const BottomButtonContainer = styled(JustifyFlexEnd)`
	flex: 1;
	align-items: flex-end;
	flex-direction: column;
	padding-bottom: 15px;
`

export const BottomButtonWrapper = styled.div`
	width: 100%;
	padding: 5px 20px;
`

export const Dimmer = styled.div`
	position: fixed;
	top: 0px;
	left: 0px;
	background: rgba(0, 0, 0, 0.3);
	height: 100vh;
	width: 100vw;
	z-index: 300;
`

/**
 * Patient Survey
 */

export const PatientSurveyQuestionList = styled.div`
	padding-top: 50px;
`

/**
 * Styled Boxes
 */
export const ShadowedBox = styled.div`
	box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
	border: ${BORDER_GRAY};
`

/**
 * Button Box
 */
export const SubmitButtonBox = styled.div`
	text-align: right;
	margin-top: 30px;
`

/**
 * Tables
 */

export const EllipsisCell = styled.td`
	width: ${ELLIPSIS_VERTICAL_WIDTH}px;

	table tr & {
		padding: 0 !important;
	}
`

/**
 * Modals
 */
export const CenteredModalActions = styled(Modal.Actions)`
	text-align: center !important;
	padding-right: 0px !important;
`

/**
 * Header
 */

export const HeaderBaseContent = styled.div`
	border-right: ${BORDER_GRAY};
	height: 100%;
	display: flex;
	padding: 5px 20px 5px 10px;
	justify-content: start;
	align-items: center;
	flex-direction: row;
	margin-right: 30px;
`

export const HeaderDetailContent = styled.div`
	flex: 1;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: start;

	padding: 0px 10px;
	flex-direction: column;
`

export const FadeInContainer = styled.div`
	height: ${props => (props.isVisible ? 'auto' : '0px')};
	opacity: ${props => (props.isVisible ? 1 : 0)};

	transition: opacity 0.2s;
`
