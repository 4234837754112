import { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

class ScrollToTopComp extends PureComponent {
	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			const fd = this.props.contentRef.current

			if (fd != null) {
				fd.scrollTop = 0
			}
		}
	}

	render() {
		return this.props.children
	}
}
// prop types 추가
ScrollToTopComp.propTypes = {
	children: PropTypes.object,
	location: PropTypes.object,
}

export default withRouter(ScrollToTopComp)
