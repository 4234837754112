import React from 'react'
import PageContainer from '../components/common/container/PageContainer'
import Image404 from '../assets/images/drawings/404-error.svg'
import { COLOR_TEXT_LIGHTGRAY } from '../constants/styles'

const Page404 = () => {
	return (
		<PageContainer fluid hasMobile>
			<div style={{ textAlign: 'center' }}>
				<img alt="찾으시는 페이지가 없습니다" src={Image404} style={{ width: 400 }} />
				<div style={{ fontSize: 14, color: COLOR_TEXT_LIGHTGRAY }}>찾으시는 페이지가 없습니다.</div>
			</div>
		</PageContainer>
	)
}

Page404.propTypes = {}

export default Page404
