import React, { PureComponent } from 'react'
import { Transition, Message } from 'semantic-ui-react'

export default WrappedComponent => {
	class hocComponent extends PureComponent {
		static propTypes = {}

		constructor(props) {
			super(props)

			this.state = {
				toastMessage: null,
				headerMessage: null,
			}
		}

		handleToastMessage = options => {
			this.setState({ toastMessage: options })
			if (options.autoDismiss !== false) {
				setTimeout(() => this.setState({ toastMessage: null }), 3000)
			}
		}

		handleHeaderMessage = options => {
			this.setState({ headerMessage: options })
			if (options.autoDismiss !== false) {
				setTimeout(() => this.setState({ headerMessage: null }), 3000)
			}
		}

		render() {
			const { toastMessage, headerMessage } = this.state
			return (
				<React.Fragment>
					<WrappedComponent
						{...this.props}
						onShowToastMessage={this.handleToastMessage}
						onShowHeaderMessage={this.handleHeaderMessage}
					/>
					<Transition
						visible={toastMessage != null ? true : false}
						animation="scale"
						duration={500}
					>
						<Message
							style={{
								position: 'fixed',
								bottom: 100,
								left: '50%',
							}}
							floating
							compact
							{...toastMessage}
						/>
					</Transition>
					<Transition
						visible={headerMessage != null ? true : false}
						animation="scale"
						duration={500}
					>
						<div
							style={{
								position: 'fixed',
								top: 60,
								width: '100%',
								left: 0,
								zIndex: 150,
							}}
						>
							<Message
								hidden={headerMessage == null}
								color={headerMessage != null ? headerMessage.color : null}
								onDismiss={this.handleDismissMessage}
								success={headerMessage != null && headerMessage.success}
								error={headerMessage != null && headerMessage.error}
								primary={headerMessage != null && headerMessage.primary}
							>
								<Message.Header>{headerMessage != null && headerMessage.title}</Message.Header>
								<p>{headerMessage != null && headerMessage.content}</p>
							</Message>
						</div>
					</Transition>
				</React.Fragment>
			)
		}
	}
	return hocComponent
}
