import gql from 'graphql-tag'
import {
	SYMPTOM_TYPE_FRAGMENT,
	PATIENT_FRAGMENT,
	PATIENT_RESEARCH_FRAGMENT,
	PATIENT_RESEARCH_BASIC_FRAGMENT,
	RESEARCH_PATIENT_FRAGMENT,
} from '../fragments/patient'
import { RECORD_FRAGMENT } from '../fragments/record'
import { RESEARCH_FRAGMENT } from '../fragments/research'
import { STAT_RESULT_FRAGMENT } from '../fragments/statistics'
import { DEFAULT_PATIENT_RECORDS_PER_PAGE } from '../../constants'

/*------------------------------ 목록 불러오기 ---------------------------------*/

// 사용자 환자 목록 불러오기
export const GET_DOCTOR_PATIENTS = gql`
	query getDoctorPatients($pagination: PatientPagination!, $filter: ListFilter) {
		doctorPatients(pagination: $pagination, filter: $filter) {
			totalCount
			perPage
			page
			items {
				...PatientParts
				lastRecordId
				symptomType {
					image
				}
				patientResearches {
					research {
						id
						title
						samplingPlan
						type
						cohortType
					}
					code
				}
				updatedAt
			}
		}
	}
	${PATIENT_FRAGMENT}
`

// 연구 대상 환자 목록 불러오기
export const GET_RESEARCH_PATIENTS = gql`
	query getResearchPatients(
		$researchId: Int!
		$pagination: PatientPagination!
		$filter: ListFilter
	) {
		researchPatients(researchId: $researchId, pagination: $pagination, filter: $filter) {
			totalCount
			perPage
			page
			items {
				...ResearchPatientParts
			}
		}
	}
	${RESEARCH_PATIENT_FRAGMENT}
`

// 연구 대상 환자 목록 불러오기
export const GET_RESEARCH_PATIENTS_FOR_SURVEY = gql`
	query getResearchPatientsForSurvey(
		$researchId: Int!
		$pagination: PatientPagination!
		$filter: ListFilter
	) {
		researchPatients(researchId: $researchId, pagination: $pagination, filter: $filter) {
			totalCount
			perPage
			page
			items {
				id
				name
				patientCode
				sexMale
				birthDate
				patientResearches {
					code
				}
			}
		}
	}
`

/*------------------------------ 상세정보 불러오기 ---------------------------------*/

// 환자 상세 정보 가져오기
export const GET_PATIENT_DETAIL_BY_ID = gql`
	query getPatientDetailById($id: Int!, $researchId: Int, $recordFilter: ListFilter) {
		patient(id: $id, researchId: $researchId) {
			...PatientParts
			registerer {
				id
				name
				institution {
					id
					name
				}
				researcherInfo {
					code
				}
			}
			patientResearches {
				...PatientResearchBasicParts
			}
			symptomType {
				...SymptomTypePart
			}
			updatedAt
			createdAt
			recordCount
			lastRecordId
		}

		symptomTypes {
			...SymptomTypePart
		}

    patientRecords(pagination: {
      perPage: ${DEFAULT_PATIENT_RECORDS_PER_PAGE},
    },
		filter: $recordFilter,
		patientId: $id,
		researchId: $researchId,
		) {
      items {
        ...RecordParts
				creator {
					id
					name
				}
        template {
          id
          title
          type
          job
          shouldReserve
        }
        researches {
          id
          title
          type
        }
      }
      perPage
      totalCount
    }
  }
  ${RECORD_FRAGMENT}
  ${PATIENT_FRAGMENT}
	${SYMPTOM_TYPE_FRAGMENT}
	${PATIENT_RESEARCH_BASIC_FRAGMENT}
`

// 환자 상세 정보 가져오기
export const GET_PATIENT_BY_ID = gql`
	query getPatientById($id: Int!) {
		patient(id: $id) {
			...PatientParts
			registerer {
				id
			}
			patientResearches {
				...PatientResearchBasicParts
			}
			symptomType {
				...SymptomTypePart
			}
			updatedAt
			createdAt
			recordCount
			lastRecordId
		}
	}
	${PATIENT_FRAGMENT}
	${SYMPTOM_TYPE_FRAGMENT}
	${PATIENT_RESEARCH_BASIC_FRAGMENT}
`

// 연구 환자 상세정보 불러오기
export const GET_RESEARCH_PATIENT_DETAIL = gql`
	query getResearchPatientDetail($researchId: Int!, $patientId: Int!) {
		patient(researchId: $researchId, id: $patientId) {
			...ResearchPatientParts
		}
	}
	${RESEARCH_PATIENT_FRAGMENT}
`

export const GET_PATIENT_STATS = gql`
	query getPatientSymptomProgress($patientId: Int!) {
		patientSymptomProgress(patientId: $patientId) {
			visitedDate
			symptoms {
				name
				value
			}
		}
	}
`

/*------------------------------ 세부정보 불러오기 ---------------------------------*/

// 환자 세부정보 열람
export const GET_PATIENT_PERSONAL_INFO = gql`
	query getPatientPersonalInfo($id: Int) {
		patientPersonalInfo(id: $id) {
			name
			patientCode
			birthDate
			isHashed
			patientResearches {
				code
				status
				phase {
					id
					title
				}
				nextTemplate {
					id
					job
					title
					doneBy
				}
				address
				nameInitial
				phone
				birthDateReal
				inOut
				research {
					id
					patientBasicInfo
				}
			}
		}
	}
`

// 연구대상자 세부정보 열람
export const GET_RESEARCH_PATIENT_PERSONAL = gql`
	query getResearchPatientPersonalInfo($patientCode: String!, $researchId: Int!) {
		researchPatientPersonal(researchId: $researchId, patientCode: $patientCode) {
			name
			sexMale
			patientCode
			birthDate
			registerer {
				id
				name
				institution {
					id
					name
				}
			}
			patientResearches {
				code
				status
				phase {
					id
					title
				}
				nextTemplate {
					job
					title
					doneBy
				}
				address
				nameInitial
				phone
				birthDateReal
				inOut
				research {
					id
					patientBasicInfo
					organizedBy {
						id
						name
					}
				}
			}
		}
	}
`

// 연구 대상자 통계
export const GET_RESEARCH_PATIENT_STATS = gql`
	query getResearchPatientStats($researchId: Int!) {
		researchStatistics(
			researchId: $researchId
			requests: [{ type: "PATIENT_GENDER_RATIO" }, { type: "PATIENT_AGE" }]
		) {
			...StatResultParts
		}
	}
	${STAT_RESULT_FRAGMENT}
`

// 기관 환자 검색
export const GET_INSTITUTION_RESEARCH_PATIENTS = gql`
	query getInstitutionResearchPatients(
		$institutionId: Int
		$researchId: Int!
		$pagination: PatientPagination!
		$filter: ListFilter
	) {
		institutionPatients(
			researchId: $researchId
			institutionId: $institutionId
			pagination: $pagination
			filter: $filter
		) {
			totalCount
			perPage
			page
			items {
				id
				name
				patientCode
				sexMale
				birthDate
				createdAt
				patientResearches {
					code
				}
			}
		}
	}
`
