/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { useMutation } from 'react-apollo'
import { Button } from 'semantic-ui-react'
import {
	NotificationEventEntityType,
	InvitationStatus,
	NotificationEntityType,
	NotificationReadStatus,
} from '../../../enum'
import { replaceParams } from '../../../libs/routeHelper'
import {
	ROUTE_PROJECT_DETAIL_DEFAULT,
	ROUTE_PATIENT_DETAIL_RECORD,
	ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL,
	ROUTE_CASE_DETAIL,
} from '../../../constants/routes'
import LinkColored from '../../common/basic/LinkColored'
import {
	ACCEPT_RESEARCH_INVITATION,
	DECLINE_RESEARCH_INVITATION,
} from '../../../graphql/mutations/research'
import DescriptionText from '../../common/basic/DescriptionText'
import { toLocalizedDateString } from '../../../libs/dateHelper'
import { COLOR_TEXT_LIGHTGRAY } from '../../../constants/styles'
import { MARK_NOTIF_AS_READ } from '../../../graphql/mutations/notification'
import StatusDot from '../../common/StatusDot'
import { GET_USER_ACTIVE_RESEARCHES_FOR_LIST } from '../../../graphql/queries/research'
import useUpdateResearchSideMenu from '../../../hooks/userUpdateResearchSideMenu'

/**
 * 알림 개별 을
 */
const NotificationItem = props => {
	const {
		currentUserId,
		refetchCount,
		history,
		event,
		entity,
		id,
		userReadStatus,
		createdAt,
	} = props

	const onUpdate = () => {
		refetchCount()
	}

	let actor
	let eventEntity
	let actorName
	let eventEntityType
	let eventType
	let entityType
	let link
	let onClickOptions

	// 읽음 상태로 전환 Mutation
	const [updateReadStatus] = useMutation(MARK_NOTIF_AS_READ, {
		variables: {
			id,
			readStatus: NotificationReadStatus.READ.value,
		},
		update: onUpdate,
	})

	// Entity, Event Entity 항목 정리
	if (event != null) {
		;({ actor, entity: eventEntity } = event)
		if (actor != null) {
			;({ name: actorName } = actor)
		}

		if (eventEntity != null) {
			;({ __typename: eventEntityType } = eventEntity)
		}

		eventType = NotificationEventEntityType[eventEntityType]
	}

	if (entity != null) {
		const { __typename: entityTypeName } = entity
		entityType = NotificationEntityType[entityTypeName]
	}

	const isUnread = userReadStatus !== NotificationReadStatus.READ.value

	// 렌더링 후 항목이 삭제되었는데 아직 읽지 않은 상태라면, 읽음으로 전환
	useEffect(() => {
		if (id != null && isUnread === true) {
			if (eventType == null || entityType == null) {
				updateReadStatus()
			}
		}
	}, [entityType, eventType, id, isUnread, updateReadStatus])

	if (eventType == null || entityType == null) {
		return <NoEntity />
	}

	const { entityKey, sentence } = eventType

	const eventEntityObject = eventEntity[entityKey]
	const sentenceSubject = (
		<span>
			<b>{actorName}</b> 님이{' '}
		</span>
	)
	const sentenceWhere = entityType.sentence

	// 진료기록에 댓글
	if (entityType.value === NotificationEntityType.Record.value) {
		link = replaceParams(ROUTE_PATIENT_DETAIL_RECORD, {
			id: entity.patientId,
			subId: entity.id,
		})
	} else if (entityType.value === NotificationEntityType.SimpleCase.value) {
		// 케이스에 댓글
		link = replaceParams(ROUTE_CASE_DETAIL, {
			caseId: entity.id,
		})
	} else if (entityType.value === NotificationEntityType.Comment.value) {
		// 댓글에 대댓글
		const commentEntity = entity.entity
		if (commentEntity == null) {
			return <NoEntity />
		}

		const { __typename: commentEntityType } = commentEntity

		if (commentEntityType === 'Record') {
			const { id: recordId, patientId, creator, researches } = commentEntity

			if (creator.id === currentUserId) {
				link = replaceParams(ROUTE_PATIENT_DETAIL_RECORD, {
					id: recordId,
					subId: patientId,
				})
			} else if (researches != null) {
				const pid = researches[0].id
				link = replaceParams(ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL, {
					pid,
					id: patientId,
					subId: id,
				})
			} else {
				return <NoEntity />
			}
		} else if (commentEntityType === 'SimpleCase') {
			const { id: caseId } = commentEntity
			link = replaceParams(ROUTE_CASE_DETAIL, {
				caseId,
			})
		}
	}

	if (eventType.value === NotificationEventEntityType.Comment.value) {
		link += `?comment=${eventEntity.id}`
	}

	if (link != null) {
		onClickOptions = {
			onClick: async () => {
				if (isUnread === true) {
					updateReadStatus()
				}
				history.push(link)
			},
		}
	}

	return (
		<ItemContainer {...onClickOptions}>
			<SentenceContainer>
				<Sentence>
					{sentenceSubject}
					{sentenceWhere + sentence}
				</Sentence>
				{isUnread === true && (
					<StatusInfo>
						<StatusDot size="mini" color="teal" />
					</StatusInfo>
				)}
				<DateInfo>{toLocalizedDateString(createdAt)}</DateInfo>
			</SentenceContainer>
			<div>
				<EntityInfo>
					{renderDetail({
						entityKey,
						eventEntityObject,
						sentenceWhere,
						sentenceSubject,
						sentence,
						eventEntity,
						eventEntityType,
						history,
						entityType,
						entity,
						createdAt,
						notifId: id,
						onRead: updateReadStatus,
					})}
				</EntityInfo>
			</div>
		</ItemContainer>
	)
}

NotificationItem.propTypes = {
	refetch: PropTypes.func,
	refetchCount: PropTypes.func,
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
	event: PropTypes.shape({
		__typename: PropTypes.string,
	}),
	entity: PropTypes.shape({
		__typename: PropTypes.string,
		patientId: PropTypes.number,
		id: PropTypes.number,
		entity: PropTypes.shape({
			__typename: PropTypes.string,
			id: PropTypes.number,
			patientId: PropTypes.number,
			creator: PropTypes.shape({
				id: PropTypes.number,
			}),
			researches: PropTypes.array,
		}),
	}),
	id: PropTypes.number,
	userReadStatus: PropTypes.string,
	createdAt: PropTypes.string,
	currentUserId: PropTypes.number,
}

export default withRouter(NotificationItem)

/**
 * 종류별로 항목 렌더링
 */
const renderDetail = props => {
	const { eventEntityType } = props

	let Comp
	if (eventEntityType === NotificationEventEntityType.ResearchInvitation.value) {
		Comp = NotifResearchInvitation
	} else if (eventEntityType === NotificationEventEntityType.Comment.value) {
		Comp = NotifComment
	}

	if (Comp != null) {
		return <Comp {...props} />
	}

	return null
}

/**
 * 연구 초대 알림
 */
const NotifResearchInvitation = ({ history, eventEntity, eventEntityObject, notifId, onRead }) => {
	const { id: researchId, title } = eventEntityObject

	const updateResearchSideMenu = useUpdateResearchSideMenu()

	const { status: inviteStatus, id: invitationId } = eventEntity

	const [acceptInvitation] = useMutation(ACCEPT_RESEARCH_INVITATION, {
		variables: {
			invitationId,
			notifId,
			readStatus: NotificationReadStatus.READ.value,
		},
		onCompleted: async () => {
			await updateResearchSideMenu(researchId, true, history)
		},
	})

	const [declineInvitation] = useMutation(DECLINE_RESEARCH_INVITATION, {
		variables: {
			invitationId,
			notifId,
			readStatus: NotificationReadStatus.READ.value,
		},
	})

	const update = async isAccept => {
		await onRead()
		if (isAccept === true) {
			acceptInvitation()
		} else {
			declineInvitation()
		}
	}

	return (
		<>
			<Content>
				<LinkColored
					to={replaceParams(ROUTE_PROJECT_DETAIL_DEFAULT, {
						id: researchId,
					})}
				>
					{title}
				</LinkColored>
			</Content>
			<Actions>
				{inviteStatus === InvitationStatus.INVITED.value ? (
					<React.Fragment>
						<Button
							primary
							onClick={() => {
								update(true)
							}}
						>
							수락
						</Button>
						<Button
							onClick={() => {
								update(false)
							}}
						>
							거절
						</Button>
					</React.Fragment>
				) : (
					<DescriptionText>{InvitationStatus[inviteStatus].text}</DescriptionText>
				)}
			</Actions>
		</>
	)
}

NotifResearchInvitation.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func,
	}).isRequired,
	eventEntity: PropTypes.shape({
		id: PropTypes.number,
		status: PropTypes.string,
	}),
	eventEntityObject: PropTypes.shape({
		id: PropTypes.number,
		title: PropTypes.string,
	}),
	notifId: PropTypes.number,
	onRead: PropTypes.func,
}

const NotifComment = props => {
	const { eventEntity } = props

	return (
		<div>
			<EntityInfo>&quot;{eventEntity.text}&quot;</EntityInfo>
		</div>
	)
}

NotifComment.propTypes = {
	eventEntity: PropTypes.shape({
		text: PropTypes.string,
	}),
}

const NoEntity = () => {
	return (
		<ItemContainer>
			<DescriptionText>항목이 삭제되었습니다.</DescriptionText>
		</ItemContainer>
	)
}
/**
 * Styles
 */

const ItemContainer = styled.div`
	cursor: ${props => (props.onClick == null ? 'auto' : 'pointer')};
	padding: 10px;
	display: flex;
	flex-direction: column;
`

const EntityInfo = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 5px;
`

const Content = styled.div`
	flex: 1;
`

const Actions = styled.div`
	width: 180px;
	text-align: right;
`

const SentenceContainer = styled.div`
	display: flex;
	flex-direction: row;
`

const Sentence = styled.div`
	flex: 1;
`
const DateInfo = styled.div`
	color: ${COLOR_TEXT_LIGHTGRAY};
	width: 100px;
`
const StatusInfo = styled.div`
	width: 20px;
	height: 100%;
`
