import React, { useState } from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CaseSideMenu from './CaseSideMenu'
import InputSearch from '../common/basic/InputSearch'
import { PAGE_MOBILE_MAX_WIDTH, PAGE_NOT_MOBILE_MIN_WIDTH } from '../../constants/styles'
import CaseWriter from './CaseWriter'
import {
	ROUTE_CASES,
	ROUTE_CASES_UPDATE,
	ROUTE_CASES_WRITE,
	ROUTE_CASE_BOOKS,
	ROUTE_CASE_DETAIL,
	ROUTE_CASE_GROUP,
	ROUTE_CASE_SEARCH_TYPE,
	ROUTE_CASE_TAG,
	ROUTE_CASE_USER,
} from '../../constants/routes'
import CaseDetail from './CaseDetail'
import ToastMessages from '../app/ToastMessages'
import CaseUpdater from './CaseUpdater'
import { ExceptMobile } from '../common/basic/ResponsiveWrappers'
import useUserInfo from '../../hooks/useUserInfo'
import { UserStatus } from '../frame/header/UserStatus'
import MyCases from './MyCases'
import BookCases from './BookCases'
import GroupCases from './GroupCases'
import NewsFeed from './NewsFeed'
import TagCases from './TagCases'
import CaseSearch from './CaseSearch'

const CasesHome = () => {
	const [isMobilePopperOpen, setMobilePopper] = useState(false)
	const toggleMobilePopper = () => setMobilePopper(!isMobilePopperOpen)
	const { user } = useUserInfo()

	return (
		<div>
			<MobileHeader>
				<Switch>
					<Route exact path={ROUTE_CASES_WRITE}>
						<MobileSimpleHeader>새로운 케이스 작성</MobileSimpleHeader>
					</Route>
					<Route path={ROUTE_CASES_UPDATE}>
						<MobileSimpleHeader>케이스 업데이트</MobileSimpleHeader>
					</Route>
					<Route path="/cases">
						<div
							style={{
								display: isMobilePopperOpen === true ? 'none' : 'flex',
								flexDirection: 'row',
								background: 'white',
							}}
						>
							<Link
								to={ROUTE_CASES}
								style={{
									display: 'block',
									padding: 20,
									paddingRight: 0,
									fontSize: 30,
								}}
							>
								<span role="img" aria-label="hah">
									&#127794;
								</span>{' '}
							</Link>
							<MobileHeaderInput onClick={toggleMobilePopper} />
						</div>
					</Route>
				</Switch>
			</MobileHeader>
			<CaseSideMenu
				isMobilePopperOpen={isMobilePopperOpen}
				toggleMobilePopper={toggleMobilePopper}
			/>
			<Content>
				<ExceptMobile
					style={{ height: 80, padding: 20, display: 'flex', justifyContent: 'flex-end' }}
				>
					{user && (
						<UserStatus image={user.profileImage} name={user.nickname} nickname={user.nickname} />
					)}
				</ExceptMobile>
				<ContentWrap>
					<Switch>
						<Route exact path={ROUTE_CASES_WRITE} component={CaseWriter} />
						<Route path={ROUTE_CASES_UPDATE} component={CaseUpdater} />
						<Route path={ROUTE_CASE_USER} component={MyCases} />
						<Route path={ROUTE_CASE_BOOKS} component={BookCases} />
						<Route path={ROUTE_CASE_GROUP} component={GroupCases} />
						<Route path={ROUTE_CASE_TAG} component={TagCases} />
						<Route path={ROUTE_CASE_SEARCH_TYPE} component={CaseSearch} />
						<Route path={ROUTE_CASE_DETAIL} component={CaseDetail} />
						<Route path="/cases" component={NewsFeed} />
					</Switch>
				</ContentWrap>
			</Content>
			<ToastMessages />
		</div>
	)
}

CasesHome.propTypes = {}

export default CasesHome

const Content = styled.div`
	/** Not Mobile */
	@media (min-width: ${PAGE_NOT_MOBILE_MIN_WIDTH}px) {
		padding-left: 340px;
	}

	/** Mobile */
	@media (max-width: ${PAGE_MOBILE_MAX_WIDTH}px) {
		padding-top: 40px;
	}
`

const ContentWrap = styled.div`
	/** Not Mobile */
	@media (min-width: ${PAGE_NOT_MOBILE_MIN_WIDTH}px) {
		padding: 30px 50px;
	}

	/** Mobile */
	@media (max-width: ${PAGE_MOBILE_MAX_WIDTH}px) {
		padding: 0px 15px;
	}
`

const MobileHeaderInput = ({ onClick }) => {
	return (
		<div style={{ flex: 1, padding: '10px 20px' }} onClick={onClick}>
			<InputSearch placeholder="동의보감 케이스 검색" fluid />
		</div>
	)
}

MobileHeaderInput.propTypes = {
	onClick: PropTypes.func,
}

const MobileHeader = styled.div`
	display: none;
	@media (max-width: ${PAGE_MOBILE_MAX_WIDTH}px) {
		display: block;
		left: 0px;
		top: 0px;
		position: fixed;
		width: 100%;
		height: 60px;
		z-index: 30;
	}
`
const MobileSimpleHeader = styled.div`
	text-align: center;
	background: white;
	font-size: 16px;
	margin-bottom: 10px;
	height: 50px;
	line-height: 50px;
	font-weight: bold;
	border-bottom: 1px solid black;
`
