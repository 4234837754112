import React from 'react'

const T1 = ({ className, style, children }) => {
	return (
		<div className={`${className} t1`} style={style}>
			{children}
		</div>
	)
}

export default T1
