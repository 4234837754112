import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Table, Icon } from 'semantic-ui-react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { replaceParams } from '../../../libs/routeHelper'
import {
	ROUTE_PROJECT_DETAIL_ISSUES_WRITE,
	ROUTE_PROJECT_DETAIL_ISSUE_DETAIL,
} from '../../../constants/routes'
import DataTable from '../../common/DataTable'
import { GET_RESEARCH_ISSUES } from '../../../graphql/queries/research'
import PageContainer from '../../common/container/PageContainer'
import SimpleLabel from '../../common/basic/SimpleLabel'
import UserInline from '../../common/UserInline'
import DateInfo from '../../common/basic/DateInfo'

const styles = {
	section: {
		width: '80%',
		margin: '10px auto',
		// height: '100vh'
		color: '#4d4d4d',
	},
	issue: {
		fontSize: '14px',
		fontWeight: 'bold',
		lineHeight: '1.21',
		textAlign: 'left',
	},
	time: {
		width: '32px',
		height: '17px',
		textAlign: 'right',
		fontSize: '14px',
		color: '#919191',
	},
	doctor: {
		textAlign: 'left',
		fontSize: '14px',
		color: '#626262',
		marginRight: '50px',
	},
	newIssue: {
		marginTop: '25px',
		marginBottom: '20px',
	},
	searchField: {
		textAlign: 'right',
		position: 'relative',
		justifyContent: 'flex-end',
		display: 'flex',
		paddingRight: '10px',
		marginBottom: '-20px',
	},
	elForm: {
		fontSize: '17px',
		textAlign: 'center',
		marginLeft: '-220px',
		backgroundColor: '#66cbd8',
		borderRadius: '1px',
		color: '#ffffff',
		height: '26px',
		width: '53px',
	},
	elComment: {
		fontSize: '11px',
		color: '#919191',
		fontWeight: 'normal',
	},
}

const PAGE_TITLE = '게시판'

class ResearchIssues extends PureComponent {
	static propTypes = {
		researchId: PropTypes.number.isRequired,
		history: PropTypes.shape({
			push: PropTypes.func,
		}).isRequired,
		location: PropTypes.shape({
			search: PropTypes.string,
		}).isRequired,
	}

	handleClickAddButton = () => {
		const { history, researchId } = this.props
		history.push(
			replaceParams(ROUTE_PROJECT_DETAIL_ISSUES_WRITE, {
				id: researchId,
			})
		)
	}

	handleClickRow = ({ id }) => {
		const { history, researchId } = this.props
		history.push(
			replaceParams(ROUTE_PROJECT_DETAIL_ISSUE_DETAIL, {
				id: researchId,
				subId: id,
			})
		)
	}

	render() {
		const { researchId, breadCrumbs } = this.props

		return (
			<DataTable
				hasSearch
				searchPlaceholder="검색"
				render={({ rendered, totalCount }) => {
					return (
						<PageContainer
							fixed
							title={PAGE_TITLE}
							breadCrumbs={breadCrumbs}
							titleNumber={totalCount}
						>
							{rendered}
						</PageContainer>
					)
				}}
				query={GET_RESEARCH_ISSUES}
				defaultVars={{
					id: researchId,
				}}
				onAddItem={this.handleClickAddButton}
				queryName="issues"
				addButtonTitle="새로운 글"
				hasWidgetAddButton
				emptyMessage="등록된 글이 없습니다."
				onClickRow={this.handleClickRow}
				columns={[
					{
						text: '분류',
						key: 'type.name',
						value: 'typeName',
						uiOptions: {
							textAlign: 'center',
							style: { width: 120 },
						},
						render: ({ type }) => {
							return <SimpleLabel>{type.name || '없음'}</SimpleLabel>
						},
					},
					{
						text: '제목',
						key: 'title',
						value: 'title',
						render: ({ title, writer, updatedAt }) => {
							return (
								<div>
									<IssueTitle>{title}</IssueTitle>
									<UserInfo>
										<Writer>
											<UserInline fontWeight={400} font-size={12} size={20} {...writer} />
										</Writer>
										<DateInfo>{moment(updatedAt).fromNow()}</DateInfo>
									</UserInfo>
								</div>
								// <div>
								// 	<div>
								// 		<p style={styles.issue}>{title}</p>
								// 	</div>
								// 	<span style={{ marginTop: '10.7px' }}>
								// 		<span style={styles.doctor}>{writer.name}</span>
								// 		<span styles={styles.time}>{moment(updatedAt).fromNow()}</span>
								// 	</span>
								// </div>
							)
						},
					},
					{
						text: '의견',
						key: 'comments.totalCount',
						value: 'commentCount',
						uiOptions: {
							textAlign: 'center',
						},
						render: ({ comments: { totalCount } }) => {
							return (
								<div>
									<Icon name="comment outline" />
									{totalCount}
								</div>
							)
						},
					},
				]}
			/>
			// </PageContainer>
			// 		<div style={styles.issue}>
			// 			{/* 이슈 글쓰기 --> ResearchIssueQuestionForm.js */}
			// 			{/* 라우터 주소 변경 */}
			// 			<Link
			// 				to={replaceParams(ROUTE_PROJECT_DETAIL_ISSUES_WRITE, {
			// 					id: researchId,
			// 				})}
			// 			>
			// 				<Button color="teal">새로운 이슈</Button>
			// 			</Link>
			// 		</div>
			// 		<div style={styles.searchField}>
			// 			{/*  데이터 검색 입력 */}
			// 			<div>
			// 				<Form size="small">
			// 					<Form.Group>
			// 						<Form.Input
			// 							name="search"
			// 							onChange={this.handleChange}
			// 							value={this.state.query}
			// 							placeholder="검색"
			// 							style={{ marginRight: '4.2px' }}
			// 						/>
			// 						<Form.Field control={Select} placeholder="Gender" />
			// 					</Form.Group>
			// 				</Form>
			// 			</div>
			// 		</div>
			// 		<Table structured size="small">
			// 			<Table.Header></Table.Header>
			// 			<Table.Body>{this.renderRowItem(items)}</Table.Body>
			// 		</Table>
			// 	</section>
			// </Container>
		)
	}
}

export default withRouter(ResearchIssues)

const IssueTitle = styled.div`
	font-weight: 700;
`

const UserInfo = styled.div`
	margin-top: 5px;
	display: flex;
	align-items: center;
`

const Writer = styled.div`
	margin-right: 20px;
`
