import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Button } from 'semantic-ui-react'
import { ResearchType, RecordType, RecordCompleteStatus, ObjectType } from '../enum'
import ExportDataModal from '../components/research/data/ExportDataModal'
import ErrorView from '../components/common/error/ErrorView'
import { ROUTE_PROJECT_DETAIL_DATA_DETAIL_DEFAULT } from '../constants/routes'
import { replaceParams } from '../libs/routeHelper'
import { toCommonDateString } from '../libs/dateHelper'
import DescriptionText from '../components/common/basic/DescriptionText'
import DateInfo from '../components/common/basic/DateInfo'
import StatusDotLabel from '../components/common/StatusDotLabel'
import RecordVersionChecker from '../components/record/RecordVersionChecker'
import DataTable from '../components/common/DataTable'
import PageContainer from '../components/common/container/PageContainer'

import { GET_RESEARCH_RECORDS } from '../graphql/queries/record'
import DeleteObjectModal from '../components/common/modal/DeleteObjectModal'
import useDeleteObject from '../hooks/useDeleteObject'

const PAGE_TITLE = '데이터'

const ResearchRecordList = props => {
	const { researchId, type, userInfo, title, error, breadCrumbs, history } = props

	const { handleOpen, ...deleteModalProps } = useDeleteObject()
	const [isExportModalOpen, setExportModalOpen] = useState(false)

	const isScheduled = type === ResearchType.SCHEDULED.key

	const templateSortKey = `template${isScheduled ? 'Order' : 'Title'}`
	const templateLabel = isScheduled ? '일정' : '양식'

	const COLUMNS = useMemo(() => {
		return [
			{
				text: '진료시기',
				key: 'visitedDate',
				value: 'visitedDate',
				uiOptions: {
					textAlign: 'center',
					style: { width: 150 },
				},
				render: ({ visitedDate }) => {
					const vdate = moment(visitedDate)
					return vdate.isSame(new Date(), 'day')
						? vdate.format('LT')
						: vdate.format('YYYY. MM. DD.')
				},
			},
			{
				text: '환자',
				key: 'patient.name',
				value: 'patientCode',
				uiOptions: {
					textAlign: 'center',
					style: { width: 200 },
				},
				render: ({ patient }) => {
					return (
						<>
							<span
								style={{
									minWidth: 60,
									display: 'inline-block',
								}}
							>
								{patient.patientResearches && patient.patientResearches[0].code}
							</span>
							&nbsp;&nbsp;&nbsp; (&nbsp; {patient.name}
							&nbsp;)
						</>
					)
				},
			},
			{
				text: templateLabel,
				key: 'template.title',
				value: templateSortKey,
				uiOptions: {
					textAlign: 'center',
				},
				render: ({ template, type }) => {
					if (template != null) return template.title
					else if (type === RecordType.DROP.key) return '참여 중단(Drop)'
				},
			},
			{
				text: '담당자',
				key: 'creatorName',
				value: 'creator.name',
				uiOptions: {
					textAlign: 'center',
					style: { width: 140 },
				},
				render: ({ creator }) => {
					return creator ? <b>{creator.name}</b> : <DescriptionText>사용자 없음</DescriptionText>
				},
			},
			{
				text: '등록일',
				key: 'createdAt',
				value: 'createdAt',
				uiOptions: {
					textAlign: 'center',
					style: { width: 140 },
				},
				render: ({ createdAt }) => {
					return <DateInfo>{toCommonDateString(createdAt)}</DateInfo>
				},
			},
			{
				text: '상태',
				key: 'isComplete',
				value: 'isComplete',
				uiOptions: {
					textAlign: 'left',
					style: { width: 150 },
				},
				render: ({ type, template, recordTemplateChangeId, isComplete }) => {
					return (
						type !== RecordType.DROP.key && (
							<div>
								<StatusDotLabel
									{...(isComplete
										? RecordCompleteStatus.COMPLETE
										: RecordCompleteStatus.INCOMPLETE)}
								/>
								{template != null && (
									<RecordVersionChecker
										currentTemplateChangeId={recordTemplateChangeId}
										recentTemplateChangeId={
											template.lastChange != null ? template.lastChange.id : null
										}
									/>
								)}
							</div>
						)
					)
				},
			},
		]
	}, [templateLabel, templateSortKey])

	if (error != null) {
		return <ErrorView error={error} />
	}

	return (
		<>
			<DataTable
				celled
				hasSearch
				searchPlaceholder="검색"
				query={GET_RESEARCH_RECORDS}
				defaultVars={{
					researchId,
				}}
				queryName="researchRecords"
				emptyMessage="데이터가 없습니다."
				onClickRow={({ id, patient }) => {
					try {
						const patientId = patient.id
						history.push(
							replaceParams(ROUTE_PROJECT_DETAIL_DATA_DETAIL_DEFAULT, {
								id: researchId,
								subId: patientId,
								recordId: id,
							})
						)
					} catch (e) {
						alert('데이터가 잘못되었습니다.')
					}
				}}
				render={({ rendered, totalCount }) => {
					return (
						<PageContainer
							fixed
							title={PAGE_TITLE}
							breadCrumbs={breadCrumbs}
							titleNumber={totalCount}
						>
							{rendered}
						</PageContainer>
					)
				}}
				columns={COLUMNS}
				ellipsisMenu={({ creator, id, refetch }) => {
					return creator.id === userInfo.id
						? {
								options: [
									{
										text: '삭제',
										key: 'delete',
										value: 'delete',
										onClick: () =>
											handleOpen(ObjectType.RECORD.value, id, {
												onComplete: refetch,
											}),
									},
								],
						  }
						: null
				}}
				widgetButton={
					<Button
						size="large"
						primary
						onClick={() => {
							setExportModalOpen(true)
						}}
						content="데이터 다운로드"
					/>
				}
			/>
			<ExportDataModal
				{...props}
				isOpen={isExportModalOpen}
				onClose={() => {
					setExportModalOpen(false)
				}}
				researchId={researchId}
				researchTitle={title}
			/>
			<DeleteObjectModal {...deleteModalProps} />
		</>
	)
}

export default ResearchRecordList

ResearchRecordList.propTypes = {
	researchId: PropTypes.number,
	onUpdate: PropTypes.func,
	perPage: PropTypes.number,
	history: PropTypes.object.isRequired,
	records: PropTypes.array,
	totalCount: PropTypes.number,
	page: PropTypes.number,
	type: PropTypes.string,
	userInfo: PropTypes.object,
	onOpenDeleteModal: PropTypes.func,
	onPageChange: PropTypes.func,
	usePassword: PropTypes.bool,
	title: PropTypes.string,
	loading: PropTypes.bool,
	error: PropTypes.object,
	onSort: PropTypes.func,
	sortBy: PropTypes.string,
	sortDirection: PropTypes.string,
	breadCrumbs: PropTypes.array,
}
