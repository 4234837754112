import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import UserProfileEditAccount from './UserProfileEditAccount'
import UserProfileEditInfo from './UserProfileEditInfo'
import PageContainer from '../components/common/container/PageContainer'
import MenuContainer from '../components/common/container/MenuContainer'

const BASE_PATH = '/profile'

const MENU_ITEMS = [
	{
		title: '사용자 정보',
		to: BASE_PATH,
		key: '',
		exact: true,
		strict: true,
		component: UserProfileEditInfo,
	},
	{
		title: '소속기관',
		to: `${BASE_PATH}/institution`,
		key: 'institution',
		component: UserProfileEditInfo,
		props: {
			isInstitution: true,
		},
	},
	{
		title: '비밀번호 번경',
		to: `${BASE_PATH}/account`,
		key: 'account',
		component: UserProfileEditAccount,
	},
]

class UserProfileEdit extends PureComponent {
	render() {
		return (
			<PageContainer title="내 프로필 관리" fixed>
				<MenuContainer
					isBox
					menuOptions={{ secondary: true, pointing: true }}
					menuItems={MENU_ITEMS}
				/>
			</PageContainer>
		)
	}
}

export default withRouter(UserProfileEdit)
