import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { RecordUsingLatestTemplateStatus } from '../../enum'
import StatusDotLabel from '../common/StatusDotLabel'

const RecordVersionChecker = props => {
	const { style, templateUpdateURI, currentTemplateChangeId, recentTemplateChangeId } = props

	const isRecent = currentTemplateChangeId >= recentTemplateChangeId
	const statusInfo =
		isRecent === true
			? RecordUsingLatestTemplateStatus.RECENT
			: RecordUsingLatestTemplateStatus.NOT_RECENT

	return (
		<div style={style}>
			<LabelBox>
				<StatusDotLabel {...statusInfo} />
			</LabelBox>
			{isRecent === false && templateUpdateURI != null && (
				<ButtonBox>
					<Button
						as={Link}
						to={templateUpdateURI}
						className="RecordVersionChecker__update"
						compact
						size="tiny"
						basic
					>
						업데이트
					</Button>
				</ButtonBox>
			)}
		</div>
	)
}

RecordVersionChecker.propTypes = {
	style: PropTypes.shape(),
	templateUpdateURI: PropTypes.string.isRequired,
	currentTemplateChangeId: PropTypes.number,
	recentTemplateChangeId: PropTypes.number,
}

RecordVersionChecker.defaultProps = {
	style: {},
	currentTemplateChangeId: null,
	recentTemplateChangeId: null,
}

export default RecordVersionChecker

const ButtonBox = styled.div`
	margin-left: 10px !important;
	display: inline-block;
`

const LabelBox = styled.div`
	display: inline-block;
`
