import React, { PureComponent } from 'react'
import withChart from '../../higherComponents/withChart'
import ChartingBoard from '../charting/ChartingBoard'

class RecordCompare extends PureComponent {
	static propTypes = {}

	handleSubmit = async () => {
		const { onSaveChart } = this.props
		await onSaveChart()
	}

	render() {
		return (
			<div>
				<ChartingBoard {...this.props} />
			</div>
		)
	}
}

export default withChart(RecordCompare, { withRef: true })
