import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import TableWidgetContainer from './container/TableWidgetContainer'
import InputSearch from './basic/InputSearch'

const TableSearch = ({ children, ...props }) => {
	return (
		<TableWidgetContainer>
			<SearchInputBox>
				{children != null ? children : <InputSearch fluid {...props} />}
			</SearchInputBox>
		</TableWidgetContainer>
	)
}

TableSearch.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
}

TableSearch.defaultProps = {
	children: null,
}

export default TableSearch

const SearchInputBox = styled.div`
	flex: 1;
	display: flex;
`
