import gql from 'graphql-tag'
import { COMMENT_NICKNAME_FRAGMENT, COMMENT_NAME_FRAGMENT } from '../fragments/community'

// 기록 추가하기
export const GET_COMMENTS_NICK = gql`
	query getCaseComments($entityType: CommentEntityType, $entityId: Int) {
		caseComments(entityType: $entityType, entityId: $entityId) {
			comment {
				...CommentNicknameParts
			}
		}
	}
	${COMMENT_NICKNAME_FRAGMENT}
`

// 기록 추가하기
export const GET_COMMENTS_NAME = gql`
	query getCaseComments($entityType: CommentEntityType, $entityId: Int) {
		caseComments(entityType: $entityType, entityId: $entityId) {
			id
			comment {
				...CommentNameParts
			}
		}
	}
	${COMMENT_NAME_FRAGMENT}
`
