import { useQuery, useMutation } from 'react-apollo'
import { useEffect } from 'react'
import { GET_LOCAL_MED_INFO } from '../graphql/queries/medInfo'
import { UPDATE_LOCAL_SURVEY_MED_INFO } from '../graphql/mutations/medInfo'

/**
 * 환자 설문 조사용 LocalMedInfo
 */
const useLocalMedInfo = (medInfoId, defaultValue) => {
	const { data: { localMedInfo = {} } = {} } = useQuery(GET_LOCAL_MED_INFO, {
		variables: {
			id: medInfoId,
		},
	})

	useEffect(() => {
		if (defaultValue != null) {
			update('value', defaultValue)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const [update] = useMutation(UPDATE_LOCAL_SURVEY_MED_INFO)

	const toUpdate = (key, value) => {
		update({
			variables: {
				medInfoId,
				[key]: value,
			},
		})
	}
	return [localMedInfo, toUpdate]
}

export default useLocalMedInfo
