import React, { useState, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Form, Icon } from 'semantic-ui-react/dist/commonjs'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { Modal, Button, Dropdown, Checkbox } from 'semantic-ui-react'
import { useMutation } from 'react-apollo'

import {
	ResearchTemplateType,
	ResearchType,
	ResearchSubjectStatus,
	TemplateJob,
} from '../../../enum'
import { checkNewRecordPossible } from '../../../libs/recordHelper'
import ResearchSelectView from '../../common/ResearchSelectView'
import { ADD_RECORD } from '../../../graphql/mutations/record'
import DateInput from '../../common/basic/DateInput'
import LightH3 from '../../common/basic/LightH3'
import {
	ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL,
	ROUTE_PATIENT_DETAIL_RECORD,
} from '../../../constants/routes'
import { replaceParams } from '../../../libs/routeHelper'
import { DEFAULT_PATIENT_RESEARCH } from '../../../constants'
import RecordAddFileUpload from './RecordAddFileUpload'
import { cleanMedInfos } from '../../../libs/medInfoHelper'
import { SEND_PATIENT_SURVEY } from '../../../graphql/mutations/survey'

/**
 * 진료기록 생성 모달
 */
const RecordAddModal = ({
	patientResearches: propResearches,
	updateParent,
	defaultResearch,
	hasRecord,
	isOpen,
	onClose,
	isOnResearchPage,
	patientId,
	researchId,
}) => {
	const history = useHistory()

	const patientResearches = useMemo(() => {
		return [...(propResearches || []), DEFAULT_PATIENT_RESEARCH]
	}, [propResearches])

	const [selectedResearch, setSelectedResearch] = useState(
		patientResearches.length > 0
			? patientResearches[0]
			: defaultResearch || DEFAULT_PATIENT_RESEARCH
	)

	const [selectedTemplate, setSelectedTemplate] = useState(
		selectedResearch != null ? getDefaultTemplate(selectedResearch, hasRecord) : null
	)
	const { extLinkCode } = selectedTemplate || {}

	const { research: { recordTemplates, templateType, type } = {} } = selectedResearch || {}

	const [visitedDate, setVisitedDate] = useState(moment().format('YYYY-MM-DD HH:mm'))

	const [excelData, setExcelData] = useState(null)

	const [isSendLink, setSendLink] = useState(false)

	// 선택된 연구가 있고 개인저장용이 아닐 경우
	// 연구 상태에 따라 표시할 경고 메시지를 확인
	// 기존에 생성된 기록이 있고, 데이터 축적 프로젝트일 경우, 이전 기록 복사 여부를 선택
	const { isSubmitDisabled, disabledMsg } = useMemo(() => {
		if (selectedResearch != null && !selectedResearch.noResearch) {
			const { disabledMsg, isSubmitDisabled } = checkNewRecordPossible(
				selectedResearch,
				isOnResearchPage
			)

			return {
				isSubmitDisabled,
				disabledMsg,
			}
		}

		return {
			isSubmitDisabled: false,
			disabledMsg: false,
		}
	}, [isOnResearchPage, selectedResearch])

	const [isUsingLastRecord, setUsingLastRecord] = useState(
		hasRecord === true ? type === ResearchType.GATHERING.value : false
	)

	const [isLoadingSubmit, setLoadingSubmit] = useState(false)

	/**
	 * 프로젝트 선택하기
	 * 프로젝트 선택을 토글하고
	 * 프로젝트가 선택되었을 때 기본 템플릿과 옵션을 설정함
	 */
	const toggleSelectResearch = useCallback(
		patientResearch => {
			if (selectedResearch != null) {
				setSelectedResearch(null)
				setSelectedTemplate(null)
			} else {
				const defaultTemplate = getDefaultTemplate(patientResearch, hasRecord)

				setSelectedResearch(patientResearch)
				setSelectedTemplate(defaultTemplate)

				const { research: { type: rType } = {} } = patientResearch || {}
				setUsingLastRecord(hasRecord === true ? rType === ResearchType.GATHERING.value : false)
			}
		},
		[hasRecord, selectedResearch]
	)

	const toggleUsingLastRecord = () => setUsingLastRecord(prev => !prev)

	const handleTemplateSelect = useCallback(
		(e, { value }) => {
			const { research: { recordTemplates = [] } = {} } = selectedResearch || {}
			setSelectedTemplate(recordTemplates.find(t => t.id === value))
		},
		[selectedResearch]
	)

	const [sendPatientSurvey] = useMutation(SEND_PATIENT_SURVEY)

	/**
	 * 기록 생성 Mutation
	 */
	const [onAddRecord] = useMutation(ADD_RECORD, {
		onCompleted: async ({ addRecord }) => {
			const {
				id: recordId,
				patient: { id: patientId },
			} = addRecord

			onClose()
			setLoadingSubmit(false)
			if (isSendLink === true) {
				sendPatientSurvey({
					variables: {
						recordId,
						patientId,
						researchId,
					},
				})
			}
			await updateParent()
			history.push(
				isOnResearchPage === false
					? replaceParams(ROUTE_PATIENT_DETAIL_RECORD, {
							id: patientId,
							subId: recordId,
					  })
					: replaceParams(ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL, {
							pid: researchId,
							id: patientId,
							subId: recordId,
					  })
			)
		},
	})

	// 진료기록 생성하기
	const handleSubmit = async () => {
		setLoadingSubmit(true)

		try {
			let canCopy = false
			if (selectedResearch != null) {
				const {
					research: { type },
				} = selectedResearch
				if (type === ResearchType.GATHERING.value && hasRecord === true) {
					canCopy = true
				}
			}
			const medInfos =
				excelData != null ? cleanMedInfos({ medInfos: excelData, shouldKeepType: true }) : null

			await onAddRecord({
				variables: {
					patientId,
					templateId: selectedTemplate ? selectedTemplate.id : null,
					visitedDate: visitedDate,
					isUsingLastRecord: canCopy === true ? isUsingLastRecord : false,
					researchId: !selectedResearch.noResearch ? selectedResearch.research.id : null,
					medInfos,
				},
			})
		} catch (e) {
			setLoadingSubmit(false)
			alert('진료기록 생성중 오류가 발생했습니다.')
		}
	}

	const shouldChooseCopying =
		(type == null || type === ResearchType.GATHERING.value) && hasRecord === true

	const shouldSelectTemplate = templateType === ResearchTemplateType.SELECT.value
	const hasTemplates = recordTemplates != null && recordTemplates.length > 0

	const canSendLink = useMemo(() => {
		if (
			selectedResearch &&
			selectedResearch.research.patientBasicInfo &&
			selectedTemplate &&
			selectedTemplate.job
		) {
			return (
				selectedResearch.research.patientBasicInfo.includes('phone') &&
				selectedTemplate.job === TemplateJob.SURVEY.value
			)
		}
		return false
	}, [selectedResearch, selectedTemplate])

	return (
		<Modal open={isOpen} onClose={onClose} size="small" closeIcon>
			<Modal.Header>새로운 환자 기록</Modal.Header>
			<Modal.Content>
				{patientResearches.length > 0 && (
					<div>
						{selectedResearch == null ? (
							<Title>기록을 등록할 프로젝트를 선택해 주세요.</Title>
						) : (
							<DropdownLabel>프로젝트</DropdownLabel>
						)}
						<ResearchSelectView
							disabled={isOnResearchPage}
							record
							items={patientResearches}
							selectedResearch={selectedResearch}
							toggleSelectResearch={toggleSelectResearch}
							templateInfo={selectedTemplate}
						/>
					</div>
				)}
				{shouldSelectTemplate === true &&
					(hasTemplates === true ? (
						<FieldContainer>
							<DropdownLabel>입력양식 선택</DropdownLabel>
							<RecordTemplateSelect
								onTemplateSelect={handleTemplateSelect}
								templates={recordTemplates}
								selectedTemplateId={selectedTemplate != null && selectedTemplate.id}
								shouldChooseCopying={shouldChooseCopying}
								toggleUsingLastRecord={toggleUsingLastRecord}
								isUsingLastRecord={isUsingLastRecord}
							/>
						</FieldContainer>
					) : (
						<NoTemplates />
					))}
				{selectedResearch != null && (
					<React.Fragment>
						{isSubmitDisabled && (
							<div style={{ marginTop: 30, marginLeft: 20, color: '#FF7777' }}>
								<Icon name="exclamation circle" />
								{disabledMsg}
							</div>
						)}
						{!isSubmitDisabled && (
							<Form onSubmit={handleSubmit}>
								<FieldContainer>
									<Form.Group>
										<Form.Field>
											<label>진료일자 확인</label>
											<div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
												<DateInput
													locale="ko"
													selected={visitedDate && moment(visitedDate)}
													showMonthDropdown
													showYearDropdown
													showTimeSelect
													scrollableYearDropdown
													disable
													placeholderText="클릭해서 날짜선택"
													dateFormat="YYYY-MM-DD HH:mm"
													dropdownMode="select"
													dateFormatCalendar="YYYY MMMM"
													onChange={item => {
														setVisitedDate(item.format('YYYY-MM-DD HH:mm'))
													}}
													maxDate={moment()}
												/>
											</div>
										</Form.Field>
										{extLinkCode != null && (
											<RecordAddFileUpload
												templateId={selectedTemplate.id}
												setParsedData={setExcelData}
											/>
										)}
									</Form.Group>
								</FieldContainer>
							</Form>
						)}
						{canSendLink === true && (
							<Checkbox
								label="환자에게 설문 링크 전송하기"
								checked={isSendLink}
								onChange={(e, { checked }) => {
									setSendLink(checked)
								}}
							/>
						)}
					</React.Fragment>
				)}
			</Modal.Content>
			<Modal.Actions>
				{selectedResearch != null && !isSubmitDisabled && (
					<Button size="large" loading={isLoadingSubmit} primary onClick={handleSubmit}>
						진료기록 생성
					</Button>
				)}
			</Modal.Actions>
		</Modal>
	)
}

RecordAddModal.propTypes = {
	patientResearches: PropTypes.array,
	patientId: PropTypes.number,
	defaultResearch: PropTypes.shape({
		type: PropTypes.string,
	}),
	isOnResearchPage: PropTypes.bool,
	hasRecord: PropTypes.bool,
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
	updateParent: PropTypes.func,
	researchId: PropTypes.number,
	onClose: PropTypes.func,
	isOpen: PropTypes.bool,
}

RecordAddModal.defaultProps = {
	patientResearches: [],
	isOnResearchPage: false,
	hasRecord: false,
}

// 환자연구정보에서 기본 진료 양식 확인하기
const getDefaultTemplate = (patientResearch, hasRecord) => {
	const { research, nextTemplate } = patientResearch

	const { templateType, recordTemplates } = research

	let result

	// 탈락처리된 환자일 경우, 종료 후 기록이 있다면 생성
	if (
		research.type === ResearchType.SCHEDULED.value &&
		patientResearch.status === ResearchSubjectStatus.DROPPED.value
	) {
		if (recordTemplates != null && recordTemplates.length > 0) {
			result =
				recordTemplates.find(i => i.job === TemplateJob.FINISH.value) || research.recordTemplates[0]

			if (result) return result
		}
	}

	if (nextTemplate) {
		result = nextTemplate
	} else if (
		templateType === ResearchTemplateType.SELECT.value ||
		templateType === ResearchTemplateType.FIXED.value
	) {
		if (recordTemplates != null && recordTemplates.length > 0) {
			if (templateType === ResearchTemplateType.SELECT.value && hasRecord === true) {
				result = recordTemplates.find(i => i.isReturning === true) || research.recordTemplates[0]
			} else {
				result = research.recordTemplates[0]
			}
		}
	}

	return result
}

export default RecordAddModal

/**
 * 템플릿 선택 View Component
 */
const RecordTemplateSelect = ({
	onTemplateSelect,
	templates,
	selectedTemplateId,
	shouldChooseCopying,
	isUsingLastRecord,
	toggleUsingLastRecord,
}) => {
	const templateOptions = templates.map(template => {
		return {
			key: template.id,
			value: template.id,
			text: template.title,
		}
	})

	return (
		<div>
			<Dropdown
				selection
				options={templateOptions}
				value={selectedTemplateId}
				onChange={onTemplateSelect}
				style={{
					minWidth: 500,
				}}
			/>
			{shouldChooseCopying === true && (
				<Checkbox
					onChange={toggleUsingLastRecord}
					label="이전 기록 복사"
					style={styles.checkbox}
					checked={isUsingLastRecord === true}
				/>
			)}
		</div>
	)
}

RecordTemplateSelect.propTypes = {
	onTemplateSelect: PropTypes.func,
	templates: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			title: PropTypes.string,
		})
	),
	selectedTemplateId: PropTypes.number,
	shouldChooseCopying: PropTypes.bool,
	isUsingLastRecord: PropTypes.bool,
	toggleUsingLastRecord: PropTypes.func,
}

/**
 * 템플릿이 없을 경우
 */
const NoTemplates = () => {
	return <div style={styles.noTemplate}>등록된 양식이 없습니다. 양식을 먼저 추가해야 합니다.</div>
}

/**
 * Styles
 */

const Title = styled(LightH3)`
	font-size: 24px;
	margin-top: 10px !important;
	margin-bottom: 30px;
`

const DropdownLabel = styled.div`
	font-size: 12px;
	line-height: 16px;
	margin-bottom: 0.28571429rem;
`

const FieldContainer = styled.div`
	margin-bottom: 10px;
	margin-top: 20px;
`

const styles = {
	templateInfo: { marginLeft: 20, fontSize: 15 },
	templateName: { marginLeft: 15 },
	noTemplate: {
		fontSize: 13,
		color: '#aaa',
		marginTop: 12,
	},
	templateLabel: {
		marginRight: 20,
	},
	checkbox: {
		marginBottom: 0,
		marginLeft: 30,
	},
}
