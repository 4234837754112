import styled from 'styled-components'
import { COLOR_PRIMARY } from '../../../constants/styles'

const ClearButton = styled.div`
	display: inline-block;
	color: ${props => props.color || COLOR_PRIMARY};
	cursor: pointer;

	:hover {
		opacity: 0.4;
	}
`

export default ClearButton
