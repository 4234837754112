import React from 'react'
import styled from 'styled-components'

const LegendTable = ({ payload, data, hasData, dataKey }) => {
	let items
	if (hasData === true && payload.length > 0) {
		items = data
	} else {
		try {
			items = payload.map(entry => {
				const { color, value, payload } = entry
				return {
					color,
					name: value,
					value: payload.value,
					percent: payload.percent,
				}
			})
		} catch (e) {
			items = []
		}
	}
	return (
		<LegendTableContainer>
			{items.map((entry, index) => {
				return <Row {...entry} key={entry.name} />
			})}
		</LegendTableContainer>
	)
}

export default LegendTable

const Row = ({ color, name, value, percent }) => {
	return (
		<RowContainer>
			<Dot color={color} />
			<Name>{name}</Name>
			<div>{value}</div>
		</RowContainer>
	)
}

const LegendTableContainer = styled.div`
	margin-top: 20px;
	margin-bottom: 20px;
`
const RowContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: left;
	padding-right: 20px;
	height: 24px;
`

const Dot = styled.div`
	border-radius: 6px;
	background: ${props => props.color};
	width: 6px;
	height: 6px;
	margin-right: 14px;
`

const Name = styled.div`
	flex: 1;
`
