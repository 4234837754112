import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Prompt } from 'react-router-dom'
import { withApollo, useQuery } from 'react-apollo'
import parseHtml from 'react-html-parser'
import qs from 'query-string'

import { Button, Form, Search, Select } from 'semantic-ui-react'

import PageContainer from '../../common/container/PageContainer'
import SignupTerm from '../../other/term/TermAgreements'
import TextInputWithError from '../../common/basic/TextInputWithError'
import SegmentToggle from '../../common/basic/SegmentToggle'

import { UserType, TermType, DoctorType, CertType } from '../../../enum'
import { validateFields } from '../../../libs/validationHelper'
import { SIGN_UP_FIELDS, SIGNUP_FIELDS_INSTITUTION } from '../../../fields'
import errorTypes from '../../../errorTypes'
import { matchErrorCode } from '../../../libs/errorHelper'
import { USER_DUPE_MESSAGES } from '../../../constants'
import { SUGGEST_INSTITUTIONS } from '../../../graphql/queries/institution'
import {
	ALERT_MESSAGE_LEAVE_SIGNUP,
	// ERROR_MESSAGE_USER_ALREADY_JOINED,
	// ERROR_MESSAGE_USER_VERIFICATION_FAILED,
	ERROR_MESSAGE_UNKNOWN,
} from '../../../lang'
import { CHECK_DUPLICATE_USERS, GET_DOCTOR_DEPARTMENTS } from '../../../graphql/queries/user'
import { InlineLoader } from '../../common/loader/InlineLoader'
import ErrorView from '../../common/error/ErrorView'
import { FlexDiv, FlexFill } from '../../common/basic/StyledBox'
// import { certificateIMP, initIMP } from '../../../libs/IMPHelper'
// import DescriptionText from '../../common/basic/DescriptionText'

const SIGNUP_STAGES = {
	terms: {
		value: 'terms',
		title: '회원 가입 신청',
		desc: '지금 가입해 함께 임상 데이터를 수집해 보세요.',
	},
	info: {
		value: 'info',
		title: '회원 정보 입력',
		desc: '회원 정보를 입력해 주세요.',
		fields: SIGN_UP_FIELDS,
	},
	institution: {
		value: 'institution',
		title: '회원 종류 선택',
		desc: '회원 종류와 소속기관 정보를 입력해 주세요.',
		fields: SIGNUP_FIELDS_INSTITUTION,
	},
	welcome: {
		value: 'welcome',
		title: '회원 가입 완료',
	},
}

const FIRST_STAGE = SIGNUP_STAGES.terms.value
const LAST_STAGE = SIGNUP_STAGES.institution.value
const DONE_STAGE = SIGNUP_STAGES.welcome.value

/**
 * 회원가입 양식
 */
class SignupForm extends React.PureComponent {
	static propTypes = {
		onSignupRequest: PropTypes.func.isRequired,

		client: PropTypes.shape({
			query: PropTypes.func,
			mutate: PropTypes.func,
		}).isRequired,
	}

	isSubmitting = false

	constructor(props) {
		super(props)

		window.onbeforeunload = () => {
			return '회원 가입 정보가 사라집니다.'
		}

		const { email: invitedUserEmail } = qs.parse(this.props.location.search)

		this.state = {
			name: '',
			username: '',
			password: '',
			email: invitedUserEmail || '',
			passwordRepeat: '',
			userType: UserType.DOCTOR.key,
			institution: '',
			institutionId: null,
			position: '',
			phone: '',
			address: '',
			nickname: '',
			agreedPrivacyTerm: false,
			agreedTerm: false,
			validationErrors: {},
			institutionResult: [],
			isInstSearchLoading: false,
			stage: FIRST_STAGE,
			loadingNext: false,
			departmentId: null,
			loadingSubmit: false,
			// certError: null,
			// certInfo: null,
			// certLoading: false,
		}
	}

	/**
	 * 값 변화 시
	 */
	handleChange = (e, { name, value }) => {
		const state = {}
		state[name] = value
		this.setState(state)
	}

	/**
	 * 유저 종류 변경
	 */
	handleChangeUserType = value => {
		this.setState({
			userType: value,
			position: '',
			institution: '',
			address: '',
			institutionId: null,
		})
	}

	/**
	 * 단계 이동
	 */
	handleChangeStage = to => {
		this.setState({
			stage: to,
		})
		window.scrollTo(0, 0)
	}

	/**
	 * '다음' 버튼 클릭 시
	 */
	handleClickDone = async () => {
		const { stage, certInfo } = this.state

		if (stage === LAST_STAGE) {
			this.handleSubmit()
		} else {
			let next
			Object.keys(SIGNUP_STAGES).find((key, index) => {
				if (SIGNUP_STAGES[key].value == stage) {
					next = SIGNUP_STAGES[Object.keys(SIGNUP_STAGES)[index + 1]].value
					return true
				}

				return false
			})

			let isValid
			if (stage == SIGNUP_STAGES.terms.value) {
				isValid = this.handleValidateTerms()
			} else {
				isValid = this.handleValidateUserInfo(stage)
			}

			if (isValid === true) {
				if (stage == SIGNUP_STAGES.info.value) {
					const { client } = this.props
					const { username, email, nickname, phone } = this.state
					try {
						this.setState({
							loadingNext: true,
						})

						await client.query({
							query: CHECK_DUPLICATE_USERS,
							variables: {
								username,
								email,
								nickname,
								phone: phone.replace(/-/, ''),
							},
						})
					} catch (e) {
						const dCode = errorTypes.DUPLICATE_ERROR.code

						const errs = e.graphQLErrors

						if (errs && errs.length > 0) {
							const errors = errs.reduce((res, r) => {
								if (r.extensions && r.extensions.code === dCode) {
									const path = r.path[0]
									const field = path.replace('check', '').toLowerCase()

									if (field != null) {
										res[field] = {
											message: USER_DUPE_MESSAGES[field],
										}
									}

									return res
								}
							}, {})

							this.setState({
								validationErrors: errors,
							})
						} else {
							alert('알 수 없는 오류가 발생했습니다.')
						}

						return
					}

					// if (certInfo == null) {
					// 	this.setState({
					// 		certError: {
					// 			message: '본인 인증을 완료해 주세요.',
					// 		},
					// 	})
					// 	return false
					// }

					this.handleChangeStage(next)
					this.setState({
						loadingNext: false,
					})
				} else {
					this.handleChangeStage(next)
				}
			}
		}
	}

	/**
	 * 이전 페이지로 돌아가기
	 */
	handlePrevStage = () => {
		const { stage } = this.state
		let prev
		Object.keys(SIGNUP_STAGES).find((key, index) => {
			if (SIGNUP_STAGES[key].value == stage) {
				if (index === 0) return true
				prev = SIGNUP_STAGES[Object.keys(SIGNUP_STAGES)[index - 1]].value
				return true
			}

			return false
		})

		if (prev !== undefined) {
			this.setState({
				stage: prev,
			})
		}
	}

	/**
	 * 기관정보 자동완성 검색결과에서 선택
	 */
	handleSearchSelect = async (e, { result }) => {
		this.setState({
			institutionId: result.id,
			institution: result.title,
			address: result.description,
		})
	}

	/**
	 * 기관 정보 검색 시
	 */
	handleSearchChange = async (e, { name, value }) => {
		this.setState({
			[name]: value,
		})

		if (value !== '') {
			this.setState({
				isInstSearchLoading: true,
			})
			const { client } = this.props
			const { data, error } = await client.query({
				query: SUGGEST_INSTITUTIONS,
				variables: {
					query: value,
				},
			})

			if (!error) {
				const { suggestInstitutions: insts } = data

				if (insts) {
					this.setState({
						institutionResult: insts.map(item => ({
							key: item.id,
							id: item.id,
							title: item.name,
							description: item.address,
						})),
					})
				}
			}

			this.setState({
				isInstSearchLoading: false,
			})
		}
	}

	/**
	 * 약관 동의 여부 확인
	 */
	handleValidateTerms = () => {
		const { agreedPrivacyTerm, agreedTerm } = this.state

		if (agreedPrivacyTerm === false || agreedTerm === false) {
			alert('이용약관에 모두 동의 후 가입하실 수 있습니다.')
			return false
		}

		return true
	}

	/**
	 * 사용자 정보 validation
	 */
	handleValidateUserInfo = stage => {
		let userInfo
		const {
			loadingNext,
			validationErrors,
			institutionResult,
			agreedPrivacyTerm,
			agreedTerm,
			// certInfo,
			// certError,
			...us
		} = this.state

		try {
			userInfo = JSON.parse(JSON.stringify(us))
		} catch (e) {
			alert(ERROR_MESSAGE_UNKNOWN)
			return false
		}

		const errors =
			validateFields(
				userInfo,
				stage === SIGNUP_STAGES.institution.value ? SIGNUP_FIELDS_INSTITUTION : SIGN_UP_FIELDS
			) || {}

		if (userInfo.password !== userInfo.passwordRepeat) {
			if (!errors.passwordRepeat) {
				errors.passwordRepeat = {
					message: '비밀번호가 재입력된 비밀번호와 일치하지 않습니다.',
				}
			}
		}

		if (Object.keys(errors).length > 0) {
			this.setState({
				validationErrors: errors,
			})
			return false
		}

		// if (certError != null) {
		// 	return false
		// }

		this.setState({
			validationErrors: errors,
		})

		return true
	}

	// // 본인인증 처리
	// handleCertificate = async () => {
	// 	try {
	// 		this.setState({
	// 			certLoading: true,
	// 		})
	// 		const { name, phone, certInfo, certError } = this.state
	// 		if (name == null || name == '') {
	// 			alert('이름을 입력해 주세요.')
	// 			this.setState({
	// 				certLoading: false,
	// 			})
	// 		} else if (phone == null || phone === '') {
	// 			alert('휴대전화 번호를 입력해 주세요.')
	// 			this.setState({
	// 				certLoading: false,
	// 			})
	// 		} else if (certInfo == null || certError != null) {
	// 			initIMP()
	// 			const certResult = await certificateIMP({ phone, name })

	// 			if (certResult.name === name) {
	// 				this.setState({
	// 					certInfo: certResult,
	// 					certError: null,
	// 					certLoading: false,
	// 				})
	// 			} else {
	// 				this.setState({
	// 					certError: {
	// 						message:
	// 							'인증 내용과 입력된 사용자 정보가 일치하지 않습니다. 성명과 휴대전화 번호가 정확하게 입력되었는지 확인해 보세요.',
	// 					},
	// 					certLoading: false,
	// 				})
	// 			}
	// 		} else {
	// 			this.setState({
	// 				certLoading: false,
	// 			})
	// 		}
	// 	} catch (e) {
	// 		let message
	// 		if (e != null && e.code === errorTypes.USER_ALREADY_JOINED_ERROR.code) {
	// 			message = ERROR_MESSAGE_USER_ALREADY_JOINED
	// 		} else {
	// 			message = ERROR_MESSAGE_USER_VERIFICATION_FAILED
	// 		}

	// 		this.setState({
	// 			certInfo: null,
	// 			certError: {
	// 				message,
	// 			},
	// 			certLoading: false,
	// 		})
	// 	}
	// }

	/**
	 * 회원가입
	 */
	handleSubmit = async () => {
		this.setState(prev => ({
			...prev,
			loadingSubmit: true,
		}))

		if (this.handleValidateUserInfo(SIGNUP_STAGES.institution.value) === false) {
			this.setState(prev => ({
				...prev,
				loadingSubmit: false,
			}))
			return
		}

		if (this.handleValidateTerms() === false) {
			this.handleChangeStage(FIRST_STAGE)
			alert('이용약관에 모두 동의해 주세요.')
			this.setState(prev => ({
				...prev,
				loadingSubmit: false,
			}))
			return
		}

		if (this.handleValidateUserInfo(SIGNUP_STAGES.info.value) === false) {
			this.handleChangeStage(SIGNUP_STAGES.info.value)
			alert('잘못된 입력값이 있습니다.')
			this.setState(prev => ({
				...prev,
				loadingSubmit: false,
			}))
			return
		}

		const { onSignupRequest } = this.props
		let userInfo
		const {
			loadingNext,
			validationErrors,
			institutionResult,
			agreedPrivacyTerm,
			agreedTerm,
			// certInfo,
			// certError,
			...us
		} = this.state

		try {
			userInfo = JSON.parse(JSON.stringify(us))
		} catch (e) {
			this.setState(prev => ({
				...prev,
				loadingSubmit: false,
			}))
			return
		}

		delete userInfo.passwordRepeat

		if (userInfo.userType !== UserType.DOCTOR.value) {
			delete userInfo.departmentId
		}

		userInfo.agreedTerm = agreedTerm
		userInfo.agreedPrivacyTerm = agreedPrivacyTerm

		// userInfo.certKey = certInfo.unique_key
		// userInfo.uid = certInfo.uid

		try {
			this.setState({
				loadingNext: true,
				loadingSubmit: false,
			})

			this.isSubmitting = true
			const isSuccess = await onSignupRequest(userInfo)
			window.onbeforeunload = null

			this.setState({
				loadingNext: false,
				loadingSubmit: false,
			})

			if (isSuccess == null || isSuccess === false) {
				alert('오류가 발생했습니다.')
			} else {
				this.handleChangeStage(DONE_STAGE)
			}
		} catch (e) {
			// 중복값 존재시 사용자정보 입력 단계로 돌아감.
			const duplicateError = matchErrorCode(e, errorTypes.DUPLICATE_ERROR)
			if (duplicateError) {
				const duplicates = duplicateError.invalidArgs
				if (duplicates && duplicates instanceof Array && duplicates.length > 0) {
					const dupes = {}
					for (const dupe of duplicates) {
						dupes[dupe] = {
							message: USER_DUPE_MESSAGES[dupe],
						}
					}

					this.setState({
						validationErrors: dupes,
					})
					this.handleChangeStage(SIGNUP_STAGES.info.value)
				}
			} else {
				alert('오류가 발생했습니다.')
			}

			this.setState(prev => ({
				...prev,
				loadingSubmit: false,
			}))
		}
	}

	/**
	 * 약관 체크
	 */
	toggleCheckTerm = termType => () => {
		let key
		if (termType === TermType.SERVICE.value) key = 'agreedTerm'
		else if (termType === TermType.PRIVACY.value) key = 'agreedPrivacyTerm'

		if (key)
			this.setState(prev => ({
				[key]: !prev[key],
			}))
	}

	render() {
		const {
			userType,
			institution,
			position,
			address,
			validationErrors,
			agreedTerm,
			agreedPrivacyTerm,
			isInstSearchLoading,
			institutionResult,
			stage,
			departmentId,
			email,
			name,
		} = this.state

		const stageInfo = SIGNUP_STAGES[stage]

		return (
			<PageContainer fixed width={stage === SIGNUP_STAGES.terms.value ? 620 : 320} hasMobile>
				<Title>{stageInfo.title}</Title>
				{stageInfo.desc != null && <DescBox>{parseHtml(stageInfo.desc)}</DescBox>}
				{stage === SIGNUP_STAGES.terms.value && (
					<Terms
						agreedTerm={agreedTerm}
						agreedPrivacyTerm={agreedPrivacyTerm}
						onToggle={this.toggleCheckTerm}
					/>
				)}
				{stage === SIGNUP_STAGES.info.value && (
					<InfoForm
						{...this.state}
						onChange={this.handleChange}
						// onCertificate={this.handleCertificate}
					/>
				)}
				{stage === SIGNUP_STAGES.institution.value && (
					<UserTypeForm
						userType={userType}
						position={position}
						institution={institution}
						address={address}
						departmentId={departmentId}
						onChangeUserType={this.handleChangeUserType}
						onChange={this.handleChange}
						onSearchChange={this.handleSearchChange}
						searchResult={institutionResult}
						onSearchSelect={this.handleSearchSelect}
						validationErrors={validationErrors}
						isInstSearchLoading={isInstSearchLoading}
					/>
				)}
				{stage === SIGNUP_STAGES.welcome.value && <SignupDone name={name} email={email} />}
				{stage !== DONE_STAGE && (
					<SubmitButton
						loading={this.state.loadingSubmit}
						onClick={this.handleClickDone}
						stage={stage}
					/>
				)}
				{stage !== FIRST_STAGE && stage !== DONE_STAGE && (
					<PrevButton onClick={this.handlePrevStage} />
				)}
				{stage !== DONE_STAGE && (
					<Prompt
						when={!this.isSubmitting} // Submit 하지 않는데 페이지 빠저나갈 경우
						message={ALERT_MESSAGE_LEAVE_SIGNUP}
					/>
				)}
			</PageContainer>
		)
	}
}

export default withApollo(SignupForm)

/**
 * 약관 동의
 */
const Terms = ({ agreedTerm, agreedPrivacyTerm, onToggle }) => {
	return (
		<React.Fragment>
			<SignupTerm
				termType={TermType.PRIVACY.value}
				agreed={agreedPrivacyTerm}
				onToggle={onToggle}
			/>
			<SignupTerm termType={TermType.SERVICE.value} agreed={agreedTerm} onToggle={onToggle} />
		</React.Fragment>
	)
}

Terms.propTypes = {
	agreedTerm: PropTypes.bool.isRequired,
	agreedPrivacyTerm: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
}

/**
 * 사용자 정보 입력
 */
const InfoForm = ({
	username,
	nickname,
	name,
	email,
	password,
	passwordRepeat,
	phone,
	validationErrors,
	onChange,
	// onCertificate,
	// certInfo,
	// certError,
	// certLoading,
}) => {
	// const isCertified = certInfo != null && certError == null

	return (
		<Form size="large">
			<TextInputWithError
				fluid
				label="사용자 ID"
				name="username"
				value={username}
				onChange={onChange}
				placeholder="사용자 ID"
				error={validationErrors.username}
			/>
			<TextInputWithError
				fluid
				label="비밀번호"
				placeholder="비밀번호"
				name="password"
				value={password}
				onChange={onChange}
				type="password"
				error={validationErrors.password}
			/>
			<TextInputWithError
				fluid
				label="비밀번호 확인"
				placeholder="비밀번호 확인"
				name="passwordRepeat"
				value={passwordRepeat}
				onChange={onChange}
				type="password"
				error={validationErrors.passwordRepeat}
			/>
			<TextInputWithError
				fluid
				label="성명"
				name="name"
				value={name}
				onChange={onChange}
				// readOnly={isCertified}
				placeholder="성명"
				error={validationErrors.name}
			/>
			<TextInputWithError
				fluid
				label="필명(닉네임)"
				name="nickname"
				value={nickname}
				onChange={onChange}
				placeholder="필명(닉네임)"
				error={validationErrors.nickname}
			/>
			<TextInputWithError
				fluid
				label="이메일"
				name="email"
				value={email}
				onChange={onChange}
				placeholder="이메일 주소"
				error={validationErrors.email}
			/>
			<div>
				<FlexDiv>
					<FlexFill>
						<TextInputWithError
							fluid
							label="휴대전화"
							name="phone"
							value={phone}
							onChange={onChange}
							// readOnly={isCertified}
							placeholder="휴대전화"
							// error={validationErrors.phone || certError}
							error={validationErrors.phone}
						/>
					</FlexFill>
					{/* <SideButtonBox>
						<Button
							loading={certLoading}
							onClick={isCertified !== true ? onCertificate : null}
							color={certInfo != null ? 'green' : null}
						>
							{isCertified === true ? (
								<>
									<span role="img" aria-label="본인인증 성공">
										✔️
									</span>
									&nbsp;인증성공
								</>
							) : (
								'본인인증'
							)}
						</Button>
					</SideButtonBox> */}
				</FlexDiv>
				{/* <DescriptionText>* 만 19세 미만은 사이트에 가입할 수 없습니다.</DescriptionText> */}
				{/* {certError != null && (
					<InputOuterMessage color={COLOR_RED}>* {certError.message}</InputOuterMessage>
				)} */}
			</div>
		</Form>
	)
}

InfoForm.propTypes = {
	username: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	nickname: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	password: PropTypes.string.isRequired,
	passwordRepeat: PropTypes.string.isRequired,
	phone: PropTypes.string.isRequired,
	validationErrors: PropTypes.shape({
		name: PropTypes.shape({
			message: PropTypes.string,
		}),
		phone: PropTypes.shape({
			message: PropTypes.string,
		}),
		username: PropTypes.shape({
			message: PropTypes.string,
		}),
		nickname: PropTypes.shape({
			message: PropTypes.string,
		}),
		email: PropTypes.shape({
			message: PropTypes.string,
		}),
		password: PropTypes.shape({
			message: PropTypes.string,
		}),
		passwordRepeat: PropTypes.shape({
			message: PropTypes.string,
		}),
	}).isRequired,
	onChange: PropTypes.func.isRequired,
	// certInfo: PropTypes.shape({
	// 	success: PropTypes.bool,
	// }),
	// certError: PropTypes.object,
	// onCertificate: PropTypes.func,
	// certLoading: PropTypes.bool,
}

/**
 * 회원 기관 정보(병원/대학 등..)
 */
const UserTypeForm = props => {
	const {
		onSearchChange,
		isInstSearchLoading,
		onSearchSelect,
		searchResult,
		validationErrors,
		userType,
		position,
		institution,
		address,
		onChange,
		onChangeUserType,
		departmentId,
	} = props
	const isDoctor = userType === UserType.DOCTOR.key
	const instLabel = isDoctor === true ? '소속 병원 명' : '소속 기관 명'

	const [userSubType, setUserSubType] = useState(DoctorType.DOCTOR.value)
	const typeInfo = DoctorType[userSubType]

	const { data, loading, error } = useQuery(GET_DOCTOR_DEPARTMENTS, {
		variables: {
			category: typeInfo && typeInfo.category,
		},
		onCompleted: data => {
			try {
				const { departments } = data
				if (departments.length > 0 && typeInfo.hasNoSubType !== true) {
					onChange(null, { value: departments[0].id, name: 'departmentId' })
				} else {
					onChange(null, { value: null, name: 'departmentId' })
				}
			} catch (e) {
				alert('오류가 발생했습니다.')
			}
		},
	})

	let departmentList

	if (data != null) {
		departmentList = data.departments
	}

	if (error) {
		return <ErrorView error={error} />
	}

	return (
		<Form>
			<Form.Field>
				<label>회원 종류</label>
				<UserTypeToggle name="userType" value={userType} onChange={onChangeUserType} />
			</Form.Field>
			{isDoctor === true && (
				<>
					<Form.Field>
						<label>분류</label>
						<SegmentToggle
							fluid
							active={userSubType}
							value={userSubType}
							onChange={setUserSubType}
							items={Object.values(DoctorType)}
						/>
					</Form.Field>
					{departmentList &&
						departmentList.length > 0 &&
						(loading === true ? (
							<div>
								<InlineLoader />
							</div>
						) : (
							<Form.Field
								control={Select}
								value={departmentId}
								name="departmentId"
								label="전문의 여부 및 분과"
								onChange={onChange}
								options={departmentList.map(d => ({
									id: `sf-d-${d.id}`,
									key: `sf-d-${d.id}`,
									text: d.name + (d.certType === CertType.BOARD_CERTIFIED.value ? ' 전문의' : ''),
									value: d.id,
								}))}
							/>
						))}
				</>
			)}
			<TextInputWithError
				control={Search}
				name="institution"
				onSearchChange={onSearchChange}
				value={institution}
				label={instLabel}
				placeholder={instLabel}
				results={searchResult}
				onResultSelect={onSearchSelect}
				error={validationErrors.institution}
				loading={isInstSearchLoading}
			/>
			{isDoctor === true && (
				<TextInputWithError
					name="address"
					onChange={onChange}
					value={address}
					label="병원 주소"
					placeholder="병원 주소"
					error={validationErrors.address}
				/>
			)}
			<TextInputWithError
				name="position"
				value={position}
				onChange={onChange}
				label="직위"
				placeholder="직위"
				error={validationErrors.position}
			/>
			{/* <Form.Input
      label="인증 - 한의사 면허증"
      type='file'
      onChange={onChange}
      placeholder='직위'
    />
    <Form.Input
      label="인증 - 주민등록증(뒷자리를 가려주세요)"
      type='file'      
      onChange={onChange}
      placeholder='직위'
    /> */}
		</Form>
	)
}

UserTypeForm.propTypes = {
	userType: PropTypes.string.isRequired,
	position: PropTypes.string.isRequired,
	institution: PropTypes.string.isRequired,
	address: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	validationErrors: PropTypes.shape({
		address: PropTypes.shape({
			message: PropTypes.string,
		}),
		position: PropTypes.shape({
			message: PropTypes.string,
		}),
		institution: PropTypes.shape({
			message: PropTypes.string,
		}),
	}).isRequired,
	onSearchChange: PropTypes.func.isRequired,
	onSearchSelect: PropTypes.func.isRequired,
	searchResult: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	isInstSearchLoading: PropTypes.bool.isRequired,
	onChangeUserType: PropTypes.func.isRequired,
	departmentId: PropTypes.number,
}

// 회원가입 완료 안내
const SignupDone = ({ email, name }) => {
	return (
		<div>
			<div style={{ marginTop: 50 }}>
				<h6 style={{ fontWeight: 300 }}>
					<b>{name}</b> 님, <br />
					레피어스 회원이 되신 것을
					<br />
					진심으로 환영합니다.
				</h6>
				<br />
				<div style={{ marginTop: 20 }}>
					입력하신 이메일(<b>{email}</b>)로 인증 메일을 보냈습니다. 이메일 인증을 완료하신 후
					서비스를 이용하실 수 있습니다.
				</div>
				<br />
				<div>메일함에서 인증 메일을 확인하시고, 메일 내의 인증 완료 버튼을 클릭해 주세요!</div>
			</div>
		</div>
	)
}

SignupDone.propTypes = {
	email: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
}

/**
 * 회원 종류 선택
 */
const UserTypeToggle = ({ value, onChange }) => {
	return (
		<SegmentToggle
			fluid
			label="회원 종류 선택"
			active={value}
			onChange={value => {
				onChange(value)
			}}
			items={Object.values(UserType).filter(i => i.canSignUp === true)}
		/>
	)
}

UserTypeToggle.propTypes = {
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
}

const SubmitButton = ({ onClick, loading = false, stage }) => {
	return (
		<SubmitButtonBox>
			<Button primary loading={loading} fluid size="large" onClick={onClick}>
				{stage === LAST_STAGE ? '회원가입 신청' : '다음'}
			</Button>
		</SubmitButtonBox>
	)
}

SubmitButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	stage: PropTypes.string.isRequired,
	loading: PropTypes.bool.isRequired,
}

const PrevButton = ({ onClick }) => {
	return (
		<SubmitButtonBox>
			<Button fluid size="large" onClick={onClick}>
				이전
			</Button>
		</SubmitButtonBox>
	)
}

PrevButton.propTypes = {
	onClick: PropTypes.func.isRequired,
}

/**
 * Styles
 */

const Title = styled.h5`
	text-align: center;
	line-height: 1.25;
`

const DescBox = styled.div`
	text-align: center;
	height: 20px;
	color: #a0a0a0;
	margin-bottom: 40px;
`

const SubmitButtonBox = styled.div`
	margin-top: 20px;
`

const SideButtonBox = styled.div`
	padding-top: 20px;
	padding-left: 5px;

	.button {
		height: 42px;
	}
`
