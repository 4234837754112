import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import 'moment/locale/ko'
import { Image, Icon } from 'semantic-ui-react'

import { Dropdown } from 'semantic-ui-react/dist/commonjs'
import CommentWrite from './CommentWrite'
import { SCROLL_LINK_COMMENT } from '../../constants'
import LinkColored from '../common/basic/LinkColored'
import { COLOR_TEXT_DARKGRAY, COLOR_TEXT_LIGHTGRAY } from '../../constants/styles'

/**
 * 개별 댓글 Presentation
 */
const CommentItemView = props => {
	const {
		isReply,
		isOwner,
		isEditing,
		isReplying,
		writer,
		useNick,
		date,
		replyTo,
		text,
		commentId,
		parentId,
		toggleReply,
		toggleEdit,
		onDelete,
		noReply,
		containerRef,
	} = props

	return (
		<div ref={containerRef} id={`${SCROLL_LINK_COMMENT}${commentId}`} style={styles.container}>
			<div style={styles.wrapper}>
				{isReply && (
					<div style={styles.reply}>
						<Icon name="reply" className="flip-270-icon" style={styles.replyIcon} />
					</div>
				)}
				<div style={styles.replyImageWrapper}>
					<Image src={writer.profileImage} circular inline style={styles.replyImage} />
				</div>
				<div style={styles.replyProfileWrapper}>
					<div>
						<b>{useNick ? writer.nickname : writer.name}</b>
						<span style={styles.date}>{date}</span>
						{isOwner && (
							<span style={styles.owner}>
								{isEditing && (
									<span style={styles.edit} onClick={toggleEdit} className="hover-darkgray">
										취소
									</span>
								)}

								{!isEditing && (
									<Dropdown style={styles.menu} icon="ellipsis horizontal">
										<Dropdown.Menu>
											<Dropdown.Item text="수정" onClick={toggleEdit} />
											<Dropdown.Item text="삭제" onClick={onDelete} />
										</Dropdown.Menu>
									</Dropdown>
								)}
							</span>
						)}
					</div>
					{!isEditing ? (
						<div style={{ marginTop: 5 }}>
							{replyTo && (
								<span>
									<LinkColored to={`/users/${replyTo.id}`}>
										@{useNick ? replyTo.nickname : replyTo.name}
									</LinkColored>
									&nbsp;
								</span>
							)}
							{text}
							{/* <Icon name={!isReplying ? 'pencil' : 'cancel'} style={{cursor: 'pointer', marginLeft: 20}} onClick={this.toggleIsReply}/> */}
							{!noReply && (
								<span style={styles.replyButton} className="link-like-text" onClick={toggleReply}>
									{isReplying ? '취소' : '답글 달기'}
								</span>
							)}
						</div>
					) : (
						<CommentWrite noProfile edit text={text} {...props} />
					)}
				</div>
				{/* <div style={{width: 150, fontSize: 14, color: '#999'}}>{dstr}</div> */}
			</div>
			{isReplying && (
				<div>
					<div style={{ display: 'flex' }}>
						<div style={styles.replying}>
							<Icon name="reply" className="flip-270-icon" style={styles.replyingIcon} />
						</div>
						<div style={{ flex: 1 }}>
							<CommentWrite
								{...props}
								replyToId={writer.id}
								parentId={parentId ? parentId : commentId}
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

CommentItemView.propTypes = {
	isReply: PropTypes.bool,
	isOwner: PropTypes.bool,
	isEditing: PropTypes.bool,
	isReplying: PropTypes.bool,
	writer: PropTypes.shape({
		name: PropTypes.string,
		nickname: PropTypes.string,
		id: PropTypes.string,
		profileImage: PropTypes.string,
	}),
	useNick: PropTypes.bool,
	date: PropTypes.string,
	replyTo: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		nickname: PropTypes.string,
		profileImage: PropTypes.string,
	}),
	text: PropTypes.string,
	commentId: PropTypes.number,
	parentId: PropTypes.number,
	toggleReply: PropTypes.func,
	toggleEdit: PropTypes.func,
	onDelete: PropTypes.func,
	noReply: PropTypes.bool,
	containerRef: PropTypes.object,
}

export default CommentItemView

const styles = {
	container: { paddingTop: 10, paddingBottom: 5 },
	wrapper: { display: 'flex' },
	reply: { paddingTop: 16, width: 40, textAlign: 'center' },
	replyIcon: { fontSize: 18, color: COLOR_TEXT_LIGHTGRAY },
	replyImageWrapper: { height: 40, marginTop: 10 },
	replyImage: { width: 30, height: 30, marginRight: 15 },
	replyProfileWrapper: { flex: 1, fontSize: 15, marginTop: 5 },
	date: { color: '#aaa', fontSize: 14, marginLeft: 13 },
	owner: { color: '#aaa', fontSize: 14 },
	edit: { marginLeft: 13, marginRight: 10, cursor: 'pointer' },
	delete: { cursor: 'pointer' },
	replyButton: { cursor: 'pointer', marginLeft: 20, fontSize: 13 },
	replying: { paddingTop: 30, width: 60, textAlign: 'center' },
	replyingIcon: { fontSize: 24 },
	menu: { float: 'right' },
}
