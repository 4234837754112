export const SUFFIX = ' - Lepius'
export const TITLE_HOME = 'Lepius - 스마트 차트 공유 플랫폼'
export const TITLE_PATIENTS = '내 환자' + SUFFIX

export const WARN_MESSAGE_LEAVE_SIGNUP = '회원 가입 정보가 사라집니다.'
export const ALERT_MESSAGE_LEAVE_SIGNUP = '회원 가입을 중단하시겠습니까?'

export const AGREEMENT_CHECK_MESSAGE = '서면으로 연구 참여 동의서를 작성하였습니다.'
export const AGREEMENT_WARNING_MESSAGE = '반드시 연구 참여 동의서를 작성해 주세요.'
export const AGREEMENT_ACTUAL_MESSAGE = '연구 참여 동의서를 업로드 해주세요.'
export const AGREEMENT_NOT_REQUIRED_MESSAGE =
	'개별적인 동의를 구하지 않았을 경우, <b>개인 식별이 불가능하도록 반드시 가명(이니셜, 닉네임 등)</b>을 사용해 주세요. 또한, 입력 정보 내에 개인 식별이 가능한 정보를 모두 제거해야 합니다. '

/**
 * Error Messages
 */

export const ERROR_MESSAGE_UNKNOWN = '알 수 없는 오류가 발생했습니다. 관리자에게 문의 바랍니다.'

export const ERROR_MESSAGE_USER_ALREADY_JOINED =
	'이미 가입된 계정이 있습니다. 계정정보를 확인해 보세요.'
export const ERROR_MESSAGE_USER_VERIFICATION_FAILED =
	'본인인증에 실패하였습니다. 본인인증을 완료해주세요.'

export const ERROR_MESSAGE_RESEARCH_MEMBER_DUPLICATE = '이미 초대된 사용자입니다.'

export const ERROR_MESSAGE_RESEARCH_MEMBER_ALREADY_JOINED = '이미 참여중인 사용자입니다.'

export const ERROR_MESSAGE_RESEARCH_MEMBER_SINGLE_INST_ONLY =
	'단일 기관 전용 프로젝트입니다. 타 기관 사용자는 참여할 수 없습니다.'
