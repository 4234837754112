import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { UISize } from '../../../enum'
import { ImageIcon } from './ImageIcon'
import StyledSVG from './StyledSVG'

const FONT_SIZE = Object.freeze({
	[UISize.MINI]: 11,
	[UISize.TINY]: 12,
	[UISize.SMALL]: 14,
	[UISize.LARGE]: 17,
	[UISize.HUGE]: 20,
	[UISize.MASSIVE]: 24,
})

export const ImageIconButtonContent = props => {
	const { text, size = 'small', disabled } = props
	return (
		<Container disabled={disabled}>
			<ImageIconButtonImage {...props} />
			<ButtonText size={size}>{text}</ButtonText>
		</Container>
	)
}

ImageIconButtonContent.propTypes = {
	isActive: PropTypes.bool,
	text: PropTypes.string.isRequired,
	imageIcon: PropTypes.string.isRequired,
	activeImageIcon: PropTypes.string,
	size: PropTypes.string,
	disabled: PropTypes.bool,
}

export const ImageIconOnlyButton = props => {
	return (
		<ImageWrapper>
			<ImageIconButtonImage {...props} />
		</ImageWrapper>
	)
}

export const ImageIconButtonImage = ({ isActive, size, imageIcon, activeImageIcon }) => {
	const isSvg = imageIcon.endsWith('svg')
	const src = `/images/icons/${isActive && activeImageIcon ? activeImageIcon : imageIcon}`
	if (isSvg === true) {
		const width = FONT_SIZE[size]
		return (
			<StyledSVG
				css={`
					width: ${width}px;
					height: ${width}px;
				`}
				className={`svg-icon ${isActive === true ? 'active' : ''}`}
				wrapper="span"
				src={src}
			/>
		)
	} else {
		return <ImageIcon size={size} src={src} />
	}
}

ImageIconButtonContent.defaultProps = {
	isActive: false,
	size: UISize.SMALL,
	activeImageIcon: null,
}

const Container = styled.div`
	align-items: center;

	${props => (props.disabled === true ? 'opacity: 0.3;' : '')}
`

const ImageWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
	width: 24px;
	height: 24px;
`

const ButtonText = styled.span`
	font-size: ${props => FONT_SIZE[props.size]}px;
	margin-left: 13px;
`
