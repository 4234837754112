import { useState } from 'react'
import { useMutation } from 'react-apollo'

import { DELETE_PATIENT } from '../graphql/mutations/patient'
import { DELETE_RECORD, DELETE_RECORD_TEMPLATE } from '../graphql/mutations/record'

import { matchErrorCode } from '../libs/errorHelper'
import errorTypes from '../errorTypes'
import useToastMessage from './useToastMessage'
import {
	ALERT_WRONG_PASSWORD,
	ALERT_DELETE_LOCKED_RECORD,
	ALERT_DELETE_NOT_LAST_RECORD,
	ALERT_DELETE_REFERENCED_RECORD_TEMPLATE,
	ALERT_DELETE_REFERENCED_RECORD,
} from '../constants/alerts'

const ObjectType = {
	PATIENT: {
		key: 'patientId',
		value: 'PATIENT',
		text: '환자',
		query: DELETE_PATIENT,
	},
	RECORD: {
		key: 'recordId',
		value: 'RECORD',
		text: '기록',
		query: DELETE_RECORD,
	},
	TEMPLATE: {
		key: 'templateId',
		value: 'TEMPLATE',
		text: '양식',
		query: DELETE_RECORD_TEMPLATE,
	},
}

const ReferencedErrorType = {
	RECORD: {
		key: 'RECORD',
		alert: ALERT_DELETE_REFERENCED_RECORD,
	},
	RECORD_TEMPLATE: {
		key: 'RECORD_TEMPLATE',
		alert: ALERT_DELETE_REFERENCED_RECORD_TEMPLATE,
	},
}

/**
 * 환자/기록 삭제 Modal을 사용하기 쉽게 도와주는 hooks
 */
const useDeleteObject = () => {
	const showToastMessage = useToastMessage()

	const [password, setPassword] = useState('')
	const [open, setOpen] = useState(false)
	const [objectType, setObjectType] = useState(ObjectType.PATIENT.value)
	const [objectId, setObjectId] = useState('')
	const [onComplete, setOnComplete] = useState(() => () => {})
	const [refetchQueries, setRefetchQueries] = useState([])

	const [onDelete] = useMutation(ObjectType[objectType].query, {
		variables: {
			[ObjectType[objectType].key]: objectId,
			password,
		},
		refetchQueries: [...refetchQueries],
	})

	const handleOpen = (objectType, objectId, { onComplete, refetchQueries } = {}) => {
		setObjectType(objectType)
		setObjectId(objectId)
		onComplete != null ? setOnComplete(() => onComplete) : setOnComplete(() => () => {})
		refetchQueries != null ? setRefetchQueries([...refetchQueries]) : setRefetchQueries([])
		setOpen(true)
	}

	const handleClose = () => setOpen(false)

	const handleChange = (e, { value }) => setPassword(value)

	const handleDelete = async () => {
		try {
			await onDelete()
			setPassword('')
			setOpen(false)
			onComplete && onComplete()
		} catch (e) {
			setPassword('')
			const referencedError = matchErrorCode(e, errorTypes.OBJECT_REFERENCED_ERROR)
			if (referencedError) {
				const referencedBy = referencedError.invalidArgs.referencedBy
				showToastMessage(ReferencedErrorType[referencedBy].alert)
				setOpen(false)
			} else if (matchErrorCode(e, errorTypes.AUTHENTICATION_FAILED_ERROR)) {
				showToastMessage(ALERT_WRONG_PASSWORD)
			} else if (matchErrorCode(e, errorTypes.OBJECT_LOCKED_ERROR)) {
				showToastMessage(ALERT_DELETE_LOCKED_RECORD)
				setOpen(false)
			} else if (matchErrorCode(e, errorTypes.NOT_LATEST_RECORD)) {
				showToastMessage(ALERT_DELETE_NOT_LAST_RECORD)
				setOpen(false)
			} else {
				alert('삭제중 오류가 발생했습니다.')
			}
		}
	}
	return {
		handleOpen,
		handleClose,
		handleDelete,
		password,
		open,
		handleChange,
		objectText: ObjectType[objectType].text,
	}
}
export default useDeleteObject
