import React from 'react'
import PropTypes from 'prop-types'
import qs from 'querystring'
import { Query } from 'react-apollo'

import LoadingView from '../../../common/loader'
import InstitutionView from './ResearchMemberInstitutionView'
import { GET_RESEARCHER_INSTITUTION_PERMISSION } from '../../../../graphql/queries/record'
import ErrorView from '../../../common/error/ErrorView'

/**
 * 프로젝트 멤버 참여 기관별 권한 설정
 *
 * 데이터 불러오기
 */
class ResearcherInstitutionSettings extends React.PureComponent {
	static propTypes = {
		location: PropTypes.shape({
			search: PropTypes.string,
		}),
		match: PropTypes.shape({
			params: PropTypes.shape({
				id: PropTypes.string,
				subId: PropTypes.string,
			}),
		}),
	}
	render() {
		const {
			location: { search },
			match: {
				params: { id: researchId, subId: memberId },
			},
		} = this.props

		const queries = qs.parse(search, { ignoreQueryPrefix: true })
		const page = queries.page || 1

		return (
			<Query
				query={GET_RESEARCHER_INSTITUTION_PERMISSION}
				variables={{
					page,
					perPage: 20,
					researchId: parseInt(researchId, 10),
					userId: parseInt(memberId, 10),
				}}
				notifyOnNetworkStatusChange
			>
				{({ loading, data, error, refetch }) => {
					if (loading) {
						return <LoadingView />
					}

					if (error) {
						return <ErrorView error={error} />
					}
					const { items, ...other } = data.researcherInstitutionPermissions

					return (
						<div>
							{
								<InstitutionView
									{...this.props}
									institutions={items}
									onUpdate={refetch}
									{...other}
								/>
							}
						</div>
					)
				}}
			</Query>
		)
	}
}

export default ResearcherInstitutionSettings
