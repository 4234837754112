import React from 'react'
import PropTypes from 'prop-types'
import MenuContainer from '../components/common/container/MenuContainer'
import PrescriptionManager from './PrescriptionManager'
import AcupunctureManager from './AcupunctureManager'
import {
	ROUTE_PROJECT_DETAIL_TEMPLATES_LIBRARY_PRESCRIPTION,
	ROUTE_PROJECT_DETAIL_TEMPLATES_LIBRARY_ACUPUNCTURE,
} from '../constants/routes'

const MENU_ITEMS = [
	{
		value: 'prescription',
		to: ROUTE_PROJECT_DETAIL_TEMPLATES_LIBRARY_PRESCRIPTION,
		text: '처방',
		component: PrescriptionManager,
	},
	{
		value: 'acupuncture',
		to: ROUTE_PROJECT_DETAIL_TEMPLATES_LIBRARY_ACUPUNCTURE,
		text: '침구 관리',
		component: AcupunctureManager,
	},
]

const ResearchLibraryManager = props => {
	const { researchId } = props

	return (
		<div>
			<MenuContainer
				isVertical
				defaultMenu="prescription"
				paramKey="subMenu"
				menuItems={MENU_ITEMS}
				replaceParam={{
					id: researchId,
				}}
				basicProps={props}
			/>
		</div>
	)
}

export default ResearchLibraryManager

ResearchLibraryManager.propTypes = {
	researchId: PropTypes.number.isRequired,
}
