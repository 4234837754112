import React, { useState, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MyIcon from './basic/MyIcon'
import { UISize } from '../../enum'
import { makeRandomId } from '../../libs/stringHelper'
import { FILE_ACCEPT_OPTIONS } from '../../constants'

/**
 * 파일 업로드 컴퍼넌트
 *
 * 파일형, 설명 메시지 등을 설정하고 파일 선택 후 동작을 설정할 수 있음
 * input click 연결을 위해 unique한 id를 사용하도록 함
 */
const FileUploadBox = ({ icon, accept, onChangeFile, description, fileId = makeRandomId(5) }) => {
	const [fileName, setFileName] = useState(null)
	const onChange = ({
		target: {
			validity,
			files: [file],
		},
	}) => {
		setFileName(file.name)
		if (validity.valid) {
			onChangeFile(file)
		}
	}

	const acceptStr = useMemo(() => {
		if (typeof accept === 'string') {
			return FILE_ACCEPT_OPTIONS[accept]
		} else if (accept instanceof Array) {
			return accept.map(i => FILE_ACCEPT_OPTIONS[i]).join(', ')
		}
	}, [accept])

	const fileRef = useRef(null)
	const id = `${fileId}-file-input`

	return (
		<Container
			htmlFor={id}
			onClick={() => {
				if (fileRef.current != null) {
					fileRef.current.click()
				}
			}}
		>
			<input id={id} type="file" onChange={onChange} hidden ref={fileRef} accept={acceptStr} />
			<MyIcon name={icon} size={UISize.SMALL} />
			<FileName>{fileName == null ? description : fileName}</FileName>
		</Container>
	)
}

FileUploadBox.propTypes = {
	onChangeFile: PropTypes.func.isRequired,
	description: PropTypes.string,
	fileId: PropTypes.string,
	accept: PropTypes.oneOfType([
		PropTypes.oneOf(Object.keys(FILE_ACCEPT_OPTIONS)),
		PropTypes.arrayOf(PropTypes.string),
	]),
	icon: PropTypes.string,
}

FileUploadBox.defaultProps = {
	description: '클릭하여 파일을 업로드할 수 있습니다.',
	accept: 'all',
	icon: 'excel',
}

export default FileUploadBox

const Container = styled.div`
	border-radius: 2px;
	border: ${props => props.theme.BORDER_GRAY};
	display: flex;
	padding: 12px 20px;
	align-items: center;
	cursor: pointer;
`

const FileName = styled.div`
	margin-left: 20px;
	flex: 1;
`
