import styled from 'styled-components'

const DataFieldDiv = styled.div`
	border-radius: 2px;
	position: relative;
	cursor: pointer;
	opacity: ${props => (props.disabled === true ? 0.5 : 1)};

	#data-field-edit-${props => props.medInfoId}:not(.active) > i {
		opacity: 0;
	}

	:hover {
		box-shadow: ${props => (props.noHover !== true ? props.theme.BOX_SHADOW_CHART_HOVER : 'none')};
	}

	:hover #data-field-edit-${props => props.medInfoId} > i {
		opacity: 1;
	}
`

export default DataFieldDiv
