import React from 'react'
import PropTypes from 'prop-types'
import { useDragLayer } from 'react-dnd'
import { DRAG_ITEM_TYPES } from '../../constants'
import PartialTemplateItem from '../research/templates/PartialTemplateItem'
import DataField from './field/DataField'
import DataFieldBox from './field/DataFieldBox'
import { ChartingSectionView } from './ChartingSection'

/**
 * Custom Drag Layer 설정
 *
 * 기본 드래그는 드래그할때 반투명 상태로 되기 때문에, 직접 Preview를 세팅해줘야 반투명해지지 않고 드래그할 수 있음.
 */
const ChartDragLayer = () => {
	const { itemType, item, initialOffset, currentOffset } = useDragLayer(monitor => ({
		item: monitor.getItem(),
		itemType: monitor.getItemType(),
		initialOffset: monitor.getInitialSourceClientOffset(),
		currentOffset: monitor.getSourceClientOffset(),
		isDragging: monitor.isDragging(),
	}))

	return (
		<div style={layerStyles}>
			<div
				style={{
					...getItemStyles(initialOffset, currentOffset),
					opacity: 1,
				}}
			>
				{renderItem(item, itemType)}
			</div>
		</div>
	)
}

export default ChartDragLayer

/**
 * 실시간으로 preview 위치를 처리함
 */
function getItemStyles(initialOffset, currentOffset) {
	if (!initialOffset || !currentOffset) {
		return {
			display: 'none',
		}
	}

	let { x, y } = currentOffset

	const transform = `translate(${x}px, ${y}px)`
	return {
		transform,
		WebkitTransform: transform,
	}
}

const layerStyles = {
	position: 'fixed',
	pointerEvents: 'none',
	zIndex: 900, // Semantic ui sticky 보다 높게 설정
	left: 0,
	top: 0,
	width: '100%',
	height: '100%',
}

function renderItem(item, itemType) {
	switch (itemType) {
		case DRAG_ITEM_TYPES.PARTIAL_TEMPLATE:
			return <PartialTemplateItem {...item} />
		case DRAG_ITEM_TYPES.DATA_FIELD:
			return <DataField {...item} />
		case DRAG_ITEM_TYPES.SECTION:
			return (
				<div style={{ width: item.width || 700 }}>
					<ChartingSectionView {...item} />
				</div>
			)
		default:
			return null
	}
}
