import gql from 'graphql-tag'

/**
 * 프로젝트 정보
 */
export const RESEARCH_FRAGMENT = gql`
	fragment ResearchParts on Research {
		id
		title
		type
		templateType
		hashPatient
		recordTemplates {
			id
			title
		}
		patientBasicInfo
		requiresAgreement
		hideName
	}
`

/**
 * 프로젝트 상세 정보(기본)
 */
export const RESEARCH_BASIC_FRAGMENT = gql`
	fragment ResearchBasicParts on Research {
		id
		title
		description
		type
		hasScreening
		status
		hashPatient
		organizedBy {
			id
			name
		}
		startDate
		endDate
		patientBasicInfo
		requiresAgreement
		templateType
		scope
		screeningCode
		registrationCode
		hasPartialTemplate
		hideName
		isOfficial
		estSubjects
	}
`

/**
 * 프로젝트 상세 정보(기본2)
 */
export const RESEARCH_BASIC_INFO_FRAGMENT = gql`
	fragment ResearchBasicInfoParts on Research {
		id
		title
		description
		type
		status
		organizedBy {
			id
			name
		}
		director {
			id
			name
		}
		startDate
		endDate
		patientBasicInfo
		requiresAgreement
		doctorCount
		patientCount
		estSubjects
		hashPatient
		samplingPlan
		templateType
		department {
			id
		}
	}
`

/**
 * 프로젝트 참여자 정보
 */
export const RESEARCHER_INFO_FRAGMENT = gql`
	fragment ResearcherInfoParts on ResearcherInfo {
		id
		role
		canInput
		canChangeSettings
		canFixTemplate
		canEdit
		canAccess
		description
		isBlinded
		status
		canAccessAll
		canEditAll
	}
`

/**
 * 프로젝트 참여자 정보 + 사용자 정보
 */
export const RESEARCHER_FRAGMENT = gql`
	fragment ResearcherParts on Researcher {
		user {
			id
			name
			profileImage
			position
			institution {
				name
				id
			}
		}
		researcherInfo {
			...ResearcherInfoParts
		}
	}
	${RESEARCHER_INFO_FRAGMENT}
`

/**
 * 프로젝트 참여자 기관별 권한 정보
 */
export const RESEARCHER_INSTITUTION_PERMISSION = gql`
	fragment ResearcherInstitutionPermissionParts on ResearcherInstitutionPermission {
		institution {
			id
			name
		}
		canAccess
		canEdit
	}
`

/**
 * 연구 사이드 메뉴 등에서 사용되는 기본 정보
 */
export const RESEARCH_SIMPLE_INFO_FRAGMENT = gql`
	fragment ResearchSimpleInfoParts on Research {
		id
		title
		status
		doctorCount
		patientCount
		type
		userResearcherInfo {
			...ResearcherInfoParts
		}
	}
	${RESEARCHER_INFO_FRAGMENT}
`
