import { useState, useEffect } from 'react'

const useStateWithProp = propValue => {
	const [value, setValue] = useState(propValue)
	useEffect(() => {
		setValue(propValue)
	}, [propValue])
	return [value, setValue]
}

export default useStateWithProp
