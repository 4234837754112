import gql from 'graphql-tag'

export const GET_ISSUE = gql`
	query getResearchIssue($issueId: Int!) {
		issueDetail(issueId: $issueId) {
			title
			id
			text
			updatedAt
			createdAt
			isClosed
			writer {
				id
				name
				profileImage
			}
			comments {
				totalCount
				items {
					id
					writer {
						id
						name
						profileImage
					}
					text
					createdAt
				}
			}
			type {
				type
				name
			}
		}
	}
`
