import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import compose from 'lodash.flowright'
import { graphql, withApollo } from 'react-apollo'

import { Modal, Button } from 'semantic-ui-react'
import MedInfoQueryForm from './MedInfoQueryForm'
import { WRITE_MED_INFO_QUERY } from '../../../../graphql/mutations/medInfo'
import InfoPopup from '../../../common/popup/InfoPopup'
import { GET_MED_INFO_QUERY } from '../../../../graphql/queries/medInfo'
import { DEFAULT_MED_INFO_QUERIES_PER_PAGE } from '../../../../constants'

class MedInfoQueryWriteModal extends PureComponent {
	static propTypes = {
		medInfoId: PropTypes.number,
		medInfoTypeName: PropTypes.string,
		open: PropTypes.bool,
		onClose: PropTypes.func,
		onWriteMedInfoQuery: PropTypes.func,
		onUpdateSingleMedInfo: PropTypes.func,
	}

	state = {
		message: '',
		loading: false,
	}

	handleChange = (e, { value }) => {
		this.setState({
			message: value,
		})
	}

	handleSubmit = async () => {
		const { client, medInfoId, onClose, onWriteMedInfoQuery, onUpdateSingleMedInfo } = this.props

		const { message } = this.state

		this.setState({
			loading: true,
		})
		try {
			const {
				data: { writeMedInfoQuery: medInfo_ },
			} = await onWriteMedInfoQuery({
				variables: {
					medInfoId,
					message,
				},
			})

			onClose()
			this.setState({
				loading: false,
				message: '',
			})

			onUpdateSingleMedInfo(medInfoId, medInfo_)

			client.query({
				query: GET_MED_INFO_QUERY,
				variables: {
					medInfoId,
					pagination: {
						page: 1,
						perPage: DEFAULT_MED_INFO_QUERIES_PER_PAGE,
					},
				},
				fetchPolicy: 'network-only',
			})
		} catch (e) {
			alert('오류가 발생했습니다.')
		}
	}

	handleClose = () => {
		const { onClose } = this.props

		this.setState({
			message: '',
		})
		onClose()
	}

	render() {
		const { open, onClose, medInfoTypeName, medInfoId } = this.props
		const { message, loading } = this.state

		return (
			<Modal open={open} size="small" onClose={onClose} closeOnDimmerClick={false}>
				<Modal.Header>
					리뷰 메시지 작성
					<InfoPopup
						style={{ marginLeft: 12 }}
						description="데이터 수정이 필요할 경우, 기록 작성자에게 수정을 요청할 수 있습니다."
					/>
					<span style={{ float: 'right', color: '#999', fontSize: 16, fontWeight: 300 }}>
						{medInfoTypeName}
					</span>
				</Modal.Header>
				<Modal.Content>
					<MedInfoQueryForm onChange={this.handleChange} message={message} />
				</Modal.Content>
				<Modal.Actions>
					<Button loading={loading} onClick={this.handleSubmit} primary>
						등록
					</Button>
					<Button onClick={this.handleClose}>취소</Button>
				</Modal.Actions>
			</Modal>
		)
	}
}

export default compose(
	withApollo,
	graphql(WRITE_MED_INFO_QUERY, {
		name: 'onWriteMedInfoQuery',
	})
)(MedInfoQueryWriteModal)
