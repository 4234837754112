/**
 * [Component] 다수의 MedInfo를 포함하는 Section
 *
 * 다수의 MedInfo를 포함하고 개별적으로 필드 추가가 가능한 Section
 */

import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Grid } from 'semantic-ui-react/dist/commonjs'
import { useDrag, useDrop } from 'react-dnd'

import { getEmptyImage } from 'react-dnd-html5-backend'
import withMedInfos from '../../higherComponents/withMedInfos'
import DataFieldBox from './field/DataFieldBox'
import DataFieldEditDropdown from './control/DataFieldEditDropdown'

import { DRAG_ITEM_TYPES } from '../../constants'
import { COLOR_PRIMARY } from '../../constants/styles'
import DataFieldDiv from './field/DataFieldDiv'

import Arrow from '../common/basic/Arrow'
import { InputSize } from '../../enum'
import DataFieldAddButton from './field/DataFieldAddButton'

export const ChartingSection = withMedInfos(props => {
	const {
		isDefaultClosed,
		name,
		medInfoIndex: sectionIndex,
		medInfos,
		medInfoType: sectionType,
		connectDragSource,
		connectDragPreview,
		connectDropTarget,
		description,
		medInfoId: sectionId,
		onMoveSectionWithId,
		width,
		...others
	} = props

	const {
		isFixed,
		templateMode,
		onOpenTypeModal,
		onPrint,
		onDeleteSection,
		onOpenSectionModal,
		onUpdateMedInfo,
	} = others

	const { size: sectionSize } = sectionType
	const [isOpen, setOpen] = useState(!isDefaultClosed)
	const [, dropRef] = useDrop({
		accept: DRAG_ITEM_TYPES.SECTION,
		drop: ({ id, originalIndex }, monitor) => {
			if (monitor.canDrop() !== true) onMoveSectionWithId(id, originalIndex)
		},
		hover: ({ id }, monitor) => {
			if (monitor.canDrop() === true && id !== sectionId) {
				onMoveSectionWithId(id, sectionIndex)
			}
		},
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
	})

	const [{ isDragging }, dragRef, preview] = useDrag({
		item: {
			id: sectionId,
			type: DRAG_ITEM_TYPES.SECTION,
			name,
			medInfos,
			templateMode,
			sectionSize,
			isOpen,
			originalIndex: sectionIndex,
			width,
		},
		collect: monitor => ({
			isDragging: !!monitor.isDragging(),
		}),
	})

	const canDrag = isFixed !== true || templateMode === true

	const hideDisabled = sectionType.format != null && sectionType.format.includes('hideDisabled')

	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true })
	}, [preview])

	const handleClickSettingMenu = useCallback(
		(e, { value }) => {
			if (value === 'delete') {
				onDeleteSection(sectionIndex)
			} else if (value === 'edit') {
				onOpenSectionModal(sectionType, sectionIndex)
			} else if (value === 'print') {
				onPrint(sectionId)
			}
		},
		[onDeleteSection, onOpenSectionModal, onPrint, sectionId, sectionIndex, sectionType]
	)

	const handleOpenEditModal = params => {
		onOpenTypeModal({ title: name, onSubmit: onUpdateMedInfo, ...params })
	}

	const canEdit = isFixed !== true || templateMode === true
	return (
		<div style={{ height: isOpen === true ? '100%' : 'auto' }}>
			<ChartingSectionView
				{...props}
				// dimRef={dimRef}
				width={width}
				dragRef={dragRef}
				dropRef={dropRef}
				isDragging={isDragging}
				isOpen={isOpen}
				setOpen={setOpen}
				canEdit={canEdit}
				sectionSize={sectionSize}
				handleClickSettingMenu={handleClickSettingMenu}
				handleOpenEditModal={handleOpenEditModal}
				canDrag={canDrag}
				hideDisabled={hideDisabled}
			/>
		</div>
	)
})

ChartingSection.propTypes = {
	name: PropTypes.string.isRequired,
	medInfos: PropTypes.arrayOf(
		PropTypes.shape({
			medInfoType: PropTypes.shape({
				id: PropTypes.number,
			}),
		})
	).isRequired,
	medInfoType: PropTypes.shape({
		id: PropTypes.number,
		size: PropTypes.string,
		inputType: PropTypes.string,
	}).isRequired,
	medInfoTypes: PropTypes.arrayOf(),
	onDeleteMedInfo: PropTypes.func.isRequired,
	onUpdateMedInfo: PropTypes.func.isRequired,
	onCreateMedInfo: PropTypes.func,
	onOpenAddModal: PropTypes.func,
	medInfoIndex: PropTypes.number.isRequired,
	templateMode: PropTypes.bool,
	onDecideSizeInfo: PropTypes.func,
	description: PropTypes.string,
	isFixed: PropTypes.bool,
	isSchedule: PropTypes.bool,
	onSearchMedInfoTypes: PropTypes.func.isRequired,
	connectDragSource: PropTypes.func,
	connectDragPreview: PropTypes.func,
	connectDropTarget: PropTypes.func,
	isDragging: PropTypes.bool,
	isOver: PropTypes.bool,
	draggedItemType: PropTypes.string,
	onInsertMedInfo: PropTypes.func,
	onOpenTypeModal: PropTypes.func.isRequired,
	onDeleteSection: PropTypes.func.isRequired,
	onOpenSectionModal: PropTypes.func,
	isDefaultClosed: PropTypes.bool,
	medInfo: PropTypes.shape({
		id: PropTypes.number,
		medInfoType: PropTypes.object,
		children: PropTypes.array,
	}),
	onPrint: PropTypes.func,
}

ChartingSection.defaultProps = {
	templateMode: false,
	onOpenAddModal: () => {},
	description: null,
	isSchedule: null,
	medInfoTypes: null,
	isDragging: false,
	isOver: false,
	draggedItemType: null,
	isDefaultClosed: false,
}

export const ChartingSectionView = React.memo(
	props => {
		const {
			isDefaultClosed,
			name,
			medInfoIndex: sectionIndex,
			medInfos,
			isDragging,
			isOver,
			draggedItemType,
			description,
			medInfoId: sectionId,
			isOpen,
			setOpen,
			handleClickSettingMenu,
			canEdit,
			handleOpenEditModal,
			dragRef,
			dropRef,
			...others
		} = props

		const {
			isFixed,
			templateMode,
			readOnly,
			onSearchMedInfoTypes,
			onOpenTypeModal,
			onInsertMedInfo,
			onCreateMedInfo,
			onPrint,
		} = others

		return (
			<Container ref={dropRef} isOpen={isOpen} medInfoId={sectionId} isDragging={isDragging}>
				<div>
					<Header
						onClick={() => {
							setOpen(prev => !prev)
						}}
						ref={isFixed === true ? null : dragRef}
					>
						<HeaderText>{name}</HeaderText>
						<Arrow isOpen={isOpen} />

						{readOnly !== true && (isFixed !== true || templateMode === true) && (
							<DataFieldEditDropdown
								medInfoId={sectionId}
								style={ChartingSectionStyles.button}
								noDelete={canEdit !== true}
								noCopy
								canPrint={onPrint != null}
								// onResize={this.handleResize}
								onClickMenu={handleClickSettingMenu}
							/>
						)}
						{/* {!isFixed &&
										(!templateMode ? (
											<AddMedInfoMenuDropdown
												onCreateMedInfo={onCreateMedInfo}
												medInfoTypes={medInfoTypes}
											/>
										) : (
											<span>
												<DataFieldEditDropdown
													style={ChartingSectionStyles.button}
													noCopy
													onClickMenu={this.handleClickSettingMenu}
												/>
											</span>
										))} */}
					</Header>
				</div>
				{description && isOpen && (
					<div style={ChartingSectionStyles.description}>{description}</div>
				)}
				<Grid
					verticalAlign="top"
					stretched={false}
					style={{
						...ChartingSectionStyles.content,
						display: isOpen ? 'flex' : 'none',
						background:
							isOver && draggedItemType === DRAG_ITEM_TYPES.DATA_FIELD && 'rgba(22,83,183,0.2)',
					}}
				>
					{medInfos &&
						medInfos.map((medInfo, medInfoIndex) => {
							const { id, medInfoType, children } = medInfo
							const { size, inputType } = medInfoType
							let sizeInfo
							if (size != null) {
								sizeInfo = {
									className: InputSize[size].className,
								}
							} else {
								sizeInfo = {
									className: InputSize.LARGE.className,
								}
							}

							return (
								<Grid.Column style={{ padding: 1 }} key={`${medInfoType.id}`} {...sizeInfo}>
									<DataFieldBox
										{...others}
										medInfo={medInfo}
										inputType={inputType}
										medInfoIndex={medInfoIndex}
										onOpenEditModal={handleOpenEditModal}
										medInfos={children}
										medInfoId={id}
										sectionIndex={sectionIndex}
									/>
								</Grid.Column>
							)
						})}
					{canEdit === true && (
						<DataFieldAddButton
							onCreateMedInfo={onCreateMedInfo}
							onInsertMedInfo={onInsertMedInfo}
							onOpenTypeModal={onOpenTypeModal}
							onSearchMedInfoTypes={onSearchMedInfoTypes}
							name={name}
						/>
					)}
				</Grid>
			</Container>
		)
	},
	(prev, next) => {
		const values = ['isOpen', 'medInfos', 'name', 'sectionSize', 'isDragging', 'description']

		return (
			values.find(prop => prev[prop] !== next[prop]) == null &&
			prev.readOnly === next.readOnly &&
			(prev.medInfoType != null &&
				next.medInfoType != null &&
				prev.medInfoType.format === next.medInfoType.format)
		)
	}
)

ChartingSectionView.propTypes = {
	isDefaultClosed: PropTypes.bool,
	name: PropTypes.string,
	medInfoIndex: PropTypes.number,
	medInfos: PropTypes.array,
	medInfoType: PropTypes.any,
	isDragging: PropTypes.bool,
	isOver: PropTypes.bool,
	draggedItemType: PropTypes.any,
	description: PropTypes.string,
	medInfoId: PropTypes.number,
	isOpen: PropTypes.bool,
	setOpen: PropTypes.func,
	handleClickSettingMenu: PropTypes.func,
	canEdit: PropTypes.bool,
	handleOpenEditModal: PropTypes.func,
	dragRef: PropTypes.func,
	dropRef: PropTypes.func,
}

// const sectionTarget = {
// 	drop(props, monitor) {
// 		if (monitor.isOver() === true) {
// 			return {
// 				sectionIndex: props.medInfoIndex,
// 				medInfoId: props.medInfoId,
// 				isAdding: true,
// 			}
// 		}
// 	},
// }

// const sectionSource = {
// 	beginDrag(props) {
// 		return {
// 			sectionIndex: props.medInfoIndex,
// 		}
// 	},
// 	canDrag(props) {
// 		return props.isFixed !== true
// 	},
// 	endDrag(props, monitor) {
// 		if (!monitor.didDrop()) {
// 			return
// 		}

// 		const source = monitor.getItem()
// 		const target = monitor.getDropResult()

// 		if (source.sectionIndex === target.sectionIndex) {
// 			return
// 		}

// 		props.onMoveSection(source.sectionIndex, target.sectionIndex)
// 	},
// }

// function collectTarget(connect, monitor) {
// 	return {
// 		connectDropTarget: connect.dropTarget(),
// 		isOver: monitor.isOver({ shallow: true }),
// 		draggedItemType: monitor.getItemType(),
// 	}
// }

// function collectSource(connect, monitor) {
// 	return {
// 		connectDragSource: connect.dragSource(),
// 		connectDragPreview: connect.dragPreview(),
// 		isDragging: monitor.isDragging(),
// 	}
// }

// /**
//  * 카테고리별 차트 섹션
//  * medInfos 와 type정보를 받아 datafieldbox를 생성한다
//  */
// class ChartingSection1 extends PureComponent {
// 	static propTypes = {
// 		name: PropTypes.string.isRequired,
// 		medInfos: PropTypes.arrayOf(
// 			PropTypes.shape({
// 				medInfoType: PropTypes.shape({
// 					id: PropTypes.number,
// 				}),
// 			})
// 		).isRequired,
// 		medInfoType: PropTypes.shape({
// 			id: PropTypes.number,
// 			size: PropTypes.string,
// 			inputType: PropTypes.string,
// 		}).isRequired,
// 		medInfoTypes: PropTypes.arrayOf(),
// 		onDeleteMedInfo: PropTypes.func.isRequired,
// 		onUpdateMedInfo: PropTypes.func.isRequired,
// 		onCreateMedInfo: PropTypes.func.isRequired,
// 		onOpenAddModal: PropTypes.func,
// 		medInfoIndex: PropTypes.number.isRequired,
// 		templateMode: PropTypes.bool,
// 		onDecideSizeInfo: PropTypes.func.isRequired,
// 		description: PropTypes.string,
// 		isFixed: PropTypes.bool,
// 		isSchedule: PropTypes.bool,
// 		onSearchMedInfoTypes: PropTypes.func.isRequired,
// 		connectDragSource: PropTypes.func.isRequired,
// 		connectDragPreview: PropTypes.func.isRequired,
// 		connectDropTarget: PropTypes.func.isRequired,
// 		isDragging: PropTypes.bool,
// 		isOver: PropTypes.bool,
// 		draggedItemType: PropTypes.string,
// 		onInsertMedInfo: PropTypes.func.isRequired,
// 		onOpenTypeModal: PropTypes.func.isRequired,
// 		onDeleteSection: PropTypes.func.isRequired,
// 		onOpenSectionModal: PropTypes.func,
// 		isDefaultClosed: PropTypes.bool,
// 		medInfo: PropTypes.shape({
// 			id: PropTypes.number,
// 			medInfoType: PropTypes.object,
// 			children: PropTypes.array,
// 		}),
// 		onPrint: PropTypes.func,
// 	}

// 	static defaultProps = {
// 		templateMode: false,
// 		onOpenAddModal: () => {},
// 		description: null,
// 		isSchedule: null,
// 		medInfoTypes: null,
// 		isDragging: false,
// 		isOver: false,
// 		draggedItemType: null,
// 		isDefaultClosed: false,
// 	}

// 	constructor(props) {
// 		super(props)

// 		this.state = {
// 			isOpen: !props.isDefaultClosed,
// 		}
// 	}

// 	handleOpenEditModal = params => {
// 		const { name, onOpenTypeModal, onUpdateMedInfo } = this.props
// 		onOpenTypeModal({ title: name, onSubmit: onUpdateMedInfo, ...params })
// 	}

// 	// 세팅 메뉴 클릭 처리
// 	handleClickSettingMenu = (e, { value }) => {
// 		const {
// 			onDeleteSection,
// 			onPrint,
// 			onOpenSectionModal,
// 			medInfo: { id: sectionId },
// 			medInfoType,
// 			medInfoIndex,
// 		} = this.props
// 		if (value === 'delete') {
// 			onDeleteSection(medInfoIndex)
// 		} else if (value === 'edit') {
// 			onOpenSectionModal(medInfoType, medInfoIndex)
// 		} else if (value === 'print') {
// 			onPrint(sectionId)
// 		}
// 	}

// 	toggleOpen = () => this.setState(prev => ({ isOpen: !prev.isOpen }))

// 	handleResize = (e, { value }) => {
// 		const {
// 			medInfo: { medInfoType },
// 			onUpdateMedInfo,
// 			medInfoIndex,
// 		} = this.props

// 		onUpdateMedInfo(
// 			{
// 				medInfoType: {
// 					...medInfoType,
// 					size: value,
// 				},
// 			},
// 			medInfoIndex
// 		)
// 	}

// 	render() {
// 		const { isOpen } = this.state
// 		const {
// 			name,
// 			medInfoIndex: sectionIndex,
// 			medInfos,
// 			medInfoType: sectionType,
// 			connectDragSource,
// 			connectDragPreview,
// 			connectDropTarget,
// 			isDragging,
// 			isOver,
// 			draggedItemType,
// 			description,
// 			medInfo: { id: sectionId },
// 			...others
// 		} = this.props

// 		const {
// 			isFixed,
// 			templateMode,
// 			onSearchMedInfoTypes,
// 			onOpenTypeModal,
// 			onInsertMedInfo,
// 			onCreateMedInfo,
// 			onDecideSizeInfo,
// 			onPrint,
// 		} = others

// 		const canEdit = isFixed !== true || templateMode === true
// 		const { size: sectionSize } = sectionType
// 		return connectDropTarget(
// 			connectDragPreview(
// 				<div style={{ height: isOpen === true ? '100%' : 'auto' }}>
// 					<Container isOpen={isOpen} medInfoId={sectionId}>
// 						{connectDragSource(
// 							<div>
// 								<Header className="fjhwie" onClick={this.toggleOpen}>
// 									<HeaderText>{name}</HeaderText>
// 									<Arrow isOpen={isOpen} />

// 									<DataFieldEditDropdown
// 										medInfoId={sectionId}
// 										style={ChartingSectionStyles.button}
// 										noDelete={canEdit !== true}
// 										noCopy
// 										canPrint={onPrint != null}
// 										// onResize={this.handleResize}
// 										onClickMenu={this.handleClickSettingMenu}
// 									/>
// 									{/* {!isFixed &&
// 										(!templateMode ? (
// 											<AddMedInfoMenuDropdown
// 												onCreateMedInfo={onCreateMedInfo}
// 												medInfoTypes={medInfoTypes}
// 											/>
// 										) : (
// 											<span>
// 												<DataFieldEditDropdown
// 													style={ChartingSectionStyles.button}
// 													noCopy
// 													onClickMenu={this.handleClickSettingMenu}
// 												/>
// 											</span>
// 										))} */}
// 								</Header>
// 							</div>
// 						)}
// 						{description && isOpen && (
// 							<div style={ChartingSectionStyles.description}>{description}</div>
// 						)}
// 						<Grid
// 							verticalAlign="top"
// 							stretched={false}
// 							style={{
// 								...ChartingSectionStyles.content,
// 								display: isOpen ? 'flex' : 'none',
// 								background:
// 									isOver && draggedItemType === DRAG_ITEM_TYPES.DATA_FIELD && 'rgba(22,83,183,0.2)',
// 							}}
// 						>
// 							{medInfos &&
// 								medInfos.map((medInfo, medInfoIndex) => {
// 									const { id, medInfoType, children } = medInfo
// 									const { size, inputType } = medInfoType
// 									const sizeInfo = onDecideSizeInfo(size, sectionSize)

// 									return (
// 										<Grid.Column style={{ padding: 1 }} key={`${medInfoType.id}`} {...sizeInfo}>
// 											<DataFieldBox
// 												{...others}
// 												medInfo={medInfo}
// 												inputType={inputType}
// 												medInfoIndex={medInfoIndex}
// 												onOpenEditModal={this.handleOpenEditModal}
// 												medInfos={children}
// 												medInfoId={id}
// 												sectionIndex={sectionIndex}
// 											/>
// 										</Grid.Column>
// 									)
// 								})}
// 							{canEdit === true && (
// 								<AddButtonColumn mobile={16}>
// 									<StyledAddFieldDropdown
// 										className="add-field-dropdown"
// 										trigger={
// 											<AddButtonButton>
// 												<AddButtonImg src={AddButtonIcon} />
// 												<AddButtonText>새로운 필드</AddButtonText>
// 											</AddButtonButton>
// 										}
// 										parentName={name}
// 										onCreateMedInfo={onCreateMedInfo}
// 										onInsertMedInfo={onInsertMedInfo}
// 										onOpenTypeModal={onOpenTypeModal}
// 										onSearch={onSearchMedInfoTypes}
// 									/>
// 								</AddButtonColumn>
// 							)}
// 						</Grid>
// 					</Container>
// 				</div>
// 			)
// 		)
// 	}
// }

// export default DropTarget(
// 	[DRAG_ITEM_TYPES.SECTION, DRAG_ITEM_TYPES.DATA_FIELD],
// 	sectionTarget,
// 	collectTarget
// )(DragSource(DRAG_ITEM_TYPES.SECTION, sectionSource, collectSource)(withMedInfos(ChartingSection)))

const Container = styled(DataFieldDiv)`
	/* box-shadow: 0 2px 4px 0 #f5f5f5; */
	border: 1px solid #ececec;
	background: white;
	height: 100%;

	.printed-preview & {
		border-color: #333;
	}

	${props =>
		props.isDragging === true
			? `
	background: ${props.theme.COLOR_BACKGROUND_DARK_GRAY} !important;

		& div {
			opacity: 0;
		}
	`
			: ''};
`

const Header = styled.div`
	padding: 16px 40px;
	border-radius: 2px 2px 0px 0px;
	border-bottom: 1px solid #ececec;
	text-align: left;

	.printed-preview & {
		border-color: #333;
		padding: 5px 20px;
		background: rgba(0, 0, 0, 0.05);
	}

	.printed-preview & .section-arrow {
		display: none;
	}
`

const HeaderText = styled.div`
	line-height: 26px;
	font-size: 16px;
`

const ChartingSectionStyles = {
	container: {
		borderRadius: 10,
		// boxShadow: 'rgba(15, 15, 15, 0.1) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 2px 4px',
		display: 'flex',
		flexDirection: 'column',
		background: 'white',
		margin: '0px',
	},
	content: {
		padding: '15px 30px',
		minHeight: 100,
		paddingTop: 0,
		marginTop: 15,
	},
	button: {
		position: 'absolute',
		right: 10,
		top: 15,
	},
	description: {
		color: COLOR_PRIMARY,
		letterSpacing: '0.5px',
		lineHeight: '1.3em',
		padding: 25,
		paddingBottom: 5,
		whiteSpace: 'pre-line',
	},
}
