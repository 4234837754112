import React, { PureComponent } from 'react'
import styled from 'styled-components'

import PropTypes from 'prop-types'
import { Form, Input, TextArea, Button, Header } from 'semantic-ui-react/dist/commonjs'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { Select, Checkbox, Radio, Popup, Icon } from 'semantic-ui-react'
import {
	ResearchStatus,
	ResearchType,
	InputType,
	ResearchTemplateType,
	ResearchScope,
} from '../../../enum'
import SubContainer from '../../common/container/SubContainer'
import FieldsContainer from '../../common/container/FieldsContainer'

const SELECT_OPTIONS = Object.keys(ResearchStatus).reduce((opts, key) => {
	const item = ResearchStatus[key]
	return opts.concat({
		key,
		text: item.text,
		value: key,
		label: { color: item.color, empty: true, circular: true },
	})
}, [])

const FIELDS = [
	{
		key: 'type',
		title: '프로젝트 종류',
		options: Object.values(ResearchType),
		control: Select,
		readOnly: true,
	},
	{
		key: 'templateType',
		title: '양식 종류',
		options: Object.values(ResearchTemplateType),
		control: Select,
		readOnly: true,
	},
	{
		key: 'scope',
		title: '공개 범위',
		options: Object.values(ResearchScope),
		control: Select,
		readOnly: true,
	},
	{
		key: 'registrationCode',
		title: '대상자 등록 코드',
		info: (
			<div>
				대상자가 프로젝트에 등록되는 시점에 부여되는 코드 규칙입니다.
				<br />
				<br />
				기관별 코드 - &#123;institution&#125;
				<br /> 사용자별 코드 - &#123;user&#125;
				<br /> 순서 - &#123;order[자리수]&#125;
				<br />
				<br /> 예시: &#123;institution&#125;-&#123;order[3]&#125;
				<br /> =&gt; XY-001
			</div>
		),
		readOnly: true,
		control: Input,
	},
	{
		key: 'screeningCode',
		title: '대상자 스크리닝 코드',
		info: (
			<div>
				대상자 스크리닝을 수행하는 시점에 부여되는 코드 규칙입니다.
				<br />
				<br />
				기관별 코드 - &#123;institution&#125;
				<br /> 사용자별 코드 - &#123;user&#125;
				<br /> 순서 - &#123;order[자리수]&#125;
				<br />
				<br /> 예시: &#123;institution&#125;-&#123;order[3]&#125;
				<br /> =&gt; XY-001
			</div>
		),
		readOnly: true,
		control: Input,
	},
	{
		key: 'hasScreening',
		title: '스크리닝 사용 여부',
		options: [
			{
				key: 1,
				value: true,
				text: '사용',
			},
			{
				key: 2,
				value: false,
				text: '사용안함',
			},
		],
		control: Select,
		readOnly: true,
	},
	{
		key: 'isOfficial',
		title: '공식 연구 여부',
		options: [
			{
				key: 1,
				value: true,
				text: '공식 연구',
			},
			{
				key: 2,
				value: false,
				text: '비공식 연구',
			},
		],
		control: Select,
		readOnly: true,
	},
]

export default class ResearchSettingsDetailForm extends PureComponent {
	static propTypes = {
		type: PropTypes.string.isRequired,
		templateType: PropTypes.string.isRequired,
		scope: PropTypes.string.isRequired,
		registrationCode: PropTypes.string.isRequired,
		screeningCode: PropTypes.string.isRequired,
		hasScreening: PropTypes.bool.isRequired,
		isOfficial: PropTypes.bool.isRequired,
		agreementCheckType: PropTypes.string.isRequired,
	}

	constructor(props) {
		super(props)
		this.state = {
			type: props.type,
			templateType: props.templateType,
			scope: props.scope,
			registrationCode: props.registrationCode,
			screeningCode: props.screeningCode,
			hasScreening: props.hasScreening,
			isOfficial: props.isOfficial,
			agreementCheckType: props.agreementCheckType,
		}
	}

	handleChangeValue = key => (e, { value }) => {
		this.setState({
			[key]: value,
		})
	}

	handleChangeSelectValue = key => (e, { data }) => {
		this.setState({
			[key]: data.value,
		})
	}

	handleSubmitBasic = async () => {
		const { onUpdateResearch, researchId, onShowToastMessage, onFinishUpdate } = this.props
		const { title, status, description } = this.state

		this.setState({
			isBtn1Loading: true,
		})

		try {
			await onUpdateResearch({
				variables: {
					title,
					status,
					description,
					researchId,
				},
			})
			onFinishUpdate()
			onShowToastMessage({
				content: '변경사항이 저장되었습니다.',
				success: true,
				autoDismiss: true,
			})
		} catch (e) {
			onShowToastMessage({
				content: '설정 변경 중 오류가 발생했습니다.',
				error: true,
				autoDismiss: true,
			})

			// onShowToastMessage(
			// 	'연구 기본정보 저장 중 오류가 발생했습니다.',
			// 	'다시 한번 시도해 주세요.',
			// 	'red'
			// )
		}

		this.setState({
			isBtn1Loading: false,
		})
	}

	render() {
		const { isBtn1Loading } = this.state
		return (
			<SubContainer>
				<Form onSubmit={this.handleSubmitBasic}>
					<FieldsContainer>
						{FIELDS.map(field => {
							if (field.key === 'screeningCode') {
								const { hasScreening } = this.state
								if (hasScreening === false) {
									return <div />
								}
							}
							const { [field.key]: value } = this.state

							return (
								<Form.Field
									key={field.key}
									{...field}
									label={
										field.info != null ? (
											<label>
												{field.title}
												<Popup
													trigger={<Icon style={styles.trigger} name="info circle" />}
													content={field.info}
												/>
											</label>
										) : (
											field.title
										)
									}
									onChange={field.readOnly !== true ? this.handleChangeValue(field.key) : () => {}}
									name={field.key}
									value={value}
								/>
							)
						})}
					</FieldsContainer>
					<Button primary size="big" type="submit" color="blue" loading={isBtn1Loading}>
						변경 사항 저장
					</Button>
				</Form>
			</SubContainer>
		)
	}
}

const styles = {
	container: {
		width: '100%',
	},
	inlineLabel: {
		width: 100,
		verticalAlign: 'top',
	},
	inlineContent: {
		display: 'inline-block',
	},
	inlineInput: {
		width: 110,
		verticalAlign: 'middle',
	},
	header: {
		marginBottom: 20,
		paddingBottom: 10,
		borderBottom: '1px solid #eee',
	},
	trigger: {
		marginLeft: 5,
		fontSize: 15,
	},
}
