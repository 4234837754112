import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import PageContainer from '../../common/container/PageContainer'
import { FlexFill } from '../../common/basic/StyledBox'
import { EmptyHeader } from '../../common/basic/StyledTexts'
import { BORDER_GRAY } from '../../../constants/styles'
import ResearchOptionCombinationList from './ResearchOptionCombinationList'
import ResearchNewForm from './ResearchNewForm'
import { ResearchPurpose, ResearchApplicationType } from '../../../enum'
import ResearchInquiry from './ResearchInquiry'
import useSubSideMenuControl from '../../../hooks/useSubSideMenuControl'

/**
 * 새로운 프로젝트 생성 페이지
 */
const ResearchNew = () => {
	const [selectedResearch, setSelectedResearch] = useState(null)

	//페이지 마운트 시 서브사이드메뉴를 닫고, 언마운트 시 다시 열어둠
	const toggleSubSideMenu = useSubSideMenuControl()
	useEffect(() => {
		toggleSubSideMenu(false)

		return () => toggleSubSideMenu(true)
	}, [toggleSubSideMenu])

	const isEmpty = selectedResearch == null

	//병원데이터 구축일 경우 별도 문의하도록
	let isHospital
	if (selectedResearch != null) {
		isHospital = selectedResearch.purpose === ResearchPurpose.HOSPITAL.value
	}

	return (
		<PageContainer fixed isSmall title="새로운 프로젝트">
			<Container>
				<ListContainer>
					<ResearchOptionCombinationList
						onSelect={setSelectedResearch}
						selected={selectedResearch}
					/>
				</ListContainer>
				<FlexFill>
					<FormContainer isEmpty={isEmpty}>
						{isEmpty && <EmptyHeader>프로젝트 종류를 선택해 주세요.</EmptyHeader>}
						{isHospital !== true ? (
							<ResearchNewForm {...selectedResearch} />
						) : (
							<ResearchInquiry
								title="병원 데이터 구축 문의"
								purpose={ResearchPurpose.HOSPITAL.value}
								type={ResearchApplicationType.GATHERING.value}
								description={
									<div>
										병원 내에서 생산되는 진료 데이터, 수납기록 등의 데이터를 수집하고 분석합니다.
										<br />
										별도의 문의 상담을 통하여 진행하실 수 있습니다.
									</div>
								}
								onSubmit={() => setSelectedResearch(null)}
							/>
						)}
					</FormContainer>
				</FlexFill>
			</Container>
		</PageContainer>
	)
}

export default ResearchNew

const Container = styled.div`
	display: flex;
	margin-top: 50px;
`

const ListContainer = styled.div`
	width: 400px;
	margin-right: 50px;
`

const FormContainer = styled.div`
	padding-left: 50px;
	border-left: ${BORDER_GRAY};
	height: 100%;

	display: flex;
	${props =>
		props.isEmpty === true
			? `
	align-items: center;
	justify-content: center;

	#new-research-form {
		opacity: 0;
		width: 0px;
		height: 0px;
	}
	`
			: `
		#new-research-form {
			height: auto;
			width: 100%;
		}		
	`}
	#new-research-form {
		transition: opacity 0.2s;
	}
`
