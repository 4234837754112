import gql from 'graphql-tag'
import {
	GET_PATIENT_SURVEY_OPEN,
	GET_PATIENT_SURVEY_STATUS,
	GET_PATIENT_SIGN,
} from '../../graphql/queries/app'
import {
	LOCAL_MED_INFO as LOCAL_MED_INFO_FRAGMENT,
	LOCAL_MED_INFO,
} from '../../graphql/fragments/medInfo'
import { setStoragePatientSurvey } from '../../libs/localStorageHelper'
import { ConditionAction } from '../../enum'
import { compareValues } from '../../libs/medInfoHelper'
import { conditionHandlerArray } from '../../libs/conditionHelper'
import { FRAGMENT_PATIENT_SURVEY_CURRENT_INDEX } from '../../graphql/fragments/app'

const TYPENAME_IS_DOING_SURVEY = 'IsDoingSurvey'
const TYPENAME_SURVEY_STATUS = 'SurveyStatus'
const TYPENAME_PATIENT_SURVEY_STATUS = 'PatientSurveyStatus'
const TYPENAME_RESEARCHER_SURVEY_STATUS = 'ResearcherSurveyStatus'
const TYPENAME_LOCAL_MED_INFO = 'LocalMedInfo'
const TYPENAME_LOCAL_MED_INFO_OPTION = 'LocalMedInfoOption'
const TYPENAME_LOCAL_MED_INFO_TYPE = 'LocalMedInfoType'
const TYPENAME_LOCAL_MED_INFO_SELECT = 'LocalMedInfoSelect'
const TYPENAME_LOCAL_MED_INFO_CHECKED = 'LocalMedInfoChecked'
const TYPENAME_LOCAL_CONDITION_ECRF = 'LocalConditionEcrf'
const TYPENAME_PATIENT_SIGN = 'PatientSign'

const INITIAL = {
	isDoingSurvey: {
		__typename: TYPENAME_IS_DOING_SURVEY,
		patient: false,
		researcher: false,
	},
	surveyStatus: {
		__typename: TYPENAME_SURVEY_STATUS,
		patient: {
			__typename: TYPENAME_PATIENT_SURVEY_STATUS,
			id: 'patient',
			researchId: null,
			recordId: null,
			patientId: null,
			patientName: null,
			patientCode: null,
			templateName: null,
			templateDescription: null,
			medInfos: null,
		},
		researcher: {
			__typename: TYPENAME_RESEARCHER_SURVEY_STATUS,
			id: 'researcher',
			researchId: null,
			recordId: null,
			patientId: null,
			patientName: null,
			patientCode: null,
			templateName: null,
			templateDescription: null,
			medInfos: null,
		},
	},
	patientSurveyStructure: null,
	patientSurveyCurrent: [],
	patientSign: {
		__typename: TYPENAME_PATIENT_SIGN,
		patientId: -1,
		patientName: '',
		researchId: -1,
		recordId: -1,
		didAgree: false,
		isOpen: false,
	},
}

export const defaults = INITIAL

export const typeDefs = gql`
	type LocalMedInfo {
		id: Int
		value: String
		optionValue: String
		vasValue: Int
		entityId: Int
		parentId: Int
		medInfoEntityId: Int
		checkedItmes: [LocalMedInfoChecked]
		medInfoType: LocalMedInfoType
		disabled: Boolean
	}

	type LocalMedInfoStructure {
		id: Int
		children: [LocalMedInfoStructure]
	}

	type LocalMedInfoType {
		id: Int
		name: String
		typeName: String
		inputType: String
		entityType: String
		color: String
		hasVAS: Boolean
		vasMax: Number
		placeholder: String
		unit: String
		format: String
		description: String
		size: String
		defaultDisabled: Boolean
		mustInclude: Boolean
		parentTypeId: Int
		defaultValue: String
		medInfoTypeEntityId: Int
		conditions: [LocalConditionEcrf]
		targetConditions: [LocalConditionEcrf]
		selectOptions: [LocalMedInfoSelect]
		options: [LocalMedInfoOption]
	}

	type LocalConditionEcrf {
		id: Int
		value: String
		operator: String
		action: String
		targetId: Int
		subjectId: Int
		message: String
		isRequired: Boolean
		scope: String
	}

	type LocalMedInfoSelect {
		value: String
		text: String
		hasText: Boolean
	}

	type LocalMedInfoOption {
		value: String
		text: String
	}

	type PatientSurveyStatus {
		recordId: Int
		patientId: Int
		patientName: String
		patientCode: String
		templateName: String
		templateDescription: String
		currentStageIndex: Int
		medInfos: [LocalMedInfo]
	}

	type PatientSign {
		patientId: Int
		patientName: String
		recordId: Int
		researchId: Int
		isOpen: Boolean
		didAgree: Boolean
	}

	input PatientSurveyStatusInput {
		recordId: Int!
		researchId: Int!
		patientId: Int!
		patientName: String!
		patientCode: String!
		templateName: String!
		medInfos: [MedInfoInput]!
	}

	extend type Query {
		localMedInfo(id: Int!): LocalMedInfo
	}

	extend type Mutation {
		toggleDoingSurvey(shouldOpen: Boolean): Boolean
		setPatientSurveyStatus(
			input: PatientSurveyStatusInput!
			isPatient: Boolean
		): PatientSurveyStatus
		setPatientSurveyStage(toIndex: Int): PatientSurveyStatus
		resetPatientSurveyStatus: PatientSurveyStatus
		updateLocalSurveyMedInfo(
			medInfoId: Int!
			value: String
			optionValue: String
			checkedItems: [MedInfoCheckedInput]
		): MedInfo
		updatePatientSign(
			patientId: Int
			patientName: String
			recordId: Int
			researchId: Int
			didAgree: Boolean
			isOpen: Boolean
		): PatientSign
	}
`

const setStorage = cache => {
	const currentStatus = cache.readQuery({
		query: GET_PATIENT_SURVEY_STATUS,
	})

	setStoragePatientSurvey(currentStatus)
}

export const resolvers = {
	Mutation: {
		/**
		 * 설문 진행중 상태 변경
		 */
		toggleDoingSurvey(_, { shouldOpen, isPatient }, { cache }) {
			const { isDoingSurvey: prev } = cache.readQuery({
				query: GET_PATIENT_SURVEY_OPEN,
			})
			// debugger
			const who = isPatient ? 'patient' : 'researcher'
			const prevData = prev[who]
			const toStatus = shouldOpen != null ? shouldOpen : !prevData

			if (shouldOpen === true) {
				cache.writeQuery({
					query: GET_PATIENT_SURVEY_OPEN,
					data: {
						isDoingSurvey: { ...prev, [who]: toStatus },
					},
				})
			} else {
				cache.writeQuery({
					query: GET_PATIENT_SURVEY_STATUS,
					data: INITIAL,
				})
			}
			setTimeout(() => setStorage(cache), 100)
			return toStatus
		},
		/**
		 * 환자 설문 내용 및 기본 정보 설정
		 */
		async setPatientSurveyStatus(_, { input, isPatient }, { cache }) {
			const { medInfos, recordId } = input
			const who = isPatient ? 'patient' : 'researcher'
			const { surveyStatus: prev, patientSurveyCurrent } = cache.readQuery({
				query: GET_PATIENT_SURVEY_STATUS,
			})
			const prevData = prev[who]

			const medInfosConv = medInfos.map(i => ({
				...i,
				__typename: TYPENAME_LOCAL_MED_INFO,
				disabled: false,
				medInfoType: {
					...i.medInfoType,
					__typename: TYPENAME_LOCAL_MED_INFO_TYPE,
					conditions: i.medInfoType.conditions.map(cc => ({
						...cc,
						__typename: TYPENAME_LOCAL_CONDITION_ECRF,
					})),
					targetConditions: i.medInfoType.targetConditions.map(cc => ({
						...cc,
						__typename: TYPENAME_LOCAL_CONDITION_ECRF,
					})),
					selectOptions: i.medInfoType.selectOptions.map(s => ({
						...s,
						hasText: s.hasText == null ? false : s.hasText,
						__typename: TYPENAME_LOCAL_MED_INFO_SELECT,
					})),
					options: i.medInfoType.options.map(s => ({
						...s,
						__typename: TYPENAME_LOCAL_MED_INFO_OPTION,
					})),
				},
				checkedItems: i.checkedItems.map(c => ({
					...c,
					__typename: TYPENAME_LOCAL_MED_INFO_CHECKED,
				})),
			}))

			await conditionHandlerArray(medInfosConv)

			/**
			 * 초기 조건식 처리
			 */
			const data = {
				...prevData,
				...input,
				medInfos: medInfosConv,
			}

			const existIndex = patientSurveyCurrent.findIndex(item => item.id === recordId) !== -1

			cache.writeQuery({
				query: GET_PATIENT_SURVEY_STATUS,
				data: {
					surveyStatus: {
						...prev,
						[who]: data,
					},
					patientSurveyCurrent: existIndex
						? patientSurveyCurrent
						: [
								...patientSurveyCurrent,
								{
									id: recordId,
									index: 0,
									__typename: 'CurrentIndex',
								},
						  ],
				},
			})

			setTimeout(() => setStorage(cache), 100)
			return data
		},
		/**
		 * 진행 단계 이동
		 */
		setPatientSurveyStage(_, { index, recordId }, { cache, getCacheKey }) {
			const prev = cache.readFragment({
				fragment: FRAGMENT_PATIENT_SURVEY_CURRENT_INDEX,
				id: getCacheKey({ __typename: 'CurrentIndex', id: recordId }),
			})

			cache.writeFragment({
				fragment: FRAGMENT_PATIENT_SURVEY_CURRENT_INDEX,
				id: getCacheKey({ __typename: 'CurrentIndex', id: recordId }),
				data: {
					...prev,
					index,
				},
			})

			setTimeout(() => setStorage(cache), 100)
		},
		updatePatientSign(_, input, { cache }) {
			const { patientSign: prev } = cache.readQuery({
				query: GET_PATIENT_SIGN,
			})

			const validInput = Object.keys(input).reduce((res, key) => {
				if (input[key] !== undefined) {
					return {
						...res,
						[key]: input[key],
					}
				}

				return res
			}, {})

			const to = {
				...prev,
				...validInput,
			}

			cache.writeData({
				data: {
					patientSign: to,
				},
			})

			return to
		},
		/**
		 * 초기화하기
		 */
		resetPatientSurveyStatus(_, __, { cache }) {
			const { surveyStatus: prev } = cache.readQuery({
				query: GET_PATIENT_SURVEY_STATUS,
			})

			const result = {
				...prev,
				...INITIAL.surveyStatus,
			}
			cache.writeData({
				data: {
					surveyStatus: result,
					patientSurveyCurrent: [],
				},
			})

			setTimeout(() => setStorage(cache), 100)

			return result
		},
		/**
		 * LocalMedInfo 값 수정
		 */
		updateLocalSurveyMedInfo(_, { medInfoId, ...values }, { cache, getCacheKey }) {
			const cacheId = getCacheKey({ __typename: TYPENAME_LOCAL_MED_INFO, id: medInfoId })

			/**
			 * 값 업데이트
			 */
			const rs = cache.readFragment({ fragment: LOCAL_MED_INFO, id: cacheId })

			if (rs == null) return

			const filteredValues = Object.keys(values).reduce((prev, key) => {
				if (values[key] !== undefined) {
					prev[key] = values[key]
				}
				return prev
			}, {})

			if (filteredValues.checkedItems != null) {
				filteredValues.checkedItems = filteredValues.checkedItems.map(item => ({
					...item,
					__typename: TYPENAME_LOCAL_MED_INFO_CHECKED,
				}))
			}

			const data = { ...rs, ...filteredValues }

			cache.writeFragment({
				fragment: LOCAL_MED_INFO,
				id: cacheId,
				data,
			})

			/**
			 * 조건식 처리
			 */
			const {
				medInfoType: { conditions },
			} = rs

			if (conditions != null) {
				const {
					surveyStatus: { medInfos },
				} = cache.readQuery({
					query: GET_PATIENT_SURVEY_STATUS,
				})

				conditions.map(condition => {
					const cMedInfo = medInfos.find(i => i.medInfoType.id === condition.targetId)
					const cMedInfoId = getCacheKey({
						__typename: TYPENAME_LOCAL_MED_INFO,
						id: cMedInfo.id,
					})

					if (cMedInfo == null) {
						return
					}

					/**
					 * 컨디션 세부 구현 필요(handleConditions 함수 참조)
					 */
					const isValid = compareValues(
						data.value,
						condition.value,
						condition.operator,
						data.checkedItems
					)

					const enableMedInfo = medInfo => {
						const actionCb = ConditionAction.ENABLE.action(condition, isValid)
						const updated = actionCb(medInfo)

						cache.writeData({
							id: cMedInfoId,
							data: updated,
						})
					}

					switch (condition.action) {
						case ConditionAction.ENABLE.key: {
							enableMedInfo(cMedInfo)
							return
						}
						case ConditionAction.ENABLE_BELOW_ALL.key: {
							// TODO:
							return
						}
						case ConditionAction.ENABLE_BELOW_PARENT: {
							// TODO:
							return
						}
						default: {
							return
						}
					}
				})
			}

			setTimeout(() => setStorage(cache), 100)

			return data
		},
	},
	Query: {
		/**
		 * LocalMedInfo 하나 불러오기
		 */
		localMedInfo(_, { id }, { cache, getCacheKey }) {
			const cacheId = getCacheKey({ __typename: TYPENAME_LOCAL_MED_INFO, id })
			const fragment = LOCAL_MED_INFO_FRAGMENT

			const rs = cache.readFragment({
				fragment,
				id: cacheId,
			})

			return rs
		},
	},
}
