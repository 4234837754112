import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import compose from 'lodash.flowright'
import { graphql, withApollo } from 'react-apollo'
import { Card, Button, Label, Icon } from 'semantic-ui-react'
import moment from 'moment'

import InlineProfile from '../../../common/InlineProfile'
import CommentItem from '../../../comment/CommentItem'
import CommentWrite from '../../../comment/CommentWrite'
import { UPDATE_MED_INFO_QUERY_STATUS } from '../../../../graphql/mutations/medInfo'

import { MedInfoQueryStatus, CommentEntityType } from '../../../../enum'
import { GET_MED_INFO_QUERY_COUNTS } from '../../../../graphql/queries/medInfo'

class MedInfoQueryItem extends PureComponent {
	static propTypes = {
		comments: PropTypes.arrayOf().isRequired,
	}

	handleUpdateStatus = async ({ status }) => {
		const { id, medInfoId, onUpdateStatusRequest, client, onUpdateSingleMedInfo } = this.props
		await onUpdateStatusRequest({
			variables: {
				medInfoQueryId: id,
				status,
			},
		})

		const {
			data: { medInfo },
		} = await client.query({
			query: GET_MED_INFO_QUERY_COUNTS,
			variables: {
				medInfoId,
			},
			fetchPolicy: 'network-only',
		})

		if (medInfo) {
			onUpdateSingleMedInfo(medInfoId, medInfo)
		}
	}

	render() {
		const { id, writer, createdAt, status, user, comments, onUpdate, canWriteQuery } = this.props

		const statusInfo = MedInfoQueryStatus[status]

		return (
			<Card fluid className="hover-raised">
				<Card.Content>
					<Card.Header
						style={{
							fontSize: 12,
							fontWeight: 300,
							borderBottom: '1px solid #eee',
							paddingBottom: 15,
						}}
					>
						<InlineProfile {...writer} size="tiny" /> 님이 수정 요청을 남기셨습니다.
						<div style={{ float: 'right', fontSize: 12, fontWeight: 400, color: '#999' }}>
							<div>{moment(createdAt).calendar()}</div>
							{statusInfo && (
								<div style={{ textAlign: 'right', marginTop: 5 }}>
									<Label
										style={{ marginRight: 7 }}
										color={statusInfo.color}
										circular
										empty
										size="mini"
									/>
									{statusInfo.text}
								</div>
							)}
						</div>
					</Card.Header>
					<div>
						{comments.map(comment => (
							<CommentItem
								key={comment.id}
								{...comment}
								user={user}
								onUpdate={onUpdate}
								entityType={CommentEntityType.MED_INFO_QUERY.value}
								entityId={id}
							/>
						))}
					</div>
					{canWriteQuery && (
						<CommentWrite
							user={user}
							entityType={CommentEntityType.MED_INFO_QUERY.value}
							entityId={id}
							onSubmit
							onUpdate={onUpdate}
						/>
					)}
					<div style={{ borderTop: '1px solid #eee', paddingTop: 15 }}>
						{status === MedInfoQueryStatus.UNRESOLVED.value ? (
							<Button
								onClick={() => {
									this.handleUpdateStatus({
										status: MedInfoQueryStatus.RESOLVED.value,
									})
								}}
							>
								<Icon name="check" />
								수정 사항 해결
							</Button>
						) : (
							<Button
								onClick={() => {
									this.handleUpdateStatus({
										status: MedInfoQueryStatus.UNRESOLVED.value,
									})
								}}
							>
								<Icon name="check" />
								미해결 상태로 전환
							</Button>
						)}
					</div>
				</Card.Content>
			</Card>
		)
	}
}

export default compose(
	withApollo,
	graphql(UPDATE_MED_INFO_QUERY_STATUS, {
		name: 'onUpdateStatusRequest',
	})
)(MedInfoQueryItem)
