import React from 'react'
import PropTypes from 'prop-types'
import DataFieldTextInput from './DataFieldTextInput'

/**
 * Data Field Number Input
 *
 * 숫자형 인풋
 */
export const DataFieldNumber = ({ options, ...props }) => {
	return (
		<DataFieldTextInput
			{...props}
			type="number"
			className={`df-number ${
				options && options.length === 0
					? 'no-unit'
					: options.length === 1
					? 'single-unit'
					: 'multiple-unit'
			}`}
			{...(options &&
				options.length === 1 && {
					labelPosition: 'right',
					label: options[0].text,
				})}
		/>
	)
}
DataFieldNumber.propTypes = {
	valueKey: PropTypes.string,
	value: PropTypes.string,
	onUpdate: PropTypes.func,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string,
		})
	),
}
