import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form, TextArea, Button, Icon } from 'semantic-ui-react'
import PageContainer from '../common/container/PageContainer'
import Image from '../../assets/images/drawings/multicenter.png'
import TextInputWithError from '../common/basic/TextInputWithError'
import ContactTermChecker from './term/ContactTermChecker'
import ButtonSelect from '../common/basic/ButtonSelect'
import { LinkColoredText } from '../common/basic/StyledTexts'
import { ExceptMobile } from '../common/basic/ResponsiveWrappers'

const ContactView = props => {
	const {
		errors,
		name,
		email,
		institution,
		contact,
		subject,
		text,
		type,
		onSubmit,
		onChange,
		hasAgreed,
		setAgreed,
		options,
	} = props

	return (
		<PageContainer title="서비스 이용 문의" small fixed hasMobile>
			<VideoContainer>
				<VideoIframe
					title="레피어스란?"
					src="https://www.youtube.com/embed/ZDnA8rO1_XQ"
					frameBorder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				/>
			</VideoContainer>
			<Title>
				레피어스 클라우드 상에서
				<br />
				임상 연구 데이터를 관리해보세요.{' '}
			</Title>
			<ImageContainer>
				<Guide>
					<ul style={{ listStyle: 'disc' }}>
						<li>다기관 연구 데이터 관리</li>
						<li>레지스트리 연구</li>
						<li>차트 리뷰, 후향적 연구 데이터 관리</li>
						<li>차트 데이터 정리 및 분석</li>
						<li>임상 데이터 통계, 시각화</li>
					</ul>
				</Guide>
				<ExceptMobile>
					<ImageContent src={Image} />
				</ExceptMobile>
			</ImageContainer>
			<Desc>편리하게 데이터를 구축하고 분석하며, 지속적인 후속연구를 이어가세요.</Desc>
			<PhoneInfo>
				<PhoneIcon name="phone" />
				전화 문의&nbsp;&nbsp;:&nbsp;&nbsp;
				<LinkColoredText inline as="a" href="tel:02-575-1593">
					<b>02-575-1593</b>
				</LinkColoredText>
			</PhoneInfo>
			<Form onSubmit={onSubmit}>
				<SelectTitle>문의 종류를 선택해 주세요.</SelectTitle>
				<ButtonSelect name="type" onChange={onChange} value={type} options={options} />
				<TextInputWithError
					name="name"
					value={name}
					onChange={onChange}
					placeholder="이름"
					error={errors.name}
				/>
				<TextInputWithError
					name="institution"
					value={institution}
					onChange={onChange}
					placeholder="소속기관 및 직위"
					error={errors.institution}
				/>
				<TextInputWithError
					name="contact"
					value={contact}
					onChange={onChange}
					placeholder="연락처"
					error={errors.contact}
				/>
				<TextInputWithError
					name="email"
					value={email}
					onChange={onChange}
					placeholder="이메일"
					error={errors.email}
				/>
				<TextInputWithError
					name="subject"
					value={subject}
					onChange={onChange}
					placeholder="제목"
					error={errors.subject}
				/>
				<TextInputWithError
					name="text"
					control={TextArea}
					value={text}
					onChange={onChange}
					placeholder="문의 내용"
					style={{ height: 300 }}
					error={errors.text}
				/>
				<div style={{ marginTop: 50 }}>
					<ContactTermChecker hasAgreed={hasAgreed} setAgreed={() => setAgreed(!hasAgreed)} />
				</div>
				<div style={styles.center}>
					<Button primary size="big">
						문의 남기기
					</Button>
				</div>
			</Form>
		</PageContainer>
	)
}

ContactView.propTypes = {
	name: PropTypes.string,
	institution: PropTypes.string,
	email: PropTypes.string,
	contact: PropTypes.string,
	subject: PropTypes.string,
	text: PropTypes.string,
	onSubmit: PropTypes.func,
	onChange: PropTypes.func,
	type: PropTypes.string,
	errors: PropTypes.shape({
		name: PropTypes.object,
		email: PropTypes.object,
		contact: PropTypes.object,
		subject: PropTypes.object,
		text: PropTypes.object,
		institution: PropTypes.object,
	}),
	hasAgreed: PropTypes.bool,
	setAgreed: PropTypes.func,
	options: PropTypes.array,
}

export default ContactView

/**
 * Styles
 */

const VideoContainer = styled.div`
	width: 100%;
	padding-top: 56.25%;
	position: relative;
	margin-top: 20px;
`

const VideoIframe = styled.iframe`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
`
const Title = styled.h5`
	margin-top: 50px;
	margin-bottom: 20px;
`

const Desc = styled.div`
	font-weight: 600;
	font-size: 18px;
	padding-left: 20px;
	margin-bottom: 50px;
`

const ImageContainer = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
`
const PhoneInfo = styled.div`
	padding: 0px 30px;
	font-size: 18px;
	margin-bottom: 60px;
`

const PhoneIcon = styled(Icon)`
	font-size: 20px;
	transform: scaleX(-1);
`

const ImageContent = styled.img`
	max-width: 300px;
	min-width: 250px;
	width: 30%;
`

const Guide = styled.div`
	font-size: 16px;
	margin-top: 20px;
	line-height: 1.8;
	margin-bottom: 50px;
	font-weight: 400;
	flex: 1;
	@media (max-width: ${props => props.theme.PAGE_MOBILE_MAX_WIDTH}px) {
		font-size: 16px;
		line-height: 1.5;
	}
`

const SelectTitle = styled.h6`
	margin-bottom: 30px;
`

const styles = {
	description: {
		marginTop: 15,
		letterSpacing: 1.1,
		fontSize: 16,
		fontWeight: 400,
		marginBottom: 20,
	},
	center: {
		textAlign: 'center',
		marginTop: 50,
	},
}
