import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { Modal, TextArea, Label, Button, Icon, Form, Sticky } from 'semantic-ui-react'
import moment from 'moment'
import 'moment/locale/ko'

import SectionModal from '../charting/modals/SectionModal'
import ChartingBoard from '../charting/ChartingBoard'
import withChart from '../../higherComponents/withChart'
import { BORDER_GRAY } from '../../constants/styles'
import { FlexDiv, FlexFill } from '../common/basic/StyledBox'
import TemplateSideBar from '../charting/TemplateSideBar'
import useUserInfo from '../../hooks/useUserInfo'
import useSubSideMenuControl from '../../hooks/useSubSideMenuControl'
import {
	ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_DEFAULT,
	ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_CHANGES,
	ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_CHANGE_DETAIL,
} from '../../constants/routes'
import { isMatchingRoutes, replaceParams } from '../../libs/routeHelper'
import ChangeModal from '../common/modal/ChangeModal'
import { ObjectType } from '../../enum'

moment.locale('ko')

/**
 * 입력 양식 보기
 */
const TemplateView = props => {
	const {
		onCreateSection,
		onEditSection,
		versionCode,
		isPartial,
		hasECRFs,
		onSaveChart,
		id,
		onUpdate,
		researchId,
		history,
		hasPartialTemplate,
	} = props

	const userInfo = useUserInfo()
	const { pathname } = useLocation()

	const [sectionModalOpen, setSectionModalOpen] = useState(false)
	const [sectionModalMedInfoType, setSectionModalMedInfoType] = useState(null)
	const [sectionModalIndex, setSectionModalIndex] = useState(null)
	const [saveModalOpen, setSaveModalOpen] = useState(false)
	const [saveModalMessage, setSaveModalMessage] = useState('')
	const [saveModalLoading, setSaveModalLoading] = useState(false)
	const toggleSubSideMenu = useSubSideMenuControl()

	useEffect(() => {
		toggleSubSideMenu(false)
	}, [toggleSubSideMenu])

	const changeModalOpen = useMemo(
		() =>
			isMatchingRoutes(pathname, [
				ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_CHANGES,
				ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_CHANGE_DETAIL,
			]),
		[pathname]
	)

	const handleSaveChart = useCallback(async () => {
		setSaveModalLoading(true)
		try {
			await onSaveChart({ message: saveModalMessage, templateId: id })
			setSaveModalLoading(false)
			setSaveModalOpen(false)
			setSaveModalMessage('')

			onUpdate()
		} catch (e) {
			alert('오류가 발생했습니다.')
			setSaveModalLoading(false)
		}
	}, [id, onSaveChart, onUpdate, saveModalMessage])

	const bottomDummy = useRef(null)
	const stickyRef = useRef(null)

	const onSaveSectionModal = useMemo(() => {
		if (sectionModalMedInfoType) {
			return onEditSection
		} else {
			return onCreateSection
		}
	}, [onCreateSection, onEditSection, sectionModalMedInfoType])

	return (
		<div>
			{hasECRFs !== true && sectionModalOpen && (
				<SectionModal
					isPartial={isPartial}
					onClose={() => {
						setSectionModalMedInfoType(null)
						setSectionModalOpen(false)
					}}
					medInfoType={sectionModalMedInfoType}
					sectionIndex={sectionModalIndex}
					open={sectionModalOpen}
					onSubmit={onSaveSectionModal}
					onScroll={() => {
						if (bottomDummy.current) {
							bottomDummy.current.scrollIntoView({
								behavior: 'smooth',
							})
						}
					}}
				/>
			)}
			{saveModalOpen && (
				<Modal size="tiny" open={saveModalOpen} closeIcon onClose={() => setSaveModalOpen(false)}>
					<Modal.Header>변경 사항 저장</Modal.Header>
					<Modal.Content>
						<Form>
							<Form.Field inline style={{ textAlign: 'center' }}>
								<span>버전 코드</span>
								<Label basic color="grey">
									{versionCode}
								</Label>
								<Icon name="arrow right" />
								<Label basic color="black">
									{versionCode + 1}
								</Label>
							</Form.Field>
							<Form.Field
								label={userInfo.name}
								value={saveModalMessage}
								onChange={(e, { value }) => setSaveModalMessage(value)}
								control={TextArea}
								placeholder="수정사항 관련 메시지를 입력해 주세요."
							/>
						</Form>
					</Modal.Content>
					<Modal.Actions>
						<Button loading={saveModalLoading} primary onClick={handleSaveChart}>
							변경 사항 저장
						</Button>
					</Modal.Actions>
				</Modal>
			)}
			{changeModalOpen === true && (
				<ChangeModal
					objectType={ObjectType.TEMPLATE.value}
					id={id}
					researchId={researchId}
					open={changeModalOpen}
					onClose={() => {
						history.replace(
							replaceParams(ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_DEFAULT, {
								id: researchId,
								menu: 'templates',
								subId: id,
							})
						)
					}}
					{...props}
				/>
			)}
			<FlexDiv>
				{/* <TemplateContainer ref={stickyRef}>
					<Sticky context={stickyRef} offset={100}>
						<TemplateSideBar />
					</Sticky>
				</TemplateContainer> */}
				<FlexFill>
					<ChartingBoard
						{...props}
						onOpenSectionModal={(medInfoType, index) => {
							setSectionModalOpen(true)
							setSectionModalMedInfoType(medInfoType)
							setSectionModalIndex(index)
						}}
						researches={[]}
						templateMode
					/>
				</FlexFill>
			</FlexDiv>

			{(hasPartialTemplate !== true || isPartial === true) && (
				<HeaderTools>
					<SaveButton>
						<Button size="big" primary onClick={() => setSaveModalOpen(true)}>
							변경 사항 저장
						</Button>
					</SaveButton>
				</HeaderTools>
			)}
			<div ref={bottomDummy} />
		</div>
	)
}

TemplateView.propTypes = {
	onCreateSection: PropTypes.func,
	onEditSection: PropTypes.func,
	versionCode: PropTypes.number,
	isPartial: PropTypes.bool,
	hasECRFs: PropTypes.bool,
	researchId: PropTypes.number,
	onGenerateMedInfo: PropTypes.func,
	onSaveChart: PropTypes.func,
	id: PropTypes.number,
	onUpdate: PropTypes.func,
	onUpdateMedInfo: PropTypes.func.isRequired,
	medInfos: PropTypes.array.isRequired,
	history: PropTypes.object,
	hasPartialTemplate: PropTypes.bool,
}

export default withChart(TemplateView)

const SaveButton = styled.div`
	text-align: right;
	/* margin-top: 50px;
	margin-bottom: 50px; */
	/* border-top: ${BORDER_GRAY}; */
	/* padding-top: 30px; */
`

const HeaderTools = styled.div`
	position: fixed;
	top: 12px;
	right: 120px;
	display: flex;
`
