import { InputType } from './enum'

export const REGEX_DATE = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/

export const USER_TYPES = {
	RESEARCHER: 'RESEARCHER',
	DOCTOR: 'DOCTOR',
}

export const CASE_SEX = {
	M: '남자',
	W: '여자',
}

export const DRAG_ITEM_TYPES = {
	SECTION: 'section',
	DATA_FIELD: 'dataField',
	DATA_GROUP: 'dataGroup',
	TABLE_COLUMN: 'tableColumn',
	PARTIAL_TEMPLATE: 'partialTemplate',
}

export const PATIENT_IN_OUT_OPTIONS = [
	{
		value: 1,
		text: '입원',
		key: 1,
	},
	{
		value: 0,
		text: '외래',
		key: 0,
	},
]

export const PATIENT_INFO_FIELDS = {
	name: {
		key: 'name',
		text: '이름',
		isBasic: true,
		basicRequired: true,
		isHashable: true,
		isInSurvey: true,
	},
	sexMale: {
		key: 'sexMAle',
		text: '성별',
		isBasic: true,
		isInSurvey: true,
	},
	patientCode: {
		key: 'patientCode',
		text: '병록(차트)번호',
		isBasic: true,
		basicRequired: true,
		isInSurvey: true,
	},
	birthDate: {
		key: 'birthDate',
		text: '출생연도',
		isBasic: true,
		basicRequired: true,
		dateOnly: true,
		isInSurvey: true,
	},
	birthDateReal: {
		key: 'birthDateReal',
		text: '생년월일',
		placeholder: '클릭해서 날짜선택',
		dateOnly: true,
		width: 6,
	},
	phone: {
		key: 'phone',
		text: '연락처',
		width: 6,
		placeholder: '휴대전화 또는 전화',
	},
	nameInitial: {
		key: 'nameInitial',
		text: '영문 이니셜',
		placeholder: '예: ABC',
		width: 4,
	},
	address: {
		key: 'address',
		text: '주소',
		placeholder: '주소',
		width: 6,
	},
	paymentSerialNumber: {
		key: 'paymentSerialNumber',
		text: '명세서 일련번호',
		placeholder: '명세서 일련번호',
	},
	inOut: {
		inputType: InputType.TOGGLE.key,
		key: 'inOut',
		text: '입원 환자',
		width: 4,
		options: PATIENT_IN_OUT_OPTIONS,
	},
}

export const PATIENT_BASIC_REQUIRED_FIELDS = Object.keys(PATIENT_INFO_FIELDS).filter(
	key => PATIENT_INFO_FIELDS[key].basicRequired === true
)

export const PATIENT_INITIAL_STATE = {
	name: '',
	patientCode: '',
	sexMale: true,
	birthDate: '1989-01-01',
	researchId: null,
	phone: '',
	nameInitial: '',
	address: '',
	paymentSerialNumber: '',
	birthDateReal: null,
	inOut: null,
}

export const HEADER_CELLS_SCHEDULED_RESEARCH_PATIENT_LIST = [
	{
		text: '상태',
		key: 'status',
		isSortable: true,
	},
	{
		text: '코드',
		key: 'patientResearchCode',
		isSortable: true,
	},
	{
		text: '환자명',
		key: 'name',
		isSortable: true,
	},
	{
		text: '진행상태',
		key: 'phase',
		isSortable: true,
	},
	{
		text: '진료기관',
		key: 'institutionName',
		isSortable: true,
	},
	{
		text: '등록일',
		key: 'createdAt',
		isSortable: true,
	},
]

export const HEADER_CELLS_DATA_GATHERING_PATIENT_LIST = [
	{
		text: '코드',
		key: 'patientResearchCode',
		isSortable: true,
	},
	{
		text: '성별',
		key: 'sexMale',
		isSortable: true,
	},
	{
		text: '나이',
		key: 'birthDate',
		isReversedOrder: true,
		isSortable: true,
	},
	{
		text: '케이스 요약',
		key: 'summary',
		isSortable: false,
	},
	{
		text: '등록자',
		key: 'registererName',
		isSortable: true,
	},
	{
		text: '등록일',
		key: 'createdAt',
		isSortable: true,
	},
]

export const PATIENT_GENDER_OPTIONS = [
	{ key: 'm', text: '남성', value: true },
	{ key: 'f', text: '여성', value: false },
]

export const DEFAULT_IMAGE_SYMPTOM_TYPE =
	'https://kr.object.ncloudstorage.com/lepius-media-storage/contents/images/symptom-types/other.png'

export const FIXED_CONTAINER_MIN_WIDTH = 1080
export const FIXED_CONTAINER_WIDTH = 1140
export const FIXED_CONTAINER_MAX_WIDTH = 1600

/**
 * 회원가입 중복값시 알림 메시지
 */
export const USER_DUPE_MESSAGES = {
	nickname: '이미 존재하는 닉네임 입니다.',
	email: '이미 등록된 이메일 입니다.',
	username: '이미 등록된 아이디 입니다.',
	phone: '이미 등록된 전화번호입니다.',
}

// 페이지 당 아이템 숫자 변경
export const DEFAULT_ROWS_PER_PAGE = 20
export const DEFAULT_PATIENTS_PER_PAGE = 20
export const DEFAULT_MED_INFO_QUERIES_PER_PAGE = 2
export const DEFAULT_PATIENT_RECORDS_PER_PAGE = 20
export const DEFAULT_MED_INFO_TYPE = {
	name: '',
	inputType: 'SHORT_FREE_TEXT',
	typeName: '',
	isPersonal: false,
	hasVAS: false,
	description: '',
	unit: null,
	format: '',
	size: 'MEDIUM',
	entityType: '',
}

export const DEFAULT_PAGINATION_VARIABLES = {
	page: 1,
	perPage: DEFAULT_ROWS_PER_PAGE,
}

export const MED_INFO_TYPE_FORMATS = {
	optionIsCheckbox: 'optionCb',
	disablesInput: 'disableInput',
}

/**
 * 데이터 다운로드 암호키 기본 길이
 */
export const AUTO_GENERATE_EXPORT_ENC_KEY_LENGTH = 15

/**
 * 데이터 다운로드 자료형
 */
export const EXPORT_FILE_TYPES = {
	CSV: {
		text: 'csv',
		value: 'CSV',
		key: 'CSV',
		export: 'zip',
	},
	XLSX: {
		text: 'xlsx',
		value: 'XLSX',
		key: 'XLSX',
		export: 'xlsx',
		noEncryption: true,
	},
	// json: {
	// 	text: 'JSON',
	// 	value: 'JSON',
	// 	key: 'JSON',
	// },
}

export const MED_INFO_TYPE_INPUT_VALID_FIELDS = [
	'id',
	'name',
	'typeName',
	'entityType',
	'inputType',
	'placeholder',
	'description',
	'hasVAS',
	'options',
	'superTypeId',
	'subTypes',
	'selectOptions',
	'color',
	'unit',
	'format',
	'medInfoCategoryId',
	'isPersonal',
	'size',
	'defaultDisabled',
	'mustInclude',
	'conditions',
	'targetConditions',
	'parentTypeId',
	'childTypes',
	'vasMax',
	'defaultValue',
	'medInfoTypeEntityId',
]

export const MED_INFO_INPUT_VALID_FIELDS = [
	'id',
	'value',
	'vasValue',
	'optionValue',
	'checkedItems',
	'medInfoTypeId',
	'parentId',
	'medInfoType',
	'children',
	'entityId',
	'medInfoEntityId',
]

export const MED_INFO_CHECKED_INPUT_VALID_FIELDS = ['value', 'freeWriting']

/**
 * 조건식 Input
 */
export const CONDITION_ECRF_INPUT_VALID_FIELDS = [
	'id',
	'subjectId',
	'value',
	'operator',
	'action',
	'targetId',
	'message',
	'isRequired',
	'scope',
]

/**
 * 선택지 Input
 */
export const MED_INFO_SELECT_INPUT_VALID_FIELDS = ['value', 'text', 'hasText']

/**
 * 옵션 선택지 Input
 */
export const MED_INFO_OPTION_INPUT_VALID_FIELDS = ['value', 'text', 'format']

export const BOSA_OPTIONS = [
	{
		key: 'na',
		value: 'na',
		text: '보사 무관',
	},
	{
		key: 'supplement',
		value: 'supplement',
		text: '보',
	},
	{
		key: 'drain',
		value: 'drain',
		text: '사',
	},
]

/**
 * 본초 기본 단위
 */
export const HERB_UNITS = [
	{
		key: '돈',
		value: '돈',
		text: '돈',
	},
	{
		key: '냥',
		value: '냥',
		text: '냥',
	},
	{
		key: '푼',
		value: '푼',
		text: '푼',
	},
	{
		key: 'g',
		value: 'g',
		text: 'g',
	},
	{
		key: 'kg',
		value: 'kg',
		text: 'kg',
	},
	{
		key: '개/매',
		value: '개/매',
		text: '개/매',
	},
	{
		key: '기타',
		value: '기타',
		text: '기타',
	},
]

export const RESEARCH_TAB_ID_ALL = 0

export const TABLE_STATS_COUNT_DATA_KEY = '사용 회수'

export const SCROLL_LINK_COMMENT = 'chart-comment-'

export const SORT_DIRECTIONS = {
	descending: {
		semantic: 'descending',
		opposite: 'ascending',
		value: 'DESC',
	},
	ascending: {
		semantic: 'ascending',
		value: 'ASC',
		opposite: 'descending',
	},
	none: {
		semantic: null,
		value: null,
	},
}

export const DEFAULT_RESEARCH = {
	id: -1,
	title: '개인 저장용',
	patientBasicInfo: '',
	requiresAgreement: false,
	recordTemplates: [],
}

export const DEFAULT_PATIENT_RESEARCH = {
	noResearch: true,
	research: DEFAULT_RESEARCH,
}

export const FILE_ACCEPT_OPTIONS = {
	all: '*',
	xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	csv: '.csv',
	image: 'image/*',
	pdf: '.pdf',
}
