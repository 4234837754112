import React from 'react'
import PropTypes from 'prop-types'
import { Form, Checkbox } from 'semantic-ui-react'
import { MED_INFO_TYPE_FORMATS } from '../../../../constants'

const styles = {
	formGroup: {
		marginTop: 5,
	},
	cornerButton: {
		position: 'absolute',
		right: 5,
		top: 7,
	},
}

const SelectOptionItemForm = ({
	text,
	onChange,
	onCheckboxChange,
	type,
	index,
	value,
	hasText,
	format,
	checkbox,
}) => {
	return (
		<React.Fragment>
			<Form.Group style={styles.selectOptionForm} width="equal">
				<Form.Field required>
					<label>내용</label>
					<input
						onChange={onChange(type)(index, 'text')}
						value={text}
						placeholder="내용을 입력해 주세요."
					/>
				</Form.Field>
				<Form.Field required>
					<label>영문 변수값</label>
					<input
						onChange={onChange(type)(index, 'value')}
						value={value}
						placeholder="영문, 숫자, 특수기호(_), (-)만 사용 가능"
					/>
				</Form.Field>
			</Form.Group>
			<Form.Field inline>
				{type === 'selectOptions' && (
					<Checkbox
						value="hasText"
						label="자유기재 가능"
						onChange={onCheckboxChange(type)(index, 'hasText')}
						checked={hasText}
					/>
				)}
				{type === 'options' && checkbox && (
					<Checkbox
						value={MED_INFO_TYPE_FORMATS.disablesInput}
						label="체크 시 필드 비활성화"
						onChange={onCheckboxChange(type)(index, 'format')}
						checked={format && format.includes(MED_INFO_TYPE_FORMATS.disablesInput)}
					/>
				)}
			</Form.Field>
		</React.Fragment>
	)
}

SelectOptionItemForm.propTypes = {
	text: PropTypes.string,
	onChange: PropTypes.func,
	type: PropTypes.string,
	index: PropTypes.number,
	value: PropTypes.string,
	hasText: PropTypes.bool,
	checkbox: PropTypes.bool,
	format: PropTypes.bool,
	onCheckboxChange: PropTypes.func,
}

export default SelectOptionItemForm
