import validator from 'validator'
import { isEmptyString } from './stringHelper'

const tests = {
	pattern: (value, pattern) => {
		return pattern.test(value)
	},
	isNumber: value => {
		return validator.isNumeric(value)
	},
	length: (value, option) => {
		return validator.isLength(value, option)
	},
	isEmail: value => {
		return validator.isEmail(value)
	},
	isMobilePhone: value => {
		return validator.isMobilePhone(value, 'ko-KR')
	},
	range: (value, { min, max, include } = {}) => {
		if (typeof value !== 'number' && isNaN(parseInt(value)) === true) {
			return false
		}

		if (min != null && max != null) {
			if (include === true) {
				return value >= min && value <= max
			} else {
				return value > min && value < max
			}
		} else if (min != null) {
			if (include === true) return value >= min
			else return value > min
		} else if (max != null) {
			if (include === true) return value <= max
			else return value < max
		}

		return true
	},
	isPassword: value => {
		//영문 대문자, 소문자, 숫자, 특수문자 중 최소 2가지
		const tests = [
			/(?=.*[a-z])/.test(value), // 소문자 있음
			/(?=.*[A-Z])/.test(value), // 대문자 있음
			/(?=.*[\d])/.test(value), //숫자 있음
			/(?=.*[-+_!@#$%^&*.,?])/.test(value), //특수문자 있음
		]

		const validCount = tests.filter(item => item === true)

		if (validCount.length >= 2) return true

		return false
	},
}

export const validateField = (value, fieldInfo) => {
	const { options, required, message } = fieldInfo
	if (required === true && isEmptyString(value) === true) {
		return { message: '필수 정보입니다.' }
	}

	if (options) {
		let isValid = true
		for (const type of Object.keys(options)) {
			isValid = tests[type](value, options[type])

			if (isValid === false) break
		}

		if (isValid === false) return { message }
	}

	return
}

/**
 * 다수 필드를 validation 검사
 */
export const validateFields = (values, fieldInfos) => {
	const errors = {}
	for (const id of Object.keys(values)) {
		const fieldInfo = fieldInfos[id]
		if (fieldInfo) {
			const value = values[id]

			const error = validateField(value, fieldInfo)
			if (error) errors[id] = error
		}
	}

	if (Object.keys(errors).length > 0) return errors
}
