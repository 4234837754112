import PropTypes from 'prop-types'
import React from 'react'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'

const PatientSurveyButton = ({ direction, children, click }) => (
	<ButtonContainer direction={direction}>
		<ButtonWrapper onClick={click}>{children}</ButtonWrapper>
	</ButtonContainer>
)

PatientSurveyButton.propTypes = {
	direction: PropTypes.string,
	children: PropTypes.node.isRequired,
}

const ButtonContainer = styled.div`
	display: flex;
	justify-content: ${props =>
		props.direction === 'left'
			? 'flex-start'
			: props.direction === 'center'
			? 'center'
			: 'flex-end'};
`

const ButtonWrapper = styled(Button)`
	border-radius: 20px !important;
	border: 1px solid ${props => props.theme.COLOR_SURVEY_BLUE} !important;
	background: whilte !important;
	color: ${props => props.theme.COLOR_SURVEY_BLUE} !important;

	margin-bottom: 15px !important;
`
export default PatientSurveyButton
