import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import ResearchStatisticsDashboard from './ResearchStatisticsDashboard'
import ResearchStatisticsSubjects from './ResearchStatisticsSubjects'
import PageContainer from '../components/common/container/PageContainer'
import {
	ROUTE_PROJECT_DETAIL_STATS_DEFAULT,
	ROUTE_PROJECT_DETAIL_STATS_SUBJECTS,
} from '../constants/routes'
import MenuContainer from '../components/common/container/MenuContainer'

const PAGE_TITLE = '통계'

const RESEARCH_STATISTICS_MENU_BASIC = Object.freeze([
	{
		key: 'RESEARCH_STATS_DASHBOARD',
		value: null,
		to: ROUTE_PROJECT_DETAIL_STATS_DEFAULT,
		exact: true,
		title: '현황판',
		component: ResearchStatisticsDashboard,
	},
	{
		key: 'RESEARCH_STATS_SUBJECTS',
		value: 'subjects',
		to: ROUTE_PROJECT_DETAIL_STATS_SUBJECTS,
		title: '분석',
		component: ResearchStatisticsSubjects,
	},
])

/**
 * 연구 통계 기본 컴퍼넌트
 */
class ResearchStatistics extends PureComponent {
	static propTypes = {
		researchId: PropTypes.number.isRequired,
	}

	constructor(props) {
		super(props)
	}

	render() {
		const { researchId, breadCrumbs } = this.props

		return (
			<PageContainer fixed title={PAGE_TITLE} breadCrumbs={breadCrumbs}>
				<MenuContainer
					replaceParam={{
						id: researchId,
					}}
					menuItems={RESEARCH_STATISTICS_MENU_BASIC}
					menuOptions={{
						pointing: true,
						secondary: true,
					}}
					basicProps={{
						renderCustomizedLabel,
						colors,
						...this.props,
					}}
				/>
			</PageContainer>
		)
	}
}

export default withRouter(ResearchStatistics)

const colors = ['#56aa99', '#847dc1', '#e2b156', '#adbd33', '#23ddaa', '#dd33aa', '#9988cc']

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
	cx,
	cy,
	midAngle,
	innerRadius,
	outerRadius,
	percent,
	payload,
}) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5
	const x = cx + radius * Math.cos(-midAngle * RADIAN) * 2
	const y = cy + radius * Math.sin(-midAngle * RADIAN) * 2

	return (
		<text
			x={x}
			y={y}
			textAnchor={x > cx ? 'start' : 'end'}
			style={{ color: '#8884d8' }}
			dominantBaseline="central"
		>
			{`${payload.name} ${(percent * 100).toFixed(0)}%(${payload.value})`}
		</text>
	)
}
