import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import { Loader } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { GET_USER_DETAIL } from '../../../graphql/queries/user'
import ResearchIssueWriteForm from './ResearchIssueWriteForm'
import { GET_ISSUES_TYPES } from '../../../graphql/queries/research'

/**
 * 새로운 이슈 작성
 */
class ResearchIssueWrite extends PureComponent {
	static propTypes = {
		researchId: PropTypes.number.isRequired,
	}

	render() {
		const { researchId } = this.props
		return (
			<Query
				query={GET_ISSUES_TYPES}
				variables={{
					researchId,
				}}
			>
				{({ error, loading, data }) => {
					if (loading) return <Loader />
					if (error) return <h1>{error.message}</h1>
					const { issueTypes } = data

					return (
						<div>
							<ResearchIssueWriteForm
								researchId={researchId}
								issueTypes={issueTypes}
								{...this.props}
							/>
						</div>
					)
				}}
			</Query>
		)
	}
}

export default withRouter(ResearchIssueWrite)
