import { useState, useEffect } from 'react'

/**
 * 텍스트 기반 Input 처리를 위한 Custom Hook
 *
 * State로 Value 바꾸고 Blur 시 Global State update
 */
const useTextInput = (onUpdate, key, value = '') => {
	const [stateValue, setStateValue] = useState(value)

	const onChange = (e, { value: iVal }) => {
		setStateValue(iVal)
	}

	const onBlur = () => {
		onUpdate(key, stateValue)
	}

	// Prop value change
	useEffect(() => {
		setStateValue(value)
	}, [value])

	return [stateValue, onChange, onBlur]
}

useTextInput.propTypes = {}

export default useTextInput
