import React from 'react'
import PropTypes from 'prop-types'
import SearchMultipleInput from './SearchMutipleInput'
import { ReadOnlyValue, ReadOnlyValueNull } from './Styled'

/**
 * 검색 인풋
 */
const DataFieldSearchMany = ({
	checkedItems,
	readOnly,
	disabled,
	onUpdateMany,
	entityType,
	placeholder,
}) => {
	if (readOnly === true) {
		if (disabled === true) return <span />
		if (checkedItems == null || checkedItems.length === 0) {
			return <ReadOnlyValueNull>비어있음</ReadOnlyValueNull>
		}

		return <ReadOnlyValue>{checkedItems.map(item => item.freeWriting).join(',')}</ReadOnlyValue>
	}

	return (
		<SearchMultipleInput
			// inputRef={inputRef}
			readOnly={readOnly}
			disabled={disabled}
			onUpdateValues={onUpdateMany}
			checkedItems={checkedItems}
			entityType={entityType}
			placeholder={placeholder}
		/>
	)
}

DataFieldSearchMany.propTypes = {
	checkedItems: PropTypes.array,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	onUpdateMany: PropTypes.func,
	entityType: PropTypes.string,
	placeholder: PropTypes.string,
}

export default DataFieldSearchMany
