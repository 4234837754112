import styled from 'styled-components'
import { COLOR_BACKGROUND_GRAY } from '../../../constants/styles'

const TableWidgetContainer = styled.div`
	background: ${COLOR_BACKGROUND_GRAY};
	padding: 10px;
	display: flex;
`

export default TableWidgetContainer
