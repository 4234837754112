import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import { Modal, Button } from 'semantic-ui-react'
import moment from 'moment'

const NoticesModal = ({ notices, isOpen, onClickNoMore, onClickClose }) => {
	return (
		<Modal open={isOpen} closeOnDimmerClick={false}>
			<Modal.Header>새로운 공지사항</Modal.Header>
			<Modal.Content scrolling>
				{notices.map(notice => (
					<Wrapper key={notice.id}>
						<Header>
							<Title>{notice.title}</Title>
							<Ago>{moment(notice.startDate).fromNow()}</Ago>
						</Header>
						<Content>
							<ReactMarkdown source={notice.content} />
						</Content>
					</Wrapper>
				))}
			</Modal.Content>
			<ActionsWrapper>
				<Button size="large" primary onClick={onClickNoMore}>
					더 이상 보지 않기
				</Button>
				<Button size="large" onClick={onClickClose}>
					닫기
				</Button>
			</ActionsWrapper>
		</Modal>
	)
}

NoticesModal.propTypes = {
	notices: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			title: PropTypes.string,
			startDate: PropTypes.string,
			content: PropTypes.string,
		})
	),
	isOpen: PropTypes.bool,
	onClickNoMore: PropTypes.func,
	onClickClose: PropTypes.func,
}

const Wrapper = styled.div`
	padding: 20px 0;
	border-bottom: 1px solid ${props => props.theme.COLOR_BORDER_GRAY};
	:first-child {
		padding-top: 0;
	}
	:last-child {
		border-bottom: none;
	}
`

const Header = styled.div`
	padding: 16px 10px;
`

const Title = styled.div`
	color: ${props => props.theme.COLOR_VIVID_GRAY};
	font-weight: bold;
	font-size: 18px;
	margin: 6px 0;
`

const Ago = styled.div`
	color: ${props => props.theme.COLOR_TEXT_LIGHTGRAY};
	font-size: 12px;
`

const Content = styled.div`
	padding: 17px 8px;
`

const ActionsWrapper = styled(Modal.Actions)`
	text-align: center !important;
`
export default NoticesModal
