// Fragment 용도 : 여러 query에서 공통된 type을 사용하는 경우
// 미리 Fragment에 정의해두고 query 날릴때 fragment에서 불러와서 사용함
// 여러 query에서 공통된 type을 사용할 경우 fragment를 사용하지 않으면
// 같은 type을 중복적으로 사용하게 됨

import gql from 'graphql-tag'
import { RECORD_FRAGMENT } from './record'
import { PATIENT_FRAGMENT } from './patient'
import { MED_INFO_FRAGMENT, MED_INFO_TYPE_FRAGMENT, MED_INFO_DATA_FRAGMENT } from './medInfo'
import { RESEARCH_FRAGMENT } from './research'

// 차트 기록 의료 정보
export const RECORD_MED_INFOS = gql`
	fragment RecordMedInfoParts on MedInfo {
		...MedInfoParts
		medInfoType {
			...MedInfoTypeParts
			childTypes {
				...MedInfoTypeParts
			}
		}
	}
	${MED_INFO_FRAGMENT}
	${MED_INFO_TYPE_FRAGMENT}
`

// 차트 기록 의료 정보
export const SIMPLE_RECORD_MED_INFO_FRAGMENT = gql`
	fragment RecordMedInfoDataParts on MedInfo {
		...MedInfoDataParts
		medInfoType {
			...MedInfoTypeParts
			childTypes {
				...MedInfoTypeParts
			}
		}
	}
	${MED_INFO_DATA_FRAGMENT}
	${MED_INFO_TYPE_FRAGMENT}
`

// 차트 기록 불러오기
export const RECORD_DETAIL_FRAGMENT = gql`
	fragment RecordDetailParts on RecordDetail {
		record {
			...RecordParts
			patient {
				...PatientParts
			}
			medInfos {
				...RecordMedInfoParts
			}
			template {
				id
				title
				type
				job
				shouldReserve
			}
			researches {
				...ResearchParts
			}
		}
		chartingMedInfoTypes {
			...MedInfoTypeParts
			superTypeId
		}
		reservation {
			reservedDate
		}
		nextSchedule {
			title
			days
			daysRange
			doneBy
		}
		conditionRefMedInfos {
			value
			medInfoTypeEntityId
		}
	}
	${RECORD_FRAGMENT}
	${PATIENT_FRAGMENT}
	${RECORD_MED_INFOS}
	${RESEARCH_FRAGMENT}
`
