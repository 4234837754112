import gql from 'graphql-tag'
import { MED_INFO_QUERY_FRAGMENT, LOCAL_MED_INFO, MED_INFO_FRAGMENT } from '../fragments/medInfo'

// MedInfoQuery 상태 변경하기
// 수정완료 or 미해결 상태로 변경
export const UPDATE_MED_INFO_QUERY_STATUS = gql`
	mutation updateMedInfoQueryStatus($medInfoQueryId: Int!, $status: MedInfoQueryStatus!) {
		updateMedInfoQueryStatus(medInfoQueryId: $medInfoQueryId, status: $status) {
			...MedInfoQueryParts
		}
	}
	${MED_INFO_QUERY_FRAGMENT}
`

// MedInfoQuery 작성하기
// medInfoId와 전달 메시지 필요
export const WRITE_MED_INFO_QUERY = gql`
	mutation writeMedInfoQuery($medInfoId: Int!, $message: String!) {
		writeMedInfoQuery(medInfoId: $medInfoId, message: $message) {
			id
			medInfoQueryCount
			unresolvedMedInfoQueryCount
		}
	}
`

/**
 * 이미지 파일 업로드
 */
export const ADD_MED_INFO_MEDIA = gql`
	mutation addMedInfoMedia($medInfoEntityId: Int!, $mediaFile: Upload!, $researchId: Int) {
		addMedInfoMedia(
			medInfoEntityId: $medInfoEntityId
			mediaFile: $mediaFile
			researchId: $researchId
		) {
			filePath
			fileUri
		}
	}
`

/**
 * 환자 설문조사 값 업데이트
 */

export const UPDATE_LOCAL_SURVEY_MED_INFO = gql`
	mutation(
		$medInfoId: Int!
		$value: String
		$optionValue: String
		$vasValue: Int
		$checkedItems: [MedInfoCheckedInput]
	) {
		updateLocalSurveyMedInfo(
			medInfoId: $medInfoId
			value: $value
			optionValue: $optionValue
			vasValue: $vasValue
			checkedItems: $checkedItems
		) @client {
			...LocalMedInfoParts
		}
	}

	${LOCAL_MED_INFO}
`

export const UPDATE_MED_INFO = gql`
	mutation(
		$id: Int!
		$value: String
		$optionValue: String
		$vasValue: Int
		$checkedItems: [MedInfoCheckedInput]
	) {
		updateMedInfo(
			id: $id
			value: $value
			optionValue: $optionValue
			vasValue: $vasValue
			checkedItems: $checkedItems
		) {
			...MedInfoParts
		}
	}
	${MED_INFO_FRAGMENT}
`
