import React, { useCallback, useState, useEffect } from 'react'
import PropTypes, { oneOfType } from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { Table } from 'semantic-ui-react'
import { useQuery } from 'react-apollo'
import TableHeader from './TableHeader'
import TableLoader from './loader/TableLoader'
import EllipsisDropdown from './EllipsisDropdown'
import TableError from './error/TableError'
import { DataTypes } from '../../enum'
import { toLocalizedDateString } from '../../libs/dateHelper'
import { SORT_DIRECTIONS, DEFAULT_ROWS_PER_PAGE } from '../../constants'
import AddButton from './basic/AddButton'
import PaddedTable from './basic/PaddedTable'
import EmptyTable from './empty/EmptyTable'
import { getQueryValue } from '../../libs/routeHelper'
import Pagination from './basic/Pagination'
import InputSearch from './basic/InputSearch'
import { EllipsisCell } from './basic/StyledBox'
import ErrorBoundary from './ErrorBoundary'

/**
 * 데이터 공용 테이블
 *
 * 데이터 로드 + 페이지네이션 + URL Routing을 한번에 처리
 * URL Querystring에 pagination과 search query를 표시함
 * graphql query 를 통해 page 에 해당하는 데이터를 fetch 하여 table로 render.
 *
 * render Prop을 사용해 렌더링 함수를 넣어 totalCount등을 함수의 parameter로 받아 사용할 수 있음.
 */
const DataTable = props => {
	const {
		emptyMessage,
		queryName,
		columns,
		defaultVars,
		query,
		history,
		location: { pathname, search: queryString },
		render,
		perPage,
		celled,
		ellipsisMenu,
	} = props

	const qPage = getQueryValue('page', queryString, 'number') || 1
	const search = getQueryValue('query', queryString, 'string') || ''

	const [totalCount, setTotalCount] = useState(0)

	const sortBy = getQueryValue('sortBy', queryString) || null
	const sortDirection = getQueryValue('sortDirection', queryString) || null

	const sortOrder =
		sortDirection != null
			? SORT_DIRECTIONS[sortDirection] && SORT_DIRECTIONS[sortDirection].value
			: null

	const handleSetPage = useCallback(
		({ searchQuery, toPage, sortBy, sortDirection }) => {
			history.push(
				`${pathname}?page=${toPage}${
					sortBy != null ? `&sortBy=${sortBy}&sortDirection=${sortDirection}` : ''
				}${searchQuery != null && searchQuery != '' ? `&query=${searchQuery}` : ''}`
			)
		},
		[history, pathname]
	)

	const handleSearch = useCallback(
		value => {
			handleSetPage({
				searchQuery: value,
				toPage: 1,
				sortBy,
				sortDirection,
			})
		},
		[handleSetPage, sortBy, sortDirection]
	)

	// sort
	const handleSort = useCallback(
		colname => () => {
			// const { sortBy, sortDirection } = sortInfo
			if (colname !== sortBy) {
				handleSetPage({
					sortBy: colname,
					sortDirection: SORT_DIRECTIONS.ascending.semantic,
					toPage: 1,
					searchQuery: search,
				})
			} else if (sortDirection === SORT_DIRECTIONS.ascending.semantic) {
				handleSetPage({
					sortBy,
					sortDirection: SORT_DIRECTIONS.descending.semantic,
					toPage: 1,
					searchQuery: search,
				})
			} else {
				handleSetPage({
					sortBy: null,
					sortDirection: null,
					toPage: 1,
					searchQuery: search,
				})
			}
		},
		[handleSetPage, search, sortBy, sortDirection]
	)

	const { data, error, loading, refetch } = useQuery(query, {
		variables: {
			...defaultVars,
			pagination: {
				page: qPage,
				perPage: perPage || DEFAULT_ROWS_PER_PAGE,
			},
			filter: {
				sortOrder,
				sortBy,
				search: {
					query: search,
				},
			},
		},
	})

	let View, items, dTotalCount, page, dPerPage

	if (loading) View = <TableLoader />
	else if (error != null) View = <TableError isMini />
	else {
		const { [queryName]: dataResult } = data
		if (dataResult == null) {
			View = <TableError isMini />
		} else {
			;({ items, perPage: dPerPage, page, totalCount: dTotalCount } = dataResult)

			if (items == null || items.length === 0) {
				View = <EmptyTable emptyMessage={emptyMessage} />
			} else {
				// items = mock
				View = <TableRows {...props} rows={items} refetch={refetch} />
			}
		}
	}

	useEffect(() => {
		if (dTotalCount != null) setTotalCount(dTotalCount)
	}, [dTotalCount])

	const rendered = (
		<div>
			<TableWidgets {...props} onSearch={handleSearch} searchQuery={search} />
			<PaddedTable celled={celled} sortable unstackable selectable verticalAlign="middle">
				<TableHeader
					columns={columns}
					sortBy={sortBy}
					sortDirection={sortDirection}
					onSort={handleSort}
					hasEllipsis={ellipsisMenu != null}
				/>
				<Table.Body>{View}</Table.Body>
			</PaddedTable>
			<PaginationContainer>
				<Pagination
					ellipsisItem={null}
					siblingRange={15}
					style={{
						margin: 'auto',
					}}
					activePage={page}
					totalPages={dTotalCount != null ? Math.ceil(dTotalCount / dPerPage) : 1}
					onPageChange={(e, { activePage }) => {
						handleSetPage({
							toPage: activePage,
							...(search !== '' && {
								searchQuery: search,
							}),
							...(sortBy != null && {
								sortBy,
							}),
							...(sortDirection != null && {
								sortDirection,
							}),
						})
					}}
				/>
			</PaginationContainer>
		</div>
	)

	return render ? render({ totalCount, rendered }) : rendered
}

DataTable.propTypes = {
	hasBottomRowAddButton: PropTypes.bool,
	hasTopRowAddButton: PropTypes.bool,
	hasWidgetAddButton: PropTypes.bool,
	hasRemoveButton: PropTypes.bool,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
		})
	).isRequired,
	defaultVars: PropTypes.shape(),
	queryName: PropTypes.string,
	query: PropTypes.shape().isRequired,
	onAddItem: PropTypes.func,
	addButtonTitle: PropTypes.string,
	emptyMessage: PropTypes.string,
	history: PropTypes.shape({
		replace: PropTypes.func,
	}),
	location: PropTypes.shape({
		pathname: PropTypes.string,
		search: PropTypes.string,
	}),
	render: PropTypes.func,
	perPage: PropTypes.number,
	celled: PropTypes.bool,
	ellipsisMenu: oneOfType([PropTypes.func, PropTypes.object]),
}

DataTable.defaultProps = {
	hasBottomRowAddButton: false,
	hasTopRowAddButton: false,
	hasWidgetAddButton: false,
	hasRemoveButton: false,
	defaultVars: {},
	queryName: null,
	onAddItem: () => {},
	addButtonTitle: null,
	emptyMessage: null,
}

/**
 * 테이블 상단 툴셋
 */
const TableWidgets = ({
	onSearch,
	hasSearch,
	searchPlaceholder,
	widgetButton,
	searchQuery,
	hasWidgetAddButton,
	addButtonTitle,
	onAddItem,
}) => {
	const [search, setSearch] = useState(searchQuery)
	return (
		<Tools>
			<ToolsLeft>
				{hasSearch && (
					<form
						onSubmit={() => {
							onSearch(search)
						}}
					>
						<InputSearch
							placeholder={searchPlaceholder}
							style={{ width: 300 }}
							onChange={(e, { value }) => setSearch(value)}
							value={search}
						/>
					</form>
				)}
			</ToolsLeft>
			{widgetButton}
			{hasWidgetAddButton && (
				<AddButton title={addButtonTitle || '새로운 항목 추가하기'} onClick={onAddItem} />
			)}
		</Tools>
	)
}

TableWidgets.propTypes = {
	hasWidgetAddButton: PropTypes.bool,
	addButtonTitle: PropTypes.string,
	onAddItem: PropTypes.func,
	onSearch: PropTypes.func,
	hasSearch: PropTypes.bool,
	searchPlaceholder: PropTypes.string,
	widgetButton: PropTypes.node,
	searchQuery: PropTypes.string,
}

TableWidgets.defaultProps = {
	hasWidgetAddButton: false,
	addButtonTitle: null,
	onAddItem: () => {},
}

/**
 * 테이블 행 목록
 */
const TableRows = ({ columns, rows, ellipsisMenu, onClickRow, refetch }) => {
	return rows.map(row => {
		const menuOptions =
			typeof ellipsisMenu === 'function' ? ellipsisMenu({ ...row, refetch }) : ellipsisMenu

		return (
			<Table.Row key={row.id} onClick={() => onClickRow != null && onClickRow(row)}>
				<ErrorBoundary
					render={() => {
						return <Table.Cell colSpan={99}>로드중 오류가 발생했습니다.</Table.Cell>
					}}
				>
					{columns.map(col => {
						const { key: colKey, getter, uiOptions, dataType } = col
						let value
						if (typeof getter === 'function') {
							try {
								value = getter(row)
							} catch (e) {
								return <td>렌더링에 실패했습니다.</td>
							}
						} else {
							const colDepth = colKey.split('.')
							value = colDepth.reduce((parent, key) => {
								if (parent != null) return parent[key]
								return parent
							}, row)

							if (dataType === DataTypes.DATE) {
								value = toLocalizedDateString(value)
							}
						}

						return (
							<Table.Cell {...uiOptions} key={colKey + '_' + value}>
								{typeof col.render === 'function' ? col.render({ value, ...row }) : value}
							</Table.Cell>
						)
					})}
					{ellipsisMenu != null && (
						<EllipsisCell>
							{menuOptions != null && <EllipsisDropdown {...menuOptions} />}
						</EllipsisCell>
					)}
				</ErrorBoundary>
			</Table.Row>
		)
	})
}

export default withRouter(DataTable)

/**
 * Styles
 */
const Tools = styled.div`
	display: flex;
	margin-bottom: 10px;
	align-items: center;
`
const ToolsLeft = styled.div`
	flex: 1;
`

const PaginationContainer = styled.div`
	margin-top: 50px;
	text-align: center;
`
