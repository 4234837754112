import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {
	ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_HISTORY,
	ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_HISTORY_DETAIL,
} from '../../../constants/routes'
import ResearchTemplateHistoryList from './ResearchTemplateHistoryList'
import ResearchTemplateHistoryDetail from './ResearchTemplateHistoryDetail'

/**
 * 양식 수정내역 메뉴 라우팅
 */
const ResearchTemplateHistory = props => {
	return (
		<Switch>
			<Route
				exact
				path={ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_HISTORY}
				component={p => <ResearchTemplateHistoryList {...props} {...p} />}
			/>
			<Route
				path={ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_HISTORY_DETAIL}
				component={p => <ResearchTemplateHistoryDetail {...props} {...p} />}
			/>
		</Switch>
	)
}

export default ResearchTemplateHistory
