import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Label } from 'semantic-ui-react'
import UserInline from '../common/UserInline'
import { convertDateToPostStyle } from '../../libs/dateHelper'
import { COLOR_BLUE, COLOR_TEXT_LIGHTGRAY } from '../../constants/styles'
import LinkColored from '../common/basic/LinkColored'
import { CASE_SEX } from '../../constants'
import PatientLabel from './PatientLabel'
import ScopeLabel from './ScopeLabel'
import { replaceParams } from '../../libs/routeHelper'
import { ROUTE_CASE_TAG } from '../../constants/routes'
import CaseTag from './CaseTag'
import { ListItem } from './ListItem'
import { SharingScopes } from '../../enum'

const CaseItem = ({
	sharingScope,
	caseId,
	title,
	createdAt,
	updatedAt,
	creator,
	tags,
	sex,
	age,
	groupName,
	commentCount,
}) => {
	let tagsArr = []

	if (tags) {
		try {
			tagsArr = JSON.parse(tags)
		} catch (e) {
			alert('태그를 불러오는데 오류가 발생했습니다.')
		}
	}

	return (
		<ListItem to={`/cases/${caseId}`}>
			<div style={{ marginBottom: 5, display: 'flex', flexDirection: 'row' }}>
				<div style={{ fontSize: 17, fontWeight: 400, lineHeight: 1.2, flex: 1 }}>
					<PatientLabel sex={sex} age={age} />
					{title}&nbsp; &nbsp;[{commentCount}]
				</div>
			</div>
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				<div style={{ flex: 1 }}>
					{tagsArr.map(tag => {
						return (
							<CaseTag
								key={`case_tag_${tag.key}`}
								style={{ fontSize: 13, marginRight: 10 }}
								tagId={tag.key}
								text={tag.text}
							/>
						)
					})}
				</div>
				<div
					style={{
						color: COLOR_TEXT_LIGHTGRAY,
						fontSize: 13,
						display: 'inline-block',
						marginRight: 20,
					}}
				>
					<ScopeLabel scope={sharingScope} style={{ marginLeft: 10 }} />
					{sharingScope === SharingScopes.GROUP.value && <span>{groupName}</span>}
				</div>
				<UserInline
					size={18}
					profileImage={creator.profileImage}
					name={creator.nickname}
					fontSize={13}
					fontWeight={400}
				/>
			</div>
			<div />
		</ListItem>
	)
}

CaseItem.propTypes = {
	caseId: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	createdAt: PropTypes.string.isRequired,
	creator: PropTypes.object.isRequired,
	groupName: PropTypes.string,
}

CaseItem.defaultProps = {
	groupName: '',
}

export default CaseItem
