import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { replaceParams } from '../../../libs/routeHelper'
import { styleEllipsisOverflow } from '../../common/basic/StyledTexts'
import LinkColored from '../../common/basic/LinkColored'
import { VerticalFlex } from '../../common/basic/StyledBox'
import { ROUTE_PROJECT_DETAIL_DEFAULT } from '../../../constants/routes'
import DescriptionText from '../../common/basic/DescriptionText'
import { getAgeRange, convertGender } from '../../../libs/valueHelper'
import HeaderPageTitle from '../../common/basic/HeaderPageTitle'
import { MiniBar } from '../../common/basic/MiniBar'

const PatientInfoHeaderView = ({
	isOnResearchPage,
	researchName,
	researchId,
	researchCode,
	sexMale,
	birthDate,
	name,
}) => {
	return (
		<PatientWrapper>
			{researchName != null && (
				<ResearchTitle
					to={replaceParams(ROUTE_PROJECT_DETAIL_DEFAULT, {
						id: researchId,
					})}
				>
					{researchName}
				</ResearchTitle>
			)}
			<HeaderPageTitle>
				<PatientName>{isOnResearchPage ? researchCode : name}</PatientName>
				<DescriptionText inline>
					{getAgeRange(birthDate)} <MiniBar /> {convertGender(sexMale)}{' '}
				</DescriptionText>
			</HeaderPageTitle>
		</PatientWrapper>
	)
}

PatientInfoHeaderView.propTypes = {
	isOnResearchPage: PropTypes.bool,
	researchName: PropTypes.string,
	researchId: PropTypes.number,
	researchCode: PropTypes.string,
	sexMale: PropTypes.bool,
	birthDate: PropTypes.string,
	name: PropTypes.string,
}

export default PatientInfoHeaderView

const PatientWrapper = styled(VerticalFlex)`
	width: 100%;
`

const ResearchTitle = styled(LinkColored)`
	font-size: 13px;
	${styleEllipsisOverflow}
`

const PatientName = styled.div`
	display: inline-block;
	margin-right: 20px;
`
