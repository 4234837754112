import React from 'react'
import { Form, TextArea } from 'semantic-ui-react'

const StyledTextArea = props => {
	return (
		<Form>
			<TextArea {...props} />
		</Form>
	)
}

StyledTextArea.propTypes = {}

export default StyledTextArea
