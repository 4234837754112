import gql from 'graphql-tag'
import { RESEARCH_SUB_MENU } from '../../constants/menus'
import { GET_RESEARCH_SUB_SIDE_MENU, GET_SUB_SIDE_MENU_OPEN } from '../../graphql/queries/app'
import { FRAGMENT_RESEARCH_SIDE_MENU_ITEM } from '../../graphql/fragments/app'

const TYPENAME_RESEARCH_SIDE_MENU = 'ResearchSideMenu'
const TYPENAME_RESEARCH_SIDE_MENU_ITEM = 'ResearchSideMenuItem'
const TYPENAME_RESEARCH_SIDE_MENU_SUB_ITEM = 'ResearchSideMenuSubItem'

const DEFAULT_ID = 1

export const defaults = {
	researchSideMenu: {
		__typename: TYPENAME_RESEARCH_SIDE_MENU,
		id: DEFAULT_ID,
		isLoaded: false,
		items: [],
	},
	isSubSideMenuOpen: true,
}

export const resolvers = {
	Mutation: {
		/**
		 * 사이드 메뉴 열고 닫기
		 */
		toggleSubSideMenu(_, { shouldOpen }, { cache }) {
			try {
				const { isSubSideMenuOpen: prev } = cache.readQuery({
					query: GET_SUB_SIDE_MENU_OPEN,
				})

				const toStatus = shouldOpen != null ? shouldOpen : !prev

				cache.writeQuery({
					query: GET_SUB_SIDE_MENU_OPEN,
					data: {
						isSubSideMenuOpen: toStatus,
					},
				})

				return toStatus
			} catch (e) {
				console.log(e)
			}
		},
		/**
		 * 프로젝트 사이드메뉴 목록 설정
		 *
		 * 연구 목록에, Active 상태와 open 상태를 붙여서 client cache에 write
		 */
		setResearchSideMenu: (_, { researches, currentId }, { cache }) => {
			try {
				if (researches == null) return
				const { researchSideMenu: prev } = cache.readQuery({
					query: GET_RESEARCH_SUB_SIDE_MENU,
				})

				const items = researches.map(research => {
					const prevItem = prev.items.find(i => i.id === research.id)
					let isOpen, isActive
					if (prevItem != null) {
						;({ isOpen } = prevItem)
					}

					const { id, userResearcherInfo } = research

					if (id == currentId) {
						isOpen = true
						isActive = true
					} else {
						isOpen = false
						isActive = false
					}

					const menuItems = RESEARCH_SUB_MENU.reduce((res, m) => {
						const { requiresType, requires: permission, numberKey, ...common } = m

						if (typeof permission === 'string') {
							if (userResearcherInfo[permission] !== true) return res
						}

						let number
						if (numberKey != null && research[numberKey]) {
							number = research[numberKey]
						} else {
							number = null
						}

						if (requiresType != null && research.type !== requiresType) {
							return res
						}

						res.push({
							...common,
							id: `${id}-${common.value}`,
							key: common.value,
							number,
							__typename: TYPENAME_RESEARCH_SIDE_MENU_SUB_ITEM,
						})

						return res
					}, [])

					const result = {
						...research,
						menuItems,
						__typename: TYPENAME_RESEARCH_SIDE_MENU_ITEM,
						isOpen: isOpen == null ? false : isOpen,
						isActive,
					}

					// client.writeQuery({
					// 	query: GET_SUB_SIDE_MENU_ITEM,
					// 	variables: {
					// 		id,
					// 	},
					// 	data: result,
					// })

					return result
				})

				const result = {
					__typename: TYPENAME_RESEARCH_SIDE_MENU,
					items,
					isLoaded: true,
					id: DEFAULT_ID,
				}

				cache.writeQuery({
					query: GET_RESEARCH_SUB_SIDE_MENU,
					data: {
						researchSideMenu: result,
					},
				})

				return result
			} catch (e) {
				console.log(e)
			}
		},
		/**
		 * 프로젝트 사이드메뉴 프로젝트별 메뉴 열고 닫기
		 */
		toggleResearchSideMenu: (_, { id, shouldOpen }, { cache, getCacheKey }) => {
			try {
				const cacheId = getCacheKey({ __typename: TYPENAME_RESEARCH_SIDE_MENU_ITEM, id })
				const fragment = FRAGMENT_RESEARCH_SIDE_MENU_ITEM

				const rs = cache.readFragment({ fragment, id: cacheId })

				const data = { ...rs, isOpen: shouldOpen == null ? !rs.isOpen : shouldOpen }

				cache.writeFragment({
					fragment,
					id: cacheId,
					data,
				})
			} catch (e) {
				console.log(e)
			}
		},
		/**
		 * 현재 프로젝트가 변경될 경우 메뉴 상태를 직접 설정
		 */
		handleResearchPageChange(_, { currentId }, { cache }) {
			try {
				const { researchSideMenu: prev } = cache.readQuery({
					query: GET_RESEARCH_SUB_SIDE_MENU,
				})

				if (prev) {
					const items = prev.items.map(i => {
						const isActive = i.id === currentId
						return {
							...i,
							isActive,
							isOpen: isActive,
						}
					})

					const result = {
						...prev,
						items,
					}

					cache.writeData({
						data: {
							researchSideMenu: result,
						},
					})

					return result
				}
			} catch (e) {
				console.log(e)
			}
		},
	},
	Query: {
		/**
		 * 프로젝트 하나의 메뉴 상태 불러오기
		 */
		researchSideMenuItem: (_, { id }, { cache, getCacheKey }) => {
			try {
				const cacheId = getCacheKey({ __typename: TYPENAME_RESEARCH_SIDE_MENU_ITEM, id })
				const fragment = FRAGMENT_RESEARCH_SIDE_MENU_ITEM
				const rs = cache.readFragment({ fragment, id: cacheId })
				return rs
			} catch (e) {
				console.log(e)
			}
		},
	},
}
