import styled from 'styled-components'
import { Card, Button } from 'semantic-ui-react'
import { UISize } from '../../../enum'

/**
 * 스타일 오버라이드로 별도의 컴퍼넌트로 활용이 필요한 것들 정리
 */

export const ColoredCard = styled(Card)`
	background: ${props => props.color} !important;
	color: white;
	border: none !important;
`

export const SubmitButton = styled(Button).attrs({ size: UISize.LARGE, primary: true })``
