import React from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import { GET_CONDITIONS } from '../../../graphql/queries/record'
import { InlineLoader } from '../../common/loader/InlineLoader'
import ErrorView from '../../common/error/ErrorView'
import ConditionView from './ConditionView'

/**
 * 프로젝트 조건식 설정 컴퍼넌트
 */
const ResearchCondition = props => {
	const { researchId } = props

	return (
		<Query
			query={GET_CONDITIONS}
			variables={{
				researchId: researchId,
			}}
		>
			{({ loading, data, error, refetch }) => {
				if (loading) {
					return <InlineLoader height={400} />
				}

				if (error) {
					return <ErrorView />
				}

				return (
					<div>
						<ConditionView
							{...props}
							onUpdate={refetch}
							conditions={data.researchConditions}
							medInfoTypes={data.researchMedInfoTypes}
						/>
					</div>
				)
			}}
		</Query>
	)
}

ResearchCondition.propTypes = {
	researchId: PropTypes.number.isRequired,
}

export default ResearchCondition
