import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalActions, ModalContent } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-apollo'
import { DELETE_SIMPLE_CASE } from '../../graphql/mutations/case'
import useToastMessage from '../../hooks/useToastMessage'
import { SUCCESS_CASE_DELETE } from '../../constants/alerts'

const ModalDeleteCase = ({ caseId, isOpen, onClose }) => {
	const showToastMessages = useToastMessage()
	const history = useHistory()
	const [deleteCase, { loading }] = useMutation(DELETE_SIMPLE_CASE, {
		variables: {
			caseId,
		},
		onCompleted: () => {
			onClose()
			showToastMessages(SUCCESS_CASE_DELETE)
			history.replace('/cases')
		},
	})

	return (
		<Modal open={isOpen} centered size="tiny" onClose={onClose}>
			<ModalContent>케이스를 삭제하시겠습니까?</ModalContent>
			<ModalActions>
				<Button onClick={onClose}>취소</Button>
				<Button color="red" onClick={() => deleteCase()} loading={loading}>
					케이스 삭제
				</Button>
			</ModalActions>
		</Modal>
	)
}

ModalDeleteCase.propTypes = {}

export default ModalDeleteCase
