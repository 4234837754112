import moment from 'moment'

export const SIGN_UP_FIELDS = {
	username: {
		name: '아이디',
		options: {
			pattern: /^[0-9a-z\-_]*$/,
			length: {
				min: 5,
				max: 20,
			},
		},
		required: true,
		message: '5~20 자의 영문 소문자, 숫자 및 특수기호(_),(-)만 사용 가능합니다.',
	},
	name: {
		name: '이름',
		options: {
			pattern: /^[가-힣A-Za-z]*$/,
			length: {
				min: 2,
				max: 10,
			},
		},
		required: true,
		message: '한글과 영문 대소문자를 입력해 주세요. (2~10자. 특수문자, 공백 사용불가)',
	},
	nickname: {
		name: '닉네임',
		options: {
			pattern: /^[가-힣A-Za-z\-_0-9]*$/,
			length: {
				min: 2,
				max: 20,
			},
		},
		required: true,
		message:
			'한글과 영문 대소문자, 숫자 및 특수기호(_), (-) 만 사용 가능합니다. (2~20자. 특수문자, 공백 사용불가)',
	},
	email: {
		name: '이메일',
		options: {
			isEmail: true,
			length: {
				min: 6,
				max: 100,
			},
		},
		required: true,
		message: '올바른 이메일주소를 입력해주세요.',
	},
	password: {
		name: '비밀번호',
		options: {
			isPassword: true,
			length: {
				min: 8,
				max: 16,
			},
		},
		required: true,
		message: '8~16자로 영문 대문자, 소문자, 숫자, 특수문자 중 최소 두가지를 조합해 주세요.',
	},
	passwordRepeat: {
		name: '비밀번호 반복',
		required: true,
	},
	phone: {
		name: '휴대전화',
		options: {
			isMobilePhone: true,
			length: {
				min: 6,
				max: 16,
			},
		},
		required: true,
		message: '형식에 맞지 않는 번호입니다.',
	},
}

export const SIGNUP_FIELDS_INSTITUTION = {
	institution: {
		name: '기관명',
		options: {
			length: {
				min: 2,
				max: 50,
			},
		},
		required: true,
		message: '형식에 맞지 않는 병원/기관명입니다.',
	},
	// address: {
	// 	name: '주소',
	// 	options: {
	// 		length: {
	// 			min: 2,
	// 			max: 60,
	// 		},
	// 	},
	// 	required: false,
	// 	message: '형식에 맞지 않는 주소입니다.',
	// },
	position: {
		name: '직위',
		required: true,
		message: '올바른 값을 입력해주세요.',
	},
}

export const INSTITUTION_FIELDS = {
	institutionName: {
		name: '기관명',
		options: {
			length: {
				min: undefined,
				max: 50,
			},
		},
		required: true,
		message: '형식에 맞지 않는 기관명입니다.',
	},
	instituionAddress: {
		name: '주소',
		options: {
			length: {
				min: undefined,
				max: 60,
			},
		},
		required: true,
		message: '형식에 맞지 않는 주소입니다.',
	},
	institutionPhone: {
		name: '전화번호',
		options: {
			isMobilePhone: true,
			length: {
				min: 6,
				max: 16,
			},
		},
		required: true,
		message: '형식에 맞지 않는 번호입니다.',
	},
}

export const MED_INFO_TYPE_FIELDS = {
	typeName: {
		name: '변수명',
		options: {
			length: {
				min: undefined,
				max: 30,
			},
		},
		required: true,
		message: '30자 이하로 입력해주세요',
	},
	name: {
		name: '항목 이름',
		options: {
			length: {
				min: 1,
				max: 255,
			},
		},
		required: true,
		message: '5~20 자의 영문 소문자, 숫자 및 특수기호(_),(-)만 사용 가능합니다.',
	},
}

export const CONTACT_FIELDS = {
	name: {
		name: '이름',
		options: {
			length: {
				min: 2,
				max: 10,
			},
		},
		required: true,
		message: '올바른 값을 입력해 주세요.',
	},
	email: {
		name: '이메일',
		options: {
			isEmail: true,
			length: {
				min: 6,
				max: 100,
			},
		},
		required: true,
		message: '이메일 주소가 잘못되었습니다.',
	},
	institution: {
		name: '소속기관 / 직위',
		options: {
			length: {
				max: 50,
			},
		},
		required: true,
		message: '소속기관 정보를 입력해 주세요.',
	},
	contact: {
		name: '연락처',
		options: {
			length: {
				min: 2,
				max: 20,
			},
		},
		required: false,
		message: '올바른 연락처를 입력해 주세요.',
	},
	subject: {
		name: '제목',
		options: {
			length: {
				max: 50,
			},
		},
		required: true,
		message: '올바른 제목을 입력해 주세요.',
	},
	text: {
		name: '문의내용',
		options: {
			length: {
				max: 6000,
			},
		},
		required: true,
		message: '문의내용을 올바르게 입력해 주세요.',
	},
}

export const PATIENT_SURVEY_FIELDS = {
	name: {
		name: '이름',
		required: true,
		options: {
			pattern: /^[ㄱ-ㅎA-Za-z.]+$/,
		},
		message: '이름을 입력해 주세요. 초성 또는 이니셜만 입력할 수 있습니다.',
	},
	bornYear: {
		name: '출생년도',
		required: true,
		options: {
			range: {
				min: 1900,
				max: moment().year() + 1,
			},
		},
		message: '출생년도를 올바르게 입력해 주세요.',
	},
}
