import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

export default function FloatingButton({
	children,
	primary,
	top,
	bottom,
	left,
	right,
	onClick,
	visible,
	absolute,
}) {
	return (
		<Wrapper
			primary={primary}
			top={top}
			bottom={bottom}
			left={left}
			right={right}
			onClick={onClick}
			visible={visible}
			absolute={absolute}
		>
			{children}
		</Wrapper>
	)
}

FloatingButton.propTypes = {
	children: PropTypes.node,
	primary: PropTypes.bool,
	top: PropTypes.number,
	bottom: PropTypes.number,
	left: PropTypes.number,
	right: PropTypes.number,
	onClick: PropTypes.func,
	visible: PropTypes.bool,
	absolute: PropTypes.bool,
}

FloatingButton.defaultProps = {
	primary: false,
	visible: true,
	absolute: false,
}

const Wrapper = styled.button`
	cursor: pointer;
	width: 60px;
	height: 60px;
	position: ${props => (props.absolute === true ? 'absolute' : 'fixed')};
	top: ${props => props.top}px;
	bottom: ${props => props.bottom}px;
	left: ${props => props.left}px;
	right: ${props => props.right}px;
	background-color: ${props =>
		props.primary ? props.theme.COLOR_PRIMARY : props.theme.COLOR_BACKGROUND_LIGHT_GRAY};
	color: ${props => (props.primary ? '#ffffff' : props.theme.COLOR_TEXT_DARKER)};
	border-radius: 50%;
	box-shadow: ${props => props.theme.BOX_SHADOW_FLOAING_BUTTON};
	transition: all 0.3s;
	& > i.icon {
		margin: 0;
	}
	border: none;
	outline: none;
	:hover {
		opacity: 0.6;
	}
	opacity: ${props => props.visible === false && 0};
`
