import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Card } from 'semantic-ui-react'
import { ColoredCard } from '../../common/basic/StyledElements'
import { LargeText } from '../../common/basic/StyledTexts'

/**
 * 연구 생성시 템플릿 개별 항목
 */
const ResearchOptionCombination = ({ isSelected, color, onSelect, researchInfo }) => {
	const { name } = researchInfo
	return (
		<StyledCard
			background={color}
			onClick={() => {
				onSelect(researchInfo)
			}}
			isSelected={isSelected}
		>
			<Card.Content>
				<LargeText>
					{name.split(' ').map(n => (
						<div key={n}>{n}</div>
					))}
				</LargeText>
			</Card.Content>
		</StyledCard>
	)
}

ResearchOptionCombination.propTypes = {
	color: PropTypes.string,
	researchInfo: PropTypes.shape({
		name: PropTypes.string,
	}),
	onSelect: PropTypes.func,
	isSelected: PropTypes.bool,
}

export default ResearchOptionCombination

const StyledCard = styled(ColoredCard)`
	height: 120px;
	overflow: hidden;
	border-radius: 20px !important;
	background: ${props => props.background} !important;
	${props =>
		props.isSelected === true
			? `
  box-shadow: 2px 2px 8px rgba(0,0,0,0.4) !important;
  opacity: 0.4;
  `
			: ''};
	:hover {
		color: white;
	}

	.content {
		display: flex;
		justify-items: center;
		align-items: center;
	}
`
