import * as React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { FOOTER_MENU_1, FOOTER_MENU_2, HEADER_MENU } from '../../constants/menus'

import logo from '../../assets/images/logo/lepius-logo-large.png'
import { ROUTE_ABOUT } from '../../constants/routes'
import {
	PAGE_DEFAULT_MIN_WIDTH,
	PAGE_DEFAULT_SIDE_PADDING,
	LOGO_LARGE_WIDTH,
	FOOTER_HEIGHT,
	COLOR_TEXT_LIGHTGRAY,
} from '../../constants/styles'
import { WrapperLink } from '../common/basic/WrapperLink'
import { MiniBar } from '../common/basic/MiniBar'
import { ExceptMobile, MobileOnly } from '../common/basic/ResponsiveWrappers'

/**
 * 페이지 하단 Footer
 */
export const Footer = () => {
	return (
		<Container>
			<Wrapper>
				<Logo />
				<FooterMenu items={HEADER_MENU} />
				<FooterMenu items={FOOTER_MENU_1} />
				<FooterMenu items={FOOTER_MENU_2} />
			</Wrapper>
		</Container>
	)
}

const Logo = () => {
	return (
		<LogoContainer>
			<WrapperLink to={ROUTE_ABOUT}>
				<LogoImage alt="Lepius" src={logo} />
			</WrapperLink>
			<Copyright>Copyright 2019 Tesser Inc. All Rights Reserved.</Copyright>
			<CompanyInfo>
				<div>
					<div>(주) 테서</div>
					<ExceptMobile>
						사업자 등록번호: 404-81-46323
						<MiniBar />
						통신판매업신고번호: 2019-서울서초-2615
					</ExceptMobile>
					<MobileOnly>사업자 등록번호: 404-81-46323</MobileOnly>
					<MobileOnly>통신판매업신고번호: 2019-서울서초-2615</MobileOnly>
					<div>주소: 서울시 서초구 태봉로 114 6층, 양재 R &amp; CD 혁신허브</div>
					<div>
						대표이사: 이수현
						<MiniBar />
						고객지원: 02-575-1593
					</div>
				</div>
			</CompanyInfo>
		</LogoContainer>
	)
}

const FooterMenuItem = ({ title, to, href }) => {
	return (
		<li>
			{to != null && <Link to={to}>{title}</Link>}
			{href != null && (
				<a href={href} target="_blank" rel="noopener noreferrer">
					{title}
				</a>
			)}
		</li>
	)
}

FooterMenuItem.propTypes = {
	title: PropTypes.string.isRequired,
	to: PropTypes.string,
	href: PropTypes.string,
}

const FooterMenu = ({ items }) => {
	return (
		<FooterMenuList>
			{items.map(item => (
				<FooterMenuItem key={item.key} {...item} />
			))}
		</FooterMenuList>
	)
}

FooterMenu.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
		})
	).isRequired,
}

/**
 * Styles
 */

const Container = styled.footer`
	text-align: left;

	@media (min-width: 769px) {
		width: ${PAGE_DEFAULT_MIN_WIDTH}px;
		padding: 0px ${PAGE_DEFAULT_SIDE_PADDING}px;
		padding-top: 93px;
		margin: auto;
		height: ${FOOTER_HEIGHT}px;
		margin-top: -${FOOTER_HEIGHT}px;
	}

	@media (max-width: 768px) {
		margin-top: 30px;
		padding-top: 30px;
	}
`

const Wrapper = styled.div`
	margin: 50px;
	display: flex;

	@media (max-width: 768px) {
		flex-direction: column;
	}
`

const FooterMenuList = styled.ul`
	display: inline-block;
	width: 200px;
	white-space: nowrap;

	@media (max-width: 768px) {
		padding-left: 0px;
	}
`

const LogoContainer = styled.div`
	vertical-align: top;
	padding-right: 100px;
	white-space: nowrap;

	@media (min-width: 769px) {
		padding-right: 100px;
	}
`

const LogoImage = styled.img`
	width: ${LOGO_LARGE_WIDTH}px;
	margin-bottom: 22px;
`

const Copyright = styled.div`
	width: 265px;
	height: 20px;
	font-family: Aeonik;
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.43;
	letter-spacing: -0.5px;
	color: #484848;
`

const CompanyInfo = styled.div`
	margin-top: 20px;
	width: 265px;
	font-size: 12px;
	color: ${COLOR_TEXT_LIGHTGRAY};
`
