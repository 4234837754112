import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link, withRouter, Switch, Route } from 'react-router-dom'
import { Query, graphql } from 'react-apollo'
import compose from 'lodash.flowright'
import { Header, Dimmer, Loader } from 'semantic-ui-react'

import ResearchOverview from './ResearchOverview'
import ResearchTemplates from './ResearchTemplates'
import ResearchTemplate from './ResearchTemplate'
import ResearchSettings from './ResearchSettings'
import ResearchStatistics from './ResearchStatistics'
import ResearchPatients from './ResearchPatients'
import ResearchData from './ResearchData'
import ResearchDoctors from './ResearchDoctors'
import ResearchIssues from '../components/research/issues/ResearchIssues'
import ResearchIssue from '../components/research/issues/ResearchIssue'
import ResearchIssueWrite from '../components/research/issues/ResearchIssueWrite'
import ResearchSchedule from './ResearchSchedule'

import { GET_RESEARCH_BASIC_INFO } from '../graphql/queries/research'
import { GET_USER_INFO } from '../graphql/queries/user'
import '../css/styles.css'
import ErrorView from '../components/common/error/ErrorView'
import PageNotFoundView from '../components/common/error/PageNotFoundView'
import ResearchQueries from './ResearchQueries'
import withMessage from '../higherComponents/withMessage'
import {
	ROUTE_PROJECT_DETAIL_DEFAULT,
	ROUTE_PROJECT_DETAIL_STATS,
	ROUTE_PROJECT_DETAIL_PATIENTS,
	ROUTE_PROJECT_DETAIL_SETTINGS,
	ROUTE_PROJECT_DETAIL_CALENDAR,
	ROUTE_PROJECT_DETAIL_DATA,
	ROUTE_PROJECT_DETAIL_TEMPLATES,
	ROUTE_PROJECT_DETAIL_DOCTORS,
	ROUTE_PROJECT_DETAIL_ISSUES,
	ROUTE_PROJECT_DETAIL_ISSUES_WRITE,
	ROUTE_PROJECT_DETAIL_ISSUE_DETAIL,
	ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL,
	ROUTE_MY_PROJECTS,
} from '../constants/routes'
import { replaceParams } from '../libs/routeHelper'
import { truncateString } from '../libs/valueHelper'
import { RESEARCH_SUB_MENU } from '../constants/menus'

const BASE_ROUTE = ROUTE_PROJECT_DETAIL_DEFAULT

/**
 * 연구 상세 페이지 컴퍼넌트
 */
class ResearchDetail extends PureComponent {
	static propTypes = {
		match: PropTypes.shape({
			params: PropTypes.shape({
				menu: PropTypes.string,
				id: PropTypes.string.isRequired,
			}).isRequired,
		}).isRequired,
		history: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
		userInfo: PropTypes.shape({
			user: PropTypes.object.isRequired,
		}).isRequired,
		onShowHeaderMessage: PropTypes.func.isRequired,
		onShowToastMessage: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = {
			isLoading: false,
		}
	}

	handleItemClick = (e, { name }) => {
		const { history, match } = this.props
		const rid = match.params.id

		history.push(`${replaceParams(BASE_ROUTE, { id: rid })}/${name}`)
	}

	handleSetLoading = isLoading => this.setState({ isLoading })

	render() {
		const { isLoading } = this.state
		const {
			userInfo,
			match: {
				params: { id: idStr, menu: activeItem },
			},
			onShowHeaderMessage,
			onShowToastMessage,
		} = this.props

		let id = parseInt(idStr, 10)
		if (isNaN(id)) return <PageNotFoundView />

		return (
			<Container>
				<Dimmer active={isLoading} page>
					<Header as="h2" inverted>
						저장중입니다..
						<Header.Subheader>잠시만 기다려 주세요..</Header.Subheader>
					</Header>
					<Loader />
				</Dimmer>

				<Query query={GET_RESEARCH_BASIC_INFO} variables={{ id }}>
					{({ loading, data, error }) => {
						if (error) {
							return <ErrorView />
						}

						const activeMenu = RESEARCH_SUB_MENU.find(m => m.value == activeItem)

						let researchDetail, userResearcherInfo, title
						if (data != null) {
							;({ researchDetail, userResearcherInfo } = data)
							if (researchDetail != null) {
								;({ title } = researchDetail)
							}
						}

						const contentProps = {
							...this.props,
							researchId: id,
							userInfo: userInfo.user,
							onLoading: this.handleSetLoading,
							onShowHeaderMessage: onShowHeaderMessage,
							onShowToastMessage: onShowToastMessage,
							userResearcherInfo,
							researchType: researchDetail && researchDetail.type,
							breadCrumbs: [
								{
									content: '프로젝트',
									as: Link,
									to: ROUTE_MY_PROJECTS,
									link: true,
									key: 'PG_BC_PROJECT',
								},
								{
									content: truncateString(title, 15),
									link: true,
									as: Link,
									to: replaceParams(ROUTE_PROJECT_DETAIL_DEFAULT, {
										id,
									}),
									key: 'PG_BC_PROJECT_DETAIL',
								},
							],
							...researchDetail,
						}

						return (
							<Switch>
								<Route
									exact
									path={BASE_ROUTE}
									component={() => (
										<ResearchOverview
											researchId={id}
											loadingResearchInfo={loading}
											{...contentProps}
										/>
									)}
								/>
								<Route
									path={ROUTE_PROJECT_DETAIL_STATS}
									component={props_ => (
										<ResearchStatistics
											{...contentProps}
											{...props_}
											loadingResearchInfo={loading}
										/>
									)}
								/>
								<Route
									path={ROUTE_PROJECT_DETAIL_SETTINGS}
									component={props_ => (
										<ResearchSettings {...contentProps} {...props_} loadingResearchInfo={loading} />
									)}
								/>
								<Route
									exact
									path={ROUTE_PROJECT_DETAIL_CALENDAR}
									component={props_ => (
										<ResearchSchedule {...contentProps} {...props_} loadingResearchInfo={loading} />
									)}
								/>
								<Route
									path={ROUTE_PROJECT_DETAIL_PATIENTS}
									component={props_ => (
										<ResearchPatients {...contentProps} {...props_} loadingResearchInfo={loading} />
									)}
								/>
								<Route
									path={ROUTE_PROJECT_DETAIL_DATA}
									component={props_ => (
										<ResearchData {...contentProps} {...props_} loadingResearchInfo={loading} />
									)}
								/>

								<Route
									path={ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL}
									component={props_ => (
										<ResearchTemplate {...contentProps} {...props_} loadingResearchInfo={loading} />
									)}
								/>
								<Route
									path={ROUTE_PROJECT_DETAIL_TEMPLATES}
									component={props_ => (
										<ResearchTemplates
											{...contentProps}
											{...props_}
											loadingResearchInfo={loading}
										/>
									)}
								/>
								<Route
									path={ROUTE_PROJECT_DETAIL_DOCTORS}
									component={props_ => (
										<ResearchDoctors
											{...contentProps}
											{...props_}
											pageInfo={activeMenu}
											loadingResearchInfo={loading}
										/>
									)}
								/>
								<Route
									exact
									path={ROUTE_PROJECT_DETAIL_ISSUES_WRITE}
									component={props_ => (
										<ResearchIssueWrite
											{...contentProps}
											{...props_}
											loadingResearchInfo={loading}
										/>
									)}
								/>
								<Route
									exact
									path={ROUTE_PROJECT_DETAIL_ISSUE_DETAIL}
									component={props_ => (
										<ResearchIssue {...contentProps} {...props_} loadingResearchInfo={loading} />
									)}
								/>
								<Route
									exact
									path={ROUTE_PROJECT_DETAIL_ISSUES}
									component={props_ => (
										<ResearchIssues {...contentProps} {...props_} loadingResearchInfo={loading} />
									)}
								/>

								<Route
									exact
									path={`${BASE_ROUTE}/queries`}
									component={props_ => (
										<ResearchQueries {...contentProps} {...props_} loadingResearchInfo={loading} />
									)}
								/>
							</Switch>
						)
					}}
				</Query>
				{/* </PageContainer> */}
			</Container>
		)
	}
}

export default compose(
	withRouter,
	withMessage,
	graphql(GET_USER_INFO, {
		props: ({ data: { userInfo, refetch } }) => ({
			userInfo,
			refetchUserInfo: refetch,
		}),
	})
)(ResearchDetail)

const Container = styled.div`
	padding-bottom: 50px;
`
