import { useMemo } from 'react'
import { DataFieldFormat } from '../../../../enum'

/**
 * Checkbox와 RadioButton 에서 공용으로 사용되는 로직
 *
 * format 값을 참조한 줄바꿈 처리
 */

const useCheckboxRadio = format => {
	const isUsingNewLine = useMemo(() => {
		return format && format.includes(DataFieldFormat.NEWLINE.key)
	}, [format])
	return [isUsingNewLine]
}

export default useCheckboxRadio
