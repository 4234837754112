import React from 'react'
import PropTypes from 'prop-types'
import { Label } from 'semantic-ui-react'

const PatientCode = ({ code }) => {
	if (code == null) return <span />

	return (
		<Label
			style={{ fontWeight: 700, width: '100%', borderRadius: 2, textAlign: 'center' }}
			basic
			color="teal"
		>
			{code}
		</Label>
	)
}

PatientCode.propTypes = {
	code: PropTypes.string,
}

PatientCode.defaultProps = {
	code: null,
}

export default PatientCode
