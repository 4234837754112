import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TextArea } from 'semantic-ui-react'
import styled from 'styled-components'
import useLocalMedInfo from '../../../hooks/useLocalMedInfo'

/**
 * 텍스트 인풋
 */
const PatientSurveyText = ({ medInfo }) => {
	const { id, medInfoType } = medInfo
	const { placeholder } = medInfoType

	const [, onUpdate] = useLocalMedInfo(id)

	const [value, setValue] = useState(medInfo.value || '')

	return (
		<Container>
			<TextInput
				placeholder={placeholder}
				onChange={(e, { value }) => {
					onUpdate('value', value)
					setValue(value)
				}}
				value={value}
			/>
		</Container>
	)
}

PatientSurveyText.propTypes = {
	medInfo: PropTypes.object,
}

export default PatientSurveyText

const Container = styled.div`
	width: 100%;
	height: 100%;
	padding: 20px;
	display: flex;
	justify-content: center;
	flex-direction: column;
`

const TextInput = styled(TextArea)`
	width: 100%;
	height: 80%;
	font-size: 5vmin;
	outline: none;
	border: none;
	color: ${props => props.theme.COLOR_TEXT_NAVY};
	background: none;
	resize: none;
	::placeholder {
		color: #cbcbcb;
	}
`
