import gql from 'graphql-tag'

const COMMENT_BASIC = gql`
	fragment CommentBasic on Comment {
		id
		text
		createdAt
		parentId
	}
`

const COMMENT_NICKNAME = gql`
	fragment CommentNickname on Comment {
		...CommentBasic
		writer {
			id
			nickname
			profileImage
		}
		replyTo {
			id
			nickname
		}
	}

	${COMMENT_BASIC}
`

const COMMENT_NAME = gql`
	fragment CommentName on Comment {
		...CommentBasic
		writer {
			id
			name
			profileImage
		}
		replyTo {
			id
			name
		}
	}

	${COMMENT_BASIC}
`

export const COMMENT_NICKNAME_FRAGMENT = gql`
	fragment CommentNicknameParts on Comment {
		...CommentNickname
	}
	${COMMENT_NICKNAME}
`

export const COMMENT_NAME_FRAGMENT = gql`
	fragment CommentNameParts on Comment {
		...CommentName
	}
	${COMMENT_NAME}
`

export const COMMENT_ENTITY = gql`
	fragment CommentEntityTYpe on CommentEntity {
		... on Record {
			id
			patientId
		}

		... on Patient {
			id
		}

		... on MedInfoQuery {
			id
			record {
				id
				patientId
			}
		}

		... on Issue {
			id
		}
	}
`
