import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'react-apollo'
import { Button, Label, Modal } from 'semantic-ui-react'
import { GET_CASE } from '../../graphql/queries/case'
import PlaceholderContent from '../common/loader/PlaceholderContents'
import PlaceholderShort from '../common/loader/PlaceholderShort'
import {
	COLOR_BORDER_GRAY,
	COLOR_SECONDARY_BLUE,
	COLOR_TEXT_DARK_GRAY,
	COLOR_TEXT_GRAY,
	COLOR_TEXT_LIGHTGRAY,
	PAGE_MOBILE_MAX_WIDTH,
	PAGE_NOT_MOBILE_MIN_WIDTH,
} from '../../constants/styles'
import { convertDateToPostStyle } from '../../libs/dateHelper'
import UserInline from '../common/UserInline'
import useUserInfo from '../../hooks/useUserInfo'
import ModalDeleteCase from './ModalDeleteCase'
import Comments from '../../containers/Comments'
import PatientLabel from './PatientLabel'
import ScopeLabel from './ScopeLabel'
import CaseTag from './CaseTag'

const CaseDetail = () => {
	const { caseId } = useParams()
	const caseIdNum = parseInt(caseId, 10)
	const history = useHistory()
	const { user } = useUserInfo()
	const [imageIndex, setImageIndex] = useState([])
	const [imageModal, setImageModal] = useState(null)
	const { data: { simpleCase } = {}, loading } = useQuery(GET_CASE, {
		variables: {
			id: caseIdNum,
		},
		fetchPolicy: 'cache-and-network',
		onCompleted: ({ simpleCase: oCC }) => {
			const chartOC = JSON.parse(oCC.charts)

			setImageIndex(
				chartOC.map(i => {
					return 0
				})
			)
		},
	})

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

	let creator = {}
	let tags = []
	let charts = []
	if (simpleCase != null) {
		;({ creator } = simpleCase)
		try {
			tags = JSON.parse(simpleCase.tags)
			charts = JSON.parse(simpleCase.charts)
		} catch (e) {
			alert('태그를 불러오는데 오류가 발생했습니다.')
		}
	}

	return (
		<DetailBox>
			<Modal
				size="large"
				open={imageModal != null}
				onClose={() => {
					setImageModal(null)
				}}
			>
				<Modal.Content>
					{imageModal != null && charts[imageModal[0]].images[imageModal[1]] && (
						<div style={{ textAlign: 'center', position: 'relative' }}>
							<img
								style={{ maxWidth: '100%', maxHeight: '80vh', objectFit: 'contain' }}
								src={charts[imageModal[0]].images[imageModal[1]].url}
							/>
						</div>
					)}
				</Modal.Content>
			</Modal>
			<div
				style={{
					fontSize: 30,
					fontWeight: 600,
					marginBottom: 10,
					lineHeight: 1.6,
					paddingBotttom: 10,
				}}
			>
				{loading === true && <PlaceholderShort />}
				{simpleCase != null && (
					<>
						<div style={{ marginBottom: 5 }}>
							<PatientLabel sex={simpleCase.sex} age={simpleCase.age} title />
						</div>
						{simpleCase.title}
					</>
				)}
			</div>
			{simpleCase != null && (
				<div>
					<div
						style={{
							color: COLOR_TEXT_LIGHTGRAY,
							fontSize: 13,
							display: 'inline-block',
							marginRight: 20,
						}}
					>
						{`${convertDateToPostStyle(simpleCase.createdAt)}`}
						{simpleCase.updatedAt != null &&
							simpleCase.updatedAt !== simpleCase.createdAt &&
							`  (최근 업데이트 ${convertDateToPostStyle(simpleCase.updatedAt)})`}
						<span style={{ marginLeft: 15, display: 'inline-block' }}>
							의견 &nbsp;<b style={{ color: '#777' }}>{simpleCase.commentCount}</b> 개
						</span>
						<ScopeLabel
							style={{ marginLeft: 20 }}
							popup={false}
							scope={simpleCase.sharingScope}
							groupTitle={simpleCase.group?.title}
						/>
					</div>
					<UserInline
						size={18}
						profileImage={creator.profileImage}
						name={creator.nickname}
						fontSize={13}
						fontWeight={400}
					/>
				</div>
			)}
			<div style={{ marginTop: 30, fontSize: 17 }}>
				{loading === true && <PlaceholderContent />}
				{simpleCase != null && (
					<div>
						<div style={{ marginBottom: 40 }}>
							{tags != null &&
								tags.map(tag => {
									return (
										<CaseTag
											style={{
												fontSize: 16,
												marginRight: 12,
											}}
											key={`case-detail-tag-${tag.key}`}
											tagId={tag.key}
											text={tag.text}
										/>
									)
								})}
						</div>
						<div style={{ whiteSpace: 'pre-line', fontSize: '1em', lineHeight: 1.8 }}>
							{charts.map((chart, i) => (
								<div
									key={`case_det_${caseId}_${chart.id}`}
									style={{ paddingBottom: 40, paddingTop: 40, borderBottom: '1px solid #ccc' }}
								>
									<div style={{ marginBottom: 12 }}>
										{i === 0 ? <Label color="green">초진</Label> : <Label>재진</Label>}
										<div
											style={{
												display: 'inline-block',
												marginLeft: 20,
												fontSize: 15,
												color: COLOR_TEXT_DARK_GRAY,
											}}
										>
											{chart.date}
										</div>
									</div>
									{chart.images && chart.images.length > 0 && (
										<div style={{ marginBottom: 20 }}>
											{imageIndex.length > 0 && (
												<div
													onClick={() => {
														setImageModal([i, imageIndex[i]])
													}}
												>
													<img
														style={{
															width: '100%',
															maxHeight: 500,
															cursor: 'pointer',
															objectFit: 'contain',
														}}
														src={chart.images[imageIndex[i]].url}
													/>
												</div>
											)}
											{chart.images.map((img, imgIdx) => (
												<ImageBox
													key={`chart_img_${img.url}`}
													selected={imgIdx === imageIndex[i]}
													onClick={() => {
														setImageIndex(
															imageIndex.map((x, xi) => {
																if (xi === i) return imgIdx
																return x
															})
														)
													}}
												>
													<img
														style={{
															width: 70,
															height: 70,
															objectFit: 'cover',
														}}
														src={img.url}
													/>
												</ImageBox>
											))}
										</div>
									)}
									<div>{chart.chart}</div>
								</div>
							))}
						</div>
						{user && creator.id === user.id && (
							<div>
								<div
									style={{ color: COLOR_TEXT_GRAY, fontSize: 14, marginBottom: 10, marginTop: 50 }}
								>
									새로운 차트 기록은 케이스 수정을 통해 진행해 주세요.
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										flex: 1,
										justifyContent: 'space-between',
									}}
								>
									<Button
										onClick={() => history.push(`/cases/write/${caseId}`)}
										primary
										size="large"
									>
										케이스 업데이트
									</Button>
									<Button
										size="large"
										onClick={() => {
											setIsDeleteModalOpen(true)
										}}
									>
										케이스 삭제
									</Button>
									<ModalDeleteCase
										isOpen={isDeleteModalOpen}
										onClose={() => setIsDeleteModalOpen(false)}
										caseId={caseIdNum}
									/>
								</div>
							</div>
						)}
					</div>
				)}
			</div>
			<Comments useNick entityType="CASE" entityId={caseIdNum} noBorder />
		</DetailBox>
	)
}

CaseDetail.propTypes = {}

export default CaseDetail

const DetailBox = styled.div`
	/** Not Mobile */
	@media (min-width: ${PAGE_NOT_MOBILE_MIN_WIDTH}px) {
		padding: 30px;
		max-width: 700px;
		margin: auto;
	}

	/** Mobile */
	@media (max-width: ${PAGE_MOBILE_MAX_WIDTH}px) {
		padding: 0px;
	}
`

const ImageBox = styled.div`
	display: inline-block;
	width: 70px;
	height: 70px;
	position: relative;
	cursor: pointer;
	box-sizing: content-box;
	border: 1px solid ${props => (props.selected === true ? COLOR_SECONDARY_BLUE : COLOR_BORDER_GRAY)};
	margin-right: 10px;
	:hover {
		border: 1px solid ${COLOR_SECONDARY_BLUE};
	}
`
