import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Header, Button } from 'semantic-ui-react'

export default class RecordUpdateTemplateHeader extends PureComponent {
	static propTypes = {
		addCount: PropTypes.number.isRequired,
		removeCount: PropTypes.number.isRequired,
		templateTitle: PropTypes.string.isRequired,
		patientName: PropTypes.string.isRequired,
		onSubmit: PropTypes.func.isRequired,
		isSaveLoading: PropTypes.bool,
	}

	render() {
		const {
			onSubmit,
			addCount,
			isSaveLoading,
			removeCount,
			templateTitle,
			patientName,
		} = this.props

		return (
			<div className="sticky-header">
				<div className="sticky-header-title" style={styles.wrap}>
					<div style={styles.header}>
						<div className="sticky-additional" style={styles.recordInfo}>
							{templateTitle} - {patientName}
						</div>
						<Header as="h3" className="sticky-header-text" style={styles.headerText}>
							양식 업데이트: 변경 사항 확인
						</Header>
					</div>
					<div className="sticky-header-fill" style={styles.diff}>
						<span style={styles.diffCount}>
							추가된 항목 <span style={styles.diffNumAdd}>+{addCount}</span>
						</span>
						<span style={styles.diffCount}>
							삭제된 항목 <span style={styles.diffNumRemove}>-{removeCount}</span>
						</span>
					</div>
					<div style={styles.buttonWrap}>
						<Button
							size="big"
							className="sticky-btn-right"
							style={styles.submitButton}
							onClick={onSubmit}
							primary
							content="업데이트 적용"
							loading={isSaveLoading}
						/>
					</div>
				</div>
			</div>
		)
	}
}

const styles = {
	wrap: {
		margin: '12px 50px',
		display: 'inline-table',
	},
	header: {
		paddingRight: 30,
		display: 'table-cell',
		verticalAlign: 'middle',
		whiteSpace: 'nowrap',
		width: '1%',
	},
	headerText: {
		marginTop: 0,
	},
	diff: {
		display: 'table-cell',
		verticalAlign: 'middle',
	},
	diffCount: {
		marginRight: 20,
	},
	diffNumRemove: {
		color: '#cb2431',
		fontWeight: 600,
		fontSize: 16,
	},
	diffNumAdd: {
		color: '#19c119',
		fontWeight: 600,
		fontSize: 16,
	},
	buttonWrap: {
		display: 'table-cell',
		whiteSpace: 'nowrap',
		width: '1%',
		verticalAlign: 'middle',
	},
	submitButton: {
		marginLeft: 20,
	},
	recordInfo: {
		fontWeight: 600,
		fontSize: 16,
	},
}
