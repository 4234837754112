import React from 'react'
import ContentContainer from './ContentContainer'

const ChartContainer = ({ children }) => {
  return (
    <ContentContainer
      style={{
        textAlign: 'center',
        padding: '20px 60px',
      }}
    >
      <div
        style={{
          minWidth: 1024,
          maxWidth: 1600,
          margin: 'auto;',
        }}
      >
        {children}
      </div>
    </ContentContainer>
  )
}

export default ChartContainer
