import React, { useState } from 'react'
import { useMutation } from 'react-apollo'

import { SET_PATIENT_SURVEY_STATUS } from '../../graphql/mutations/app'
import { ADD_RECORD_FOR_SURVEY } from '../../graphql/mutations/record'
import { getTodayString } from '../../libs/dateHelper'
import { PatientSurveySectionTitle } from '../common/basic/StyledTexts'
import PatientSearch from './PatientSearch'
import PatientSurveyButton from './PatientSurveyButton'
import PatientSurveyNewPatient from './PatientSurveyNewPatient'
import { TEMPLATE_SECTION } from './PatientSurveySection'

const PatientSurveyPatient = ({
	templateId,
	researchId,
	// setSelectedTemplate,
	// handleBefore,
	handleSection,
}) => {
	const [isAddModalOpen, setAddModalOpen] = useState(false)
	const [newPatientCode, setNewPatientCode] = useState(null)
	const [setPatientSurveyStatus] = useMutation(SET_PATIENT_SURVEY_STATUS)

	const [addRecord] = useMutation(ADD_RECORD_FOR_SURVEY, {
		onCompleted: ({
			addRecord: {
				id: recordId,
				medInfos,
				patient: { id: patientId, name: patientName, patientCode },
				template: { id: templateId, title: templateName, description: templateDescription },
			},
		}) => {
			setPatientSurveyStatus({
				variables: {
					input: {
						recordId,
						researchId,
						templateName,
						templateDescription,
						patientId,
						patientCode,
						patientName,
						medInfos,
					},
				},
			})
		},
	})

	const onSelectPatient = patientId => {
		if (patientId != null && researchId != null && templateId != null) {
			addRecord({
				variables: {
					patientId,
					templateId,
					researchId,
					visitedDate: getTodayString(),
				},
			})
		} else {
			alert('데이터가 잘못되었습니다.')
		}
	}
	return (
		<>
			<PatientSurveySectionTitle>환자를 선택하세요.</PatientSurveySectionTitle>
			<PatientSurveyButton
				direction="right"
				click={() => {
					handleSection(TEMPLATE_SECTION)
				}}
			>
				다른 설문 선택하기
			</PatientSurveyButton>
			<PatientSearch
				onSelectPatient={onSelectPatient}
				researchId={researchId}
				onCreatePatient={patientCode => {
					setNewPatientCode(patientCode)
					setAddModalOpen(true)
				}}
			/>
			<PatientSurveyNewPatient
				onSelectPatient={onSelectPatient}
				researchId={researchId}
				onUpdatePatient={onSelectPatient}
				onClose={() => setAddModalOpen(false)}
				patientCode={newPatientCode}
				isOpen={isAddModalOpen}
			/>
		</>
	)
}

export default PatientSurveyPatient
