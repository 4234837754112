import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Header, Form, Button, Input, Segment } from 'semantic-ui-react'
import PageHeader from '../../common/PageHeader'

const styles = {
	contentBox: {
		border: '1px solid #eee',
		padding: '50px 60px',
		borderRadius: 10,
	},
	description: {
		whiteSpace: 'pre-line',
		marginBottom: 30,
	},
	ind: {
		marginTop: -5,
		marginBottom: 10,
		fontSize: 13,
		color: '#777',
	},
}

const UserForgotAccountView = ({
	menu,
	isCodeSent,
	isCodeVerified,
	isPasswordChanged,
	usernameFound,
	onChange,
	onSubmit,
	email,
	name,
	username,
	authCode,
	password,
	passwordRepeat,
}) => {
	const info = {}
	if (menu === 'username') {
		info.title = '아이디 찾기'
		info.description =
			'회원 가입시 사용한 이메일 주소를 통해 계정 정보를 확인할 수 있습니다. \n 해당 이메일로 등록된 계정이 없을 경우 이메일이 도착하지 않습니다.'
	} else {
		info.title = '비밀번호 찾기'
		info.description = '회원 가입시 사용한 이메일 인증을 통해 새로운 비밀번호를 설정할 수 있습니다.'
	}

	const loginRedirectButton = (
		<div style={{ textAlign: 'center', marginTop: 20 }}>
			<Button size="big" style={{ margin: 'auto' }} primary as={Link} to="/login">
				로그인 페이지로 이동
			</Button>
		</div>
	)

	return (
		<div style={{ margin: 'auto' }}>
			<PageHeader title={info.title} />
			{!isCodeVerified ? ( // 코드 인증 전/후
				<div>
					<div style={styles.description}>{info.description}</div>
					<Form>
						{menu === 'username' && (
							<Form.Field
								label="이름"
								name="name"
								placeholder="본인 이름"
								control={Input}
								onChange={onChange}
								value={name}
							/>
						)}
						{menu === 'password' && (
							<Form.Field
								label="아이디"
								name="username"
								placeholder="아이디"
								control={Input}
								onChange={onChange}
								value={username}
							/>
						)}
						<Form.Field
							label="이메일 주소"
							name="email"
							placeholder="이메일 주소"
							control={Input}
							onChange={onChange}
							value={email}
						/>
						{isCodeSent === true && (
							<React.Fragment>
								<Form.Field
									label="인증번호"
									name="authCode"
									placeholder="6자리 인증번호"
									control={Input}
									onChange={onChange}
									value={authCode}
								/>
								<div style={styles.ind}>
									입력하신 이메일로 인증번호가 발송되었습니다. 발송 후 5분 이내에 입력해주세요.
								</div>
							</React.Fragment>
						)}
						<Button style={{ marginTop: 20 }} primary size="big" onClick={onSubmit}>
							{!isCodeSent ? '인증번호 받기' : '인증번호 확인'}
						</Button>
					</Form>
				</div>
			) : (
				<div style={{ marginTop: 30, padding: 30, marginBottom: 30 }}>
					{menu === 'username' && (
						<div>
							<Header as="h2">인증 성공.</Header>
							고객님의 계정 아이디는 다음과 같습니다.
							<br />
							<Segment textAlign="center">
								<b style={{ fontSize: 20 }}>{usernameFound}</b>
							</Segment>
							{loginRedirectButton}
						</div>
					)}
					{menu === 'password' && (
						<div>
							{!isPasswordChanged ? ( // 비밀번호 변경 전
								<div>
									<Header as="h2">
										인증 성공.
										<br />
										비밀번호를 재설정해 주세요.
									</Header>
									<Form style={{ maxWidth: 300, marginTop: 30 }}>
										<Form.Field
											label="비밀번호"
											control={Input}
											type="password"
											name="password"
											value={password}
											onChange={onChange}
										/>
										<Form.Field
											label="비밀번호 확인"
											control={Input}
											type="password"
											name="passwordRepeat"
											value={passwordRepeat}
											onChange={onChange}
										/>
										<Button size="big" style={{ marginTop: 20 }} primary onClick={onSubmit}>
											비밀번호 변경
										</Button>
									</Form>
								</div>
							) : (
								<div>
									<Header as="h2" textAlign="center">
										비밀번호가 변경되었습니다.
									</Header>
									{loginRedirectButton}
								</div>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	)
}

UserForgotAccountView.propTypes = {
	menu: PropTypes.string,
	isCodeSent: PropTypes.bool,
	isCodeVerified: PropTypes.bool,
	isPasswordChanged: PropTypes.bool,
	usernameFound: PropTypes.string,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
	email: PropTypes.string,
	name: PropTypes.string,
	username: PropTypes.string,
	authCode: PropTypes.string,
	password: PropTypes.string,
	passwordRepeat: PropTypes.string,
}

export default UserForgotAccountView
