const LOCAL_STORAGE_KEY_PATIENT_SURVEY = 'patient-survey'
const LOCAL_STORAGE_KEY_PATIENT_SURVEY_DEFAULT = 'patient-survey-default'

export const getLocalStorageJson = key => {
	let data = localStorage.getItem(key)

	if (data == null) {
		return
	}

	try {
		return JSON.parse(data)
	} catch (e) {
		console.log(e)
		return
	}
}

export const setLocalStorageJson = (key, data) => {
	try {
		localStorage.setItem(key, JSON.stringify(data))
	} catch (e) {
		console.log(e)
		throw new Error('cookie error occured')
	}
}

export const getStoragePatientSurvey = () => {
	return getLocalStorageJson(LOCAL_STORAGE_KEY_PATIENT_SURVEY)
}

export const setStoragePatientSurvey = status => {
	setLocalStorageJson(LOCAL_STORAGE_KEY_PATIENT_SURVEY, status)
}
