import styled, { css } from 'styled-components'
import { Button } from 'semantic-ui-react'

export default styled(Button)`
	background: ${props =>
		props.readOnly === true
			? 'linear-gradient(97deg, #f4f4f4, #dfdfdf)'
			: 'linear-gradient(97deg, #87c8d7, #7abfcf)'} !important;
	width: 80%;
	height: 64px;
	flex: ${props => props.flex};
	color: white !important;
	border-radius: 8px !important;
	box-shadow: ${props => props.theme.BOX_SHADOW_SURVEY_BUTTON} !important;
	border: ${props => props.theme.BORDER_SURVEY_BUTTON} !important;
	outline: none;
	font-size: 20px !important;
	transition: all 0.3s !important;
	${props =>
		props.readOnly === true &&
		css`
			pointer-events: none;
		`};
`
