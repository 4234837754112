import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { Checkbox, Icon, Table, Pagination } from 'semantic-ui-react'
import { withApollo } from 'react-apollo'
import InstitutionViewRow from './ResearchMemberInstitutionViewRow'
import { COLOR_TEXT_DARKGRAY } from '../../../../constants/styles'
import { matchErrorCode } from '../../../../libs/errorHelper'
import errorTypes from '../../../../errorTypes'
import { UPDATE_RESEARCHER_INFO } from '../../../../graphql/mutations/record'
import {
	GET_RESEARCHER_INFO,
	GET_RESEARCHER_INSTITUTION_PERMISSION,
} from '../../../../graphql/queries/record'

import { InlineLoader } from '../../../common/loader/InlineLoader'
import { UISize } from '../../../../enum'

/**
 * 연구자 기관 권한 설정 화면
 */
class InstitutionView extends PureComponent {
	static propTypes = {
		researcher: PropTypes.shape({
			researcherInfo: PropTypes.shape({
				canAccess: PropTypes.bool,
				canEdit: PropTypes.bool,
				canAccessAll: PropTypes.bool,
				canEditAll: PropTypes.bool,
			}),
		}).isRequired,
		onUpdate: PropTypes.func.isRequired,
		perPage: PropTypes.number.isRequired,
		match: PropTypes.shape({
			params: PropTypes.shape({
				id: PropTypes.string,
				subId: PropTypes.string,
			}),
		}),
		onUpdateResearcherInfo: PropTypes.func.isRequired,
		onRefreshResearcherInfo: PropTypes.func.isRequired,
		institutions: PropTypes.arrayOf(),
		page: PropTypes.number,
		totalCount: PropTypes.number,
		client: PropTypes.shape({
			mutate: PropTypes.func,
		}),
	}
	constructor(props) {
		super(props)

		this.state = {
			loading: false,
		}
	}

	handlePageChange = (e, { activePage }) => {
		const { onUpdate, perPage, match } = this.props
		onUpdate({
			researchId: parseInt(match.params.id, 10),
			userId: parseInt(match.params.subId, 10),
			page: activePage,
			perPage,
		})
	}

	handleCheck = key => async (e, { checked }) => {
		const { match, client } = this.props
		const researchId = parseInt(match.params.id, 10)
		const userId = parseInt(match.params.subId, 10)

		try {
			this.setState({
				loading: true,
			})
			let researcherInfo = { [key]: checked }
			if (key === 'canAccessAll' && checked === false) {
				researcherInfo.canEditAll = false
			}

			await client.mutate({
				mutation: UPDATE_RESEARCHER_INFO,
				variables: {
					researchId,
					userId,
					researcherInfo,
				},
				refetchQueries: [
					{
						query: GET_RESEARCHER_INFO,
						variables: {
							researchId,
							userId,
						},
					},
					{
						query: GET_RESEARCHER_INSTITUTION_PERMISSION,
						variables: {
							perPage: 20,
							page: 1,
							researchId,
							userId,
						},
					},
				],
			})

			this.setState({
				loading: false,
			})
		} catch (e) {
			this.setState({
				loading: false,
			})
			if (matchErrorCode(e, errorTypes.ACCESS_FORBIDDEN_ERROR)) {
				alert('권한이 부족합니다.')
			} else {
				alert('상태 변경에 실패하였습니다.')
			}
		}
	}

	render() {
		const { institutions, page, totalCount, perPage, researcher } = this.props
		const { loading } = this.state
		const {
			researcherInfo: { canEdit, canAccess, canAccessAll, canEditAll },
		} = researcher

		return (
			<Container>
				{loading === true ? (
					<InlineLoader height={40} size={UISize.TINY} />
				) : (
					<>
						<Tool>
							<Checkbox
								label="전체 기관 열람 권한 가짐"
								disabled={canAccess === false}
								checked={canAccessAll}
								onChange={this.handleCheck('canAccessAll')}
							/>
						</Tool>
						<Tool>
							<Checkbox
								disabled={canEdit === false || canAccessAll !== true}
								label="전체 기관 데이터 관리"
								checked={canEditAll}
								onChange={this.handleCheck('canEditAll')}
							/>
						</Tool>
					</>
				)}
				<TableTools>
					<Count>{totalCount} 개 기관</Count>
				</TableTools>
				<Table celled style={{ textAlign: 'center' }}>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>
								기관명
								{/* <Icon name="caret down" /> */}
							</Table.HeaderCell>
							<Table.HeaderCell>
								데이터 열람
								{/* <Icon name="caret down" /> */}
							</Table.HeaderCell>
							<Table.HeaderCell>
								데이터 관리
								{/* <Icon name="caret down" /> */}
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{institutions.map(inst => (
							<InstitutionViewRow
								key={inst.institution.id}
								editDisabled={canEdit === false || canEditAll === true || inst.canAccess === false}
								readDisabled={canAccess === false || canAccessAll === true}
								{...this.props}
								{...inst}
								canAll={{ canAccessAll, canEditAll }}
							/>
						))}
					</Table.Body>
				</Table>

				<div style={{ textAlign: 'center' }}>
					<Pagination
						style={{
							margin: '40px auto',
						}}
						activePage={page}
						totalPages={Math.ceil(totalCount / perPage)}
						onPageChange={this.handlePageChange}
					/>
				</div>
			</Container>
		)
	}
}

export default withApollo(InstitutionView)

/**
 * Styles
 */

const Container = styled.div`
	padding: 20px 40px;
	padding-top: 0px;
`

const Tool = styled.span`
	display: inline-block;
	margin-left: 10px;
`

const TableTools = styled.div`
	margin-top: 40px;
`

const Count = styled.div`
	font-size: 13px;
	color: ${COLOR_TEXT_DARKGRAY};
`
