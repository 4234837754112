import { useQuery } from 'react-apollo'
import { GET_RESEARCH_TEMPLATES } from '../graphql/queries/research'

/**
 * 연구 템플릿 목록 불러오기
 */
const useResearchTemplates = researchId => {
	const {
		data: { researchDetail: { recordTemplates } = {} } = {},
		loading,
		error,
		refetch,
	} = useQuery(GET_RESEARCH_TEMPLATES, {
		variables: {
			researchId,
		},
	})

	return { loading, templates: recordTemplates, error, refetch }
}

export default useResearchTemplates
