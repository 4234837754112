import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'semantic-ui-react'
import ReactSVG from 'react-svg'

import Icon from '../../../assets/images/icons/search.svg'

const InputSearch = props => {
	const { inputRef, fluid, className, isRound, placeholder, onClickIcon, ...others } = props
	return (
		<div
			className={className}
			style={{
				...(fluid === true
					? {
							display: 'block',
							flex: 1,
					  }
					: {
							display: 'inline-block',
					  }),
				position: 'relative',
			}}
		>
			<Input
				fluid={fluid}
				ref={inputRef}
				placeholder={placeholder || '검색어를 입력해주세요.'}
				className={`rect-search ${isRound === true ? 'round-search' : ''}`}
				{...others}
				key="Rect-Search"
			/>
			<ReactSVG
				src={Icon}
				onClick={onClickIcon}
				style={{ position: 'absolute', left: 10, top: '50%', marginTop: -10 }}
			/>
		</div>
	)
}

export default InputSearch

InputSearch.propTypes = {
	fluid: PropTypes.bool,
	key: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	name: PropTypes.string,
	isRound: PropTypes.bool,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	inputRef: PropTypes.node,
}

InputSearch.defaultProps = {
	fluid: false,
	key: '',
	value: '',
	onChange: () => {},
	name: '',
	isRound: false,
	placeholder: null,
}
