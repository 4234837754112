import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { Header, Button, Menu, Search, Dropdown, Image, Icon } from 'semantic-ui-react'

import { FIXED_CONTAINER_WIDTH } from '../constants'

const styles = {
	container: {
		margin: 'auto',
		marginTop: 50,
		width: FIXED_CONTAINER_WIDTH,
	},
	header: {
		textAlign: 'center',
	},
	desc: {
		color: '#999',
		fontSize: 17,
	},
	btn: {
		margin: 'auto',
		marginTop: 15,
		marginBottom: 15,
	},
	search: {
		display: 'inline-block',
	},
	dropdown: {
		display: 'inline-block',
		marginLeft: 20,
	},
	itemImage: {
		width: 60,
		height: 60,
		display: 'inline-block',
		verticalAlign: 'middle',
	},
	itemContent: {
		display: 'inline-block',
		paddingLeft: 30,
		width: 'calc(100% - 80px)',
		verticalAlign: 'middle',
	},
	itemAttrs: {
		float: 'right',
	},
	itemTitle: {
		minHeight: 25,
	},
	itemTitleText: {
		fontSize: 17,
		color: '#555',
	},
	itemUser: {
		display: 'inline-block',
		fontSize: 15,
		fontWeight: 700,
	},
	itemAttr: {
		color: '#555',
		display: 'inline-block',
		fontSize: 15,
		marginLeft: 20,
	},
}

class Cases extends PureComponent {
	static propTypes = {
		history: PropTypes.object,
		match: PropTypes.object,
	}

	handleClickMenu = (e, { name }) => {
		const { history } = this.props

		history.push(`/cases/${name}`)
	}

	render() {
		const { match } = this.props
		// const queries = qs.parse(this.props.location.search, {ignoreQueryPrefix: true})

		const activeMenu = match.params.p1 || 'popular'
		return (
			<div style={styles.container}>
				<div style={styles.header}>
					<Header as="h2">Lepius 케이스</Header>
					<div style={styles.desc}>더 나은 치료방식을 찾고 토론하는 공간입니다.</div>
					<Button color="blue" style={styles.btn}>
						케이스 공유하기
					</Button>
				</div>
				<div>
					<div style={{ float: 'right' }}>
						<Search style={styles.search} placeholder="검색" />
						<Dropdown style={styles.dropdown} placeholder="정렬" />
					</div>
					<Menu pointing secondary>
						<Menu.Item
							active={activeMenu === 'popular'}
							onClick={this.handleClickMenu}
							name="popular"
						>
							인기
						</Menu.Item>
						<Menu.Item active={activeMenu === 'all'} onClick={this.handleClickMenu} name="all">
							전체
						</Menu.Item>
					</Menu>
				</div>
				<div>
					<div style={{ padding: 20, borderBottom: '1px solid #eee' }}>
						<Image
							style={styles.itemImage}
							wrapped
							src="https://kr.object.ncloudstorage.com/lepius-media-storage/contents/images/symptom-types/neural.png"
						/>
						<div style={styles.itemContent}>
							<div style={styles.itemTitle}>
								<Link to="/cases/1" style={styles.itemTitleText} className="hover-blue">
									하하하하
								</Link>
							</div>
							<div>
								<div style={styles.itemUser}>
									<Link to="/users/1">PhdHiru</Link>
								</div>
								<div style={styles.itemAttrs}>
									<span style={styles.itemAttr}>2019-03-10</span>
									<span style={styles.itemAttr}>
										<Icon name="star" />
										52
									</span>
									<span style={styles.itemAttr}>
										<Icon name="comment outline" />
										105
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(Cases)
