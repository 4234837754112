/**
 * [Component] Ellipsis(...) 버튼 (점 세개 누르면 드롭다운 메뉴 나오는거)
 */

import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Dropdown } from 'semantic-ui-react'
import { COLOR_TEXT_DARKGRAY } from '../../constants/styles'

const EllipsisDropdown = ({
	id,
	className,
	isHorizontal,
	options,
	content,
	style,
	direction,
	...other
}) => {
	return (
		<StyledDropdown
			id={id}
			className={`ellipsis-more-button ${className != null ? className : ''}`}
			floating
			onClick={e => {
				e.preventDefault()
				e.stopPropagation()
				return true
			}}
			trigger={<React.Fragment />}
			style={{
				padding: '5px 10px',
				...style,
			}}
			icon={`ellipsis ${isHorizontal !== true ? 'vertical' : 'horizontal'}`}
			direction={direction}
			{...other}
		>
			<Dropdown.Menu>
				{content == null
					? options != null && options.map(option => <Dropdown.Item key={option.key} {...option} />)
					: content}
			</Dropdown.Menu>
		</StyledDropdown>
	)
}

EllipsisDropdown.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			content: PropTypes.string,
		})
	),
	content: PropTypes.node,
	style: PropTypes.shape(),
	isHorizontal: PropTypes.bool,
	direction: PropTypes.string,
}

EllipsisDropdown.defaultProps = {
	style: null,
	options: null,
	isHorizontal: false,
	direction: 'left',
}

export default EllipsisDropdown

const StyledDropdown = styled(Dropdown)`
	float: right;
	color: ${COLOR_TEXT_DARKGRAY};
	transition: opacity 0.1s, box-shadow 0.1s ease, width 0.1s ease !important;

	.menu {
		width: 150px;
	}

	.ellipsis.icon {
		transition: opacity 0.1s !important;
	}

	:hover .ellipsis.icon {
		opacity: 0.4 !important;
	}
`
