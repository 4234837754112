/* eslint-disable import/no-cycle */
/**
 * [Component] BLOCK InputType의 MedInfo (MedInfo 그룹)
 *
 * medInfos Prop으로 하위 MedInfo 소유
 */

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { DropTarget } from 'react-dnd'
import { Accordion, Header, Grid } from 'semantic-ui-react'

import withMedInfos from '../../../higherComponents/withMedInfos'
import DataFieldBox from './DataFieldBox'
import DataFieldEditDropdown from '../control/DataFieldEditDropdown'

import { DRAG_ITEM_TYPES } from '../../../constants'
import { DataFieldFormat } from '../../../enum'
import Arrow from '../../common/basic/Arrow'
import DataFieldDiv from './DataFieldDiv'
import { COLOR_PRIMARY } from '../../../constants/styles'
import DataFieldAddButton from './DataFieldAddButton'

// 드래그 앤 드롭: 드롭 받을 때
const dataGroupTarget = {
	drop(props, monitor) {
		if (monitor.isOver() === true) {
			return {
				sectionIndex: props.sectionIndex,
				groupIndex: props.medInfoIndex,
				groupId: props.medInfoId,
				medInfoId: props.medInfoId,
				isAdding: true,
				isGroup: true,
			}
		}
	},
}

// 드롭 타겟 수집
function collectTarget(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver({ shallow: true }),
	}
}

class DataFieldGroup extends PureComponent {
	static propTypes = {
		medInfos: PropTypes.array,
		name: PropTypes.string.isRequired,
		sectionIndex: PropTypes.number.isRequired,
		medInfoIndex: PropTypes.number.isRequired,
		onOpenTypeModal: PropTypes.func.isRequired,
		templateMode: PropTypes.bool.isRequired,
		onCreateMedInfo: PropTypes.func.isRequired,
		onUpdateMedInfo: PropTypes.func.isRequired,
		onDeleteMedInfo: PropTypes.func.isRequired,
		onClickSettingMenu: PropTypes.func,
		connectDragSource: PropTypes.func,
		connectDropTarget: PropTypes.func.isRequired,
		depth: PropTypes.number,
		isFixed: PropTypes.bool,
		isSchedule: PropTypes.bool,
	}

	static defaultProps = {
		connectDragSource: elem => elem,
	}

	constructor(props) {
		super(props)

		this.state = {
			isActive: props.isSchedule ? false : true,
		}
	}

	// 접기 설정
	toggleFold = () =>
		this.setState(prevState => ({
			isActive: !prevState.isActive,
		}))

	// 필드 수정 Modal 열기
	handleOpenEditModal = params => {
		const { name, onOpenTypeModal, onUpdateMedInfo } = this.props
		onOpenTypeModal({ title: name, onSubmit: onUpdateMedInfo, ...params })
	}

	// 필드 추가 Modal 열기
	handleOpenAddModal = e => {
		e.stopPropagation()
		const { onOpenTypeModal, name, onCreateMedInfo } = this.props
		onOpenTypeModal({ title: name, onSubmit: onCreateMedInfo })
	}

	render() {
		const { isActive: isActiveState } = this.state
		const {
			depth,
			medInfos,
			sectionIndex,
			medInfoIndex: groupIndex,
			name,
			description,
			isOver,
			connectDropTarget,
			connectDragSource,
			onClickSettingMenu,
			onSearchMedInfoTypes,
			sectionSize,
			onDecideSizeInfo,
			onCreateMedInfo,
			format,
			medInfo,
			disabled: d_,
			size,
			value,
			onResize,
			medInfoId,
			...others
		} = this.props

		const { onOpenTypeModal, onInsertMedInfo, isFixed, templateMode } = others
		const disabled = templateMode ? false : d_

		const isActive = disabled ? false : isActiveState

		const hasScore = format && format.includes(DataFieldFormat.SCORE.key)

		const canEdit = isFixed !== true || templateMode === true

		return (
			<Accordion
				styled
				style={{
					...styles.container,
					...(disabled && {
						opacity: 0.2,
					}),
				}}
				className={isActive ? 'active-acc' : ''}
			>
				<DataFieldDiv medInfoId={medInfo.id}>
					{connectDragSource(
						<div>
							<Accordion.Title
								style={{
									...styles.title,
									...(disabled && {
										color: '#bbb',
									}),
								}}
								active={isActive}
								index={0}
								onClick={!disabled ? this.toggleFold : null}
							>
								<Arrow isOpen={isActive} />
								{name}
								{canEdit === true && (
									<DataFieldEditDropdown
										medInfoId={medInfo.id}
										style={styles.button}
										noCopy
										onResize={onResize}
										onClickMenu={onClickSettingMenu}
									/>
								)}
							</Accordion.Title>
						</div>
					)}
					<Accordion.Content
						active={isActive}
						style={{
							background: isOver && 'rgba(22,83,183,0.2)',
							padding: '10px 20px 15px',
						}}
					>
						{(description || hasScore) && (
							<div style={styles.description}>
								<div style={styles.descText}>{description}</div>
								{hasScore && (
									<div style={{ marginTop: 5 }}>
										점수 : <b>{value}</b>
									</div>
								)}
							</div>
						)}
						{connectDropTarget(
							<div style={{ padding: 5 }}>
								{/* // 	<Header style={{ fontWeight: '500' }} color="grey" as="h3" sub>
									// 		필드를 추가해 주세요!
									// 	</Header>
									// ) : ( */}
								<Grid verticalAlign="top" stretched={false}>
									{medInfos.map((medInfo, medInfoIndex) => {
										const { id, value, vasValue, optionValue, medInfoType, checkedItems } = medInfo
										const { size } = medInfoType
										const sizeInfo = onDecideSizeInfo(size, sectionSize)

										return (
											<Grid.Column
												key={medInfoType.id + '-' + medInfoIndex}
												style={{ padding: 5 }}
												{...sizeInfo}
											>
												<DataFieldBox
													disabled={disabled}
													{...others}
													{...medInfoType}
													sectionSize={sectionSize}
													key={medInfoType.id + '-' + medInfoIndex}
													medInfoIndex={medInfoIndex}
													medInfo={medInfo}
													medInfos={medInfo.children}
													groupIndex={groupIndex}
													groupId={medInfoId}
													sectionIndex={sectionIndex}
													parentIndex={groupIndex}
													currentRecord={medInfos}
													value={value}
													vasValue={vasValue}
													optionValue={optionValue}
													medInfoId={id}
													templateMode={templateMode}
													checkedItems={checkedItems}
													onOpenEditModal={this.handleOpenEditModal}
													onCreateMedInfo={onCreateMedInfo}
												/>
											</Grid.Column>
										)
									})}
									{canEdit === true && (
										<DataFieldAddButton
											onCreateMedInfo={onCreateMedInfo}
											onInsertMedInfo={onInsertMedInfo}
											onOpenTypeModal={onOpenTypeModal}
											onSearchMedInfoTypes={onSearchMedInfoTypes}
											name={name}
										/>
									)}
								</Grid>
							</div>
						)}
					</Accordion.Content>
				</DataFieldDiv>
			</Accordion>
		)
	}
}

export default DropTarget(DRAG_ITEM_TYPES.DATA_FIELD, dataGroupTarget, collectTarget)(
	withMedInfos(DataFieldGroup)
)

const styles = {
	container: {
		textAlign: 'left',
		width: '100%',
		marginBottom: 20,
		marginTop: 10,
		borderRadius: 2,
		// boxShadow: 'none',
		// margin: -10
	},
	btn: {
		float: 'right',
		marginTop: -4,
	},
	button: {
		position: 'absolute',
		right: 10,
		top: 15,
	},
	title: {
		position: 'relative',
		borderTop: 'none',
		fontWeight: 400,
		fontSize: 16,
		padding: '15px 20px',
	},
	description: {
		fontSize: 15,
		marginTop: 5,
		marginLeft: 10,
		marginBottom: 25,
		whiteSpace: 'pre-line',
	},
	descText: {
		color: COLOR_PRIMARY,
		letterSpacing: '0.5px',
		lineHeight: '1.3em',
	},
}
