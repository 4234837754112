import React from 'react'
import { Table } from 'semantic-ui-react'

const TermContact = () => {
	return (
		<div style={{ padding: 30 }}>
			<div>개인정보를 이용하는 목적은 다음과 같습니다.</div>
			<Table textAlign="center" celled fluid unstackable>
				<Table.Header textAlign="center">
					<Table.Row>
						<Table.HeaderCell>수집 목적</Table.HeaderCell>
						<Table.HeaderCell>수집항목</Table.HeaderCell>
						<Table.HeaderCell>보유/이용기간</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					<Table.Row>
						<Table.Cell>문의응대</Table.Cell>
						<Table.Cell>이름, 연락처, 이메일, 소속기관, 직위, 문의내용</Table.Cell>
						<Table.Cell>문의응대 종료 후 3년</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>

			<div>
				동의를 거부할 권리가 있으며, 동의 거부 시 서비스 이용관련 문의에 응답을 받으실 수 없습니다.
			</div>
		</div>
	)
}

export default TermContact
