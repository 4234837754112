import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'semantic-ui-react'
import PlusIcon from '../../../assets/images/icons/plus-12.png'

const AddButton = props => {
	const { className, onClick, title, size, style } = props
	return (
		<Button className={className} style={style} size={size} basic onClick={onClick}>
			<AddButtonImage src={PlusIcon} />
			<Text>{title}</Text>
		</Button>
	)
}

AddButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	title: PropTypes.string,
	size: PropTypes.string,
	className: PropTypes.string,
	style: PropTypes.object,
}

AddButton.defaultProps = {
	title: '',
	size: null,
}

export default AddButton

const AddButtonImage = styled.img`
	width: 20px;
	height: 20px;
	padding: 4px;
	vertical-align: middle;
`

const Text = styled.span`
	vertical-align: middle;
`
