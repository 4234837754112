import React from 'react'
import { Link } from 'react-router-dom'
import { Icon, Header, Segment } from 'semantic-ui-react'

const PageNotFoundView = () => {
  return (
    <div>
      <div style={{ margin: 'auto', marginTop: 50, textAlign: 'center', maxWidth: 700 }}>
        <Header as="h1">
          <Icon name="warning sign" /> 해당 페이지를 찾을 수 없습니다.
        </Header>
        <Segment style={{ textAlign: 'left', padding: 30, marginTop: 50, lineHeight: 1.3 }}>
          <Header as="h2">
            접근하시려는 페이지가 존재하지 않습니다.
            <br />
            존재하지 않는 주소이거나, 업데이트로 인해 변경되었을 수 있습니다.
            <br /> <br />
            <Link to="/">홈 화면 바로가기</Link>
          </Header>
        </Segment>
      </div>
    </div>
  )
}

export default PageNotFoundView
