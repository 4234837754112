import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'
import { useMutation } from 'react-apollo'

import PatientSurveyQuestion from './PatientSurveyQuestion'
import NextButton from './NextButton'
import PrevButton from './PrevButton'
import { findEmptyMedInfo, disassembleMedInfos } from '../../libs/medInfoHelper'
import { UPDATE_MED_INFO } from '../../graphql/mutations/medInfo'

/**
 * 환자 설문 질문 페이지 컴퍼넌트
 */
const PatientSurveyPage = ({ medInfo, toNext, toPrev }) => {
	const { medInfoType } = medInfo
	const { name } = medInfoType
	const hasNullValue = useMemo(() => {
		return findEmptyMedInfo([medInfo])
	}, [medInfo])

	const [updateMedInfo] = useMutation(UPDATE_MED_INFO)

	const onUpdateMedInfo = () => {
		const disassembled = disassembleMedInfos(medInfo)
		disassembled.map(md => {
			const value =
				typeof md.value === 'string'
					? md.value
					: typeof md.value === 'number'
					? md.value.toString()
					: null

			updateMedInfo({
				variables: {
					id: md.id,
					value: value,
					optionValue: md.optionValue,
					checkedItems: md.checkedItems
						? md.checkedItems.map(ov => ({
								value: ov.value,
								freeWriting: ov.freeWriting,
						  }))
						: null,
					vasValue: md.vasValue,
				},
			})
		})
	}

	return (
		<Container>
			<Title>
				<TitleText>{name}</TitleText>
			</Title>
			<PatientSurveyQuestion medInfo={medInfo} />
			<ButtonWrapper>
				<PrevButton onClick={() => toPrev()}>
					<Icon name="angle left" size="large" />
				</PrevButton>
				<NextButton
					onClick={
						hasNullValue === true
							? () => {}
							: () => {
									toNext()
									onUpdateMedInfo()
							  }
					}
					flex={1}
					readOnly={hasNullValue}
				>
					다음
				</NextButton>
			</ButtonWrapper>
		</Container>
		// <Container>
		// 	<Title>
		// 		<TitleText>{name}</TitleText>
		// 		<DescriptionText>아래로 내려가며 설문을 진행해 주세요.</DescriptionText>
		// 	</Title>

		// 	{description && (
		// 		<PatientSurveyDescription>
		// 			<ReactMarkdown>{description}</ReactMarkdown>
		// 		</PatientSurveyDescription>
		// 	)}
		// 	<PatientSurveyQuestionList>
		// 		{filteredMedInfos.map((medInfo, idx) => {
		// 			let Comp
		// 			if (medInfo.medInfoType.inputType === InputType.BLOCK.value) {
		// 				Comp = PatientSurveyBlock
		// 			} else {
		// 				Comp = PatientSurveyQuestion
		// 			}

		// 			return (
		// 				<Comp
		// 					isActive={isActive}
		// 					key={medInfo.id}
		// 					medInfoId={medInfo.id}
		// 					{...medInfo}
		// 					medInfos={medInfo.children}
		// 					index={idx}
		// 				/>
		// 			)
		// 		})}
		// 	</PatientSurveyQuestionList>
		// 	<AlignCenteredFlexDiv>
		// 		<NextButton readOnly={hasNullValue} onClick={hasNullValue == true ? () => {} : toNext}>
		// 			{isLastPage === true ? '마침' : '다음'}
		// 		</NextButton>
		// 	</AlignCenteredFlexDiv>
		// </Container>
	)
}

PatientSurveyPage.propTypes = {
	toNext: PropTypes.func,
	toPrev: PropTypes.func,
	medInfo: PropTypes.object,
}

export default PatientSurveyPage

/**
 * Styles
 */

const Container = styled.div`
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	align-items: center;
	padding: 0px 10%;
	padding-top: 10vh;
	padding-bottom: 10%;
	height: 100%;
	overflow-y: auto;

	&::-webkit-scrollbar {
		display: none;
	}
`

const Title = styled.div`
	margin-top: 5vw;
	padding: 0px 20px;
`

const TitleText = styled.h2`
	word-break: all;
	font-size: 48px;
	font-weight: 800;
	font-size: 6vmin;
	/* text-align: center; */
`

const ButtonWrapper = styled.div`
	display: flex;
	width: 100%;
`
