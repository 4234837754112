/**
 * [HOC] 차트 입력기 Higer order component
 * 차트 입력, 수정, 저장 등 필요할 경우 사용.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Form, Icon, Segment, List, Button, Modal, Input, Loader, Dimmer } from 'semantic-ui-react'
import 'moment/locale/ko'

import withChart from './withChart'

import { TemplateType, TemplateJob, ConditionAction, ResearchSubjectStatus } from '../enum'
import { compareValues, findMedInfoTree, checkMedInfoHasEmptyValues } from '../libs/medInfoHelper'
import NextScheduleModal from '../components/charting/modals/NextScheduleModal'
import PrintChartModal from '../components/charting/modals/PrintChartModal'
import { getAgeRange, convertGender } from '../libs/valueHelper'

const styles = {
	nullCell: {
		display: 'inline-block',
		verticalAlign: 'top',
		marginRight: 10,
		marginLeft: 10,
	},
	nullText: {
		display: 'inline-block',
		verticalAlign: 'top',
		marginLeft: 10,
	},
	recBtn: {
		float: 'right',
		marginTop: -4,
		verticalAlign: 'top',
	},
	recBtnCirc: {
		float: 'right',
		marginTop: -4,
		verticalAlign: 'top',
		fontSize: '1rem',
		boxShadow: 'none',
	},
	recordInfo: {
		fontWeight: '600',
		marginRight: 20,
		color: '#333',
		fontSize: 16,
	},
}

export default WrappedComponent => {
	class withChartEditor extends React.PureComponent {
		static propTypes = {
			onSaveChart: PropTypes.func,
			noEdit: PropTypes.bool,
			template: PropTypes.object,
			conditions: PropTypes.array,
			medInfos: PropTypes.array,
			reservation: PropTypes.object,
			nextSchedule: PropTypes.object,
			visitedDate: PropTypes.string,
			patient: PropTypes.objectOf({
				patientResearches: PropTypes.array,
			}).isRequired,
		}

		saving = false
		isScreening

		constructor(props) {
			super(props)

			this.state = {
				isScreenModalOpen: false,
				screeningReasons: [],
				medInfoCategories: [],
				newInputIndex: -1,
				saveLoading: false,
				loading: true,
				hasNullValues: false,
				readOnly: props.noEdit ? true : false,
				isReserveModalOpen: false,
				isScreeningOK: false,
				screeningFailReason: '',
				isPrintModalOpen: false,
				printingSectionId: null,
			}

			this.isScreening = props.template && props.template.job === TemplateJob.SCREENING.key
		}

		handleChange = key => (e, { value }) => {
			this.setState({
				[key]: value,
			})
		}

		handleOpenScreenModal = () => this.setState({ isScreenModalOpen: true })

		handleCloseScreenModal = () => this.setState({ isScreenModalOpen: false })

		handleOpenReserveModal = () => this.setState({ isReserveModalOpen: true })

		handleCloseReserveModal = () => {
			this.setState({ isReserveModalOpen: false })
		}

		handleOpenPrintModal = printingSectionId =>
			this.setState({ isPrintModalOpen: true, printingSectionId })

		handleClosePrintModal = () => this.setState({ isPrintModalOpen: false })

		// 스크리닝 컨디션 체크
		handleSubmitScreening = async () => {
			const { conditions, medInfos } = this.props

			//빈 값 확인
			const nulls = checkMedInfoHasEmptyValues(medInfos)
			if (nulls.length > 0) {
				return this.setState({
					screeningReasons: nulls,
					isScreeningOK: false,
					isScreenModalOpen: true,
					hasNullValues: true,
				})
			}

			const reasons = []

			//스크리닝 컨디션 확인
			for (const condition of conditions) {
				if (condition.action === ConditionAction.SCREEN_FAIL.key) {
					const medInfos_ = findMedInfoTree(
						medInfos,
						item => item.medInfoType.id === condition.subjectId
					)
					if (medInfos_[0] && medInfos_[0].disabled !== true) {
						const valid = compareValues(medInfos_[0].value, condition.value, condition.operator)

						if (valid) {
							reasons.push({
								text: medInfos_
									.reverse()
									.map(item => item.medInfoType.name)
									.join('  >  '),
								id: medInfos_[0].id,
							})
						}
					}
				}
			}

			if (reasons.length > 0) {
				this.setState({
					isScreenModalOpen: true,
					screeningReasons: reasons,
					hasNullValues: false,
					isScreeningOK: false,
				})
			} else {
				this.setState({
					isScreenModalOpen: true,
					screeningReasons: null,
					hasNullValues: false,
					isScreeningOK: true,
				})
			}
		}

		handleSaveChart = async params => {
			if (this.saving === true) return

			this.saving = true
			this.setState({
				saveLoading: true,
			})
			const { onSaveChart } = this.props

			await onSaveChart(params)

			this.setState({
				saveLoading: false,
			})
			this.saving = false
		}

		handleFailScreening = () => {
			const { screeningFailReason } = this.state
			this.handleSaveChart({ screeningValid: false, screeningFailReason })
			this.setState({
				isScreenModalOpen: false,
			})
		}

		handleSuccessScreening = () => {
			this.handleSaveChart({ screeningValid: true })
			this.setState({
				isScreenModalOpen: false,
			})
		}

		handleSubmit = async () => {
			const { readOnly } = this.state
			const {
				template,
				patient: { patientResearches },
			} = this.props
			if (readOnly === true) {
				this.toggleEditMode()
			} else if (this.isScreening === true) {
				this.handleSubmitScreening({ onFinish: this.handleCloseScreenModal })
			} else {
				const isDropped =
					patientResearches != null && patientResearches.length > 0
						? patientResearches[0].status === ResearchSubjectStatus.DROPPED.value
						: false
				if (template && template.shouldReserve != null && isDropped !== true) {
					this.handleOpenReserveModal()
				} else {
					await this.handleSaveChart({ onFinish: this.handleCloseReserveModal })
				}
			}
		}

		handleSubmitReservation = async reserveDate => {
			if (!reserveDate) {
				return alert('날짜를 설정해 주세요.')
			} else {
				this.handleSaveChart({ nextDate: reserveDate, onFinish: this.handleCloseReserveModal })
			}
		}

		handleRenderNulls = nullFields => {
			return nullFields.map(nf => {
				if (nf.nullFields) {
					return (
						<div key={nf.text}>
							{'>'}{' '}
							<span style={styles.nullText}>
								{nf.text.length > 15 ? nf.text.slice(0, 15) + '..' : nf.text}
							</span>
							<div style={styles.nullCell}>{this.handleRenderNulls(nf.nullFields)}</div>
						</div>
					)
				}
			})
		}

		toggleEditMode = () => {
			this.setState(prevState => ({
				readOnly: !prevState.readOnly,
			}))
		}

		render() {
			const {
				isScreeningOK,
				isReserveModalOpen,
				isScreenModalOpen,
				isPrintModalOpen,
				hasNullValues,
				printingSectionId,
				saveLoading,
				screeningReasons,
				screeningFailReason,
			} = this.state
			const {
				template,
				nextSchedule,
				medInfos,
				patient,
				creator,
				institution,
				visitedDate,
				researches,
			} = this.props

			const isFixed = template && template.type === TemplateType.FIXED.value
			const isScreening = this.isScreening

			const { patientResearches } = patient
			const patientResearch =
				patientResearches != null && patientResearches.length > 0 ? patientResearches[0] : null

			return (
				<React.Fragment>
					<WrappedComponent
						onSubmit={this.handleSubmit}
						onToggleEditMode={this.toggleEditMode}
						onPrint={this.handleOpenPrintModal}
						isScreening={this.isScreening}
						isFixed={isFixed}
						medInfos={medInfos}
						{...this.props}
						{...this.state}
					/>
					{saveLoading === true && (
						<Dimmer>
							<Loader />
						</Dimmer>
					)}
					{isScreening && (
						<Modal open={isScreenModalOpen} onClose={this.handleCloseScreenModal}>
							<Modal.Header>
								{
									<Icon
										color={isScreeningOK ? 'green' : 'red'}
										name={isScreeningOK ? 'sign-in alternate' : 'exclamation circle'}
									/>
								}
								{hasNullValues
									? '다음 값을 모두 입력해주세요'
									: `스크리닝 결과: ${isScreeningOK ? '적합' : '부적합'}`}
							</Modal.Header>
							<Modal.Content>
								{!isScreeningOK ? (
									<div>
										{!hasNullValues && (
											<div>
												연구 등록에 <b style={{ color: '#d33' }}>적합하지 않은</b> 환자입니다. 다음
												항목을 참조해 주세요. <br />
											</div>
										)}
										<Segment>
											<List relaxed="very" bulleted>
												{hasNullValues
													? screeningReasons.map(item => (
															<List.Item
																key={item.id}
																style={{ fontWeight: '600', whiteSpace: 'pre-wrap' }}
															>
																<div style={styles.nullCell}>{item.text}</div>
																<div style={styles.nullCell}>
																	{this.handleRenderNulls(item.nullFields)}
																</div>
															</List.Item>
													  ))
													: screeningReasons.map(item => (
															<List.Item
																key={item.id}
																style={{ fontWeight: '600', whiteSpace: 'pre-wrap' }}
															>
																{item.text}
															</List.Item>
													  ))}
											</List>
										</Segment>
										{!hasNullValues ? (
											<div>
												잘못된 값이 있을 경우 취소 후 수정하시고, 아닐 경우 아래의 &rdquo;결과
												저장&rdquo; 버튼을 눌러주세요.
												<br />위 항목 이외의 다른 부적합 사유가 있을 경우 대해 아래 칸에 간단히
												기재해 주세요.
											</div>
										) : (
											<div>위 값을 모두 정확히 입력하신 후 진행해 주세요.</div>
										)}
										{!isScreeningOK && !hasNullValues && (
											<Form style={{ marginTop: 20 }}>
												<Form.Field
													control={Input}
													placeholder="부적합 사유"
													value={screeningFailReason}
													onChange={this.handleChange('screeningFailReason')}
												/>
											</Form>
										)}
									</div>
								) : (
									<div>
										연구등록에 적합한 환자입니다. &rdquo;저장 및 진행&rdquo; 버튼을 눌러 다음 과정을
										진행해 주세요.
									</div>
								)}
							</Modal.Content>

							{!hasNullValues && (
								<Modal.Actions>
									<Button onClick={this.handleCloseScreenModal}>취소</Button>
									{isScreeningOK ? (
										<Button color="green" onClick={this.handleSuccessScreening}>
											저장 및 진행
										</Button>
									) : (
										<Button color="red" onClick={this.handleFailScreening}>
											결과 저장
										</Button>
									)}
								</Modal.Actions>
							)}
						</Modal>
					)}
					{nextSchedule && (
						<NextScheduleModal
							open={isReserveModalOpen}
							onClose={this.handleCloseReserveModal}
							onSubmit={this.handleSubmitReservation}
							nextSchedule={nextSchedule}
							currentSchedule={template}
							loading={saveLoading}
							initialVisitDate={patientResearch != null && patientResearch.initialVisitDate}
						/>
					)}

					{isPrintModalOpen === true && (
						<PrintChartModal
							isOpen={isPrintModalOpen}
							onClose={this.handleClosePrintModal}
							medInfos={medInfos}
							templateTitle={template && template.title}
							researchTitle={researches && researches[0] && researches[0].title}
							patientCode={patient && patient.patientCode}
							patientName={patient && patient.name}
							patientAge={patient && getAgeRange(patient.birthDate)}
							visitedDate={visitedDate}
							patientGender={patient && convertGender(patient.sexMale)}
							institutionName={institution && institution.name}
							writerName={creator && creator.name}
							hasPatientCode
							printingSectionId={printingSectionId}
						/>
					)}
				</React.Fragment>
			)
		}
	}

	return withChart(withChartEditor)
}
