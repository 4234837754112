import React from 'react'
import { Table } from 'semantic-ui-react'

/**
 * 데이터 테이블 헤더
 */
const TableHeader = ({ hasEllipsis, columns, sortBy, sortDirection, onSort }) => {
	return (
		<Table.Header>
			<Table.Row>
				{columns.map(col => {
					const { text, uiOptions, key, value } = col

					return (
						<Table.HeaderCell
							key={key}
							{...uiOptions}
							sorted={sortBy === value ? sortDirection : null}
							onClick={onSort(value)}
							textAlign="center"
						>
							{text}
						</Table.HeaderCell>
					)
				})}
				{hasEllipsis === true && <Table.HeaderCell style={{ width: 30 }} />}
			</Table.Row>
		</Table.Header>
	)
}

export default TableHeader
