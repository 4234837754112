import {
	ROUTE_MY_PATIENTS,
	ROUTE_MY_PROJECTS,
	ROUTE_MY_STATISTICS,
	ROUTE_TERMS_PRIVACY,
	ROUTE_CONTACT,
	ROUTE_FAQ,
	// ROUTE_MY_SETTINGS,
	ROUTE_PROJECT_DETAIL_OVERVIEW,
	ROUTE_PROJECT_DETAIL_CALENDAR,
	ROUTE_PROJECT_DETAIL_TEMPLATES_DEFAULT,
	ROUTE_PROJECT_DETAIL_PATIENTS_DEFAULT,
	ROUTE_PROJECT_DETAIL_DATA_DEFAULT,
	ROUTE_PROJECT_DETAIL_SETTINGS_DEFAULT,
	ROUTE_PROJECT_DETAIL_STATS_DEFAULT,
	ROUTE_PROJECT_DETAIL_ISSUES_DEFAULT,
	ROUTE_PROJECT_DETAIL_DOCTORS_DEFAULT,
	ROUTE_TERMS_DEFAULT,
} from './routes'
import { URL_COMPANY } from './urls'
import { ResearchType } from '../enum'

export const HEADER_MENU = Object.freeze([
	{
		key: 'MY_PATIENTS',
		to: ROUTE_MY_PATIENTS,
		title: '내 데이터',
		imageIcon: 'menu_main_patient.svg',
		activeImageIcon: 'menu_main_patient_active.svg',
	},
	{
		key: 'MY_STATISTICS',
		to: ROUTE_MY_STATISTICS,
		title: '통계',
		imageIcon: 'menu_main_stat.svg',
		activeImageIcon: 'menu_main_stat_active.svg',
	},
	{
		key: 'MY_PROJECTS',
		to: ROUTE_MY_PROJECTS,
		title: '프로젝트',
		imageIcon: 'menu_main_projects.svg',
		activeImageIcon: 'menu_main_projects_active.svg',
	},
	// {
	// 	key: 'SETTINGS',
	// 	to: ROUTE_MY_SETTINGS,
	// 	title: '차팅 설정',
	// 	imageIcon: 'menu_main_settings.svg',
	// 	activeImageIcon: 'menu_main_settings.svg',
	// },
])

export const HEADER_MENU_RESEARCHER = Object.freeze([
	{
		key: 'MY_PROJECTS',
		to: ROUTE_MY_PROJECTS,
		title: '내 프로젝트',
		imageIcon: 'round-folder-24-px.svg',
	},
])

export const RESEARCH_SUB_MENU = Object.freeze([
	{
		key: 'OVERVIEW',
		value: null,
		to: ROUTE_PROJECT_DETAIL_OVERVIEW,
		title: '개요',
		exact: true,
		imageIcon: 'intro.png',
	},
	{
		key: 'STATISTICS',
		value: 'statistics',
		to: ROUTE_PROJECT_DETAIL_STATS_DEFAULT,
		title: '통계',
		exact: false,
		imageIcon: 'stats.png',
	},
	{
		key: 'TEMPLATES',
		value: 'templates',
		to: ROUTE_PROJECT_DETAIL_TEMPLATES_DEFAULT,
		title: '입력 양식',
		requires: 'canFixTemplate',
		exact: false,
		imageIcon: 'template.png',
	},
	{
		key: 'PATIENTS',
		value: 'subjects',
		to: ROUTE_PROJECT_DETAIL_PATIENTS_DEFAULT,
		title: '연구대상자',
		numberKey: 'patientCount',
		requires: 'canAccess',
		exact: false,
		imageIcon: 'subject.png',
	},
	{
		key: 'DATA',
		value: 'data',
		to: ROUTE_PROJECT_DETAIL_DATA_DEFAULT,
		title: '데이터',
		numberKey: 'recordCount',
		requires: 'canAccess',
		exact: false,
		imageIcon: 'data.png',
	},
	{
		key: 'ISSUES',
		value: 'issues',
		to: ROUTE_PROJECT_DETAIL_ISSUES_DEFAULT,
		title: '게시판',
		numberKey: 'issueCount',
		exact: false,
		imageIcon: 'issues.png',
	},
	{
		key: 'DOCTORS',
		value: 'doctors',
		to: ROUTE_PROJECT_DETAIL_DOCTORS_DEFAULT,
		title: '참여 의료인',
		numberKey: 'doctorCount',
		exact: false,
		imageIcon: 'doctors.png',
	},
	{
		key: 'CALENDAR',
		value: 'calendar',
		to: ROUTE_PROJECT_DETAIL_CALENDAR,
		title: '일정',
		requires: 'canAccess',
		exact: false,
		imageIcon: 'calendar.png',
		requiresType: ResearchType.SCHEDULED.value,
	},
	{
		key: 'SETTINGS',
		value: 'settings',
		to: ROUTE_PROJECT_DETAIL_SETTINGS_DEFAULT,
		title: '설정',
		requires: 'canChangeSettings',
		exact: false,
		imageIcon: 'settings.png',
	},
])

export const FOOTER_MENU_1 = Object.freeze([
	{
		key: 'COMPANY_INFO',
		href: URL_COMPANY,
		title: '회사 소개',
	},
	{
		key: 'TERMS',
		to: ROUTE_TERMS_DEFAULT,
		title: '이용약관',
	},
	{
		key: 'TERMS_PRIVACY',
		to: ROUTE_TERMS_PRIVACY,
		title: '개인정보 보호정책',
	},
	// {
	// 	key: 'DATA_DRIVEN_MEDICINE',
	// 	to: ROUTE_INTRO_DDM,
	// 	title: '데이터 기반 의학',
	// },
])

export const FOOTER_MENU_2 = Object.freeze([
	{
		key: 'CONTACT',
		to: ROUTE_CONTACT,
		title: '문의하기',
	},
	{
		key: 'FAQ',
		to: ROUTE_FAQ,
		title: 'FAQ',
	},
])
