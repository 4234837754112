/**
 * 시스템 내 사용 루트 목록
 */

/**
 * 기본 루트
 */
export const ROUTE_HOME = '/'
export const ROUTE_ABOUT = '/about'

export const ROUTE_LOGIN = '/login'
export const ROUTE_LOGIN_REDIRECT = '/login?redirect='
export const ROUTE_SIGNUP = '/login/signup'
export const ROUTE_LOGIN_FORGOT = '/forgot-account/:menu'
export const ROUTE_LOGIN_FORGOT_PASSWORD = '/forgot-account/password'
export const ROUTE_LOGIN_FORGOT_ACCOUNT = '/forgot-account/username'
export const ROUTE_EMAIL_VERIFICATION = '/accounts/verification/:token'

export const ROUTE_MY_PATIENTS = '/patients'
export const ROUTE_MY_STATISTICS = '/statistics'
export const ROUTE_MY_PROJECTS = '/projects'
export const ROUTE_MY_SETTINGS = '/settings'

export const ROUTE_MY_PROFILE = '/profile/:menu?'
export const ROUTE_MY_PROFILE_DEFAULT = '/profile'

export const ROUTE_TERMS_DEFAULT = '/terms'
export const ROUTE_TERMS = '/terms/:menu?'
export const ROUTE_TERMS_PRIVACY = '/terms/privacy'
export const ROUTE_INTRO_DDM = '/data-driven-medicine'

export const ROUTE_COMPANY = '/company'
export const ROUTE_CONTACT = '/contact'
export const ROUTE_CONTACT_DOC = '/contact-doc'
export const ROUTE_FAQ = '/faq'

export const ROUTE_BLOG = '/blog'
export const ROUTE_TEST = '/test'

export const ROUTE_SURVEY = '/survey/:code'

/**
 * 케이스 공유 관련 루트
 */
export const ROUTE_CASES = '/cases'
export const ROUTE_CASES_WRITE = '/cases/write'
export const ROUTE_CASES_UPDATE = '/cases/write/:caseId'
export const ROUTE_CASE_DETAIL = '/cases/:caseId'
export const ROUTE_CASE_USER = '/cases/users/:userId'
export const ROUTE_CASE_BOOKS = '/cases/books'
export const ROUTE_CASE_GROUP = '/cases/groups/:groupId'
export const ROUTE_CASE_TAG = '/cases/tags/:tagId'
export const ROUTE_CASE_SEARCH = '/cases/search'
export const ROUTE_CASE_SEARCH_TYPE = '/cases/search/:searchType'

/**
 * 환자 상세 페이지 관련 루트
 */

export const ROUTE_PATIENT_DETAIL = '/patients/:id/:menu?/:subId?'
export const ROUTE_PATIENT_DETAIL_DEFAULT = '/patients/:id'
export const ROUTE_PATIENT_DETAIL_STATS = '/patients/:id/statistics/:menu?'
export const ROUTE_PATIENT_DETAIL_STATS_DEFAULT = '/patients/:id/statistics'
export const ROUTE_PATIENT_DETAIL_SETTINGS = '/patients/:id/settings/:menu?'
export const ROUTE_PATIENT_DETAIL_SETTINGS_DEFAULT = '/patients/:id/settings'
export const ROUTE_PATIENT_DETAIL_RECORD = '/patients/:id/records/:subId'
export const ROUTE_PATIENT_DETAIL_RECORD_CHANGES = '/patients/:id/records/:subId/changes'
export const ROUTE_PATIENT_DETAIL_RECORD_CHANGE_DETAIL =
	'/patients/:id/records/:subId/changes/:changeId'

/**
 * 연구 관련 루트
 */

export const ROUTE_PROJECTS = '/projects/:id?/:menu?/:subMenu?/:subsubMenu?/:subsubsubMenu?'

export const ROUTE_NEW_PROJECT = '/projects/new'

export const ROUTE_PROJECT_DETAIL = '/projects/:id/:menu?'
export const ROUTE_PROJECT_DETAIL_DEFAULT = '/projects/:id'
export const ROUTE_PROJECT_DETAIL_OVERVIEW = '/projects/:id'
export const ROUTE_PROJECT_DETAIL_OVERVIEW_EDIT = '/projects/:id/overview'

export const ROUTE_PROJECT_DETAIL_STATS = '/projects/:id/statistics/:menu?'
export const ROUTE_PROJECT_DETAIL_STATS_DEFAULT = '/projects/:id/statistics'
export const ROUTE_PROJECT_DETAIL_STATS_SUBJECTS = '/projects/:id/statistics/subjects'
export const ROUTE_PROJECT_DETAIL_STATS_RELATIONS = '/projects/:id/statistics/relations'

export const ROUTE_PROJECT_DETAIL_DATA = '/projects/:id/data/:subId?'
export const ROUTE_PROJECT_DETAIL_DATA_DETAIL =
	'/projects/:id/subjects/:subId/records/:recordId/:recordMenu?'
export const ROUTE_PROJECT_DETAIL_DATA_DETAIL_DEFAULT =
	'/projects/:id/subjects/:subId/records/:recordId'
export const ROUTE_PROJECT_DETAIL_DATA_HISTORY =
	'/projects/:id/subjects/:subId/records/:recordId/history/'
export const ROUTE_PROJECT_DETAIL_DATA_HISTORY_DETAIL =
	'/projects/:id/subjects/:subId/records/:recordId/history/:historyId'
export const ROUTE_PROJECT_DETAIL_DATA_DETAIL_UPDATE =
	'/projects/:id/subjects/:subId/records/:recordId/update-template'
export const ROUTE_PROJECT_DETAIL_DATA_DEFAULT = '/projects/:id/data'

export const ROUTE_PROJECT_DETAIL_TEMPLATES = '/projects/:id/templates/:menu?'
export const ROUTE_PROJECT_DETAIL_TEMPLATES_DEFAULT = '/projects/:id/templates'
export const ROUTE_PROJECT_DETAIL_TEMPLATES_FORMS_DEFAULT = '/projects/:id/templates/forms'
export const ROUTE_PROJECT_DETAIL_TEMPLATES_CONDITIONS_DEFAULT =
	'/projects/:id/templates/conditions'
export const ROUTE_PROJECT_DETAIL_TEMPLATES_LIBRARY_DEFAULT = '/projects/:id/templates/library'
export const ROUTE_PROJECT_DETAIL_TEMPLATES_FIELDS_DEFAULT = '/projects/:id/templates/fields'
export const ROUTE_PROJECT_DETAIL_TEMPLATES_LIBRARY_PRESCRIPTION =
	'/projects/:id/templates/library/prescription'
export const ROUTE_PROJECT_DETAIL_TEMPLATES_LIBRARY_ACUPUNCTURE =
	'/projects/:id/templates/library/acupuncture'

export const ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL = '/projects/:id/templates/:menu/:subId'
export const ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_DEFAULT = '/projects/:id/templates/:menu/:subId'
export const ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_HISTORY =
	'/projects/:id/templates/:menu/:subId/history'
export const ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_CHANGES =
	'/projects/:id/templates/:menu/:subId/changes'
export const ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_CHANGE_DETAIL =
	'/projects/:id/templates/:menu/:subId/changes/:changeId'
export const ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_HISTORY_DETAIL =
	'/projects/:id/templates/:menu/:subId/history/:subsubId'
export const ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_SETTINGS =
	'/projects/:id/templates/:menu/:subId/settings'

export const ROUTE_PROJECT_DETAIL_CALENDAR = '/projects/:id/calendar'

export const ROUTE_PROJECT_DETAIL_SETTINGS = '/projects/:id/settings/:menu?'
export const ROUTE_PROJECT_DETAIL_SETTINGS_DEFAULT = '/projects/:id/settings/'
export const ROUTE_PROJECT_DETAIL_SETTINGS_DETAIL = '/projects/:id/settings/detail'
export const ROUTE_PROJECT_DETAIL_SETTINGS_MEMBERS = '/projects/:id/settings/members'
export const ROUTE_PROJECT_DETAIL_SETTINGS_MEMBERS_DETAIL =
	'/projects/:id/settings/members/:subId/:menu?'
export const ROUTE_PROJECT_DETAIL_SETTINGS_MEMBERS_DETAIL_INST =
	'/projects/:id/settings/members/:subId/institutions'
export const ROUTE_PROJECT_DETAIL_SETTINGS_MEMBERS_DETAIL_DEFAULT =
	'/projects/:id/settings/members/:subId'
export const ROUTE_PROJECT_DETAIL_SETTINGS_SECURITY = '/projects/:id/settings/security'

export const ROUTE_PROJECT_DETAIL_PATIENTS = '/projects/:id/subjects/:subId?'
export const ROUTE_PROJECT_DETAIL_PATIENTS_DEFAULT = '/projects/:id/subjects'
export const ROUTE_PROJECT_DETAIL_PATIENT_DETAIL = '/projects/:id/subjects/:subId'
export const ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_DEFAULT = '/projects/:pid/subjects/:id'
export const ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORDS = '/projects/:pid/subjects/:id/records'
export const ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL =
	'/projects/:pid/subjects/:id/records/:subId'
export const ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL_CHANGES =
	'/projects/:pid/subjects/:id/records/:subId/changes'
export const ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL_CHANGE_DETAIL =
	'/projects/:pid/subjects/:id/records/:subId/changes/:changeId'

export const ROUTE_PROJECT_DETAIL_DOCTORS = '/projects/:id/doctors/:subId?'
export const ROUTE_PROJECT_DETAIL_DOCTORS_DEFAULT = '/projects/:id/doctors'

export const ROUTE_PROJECT_DETAIL_ISSUES = '/projects/:id/issues/:subId?'
export const ROUTE_PROJECT_DETAIL_ISSUES_DEFAULT = '/projects/:id/issues'
export const ROUTE_PROJECT_DETAIL_ISSUE_DETAIL = '/projects/:id/issues/:subId'
export const ROUTE_PROJECT_DETAIL_ISSUES_WRITE = '/projects/:id/issues/write'

/**
 * 통계 관련
 */

export const ROUTE_STATISTICS = '/statistics/:menu?'
export const ROUTE_STATISTICS_ANALYZE = '/statistics/analyze'

/**
 * 환자 설문 전용 페이지
 */

export const ROUTE_PATIENT_ONLY_SURVEY = '/patient-survey'

/**
 * 프레임 배치용 배열
 */
export const ROUTES_SIDE_MENU = [
	ROUTE_MY_PATIENTS,
	ROUTE_MY_STATISTICS,
	ROUTE_MY_PROJECTS,
	ROUTE_MY_SETTINGS,
]

export const ROUTES_SUB_SIDE_MENU = [
	ROUTE_PATIENT_DETAIL,
	ROUTE_MY_STATISTICS,
	ROUTE_MY_PROJECTS,
	ROUTE_PROJECT_DETAIL,
]

export const ROUTES_NO_HEADER = []

export const ROUTES_NO_FOOTER = [
	ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL,
	ROUTE_PATIENT_DETAIL,
	...ROUTES_SIDE_MENU,
]

export const ROUTES_FULL_WIDTH_CONTENT = [
	ROUTE_PATIENT_DETAIL_RECORD,
	ROUTE_PROJECT_DETAIL_DATA_DETAIL,
	ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL,
]

export const ROUTES_AUTH_REQUIRED = [ROUTE_MY_PATIENTS, ROUTE_MY_STATISTICS, ROUTE_MY_SETTINGS]

export const ROUTES_NO_HEADER_MENU = [ROUTE_LOGIN, ROUTE_SIGNUP, ROUTE_LOGIN_FORGOT]

export const ROUTES_HEADER_SIDE_CONTENT = [
	ROUTE_PATIENT_DETAIL,
	ROUTE_PROJECT_DETAIL_DATA_DETAIL,
	ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL,
	ROUTE_PROJECT_DETAIL_PATIENT_DETAIL,
]
