import gql from 'graphql-tag'
import {
	MED_INFO_TYPE_FRAGMENT,
	MED_INFO_QUERY_FRAGMENT,
	LOCAL_MED_INFO,
} from '../fragments/medInfo'
import { COMMENT_NAME_FRAGMENT } from '../fragments/community'
import { RECORD_MED_INFOS } from '../fragments'

export const SEARCH_MED_INFO_TYPES = gql`
	query searchMedInfoTypes($researchId: Int, $searchString: String) {
		searchMedInfoTypes(searchString: $searchString, researchId: $researchId) {
			id
			name
			typeName
			inputType
		}
	}
`

// export const GET_MED_INFO_TYPE = gql`
export const GET_PUBLIC_MED_INFO_TYPE = gql`
	query getField($medInfoTypeId: Int!) {
		field(medInfoTypeId: $medInfoTypeId) {
			medInfoType {
				...MedInfoTypeParts
			}
			medInfo {
				...RecordMedInfoParts
				children {
					...RecordMedInfoParts
				}
			}
		}
	}
	${RECORD_MED_INFOS}
	${MED_INFO_TYPE_FRAGMENT}
`

export const GET_TEMPLATE_FIELDS = gql`
	query getTemplateFields($templateId: Int!) {
		templateMedInfoTypes(templateId: $templateId) {
			...MedInfoTypeParts
		}
	}

	${MED_INFO_TYPE_FRAGMENT}
`

export const GET_MED_INFO_QUERY = gql`
	query medInfoQueries($medInfoId: Int!, $pagination: MedInfoQueryPagination!) {
		medInfoQueries(medInfoId: $medInfoId, pagination: $pagination) {
			totalCount
			page
			perPage
			items {
				...MedInfoQueryParts
				comments {
					...CommentNameParts
				}
			}
		}
	}
	${MED_INFO_QUERY_FRAGMENT}
	${COMMENT_NAME_FRAGMENT}
`

export const GET_MED_INFO_QUERY_COUNTS = gql`
	query getMedInfoQueryCounts($medInfoId: Int!) {
		medInfo(medInfoId: $medInfoId) {
			id
			medInfoQueryCount
			unresolvedMedInfoQueryCount
		}
	}
`

export const GET_LOCAL_MED_INFO = gql`
	query($id: Int!) {
		localMedInfo(id: $id) @client {
			...LocalMedInfoParts
		}
	}

	${LOCAL_MED_INFO}
`
