import React, { PureComponent } from 'react'
import { Modal, Button } from 'semantic-ui-react'

/**
 * [HOC] 확인 모달 컨트롤 hoc
 */
export default WrappedComponent => {
	class hocComponent extends PureComponent {
		state = {
			isOpen: false,
			message: '저장되었습니다.',
			buttonText: '확인',
			onSubmit: () => {},
		}

		handleOpenDialoge = ({ onSubmit, message, buttonText }) => {
			this.setState({ isOpen: true, onSubmit, message, buttonText })
		}

		handleCloseDialogue = () => {
			this.setState({ isOpen: false })
		}

		render() {
			const { isOpen, onSubmit, message, buttonText } = this.state
			return (
				<>
					<WrappedComponent
						{...this.props}
						onOpenDialogue={this.handleOpenDialogue}
						onCloseDialogue={this.handleCloseDialogue}
					/>
					<Modal open={isOpen} onClose={this.handleCloseDialogue}>
						<Modal.Content>{message}</Modal.Content>
						<Modal.Actions>
							<Button
								primary
								onClick={() => {
									onSubmit()
									this.handleCloseDialogue()
								}}
							>
								{buttonText}
							</Button>
							<Button primary onClick={this.handleCloseDialogue}>
								취소
							</Button>
						</Modal.Actions>
					</Modal>
				</>
			)
		}
	}

	return hocComponent
}
