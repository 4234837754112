import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Query } from 'react-apollo'
import { Dropdown } from 'semantic-ui-react'
import {
	GET_USER_NOTIFICATIONS,
	GET_USER_UNREAD_NOTIFICATION_COUNT,
} from '../../../graphql/queries/notification'
import LoadingView from '../../common/loader'
import MiniErrorView from '../../common/error/MiniErrorView'
import EmptyResult from '../../common/empty/EmptyResult'
import { UISize } from '../../../enum'
import { ReactComponent as NotifImage } from '../../../assets/images/icons/notification.svg'
import NotificationItem from './NotificationItem'
import { COLOR_VIVID_RED } from '../../../constants/styles'
import ErrorBoundary from '../../common/ErrorBoundary'
import { ExceptMobile, MobileOnly } from '../../common/basic/ResponsiveWrappers'
import MyIcon from '../../common/basic/MyIcon'
import { CloseIconBox } from '../../common/basic/StyledBox'

/**
 * 사용자 알림 목록 Dropdown
 */
const NotificationPopup = () => {
	const { search } = useLocation()
	const [isOpen, setIsOpen] = useState()

	useEffect(() => {
		const { notification } = qs.parse(search)
		setIsOpen(notification === 'open')
	}, [search])

	return (
		<Query query={GET_USER_UNREAD_NOTIFICATION_COUNT} pollInterval={30000}>
			{({ data, refetch: refetchCount }) => {
				let count
				let userId
				if (data != null) {
					;({
						userUnreadNotificationCount: count,
						userInfo: {
							user: { id: userId },
						},
					} = data)
				}

				return (
					<React.Fragment>
						<ErrorBoundary>
							{count > 0 && <UnreadCount>{count}</UnreadCount>}
							<Query
								query={GET_USER_NOTIFICATIONS}
								variables={{
									pagination: {
										perPage: 10,
										page: 1,
									},
								}}
								fetchPolicy="cache-and-network"
							>
								{({ data: data2, error, loading, refetch, fetchMore }) => {
									const { userNotifications: { items = [], totalCount, page } = {} } = data2 || {}
									const shouldLoadMore = totalCount > items.length

									return (
										<>
											<ExceptMobile>
												<Dropdown
													open={isOpen}
													style={{ padding: 3 }}
													on="click"
													direction="left"
													trigger={<NotificationTrigger onClick={() => setIsOpen(prev => !prev)} />}
													icon={null}
													onOpen={() => refetch()}
													onClose={() => setIsOpen(false)}
												>
													<Dropdown.Menu style={{ zIndex: 900 }}>
														<NotificationContent
															totalCount={totalCount}
															items={items}
															shouldLoadMore={shouldLoadMore}
															loading={loading}
															error={error}
															refetch={refetch}
															userId={userId}
															refetchCount={refetchCount}
															fetchMore={fetchMore}
															page={page}
														/>
													</Dropdown.Menu>
												</Dropdown>
											</ExceptMobile>
											<MobileOnly>
												<NotificationMobile
													totalCount={totalCount}
													items={items}
													shouldLoadMore={shouldLoadMore}
													loading={loading}
													error={error}
													refetch={refetch}
													userId={userId}
													refetchCount={refetchCount}
												/>
											</MobileOnly>
										</>
									)
								}}
							</Query>
						</ErrorBoundary>
					</React.Fragment>
				)
			}}
		</Query>
	)
}

export default NotificationPopup

const NotificationTrigger = ({ onClick }) => {
	return (
		<NotifIcon onClick={onClick}>
			<NotifImage />
		</NotifIcon>
	)
}

NotificationTrigger.propTypes = {
	onClick: PropTypes.func,
}

NotificationTrigger.defaultProps = {
	onClick: () => {},
}

/**
 * 모바일 화면일 때 notification 보기
 */
const NotificationMobile = ({
	totalCount,
	items,
	shouldLoadMore,
	loading,
	error,
	refetch,
	userId,
	refetchCount,
}) => {
	const [isOpen, setOpen] = useState(false)
	return (
		<>
			<NotificationTrigger
				onClick={() => {
					setOpen(r => !r)
				}}
			/>
			<NotificationContent
				isOpen={isOpen}
				onClose={() => setOpen(false)}
				totalCount={totalCount}
				items={items}
				shouldLoadMore={shouldLoadMore}
				loading={loading}
				error={error}
				refetch={refetch}
				userId={userId}
				refetchCount={refetchCount}
			/>
		</>
	)
}

NotificationMobile.propTypes = {
	items: PropTypes.array,
	shouldLoadMore: PropTypes.bool,
	refetch: PropTypes.func,
	refetchCount: PropTypes.func,
	userId: PropTypes.number,
	loading: PropTypes.bool,
	error: PropTypes.object,
	totalCount: PropTypes.number,
}

/**
 * 내용
 */
const NotificationContent = ({
	isOpen,
	onClose,
	items,
	shouldLoadMore,
	refetch,
	refetchCount,
	userId,
	loading,
	error,
	totalCount,
	fetchMore,
	page,
}) => {
	return (
		<Container
			isOpen={isOpen}
			onScroll={e => {
				if (shouldLoadMore === true) {
					const isBottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
					if (isBottom === true && loading !== true) {
						fetchMore({
							variables: {
								pagination: {
									perPage: 10,
									page: page + 1,
								},
							},
							updateQuery: (prev, { fetchMoreResult }) => {
								if (fetchMoreResult == null) return prev
								const { userNotifications } = fetchMoreResult
								const { userNotifications: pUserNotifications } = prev
								if (pUserNotifications.page === userNotifications.page) return prev
								return {
									userNotifications: {
										items: [...pUserNotifications.items, ...userNotifications.items],
										perPage: userNotifications.perPage,
										page: userNotifications.page,
										totalCount: userNotifications.totalCount,
										__typename: 'Notification',
									},
								}
							},
						})
					}
				}
			}}
		>
			<MobileOnly>
				<CloseIconBox onClick={() => onClose()}>
					<MyIcon name="close" />
				</CloseIconBox>
			</MobileOnly>
			{loading === true && <LoadingView />}
			{error != null && <MiniErrorView error={error} />}
			{totalCount === 0 || items == null ? (
				<EmptyContainer>
					<EmptyResult size={UISize.SMALL} message="알림이 없습니다." />
				</EmptyContainer>
			) : (
				items.map(item => {
					return (
						<Item key={`notif-${item.id}`}>
							<NotificationItem
								{...item}
								refetch={refetch}
								refetchCount={refetchCount}
								currentUserId={userId}
							/>
						</Item>
					)
				})
			)}
			{/* {shouldLoadMore === true && (
														<div>
															<InlineLoader size={UISize.MINI} height={50} />
														</div>
													)} */}
		</Container>
	)
}

NotificationContent.propTypes = {
	items: PropTypes.array,
	shouldLoadMore: PropTypes.bool,
	refetch: PropTypes.func,
	refetchCount: PropTypes.func,
	userId: PropTypes.number,
	loading: PropTypes.bool,
	error: PropTypes.object,
	totalCount: PropTypes.number,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	fetchMore: PropTypes.func,
	page: PropTypes.number,
}

/**
 * Styles
 */

const Container = styled.div`
	width: 500px;
	max-height: 400px;
	overflow-y: auto;
	background: white;

	@media (max-width: ${props => props.theme.PAGE_MOBILE_MAX_WIDTH}px) {
		position: relative;
		max-height: unset;
		height: 100%;
		width: 100vw;
		position: fixed;
		left: 0px;
		top: 0px;
		padding-top: 50px;
		display: ${props => (props.isOpen === true ? 'block' : 'none')};
	}
`

const EmptyContainer = styled.div`
	width: 100%;
	height: 100px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
`

const NotifIcon = styled.div`
	margin-top: 3px;
	:hover {
		opacity: 0.4;
	}
`

const Item = styled(Dropdown.Item)`
	:not(:last-child) {
		border-bottom: 1px solid #eee;
	}
`

const UnreadCount = styled.div`
	position: absolute;
	left: 20px;
	padding: 5px;
	bottom: -2px;
	height: 16px;
	width: auto;
	text-align: center;
	background: ${COLOR_VIVID_RED};
	border-radius: 20px;
	color: white;
	font-size: 11px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
`
