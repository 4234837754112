import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Query, graphql } from 'react-apollo'
import compose from 'lodash.flowright'
import { Header } from 'semantic-ui-react'
import styled from 'styled-components'
import CommentItem from '../components/comment/CommentItem'
import CommentWrite from '../components/comment/CommentWrite'
import { GET_USER_INFO } from '../graphql/queries/user'
import { GET_COMMENTS_NAME, GET_COMMENTS_NICK } from '../graphql/queries/community'
import { WRITE_COMMENT_NAME, WRITE_COMMENT_NICK } from '../graphql/mutations/community'
import ErrorView from '../components/common/error/ErrorView'
import LoadingView from '../components/common/loader'
import { PAGE_MOBILE_MAX_WIDTH } from '../constants/styles'

class Comments extends PureComponent {
	static propTypes = {
		useNick: PropTypes.bool,
		entityType: PropTypes.string,
		entityId: PropTypes.number,
		writeCommentName: PropTypes.func,
		writeCommentNick: PropTypes.func,
		noBorder: PropTypes.bool,
	}

	handleSubmit = async params => {
		const { useNick, writeCommentName, writeCommentNick } = this.props
		const onSubmit = useNick ? writeCommentNick : writeCommentName
		await onSubmit(params)
		this.messagesEnd.scrollIntoView({
			behavior: 'smooth',
		})
	}

	render() {
		const { useNick, entityType, entityId, noBorder, style } = this.props

		return (
			<CommentWrap style={style} noBorder>
				<div style={{ maxWidth: 1100, margin: 'auto' }}>
					<Query
						query={useNick ? GET_COMMENTS_NICK : GET_COMMENTS_NAME}
						variables={{ entityType, entityId }}
						fetchPolicy="network-only"
					>
						{({ data, refetch, error, loading }) => {
							if (loading) {
								return <LoadingView />
							}

							if (error) {
								return <ErrorView />
							}

							const { caseComments } = data

							return (
								<div>
									<div style={{ marginBottom: 30 }}>
										<Header as="h3">의견 {caseComments.length} 개</Header>
										<CommentWrite onUpdate={refetch} onSubmit={this.handleSubmit} {...this.props} />
									</div>
									{caseComments instanceof Array && caseComments.length > 0 ? (
										caseComments.map(cCom => {
											if (cCom && cCom.comment) {
												const { comment } = cCom
												return (
													<CommentItem
														key={comment.id}
														{...comment}
														{...this.props}
														onUpdate={refetch}
													/>
												)
											}
											return null
										})
									) : (
										<div style={{ textAlign: 'center', color: '#999', fontSize: 16 }}>
											아직 의견이 없습니다.
										</div>
									)}
									<div
										style={{ float: 'left', clear: 'both' }}
										ref={el => {
											this.messagesEnd = el
										}}
									/>
								</div>
							)
						}}
					</Query>
				</div>
			</CommentWrap>
		)
	}
}

export default compose(
	graphql(GET_USER_INFO, {
		props: ({ data }) => ({
			user: data.userInfo && data.userInfo.user,
		}),
	}),
	graphql(WRITE_COMMENT_NAME, {
		name: 'writeCommentName',
	}),
	graphql(WRITE_COMMENT_NICK, {
		name: 'writeCommentNick',
	})
)(Comments)

const CommentWrap = styled.div`
	text-align: left;
	border-top: ${({ noBorder }) => (noBorder !== true ? '1px solid #eee' : 'none')};
	padding: 30px;
	margin: 30px;

	@media (max-width: ${PAGE_MOBILE_MAX_WIDTH}px) {
		padding: 0px;
		margin: 0px;
		margin-top: 20px;
		padding-bottom: 60px !important;
	}
`
