import { GET_TOAST_MESSAGES } from '../../graphql/queries/app'

export const defaults = {
	toastMessages: [],
}

/**
 * 토스트 메시지 등 글로벌 앨러트메시지 관리
 */
export const resolvers = {
	Mutation: {
		addToastMessage: (_, variables, { cache, client }) => {
			const { id, autoDismiss = true, content, success = false, error = false } = variables

			const previousState = cache.readQuery({ query: GET_TOAST_MESSAGES })
			const newMessage = {
				id,
				content,
				success,
				error,
				autoDismiss,
				__typename: 'ToastMessage',
			}
			const toastMessages = [...previousState.toastMessages, newMessage]

			client.writeQuery({
				query: GET_TOAST_MESSAGES,
				data: { toastMessages },
			})

			if (autoDismiss === true) {
				setTimeout(() => {
					const previousState = cache.readQuery({ query: GET_TOAST_MESSAGES })
					client.writeQuery({
						query: GET_TOAST_MESSAGES,
						data: {
							toastMessages: previousState.toastMessages.filter(i => i.id !== id),
						},
					})
				}, 3000)
			}

			return newMessage
		},
	},
}
