import React from 'react'
import PropTypes from 'prop-types'
import { Prompt } from 'react-router-dom'

import { Button, Modal, ModalActions, ModalContent } from 'semantic-ui-react'
import ResearcherBasicSettingForm from './ResearchMemberBasicSettingForm'
import { matchErrorCode } from '../../../../libs/errorHelper'
import errorTypes from '../../../../errorTypes'

const styles = {
	container: {
		width: '100%',
	},
	contentContainer: {
		padding: '50px 40px',
		paddingTop: 0,
	},
}

/**
 * 연구자 관리 - 기본 정보 수정
 */
class ResearchMemberBasicSetting extends React.PureComponent {
	static propTypes = {
		researcher: PropTypes.shape({
			id: PropTypes.string,
			__typename: PropTypes.string,
		}),
		match: PropTypes.shape({
			params: PropTypes.shape({
				id: PropTypes.string,
				subId: PropTypes.string,
			}),
		}),
		onRemoveResearcher: PropTypes.func,
		onUpdateResearcherInfo: PropTypes.func,
	}

	constructor(props) {
		super(props)

		this.state = {
			researcher: props.researcher,
			hasChanged: false,
			deleteModalOn: false,
		}
	}

	handleChange = (key, value) => {
		this.setState(prevState => {
			return {
				...prevState,
				hasChanged: true,
				researcher: {
					...prevState.researcher,
					researcherInfo: {
						...(prevState.researcher && prevState.researcher.researcherInfo),
						[key]: value,
					},
				},
			}
		})
		this.handleDifferent() // 새로고침하거나 탭을 종료할때
	}

	handleSubmit = async () => {
		const {
			onUpdateResearcherInfo,
			match: {
				params: { id, subId },
			},
		} = this.props
		const { researcher } = this.state
		const { researcherInfo } = researcher

		const { __typename, id: x, ...rchInfo } = researcherInfo

		try {
			await onUpdateResearcherInfo({
				variables: {
					researchId: parseInt(id, 10),
					userId: parseInt(subId, 10),
					researcherInfo: rchInfo,
				},
			})
			alert('저장이 완료되었습니다.')
			this.setState({ hasChanged: false }) // 저장된 이후에는 팝업이 뜰 이유가 없음
			window.onbeforeunload = null // 저장된 이후에는 팝업이 뜰 이유가 없음
		} catch (e) {
			if (matchErrorCode(e, errorTypes.ACCESS_FORBIDDEN_ERROR)) {
				alert('권한이 부족합니다.')
			} else {
				alert('저장이 실패하였습니다.')
			}
		}
	}

	handleDifferent = () => {
		window.onbeforeunload = e => {
			e.preventDefault()
			return false
		}
	}

	handleDelete = () => {
		this.setState({
			deleteModalOn: true,
		})
	}

	handleDeleteRequest = async () => {
		const {
			onRemoveResearcher,
			match: {
				params: { id, subId },
			},
			history,
		} = this.props
		await onRemoveResearcher({
			variables: {
				researchId: parseInt(id, 10),
				userId: parseInt(subId, 10),
			},
		})

		history.push(-1)
		this.setState({
			deleteModalOn: false,
		})
	}

	render() {
		const { researcher, hasChanged } = this.state
		return (
			<React.Fragment>
				<Prompt
					when={hasChanged} // hasChanged state가 true인 경우 페이지 이동시 Prompt 컴포넌트가 실행됨
					message="변경 사항이 있습니다. 저장하지 않고 진행하시겠습니까?"
				/>
				<div style={styles.container}>
					<div style={styles.contentContainer}>
						<ResearcherBasicSettingForm {...researcher} onChange={this.handleChange} />
						<Button
							style={{ marginTop: 20 }}
							type="submit"
							size="big"
							primary
							onClick={this.handleSubmit}
						>
							변경 사항 저장
						</Button>
						<Button
							style={{ marginTop: 20, marginLeft: 10 }}
							type="submit"
							size="big"
							color="red"
							onClick={this.handleDelete}
						>
							연구자 제거
						</Button>
					</div>
				</div>
				<Modal
					size="mini"
					open={this.state.deleteModalOn}
					onClose={() => this.setState({ deleteModalOn: false })}
				>
					<ModalContent>
						연구자를 제거하시겠습니까? 연구자는 해당 프로젝트에 더 이상 접근할 수 없게 됩니다.
					</ModalContent>
					<ModalActions>
						<Button
							onClick={() => {
								this.setState({ deleteModalOn: false })
							}}
						>
							취소
						</Button>
						<Button color="red" onClick={this.handleDeleteRequest}>
							연구자 제거
						</Button>
					</ModalActions>
				</Modal>
			</React.Fragment>
		)
	}
}

export default ResearchMemberBasicSetting
