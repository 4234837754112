import React, { useState } from 'react'

import { withRouter } from 'react-router-dom'
import { Menu, Pagination, Accordion } from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { useQuery } from 'react-apollo'

import PageContainer from '../components/common/container/PageContainer'
import { GET_FAQS_ALL, GET_CATEGORIES } from '../graphql/queries/faq'
import InputSearch from '../components/common/basic/InputSearch'
import ContentContainer from '../components/common/container/ContentContainer'
import Item from '../components/other/faq/Item'
import { toCommonDateString } from '../libs/dateHelper'
import LoadingView from '../components/common/loader'
import EmptyResult from '../components/common/empty/EmptyResult'
import ErrorView from '../components/common/error/ErrorView'

const PAGE_TITLE = 'FAQ'
const perPage = 15

/**
 * FAQ 페이지
 */
const Faq = () => {
	const [page, setPage] = useState(1)
	const [searchQuery, setSearchQuery] = useState('')
	const [activeId, setActiveId] = useState(-1) // 펼친 FAQ ID
	const [categoryId, setCategoryId] = useState(0) // 선택한 카테고리 ID (0이면 전체)
	const { loading: faqsLoading, data: faqsData, error: faqsError } = useQuery(GET_FAQS_ALL, {
		variables: {
			pagination: { page: page, perPage: perPage },
			filter: {
				search: { query: searchQuery },
				fields: categoryId !== 0 ? { name: 'category', value: String(categoryId) } : null,
			},
		},
	})
	const { loading: categoriesLoading, data: categoriesData, error: categoriesError } = useQuery(
		GET_CATEGORIES
	)

	const { faqs: { items, totalCount = 0 } = {} } = faqsData || {}

	const handleToggle = id => {
		if (activeId === id) {
			setActiveId(-1)
		} else {
			setActiveId(id)
		}
	}

	const handlePage = (e, { activePage }) => {
		setPage(activePage)
	}

	const handleSearch = e => {
		setSearchQuery(e.target.value)
		setPage(1)
	}

	const handleCategory = (e, data) => {
		setCategoryId(data.id)
		setPage(1)
	}

	return (
		<PageContainer title={PAGE_TITLE} fixed hasMobile>
			<Categories pointing secondary>
				<Category id={0} onClick={handleCategory} active={categoryId === 0}>
					<span>전체</span>
				</Category>
				{categoriesLoading !== true &&
					categoriesError == null &&
					categoriesData.appCategories.map(category => (
						<Category
							key={category.id}
							id={category.id}
							onClick={handleCategory}
							active={categoryId === category.id}
						>
							<span>{category.name}</span>
						</Category>
					))}
			</Categories>
			<SearchBar fluid placeholder="검색" value={searchQuery} onChange={handleSearch} />
			<ContentContainer>
				{faqsError != null ? (
					<ErrorView />
				) : faqsLoading === true ? (
					<LoadingView />
				) : totalCount === 0 ? (
					<EmptyWrapper>
						<EmptyResult message="항목이 없습니다" />
					</EmptyWrapper>
				) : (
					<Accordion styled>
						{items.map(item => (
							<Item
								key={item.id}
								title={item.question}
								category={item.category.name}
								date={toCommonDateString(item.createdAt)}
								active={activeId === item.id}
								onClick={() => handleToggle(item.id)}
								onClose={() => setActiveId(-1)}
							>
								<ReactMarkdown source={item.answer} />
							</Item>
						))}
					</Accordion>
				)}
			</ContentContainer>
			<PaginationWrapper>
				<Pagination
					activePage={page || 1}
					totalPages={faqsData != null ? Math.ceil(totalCount / perPage) || 1 : 1}
					onPageChange={handlePage}
				/>
			</PaginationWrapper>
		</PageContainer>
	)
}

export default withRouter(Faq)

/**
 * Styles
 */

const SearchBar = styled(InputSearch)`
	margin-top: 28px;
	max-width: 300px;
`

const PaginationWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 20px;
`

const Categories = styled(Menu)`
	border-bottom: 1px solid ${props => props.theme.COLOR_BORDER_GRAY} !important;
	box-sizing: border-box !important;
`

const Category = styled(Menu.Item)``

const EmptyWrapper = styled.div`
	height: 300px;
`
