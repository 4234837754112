/**
 * [Component] 필드 정보 설정 Modal 내의 선택지 추가/제거 뷰
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Segment, Grid, Icon } from 'semantic-ui-react'
import SelectOptionItem from './SelectOptionItem'

const SelectOptionList = ({ items, onAddOption, ...others }) => {
  const { number, type } = others

  const selects = items.map((item, index) => (
    <Grid.Column width={8} key={'a' + index}>
      <SelectOptionItem {...item} index={index} {...others} />
    </Grid.Column>
  ))

  let i,
    j,
    selectChunk,
    chunk = 2,
    selectChunks = []
  for (i = 0, j = selects.length; i < j; i += chunk) {
    selectChunk = selects.slice(i, i + chunk)
    selectChunks.push(selectChunk)
  }

  return (
    <Segment.Group>
      <Segment
        style={styles.buttonSegment}
        secondary
        textAlign="center"
        onClick={onAddOption(type, number)}
      >
        <Icon name="add" /> {number === true && '단위 '}선택지 추가하기
      </Segment>
      <Segment>
        <Grid celled="internally">
          {selectChunks.map((item, index) => (
            <Grid.Row key={index + 'n'}>{item}</Grid.Row>
          ))}
        </Grid>
      </Segment>
    </Segment.Group>
  )
}

SelectOptionList.propTypes = {
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onAddOption: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  chunkSize: PropTypes.number.isRequired,
}

export default SelectOptionList

const styles = {
  buttonSegment: {
    cursor: 'pointer',
  },
}
