import styled from 'styled-components'

export const TabletInput = styled.input`
	font-size: 18px !important;
	border: none !important;
	border-bottom: 1px solid ${props => props.theme.COLOR_BORDER_GRAY} !important;
	border-radius: 0px;
	box-shadow: none !important;
	padding: 20px !important;
	min-width: 300px;
	max-width: 500px;
	width: 100%;
	background: none;
	::placeholder {
		font-weight: 400;
		color: ${props => props.theme.COLOR_TEXT_LIGHTGRAY};
	}

	:focus {
		outline: none;
	}
`
