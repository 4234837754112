import React from 'react'
import PropTypes from 'prop-types'
import MedInfoQueryItem from './MedInfoQueryItem'
import EmptyResult from '../../../common/empty/EmptyResult'
import { UISize } from '../../../../enum'
import { InlineLoader } from '../../../common/loader/InlineLoader'
import MiniErrorView from '../../../common/error/MiniErrorView'

const MedInfoQueryListView = props => {
	const { loading, error, items, onUpdate, user } = props

	if (loading) {
		return <InlineLoader />
	}

	if (error) {
		return <MiniErrorView />
	}

	return (
		<div>
			{!items || items.length <= 0 ? (
				<EmptyResult message="리뷰 메시지가 없습니다." size={UISize.TINY} />
			) : (
				<div>
					{items.map(item => {
						return (
							<MedInfoQueryItem
								{...props}
								key={item.id}
								{...item}
								user={user}
								onUpdate={onUpdate}
							/>
						)
					})}
				</div>
			)}
		</div>
	)
}

MedInfoQueryListView.propTypes = {
	items: PropTypes.array,
	onUpdate: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	loading: PropTypes.bool,
	error: PropTypes.object,
}

export default MedInfoQueryListView
