import { useState, useEffect } from 'react'

/**
 * Select(Dropdown) Input 처리를 위한 Custom Hook
 *
 * 선택이 변경되면 State Value를 바꾸고 바로 Global State를 Update함
 */
const useSelectInput = (onUpdate, key, value, selectOptions) => {
	const [stateValue, setStateValue] = useState(value)

	const onChange = (e, { value: iVal }) => {
		setStateValue(iVal)
		onUpdate(key, iVal)
	}

	// Prop value change
	useEffect(() => {
		if (value != null) setStateValue(value)
	}, [value])

	return [stateValue, onChange]
}

export default useSelectInput
