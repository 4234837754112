import gql from 'graphql-tag'

export const FRAGMENT_RESEARCH_SIDE_MENU_ITEM = gql`
	fragment ResearchSideMenuItemParts on ResearchSideMenuItem {
		id
		title
		isOpen
		__typename
		menuItems {
			id
			key
			title
			to
			value
			exact
			number
			imageIcon
		}
	}
`

export const FRAGMENT_RESEARCH_SIDE_MENU_ITEM_LIST = gql`
	fragment ResearchSideMenuItemLists on ResearchSideMenuItem {
		isOpen
		menuItems {
			id
			key
			title
			to
			value
			exact
			number
			imageIcon
		}
	}
`

export const FRAGMENT_PATIENT_SURVEY_CURRENT_INDEX = gql`
	fragment PatientSurveyCurrentIndex on CurrentIndex {
		id
		index
	}
`
