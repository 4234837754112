import * as React from 'react'
import PropTypes from 'prop-types'
import { UISize } from '../../enum'

/**
 * 유저 아바타 이미지. 원 형태의 작은 이미지.
 */

const WIDTHS = Object.freeze({
	[UISize.MINI]: 24,
	[UISize.TINY]: 32,
	[UISize.SMALL]: 40,
	[UISize.LARGE]: 50,
	[UISize.HUGE]: 80,
	[UISize.MASSIVE]: 120,
})

/**
 * 유저 아바타 이미지. 댓글 등에 사용되는 원 형태의 이미지.
 */
export const Avatar = ({ style, image, size = UISize.SMALL, width }) => {
	const wh = width || WIDTHS[size]
	return (
		<div
			style={{
				display: 'inline-block',
				width: wh,
				height: wh,
				backgroundImage: `url(${image})`,
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center',
				borderRadius: wh,
				verticalAlign: 'middle',
				...style,
			}}
		/>
	)
}

Avatar.propTypes = {
	image: PropTypes.string.isRequired,
	size: PropTypes.oneOf(Object.values(UISize)),
	width: PropTypes.number,
	style: PropTypes.object,
}

Avatar.defaultProps = {
	size: UISize.SMALL,
	width: null,
	style: null,
}
