import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Query } from 'react-apollo'
import {
	Placeholder,
	PlaceholderLine,
	Segment,
	Image,
	Header,
	Grid,
	Icon,
} from 'semantic-ui-react/dist/commonjs'

import ReactMarkdown from 'react-markdown'
import moment from 'moment'

import LoadingView from '../components/common/loader'

import '../css/markdown.css'
import { GET_RESEARCH_OVERVIEW } from '../graphql/queries/research'
import ErrorView from '../components/common/error/ErrorView'
import StatusLabel from '../components/common/StatusLabel'
import { ResearchStatus, ResearcherRole } from '../enum'
import { truncateString } from '../libs/valueHelper'
import { MiniBar } from '../components/common/basic/MiniBar'
import { COLOR_TEXT_LIGHTGRAY } from '../constants/styles'
import PageContainer from '../components/common/container/PageContainer'
import Breadcrumb from '../components/common/basic/Breadcrumb'
import ResearchOverviewEdit from '../components/research/overview/ResearchOverviewEdit'
import ClearButton from '../components/common/basic/ClearButton'

/**
 * 연구 개요
 */
export default class ResearchOverview extends PureComponent {
	static propTypes = {
		researchId: PropTypes.number.isRequired,
		loadingResearchInfo: PropTypes.bool.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = {
			isEditing: false,
		}
	}

	toggleEditing = () =>
		this.setState(pr => ({
			isEditing: !pr.isEditing,
		}))

	handleStopEditing = () =>
		this.setState({
			isEditing: false,
		})

	render() {
		const { loadingResearchInfo, breadCrumbs, researchId, userResearcherInfo } = this.props
		const { isEditing } = this.state
		let canChangeSettings
		if (userResearcherInfo != null) {
			;({ canChangeSettings } = userResearcherInfo)
		}

		return (
			<Query query={GET_RESEARCH_OVERVIEW} variables={{ id: researchId }}>
				{({ loading, data, error }) => {
					let researchDetail
					let description
					if (data != null) {
						;({ researchDetail } = data)

						if (researchDetail != null) {
							;({ description } = researchDetail)
						}
					}

					return (
						<PageContainer fixed width={900}>
							<Breadcrumb sections={breadCrumbs} />
							<ResearchHeader loading={loadingResearchInfo} {...this.props} />
							<div>{description} </div>
							{loading === true && <LoadingView />}
							{error != null && <ErrorView />}
							{researchDetail != null && (
								<React.Fragment>
									<ResearchStatusInfo {...researchDetail} />
									<div style={styles.markdown}>
										{canChangeSettings === true && isEditing === false && (
											<SectionTitle>
												<ClearButton onClick={this.toggleEditing}>수정</ClearButton>
											</SectionTitle>
										)}
										<MarkdownContent>
											{isEditing === false ? (
												<ReactMarkdown className="markdown-body" source={researchDetail.overview} />
											) : (
												<ResearchOverviewEdit
													researchId={researchId}
													onStopEditing={this.handleStopEditing}
													overview={researchDetail.overview}
												/>
											)}
										</MarkdownContent>
									</div>
								</React.Fragment>
							)}
						</PageContainer>
					)
				}}
			</Query>
		)
	}
}

/**
 * 연구 상세 페이지 상단 제목 헤더
 */
const ResearchHeader = ({ loading, title, organizedBy, status, userResearcherInfo }) => {
	return (
		<ResearchHeaderContainer>
			{loading === true ? (
				<Placeholder>
					<PlaceholderLine />
					<PlaceholderLine />
					<PlaceholderLine />
				</Placeholder>
			) : (
				<React.Fragment>
					<StatusLabel status={status} set={ResearchStatus} />
					<h5>{truncateString(title, 30)}</h5>
					<HeaderSub>
						{organizedBy != null && organizedBy.name} <MiniBar />{' '}
						{userResearcherInfo &&
							ResearcherRole[userResearcherInfo.role] &&
							ResearcherRole[userResearcherInfo.role].text}
					</HeaderSub>
				</React.Fragment>
			)}
		</ResearchHeaderContainer>
	)
}

ResearchHeader.propTypes = {
	loading: PropTypes.bool.isRequired,
	title: PropTypes.string,
	organizedBy: PropTypes.shape({
		name: PropTypes.string.isRequired,
	}),
	status: PropTypes.string,
	userResearcherInfo: PropTypes.shape({
		role: PropTypes.string.isRequired,
		status: PropTypes.string.isRequired,
	}),
}

ResearchHeader.defaultProps = {
	status: '',
	title: null,
	organizedBy: null,
	userResearcherInfo: null,
}

const ResearchStatusInfo = ({ director, startDate, endDate, estSubjects }) => {
	return (
		<Segment>
			<Grid divided columns={3}>
				<Grid.Column textAlign="center">
					<Header as="h4">연구 책임자(PI)</Header>
					<div>
						<Image
							as="a"
							wrapped
							style={styles.directorImage}
							circular
							src={director.profileImage}
						/>
						<div style={styles.infoWrap}>
							<b>{director.name}</b> <br />
							{director.institution.name}
						</div>
					</div>
				</Grid.Column>
				<Grid.Column textAlign="center">
					<Header as="h4">연구기간</Header>
					<div style={styles.infoWrap2}>
						<b>{moment(startDate).format('YYYY년 MM월 DD일')}</b> 부터
						<br />
						<b>{moment(endDate).format('YYYY년 MM월 DD일')}</b> 까지
					</div>
				</Grid.Column>
				<Grid.Column textAlign="center">
					<Header as="h4">예상 연구 규모</Header>
					<div>
						{estSubjects && (
							<div>
								<Icon name="user" /> <b>목표 대상자</b> {estSubjects} 명
							</div>
						)}
					</div>
				</Grid.Column>
			</Grid>
		</Segment>
	)
}
/**
 * Styles
 */

const ResearchHeaderContainer = styled.div`
	margin-top: 40px;
	margin-bottom: 30px;
`

const HeaderSub = styled.div`
	color: ${COLOR_TEXT_LIGHTGRAY};
	font-size: 12px;
	line-height: 16px;
`

const SectionTitle = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 30px;
`

const MarkdownContent = styled.div`
	margin-top: 10px;
`

const styles = {
	directorImage: {
		width: 50,
		height: 50,
		marginRight: 10,
	},
	infoWrap: {
		display: 'inline-block',
		verticalAlign: 'middle',
		textAlign: 'left',
	},
	infoWrap2: {
		marginTop: 30,
	},
	infoSegment: {
		marginTop: 10,
		marginBottom: 70,
	},
}
