import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button, Form } from 'semantic-ui-react/dist/commonjs'
import { Link } from 'react-router-dom'
import { ROUTE_SIGNUP, ROUTE_LOGIN_FORGOT_ACCOUNT } from '../../../constants/routes'
import PageContainer from '../../common/container/PageContainer'
import LinkColored from '../../common/basic/LinkColored'

class LoginForm extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			username: '',
			password: '',
		}
	}

	handleChange = e => {
		const up = {}
		up[e.target.name] = e.target.value
		this.setState(up)
	}

	handleLogin = async () => {
		const { onLoginRequest } = this.props
		const { username, password } = this.state

		await onLoginRequest({ username, password })
	}

	render() {
		const { username, password } = this.state

		return (
			<Container>
				<TitleBox>
					<h5>로그인</h5>
				</TitleBox>
				<FormBox>
					<Form onSubmit={this.handleLogin}>
						<Form.Input
							fluid
							name="username"
							label="사용자 ID"
							placeholder="사용자 ID"
							value={username}
							onChange={this.handleChange}
						/>
						<Form.Input
							fluid
							placeholder="비밀번호"
							label="비밀번호"
							name="password"
							type="password"
							value={password}
							onChange={this.handleChange}
						/>
						<Button primary fluid size="large">
							로그인
						</Button>
					</Form>
				</FormBox>
				<ForgotBox>
					<LinkColored to={ROUTE_LOGIN_FORGOT_ACCOUNT}>
						아이디나 비밀번호가 기억나지 않습니다.
					</LinkColored>
				</ForgotBox>
				<SignUpBox>
					아직 계정이 없으신가요?
					<SignUpButtonBox>
						<Button fluid as={Link} size="large" primary to={ROUTE_SIGNUP}>
							가입 신청
						</Button>
					</SignUpButtonBox>
				</SignUpBox>
			</Container>
		)
	}
}

LoginForm.propTypes = {
	onLoginRequest: PropTypes.func.isRequired,
}

export default LoginForm

const Container = styled.div`
	width: 320px;
	text-align: left;

	@media (max-width: ${props => props.theme.PAGE_MOBILE_MAX_WIDTH}px) {
		width: 100%;
	}
`
const TitleBox = styled.div`
	text-align: center;
`

const FormBox = styled.div`
	margin-top: 40px;
`

const ForgotBox = styled.div`
	margin-top: 20px;
`

const SignUpBox = styled.div`
	margin-top: 20px;
`

const SignUpButtonBox = styled.div`
	margin-top: 10px;
`
