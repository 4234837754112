import React, { PureComponent, Children } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { Loader } from 'semantic-ui-react/dist/commonjs'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import Toolbar from 'react-big-calendar/lib/Toolbar'
import Event from 'react-big-calendar/lib/EventCell'
import moment from 'moment'
import 'moment/locale/ko'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import { Button, Icon } from 'semantic-ui-react'
import { GET_RESEARCH_SCHEDULES } from '../graphql/queries/research'
import ErrorView from '../components/common/error/ErrorView'
import { ScheduleStatus } from '../enum'

import LeftArrow from '../assets/images/icons/left.svg'
import RightArrow from '../assets/images/icons/right.svg'
import { ROUTE_PROJECT_DETAIL_PATIENT_DETAIL } from '../constants/routes'
import { replaceParams } from '../libs/routeHelper'
import PageContainer from '../components/common/container/PageContainer'
import { COLOR_SECONDARY_BLUE, COLOR_SUCCESS } from '../constants/styles'

moment.locale('ko')

const localizer = momentLocalizer(moment)

const PAGE_TITLE = '조사 일정'

/**
 * 조사일정 설정
 */
class ResearchSchedule extends PureComponent {
	static propTypes = {
		researchId: PropTypes.number.isRequired,
		history: PropTypes.shape({
			push: PropTypes.func,
		}).isRequired,
		breadCrumbs: PropTypes.arrayOf(PropTypes.object),
	}

	// 이벤트 셀렉트 할 시에 연구 대상자 라우터로 넘어 감
	handleEventSelect = event => {
		const { researchId, history } = this.props
		history.push(
			replaceParams(ROUTE_PROJECT_DETAIL_PATIENT_DETAIL, {
				id: researchId,
				subId: event.patientId,
			})
		)
	}

	render() {
		const { breadCrumbs, researchId } = this.props
		const CURRENT_DATE = new Date()

		const cellWrapper = ({ children }) =>
			React.cloneElement(Children.only(children), {
				style: {
					...children.style,
				},
			})

		return (
			<Query
				query={GET_RESEARCH_SCHEDULES}
				variables={{
					researchId,
					year: CURRENT_DATE.getFullYear(),
					month: CURRENT_DATE.getMonth(),
				}}
				fetchPolicy="network-only"
			>
				{({ data, loading, error, refetch }) => {
					if (loading) return <Loader size="large" />
					if (error) return <ErrorView />

					const { researchSchedules } = data

					const newEvents = researchSchedules.map(item => {
						const rDate = new Date(item.reservedDate)
						return {
							title: `${item.patient.name} ${item.phase.title}`,
							start: rDate,
							end: rDate,
							allDay: false,
							patientId: item.patient.id,
							isFinished: item.status === ScheduleStatus.DONE.value,
						}
					})
					return (
						<PageContainer fixed title={PAGE_TITLE} breadCrumbs={breadCrumbs}>
							<Wrapper>
								<Calendar
									className="my-calendar"
									localizer={localizer}
									startAccessor="start"
									endAccessor="end"
									selectable
									views={[Views.MONTH]}
									events={newEvents}
									step={30}
									popup
									showMultiDayTimes
									onSelectEvent={event => this.handleEventSelect(event)}
									onRangeChange={range => {
										//날짜 범위 변경 시 e.g.) 달 이동
										let startDate, endDate
										if (range.start && range.end) {
											startDate = range.start
											endDate = range.end
										} else if (Object.keys(range).length === 7) {
											startDate = range[0]
											endDate = range[6]
										}

										if (startDate && endDate) {
											refetch({
												researchId,
												startDate: range.start,
												endDate: range.end,
											})
										}
									}}
									messages={{
										month: '월별',
										next: '다음',
										previous: '이전',
										agenda: '목록형',
										noEventsInRange: '이 기간에는 일정이 없습니다.',
										today: '오늘',
										week: '주간',
									}}
									components={{
										dateCellWrapper: cellWrapper,
										toolbar: MyToolbar,
										event: MyEvent,
									}}
									style={{ width: '100%', height: 900, position: 'relative' }}
								/>
							</Wrapper>
						</PageContainer>
					)
				}}
			</Query>
		)
	}
}

export default withRouter(ResearchSchedule)

/**
 * react-big-calendar용 커스텀 툴바
 */
class MyToolbar extends Toolbar {
	render() {
		const { date } = this.props

		return (
			<ToolbarContainer>
				<TodayButton>
					<Button onClick={() => this.navigate('TODAY')} primary size="large">
						오늘
					</Button>
				</TodayButton>
				<Title>
					<ArrowButton isLeft onClick={() => this.navigate('PREV')} />
					<TitleText>{moment(date).format('YYYY년 MM월')}</TitleText>
					<ArrowButton onClick={() => this.navigate('NEXT')} />
				</Title>
			</ToolbarContainer>
		)
	}
}

/**
 * 예약 이벤트
 */
class MyEvent extends Event {
	render() {
		const {
			title,
			event: { isFinished },
		} = this.props
		return (
			<EventItem isFinished={isFinished}>
				{title}
				{isFinished === true && (
					<IconWrap>
						<Icon color="white" name="check circle" />
					</IconWrap>
				)}
			</EventItem>
		)
	}
}

/**
 * 달 앞 뒤 이동버튼
 */
const ArrowButton = props => {
	const { isLeft } = props
	return (
		<Button {...props}>
			<img src={isLeft === true ? LeftArrow : RightArrow} />
		</Button>
	)
}

ArrowButton.propTypes = {
	isLeft: PropTypes.bool,
}

ArrowButton.defaultProps = {
	isLeft: false,
}

/**
 * Styles
 */

const Wrapper = styled.div`
	margin-bottom: 200px;
`

const ToolbarContainer = styled.div`
	margin-bottom: 30px;
`

const TodayButton = styled.div`
	display: inline-block;
	position: absolute;
	right: 0px;
	top: -5px;

	.button {
		margin-right: 0px;
	}
`

const Title = styled.div`
	text-align: center;
	font-size: 20px;
`

const TitleText = styled.div`
	margin: 0px 30px;
	display: inline-block;
`

const IconWrap = styled.div`
	display: inline-block;
	margin-left: 5px;
`

const EventItem = styled.div`
	background: ${props => (props.isFinished === true ? COLOR_SUCCESS : COLOR_SECONDARY_BLUE)};
	padding: 5px 10px;
	border-radius: 20px;
`
