/**
 * [Component] 연구대상자 진료 기록 목록
 *
 * 연구 대상자 페이지, 데이터 페이지에서 모두 사용
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Table } from 'semantic-ui-react'
import moment from 'moment'

import EllipsisDropdown from '../../common/EllipsisDropdown'
import { RecordType, RecordCompleteStatus } from '../../../enum'

import 'moment/locale/ko'

import StatusDotLabel from '../../common/StatusDotLabel'
import RecordVersionChecker from '../../record/RecordVersionChecker'
import { toCommonDateString } from '../../../libs/dateHelper'
import { COLOR_TEXT_LIGHTGRAY } from '../../../constants/styles'
import DescriptionText from '../../common/basic/DescriptionText'

moment.locale('ko')

const ResearchRecordItem = props => {
	const {
		OpenDeleteModal,
		onPatientPage,
		type,
		visitedDate,
		template,
		userInfo,
		patient,
		creator,
		isComplete,
		recordTemplateChangeId,
		createdAt,
	} = props

	const vdate = moment(visitedDate)

	return (
		<React.Fragment>
			<Table.Cell
				style={{
					width: 150,
					fontWeight: 600,
				}}
			>
				{vdate.isSame(new Date(), 'day') ? vdate.format('LT') : vdate.format('YYYY. MM. DD.')}
			</Table.Cell>
			{!onPatientPage && (
				<Table.Cell
					style={{
						width: 200,
					}}
				>
					<span
						style={{
							minWidth: 60,
							display: 'inline-block',
						}}
					>
						{patient.patientResearches && patient.patientResearches[0].code}
					</span>
					&nbsp;&nbsp;&nbsp; (&nbsp;{patient.name}
					&nbsp;)
				</Table.Cell>
			)}
			<Table.Cell>
				{template && template.title}
				{type === RecordType.DROP.key && '참여 중단(Drop)'}
			</Table.Cell>
			<Table.Cell style={{ width: 140 }}>
				{creator ? <b>{creator.name}</b> : <DescriptionText>사용자 없음</DescriptionText>}
			</Table.Cell>
			<Table.Cell style={{ width: 140 }}>
				<DateInfo>{toCommonDateString(createdAt)}</DateInfo>
			</Table.Cell>
			<Table.Cell textAlign="left">
				{type !== RecordType.DROP.key && (
					<div>
						<StatusDotLabel
							{...(isComplete ? RecordCompleteStatus.COMPLETE : RecordCompleteStatus.INCOMPLETE)}
						/>
						{template != null && (
							<RecordVersionChecker
								currentTemplateChangeId={recordTemplateChangeId}
								recentTemplateChangeId={template.lastChange != null ? template.lastChange.id : null}
							/>
						)}
					</div>
				)}
			</Table.Cell>
			<Table.Cell style={{ width: 30 }}>
				{creator.id === userInfo.id && (
					<EllipsisDropdown
						options={[
							{
								text: '삭제',
								key: 'delete',
								value: 'delete',
								onClick: OpenDeleteModal,
							},
						]}
					/>
				)}
			</Table.Cell>
		</React.Fragment>
	)
}

ResearchRecordItem.propTypes = {
	onPatientPage: PropTypes.bool,
	type: PropTypes.string,
	visitedDate: PropTypes.string,
	template: PropTypes.object,
	forPatient: PropTypes.bool,
	patient: PropTypes.object,
	creator: PropTypes.object,
	researchId: PropTypes.number,
	userInfo: PropTypes.object,
	OpenDeleteModal: PropTypes.func,
	isComplete: PropTypes.bool.isRequired,
	recordTemplateChangeId: PropTypes.number,
	createdAt: PropTypes.string,
}

export default ResearchRecordItem

const DateInfo = styled.div`
	color: ${COLOR_TEXT_LIGHTGRAY};
`
