/**
 * [Component] 환자 개인정보 포함 상세정보 Modal
 */

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import moment from 'moment'
import { Modal, Grid } from 'semantic-ui-react'

import { GET_RESEARCH_PATIENT_PERSONAL } from '../../../graphql/queries/patient'
import MiniErrorView from '../../common/error/MiniErrorView'
import LoadingView from '../../common/loader'
import { PATIENT_INFO_FIELDS } from '../../../constants'

/**
 * 환자 상세 정보 보기
 */
export default class PatientDetailModal extends PureComponent {
	static propTypes = {
		onClose: PropTypes.func.isRequired,
		researchId: PropTypes.number.isRequired,
		code: PropTypes.string.isRequired,
		isOpen: PropTypes.bool.isRequired,
		patientBasicInfo: PropTypes.arrayOf(PropTypes.string),
	}

	static defaultProps = {
		patientBasicInfo: [],
	}

	// query 에 variables 적용
	render() {
		const { patientBasicInfo, researchId, code, onClose, isOpen } = this.props

		return (
			<Modal open={isOpen} closeIcon size="small" onClose={onClose}>
				<Modal.Header>연구대상자 상세 정보</Modal.Header>
				<Modal.Content>
					<Query
						query={GET_RESEARCH_PATIENT_PERSONAL}
						variables={{
							researchId,
							patientCode: code,
						}}
					>
						{({ data, loading, error }) => {
							if (loading) return <LoadingView />
							if (error) return <MiniErrorView />

							let inOut

							const { researchPatientPersonal: patient } = data
							const { registerer, patientResearches } = patient
							const patientResearch = patientResearches[0]

							const { inOut: inOut_, address, phone } = patientResearch

							if (inOut_ != null) {
								inOut = inOut_ === 1 ? '입원' : '외래'
							}

							const { institution } = registerer
							const gender = patient.sexMale ? '남자' : '여자'
							const isBirthDateReal =
								patientBasicInfo.find(i => i === PATIENT_INFO_FIELDS.birthDateReal.key) != null

							const birth =
								isBirthDateReal === true
									? moment(patientResearch.birthDateReal).format('ll')
									: moment(patient.birthDate).get('year') + '년'
							const bithdateLabel = isBirthDateReal === true ? '생년월일' : '출생연도'

							return (
								<div style={styles.wrapper}>
									<Grid columns={2}>
										<Grid.Column>
											<div style={styles.modalDiv}>
												<b style={styles.modalBold}>이름</b>
												<span>{patient.name}</span>
											</div>
											<div style={styles.modalDiv}>
												<b style={styles.modalBold}>성별</b>
												<span>{gender}</span>
											</div>
											<div style={styles.modalDiv}>
												<b style={styles.modalBold}>진료기관</b>
												<span>{institution.name}</span>
											</div>
										</Grid.Column>
										<Grid.Column>
											<div style={styles.modalDiv}>
												<b style={styles.modalBold}>연구 등록번호</b>
												<span>{patientResearch.code}</span>
											</div>
											<div style={styles.modalDiv}>
												<b style={styles.modalBold}>{bithdateLabel}</b>
												<span>{birth}</span>
											</div>
											<div style={styles.modalDiv}>
												<b style={styles.modalBold}>담당의</b>
												<span>{registerer.name}</span>
											</div>
										</Grid.Column>
									</Grid>
									<div style={styles.section}>
										<section>
											{inOut != null && (
												<div style={{ display: 'flex' }}>
													<p>
														<b style={styles.modalBold}>진료형태</b>
													</p>
													<span>{inOut}</span>
												</div>
											)}
											{phone != null && (
												<div style={{ display: 'flex' }}>
													<p>
														<b style={styles.modalBold}>연락처</b>
													</p>
													<span>{phone}</span>
												</div>
											)}
											{address != null && (
												<div style={{ display: 'flex' }}>
													<p>
														<b style={styles.modalBold}>주소</b>
													</p>
													<span>{address}</span>
												</div>
											)}
										</section>
									</div>
								</div>
							)
						}}
					</Query>
				</Modal.Content>
			</Modal>
		)
	}
}

const styles = {
	wrapper: {
		padding: '10px 20px',
	},
	section: {
		marginTop: 15,
	},
	modalDiv: {
		marginBottom: '10px',
	},
	modalBold: {
		fontWeight: 'bold',
		opacity: '0.89',
		display: 'inline-block',
		width: 120,
	},
	modalSpan: {
		fontSize: '18px',
		color: '#4b4b4b',
	},
}
