import gql from 'graphql-tag'
import {
	PATIENT_FRAGMENT,
	NEXT_SCHEDULE_FRAGMENT,
	RESEARCH_PATIENT_FRAGMENT,
	RESEARCH_PATIENT_DETAIL_FRAGMENT,
} from '../fragments/patient'
import { RECORD_FRAGMENT, PATIENT_RECORD_FRAGMENT } from '../fragments/record'
import {
	RESEARCH_FRAGMENT,
	RESEARCHER_FRAGMENT,
	RESEARCHER_INSTITUTION_PERMISSION,
} from '../fragments/research'
import { RECORD_MED_INFOS } from '../fragments'

export const GET_PATIENT_RECORDS = gql`
	query getPatientRecords(
		$pagination: CursorPaginationInput!
		$patientId: Int!
		$researchId: Int
		$filter: ListFilter
	) {
		patientRecords(
			pagination: $pagination
			patientId: $patientId
			filter: $filter
			researchId: $researchId
		) {
			items {
				...PatientRecordParts
			}
			perPage
			totalCount
		}
	}
	${PATIENT_RECORD_FRAGMENT}
`

// 차트 기록 불러오기
export const GET_RECORD_DETAIL = gql`
	query getRecordDetail($id: Int!) {
		recordDetail(id: $id) {
			record {
				...RecordParts
				patient {
					...PatientParts
					patientResearches {
						id
						initialVisitDate
					}
				}
				medInfos {
					...RecordMedInfoParts
				}
				template {
					id
					title
					type
					job
					shouldReserve
					days
				}
				researches {
					...ResearchParts
				}
			}
			chartingMedInfoTypes {
				...MedInfoTypeParts
				superTypeId
			}
			reservation {
				reservedDate
			}
			nextSchedule {
				...NextScheduleParts
			}
			conditionRefMedInfos {
				value
				medInfoTypeEntityId
			}
		}
	}
	${RECORD_FRAGMENT}
	${PATIENT_FRAGMENT}
	${RECORD_MED_INFOS}
	${RESEARCH_FRAGMENT}
	${NEXT_SCHEDULE_FRAGMENT}
`

// 기본 데이터 분류 불러오기
export const GET_MED_INFO_CATEGORIES = gql`
	query getMedInfoTypeCategories {
		medInfoCategories {
			id
			name
			color
			medInfoTypes {
				id
				name
				hasVAS
				typeName
				entityType
				inputType
				medInfoCategoryId
				superTypeId
				options {
					value
					text
				}
			}
		}
	}
`

// 연구 양식 상세정보 보기
export const GET_RECORD_TEMPLATE = gql`
	query getResearchRecordTemplate($templateId: Int!) {
		recordTemplate(templateId: $templateId) {
			id
			title
			description
			days
			daysRange
			order
			createdAt
			versionCode
			job
			isPartial
			isSchedule
			# hasECRFs
			creator {
				id
				name
				profileImage
			}
			changeCount
			medInfos {
				...RecordMedInfoParts
			}
		}
	}
	${RECORD_MED_INFOS}
`

// 연구 양식 기본정보
export const GET_RECORD_TEMPLATE_INFO = gql`
	query getResearchRecordTemplateInfo($templateId: Int!) {
		recordTemplate(templateId: $templateId) {
			id
			title
			description
			days
			daysRange
			order
			createdAt
			versionCode
			job
			isPartial
			isSchedule
			shouldReserve
			# hasECRFs
			creator {
				id
				name
				profileImage
			}
		}
	}
`

// 양식
export const GET_RECORD_TEMPLATE_RECENT_VERSION = gql`
	query getRecordTemplateRecentVersion($templateId: Int!) {
		recordTemplate(templateId: $templateId) {
			id
			versionCode
		}
	}
`

// 양식 상세정보
export const GET_RECORD_TEMPLATE_RECENT_MED_INFOS = gql`
	query getRecordTemplateRecentMedInfos($templateId: Int!) {
		recordTemplate(templateId: $templateId) {
			id
			title
			versionCode
			medInfos {
				...RecordMedInfoParts
			}
		}
	}
	${RECORD_MED_INFOS}
`

// 연구 양식 목록 보기
export const GET_RESEARCH_RECORD_TEMPLATES = gql`
	query getResearchRecordTemplates($researchId: Int!, $job: RecordTemplateJob) {
		researchRecordTemplates(researchId: $researchId, job: $job) {
			id
			title
			description
			days
			daysRange
			order
			createdAt
			job
			creator {
				id
				name
				profileImage
			}
			medInfos {
				...RecordMedInfoParts
			}
			department {
				id
				name
			}
		}
	}
	${RECORD_MED_INFOS}
`

// 연구 양식 목록 보기
export const GET_RESEARCH_RECORD_TEMPLATE_INFOS = gql`
	query getResearchRecordTemplateInfos($researchId: Int!, $job: RecordTemplateJob) {
		researchRecordTemplates(researchId: $researchId, job: $job) {
			id
			title
		}
	}
`

// 연구 양식 변경 내역 목록 보기
export const GET_RECORD_TEMPLATE_CHANGES = gql`
	query getResearchRecordTemplateChanges(
		$templateId: Int!
		$researchId: Int!
		$pagination: Pagination!
		$filter: ListFilter
	) {
		researchRecordTemplateChanges(
			researchId: $researchId
			templateId: $templateId
			pagination: $pagination
			filter: $filter
		) {
			totalCount
			items {
				id
				type
				createdAt
				message
				versionCode
				user {
					id
					name
					profileImage
				}
			}
		}
	}
`

// 양식 변경 내역 상세 보기
export const GET_RECORD_TEMPLATE_CHANGE_DETAIL = gql`
	query getRecordTemplateChangeDetail($templateId: Int!, $versionCode: Int!) {
		recordTemplateChangeDetail(templateId: $templateId, versionCode: $versionCode) {
			id
			type
			createdAt
			message
			versionCode
			medInfos {
				...RecordMedInfoParts
			}
			user {
				id
				name
				profileImage
				researcherInfo {
					code
				}
			}
		}
	}
	${RECORD_MED_INFOS}
`

// 연구 차트 기본정보 불러오기
export const GET_RESEARCH_RECORD_INFO = gql`
	query getResearchRecordInfo($recordId: Int!, $researchId: Int) {
		recordDetail(id: $recordId, researchId: $researchId) {
			record {
				...RecordParts
				creator {
					id
					name
					profileImage
					institution {
						id
						name
					}
				}
				template {
					id
					title
					type
					job
					lastChange {
						id
					}
					shouldReserve
				}
				requestCount
				changeCount
				patient {
					...ResearchPatientParts
				}
				researches {
					id
					patientBasicInfo
				}
			}
		}
	}
	${RECORD_FRAGMENT}
	${RESEARCH_PATIENT_FRAGMENT}
`

// 연구 기록 상세정보 불러오기
export const GET_RESEARCH_RECORD_DETAIL = gql`
	query getResearchRecordDetail($recordId: Int!, $researchId: Int!) {
		recordDetail(id: $recordId, researchId: $researchId) {
			record {
				...RecordParts
				patient {
					...ResearchPatientDetailParts
				}
				medInfos {
					...RecordMedInfoParts
				}
			}
			reservation {
				reservedDate
			}
			nextSchedule {
				...NextScheduleParts
			}
		}
	}
	${RECORD_FRAGMENT}
	${RESEARCH_PATIENT_DETAIL_FRAGMENT}
	${RECORD_MED_INFOS}
	${NEXT_SCHEDULE_FRAGMENT}
`

// 기록 상세정보를 양식 상세정보(MedInfo 포함)와 함께 불러오기
export const GET_RECORD_DETAIL_WITH_TEMPLATE = gql`
	query getRecordDetailWithTemplate($researchId: Int!, $recordCode: String!) {
		recordDetail(recordCode: $recordCode, researchId: $researchId) {
			record {
				...RecordParts
				medInfos {
					...RecordMedInfoParts
				}
				template {
					id
					title
					type
					lastChange {
						id
					}
					medInfos {
						...RecordMedInfoParts
					}
				}
			}
		}
	}
	${RECORD_FRAGMENT}
	${RECORD_MED_INFOS}
`

// 연구기록 상세정보를 양식 상세정보(MedInfo 포함)와 함께 불러오기
export const GET_RESEARCH_RECORD_DETAIL_WITH_TEMPLATE = gql`
	query getResearchRecordDetailWithTemplate($researchId: Int!, $recordCode: String!) {
		researchRecordDetail(recordCode: $recordCode, researchId: $researchId) {
			record {
				...RecordParts
				medInfos {
					...RecordMedInfoParts
				}
				template {
					id
					title
					type
					lastChange {
						id
					}
					medInfos {
						...RecordMedInfoParts
					}
				}
			}
		}
	}
	${RECORD_FRAGMENT}
	${RECORD_MED_INFOS}
`

// 연구 기록 목록 보기
export const GET_RESEARCH_RECORDS = gql`
	query getResearchRecords($researchId: Int!, $pagination: Pagination!, $filter: ListFilter) {
		researchRecords(researchId: $researchId, pagination: $pagination, filter: $filter) {
			totalCount
			perPage
			page
			items {
				...RecordParts
				template {
					id
					title
					type
					lastChange {
						id
					}
				}
				creator {
					id
					name
				}
				patient {
					...ResearchPatientParts
				}
				summary
			}
		}
	}
	${RECORD_FRAGMENT}
	${RESEARCH_PATIENT_FRAGMENT}
`

// 차트 기록 불러오기
export const GET_RECORD_CHANGES = gql`
	query getRecordChanges(
		$researchId: Int
		$recordId: Int!
		$pagination: Pagination!
		$filter: ListFilter
	) {
		recordChanges(
			researchId: $researchId
			recordId: $recordId
			pagination: $pagination
			filter: $filter
		) {
			totalCount
			items {
				id
				type
				createdAt
				message
				code
				user {
					id
					name
					profileImage
				}
			}
		}
	}
`

// 연구 기록 변경 내역 상세 불러오기
export const GET_RECORD_CHANGE_DETAIL = gql`
	query getRecordChangeDetail($recordChangeId: Int!, $researchId: Int) {
		recordChangeDetail(researchId: $researchId, recordChangeId: $recordChangeId) {
			id
			type
			createdAt
			message
			code
			medInfos {
				...RecordMedInfoParts
			}
			user {
				id
				name
				profileImage
				researcherInfo {
					code
				}
			}
		}
	}
	${RECORD_MED_INFOS}
`

// 조건식 불러오기
export const GET_CONDITIONS = gql`
	query getResearchConditions($researchId: Int!) {
		researchConditions(researchId: $researchId) {
			id
			value
			operator
			scope
			isRequired
			action
			message
			subjectId
			targetId
		}

		researchMedInfoTypes(researchId: $researchId) {
			id
			name
		}
	}
`
// 프로젝트 설정 연구원관리 설정 기본설정
export const GET_RESEARCHER_INFO = gql`
	query getResearcherInfo($researchId: Int!, $userId: Int!) {
		researcher(researchId: $researchId, userId: $userId) {
			...ResearcherParts
		}
	}
	${RESEARCHER_FRAGMENT}
`

// 프로젝트 설정 연구원관리 설정 기관별 권한
export const GET_RESEARCHER_INSTITUTION_PERMISSION = gql`
	query getResearcherInstitutionPermission(
		$page: Int!
		$perPage: ConstraintNumber!
		$researchId: Int!
		$userId: Int!
	) {
		researcherInstitutionPermissions(
			pagination: { page: $page, perPage: $perPage }
			researchId: $researchId
			userId: $userId
		) {
			items {
				...ResearcherInstitutionPermissionParts
			}
			totalCount
			perPage
			page
		}
	}
	${RESEARCHER_INSTITUTION_PERMISSION}
`
