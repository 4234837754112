import {
	GET_TEMPLATE_CHANGE_MODAL_PAGE,
	GET_RECORD_CHANGE_MODAL_PAGE,
} from '../../graphql/queries/change'

const TYPENAME_CHANGE_MODAL_PAGE = 'ChangeModalPage'
const TYPENAME_TEMPLATE_CHANGE_MODAL_PAGE = 'TemplateChangeModalPage'
const TYPENAME_RECORD_CHANGE_MODAL_PAGE = 'RecordChangeModalPage'

export const defaults = {
	changeModalPage: {
		template: [],
		record: [],
		__typename: TYPENAME_CHANGE_MODAL_PAGE,
	},
}

/**
 * 양식 수정 모달의 페이지를 기억
 */
export const resolvers = {
	Mutation: {
		setTemplateChangeModalPage: (_, variables, { cache, client }) => {
			const { templateId, page } = variables

			const previousState = cache.readQuery({ query: GET_TEMPLATE_CHANGE_MODAL_PAGE })

			const newPage = {
				templateId,
				page,
				__typename: TYPENAME_TEMPLATE_CHANGE_MODAL_PAGE,
			}

			const template = [
				...previousState.changeModalPage.template.filter(item => item.templateId !== templateId),
				newPage,
			]

			client.writeQuery({
				query: GET_TEMPLATE_CHANGE_MODAL_PAGE,
				data: {
					changeModalPage: {
						...previousState.changeModalPage,
						template,
					},
				},
			})

			return newPage
		},
		setRecordChangeModalPage: (_, variables, { cache, client }) => {
			const { recordId, page } = variables

			const previousState = cache.readQuery({ query: GET_RECORD_CHANGE_MODAL_PAGE })

			const newPage = {
				recordId,
				page,
				__typename: TYPENAME_RECORD_CHANGE_MODAL_PAGE,
			}

			const record = [
				...previousState.changeModalPage.record.filter(item => item.recordId !== recordId),
				newPage,
			]

			client.writeQuery({
				query: GET_RECORD_CHANGE_MODAL_PAGE,
				data: {
					changeModalPage: {
						...previousState.changeModalPage,
						record,
					},
				},
			})

			return newPage
		},
	},
}
