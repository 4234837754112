import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Query } from 'react-apollo'
import { Grid } from 'semantic-ui-react'
import { GET_MY_STATISTICS } from '../../graphql/queries/statistics'
import { StatisticsRequestType, TableStatsKeys } from '../../enum'
import LoadingView from '../common/loader'
import PageContainer from '../common/container/PageContainer'
import NumberDisplay from './NumberDisplay'
import { PAGE_CONTENT_MEDIUM_WIDTH, COLOR_PURPLE, COLOR_PINK } from '../../constants/styles'
import RatioPieChart from './RatioPieChart'
import StatItem from './StatItem'
import LegendTable from './LegendTable'
import SimpleBarChart from './SimpleBarChart'
import StatsGrid from '../common/grid/StatsGrid'
import { processSexRatio, getStatResult, processTableData } from '../../libs/statHelper'
import { TABLE_STATS_COUNT_DATA_KEY } from '../../constants'

const MARGIN = 40

const BASIC_CHART_WIDTH = (PAGE_CONTENT_MEDIUM_WIDTH - MARGIN) / 2

const BREAD_CRUMB = {
	key: 'ST_BC_DASHBOARD',
	active: true,
	content: '대시보드',
	link: false,
}

const {
	PATIENT_AVERAGE_AGE_SINGLE_VALUE,
	PAITENT_COUNT_SINGLE_VALUE,
	RECORD_COUNT_SINGLE_VALUE,
	PATIENT_GENDER_RATIO,
	PATIENT_GENERATION_RATIO,
	PRESCRIPTION_TOP_TABLE_DATA,
	SYMPTOM_TOP_TABLE_DATA,
} = StatisticsRequestType

/**
 * 통계 대시보드
 */
const StatsDashboard = props => {
	return (
		<Query
			query={GET_MY_STATISTICS}
			fetchPolicy="network-only"
			variables={{
				requests: [
					{
						type: PATIENT_AVERAGE_AGE_SINGLE_VALUE.type,
					},
					{
						type: PAITENT_COUNT_SINGLE_VALUE.type,
					},
					{
						type: RECORD_COUNT_SINGLE_VALUE.type,
					},
					{
						type: PATIENT_GENDER_RATIO.type,
					},
					{
						type: PATIENT_GENERATION_RATIO.type,
					},
					{
						type: PRESCRIPTION_TOP_TABLE_DATA.type,
					},
					{
						type: SYMPTOM_TOP_TABLE_DATA.type,
					},
				],
			}}
		>
			{({ loading, data, error, refetch }) => {
				let results

				if (data != null) {
					;({ myStatistics: results } = data)
				}
				return <StatsDashboardView {...props} results={results} loading={loading} error={error} />
			}}
		</Query>
	)
}

StatsDashboard.propTypes = {}

export default StatsDashboard

const StatsDashboardView = props => {
	const { results, loading, error, breadCrumbs } = props

	let dataGenRatio, dataSexRatio, dataPrescTop, dataSymptomTop

	if (results != null) {
		dataGenRatio = getStatResult(results, [PATIENT_GENERATION_RATIO.type])
		dataSexRatio = processSexRatio(getStatResult(results, [PATIENT_GENDER_RATIO.type]))

		dataPrescTop = processTableData(results, PRESCRIPTION_TOP_TABLE_DATA.type)
		dataSymptomTop = processTableData(results, SYMPTOM_TOP_TABLE_DATA.type)
	}

	return (
		<PageContainer
			fixed
			width={PAGE_CONTENT_MEDIUM_WIDTH}
			breadCrumbs={[...breadCrumbs, BREAD_CRUMB]}
			title="대시보드"
		>
			{loading === true && <LoadingView />}
			{results != null && (
				<Content>
					<StatsGrid relaxed>
						<Grid.Row>
							<Grid.Column>
								<NumberDisplay
									items={getStatResult(results, [
										PAITENT_COUNT_SINGLE_VALUE.type,
										RECORD_COUNT_SINGLE_VALUE.type,
										PATIENT_AVERAGE_AGE_SINGLE_VALUE.type,
									])}
								/>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row columns={2}>
							<Grid.Column>
								<StatItem
									title="성비"
									content={
										<RatioPieChart
											width={BASIC_CHART_WIDTH}
											hasLegendTable
											innerRadius={0}
											outerRadius={90}
											data={dataSexRatio.length > 0 && dataSexRatio[0].ratios}
										/>
									}
								/>
								<StatItem
									title="자주 나타난 증상"
									content={
										<SimpleBarChart
											width={BASIC_CHART_WIDTH}
											hasLegendTable
											data={dataSymptomTop}
											dataKey={TABLE_STATS_COUNT_DATA_KEY}
										/>
									}
								/>
							</Grid.Column>
							<Grid.Column>
								<StatItem
									title="연령대 비율"
									content={
										<RatioPieChart
											width={BASIC_CHART_WIDTH}
											hasLegendTable
											innerRadius={0}
											outerRadius={90}
											data={dataGenRatio.length > 0 && dataGenRatio[0].ratios}
										/>
									}
								/>
								<StatItem
									title="자주 사용한 처방"
									content={
										<SimpleBarChart
											width={BASIC_CHART_WIDTH}
											hasLegendTable
											data={dataPrescTop}
											dataKey={TABLE_STATS_COUNT_DATA_KEY}
										/>
									}
								/>
							</Grid.Column>
						</Grid.Row>
					</StatsGrid>
				</Content>
			)}
		</PageContainer>
	)
}

const Content = styled.div`
	margin-top: 60px;
`
