/* eslint-disable import/no-cycle */
/**
 * [Component]  의료정보 입력 표(Grid) 개별 행
 *
 * 표 종류에 따라 행 제거 버튼 가질 수 있음
 */

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Table, Button, Popup } from 'semantic-ui-react'

import withMedInfos from '../../../higherComponents/withMedInfos'
import DataFieldBox from './DataFieldBox'
import { COLOR_TEXT_DARKGRAY } from '../../../constants/styles'
import { InputType } from '../../../enum'

class DataFieldGridRow extends PureComponent {
	static propTypes = {
		medInfoId: PropTypes.number.isRequired,
		medInfos: PropTypes.arrayOf(),
		name: PropTypes.string.isRequired,
		medInfoIndex: PropTypes.number.isRequired,
		onOpenTypeModal: PropTypes.func.isRequired,
		templateMode: PropTypes.bool.isRequired,
		onCreateMedInfo: PropTypes.func.isRequired,
		onUpdateMedInfo: PropTypes.func.isRequired,
		onDeleteMedInfo: PropTypes.func.isRequired,
		onOpenDeleteModal: PropTypes.func.isRequired,
		onDeleteRow: PropTypes.func.isRequired,
		connectDragSource: PropTypes.func.isRequired,
		connectDropTarget: PropTypes.func.isRequired,
		indiCols: PropTypes.arrayOf().isRequired, // 행별로 개별적인 칼럼을 가짐
		addable: PropTypes.bool.isRequired, // 행 추가 제거가 가능한지 여부
		hasLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // 1열에 Row Label 포함
	}

	static defaultProps = {
		medInfos: null,
		hasLabel: false,
	}

	constructor(props) {
		super(props)

		this.state = {
			isDeletePopupOpen: false,
		}
	}

	// 필드 수정 Modal 열기
	handleOpenEditModal = params => {
		const { name, onOpenTypeModal, onUpdateMedInfo } = this.props
		onOpenTypeModal({
			parentType: InputType.GRID.value,
			title: name,
			onSubmit: onUpdateMedInfo,
			...params,
		})
	}

	render() {
		const {
			medInfos,
			templateMode,
			medInfoIndex: groupIndex,
			name,
			addable,
			hasLabel,
			indiCols,
			onDeleteRow,
			...others
		} = this.props

		const { isDeletePopupOpen } = this.state

		return (
			<React.Fragment>
				{hasLabel ? (
					<Table.Cell style={{ width: 20, textAlign: 'center', fontWeight: 600 }}>
						{name}
					</Table.Cell>
				) : null}
				{medInfos &&
					medInfos.map((medInfo, medInfoIndex) => {
						const { id, value, vasValue, optionValue, medInfoType, checkedItems } = medInfo

						return (
							<Table.Cell key={medInfo.id}>
								<DataFieldBox
									{...others}
									{...medInfoType}
									key={medInfoType.id + '-' + medInfo.id}
									medInfoIndex={medInfoIndex}
									medInfo={medInfo}
									medInfos={medInfo.children}
									groupIndex={groupIndex}
									groupId={others.medInfoId}
									parentIndex={groupIndex}
									currentRecord={medInfos}
									value={value}
									vasValue={vasValue}
									optionValue={optionValue}
									medInfoId={id}
									onOpenEditModal={this.handleOpenEditModal}
									templateMode={templateMode}
									checkedItems={checkedItems}
									gridCell
									gridCellIndi={indiCols.indexOf(medInfoIndex) >= 0}
								/>
							</Table.Cell>
						)
					})}
				{addable === true ? (
					<Table.Cell style={{ width: 20, textAlign: 'center' }}>
						<Popup
							on="click"
							position="top right"
							trigger={
								<Button
									icon="close"
									style={{
										width: 30,
										height: 30,
										padding: '5px 0px',
										margin: 'auto',
										textAlign: 'center',
										boxShadow: 'none',
										border: 'none',
										color: COLOR_TEXT_DARKGRAY,
									}}
									circular
									basic
									className="option-button"
								/>
							}
							open={isDeletePopupOpen}
							onOpen={() => this.setState({ isDeletePopupOpen: true })}
							onClose={() => this.setState({ isDeletePopupOpen: false })}
							content={
								<Button
									color="red"
									onClick={() => {
										onDeleteRow(groupIndex)
										this.setState({ isDeletePopupOpen: false })
									}}
								>
									행을 삭제하시겠습니까?
								</Button>
							}
						/>
					</Table.Cell>
				) : (
					templateMode && <Table.Cell></Table.Cell>
				)}
			</React.Fragment>
		)
	}
}

export default withMedInfos(DataFieldGridRow)
