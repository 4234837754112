import { useCallback } from 'react'

/**
 * Checkbox Input 처리를 위한 Custom Hook
 *
 *
 */
const useCheckboxInput = (onUpdate, key = 'checkedItems', checkedItems) => {
	const onUpdateChecked = useCallback(
		(value, checked) => {
			let result
			if (checked === false) {
				result = checkedItems.filter(checked => checked.value !== value)
			} else {
				result = checkedItems.concat({ value, freeWriting: '' })
			}

			onUpdate(key, result)
		},
		[checkedItems, key, onUpdate]
	)

	const onUpdateFreeWriting = useCallback(
		(value, freeWriting) => {
			const result = checkedItems.map(checkedItem => {
				if (checkedItem.value === value) {
					return {
						...checkedItem,
						freeWriting,
					}
				}

				return checkedItem
			})

			onUpdate(key, result)
		},
		[checkedItems, key, onUpdate]
	)

	return [onUpdateChecked, onUpdateFreeWriting]
}

export default useCheckboxInput
