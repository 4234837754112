import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useQuery, useMutation } from 'react-apollo'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Modal, Pagination } from 'semantic-ui-react'
import moment from 'moment'
import { cloneDeep } from 'lodash'

import ChartContent from '../../charting/ChartContent'
import {
	GET_RECORD_TEMPLATE_CHANGES,
	GET_RECORD_TEMPLATE_CHANGE_DETAIL,
	GET_RECORD_CHANGES,
	GET_RECORD_CHANGE_DETAIL,
} from '../../../graphql/queries/record'
import {
	ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_CHANGES,
	ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_CHANGE_DETAIL,
	ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL_CHANGE_DETAIL,
	ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL_CHANGES,
	ROUTE_PATIENT_DETAIL_RECORD_CHANGES,
	ROUTE_PATIENT_DETAIL_RECORD_CHANGE_DETAIL,
} from '../../../constants/routes'
import {
	GET_TEMPLATE_CHANGE_MODAL_PAGE,
	GET_RECORD_CHANGE_MODAL_PAGE,
} from '../../../graphql/queries/change'
import {
	SET_TEMPLATE_CHANGE_MODAL_PAGE,
	SET_RECORD_CHANGE_MODAL_PAGE,
} from '../../../graphql/mutations/change'
import ErrorView from '../error/ErrorView'
import LoadingView from '../loader'
import EmptyResult from '../empty/EmptyResult'
import { replaceParams, isMatchingRoutes } from '../../../libs/routeHelper'
import { RecordTemplateChangeType, RecordChangeType } from '../../../enum'
import UserInline from '../UserInline'
import DescriptionText from '../basic/DescriptionText'
import { toCommonDateTimeString } from '../../../libs/dateHelper'
import { assembleMedInfos } from '../../../libs/medInfoHelper'
import PageContainer from '../container/PageContainer'
import { PAGE_CONTENT_FIXED_WIDTH, PAGE_CONTENT_MAX_WIDTH } from '../../../constants/styles'

const ObjectType = {
	TEMPLATE: {
		key: 'template',
		id: 'templateId',
		value: 'TEMPLATE',
		text: '양식',
		queries: {
			default: GET_RECORD_TEMPLATE_CHANGES,
			detail: GET_RECORD_TEMPLATE_CHANGE_DETAIL,
			page: GET_TEMPLATE_CHANGE_MODAL_PAGE,
		},
		mutations: {
			page: SET_TEMPLATE_CHANGE_MODAL_PAGE,
		},
		data: {
			default: 'researchRecordTemplateChanges',
			detail: 'recordTemplateChangeDetail',
		},
	},
	RECORD: {
		key: 'record',
		id: 'recordId',
		value: 'RECORD',
		text: '기록',
		queries: {
			default: GET_RECORD_CHANGES,
			detail: GET_RECORD_CHANGE_DETAIL,
			page: GET_RECORD_CHANGE_MODAL_PAGE,
		},
		mutations: {
			page: SET_RECORD_CHANGE_MODAL_PAGE,
		},
		data: {
			default: 'recordChanges',
			detail: 'recordChangeDetail',
		},
	},
}

const PER_PAGE = 10

/**
 * 양식 수정 내역 모달
 */
const ChangeModal = props => {
	const { id, researchId, open, onClose, objectType, isOnResearchPage } = props
	const history = useHistory()
	const params = useParams()
	const { pathname } = useLocation()

	const object = ObjectType[objectType]

	const route = useMemo(() => {
		if (objectType === ObjectType.TEMPLATE.value) {
			return {
				default: replaceParams(ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_CHANGES, params),
				detail: replaceParams(ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_CHANGE_DETAIL, params),
			}
		} else {
			if (isOnResearchPage === true) {
				return {
					default: replaceParams(
						ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL_CHANGES,
						params
					),
					detail: replaceParams(
						ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL_CHANGE_DETAIL,
						params
					),
				}
			} else {
				return {
					default: replaceParams(ROUTE_PATIENT_DETAIL_RECORD_CHANGES, params),
					detail: replaceParams(ROUTE_PATIENT_DETAIL_RECORD_CHANGE_DETAIL, params),
				}
			}
		}
	}, [isOnResearchPage, objectType, params])

	const {
		data: { changeModalPage },
	} = useQuery(object.queries.page)
	const bookmark = useMemo(() => changeModalPage[object.key].find(item => item[object.id] === id), [
		object,
		changeModalPage,
		id,
	])
	const { page = 1 } = useMemo(() => bookmark || {}, [bookmark])

	const { data, loading, error } = useQuery(object.queries.default, {
		variables: {
			researchId,
			[object.id]: id,
			pagination: { perPage: PER_PAGE, page },
		},
		fetchPolicy: 'network-only',
	})
	const [setClientStatePage] = useMutation(object.mutations.page)
	const isDetail = useMemo(() => isMatchingRoutes(pathname, [route.detail]), [route, pathname])

	const handlePage = useCallback(
		(e, { activePage }) => {
			setClientStatePage({
				variables: {
					[object.id]: id,
					page: activePage,
				},
			})
		},
		[setClientStatePage, id, object]
	)

	const { [object.data.default]: { items, totalCount = 0 } = {} } = data || {}

	const content = useMemo(() => {
		if (error != null) {
			return <ErrorView />
		} else if (loading === true) {
			return <LoadingView />
		} else if (totalCount === 0) {
			return <EmptyResult message="항목이 없습니다." />
		} else {
			return (
				<>
					{items.map(change => (
						<ChangeRow
							key={change.id}
							message={change.message}
							versionCode={
								objectType === ObjectType.RECORD.value
									? RecordChangeType[change.type].text
									: change.versionCode
							}
							type={change.type}
							user={change.user}
							createdAt={change.createdAt}
							onClick={() => {
								history.replace(
									replaceParams(route.detail, {
										changeId: change.versionCode != null ? change.versionCode : change.id,
									})
								)
							}}
						/>
					))}
					<PaginationWrapper>
						<Pagination
							activePage={page}
							totalPages={Math.ceil(totalCount / PER_PAGE) || 1}
							onPageChange={handlePage}
						/>
					</PaginationWrapper>
				</>
			)
		}
	}, [error, loading, handlePage, history, items, page, totalCount, route, objectType])

	return (
		<>
			<Modal closeIcon open={open} onClose={onClose}>
				<Modal.Header>
					{object.text} 수정 내역 <SubTitle inline>2차 조사</SubTitle>
				</Modal.Header>
				<Modal.Content scrolling>{content}</Modal.Content>
			</Modal>
			{isDetail && (
				<ChangeDetailModal
					{...props}
					open={isDetail}
					templateId={id}
					onClose={() => {
						history.replace(route.default)
					}}
					object={object}
				/>
			)}
		</>
	)
}

ChangeModal.propTypes = {
	id: PropTypes.number.isRequired,
	researchId: PropTypes.number,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	objectType: PropTypes.string.isRequired,
	isOnResearchPage: PropTypes.bool,
}

export default ChangeModal

/**
 * 양식 수정 내역 필드
 */
const ChangeRow = ({ type, createdAt, message, user, versionCode, onClick }) => {
	return (
		<RowWrapper onClick={onClick} cursor="pointer">
			<VersionCode type={type}># {versionCode}</VersionCode>
			<Message>{message}</Message>
			<UserInline profileImage={user.profileImage} name={user.name} />
			<RowDate inline>{moment(createdAt).calendar()}</RowDate>
		</RowWrapper>
	)
}

ChangeRow.propTypes = {
	type: PropTypes.string,
	createdAt: PropTypes.string,
	message: PropTypes.string,
	user: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		profileImage: PropTypes.string,
	}),
	versionCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	onClick: PropTypes.func,
}

/**
 * 양식 수정 상세 보기
 */
const ChangeDetailModal = props => {
	const { templateId, open, onClose, object } = props
	const params = useParams()
	const { changeId } = params
	const { data, loading, error } = useQuery(object.queries.detail, {
		variables: {
			templateId,
			versionCode: parseInt(changeId),
			recordChangeId: parseInt(changeId),
		},
	})

	const {
		[object.data.detail]: {
			medInfos,
			type = RecordTemplateChangeType.UPDATE.key,
			message,
			createdAt,
			user: { profileImage = '', name = '' } = {},
		} = {},
	} = data || {}

	return (
		<DetailModal closeIcon size="fullscreen" open={open} onClose={onClose}>
			<Modal.Header>
				{object.text} 수정 내역 <SubTitle inline>2차 조사</SubTitle>
			</Modal.Header>
			<Modal.Description>
				<RowWrapper height="78px">
					<VersionCode type={type} marginLeft>
						# {object.value === ObjectType.RECORD.value ? RecordChangeType[type].text : changeId}
					</VersionCode>
					<Message vertical bold>
						{message}
						<DetailDate>{toCommonDateTimeString(createdAt)}</DetailDate>
					</Message>
					<UserInline profileImage={profileImage} name={name} marginRight />
				</RowWrapper>
			</Modal.Description>
			<Modal.Content scrolling>
				{error != null ? (
					<ErrorView />
				) : loading === true ? (
					<LoadingView />
				) : (
					<PageContainer
						minMax
						minWidth={PAGE_CONTENT_FIXED_WIDTH}
						maxWidth={PAGE_CONTENT_MAX_WIDTH}
					>
						<ChartContent
							{...props}
							templateMode={false}
							isFixed
							readOnly
							medInfos={assembleMedInfos(cloneDeep(medInfos))}
							isForModal
						/>
					</PageContainer>
				)}
			</Modal.Content>
		</DetailModal>
	)
}

ChangeDetailModal.propTypes = {
	researchId: PropTypes.number,
	templateId: PropTypes.number,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	object: PropTypes.shape({
		data: PropTypes.shape({
			detail: PropTypes.string,
		}),
		text: PropTypes.string,
		value: PropTypes.string,
		queries: PropTypes.shape({
			detail: PropTypes.any,
		}),
	}),
}

/**
 * Styles
 */

const SubTitle = styled.div`
	display: inline-block;
	margin-left: 10px;
	font-weight: 300;
	font-size: 14px;
	color: ${props => props.theme.COLOR_TEXT_LIGHTGRAY};
`

const PaginationWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 50px;
`

const RowWrapper = styled.div`
	display: flex;
	align-items: center;
	height: ${props => props.height || '50px'};
	border-bottom: ${props => props.theme.BORDER_GRAY};
	padding: 20px;
	cursor: ${props => props.cursor || 'auto'};
	:hover {
		background: #f1f1f1;
	}
`

const VersionCode = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 50px;
	padding: 0 10px;
	height: 20px;
	background-color: ${props => RecordTemplateChangeType[props.type].color};
	color: #ffffff;
	font-size: 12px;
	margin-left: ${props => props.marginLeft === true && 77}px;
	border-radius: 2px;
`

const Message = styled.div`
	display: flex;
	color: ${props => props.theme.COLOR_TEXT};
	margin-left: 14px;
	margin-right: auto;
	font-size: 16px;
	flex-direction: ${props => (props.vertical === true ? 'column' : 'row')};
	font-weight: ${props => props.bold && 'bold'};
`

const RowDate = styled(DescriptionText)`
	width: 80px;
	margin-top: 1px;
	margin-bottom: 1px;
	margin-right: 16px;
	text-align: right;
`

const DetailModal = styled(Modal)`
	height: 80vh;
	top: 30px;
`

const DetailDate = styled(DescriptionText)`
	margin: 3px 0;
`
