import { useState } from 'react'

const useInputText = initial => {
	const [state, setstate] = useState(initial)

	const onChange = (e, { value }) => setstate(value)

	return [state, onChange]
}

export default useInputText
