import React, { PureComponent, createRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Button, Segment, Ref } from 'semantic-ui-react'
import 'moment/locale/ko'

import ChartingBoard from './ChartingBoard'
import RecordEditModal from '../record/RecordEditModal'

import { TemplateType, TemplateJob, CommentEntityType } from '../../enum'

import { FIXED_CONTAINER_MIN_WIDTH, FIXED_CONTAINER_MAX_WIDTH } from '../../constants'
import withChartEditor from '../../higherComponents/withChartEditor'
import PageContainer from '../common/container/PageContainer'
import { COLOR_PRIMARY, COLOR_TEXT_LIGHTGRAY } from '../../constants/styles'
import TemplateSaveModal from '../template/modals/TemplateSaveModal'
import MyIcon from '../common/basic/MyIcon'
import Comments from '../../containers/Comments'

/**
 * 차트 뷰
 */
class ChartView extends PureComponent {
	static propTypes = {
		template: PropTypes.shape(),
		visitedDate: PropTypes.string.isRequired,
		recordId: PropTypes.number.isRequired,
		onSaveChart: PropTypes.func.isRequired,
		onSaveTemplate: PropTypes.func.isRequired,
		onCreateMedInfo: PropTypes.func,
		onDeleteMedInfo: PropTypes.func.isRequired,
		onUpdateMedInfo: PropTypes.func.isRequired,
		fixedFields: PropTypes.arrayOf(PropTypes.shape({})),
		medInfos: PropTypes.arrayOf().isRequired,
		researches: PropTypes.arrayOf(PropTypes.shape({})),
		isNewRecord: PropTypes.bool.isRequired,
		readOnly: PropTypes.bool.isRequired,
		noEdit: PropTypes.bool.isRequired,
		onSubmit: PropTypes.func.isRequired,
		onToggleEditMode: PropTypes.func.isRequired,
		saveLoading: PropTypes.bool.isRequired,
		onChangeDate: PropTypes.func.isRequired,
		onPrint: PropTypes.func,
		anchorOpenComment: PropTypes.number,
		isForModal: PropTypes.bool,
	}

	headerStickyRef = createRef()

	chartingBoardRef = createRef()

	static defaultProps = {
		template: null,
		fixedFields: null,
		researches: null,
		isForModal: false,
	}

	constructor(props) {
		super(props)

		this.state = {
			templateTitle: '',
			isTemplateModalOpen: false,
			isInfoEditModalOpen: false,
		}

		this.isScreening = props.template && props.template.job === TemplateJob.SCREENING.key
	}

	handleTemplateTitleChange = (e, { value }) => {
		this.setState({
			templateTitle: value,
		})
	}

	handleSaveTemplate = async () => {
		const { onSaveChart } = this.props
		const { templateTitle } = this.state

		await onSaveChart({ title: templateTitle, template: true })
		this.setState({
			templateTitle: '',
			isTemplateModalOpen: false,
		})
	}

	handleOpenTemplateModal = () => this.setState({ isTemplateModalOpen: true })

	handleCloseTemplateModal = () => this.setState({ isTemplateModalOpen: false })

	handleOpenInfoEditModal = () => this.setState({ isInfoEditModalOpen: true })

	handleCloseInfoEditModal = () => this.setState({ isInfoEditModalOpen: false })

	render() {
		const { isTemplateModalOpen, isInfoEditModalOpen } = this.state
		const {
			researches,
			medInfos,
			template,
			readOnly,
			// noEdit,
			onSubmit,
			// onToggleEditMode,
			visitedDate,
			saveLoading,
			onChangeDate,
			recordId,
			onPrint,
			anchorOpenComment,
			isForModal,
		} = this.props

		const isFixed = template && template.type === TemplateType.FIXED.value

		const research = researches && researches.length > 0 ? researches[0] : null
		const containerOptions =
			isForModal !== true
				? {
						minMax: true,
				  }
				: {
						fluid: true,
						padded: false,
				  }

		return (
			<Ref innerRef={this.headerStickyRef}>
				<PageContainer {...containerOptions}>
					<HeaderTools>
						<IconTools>
							<PrintIcon name="print" onClick={() => onPrint()} />
						</IconTools>
						<SaveButton>
							<Button size="big" primary onClick={onSubmit} loading={saveLoading}>
								저장하기
							</Button>
						</SaveButton>
					</HeaderTools>
					{/* <Segment style={{ padding: '1.3em' }}> */}
					{/* <Sticky offset={15}> */}
					{/* <Title>
						<MainTitle>
							<UpperTitle>
								{template && <TemplateTitle>{template.title}</TemplateTitle>}
								<DateForm>
									<Form.Field inline className="tight-input vertical-align-middle">
										<label style={styles.dateLabel}>진료일시</label>
										<DateInput
											inline
											locale="ko"
											selected={visitedDate && moment(visitedDate)}
											showMonthDropdown
											showYearDropdown
											onChangeRaw={event => {
												onChangeDate(event.target.value)
											}}
											showTimeSelect
											scrollableYearDropdown
											disable
											placeholderText="클릭해서 날짜선택"
											dateFormat="YYYY-MM-DD HH:mm"
											dropdownMode="select"
											dateFormatCalendar="YYYY MMMM"
											onChange={onChangeDate}
											maxDate={moment()}
										/>
									</Form.Field>
								</DateForm>
							</UpperTitle>
							{research != null && <ResearchTitle>{research.title}</ResearchTitle>}
						</MainTitle>
						<ChartInfoTools>
							<PrintIcon name="print" onClick={() => onPrint()} />
						</ChartInfoTools>
						<HeaderTools>
							<SaveButton>
								<Button size="big" color="primary" onClick={onSubmit} loading={saveLoading}>
									저장하기
								</Button>
							</SaveButton>
						</HeaderTools>
					</Title> */}
					{/* <div style={styles.wrapper}>
						<div style={styles.templateInfo}>
							{titleStyle === 'research' && (
								
								<TemplateTitle>

								</TemplateTitle>
								<div style={styles.inlineBlock}>
									{research.title}
									
								</div>
							)}
							{titleStyle === 'template' && (
								<div style={styles.inlineBlock}>
									<div style={styles.imageWrap}>
										<Image src="/images/clipboard.png" style={{ height: 35, marginLeft: 10 }} />
									</div>
									{template.title}
								</div>
							)}
						</div>
						<div style={{ marginTop: 5 }}>
							
						</div>
					</div> */}
					{/* <Button style={styles.headerButton} loading={saveLoading} primary onClick={onSubmit}>
						변경 사항 저장
					</Button> */}
					{/* </Segment> */}
					<div
						style={{
							minWidth: FIXED_CONTAINER_MIN_WIDTH,
							maxWidth: FIXED_CONTAINER_MAX_WIDTH,
							margin: 'auto',
							minHeight: 500,
							marginBottom: 100,
						}}
					>
						<ChartingBoard
							medInfos={medInfos}
							researches={researches}
							isFixed={isFixed}
							readOnly={readOnly}
							{...this.props}
						/>
						{isForModal !== true && (
							<BottomSaveButton>
								<Button size="big" primary onClick={onSubmit} loading={saveLoading}>
									저장하기
								</Button>
							</BottomSaveButton>
						)}
					</div>
					{/* {researches.length === 0 ? (
					<FixedFooter
						buttonsLeft={[
							{
								text: '템플릿 새로 저장',
								props: {
									onClick: this.openTemplateModal,
								},
							},
						]}
						buttonsRight={[
							{
								text: '진료 기록 저장',
								props: {
									loading: saveLoading,
									primary: true,
									onClick: onSubmit,
								},
							},
						]}
					/>
				) : (
					<FixedFooter
						buttonsLeft={
							!readOnly
								? [
										{
											text: !isNewRecord ? '수정 중' : '작성 중',
											icon: 'pencil',
											props: {
												basic: true,
												color: 'black',
												style: {
													fontWeight: '600',
												},
											},
										},
								  ]
								: [
										{
											text: '열람 중',
											icon: 'eye',
											props: {
												basic: true,
												color: 'black',
												style: {
													fontWeight: '600',
												},
											},
										},
								  ]
						}
						buttonsRight={
							!isNewRecord
								? [
										...(!readOnly && [
											{
												text: '취소',
												props: {
													onClick: onToggleEditMode,
												},
											},
										]),
										...(!noEdit && [
											{
												text: readOnly ? '수정하기' : '변경 사항 저장',
												props: {
													color: 'teal',
													onClick: onSubmit,
													loading: saveLoading,
												},
												...(readOnly && {
													icon: 'pencil',
												}),
											},
										]),
								  ]
								: [
										{
											text: isScreening ? '저장 후 진행' : '기록 저장',
											props: {
												color: 'teal',
												onClick: onSubmit,
												loading: saveLoading,
												style: {
													padding: '11px 28px',
												},
											},
										},
								  ]
						}
					/> */}
					{/* )} */}
					<Comments
						useNick={false}
						entityType={CommentEntityType.RECORD.value}
						entityId={recordId}
						anchorOpenComment={anchorOpenComment}
					/>
					{!isFixed && (
						<TemplateSaveModal
							open={isTemplateModalOpen}
							onChangeTitle={this.handleTemplateTitleChange}
							onClose={this.handleCloseTemplateModal}
							onSave={this.handleSaveTemplate}
						/>
					)}
					<RecordEditModal
						open={isInfoEditModalOpen}
						recordId={recordId}
						onClose={this.handleCloseInfoEditModal}
						visitedDate={visitedDate}
					/>
				</PageContainer>
			</Ref>
		)
	}
}

export default withChartEditor(ChartView)

/**
 * Styled Components
 */

const Title = styled(Segment)`
	display: flex;
	width: 100%;
	padding: 20px 40px !important;
`

const TemplateTitle = styled.div`
	font-size: 18px;
	font-weight: 700;
	display: inline-block;
	margin-right: 20px;
	vertical-align: middle;
`

const ResearchTitle = styled(Link)`
	color: ${COLOR_PRIMARY};
`

const MainTitle = styled.div`
	/* display: ; */
	flex: 1;
`

const UpperTitle = styled.div`
	margin-bottom: 10px;
`
const DateForm = styled.div`
	display: inline-block;
	vertical-align: middle;
`

const SaveButton = styled.div`
	/* display: table-cell; */
	display: inline-block;
	> .ui.button {
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
		width: 150px;
	}
`

const BottomSaveButton = styled.div`
	text-align: right;
	margin-top: 20px;
`
const HeaderTools = styled.div`
	position: fixed;
	top: 12px;
	right: 120px;
	display: flex;
`

const PrintIcon = styled(MyIcon)``

const IconTools = styled.div`
	margin-right: 50px;
	display: flex;
	align-items: flex-end;
`

const styles = {
	container: {
		minWidth: 1024,
	},
	wrapper: {
		paddingLeft: 20,
		paddingTop: 10,
	},
	dateLabel: {
		verticalAlign: 'middle',
		display: 'inline-block',
		fontSize: 12,
		marginRight: 5,
		color: COLOR_TEXT_LIGHTGRAY,
	},
	dateInput: {
		verticalAlign: 'middle',
	},
	header: {
		position: 'relative',
		background: '#F6F6F6',
		padding: '10px 25px',
		paddingTop: 20,
		margin: -10,
		marginBottom: 30,
	},
	templateInfo: {
		fontSize: 20,
		fontWeight: 600,
	},
	nullCell: {
		display: 'inline-block',
		verticalAlign: 'top',
		marginRight: 10,
		marginLeft: 10,
	},
	nullText: {
		display: 'inline-block',
		verticalAlign: 'top',
		marginLeft: 10,
	},
	recBtn: {
		float: 'right',
		marginTop: -4,
		verticalAlign: 'top',
	},
	recBtnCirc: {
		float: 'right',
		marginTop: -4,
		verticalAlign: 'top',
		fontSize: '1rem',
		boxShadow: 'none',
	},
	recordInfo: {
		fontWeight: '600',
		marginRight: 20,
		color: '#333',
		fontSize: 16,
	},
	imageWrap: {
		display: 'inline-block',
		height: 40,
		width: 40,
		verticalAlign: 'middle',
		marginRight: 10,
	},
	patientInfo: {
		marginRight: 20,
	},
	headerButton: {
		position: 'absolute',
		right: 20,
		top: '50%',
		padding: '11px 28px',
		marginTop: -20,
	},
	test: {
		fontWeight: 600,
		color: '#FF7777',
	},
	inlineBlock: {
		display: 'inline-block',
		marginRight: 23,
	},
	date: {
		display: 'inline-block',
		verticalAlign: 'middle',
	},
	regularFill: {
		height: 40,
		paddingLeft: 6,
		paddingTop: 5,
	},
	calendar: {
		verticalAlign: 'middle',
	},
}
