import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import compose from 'lodash.flowright'
import { graphql, withApollo } from 'react-apollo'
import { Transition, Message } from 'semantic-ui-react'
import moment from 'moment'

import ChartView from '../components/charting/ChartView'
import LoadingView from '../components/common/loader'

import { TemplateType, RecordType } from '../enum'
import { GET_RECORD_DETAIL } from '../graphql/queries/record'
import {
	UPDATE_RECORD_MED_INFOS,
	CREATE_RECORD_TEMPLATE,
	UPDATE_RECORD_INFO,
} from '../graphql/mutations/record'
import ErrorView from '../components/common/error/ErrorView'
import DropRecordView from '../components/record/DropRecordView'
import { GET_PATIENT_DETAIL_BY_ID } from '../graphql/queries/patient'
import { getConditions } from '../libs/conditionHelper'

const styles = {
	message: {
		position: 'fixed',
		bottom: 0,
		zIndex: 100,
		width: '100%',
		marginBottom: 0,
	},
}

const fixedFields = [
	{
		id: 1,
		name: '이름',
		dataType: 'name',
		color: 'grey',
	},
	{
		id: 2,
		name: '생년월일',
		dataType: 'birthDate',
		color: 'grey',
	},
	{
		id: 3,
		name: '성별',
		dataType: 'sexMale',
		color: 'grey',
	},
	{
		id: 4,
		name: '병록(차트)번호',
		dataType: 'patientCode',
		color: 'grey',
	},
]

class Chart extends PureComponent {
	static propTypes = {
		history: PropTypes.shape({
			push: PropTypes.func,
		}).isRequired,
		match: PropTypes.shape({
			params: PropTypes.shape({
				subId: PropTypes.string,
			}).isRequired,
		}).isRequired,
		patient: PropTypes.shape({}),
		currentUser: PropTypes.shape({
			id: PropTypes.number,
		}),
		createRecordTemplate: PropTypes.func.isRequired,
		updateRecordInfo: PropTypes.func.isRequired,
		updatePatient: PropTypes.func.isRequired,
		updateRecordMedInfos: PropTypes.func.isRequired,
		loading: PropTypes.bool,
		record: PropTypes.shape({
			id: PropTypes.number,
			patient: PropTypes.shape({
				id: PropTypes.number,
			}),
			creator: PropTypes.shape({
				id: PropTypes.number,
				institution: PropTypes.shape({
					id: PropTypes.number,
				}),
			}),
			medInfos: PropTypes.arrayOf(PropTypes.shape({})),
			researches: PropTypes.arrayOf(PropTypes.shape({})),
			isNewRecord: PropTypes.bool,
			template: PropTypes.shape({
				type: PropTypes.string,
			}),
			visitedDate: PropTypes.string,
			type: PropTypes.string,
		}),
		chartingMedInfoTypes: PropTypes.arrayOf(),
		conditionRefMedInfos: PropTypes.arrayOf(PropTypes.shape({})),
		reservation: PropTypes.shape({}),
		nextSchedule: PropTypes.shape({}),
		error: PropTypes.object,
		refetch: PropTypes.func,
		patientId: PropTypes.number,
	}

	constructor(props) {
		super(props)

		this.state = {
			isTemplateSaveSuccess: false,
			savedTemplateTitle: '',
			message: null,
		}
	}

	handleSaveChart = async (medInfos, options) => {
		const { history, patientId, updateRecordMedInfos, record, refetch } = this.props

		this.handleMessage({
			success: true,
			content: '저장이 완료되었습니다.',
			autoDismiss: true,
		})

		try {
			if (options) {
				const { onFinish } = options
				//스크리닝일 경우
				if (options.screeningValid !== undefined) {
					updateRecordMedInfos({
						variables: {
							recordId: record.id,
							medInfos,
							originalMedInfos: options.originalMedInfos,
							screeningValid: options.screeningValid,
							screeningFailReason: options.screeningFailReason,
						},
						refetchQueries: [
							{
								query: GET_PATIENT_DETAIL_BY_ID,
								variables: {
									id: patientId,
									researchId: null,
								},
							},
						],
						update: async (cache, { data: { updateRecordMedInfos: record } }) => {
							const { id } = record
							if (options.screeningValid === true) {
								history.push(`/patients/${record.patient.id}/records/${id}`)
							}

							if (onFinish) onFinish()
						},
					})

					// await getRecordDetail.refetch()

					// 예약일 경우
				} else if (options.nextDate) {
					try {
						await updateRecordMedInfos({
							variables: {
								recordId: record.id,
								medInfos,
								originalMedInfos: options.originalMedInfos,
								nextDate: options.nextDate,
							},
							update: () => {
								// if (refetch != null) {
								// 	refetch()
								// }
								if (onFinish) onFinish()
							},
						})
						// history.push('/patients/' + record.patient.id)
					} catch (e) {
						alert('저장 중 오류가 발생했습니다. 담당자에게 문의 바랍니다.')
					}
				} else {
					// 기타
					await updateRecordMedInfos({
						variables: {
							recordId: record.id,
							medInfos,
							originalMedInfos: options.originalMedInfos,
						},
					})
					// await refetch()
					if (onFinish) onFinish()
					// history.push('/patients/' + record.patient.id)
				}
			} else {
				//?
				await updateRecordMedInfos({ variables: { recordId: record.id, medInfos } })
				// history.push('/patients/' + record.patient.id)

				await refetch()
			}
		} catch (e) {
			alert('저장중 오류가 발생했습니다. 관리자에게 문의해주세요.')
		}
	}

	handleSaveTemplate = async ({ title }, medInfos) => {
		const { createRecordTemplate } = this.props

		await createRecordTemplate({ variables: { medInfos, title } })
		this.setState({
			isTemplateSaveSuccess: true,
			savedTemplateTitle: title,
		})
	}

	handleMessage = options => {
		this.setState({ message: options })
		if (options.autoDismiss) {
			setTimeout(() => this.setState({ message: null }), 3000)
		}
	}

	handleDismissMessage = () => {
		this.setState({ isTemplateSaveSuccess: false, savedTemplateTitle: '' })
	}

	handleChangeDate = async item => {
		const { match, updateRecordInfo, recordId } = this.props

		await updateRecordInfo({
			variables: {
				recordId: recordId,
				recordInfo: {
					visitedDate: moment(item).format('YYYY-MM-DD HH:mm'),
				},
			},
		})

		this.handleMessage({
			content: '진료일시를 변경하였습니다.',
			success: true,
			autoDismiss: true,
		})
	}

	render() {
		const { isTemplateSaveSuccess, savedTemplateTitle, message } = this.state
		const {
			currentUser,
			record,
			chartingMedInfoTypes,
			conditionRefMedInfos,
			reservation,
			nextSchedule,
			loading,
			error,
		} = this.props

		if (error != null) {
			return <ErrorView error={error} />
		} else if (loading === true || record == null) {
			return <LoadingView />
		}

		const isFixed = record && (record.template && record.template.type === TemplateType.FIXED.value)

		const userId = currentUser != null ? currentUser.id : null

		const noEdit = record.creator && record.creator.id !== userId

		if (record.type === RecordType.DROP.value) {
			return <DropRecordView {...record} />
		}

		return (
			<div>
				<ChartView
					{...this.props}
					patient={record.patient}
					fixedFields={fixedFields}
					recordId={record.id}
					medInfos={record.medInfos}
					researches={record.researches}
					template={record.template}
					reservation={reservation}
					nextSchedule={nextSchedule}
					visitedDate={record.visitedDate}
					isNewRecord={record.isNewRecord}
					conditions={getConditions(record.medInfos)}
					conditionRefMedInfos={conditionRefMedInfos}
					onSaveChart={this.handleSaveChart}
					noEdit={noEdit}
					onChangeDate={this.handleChangeDate}
					onSaveTemplate={this.handleSaveTemplate}
					{...(!isFixed && {
						medInfoTypes: chartingMedInfoTypes,
					})}
					creator={record.creator}
					institution={record.creator && record.creator.institution}
					useNick={record.researches.length <= 0}
				/>
				{isTemplateSaveSuccess && (
					<Message success style={styles.message} onDismiss={this.handleDismissMessage}>
						<div>
							<Message.Header>새로운 템플릿이 저장되었습니다.</Message.Header>
							<p>
								&lt;{savedTemplateTitle}&gt; 템플릿이 생성되었습니다. 이후 새로운 진료기록을 생성할
								때 저장된 양식을 한번에 불러올 수 있습니다.
							</p>
						</div>
					</Message>
				)}
				{message && (
					<Transition visible={message ? true : false} animation="scale" duration={500}>
						<Message
							style={{
								position: 'fixed',
								bottom: 100,
								left: '50%',
							}}
							floating
							compact
							{...message}
						/>
					</Transition>
				)}
			</div>
		)
	}
}

export default compose(
	withApollo,
	withRouter,
	graphql(GET_RECORD_DETAIL, {
		options: props => ({
			variables: {
				id: props.recordId || parseInt(props.match.params.subId, 10),
			},
			fetchPolicy: 'network-only',
		}),
		// name: 'getRecordDetail',
		props: ({ data: { recordDetail, loading, error, refetch }, ownProps: { headerRef } }) => {
			const { record: { id } = {} } = recordDetail || {}

			return {
				loading,
				error,
				...recordDetail,
				recordId: id,
				refetch,
			}
		},
	}),
	graphql(UPDATE_RECORD_MED_INFOS, {
		name: 'updateRecordMedInfos',
	}),
	graphql(CREATE_RECORD_TEMPLATE, {
		name: 'createRecordTemplate',
	}),
	graphql(UPDATE_RECORD_INFO, {
		name: 'updateRecordInfo',
	})
)(Chart)
