import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {
	ROUTE_PROJECT_DETAIL_SETTINGS_MEMBERS_DETAIL_DEFAULT,
	ROUTE_PROJECT_DETAIL_SETTINGS_MEMBERS,
} from '../constants/routes'
import ResearchSettingsMemebersList from './ResearchSettingsMemebersList'
import ResearchMemberSetting from '../components/research/settings/members/ResearchMemberSetting'

const ResearchSettingsMemebers = props => {
	return (
		<Switch>
			<Route
				path={ROUTE_PROJECT_DETAIL_SETTINGS_MEMBERS_DETAIL_DEFAULT}
				component={p => <ResearchMemberSetting {...props} {...p} />}
			/>
			<Route
				exact
				path={ROUTE_PROJECT_DETAIL_SETTINGS_MEMBERS}
				component={p => <ResearchSettingsMemebersList {...props} {...p} />}
			/>
		</Switch>
	)
}

export default ResearchSettingsMemebers
