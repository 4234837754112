import { DEPARTMENT_SECTION, PATIENT_SECTION, PROJECT_SECTION } from './PatientSurveySection'

import PatientSurveyButton from './PatientSurveyButton'
import { PatientSurveySectionTitle } from '../common/basic/StyledTexts'
import React from 'react'
import ResearchTemplateSelectView from './ResearchTemplateSelectView'
import styled from 'styled-components'

const PatientSurveyTemplate = ({
	researchId,
	researchCount,
	selectedTemplate,
	setSelectedTemplate,
	handleSection,
	isDepartment,
}) => (
	<Container>
		<PatientSurveySectionTitle>환자 설문지를 선택하세요.</PatientSurveySectionTitle>
		{researchCount > 1 ? (
			<PatientSurveyButton
				direction="right"
				click={() => {
					handleSection(PROJECT_SECTION)
				}}
			>
				뒤로가기
			</PatientSurveyButton>
		) : (
			<PatientSurveyButton
				direction="right"
				click={() => {
					handleSection(DEPARTMENT_SECTION)
				}}
			>
				진료과 변경하기
			</PatientSurveyButton>
		)}
		<ResearchTemplateSelectView
			selectedTemplate={selectedTemplate}
			selectTemplate={template => {
				setSelectedTemplate(template)
				handleSection(PATIENT_SECTION)
			}}
			researchId={researchId}
			isDepartment={isDepartment}
		/>
	</Container>
)

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`

export default PatientSurveyTemplate
