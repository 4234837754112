import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Button } from 'semantic-ui-react'
import SelectOptionItemForm from './SelectOptionItemForm'
import SelectOptionItemFormNum from './SelectOptionItemFormNum'

const styles = {
	formGroup: {
		marginTop: 5,
	},
	cornerButton: {
		position: 'absolute',
		right: 5,
		top: 7,
	},
}

const SelectOptionItem = props => {
	const { number, index, type, onDelete } = props
	return (
		<React.Fragment>
			{number === true ? (
				<SelectOptionItemFormNum {...props} />
			) : (
				<SelectOptionItemForm {...props} />
			)}
			<Button
				onClick={onDelete(type)(index)}
				compact
				style={styles.cornerButton}
				circular
				icon
				size="tiny"
			>
				<Icon name="remove" />
			</Button>
		</React.Fragment>
	)
}

SelectOptionItem.propTypes = {
	number: PropTypes.bool,
	text: PropTypes.string,
	index: PropTypes.number,
	onChange: PropTypes.func,
	type: PropTypes.string,
	value: PropTypes.string,
	hasText: PropTypes.bool,
	onDelete: PropTypes.func,
}

export default SelectOptionItem
