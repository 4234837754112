import React from 'react'
import PropTypes from 'prop-types'

import { Legend, Tooltip, PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'
import { CHART_COLORS } from '../../constants/styles'
import LegendTable from './LegendTable'

const RADIAN = Math.PI / 180

const PADDING_HEIGHT = 40
const PADDING_TABLE = 30

const renderCustomizedLabel = ({
	cx,
	cy,
	midAngle,
	innerRadius,
	outerRadius,
	percent,
	payload,
}) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5
	const x = cx + radius * Math.cos(-midAngle * RADIAN) * 2
	const y = cy + radius * Math.sin(-midAngle * RADIAN) * 2

	return (
		<text
			x={x}
			y={y}
			textAnchor={x > cx ? 'start' : 'end'}
			style={{ color: '#8884d8' }}
			dominantBaseline="central"
		>
			{`${payload.name} ${(percent * 100).toFixed(0)}%(${payload.value})`}
		</text>
	)
}

const RatioPieChart = props => {
	const { data, width, innerRadius, outerRadius, hasLegendTable } = props
	let height = outerRadius * 2 + PADDING_HEIGHT * 2
	if (data != null && hasLegendTable === true) {
		height += data.length * 24 + PADDING_TABLE
	}

	return (
		<PieChart height={height} margin={{ top: 0, right: 20, left: 20, bottom: 20 }} width={width}>
			<Pie
				data={data}
				dataKey="value"
				cx={width / 2}
				cy={outerRadius + PADDING_HEIGHT}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				fill="#8884d8"
				// label={renderCustomizedLabel}
			>
				{data.map((item, index) => (
					<Cell
						key={item.name}
						fill={
							item.color != null
								? item.color
								: CHART_COLORS.length - 1 > index
								? CHART_COLORS[index]
								: 'white'
						}
					/>
				))}
			</Pie>
			<Tooltip />
			<Legend
				layout="horizontal"
				// content={LegendTable}
				{...(hasLegendTable === true && { content: LegendTable })}
			/>
		</PieChart>
	)
}

RatioPieChart.propTypes = {
	data: PropTypes.array,
	color: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
	innerRadius: PropTypes.number,
	outerRadius: PropTypes.number,
}

export default RatioPieChart
