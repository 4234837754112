import React from 'react'
import PropTypes from 'prop-types'

const BookCases = props => {
	return <h2>준비중입니다.</h2>
}

BookCases.propTypes = {}

export default BookCases
