/**
 * [Component] 의료정보 입력 표(Grid)
 *
 * medInfos Prop으로 하위 MedInfo 소유. 행 추가 제거 가능.
 * 열 정보를 Array로 받음
 */

import React, { PureComponent } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { DropTarget } from 'react-dnd'
import { Table } from 'semantic-ui-react'

import withMedInfos from '../../../higherComponents/withMedInfos'
import DataFieldGridRow from './DataFieldGridRow'
import DataFieldEditDropdown from '../control/DataFieldEditDropdown'

import { DRAG_ITEM_TYPES, DEFAULT_MED_INFO_TYPE } from '../../../constants'
import { InputType, DataFieldFormat } from '../../../enum'
import DataFieldGridHead from './DataFieldGridHead'
import { InputLabel } from './DataFieldLabel'
import { TitleBox } from './DataFieldTitleBox'
import { ImageIconButtonContent } from '../../common/basic/ImageIconButton'
import { COLOR_BACKGROUND_DARK_GRAY } from '../../../constants/styles'
import DataFieldDiv from './DataFieldDiv'

// 드래그 앤 드롭: 드롭 받을 때
const dataGroupTarget = {
	drop(props, monitor) {
		if (monitor.isOver() === true) {
			return {
				sectionIndex: props.sectionIndex,
				groupIndex: props.medInfoIndex,
				groupId: props.medInfoId,
				medInfoId: props.medInfoId,
				isAdding: true,
				isGroup: true,
			}
		}
	},
}

// 드롭 타겟 수집
function collectTarget(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver({ shallow: true }),
	}
}

class DataFieldGrid extends PureComponent {
	static propTypes = {
		medInfo: PropTypes.shape().isRequired,
		medInfos: PropTypes.arrayOf(PropTypes.shape({})),
		name: PropTypes.string.isRequired,
		medInfoId: PropTypes.number.isRequired,
		sectionIndex: PropTypes.number.isRequired,
		medInfoIndex: PropTypes.number.isRequired,
		templateMode: PropTypes.bool.isRequired,
		connectDragSource: PropTypes.func,
		connectDropTarget: PropTypes.func.isRequired,
		childTypes: PropTypes.arrayOf(PropTypes.shape({})),
		format: PropTypes.string,
		description: PropTypes.string,
		defaultDisabled: PropTypes.bool,
		disabled: PropTypes.bool,
		readOnly: PropTypes.bool,
		isOver: PropTypes.bool,
		onOpenTypeModal: PropTypes.func.isRequired,
		onClickSettingMenu: PropTypes.func,
		onCreateMedInfoType: PropTypes.func.isRequired,
		onCreateMedInfo: PropTypes.func.isRequired,
		onUpdateMedInfo: PropTypes.func.isRequired,
		onDeleteMedInfo: PropTypes.func.isRequired,
		onUpdateSelf: PropTypes.func.isRequired,
		onGenerateMedInfo: PropTypes.func.isRequired,
		onInsertMedInfo: PropTypes.func.isRequired,
		onDeleteChildType: PropTypes.func.isRequired,
		onUpdateChildType: PropTypes.func.isRequired,
		onOpenEditModal: PropTypes.func.isRequired,
		onOpenDeleteModal: PropTypes.func.isRequired,
		onSearchMedInfoTypes: PropTypes.func,
		onDecideSizeInfo: PropTypes.func.isRequired,
	}

	static defaultProps = {
		connectDragSource: elem => elem,
		medInfos: null,
		format: null,
		description: null,
		onClickSettingMenu: () => {},
		defaultDisabled: false,
		disabled: false,
		readOnly: false,
		isOver: false,
		childTypes: null,
	}

	constructor(props) {
		super(props)

		this.state = {
			isActive: false,
		}
	}

	getColumns = () => {
		const { childTypes } = this.props
		return childTypes.filter(item => item.inputType !== InputType.GRID_ROW.key)
	}

	// 접기 설정
	toggleFold = () => this.setState(prevState => ({ isActive: !prevState.isActive }))

	// 필드 수정 Modal 열기
	handleOpenEditModal = params => {
		const { name, onOpenTypeModal, onUpdateMedInfo } = this.props
		onOpenTypeModal({
			title: name,
			onSubmit: onUpdateMedInfo,
			parentType: InputType.GRID.value,
			...params,
		})
	}

	// 필드 추가 Modal 열기
	handleOpenAddModal = e => {
		if (e && e.stop) e.stopPropagation()
		const { onOpenTypeModal, name, onCreateMedInfoType } = this.props
		onOpenTypeModal({
			title: name,
			parentType: InputType.GRID.value,
			onSubmit: onCreateMedInfoType,
		})
	}

	// 그리드 행 추가하기
	// Row MedInfo 생성후 Column별 MedInfo 생성하여
	// withChart.js의 medInfos state로 업데이트
	handleAddRow = isFront => async () => {
		const { onGenerateMedInfo, childTypes, onInsertMedInfo, medInfoId } = this.props
		const rowtype = childTypes.find(item => item.inputType === InputType.GRID_ROW.key)
		const row = await onGenerateMedInfo(
			{
				medInfoType: rowtype,
				notAddMedInfoType: true,
			},
			medInfoId
		)

		const columns = this.getColumns()

		let medInfos = await Promise.all(
			columns.map(async medInfoType => {
				const isIndi = medInfoType.inputType === InputType.GRID_INDI_COLUMN.value

				return await onGenerateMedInfo(
					{
						medInfoType: isIndi ? DEFAULT_MED_INFO_TYPE : medInfoType, // 독립적인 MedInfoType을 갖는 열일 경우 항목 추가
						notAddMedInfoType: !isIndi,
					},
					row.id
				)
			})
		)

		row.children = medInfos

		onInsertMedInfo(row, null, isFront)
	}

	// 행 삭제하기
	handleDeleteRow = index => {
		const { onDeleteMedInfo } = this.props
		onDeleteMedInfo(index)
	}

	// 헤더의 Column 별 Setting Menu 클릭시 (삭제 또는 수정)
	handleClickChildTypeSetting = medInfoType => (e, { value }) => {
		const { medInfoIndex, onDeleteChildType, onUpdateChildType, onOpenEditModal } = this.props
		if (value === 'delete') {
			onDeleteChildType(medInfoType.id)
		} else if (value === 'edit') {
			onOpenEditModal({
				medInfoType,
				medInfoIndex,
				onSubmit: onUpdateChildType,
				parentType: InputType.GRID.value,
			})
		}
	}

	handleChangeColumnOrder = (source, target) => {
		const { childTypes, medInfos, onUpdateSelf, medInfoIndex, medInfo } = this.props

		const columnCopy = childTypes.slice(0)

		const targetIndex = childTypes.findIndex(i => i.id === target.medInfoTypeId)
		const sourceIndex = childTypes.findIndex(i => i.id === source.medInfoTypeId)

		columnCopy.splice(sourceIndex, 1)
		columnCopy.splice(targetIndex, 0, childTypes[sourceIndex])

		const children = medInfos.slice(0)

		const tRowIndex = childTypes
			.filter(i => i.inputType !== InputType.GRID_ROW.value)
			.findIndex(i => i.id === target.medInfoTypeId)

		const sRowIndex = childTypes
			.filter(i => i.inputType !== InputType.GRID_ROW.value)
			.findIndex(i => i.id === source.medInfoTypeId)

		for (const row of children) {
			const cells = row.children.slice(0)
			cells.splice(sRowIndex, 1)
			cells.splice(tRowIndex, 0, row.children[sRowIndex])
			row.children = cells
		}

		onUpdateSelf(
			{
				...medInfo,
				medInfoType: {
					...medInfo.medInfoType,
					childTypes: columnCopy,
				},
				children,
			},
			medInfoIndex
		)
	}

	render() {
		const {
			medInfos,
			templateMode,
			medInfoIndex: groupIndex,
			name,
			onClickSettingMenu,
			onResize,
			onCreateMedInfo,
			childTypes,
			format,
			description,
			onSearchMedInfoTypes,
			defaultDisabled,
			disabled: disabled_,
			readOnly,
			isOver,
			onDecideSizeInfo,
			connectDropTarget,
			connectDragSource,
			medInfo: { id: medInfoId },
			...others
		} = this.props

		const disabled = templateMode !== true && disabled_ !== undefined ? disabled_ : defaultDisabled

		const isFixed = format && format.includes(DataFieldFormat.FIXED.key)
		const hasLabel = format && format.includes(DataFieldFormat.LABELED.key)

		const deletable = !isFixed && !readOnly
		const addable = !readOnly && (!isFixed || templateMode)

		const columns = this.getColumns()

		const indiCols =
			templateMode === true
				? columns.reduce((arr, cv, index) => {
						if (cv.inputType === InputType.GRID_INDI_COLUMN.value) {
							arr.push(index)
						}
						return arr
				  }, [])
				: []

		return connectDragSource(
			<div>
				<DataFieldDiv medInfoId={medInfoId} className="chart-grid">
					<TitleBox>
						{templateMode && (
							<div style={styles.btn}>
								<DataFieldEditDropdown
									medInfoId={medInfoId}
									onClickMenu={onClickSettingMenu}
									onResize={onResize}
									style={{ marginTop: -5 }}
								/>
							</div>
						)}
						<InputLabel disabled={disabled}>{name}</InputLabel>

						{/* {addable && (
            <Button
              disabled={disabled}
              onClick={this.handleAddRow(false)}
              basic
              icon="plus"
              style={{ margin: 3, marginTop: -5, float: 'right' }}
              circular
            />
          )} */}
					</TitleBox>
					<div>{description}</div>
					<Table style={styles.table}>
						<DataFieldGridHead
							disabled={disabled}
							hasLabel={hasLabel}
							columns={columns}
							templateMode={templateMode}
							deletable={deletable}
							onSearchMedInfoTypes={onSearchMedInfoTypes}
							onCreateMedInfo={onCreateMedInfo}
							onOpenAddModal={this.handleOpenAddModal}
							onClickEditMenu={this.handleClickChildTypeSetting}
							onChangeColumnOrder={this.handleChangeColumnOrder}
						/>
						<Table.Body>
							{medInfos &&
								medInfos.map((medInfo, medInfoIndex) => {
									const { id, medInfoType, checkedItems } = medInfo

									return (
										<Table.Row key={`${id}`} className="chart-grid-row">
											<DataFieldGridRow
												{...others}
												{...medInfoType}
												readOnly={readOnly}
												disabled={disabled}
												medInfoIndex={medInfoIndex}
												medInfo={medInfo}
												medInfos={medInfo.children}
												groupIndex={groupIndex}
												groupId={others.medInfoId}
												parentIndex={groupIndex}
												currentRecord={medInfos}
												medInfoId={id}
												templateMode={templateMode}
												checkedItems={checkedItems}
												onOpenEditModal={this.handleOpenEditModal}
												onDeleteRow={this.handleDeleteRow}
												hasLabel={hasLabel}
												fixedRow={isFixed}
												indiCols={indiCols}
												addable={addable}
											/>
										</Table.Row>
									)
								})}
						</Table.Body>
						{addable && (
							<Table.Footer>
								<Table.Row
									disabled={disabled}
									onClick={disabled !== true ? this.handleAddRow(false) : () => {}}
								>
									<TableAddRowCell textAlign="center" colSpan={childTypes.length + 1}>
										<ImageIconButtonContent
											disabled={disabled}
											size="tiny"
											text="새로운 행"
											imageIcon="plus-16.svg"
										/>
									</TableAddRowCell>
								</Table.Row>
							</Table.Footer>
						)}
					</Table>
				</DataFieldDiv>
			</div>
		)
	}
}

export default DropTarget(DRAG_ITEM_TYPES.DATA_FIELD, dataGroupTarget, collectTarget)(
	withMedInfos(DataFieldGrid)
)

const TableAddRowCell = styled(Table.HeaderCell)`
	cursor: pointer !important;

	:hover {
		background: ${COLOR_BACKGROUND_DARK_GRAY} !important;
	}
`

const styles = {
	table: {
		tableLayout: 'fixed',
		marginTop: 10,
	},
}
