import React from 'react'
import PageContainer from '../components/common/container/PageContainer'
import { FullCenteredVerticalFlexDiv } from '../components/common/basic/StyledBox'
import image from '../assets/images/drawings/laptop-man.png'
import { FullWidthImage } from '../components/common/basic/StyledImage'

/**
 * 모바일 준비 안된 페이지 대체화면
 */
const MobileNotSupported = () => {
	return (
		<PageContainer fluid hasMobile>
			<FullCenteredVerticalFlexDiv>
				<div style={{ width: '50%', marginTop: 50, marginBottom: 50 }}>
					<FullWidthImage src={image} />
				</div>
				<div>현재 모바일 페이지가 개발중입니다.</div>
				<div>데이터 관리 기능은 우선 PC를 통해서 사용해 주세요!</div>
			</FullCenteredVerticalFlexDiv>
		</PageContainer>
	)
}

export default MobileNotSupported
