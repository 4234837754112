import gql from 'graphql-tag'

export const GET_PRESCRIPTION = gql`
	query getPrescription($id: ID!) {
		prescription(id: $id) {
			id
			name
			PrescriptionHerbs {
				process
				amount
				Herb {
					id
					name
					nameChinese
					nameScientific
				}
			}
		}
	}
`

export const GRID_ADD_ROWS = gql`
	query getLibraryEntity($id: ID!, $entityType: String) {
		libraryEntity(id: $id, entityType: $entityType) {
			id
			name
			... on Prescription {
				PrescriptionHerbs {
					process
					amount
					measure
					Herb {
						id
						name
						nameChinese
						nameScientific
					}
				}
			}
			... on PrescriptionTk {
				PrescriptionHerbTks {
					process
					amount
					unit
					Herb {
						id
						name
						nameChinese
						nameScientific
					}
				}
			}
			... on AcupunctureSaam {
				AcupointD1 {
					id
					name
					nameChinese
				}
				AcupointD2 {
					id
					name
					nameChinese
				}
				AcupointS1 {
					id
					name
					nameChinese
				}
				AcupointS2 {
					id
					name
					nameChinese
				}
			}
		}
	}
`

export const GRID_ADD_ROWS_SIMPLE = gql`
	query getLibraryRows($id: ID!, $entityType: String) {
		libraryAddedRows(id: $id, entityType: $entityType) {
			cells {
				entityId
				optionValue
				value
				typeName
			}
			entityId
		}
	}
`

export const SEARCH_RECORD_TEMPLATES = gql`
	query searchRecordTemplates($searchString: String!) {
		recordTemplates(searchString: $searchString) {
			id
			title
		}
	}
`

export const GET_AUTOCOMPLETE_RESULTS = gql`
	query getAutoCompleteResults($entityType: String!, $searchString: String!) {
		getAutoCompleteResults(entityType: $entityType, searchString: $searchString) {
			id
			title
			description
		}
	}
`

export const SEARCH_TAGS = gql`
	query searchCaseTags($query: String!) {
		searchCaseTags(query: $query) {
			id
			name
			textKorean
			textChinese
			caseCount
			tagType
			description
		}
	}
`

export const GET_DONGUIBOGAM_TITLE_LIST = gql`
	query GetDonguibogamTitleList {
		donguibogamTitleList {
			id
			key
			text
			textKorean
			typeCode
			itemType
			titleType
			subItemCount
			caseCount
			subCaseSum
			caseTagId
		}
	}
`

export const GET_DONGUIBOGAM_SUB_ITEMS = gql`
	query GetDonguibogamLineSubItems($id: ID!) {
		donguibogamLineSubItems(id: $id) {
			id
			key
			text
			textKorean
			typeCode
			itemType
			titleType
			subItemCount
			subCaseSum
			caseCount
			caseTagId
		}
	}
`
