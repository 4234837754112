import styled from 'styled-components'
import FadeIn from './FadeIn'

const HeaderPageTitle = styled(FadeIn)`
	font-size: 18px;
	font-weight: 700;
	white-space: nowrap;
`

export default HeaderPageTitle
