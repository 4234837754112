import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { COLOR_TEXT_LIGHTGRAY } from '../../constants/styles'
import SegmentSlim from '../common/basic/SegmentSlim'

const NumberDisplay = props => {
	const { items } = props

	return (
		<SegmentSlim>
			{items != null &&
				items.map(item => {
					return <Item key={item.title} {...item} />
				})}
		</SegmentSlim>
	)
}

NumberDisplay.propTypes = {}

export default NumberDisplay

const Item = React.memo(({ value, title, floorDecimal }) => {
	const { floatValue, intValue, stringValue } = value
	let numberValue = floatValue == null ? intValue || stringValue : floatValue

	if (floatValue != null) {
		const tenth = 10 ** floorDecimal
		numberValue = Math.floor(tenth * numberValue) / tenth
	}

	return (
		<ItemContainer>
			<Value>{numberValue}</Value>
			<ItemLabel>{title}</ItemLabel>
		</ItemContainer>
	)
})

Item.propTypes = {
	value: PropTypes.shape({
		intValue: PropTypes.number,
		floatValue: PropTypes.number,
		stringValue: PropTypes.string,
	}),
	title: PropTypes.string.isRequired,
	floorDecimal: PropTypes.number,
}

const ItemContainer = styled.div`
	padding-top: 45px;
	padding-bottom: 35px;
	width: 278px;
	display: inline-block;
	text-align: center;
`
const Value = styled.h6`
	margin-bottom: 12px;
`
const ItemLabel = styled.div`
	color: ${COLOR_TEXT_LIGHTGRAY};
	font-size: 14px;
	height: 20px;
`
