import gql from 'graphql-tag'
import { AUTH_PAYLOAD_FRAGMENT } from '../fragments/auth'

// 회원가입
export const ADD_USER = gql`
	mutation addUser(
		$username: String!
		$password: String!
		$email: String!
		$name: String!
		$userType: String
		$institution: String
		$position: String
		$address: String
		$phone: String
		$nickname: String!
		$agreedTerm: Boolean
		$agreedPrivacyTerm: Boolean
		$departmentId: Int # $certKey: String # $uid: String
	) {
		addUser(
			username: $username
			nickname: $nickname
			password: $password
			email: $email
			name: $name
			userType: $userType
			institution: $institution
			position: $position
			address: $address
			phone: $phone
			agreedTerm: $agreedTerm
			agreedPrivacyTerm: $agreedPrivacyTerm
			departmentId: $departmentId # certKey: $certKey # uid: $uid
		) {
			...AuthPayloadParts
		}
	}

	${AUTH_PAYLOAD_FRAGMENT}
`

// 로그인
export const LOGIN = gql`
	mutation login($username: String!, $password: String!) {
		login(username: $username, password: $password) {
			...AuthPayloadParts
		}
	}

	${AUTH_PAYLOAD_FRAGMENT}
`

// 액세스 토큰 요청
export const REQUEST_ACCESS_TOKEN = gql`
	mutation requestAccessToken($refreshToken: String!, $userId: Int!) {
		requestAccessToken(refreshToken: $refreshToken, userId: $userId) {
			...AuthPayloadParts
		}
	}

	${AUTH_PAYLOAD_FRAGMENT}
`

// 프로필 수정
export const UPDATE_USER_PROFILE = gql`
	mutation updateUserProfile(
		$phone: String
		$nickname: String
		$profileImage: Upload
		$email: String
	) {
		updateUserProfile(
			phone: $phone
			nickname: $nickname
			profileImage: $profileImage
			email: $email
		) {
			id
			phone
			nickname
			profileImage
			email
		}
	}
`

// 계정 수정
export const UPDATE_USER_ACCOUNT = gql`
	mutation updateUserAccount($newPassword: String!, $password: String!) {
		updateUserAccount(newPassword: $newPassword, password: $password)
	}
`

// 아이디 찾기
export const FIND_USERNAME = gql`
	mutation findUsername($name: String!, $email: String!) {
		findUsername(name: $name, email: $email)
	}
`

// 비밀번호 찾기
export const FIND_PASSWORD = gql`
	mutation findPassword($username: String!, $email: String!) {
		findPassword(username: $username, email: $email)
	}
`

// 아이디 찾기 인증코드 확인
export const VERIFY_USERNAME_CODE = gql`
	mutation verifyUsernameCode($name: String!, $email: String!, $code: String!) {
		verifyUsernameCode(name: $name, email: $email, code: $code)
	}
`

// 비밀번호 찾기 인증코드 확인
export const VERIFY_PASSWORD_CODE = gql`
	mutation verifyPasswordCode($username: String!, $email: String!, $code: String!) {
		verifyPasswordCode(username: $username, email: $email, code: $code)
	}
`

// 비밀번호 재설정
export const RESET_FORGOTTEN_PASSWORD = gql`
	mutation resetForgottenPassword(
		$username: String!
		$email: String!
		$code: String!
		$password: String!
		$passwordRepeat: String!
	) {
		resetForgottenPassword(
			username: $username
			email: $email
			code: $code
			password: $password
			passwordRepeat: $passwordRepeat
		)
	}
`

// 약관 동의하기
export const AGREE_TERMS = gql`
	mutation agreeTerms($serviceTermId: Int, $privacyTermId: Int) {
		agreeTerms(agreedTermId: $serviceTermId, agreedPrivacyTermId: $privacyTermId) {
			id
			type
		}
	}
`

// 약관 동의하기(클라이언트)
export const AGREE_TERMS_CLIENT = gql`
	mutation agreeTermsClient($serviceTermId: Int, $privacyTermId: Int) {
		agreeTerms(agreedTermId: $serviceTermId, agreedPrivacyTermId: $privacyTermId) @client {
			id
			type
		}
	}
`

// 이메일 본인 확인
export const VERIFY_REGISTER_TOKEN = gql`
	mutation verifyRegisterCode($token: String!) {
		verifyRegisterCode(token: $token)
	}
`

/**
 *      Client Queries
 */

// [Client]
export const UPDATE_USER_INFO = gql`
	mutation updateUserInfo($authPayload: AuthPayload) {
		updateUserInfo(authPayload: $authPayload) @client {
			...AuthPayloadParts
		}
	}
	${AUTH_PAYLOAD_FRAGMENT}
`

// [Client]
export const RESET_USER_INFO = gql`
	mutation resetUserInfo {
		resetUserInfo @client {
			...AuthPayloadParts
		}
	}
	${AUTH_PAYLOAD_FRAGMENT}
`
// [Client]
export const UPDATE_NO_USER_INFO = gql`
	mutation noUserInfo {
		noAuthUser @client
	}
`

// [Client]
export const UPDATE_SIGNUP_INPUT = gql`
	mutation updateSignupInput {
		updateSignupInput(input: SignupInput) @client
	}
`

// [Client]
export const GET_SIGNUP_INPUT = gql`
	query getSignupInput {
		signupInput @client {
			username
			nickname
			password
			email
			name
			userType
			institution
			position
			address
			phone
			agreedTerm
			agreedPrivacyTerm
		}
	}
`
