import React from 'react'
import PropTypes from 'prop-types'

import { XAxis, YAxis, Legend, Tooltip, BarChart, Bar, Cell, ResponsiveContainer } from 'recharts'
import { CHART_COLORS } from '../../constants/styles'
import LegendTable from './LegendTable'
import EmptyChart from './EmptyChart'

const HEIGHT = 196
const PADDING_HEIGHT = 40
const PADDING_TABLE = 30

const SimpleBarChart = props => {
	const { data, dataKey, width, hasLegendTable } = props
	let height = HEIGHT + PADDING_HEIGHT * 2
	if (data != null && hasLegendTable === true) {
		height += data.length * 24 + PADDING_TABLE
	}
	if (data == null || data.length === 0) {
		return <EmptyChart width={width} height={height} />
	}

	return (
		<BarChart
			width={width}
			height={height}
			margin={{ top: 50, right: 20, left: 20, bottom: 20 }}
			data={data}
			barCategoryGap={3}
		>
			<XAxis dataKey="name" hide />
			<Tooltip />
			<Bar dataKey={dataKey} unit="회" height={HEIGHT} label={{ position: 'top' }}>
				{data.map((entry, index) => {
					entry.color = CHART_COLORS[index]
					return <Cell key={`cell-${entry.name}`} fill={CHART_COLORS[index]} title={entry.name} />
				})}
			</Bar>
			<Legend
				margin={{ bottom: 20 }}
				{...(hasLegendTable === true && {
					content: (
						<LegendTable
							hasData
							data={data.map((entry, index) => {
								const { name, color, [dataKey]: value } = entry
								return {
									name: name,
									color,
									value,
								}
							})}
							dataKey={dataKey}
						/>
					),
				})}
			/>
		</BarChart>
	)
}

SimpleBarChart.propTypes = {
	data: PropTypes.array,
	color: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
	innerRadius: PropTypes.number,
	outerRadius: PropTypes.number,
}

export default SimpleBarChart
