import gql from 'graphql-tag'
import { RESEARCH_FRAGMENT, RESEARCHER_INFO_FRAGMENT } from './research'

export const SYMPTOM_TYPE_FRAGMENT = gql`
	fragment SymptomTypePart on SymptomType {
		id
		name
		image
	}
`

export const PATIENT_FRAGMENT = gql`
	fragment PatientParts on Patient {
		id
		name
		code
		sexMale
		patientCode
		birthDate
		isTest
		summary
		isHashed
	}
`

export const RESEARCH_PATIENT_FRAGMENT = gql`
	fragment ResearchPatientParts on Patient {
		id
		name
		sexMale
		birthDate
		recordCount
		code
		createdAt
		updatedAt
		summary
		doctors {
			id
			name
		}
		registerer {
			id
			name
			profileImage
			institution {
				id
				name
			}
			researcherInfo {
				code
			}
		}
		patientResearches {
			code
			status
			phase {
				id
				title
			}
			nextTemplate {
				id
				title
			}
			nextReservation {
				phase {
					id
					title
				}
				reservedDate
				status
			}
		}
	}
`

export const RESEARCH_PATIENT_DETAIL_FRAGMENT = gql`
	fragment ResearchPatientDetailParts on Patient {
		id
		name
		sexMale
		birthDate
		recordCount
		code
		createdAt
		updatedAt
		summary
		doctors {
			id
			name
		}
		registerer {
			id
			name
			profileImage
			institution {
				id
				name
			}
			researcherInfo {
				code
			}
		}
		patientResearches {
			code
			status
			phase {
				id
				title
			}
			nextTemplate {
				id
				title
			}
			nextReservation {
				phase {
					id
					title
				}
				reservedDate
				status
			}
			initialVisitDate
		}
	}
`

export const PATIENT_PERSONAL_FRAGMENT = gql`
	fragment PatientPersonalParts on Patient {
		patientResearches {
			phone
			address
			birthDateReal
			inOut
		}
	}
`

export const NEXT_SCHEDULE_FRAGMENT = gql`
	fragment NextScheduleParts on RecordTemplate {
		title
		days
		daysRange
		doneBy
	}
`

export const PATIENT_RESEARCH_BASIC_FRAGMENT = gql`
	fragment PatientResearchBasicParts on ResearchSubjectInfo {
		id
		code
		status
		phase {
			id
			title
		}
		nextTemplate {
			id
			job
			title
			doneBy
		}
		research {
			id
			title
			type
			templateType
			patientBasicInfo
			userResearcherInfo {
				...ResearcherInfoParts
			}
			organizedBy {
				id
				name
			}
			recordTemplates {
				id
				job
				title
				doneBy
				type
				isReturning
				extLinkCode
			}
		}
	}
	${RESEARCHER_INFO_FRAGMENT}
`

export const PATIENT_RESEARCH_FRAGMENT = gql`
	fragment PatientResearchParts on ResearchSubjectInfo {
		code
		status
		phase {
			id
			title
		}
		nextTemplate {
			job
			title
			doneBy
		}
		address
		nameInitial
		phone
		birthDateReal
		inOut
		research {
			...ResearchParts
			organizedBy {
				id
				name
			}
		}
	}
	${RESEARCH_FRAGMENT}
`
