import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'semantic-ui-react'

const styles = {
	formGroup: {
		marginTop: 5,
	},
	cornerButton: {
		position: 'absolute',
		right: 5,
		top: 7,
	},
}

const SelectOptionItemFormNum = ({ text, onChange, onUnitChange, index, min, max, type }) => {
	return (
		<React.Fragment>
			<Form.Group style={styles.optionForm} key={`a${index.toString()}`} widths="equal">
				<Form.Field>
					<label>단위</label>
					<input onChange={onUnitChange(index)} placeholder="단위 입력" value={text} />
				</Form.Field>
				<Form.Field>
					<label>최소값</label>
					<input
						type="number"
						onChange={onChange(type)(index, 'min')}
						placeholder="최소값"
						value={min}
					/>
				</Form.Field>
				<Form.Field>
					<label>최대값</label>
					<input
						type="number"
						onChange={onChange(type)(index, 'max')}
						placeholder="최대값"
						value={max}
					/>
				</Form.Field>
			</Form.Group>
		</React.Fragment>
	)
}

SelectOptionItemFormNum.propTypes = {
	text: PropTypes.string,
	onChange: PropTypes.func,
	type: PropTypes.string,
	index: PropTypes.number,
	onUnitChange: PropTypes.func,
	min: PropTypes.number,
	max: PropTypes.number,
}

export default SelectOptionItemFormNum
