/**
 * [Component] 환자 정보 수정 Modal
 */

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'semantic-ui-react'

import { Query } from 'react-apollo'
import { GET_PATIENT_PERSONAL_INFO } from '../../../graphql/queries/patient'
import { InlineLoader } from '../../common/loader/InlineLoader'
import MiniErrorView from '../../common/error/MiniErrorView'
import PatientEditModalContent from './PatientEditModalContent'

export default class PatientEditModal extends PureComponent {
	static propTypes = {
		open: PropTypes.bool,
		patientId: PropTypes.number,
		onClose: PropTypes.func,
	}

	render() {
		const { open, patientId, onClose } = this.props

		return (
			<Modal open={open} onClose={onClose} closeIcon>
				<Modal.Header>환자 정보 변경</Modal.Header>
				<Query
					query={GET_PATIENT_PERSONAL_INFO}
					variables={{ id: patientId }}
					fetchPolicy="network-only"
				>
					{({ loading, data, error }) => {
						if (loading || error) {
							let view
							if (loading) view = <InlineLoader height={200} />
							if (error) view = <MiniErrorView />

							return (
								<React.Fragment>
									<Modal.Content>{view}</Modal.Content>
								</React.Fragment>
							)
						}

						const { patientPersonalInfo } = data
						return (
							<PatientEditModalContent
								{...this.props}
								{...patientPersonalInfo}
								{...(patientPersonalInfo.patientResearches.length > 0 &&
									patientPersonalInfo.patientResearches[0])}
							/>
						)
					}}
				</Query>
			</Modal>
		)
	}
}
