import React from 'react'
import { Switch, Route, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import { HeaderDetailContent } from '../../common/basic/StyledBox'
import {
	ROUTE_PATIENT_DETAIL_RECORD,
	ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL,
	ROUTE_PATIENT_DETAIL_RECORD_CHANGE_DETAIL,
	ROUTE_PATIENT_DETAIL_RECORD_CHANGES,
	ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL_CHANGE_DETAIL,
	ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL_CHANGES,
} from '../../../constants/routes'

import RecordHeader from './RecordHeader'

import PatientInfoHeader from './PatientInfoHeader'

/**
 * 헤더에서 보여주는 환자페이지 상의 내용
 *
 * 환자 정보와 레코드 정보를 조건에 따라 보여줌.
 */
const PatientHeader = ({ isSubSideMenuOpen }) => {
	const params = useParams()
	const { pid, id, subId } = params
	const patientId = parseInt(id, 10)
	const researchId = pid != null ? parseInt(pid, 10) : null
	const recordId = subId != null ? parseInt(subId, 10) : null
	const isOnResearchPage = pid != null

	return (
		<>
			{isSubSideMenuOpen === false && (
				<PatientInfoHeader
					patientId={patientId}
					researchId={researchId}
					isOnResearchPage={isOnResearchPage}
				/>
			)}
			<HeaderDetailContent>
				<Switch>
					<Route
						path={[
							ROUTE_PATIENT_DETAIL_RECORD_CHANGE_DETAIL,
							ROUTE_PATIENT_DETAIL_RECORD_CHANGES,
							ROUTE_PATIENT_DETAIL_RECORD,
							ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL_CHANGE_DETAIL,
							ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL_CHANGES,
							ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL,
						]}
						render={() => (
							<RecordHeader
								recordId={recordId}
								researchId={researchId}
								patientId={patientId}
								isOnResearchPage={isOnResearchPage}
							/>
						)}
					/>
				</Switch>
			</HeaderDetailContent>
		</>
	)
}

PatientHeader.propTypes = {
	isSubSideMenuOpen: PropTypes.bool.isRequired,
}

export default PatientHeader
