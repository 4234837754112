import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Grid, Card } from 'semantic-ui-react'
import ResearchListItem from './ResearchListItem'
import MyIcon from '../../common/basic/MyIcon'
import T1 from '../../common/basic/T1'
import DescriptionText from '../../common/basic/DescriptionText'
import { EmptyHeader } from '../../common/basic/StyledTexts'

/**
 * 프로젝트 목록 보기 뷰 처리
 */
const ResearchList = props => {
	const { hasAddButton, researches, onResearchClick, onAddButtonClick } = props
	const hasResearches = researches != null && researches.length > 0
	return (
		<div>
			<Grid columns="2">
				{hasAddButton === true && (
					<StyledColumn>
						<ItemContainer onClick={onAddButtonClick}>
							<NewResearchWrap>
								<AddIcon />
								<T1>새로운 프로젝트 생성 </T1>
								<DescriptionText>
									다양한 종류의 프로젝트를 자유롭게 생성할 수 있습니다.
								</DescriptionText>
							</NewResearchWrap>
						</ItemContainer>
					</StyledColumn>
				)}
				{hasResearches === true &&
					researches.map(research => {
						return (
							<StyledColumn key={research.id}>
								<ItemContainer onClick={onResearchClick(research.id)}>
									<ItemWrap>
										<ResearchListItem {...research} />
									</ItemWrap>
								</ItemContainer>
							</StyledColumn>
						)
					})}
				{hasAddButton === false && hasResearches === false && (
					<EmptyContainer>
						<EmptyHeader>프로젝트가 없습니다.</EmptyHeader>
					</EmptyContainer>
				)}
			</Grid>
		</div>
	)
}

ResearchList.propTypes = {
	hasAddButton: PropTypes.bool.isRequired,
	researches: PropTypes.array,
	onResearchClick: PropTypes.func.isRequired,
	onAddButtonClick: PropTypes.func.isRequired,
}

export default ResearchList

/**
 * Styles
 */

const StyledColumn = styled(Grid.Column)`
	padding: 8px !important;
`
const ItemContainer = styled(Card).attrs({ fluid: true })`
	height: 220px;
	position: relative;
	cursor: pointer;
`

const ItemWrap = styled(Card.Content)`
	padding: 24px 16px !important;
`

const NewResearchWrap = styled(Card.Content)`
	padding: 60px 16px !important;
`
const AddIcon = styled(MyIcon).attrs({ name: 'plusLarge', size: 'large' })`
	margin-bottom: 20px;
	display: block;
`

const EmptyContainer = styled.div`
	margin-top: 50px;
`
