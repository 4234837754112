import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import { useQuery, useMutation } from 'react-apollo'
import { Form, TextArea, Checkbox, Modal } from 'semantic-ui-react'

import { GET_USER_INFO } from '../../../graphql/queries/user'
import useInputReducer from '../../../hooks/useInputReducer'
import { LinkColoredText } from '../../common/basic/StyledTexts'
import TermContact from '../../other/term/TermContact'
import { FlexFill, SubmitButtonBox } from '../../common/basic/StyledBox'
import { SubmitButton } from '../../common/basic/StyledElements'
import { SUBMIT_RESEARCH_APPLICATION } from '../../../graphql/mutations/research'

import useToastMessage from '../../../hooks/useToastMessage'
import { ResearchApplicationType } from '../../../enum'
import {
	ALERT_RESEARCH_INQUIERY_SUCCESS,
	ALERT_RESEARCH_INQUIERY_FAILED,
} from '../../../constants/alerts'
import { validateFields } from '../../../libs/validationHelper'
import TextInputWithError from '../../common/basic/TextInputWithError'
import ContactTermChecker from '../../other/term/ContactTermChecker'

const FIELD_INFOS = {
	name: {
		required: true,
		isPersonal: true,
	},
	phone: {
		required: true,
		isPersonal: true,
	},
	email: {
		required: true,
		isPersonal: true,
	},
	position: {
		required: true,
		isPersonal: true,
	},
	title: {
		required: true,
	},
	description: {
		required: true,
	},
	startDate: {
		required: false,
	},
	endDate: {
		required: false,
	},
	purpose: {
		required: true,
	},
	institutionName: {
		required: true,
		isPersonal: true,
	},
}

/**
 * 연구 프로젝트 문의
 */
const ResearchInquiry = ({
	title: pageTitle,
	purpose: purposeProp,
	description: pageDescription,
	type: typeProp,
	onSubmit,
}) => {
	const {
		data: { userInfo },
	} = useQuery(GET_USER_INFO)

	const hasUser = userInfo.id !== 0
	const [state, onChange, onRollBack] = useInputReducer({
		name: userInfo.name,
		phone: '',
		email: userInfo.email,
		title: '',
		description: '',
		startDate: null,
		endDate: null,
		purpose: purposeProp,
		type: null,
		position: '',
		institutionName: '',
	})

	const {
		name,
		phone,
		email,
		position,
		title,
		description,
		startDate,
		endDate,
		purpose,
		institutionName,
	} = state

	const [hasAgreed, setHasAgreed] = useState(null)
	const [fieldErrors, setFieldErrors] = useState({})
	const [isPrivacyModalOpen, setPrivacyModalOpen] = useState(false)
	const showToastMessage = useToastMessage()

	const [sendResearchApplication] = useMutation(SUBMIT_RESEARCH_APPLICATION, {
		variables: {
			researchApplication: {
				requesterName: name,
				requesterPhone: phone,
				requesterEmail: email,
				requesterPosition: position,
				requesterInstitution: institutionName,
				title,
				description,
				purpose,
				startDate,
				endDate,
				type: typeProp,
			},
		},
		onCompleted: () => {
			showToastMessage(ALERT_RESEARCH_INQUIERY_SUCCESS)
			onRollBack()
			onSubmit()
		},
		onError: () => {
			showToastMessage(ALERT_RESEARCH_INQUIERY_FAILED)
		},
	})

	const submitRequest = () => {
		let fields
		if (hasUser === true) {
			fields = _.pickBy(FIELD_INFOS, i => i.isPersonal !== true)
		} else {
			if (hasAgreed !== true) {
				alert('개인정보 제공 동의 후 문의하실 수 있습니다.')
				return
			}
			fields = FIELD_INFOS
		}

		const errors = validateFields(state, fields)

		if (_.isEmpty(errors) !== true) {
			if (!description) {
				alert('문의 내용을 입력해 주세요.')
				return
			}
			setFieldErrors(errors)
			return
		}

		sendResearchApplication()
	}

	return (
		<FlexFill>
			<h6>{pageTitle}</h6>
			<div>{pageDescription}</div>
			<StyledForm>
				{hasUser !== true && (
					<>
						<TextInputWithError
							error={fieldErrors.name}
							label="성명"
							name="name"
							value={name}
							onChange={onChange}
						/>
						<TextInputWithError
							error={fieldErrors.phone}
							label="전화"
							name="phone"
							value={phone}
							onChange={onChange}
						/>
						<TextInputWithError
							error={fieldErrors.email}
							label="이메일"
							name="email"
							value={email}
							onChange={onChange}
						/>
						<TextInputWithError
							error={fieldErrors.institutionName}
							label="기관명"
							name="institutionName"
							value={institutionName}
							onChange={onChange}
						/>
						<TextInputWithError
							error={fieldErrors.position}
							label="직책"
							name="position"
							value={position}
							onChange={onChange}
						/>
						<ContactTermChecker hasAgreed={hasAgreed} setAgreed={setHasAgreed} />
						{/* <Form.Field>
							<Checkbox checked={hasAgreed} onChange={() => setHasAgreed(r => !r)} /> 문의 상담에
							필요한 개인정보 수집이용에 동의합니다.{' '}
							<LinkColoredText onClick={() => setPrivacyModalOpen(true)}>
								자세히 보기
							</LinkColoredText>
						</Form.Field> */}
						<Modal
							open={isPrivacyModalOpen}
							header="서비스 이용관련 문의 개인정보수집이용 동의(필수)"
							content={<TermContact />}
							closeIcon
							onClose={() => setPrivacyModalOpen(false)}
						/>
					</>
				)}
				<TextInputWithError
					error={fieldErrors.title}
					label="제목"
					placeholder="제목을 입력해 주세요."
					name="title"
					value={title}
					onChange={onChange}
				/>
				<Form.Field
					control={StyledTextArea}
					label="내용"
					name="description"
					placeholder="진행하실 프로젝트에 대한 간단한 설명을 남겨주세요."
					value={description}
					onChange={onChange}
				/>
				<SubmitButtonBox>
					<SubmitButton onClick={submitRequest}>문의 전송</SubmitButton>
				</SubmitButtonBox>
			</StyledForm>
		</FlexFill>
	)
}

ResearchInquiry.propTypes = {
	title: PropTypes.string,
	purpose: PropTypes.string,
	description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	type: PropTypes.string,
	onSubmit: PropTypes.func,
}

ResearchInquiry.defaultProps = {
	title: '프로젝트 문의하기',
	purpose: null,
	description: '연구 데이터베이스 활용에 대해 자유롭게 문의하여 주세요.',
	type: ResearchApplicationType.ETC.value,
}

export default ResearchInquiry

const StyledForm = styled(Form)`
	width: 100%;
	margin-top: 20px;
`

const StyledTextArea = styled(TextArea)`
	min-height: 300px;
`
