import React from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import { Container, Grid } from 'semantic-ui-react'
import { Tooltip, PieChart, Pie, Cell } from 'recharts'

import moment from 'moment'
import { GET_RESEARCH_STATS } from '../graphql/queries/research'
import LoadingView from '../components/common/loader'
import SimpleInfoCard from '../components/common/basic/SimpleInfoCard'
import StatsGrid from '../components/common/grid/StatsGrid'
import NumberDisplay from '../components/statistics/NumberDisplay'
import StatItem from '../components/statistics/StatItem'
import RatioPieChart from '../components/statistics/RatioPieChart'
import ProgressBar from '../components/statistics/ProgressBar'
import { PAGE_CONTENT_MEDIUM_WIDTH } from '../constants/styles'
import { StatisticsRequestType } from '../enum'
import { getStatResult, processSexRatio } from '../libs/statHelper'
import ErrorView from '../components/common/error/ErrorView'
import { dateFromDate, toCommonDateString } from '../libs/dateHelper'
import DescriptionText from '../components/common/basic/DescriptionText'
import PageContainer from '../components/common/container/PageContainer'

const MARGIN = 40

const BASIC_CHART_WIDTH = (PAGE_CONTENT_MEDIUM_WIDTH - MARGIN) / 2

const REQUESTS = [
	{
		type: StatisticsRequestType.PATIENT_GENDER_RATIO.type,
	},
	{
		type: StatisticsRequestType.PATIENT_AVERAGE_AGE_SINGLE_VALUE.type,
	},
	{
		type: StatisticsRequestType.PATIENT_GENERATION_RATIO.type,
	},
]

/**
 * 프로젝트 통계 - 현황판(대시보드)
 */
const ResearchStatisticsDashboard = ({
	researchId,
	patientCount,
	recordCount,
	doctorCount,
	estSubjects,
	startDate,
	endDate,
}) => {
	return (
		<Query
			query={GET_RESEARCH_STATS}
			variables={{
				researchId,
				requests: REQUESTS,
			}}
		>
			{({ loading, error, data }) => {
				if (loading) {
					return <LoadingView />
				} else if (error != null) {
					return <ErrorView error={error} />
				} else {
					const { researchStatistics: results } = data

					let genderRatios, genRatios

					try {
						genderRatios = processSexRatio(
							getStatResult(results, [StatisticsRequestType.PATIENT_GENDER_RATIO.type])
						)[0].ratios

						genRatios = getStatResult(results, [
							StatisticsRequestType.PATIENT_GENERATION_RATIO.type,
						])[0].ratios
					} catch (e) {
						return <ErrorView />
					}

					return (
						<PageContainer fixed>
							<StatsGrid relaxed>
								<Grid.Row>
									<Grid.Column>
										<NumberDisplay
											items={[
												{
													title: '모집된 연구 대상자',
													key: 'PATIENT_COUNT',
													value: {
														stringValue: `${patientCount}${
															estSubjects != null ? ` / ${estSubjects}` : ''
														}`,
													},
												},
												{
													title: '수집 기록 수',
													key: 'RECORD_COUNT',
													value: {
														intValue: recordCount,
													},
												},
												{
													title: '의사 평균 수집 대상자',
													key: 'PATIENT_COUNT',
													floorDecimal: 1,
													value: {
														floatValue: patientCount / doctorCount,
													},
												},
												{
													title: '연구 대상자 평균 연령',
													key: 'PATIENT_COUNT',
													value: {
														intValue: patientCount,
													},
												},
											]}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row columns={2}>
									<Grid.Column>
										{estSubjects && (
											<StatItem
												title="수집 진행 상황"
												content={
													<ProgressBar
														progress="percent"
														data={[
															{
																key: 'PATIENT_COUNT',
																total: estSubjects,
																value: patientCount,
																color: 'green',
																label: `수집 대상자 수 (${patientCount} / ${estSubjects})`,
															},
															{
																key: 'PATIENT_COUNT',
																total: moment(startDate).diff(endDate, 'days'),
																value: moment(startDate).diff(new Date(), 'days'),
																color: 'green',
																label: `경과기간`,
															},
														]}
														descRight={
															<DescriptionText>
																진행 기간 {toCommonDateString(startDate)} ~{' '}
																{toCommonDateString(endDate)}
															</DescriptionText>
														}
														// descLeft={
														// 	<DescriptionText>
														// 		수집대상자 수({patientCount}) / 목표대상자 수({estSubjects})
														// 	</DescriptionText>
														// }
													/>
												}
											/>
										)}
										<StatItem
											title="대상자 성비"
											content={
												<RatioPieChart
													width={BASIC_CHART_WIDTH}
													hasLegendTable
													innerRadius={0}
													outerRadius={90}
													data={genderRatios}
												/>
											}
										/>
									</Grid.Column>
									<Grid.Column>
										<StatItem
											title="대상자 세대 분포"
											content={
												<RatioPieChart
													width={BASIC_CHART_WIDTH}
													hasLegendTable
													innerRadius={0}
													outerRadius={90}
													data={genRatios}
												/>
											}
										/>
									</Grid.Column>
								</Grid.Row>
							</StatsGrid>
						</PageContainer>
					)
				}
			}}
		</Query>
	)
}

ResearchStatisticsDashboard.propTypes = {
	researchId: PropTypes.number,
	patientCount: PropTypes.number,
	recordCount: PropTypes.number,
	doctorCount: PropTypes.number,
	renderCustomizedLabel: PropTypes.func,
	estSubjects: PropTypes.number,
}

export default ResearchStatisticsDashboard
