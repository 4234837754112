import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useSelectInput from './useSelectInput'
import useCheckboxRadio from './useCheckboxRadio'

import { OptionContainer, StyledCheckbox } from './Styled'
import DataFieldTextInput from './DataFieldTextInput'

/**./useCheckboxRadio
 * Data Field Radio
 *
 * 라디오버튼
 */
const DataFieldRadio = ({
	valueKey = 'value',
	value,
	readOnly,
	onUpdate,
	selectOptions,
	medInfoId,
	format,
	disabled,
	optionValue,
}) => {
	const [isUsingNewLine] = useCheckboxRadio(format)

	return (
		<span>
			<OptionContainer className="df-checkbox">
				{selectOptions.map((item, index) => {
					const key = `${medInfoId}-${item.value || index}`
					return (
						<span key={key}>
							<StyledCheckbox
								radio
								readOnly={readOnly}
								disabled={disabled}
								isUsingNewLine={isUsingNewLine}
								label={item.text}
								value={item.value}
								checked={value === item.value}
								onChange={() => onUpdate(valueKey, item.value)}
							/>
							{item.hasText && value === item.value && (
								<FreeInput
									readOnly={readOnly}
									disabled={disabled}
									labelPosition="left"
									valueKey="optionValue"
									onUpdate={onUpdate}
									value={optionValue}
								/>
							)}
						</span>
					)
				})}
			</OptionContainer>
		</span>
	)
}

DataFieldRadio.propTypes = {
	valueKey: PropTypes.string,
	value: PropTypes.string,
	onUpdate: PropTypes.func,
	readOnly: PropTypes.bool,
	selectOptions: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string,
			value: PropTypes.string,
		})
	),
	medInfoId: PropTypes.number,
	format: PropTypes.string,
	disabled: PropTypes.bool,
	optionValue: PropTypes.string,
}

export default DataFieldRadio

const FreeInput = styled(DataFieldTextInput)`
	padding: 5px;
	width: 213px;
`
