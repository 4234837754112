import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button, Icon, Modal } from 'semantic-ui-react'
import withChartEditor from '../../../higherComponents/withChartEditor'

import ChartingBoard from '../../charting/ChartingBoard'
import { ResearchType, CommentEntityType } from '../../../enum'

import Comments from '../../../containers/Comments'
import FloatingButton from '../../common/basic/FloatingButton'

const styles = {
	charting: {
		marginTop: 30,
		paddingBottom: 30,
	},
	desc: {
		marginTop: 20,
	},
}

/**
 * 프로젝트 진료기록 상세보기
 */
class ResearchRecordChartView extends PureComponent {
	static propTypes = {
		medInfos: PropTypes.array,
		client: PropTypes.object,
		researchId: PropTypes.number,
		onLoading: PropTypes.func,
		onShowHeaderMessage: PropTypes.func,
		match: PropTypes.object,
		onChangeMessage: PropTypes.func,
		researches: PropTypes.array,
		researchType: PropTypes.string,
		creator: PropTypes.object,
		userInfo: PropTypes.object,
		id: PropTypes.number,
		anchorOpenComment: PropTypes.number,
		onSubmit: PropTypes.func,
		setMedInfos: PropTypes.func,
	}

	constructor(props) {
		super(props)

		this.state = {
			isEdit: false,
			saveModalOpen: false,
			originMedInfos: [],
		}
	}

	handleCloseModal = () => {
		this.setState({ saveModalOpen: false })
	}

	render() {
		const {
			id,
			anchorOpenComment,
			onSubmit,
			medInfos,
			researches,
			researchType,
			creator,
			userInfo,
			setMedInfos,
			userResearcherInfo: { canEdit },
		} = this.props

		const isWriter = creator.id === userInfo.id
		const { isEdit, saveModalOpen, originMedInfos } = this.state

		return (
			<div style={styles.charting}>
				<ChartingBoard
					medInfos={medInfos}
					researches={researches}
					{...this.props}
					readOnly={!isWriter && !isEdit}
					canWriteQuery={!isWriter && researchType === ResearchType.SCHEDULED.key}
				/>
				{isWriter === true && (
					<SaveButton>
						<Button
							primary
							size="big"
							onClick={() => {
								onSubmit()
							}}
						>
							저장하기
						</Button>
					</SaveButton>
				)}
				<Comments
					useNick={false}
					entityType={CommentEntityType.RECORD.value}
					entityId={id}
					anchorOpenComment={anchorOpenComment}
				/>
				{!isWriter && canEdit && (
					<ButtonsWrapper>
						<FloatingButton
							top={100}
							left={isEdit ? 40 : -30}
							onClick={() => {
								this.setState({ saveModalOpen: true })
							}}
							visible={isEdit}
							absolute
						>
							<Icon name="x" />
						</FloatingButton>
						<FloatingButton
							primary
							top={100}
							left={-30}
							onClick={() => {
								if (isEdit === true) {
									onSubmit()
									this.setState({ isEdit: false })
								} else {
									this.setState({ isEdit: true, originMedInfos: medInfos })
								}
							}}
							absolute
						>
							{isEdit === true ? '저장' : <Icon name="pencil" />}
						</FloatingButton>
					</ButtonsWrapper>
				)}
				<Modal open={saveModalOpen} size="mini" onClose={this.handleCloseModal}>
					<Modal.Content>변경내역을 저장하시겠습니까?</Modal.Content>
					<Modal.Actions>
						<Button
							onClick={() => {
								this.handleCloseModal()
								this.setState({ isEdit: false })
								setMedInfos(originMedInfos)
							}}
						>
							아니오
						</Button>
						<Button
							primary
							onClick={() => {
								onSubmit()
								this.handleCloseModal()
								this.setState({ isEdit: false })
							}}
						>
							예
						</Button>
					</Modal.Actions>
				</Modal>
			</div>
		)
	}
}

export default withChartEditor(ResearchRecordChartView)

const SaveButton = styled.div`
	text-align: right;
	margin-top: 20px;
	.ui.button {
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	}
`

const ButtonsWrapper = styled.div`
	position: fixed;
	top: 0px;
`
