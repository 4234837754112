import moment from 'moment'
import _ from 'lodash'

export const convertGender = sexMale => {
	if (sexMale === true) {
		return '남자'
	}
	return '여자'
}

export const convertBirthDate = birthDate => {
	return moment(birthDate).format('YYYY년 MM월 DD일')
}

export const getAge = birthDate => {
	return `${moment().diff(birthDate, 'years')} 세`
}

export const getAgeRange = birthDate => {
	const age = moment().diff(birthDate, 'years')
	const gen = Math.floor(age / 10) * 10
	const digit1 = age % 10
	let res

	if (gen === 0) {
		if (digit1 >= 6) {
			res = '소아'
		} else if (digit1 >= 1) {
			res = '유아'
		} else {
			res = '영아'
		}
	} else {
		if (digit1 >= 7) {
			res = '후반'
		} else if (digit1 >= 3) {
			res = '중반'
		} else {
			res = '초반'
		}
		res = `${gen}대 ${res}`
	}

	return res
}

// 동일 배열 비교
export const arraysEqual = (a, b) => {
	if (a === b) return true
	if (a == null || b == null) return false
	if (a.length != b.length) return false

	for (let i = 0; i < a.length; ++i) {
		if (a[i] !== b[i]) return false
	}
	return true
}

// 너무 긴 String 줄이고 말줄임표
export const truncateString = (str, maxLen) => {
	let result = ''

	result = str && str.length > maxLen + 2 ? `${str.slice(0, maxLen)}..` : str

	if (result === null) {
		return ''
	}
	return result
}

// Object 배열을 문자열로 검색
export const searchObject = (query, items, fields) => {
	const sq = query.trim()
	return items.filter(item => {
		return fields.some(field => {
			const it = field.split('.').reduce((it, f) => {
				return it[f]
			}, item)

			if (typeof it === 'string') return it.includes(sq)
		})
	})
}

// 브라우저가 익스플로러인지 확인
export const detectIe = () => {
	const ua = window.navigator.userAgent

	const msie = ua.indexOf('MSIE')

	if (msie > 0) {
		// IE 10
		return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
	}

	const trident = ua.indexOf('Trident/')

	if (trident > 0) {
		// ie 11 --> version 번호를 리턴
		const rv = ua.indexOf('rv')
		return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
	}

	const edge = ua.indexOf('Edge/')

	if (edge > 0) {
		return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
	}

	return false
}

export const filterInvalidInputField = (item, validCols) => {
	return _.pick(item, validCols)
}

export const filterInvalidInputFields = (items, validCols) => {
	if (items instanceof Array) {
		return items.map(item => filterInvalidInputField(item, validCols))
	}

	return null
}
