import { COLOR_PURPLE, COLOR_PINK } from '../constants/styles'
import { StatisticsRequestType, TableStatsKeys } from '../enum'
import { TABLE_STATS_COUNT_DATA_KEY } from '../constants'

/**
 * 통계 값 가져오기
 */
export const getStatResult = (results, keys) => {
	return results
		.filter(r => keys.indexOf(r.type) >= 0)
		.map(i => {
			return {
				...i,
				...StatisticsRequestType[i.type],
			}
		})
}

/**
 * 성비 데이터 색상 처리
 */
export const processSexRatio = dataSexRatio => {
	if (dataSexRatio.length > 0) {
		dataSexRatio[0].ratios = dataSexRatio[0].ratios.map(i => {
			if (i.name === '남성') {
				i.color = COLOR_PURPLE
			} else {
				i.color = COLOR_PINK
			}
			return i
		})
	}

	return dataSexRatio
}

/**
 * 테이블 자료 처리
 */
export const processTableData = (results, key) => {
	let result
	const data = getStatResult(results, [key])
	if (data.length > 0) {
		const { columns, rows } = data[0]
		const idxName = columns.findIndex(c => c.name === TableStatsKeys.ITEM_NAME)
		const idxCount = columns.findIndex(c => c.name === TableStatsKeys.ITEM_COUNT)

		if (rows != null) {
			result = rows.map(row => {
				const { values } = row
				if (values != null) {
					return {
						name: values[idxName].stringValue,
						[TABLE_STATS_COUNT_DATA_KEY]: values[idxCount].intValue,
					}
				}
			})
		}
	}

	return result
}
