import gql from 'graphql-tag'
import { PATIENT_FRAGMENT } from './patient'

export const RECORD_FRAGMENT = gql`
	fragment RecordParts on Record {
		id
		visitedDate
		isFirst
		isNewRecord
		code
		type
		summary
		description
		createdAt
		creator {
			id
			name
			institution {
				id
				name
			}
		}
		recordTemplateChangeId
		template {
			id
			title
			type
			shouldReserve
			job
			days
			daysRange
		}
		isComplete
	}
`

export const PATIENT_RECORD_FRAGMENT = gql`
	fragment PatientRecordParts on Record {
		...RecordParts
		patient {
			...PatientParts
		}
		template {
			id
			title
			type
			job
			shouldReserve
		}
		researches {
			id
			title
			type
			templateType
			hashPatient
			patientBasicInfo
			requiresAgreement
			hideName
		}
	}
	${RECORD_FRAGMENT}
	${PATIENT_FRAGMENT}
`

export const TEMPLATE_BASIC_INFO = gql`
	fragment TemplateBasicInfo on RecordTemplate {
		id
		title
		type
		job
		shouldReserve
		days
		daysRange
		doneBy
		isSchedule
		isPartial
	}
`
