import { useReducer, useState, useMemo, useCallback, useEffect } from 'react'
import { PATIENT_INITIAL_STATE, DEFAULT_RESEARCH } from '../constants'

import {
	AGREEMENT_NOT_REQUIRED_MESSAGE,
	AGREEMENT_WARNING_MESSAGE,
	AGREEMENT_ACTUAL_MESSAGE,
	AGREEMENT_CHECK_MESSAGE,
} from '../lang'
import { ResearchAgreementCheckType } from '../enum'
import useUserActiveResearches from './useUserActiveResearches'

const patientReducer = (state, action) => {
	if (action.type === 'reset') {
		return {
			...PATIENT_INITIAL_STATE,
		}
	} else {
		return { ...state, [action.key]: action.value }
	}
}

/**
 * 환자 생성하기 처리 로직
 */
const useAddPatient = ({ defaultResearchId, shouldAutoChooseSingle } = {}) => {
	/**
	 * 프로젝트 목록 불러오기
	 */
	const { researches, loading: researchLoading, error: researchError } = useUserActiveResearches()

	const [patientInfo, dispatchState] = useReducer(patientReducer, PATIENT_INITIAL_STATE)
	const [researchId, setResearchId] = useState(defaultResearchId)
	const [hasAgreed, setHasAgreed] = useState(false)

	// 사용자가 단일 연구일 경우 연구를 선택할 수 없도록 해야할 때
	const hasSingleResearch =
		shouldAutoChooseSingle === true && defaultResearchId == null && researches.length === 1
	useEffect(() => {
		if (hasSingleResearch === true) {
			setResearchId(researches[0].id)
		}
	}, [defaultResearchId, hasSingleResearch, researches, shouldAutoChooseSingle])

	const resetPatientInfo = () => dispatchState({ type: 'reset' })

	const researchItems = useMemo(() => {
		return [...researches, DEFAULT_RESEARCH]
	}, [researches])

	/**
	 * 연구 선택 시 research object를 목록에서 가져오고 연구에서 추가적으로 요구하는 필드 목록을 확인
	 */
	const { selectedResearch, researchFields } = useMemo(() => {
		if (researchId != null) {
			const selectedResearch = researchItems.find(item => item.id === researchId)
			const researchFields =
				selectedResearch != null && selectedResearch.patientBasicInfo != null
					? selectedResearch.patientBasicInfo.split('|')
					: []

			return {
				selectedResearch,
				researchFields,
			}
		} else {
			return {}
		}
	}, [researchId, researchItems])

	const { requiresAgreement, agreementCheckType } = selectedResearch || {}

	const { message: agreementMessage, hasCheckbox } = useMemo(() => {
		let message, hasCheckbox
		if (requiresAgreement !== true) {
			message = AGREEMENT_NOT_REQUIRED_MESSAGE
			hasCheckbox = false
		} else {
			if (agreementCheckType === ResearchAgreementCheckType.WARNING.value) {
				message = AGREEMENT_WARNING_MESSAGE
				hasCheckbox = false
			} else if (agreementCheckType === ResearchAgreementCheckType.PAPER_IMAGE.value) {
				message = AGREEMENT_ACTUAL_MESSAGE
				hasCheckbox = false
				//TODO: 동의서 업로드 로직
			} else {
				hasCheckbox = true
				message = AGREEMENT_CHECK_MESSAGE
			}
		}

		return {
			message,
			hasCheckbox,
		}
	}, [agreementCheckType, requiresAgreement])

	const toggleSelectResearch = useCallback(
		research => {
			if (researchId !== null) {
				// 프로젝트 선택된 상태에서 다시 클릭했을 경우
				setResearchId(null)
			} else {
				// 프로젝트 선택 안된상태에서 선택하기
				setHasAgreed(false)
				setResearchId(research.id)
			}
		},
		[researchId]
	)

	/**
	 * 입력값 변경
	 */
	const handleChange = (e, { name, value }) => {
		dispatchState({
			key: name,
			value,
		})
	}

	const handleChangeBirthDate = (e, { value }) => {
		dispatchState({
			key: 'birthDate',
			value: `${value}-01-01`,
		})
	}

	return {
		patientInfo,
		resetPatientInfo,
		researchId,
		hasAgreed,
		handleChange,
		handleChangeBirthDate,
		toggleSelectResearch,
		researchLoading,
		researchError,
		agreementMessage,
		hasCheckbox,
		researchFields,
		selectedResearch,
		setHasAgreed,
		researchItems,
		researches,
	}
}

export default useAddPatient
