import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import AuthRequired from './AuthRequired'
import MobileNotSupported from '../../../containers/MobileNotSupported'

/**
 * Auth Required 체크하는 기본 루트
 */
const PropRoute = ({ component: Component, props, hasMobile, authRequired, ...others }) => {
	return (
		<Route
			{...others}
			render={routeProps => {
				if (hasMobile !== true && isMobile === true) {
					return <MobileNotSupported />
				}

				if (authRequired === true) {
					return <AuthRequired render={Component} routerProps={routeProps} {...props} {...others} />
				}

				return <Component {...props} {...routeProps} />
			}}
		/>
	)
}

PropRoute.propTypes = {
	props: PropTypes.shape().isRequired,
	component: PropTypes.func.isRequired,
	authRequired: PropTypes.bool,
	hasMobile: PropTypes.bool,
}

PropRoute.defaultProps = {
	authRequired: false,
}

export default PropRoute
