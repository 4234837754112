import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import { Segment } from 'semantic-ui-react'

import { GET_TERMS } from '../../../graphql/queries/app'
import TermAgreementView from './TermAgreementView'
import MiniErrorView from '../../common/error/MiniErrorView'

/**
 * 약관 동의 박스
 */
export default class TermAgreements extends PureComponent {
	static propTypes = {
		termType: PropTypes.string.isRequired,
		agreed: PropTypes.bool.isRequired,
		onToggle: PropTypes.func.isRequired,
	}

	render() {
		const { termType, agreed, onToggle } = this.props
		return (
			<Segment>
				<Query query={GET_TERMS} variables={{ type: termType }}>
					{({ loading, data, error }) => {
						if (error) {
							return <MiniErrorView />
						}

						return (
							<TermAgreementView
								text={data && data.terms && data.terms.text}
								onToggle={onToggle(termType)}
								agreed={agreed}
								loading={loading}
							/>
						)
					}}
				</Query>
			</Segment>
		)
	}
}
