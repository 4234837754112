import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

class ScrollToTop extends PureComponent {
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0)
		}
	}

	render() {
		return this.props.children
	}
}
// prop types 추가
ScrollToTop.propTypes = {
	children: PropTypes.object,
	location: PropTypes.object,
}

export default withRouter(ScrollToTop)
