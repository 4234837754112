import React from 'react'
import PropTypes from 'prop-types'
import { Form, Select, Grid } from 'semantic-ui-react'
import moment from 'moment'
import _ from 'lodash'
import PatientResearchForm from './PatientResearchForm'
import SegmentToggle from '../common/basic/SegmentToggle'

const PatientForm = props => {
	const {
		onChange,
		onChangeBirthDate,
		name,
		patientCode,
		birthDate,
		sexMale,
		isTest,
		isHashed,
		researchFields,
	} = props
	return (
		<Form>
			<Grid columns={2}>
				<Grid.Column>
					<Form.Input
						fluid
						name="name"
						label="이름"
						placeholder={isHashed === true ? '익명' : '이름'}
						readOnly={isHashed}
						value={name}
						onChange={onChange}
					/>
				</Grid.Column>
				{(!researchFields || researchFields.indexOf('birthDateReal') < 0) && (
					<Grid.Column>
						<Form.Field>
							<label>출생연도</label>
							<Select
								options={_.range(moment().year(), 1900, -1).map(item => {
									const v = `${item}`
									return {
										key: v,
										value: v,
										text: v,
									}
								})}
								name="birthDate"
								value={birthDate.slice(0, 4)}
								onChange={onChangeBirthDate}
							/>
						</Form.Field>
					</Grid.Column>
				)}
				{!isTest && (
					<Grid.Column>
						<Form.Input
							fluid
							name="patientCode"
							label="병록(차트)번호"
							placeholder="병록(차트)번호"
							value={patientCode}
							onChange={onChange}
						/>
					</Grid.Column>
				)}
				<Grid.Column>
					<Form.Field fluid compact name="sexMale" value={sexMale} onChange={onChange}>
						<label>성별</label>
						<SegmentToggle
							fluid
							active={sexMale}
							onChange={value => onChange(null, { value, name: 'sexMale' })}
							items={[
								{
									key: 'male',
									value: true,
									text: '남성',
								},
								{
									key: 'female',
									value: false,
									text: '여성',
								},
							]}
						/>
					</Form.Field>
				</Grid.Column>
				<PatientResearchForm
					researchFields={researchFields}
					values={props}
					onChange={onChange}
					onChangeBirthDate={onChangeBirthDate}
				/>
			</Grid>
		</Form>
	)
}

PatientForm.propTypes = {
	onChange: PropTypes.func,
	onChangeBirthDate: PropTypes.func,
	name: PropTypes.string,
	patientCode: PropTypes.string,
	birthDate: PropTypes.string,
	sexMale: PropTypes.bool,
	isTest: PropTypes.bool,
	researchFields: PropTypes.array,
	isHashed: PropTypes.bool,
}

export default PatientForm
