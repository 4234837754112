import React from 'react'
import { Input } from 'semantic-ui-react'

const ToggledInput = ({
	input: InputComponent = Input,
	style,
	isEditing,
	name,
	value,
	onChange,
	fluid,
	options,
	placeholder,
	inputType,
}) => {
	if (isEditing === true) {
		return (
			<InputComponent
				className="toggled-input"
				style={style}
				fluid={fluid}
				name={name}
				value={value}
				options={options}
				type={inputType}
				placeholder={placeholder}
				onChange={(e, { value }) => onChange(name, value)}
			/>
		)
	} else return <span style={style}>{value}</span>
}

export default ToggledInput
