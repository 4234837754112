import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'
import { CHART_LINE_COLORS } from '../../constants/styles'
import LegendTable from './VerticalLegend'

const colors = CHART_LINE_COLORS
const THIS_YEAR = moment().year()
const dateTickFormatter = function(tickItem) {
	return moment(tickItem)
		.format('YYYY-MM-DD')
		.replace(`${THIS_YEAR}-`, '')
}

/**
 * 환자 증상 VAS 기록 차트
 */
class VasChart extends PureComponent {
	render() {
		const { progress } = this.props

		const data = []
		const keys = new Set()

		const lines = []

		for (const item of progress) {
			const obj = {
				visitedDate: moment(item.visitedDate).valueOf(),
			}

			for (var status of item.symptoms) {
				const { name, value } = status
				obj[name] = value
				keys.add(name)
			}

			data.push(obj)
		}

		var vc = 0
		for (var i of keys) {
			lines.push(<Line key={vc} type="monotone" dataKey={i} fill="#fff" stroke={colors[vc]} />)
			vc = vc + 1
		}

		return (
			<div>
				<LineChart width={920} height={500} data={data} style={{ margin: 'auto' }}>
					{lines}
					<CartesianGrid />
					<XAxis
						tick
						tickFormatter={dateTickFormatter}
						key="xAxis0"
						angle={-30}
						textAnchor="end"
						type="number"
						domain={['auto', 'auto']}
						scale="time"
						dataKey="visitedDate"
					/>
					<YAxis key="yAxis0" domain={[0, 100]} />
					<Tooltip labelFormatter={dateTickFormatter} />
					<Legend layout="vertical" align="left" verticalAlign="top" content={LegendTable} />
				</LineChart>
				<div style={{ marginTop: 20, textAlign: 'center', color: '#777' }}>
					*그래프 상의 값은 최대값 100을 기준으로 보정되어, 낮아질수록 호전됨을 나타냅니다.
				</div>
			</div>
		)
	}
}

VasChart.propTypes = {
	progress: PropTypes.array,
}

export default VasChart
