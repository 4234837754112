import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
	Form,
	Input,
	Search,
	Checkbox,
	Button,
	Header,
	Modal,
} from 'semantic-ui-react/dist/commonjs'
import { useMutation } from 'react-apollo'

import ResearchMemberRow from './ResearchMemberRow'
import { ResearcherRole, ResearcherRight } from '../../../../enum'

import PlusIcon from '../../../../assets/images/icons/plus-20.svg'
import { COLOR_BORDER_GRAY } from '../../../../constants/styles'
import { ROUTE_PROJECT_DETAIL_SETTINGS_MEMBERS_DETAIL_DEFAULT } from '../../../../constants/routes'
import { replaceParams } from '../../../../libs/routeHelper'
import errorTypes from '../../../../errorTypes'
import {
	ERROR_MESSAGE_RESEARCH_MEMBER_DUPLICATE,
	ERROR_MESSAGE_RESEARCH_MEMBER_ALREADY_JOINED,
} from '../../../../lang'
import { CANCEL_RESEARCH_INVITATION } from '../../../../graphql/mutations/research'
import { GET_RESEARCH_MEMBERS } from '../../../../graphql/queries/research'

const ROLES = Object.values(ResearcherRole).filter(item => item.key !== 'DIRECTOR')

const RESEARCHER_INFO_INPUT = [
	'role',
	'canAccess',
	'canEdit',
	'canFixTemplate',
	'canInput',
	'canChangeSettings',
	'isBlinded',
	'status',
	'description',
]

/**
 * 연구자 관리 페이지
 */
export default class ResearchMembersView extends PureComponent {
	static propTypes = {}

	state = {
		isInviteModalOpen: false,
		isSettingModalOpen: false,
		isInviteModalLoading: false,
		inviteUser: null,
		inviteUserRole: ROLES[0].value,
		inviteUserSearchIsValid: false,
		inviteUserSearchResults: [],
		inviteUserSearchInput: '',
		settingsModalUser: null,
		settingsModalLoading: false,
		isInviteCancleModalOpen: false,
		inviteCancleUserName: null,
		inviteCancleUserId: null,
	}

	handleCloseInviteModal = () =>
		this.setState({
			isInviteModalOpen: false,
			isInviteModalLoading: false,
		})

	handleOpenInviteModal = () =>
		this.setState({
			isInviteModalOpen: true,
			inviteUserSearchIsValid: false,
			inviteUserSearchResults: [],
			isInviteModalLoading: false,
		})

	handleCloseInviteCancleModal = () =>
		this.setState({
			isInviteCancleModalOpen: false,
			inviteCancleUserName: null,
			inviteCancleUserId: null,
		})

	handleOpenInviteCancleModal = ({ id, name }) =>
		this.setState({
			isInviteCancleModalOpen: true,
			inviteCancleUserName: name,
			inviteCancleUserId: id,
		})

	handleInviteUser = async () => {
		const { inviteUser, inviteUserSearchInput, inviteUserRole } = this.state
		const { researchId, onInviteUser, onUpdate, onShowToastMessage } = this.props

		this.setState({
			isInviteModalLoading: true,
		})

		const isEmail = inviteUserSearchInput.includes('@')

		if (inviteUser != null || isEmail === true) {
			let id, title
			if (inviteUser != null) {
				;({ id, title } = inviteUser)
			}

			try {
				await onInviteUser({
					variables: {
						researchId,
						userId: id,
						email: isEmail === true ? inviteUserSearchInput : null,
						role: inviteUserRole,
					},
					update: () => {
						this.handleCloseInviteModal()
						onShowToastMessage({
							content: `${inviteUser.description}(${title}) 님에게 초대를 보냈습니다.`,
							success: true,
							autoDismiss: true,
						})
						onUpdate()
					},
				})
			} catch (e) {
				const errorMessage = e.graphQLErrors != null && e.graphQLErrors[0].message
				if (errorMessage === errorTypes.DUPLICATE_ERROR.message) {
					alert(ERROR_MESSAGE_RESEARCH_MEMBER_DUPLICATE)
				} else if (errorMessage === errorTypes.ALREADY_JOINED_RESEARCH_ERROR.message) {
					alert(ERROR_MESSAGE_RESEARCH_MEMBER_ALREADY_JOINED)
				} else {
					alert('오류가 발생했습니다.')
				}
				this.setState({
					isInviteModalLoading: false,
				})
			}
		} else {
			alert('사용자를 선택해 주세요.')
		}
	}

	handleInviteUserRole = (e, { value }) => {
		this.setState({
			inviteUserRole: value,
		})
	}

	handleSearchUsers = async (e, { value }) => {
		const { onSearchUsers } = this.props

		const isEmail = value.includes('@')
		if (isEmail === true) {
			this.setState({
				inviteUserSearchResults: [
					{
						id: null,
						title: value,
						isEmail: true,
						description: '이메일로 프로젝트 초대 전송',
					},
				],
				inviteUserSearchInput: value,
			})
		} else {
			const users = await onSearchUsers(value)
			if (users) {
				this.setState({
					inviteUserSearchResults: users.map(user => ({
						id: user.id,
						title: user.username,
						description: user.name,
					})),
					inviteUserSearchIsValid: false,
				})
			} else {
				this.setState({
					inviteUserSearchResults: [],
					inviteUserSearchIsValid: false,
				})
			}
		}
	}

	handleSelectUser = (e, { result }) => {
		this.setState({
			inviteUser: result,
			inviteUserSearchIsValid: true,
			inviteUserSearchInput: result.title,
		})
	}

	handleOpenSettingsModal = user => {
		this.setState({
			isSettingModalOpen: true,
			settingsModalUser: user,
		})
	}

	handleCloseSettingsModal = () =>
		this.setState({
			isSettingModalOpen: false,
			settingsModalUser: null,
			settingsModalLoading: false,
		})

	handleChangeSettingModal = key => (event, { value }) => {
		this.setState(prevState => ({
			settingsModalUser: {
				...prevState.settingsModalUser,
				[key]: value,
			},
		}))
	}

	handleCheckboxChangeSettingModal = key => (event, { checked }) => {
		this.setState(prevState => ({
			settingsModalUser: {
				...prevState.settingsModalUser,
				[key]: checked,
			},
		}))
	}

	handleSaveSettingModal = async () => {
		const { settingsModalUser } = this.state
		const { onUpdateResearcher, researchId, onUpdate } = this.props

		this.setState({
			settingsModalLoading: true,
		})

		const researcherInfo = Object.keys(settingsModalUser)
			.filter(key => RESEARCHER_INFO_INPUT.includes(key))
			.reduce((obj, key) => {
				obj[key] = settingsModalUser[key]
				return obj
			}, {})

		await onUpdateResearcher({
			variables: {
				researchId,
				userId: settingsModalUser.id,
				researcherInfo,
			},
		})

		this.handleCloseSettingsModal()
		onUpdate()
	}

	handleRemoveResearcher = async () => {
		const { settingsModalUser } = this.state
		const { researchId, onRemoveResearcher, onUpdate } = this.props
		if (window.confirm(`정말 ${settingsModalUser.name} 님을 연구원 목록에서 삭제하시겠습니까?`)) {
			this.setState({
				settingsModalLoading: true,
			})

			await onRemoveResearcher({
				variables: {
					researchId,
					userId: settingsModalUser.userId,
				},
			})

			this.handleCloseSettingsModal()
			onUpdate()
		}
	}

	handleMoveToSettings = id => {
		// console.log(id)
		const { history, researchId } = this.props
		history.push(
			replaceParams(ROUTE_PROJECT_DETAIL_SETTINGS_MEMBERS_DETAIL_DEFAULT, {
				id: researchId,
				subId: id,
			})
		)
	}

	render() {
		const { researchers, researchId, invitedResearchers, onShowToastMessage } = this.props
		const {
			settingsModalUser,
			isSettingModalOpen,
			inviteUserSearchIsValid,
			isInviteModalOpen,
			isInviteModalLoading,
			inviteUserSearchResults,
			inviteUserRole,
			settingsModalLoading,
			isInviteCancleModalOpen,
			inviteCancleUserName,
			inviteCancleUserId,
		} = this.state

		return (
			<div>
				<InviteModal
					open={isInviteModalOpen}
					onClose={this.handleCloseInviteModal}
					onInvite={this.handleInviteUser}
					onSearchUser={this.handleSearchUsers}
					onSelectUser={this.handleSelectUser}
					onChangeRole={this.handleInviteUserRole}
					searchResults={inviteUserSearchResults}
					isValid={inviteUserSearchIsValid}
					role={inviteUserRole}
					loading={isInviteModalLoading}
				/>
				<SettingsModal
					open={isSettingModalOpen}
					onClose={this.handleCloseSettingsModal}
					onSave={this.handleSaveSettingModal}
					onRemove={this.handleRemoveResearcher}
					onChange={this.handleChangeSettingModal}
					onChangeCheckbox={this.handleCheckboxChangeSettingModal}
					loading={settingsModalLoading}
					{...settingsModalUser}
				/>
				<InviteCancleModal
					open={isInviteCancleModalOpen}
					onClose={this.handleCloseInviteCancleModal}
					name={inviteCancleUserName}
					id={inviteCancleUserId}
					researchId={researchId}
					onShowToastMessage={onShowToastMessage}
				/>
				<AddMemberButton onClick={this.handleOpenInviteModal}>
					<PlusIconImage src={PlusIcon} /> 프로젝트 멤버 초대
				</AddMemberButton>
				<div style={styles.memberContainer}>
					{/* <TableSearch placeholder="참여중인 멤버를 검색하세요" /> */}
					{invitedResearchers.map(researcher => (
						<ResearchMemberRow
							key={`${researcher.id}`}
							researchId={researchId}
							user={researcher.user}
							researcherInfo={{ role: researcher.role }}
							invitation={{
								id: researcher.id,
								email: researcher.email,
								createdAt: researcher.createdAt,
								onCancle: this.handleOpenInviteCancleModal,
							}}
						/>
					))}
					{researchers.map(researcher => (
						<ResearchMemberRow
							key={`${researcher.user.id}`}
							onMoveToSettings={this.handleMoveToSettings}
							researchId={researchId}
							{...researcher}
						/>
					))}
				</div>
			</div>
		)
	}
}

/**
 * 연구자 초대 모달
 */
const InviteModal = ({
	open,
	onClose,
	onInvite,
	isValid,
	loading,
	searchResults,
	role,
	onChangeRole,
	onSearchUser,
	onSelectUser,
}) => (
	<Modal size="tiny" closeIcon open={open} onClose={onClose} dimmer={false}>
		<Modal.Header>프로젝트 멤버 초대</Modal.Header>
		<Modal.Content>
			<Form>
				<Form.Field
					id="add-user-id"
					placeholder="사용자 ID, 이름, 이메일을 검색하세요."
					label="프로젝트에 초대할 사용자를 선택하세요"
					control={Search}
					noResultsMessage="사용자가 존재하지 않습니다."
					onSearchChange={onSearchUser}
					onResultSelect={onSelectUser}
					results={searchResults}
				/>
				<Form.Select
					id="add-user-role"
					selection
					value={role}
					onChange={onChangeRole}
					options={ROLES}
					placeholder="역할을 선택해주세요."
					label="초대할 사용자의 역할을 선택하세요"
				/>
			</Form>
		</Modal.Content>
		<Modal.Actions>
			<Button
				loading={loading}
				onClick={onInvite}
				primary
				key="invite"
				disabled={!isValid || !role}
			>
				초대
			</Button>
		</Modal.Actions>
	</Modal>
)

InviteModal.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onInvite: PropTypes.func.isRequired,
}

const SettingsModal = props => {
	const {
		open,
		onClose,
		onSave,
		onRemove,
		name,
		role,
		isBlinded,
		description,
		loading,
		onChange,
		onChangeCheckbox,
	} = props
	const isNotDirector = role !== 'DIRECTOR'
	return (
		<Modal size="tiny" open={open} onClose={onClose} dimmer={false}>
			<Modal.Header>연구원 설정</Modal.Header>
			<Modal.Content>
				<Header as="h4">{name}</Header>
				<Form>
					{isNotDirector ? (
						<Form.Select
							inline
							id="settings-user-role"
							selection
							options={ROLES}
							value={role}
							placeholder="역할을 선택해주세요."
							label="역할 변경"
							onChange={onChange('role')}
						/>
					) : (
						<Form.Field value="연구 책임자(PI)" disabled control={Input} />
					)}

					<label style={styles.label}>권한</label>
					<div style={styles.rightsContainer}>
						{Object.keys(ResearcherRight).map(key => (
							<Checkbox
								key={key}
								style={styles.checkbox}
								onChange={onChangeCheckbox(key)}
								checked={props[key]}
								disabled={isNotDirector === false && key === 'canChangeSettings'}
								label={ResearcherRight[key].text}
							/>
						))}
					</div>
					<Checkbox
						style={styles.checkbox}
						onChange={onChangeCheckbox('isBlinded')}
						checked={isBlinded}
						label="눈가림 설정"
					/>
					<Form.Field
						label="담당 업무"
						control={Input}
						value={description}
						onChange={onChange('description')}
						placeholder="연구 내에서 담당하는 업무에 대한 간단한 설명을 적어주세요."
					/>
				</Form>
			</Modal.Content>
			<Modal.Actions>
				{isNotDirector && (
					<Button
						loading={loading}
						style={styles.leftBtn}
						onClick={onRemove}
						color="red"
						key="remove"
					>
						삭제
					</Button>
				)}
				<Button loading={loading} onClick={onSave} primary key="save">
					수정
				</Button>
				<Button onClick={onClose} key="cancel">
					취소
				</Button>
			</Modal.Actions>
		</Modal>
	)
}

SettingsModal.propTypes = {
	open: PropTypes.bool.isRequired,
	name: PropTypes.string,
	role: PropTypes.string,
	isBlinded: PropTypes.bool,
	description: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onChangeCheckbox: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
}

SettingsModal.defaultProps = {}

const InviteCancleModal = ({ id, name, open, onClose, researchId, onShowToastMessage }) => {
	const [submitResearchApplication] = useMutation(CANCEL_RESEARCH_INVITATION, {
		variables: {
			id,
		},
		refetchQueries: [
			{
				query: GET_RESEARCH_MEMBERS,
				variables: {
					id: researchId,
				},
			},
		],
	})
	return (
		<Modal open={open} size="mini">
			<Modal.Content>{name}님의 연구 초대를 취소하시겠습니까?</Modal.Content>
			<Modal.Actions>
				<Button onClick={onClose}>아니오</Button>
				<Button
					color="red"
					onClick={() => {
						submitResearchApplication().then(() => {
							onClose()
							onShowToastMessage({
								content: `${name}님의 연구 초대가 취소되었습니다.`,
								success: true,
								error: false,
							})
						})
					}}
				>
					초대 취소
				</Button>
			</Modal.Actions>
		</Modal>
	)
}

InviteCancleModal.propTypes = {
	id: PropTypes.number,
	name: PropTypes.string,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	researchId: PropTypes.number,
	onShowToastMessage: PropTypes.func,
}

const AddMemberButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	border-bottom: 1px solid ${COLOR_BORDER_GRAY};
	cursor: pointer;
	line-height: 20px;
	box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.05);

	:hover {
		opacity: 0.4;
	}
`
const PlusIconImage = styled.img`
	padding: 4px;
`

const styles = {
	container: {
		width: '100%',
	},
	contentContainer: {
		marginTop: 10,
		marginBottom: 50,
	},
	memberContainer: {
		borderLeft: '1px solid #eee',
		borderRight: '1px solid #eee',
		borderTop: '1px solid #eee',
	},
	inlineLabel: {
		width: 100,
		verticalAlign: 'top',
	},
	inlineContent: {
		display: 'inline-block',
	},
	inlineInput: {
		width: 110,
		verticalAlign: 'middle',
	},
	header: {
		marginTop: 20,
		marginBottom: 20,
		paddingBottom: 10,
		borderBottom: '1px solid #eee',
	},
	topItems: {
		marginBottom: 20,
	},
	search: {
		marginRight: 10,
	},
	inviteModal: {
		padding: 20,
	},
	label: {
		fontWeight: 600,
	},
	rightsContainer: {
		marginTop: 10,
		marginBottom: 15,
	},
	checkbox: {
		marginRight: 10,
		marginBottom: 10,
	},
	leftBtn: {
		float: 'left',
	},
}
