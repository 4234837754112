// 페이지 기본 사이즈
export const PAGE_DEFAULT_MIN_WIDTH = 1280
export const PAGE_DEFAULT_SIDE_PADDING = 70
export const PAGE_DEFAULT_MOBILE_PADDING = 30
export const PAGE_MIN_SIDE_PADDING = 30
export const PAGE_CONTENT_FIXED_WIDTH = 1140
export const PAGE_CONTENT_MINI_WIDTH = 672
export const PAGE_CONTENT_SMALL_WIDTH = 784
export const PAGE_CONTENT_MEDIUM_WIDTH = 840
export const PAGE_CONTENT_MAX_WIDTH = 1600
export const PAGE_CONTENT_MAX_WIDTH_EXTRA = 1800
export const PAGE_CONTENT_SMALL_WIDTH_HOR_PADDING = 120

export const PAGE_MOBILE_MAX_WIDTH = 768
export const PAGE_NOT_MOBILE_MIN_WIDTH = 769

export const PAGE_TABLET_MAX_WIDTH = 996

// 헤더 기본 높이
export const HEADER_SMALL_HEIGHT = 80

// 헤더 '스크롤 내림' 처리 기준 높이
export const HEADER_SCROLL_HEIGHT = 50

/**
 * 로고 크기
 */

// 헤더 로고
export const LOGO_LARGE_WIDTH = 118.3
// 사이드 바 로고
export const LOGO_SMALL_WIDTH = 89

/**
 * 사이드바 크기
 */
export const SIDE_BAR_WIDTH = 80
export const SIDE_BAR_RIGHT_MARGIN = 100
export const SUB_SIDE_BAR_WIDTH = 250
export const SUB_SIDE_BAR_PADDING_RIGHT = 16

export const ELLIPSIS_VERTICAL_WIDTH = 30

export const SIDE_BAR_CONTENT_WIDTH =
	PAGE_DEFAULT_MIN_WIDTH - PAGE_DEFAULT_SIDE_PADDING * 2 - SIDE_BAR_WIDTH - SIDE_BAR_RIGHT_MARGIN

export const FOOTER_HEIGHT = 336

export const H1_FONT_SIZE = 64
export const H2_FONT_SIZE = 56
export const H3_FONT_SIZE = 48
export const H4_FONT_SIZE = 40
export const H5_FONT_SIZE = 32
export const H6_FONT_SIZE = 24
export const T1_FONT_SIZE = 20
export const BODY_FONT_SIZE = 14

export const FONT_SIZE = {
	h1: H1_FONT_SIZE,
	h2: H2_FONT_SIZE,
	h3: H3_FONT_SIZE,
	h4: H4_FONT_SIZE,
	h5: H5_FONT_SIZE,
	h6: H6_FONT_SIZE,
	t1: T1_FONT_SIZE,
	body: BODY_FONT_SIZE,
	mini: 12,
	tiny: 13,
	small: 14,
	large: 16,
	big: 18,
	huge: 20,
}

export const BORDER_RADIUS = 2

export const COLOR_PRIMARY = '#389fa2'
export const COLOR_LIGHT_PINK = '#fff2fc'
export const COLOR_SECONDARY_BLUE = '#356cea'

export const COLOR_TEXT_LINK_BLUE = '#40c2f4'

export const COLOR_TEXT = '#484848'
export const COLOR_TEXT_DARKER = '#3e4556'
export const COLOR_TEXT_DARKGRAY = '#767676'
export const COLOR_TEXT_GRAY = '#999'
export const COLOR_TEXT_DARK_GRAY = '#666'
export const COLOR_TEXT_LIGHTGRAY = '#ababab'
export const COLOR_TEXT_LIGHTERGRAY = '#ccc'
export const COLOR_TEXT_YELLOW = '#e9b46e'
export const COLOR_TEXT_ORANGE = '#fe9f25'
export const COLOR_TEXT_NAVY = '#575b5b'

export const COLOR_BACKGROUND_DARK = '#444953'
export const COLOR_BACKGROUND_GRAY = '#f6f8f9'
export const COLOR_BACKGROUND_LIGHT_GRAY = '#fdfdfd'
export const COLOR_BACKGROUND_DARK_GRAY = '#f1f1f1'
export const COLOR_BACKGROUND_DARKER_GRAY = '#e1e1e1'
export const COLOR_BACKGROUND_INPUT_ERROR = '#FFF6F6'
export const COLOR_LIGHT_BACKGROUND_PRIMARY = '#f4f9f9'
export const COLOR_LIGHT_BACKGROUND = '#fffefc'
export const COLOR_BORDER_GRAY = '#e9eced'
export const COLOR_BORDER_DARK_GRAY = '#cacaca'
export const COLOR_BORDER_PRIMARY = '#e8f1f1'
export const COLOR_BORDER_INPUT_ERROR = '#E0B4B4'

export const COLOR_ERROR = '#b14f79'
export const COLOR_WARN = '#fdcf6f'
export const COLOR_SUCCESS = '#70be49'

export const COLOR_PINK = '#f1cae8'
export const COLOR_PURPLE = '#8a94eb'
export const COLOR_RED = '#f08f9f'
export const COLOR_YELLOW = '#fcca88'
export const COLOR_BLUE = '#5e99db'
export const COLOR_BROWN = '#d1996f'
export const COLOR_GREEN = '#70be49'
export const COLOR_DARKBLUE = '#8ea6e6'
export const COLOR_ORANGE = '#f7b95c'

export const COLOR_VIVID_RED = '#e01b1b'
export const COLOR_VIVID_PINK = '#FF0266'
export const COLOR_VIVID_BLUE = '#0336ff'
export const COLOR_VIVID_ORANGE = '#fb7f16'
export const COLOR_VIVID_GREEN = '#20d225'
export const COLOR_VIVID_PURPLE = '#6200EE'
export const COLOR_VIVID_GRAY = '#757575'
export const COLOR_VIVID_YELLOW = '#FFDE03'
export const COLOR_VIVID_BLACK = '#2d2d2d'
export const COLOR_VIVID_TEAL = '#03DAC5'
export const COLOR_VIVID_BROWN = '#b36300'

export const COLOR_BACKGROUND_RED = '#f18484'
export const COLOR_BACKGROUND_YELLOW = '#fdc64b'
export const COLOR_BACKGROUND_BLUE = '#5699e5'
export const COLOR_BACKGROUND_ORANGE = '#ff9a31'
export const COLOR_BACKGROUND_PURPLE = '#8454c3'
export const COLOR_BACKGROUND_CORAL = '#ff9b77'
export const COLOR_BACKGROUND_GREEN = '#4eabba'
export const COLOR_BACKGROUND_LIGHTGREEN = '#9fd861'

export const COLOR_SURVEY_BLUE = '#007fff'

export const CHART_COLORS = [
	COLOR_PRIMARY,
	COLOR_PINK,
	COLOR_PURPLE,
	COLOR_RED,
	COLOR_YELLOW,
	COLOR_BLUE,
	COLOR_BROWN,
	COLOR_GREEN,
	COLOR_DARKBLUE,
	COLOR_ORANGE,
]

export const CHART_LINE_COLORS = [
	COLOR_PRIMARY,
	COLOR_PURPLE,
	COLOR_RED,
	COLOR_YELLOW,
	COLOR_BLUE,
	COLOR_BROWN,
	COLOR_GREEN,
	COLOR_DARKBLUE,
	COLOR_ORANGE,
	COLOR_VIVID_PURPLE,
	COLOR_VIVID_RED,
	COLOR_VIVID_GREEN,
	COLOR_VIVID_BLUE,
	COLOR_VIVID_YELLOW,
	COLOR_VIVID_TEAL,
	COLOR_VIVID_GRAY,
	COLOR_VIVID_BROWN,
]

export const CARD_COLORS = [
	COLOR_BACKGROUND_RED,
	COLOR_BACKGROUND_ORANGE,
	COLOR_BACKGROUND_YELLOW,
	COLOR_BACKGROUND_LIGHTGREEN,
	COLOR_BACKGROUND_GREEN,
	COLOR_BACKGROUND_BLUE,
	COLOR_BACKGROUND_CORAL,
	COLOR_BACKGROUND_PURPLE,
]

export const PROGRESS_GRADIENT = 'linear-gradient( 90deg,#389fa2,#97dcde)'
export const PROGRESS_GRADIENT_SUCCESS = 'linear-gradient( 90deg,#6cf771,#4bce7f)'
export const PROGRESS_GRADIENT_ERROR = 'linear-gradient( 90deg,#f7303e,#ff9494)'
export const PROGRESS_GRADIENT_GRAY = 'linear-gradient(120deg, #f3f3f3, #fafafa)'
export const PROGRESS_GRADIENT_LIGHT_GRAY = 'linear-gradient(135deg, #f4f4f4, #dfdfdf)'

export const BORDER_GRAY = `1px solid ${COLOR_BORDER_GRAY}`
export const BORDER_DARK_GRAY = `1px solid ${COLOR_BORDER_DARK_GRAY}`
export const BORDER_SURVEY_BUTTON = 'solid 1px rgba(0, 0, 0, 0.1)'

export const BOX_SHADOW_NORMAL = '0px 0px 5px 0 rgba(0, 0, 0, 0.1)'
export const BOX_SHADOW_LIGHT = '0px 0px 5px 0 rgba(0, 0, 0, 0.03)'
export const BOX_SHADOW_DEEM = '1px 2px 10px 0 rgba(0, 0, 0, 0.1)'
export const BOX_SHADOW_HOVER = '0 3px 5px 0 rgba(0, 0, 0, 0.1)'
export const BOX_SHADOW_CHART_HOVER = '0 3px 3px 0 rgba(0, 0, 0, 0.07)'
export const BOX_SHADOW_BUTTON = '0 2px 4px 0 rgba(227, 227, 227, 0.5)'
export const BOX_SHADOW_BUTTON_HOVER = '0 2px 4px 0 rgba(0, 0, 0, 0.5)'
export const BOX_SHADOW_FLOAING_BUTTON = '0 3px 6px 0 rgba(0, 0, 0, 0.16)'
export const BOX_SHADOW_SURVEY_BUTTON = '0 1px 2px 0 rgba(0, 0, 0, 0.2)'

export const BASIC_COLORS = {
	red: '#DB2828',
	orange: '#F2711C',
	yellow: '#FBBD08',
	olive: '#B5CC18',
	green: '#55c27e',
	teal: '#389fa2',
	blue: '#48C9E3',
	violet: '#6435C9',
	purple: '#A333C8',
	pink: '#E03997',
	brown: '#A5673F',
	grey: '#ABABAB',
	black: '#1B1C1D',
}
