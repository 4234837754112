import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DateInput from '../../../common/basic/DateInput'
import MyIcon from '../../../common/basic/MyIcon'
import { AlignCenteredFlexDiv } from '../../../common/basic/StyledBox'
import { ReadOnlyValue, ReadOnlyValueNull } from './Styled'

/**
 * 날짜입력 인풋
 */
const DataFieldDate = ({ onUpdate, disabled, unit, value, readOnly }) => {
	const dateOptions = useMemo(() => {
		try {
			if (unit) return JSON.parse(unit)
			else return {}
		} catch (e) {
			return {}
		}
	}, [unit])

	const dateValue = useMemo(() => {
		return value && moment(value)
	}, [value])

	const inputOptions = useMemo(() => {
		const { selectTime, timeOnly, timeIntervals, until, past, format } = dateOptions

		if (timeOnly !== true) {
			return {
				selected: dateValue,
				showMonthDropdown: true,
				showYearDropdown: true,
				scrollableYearDropdown: true,
				dateFormat: format || (selectTime === true ? 'YYYY-MM-DD a hh:mm' : 'YYYY-MM-DD'),
				dropdownMode: 'select',
				dateFormatCalendar: 'YYYY MMMM',
				showTimeSelect: selectTime === true,
				timeIntervals: timeIntervals || 30,
				...(past === true && {
					maxDate: moment(),
				}),
				...(until != null && {
					minDate: moment().subtract(until.value, until.unit),
				}),
				onChange: item => {
					onUpdate('value', item ? item.format(dateOptions.format || 'YYYY-MM-DD') : null)
				},
			}
		} else {
			return {
				selected: dateValue,
				showTimeSelect: true,
				showTimeSelectOnly: true,
				dateFormat: 'a hh:mm',
				onChange: item => {
					onUpdate('value', item)
				},
			}
		}
	}, [dateOptions, dateValue, onUpdate])

	if (readOnly === true) {
		if (disabled) {
			return <span />
		}

		if (value == null) {
			return <ReadOnlyValueNull>비어있음</ReadOnlyValueNull>
		}

		return (
			<ReadOnlyValue>
				<AlignCenteredFlexDiv>
					<MyIcon name="calendar" /> &nbsp;&nbsp;
					<div>{moment(value).format(inputOptions.dateFormat)}</div>
				</AlignCenteredFlexDiv>
			</ReadOnlyValue>
		)
	}

	return (
		<DateInput
			disabled={disabled}
			readOnly={readOnly}
			locale="ko"
			placeholderText="클릭 후 날짜선택"
			{...inputOptions}
		/>
	)
}

DataFieldDate.propTypes = {
	onUpdate: PropTypes.func,
	disabled: PropTypes.bool,
	unit: PropTypes.string,
	value: PropTypes.string,
	readOnly: PropTypes.bool,
}

export default DataFieldDate
