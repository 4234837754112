import { matchPath } from 'react-router'
import qs from 'query-string'

export const getQueryValue = (key, search, type) => {
	const qss = qs.parse(search.slice(1), { ignoreQueryPrefix: true })

	if (qss[key]) {
		if (type === 'number') {
			return parseInt(qss[key], 10)
		}

		return qss[key]
	}

	return null
}

/**
 * Route Path 목록에서 매칭되는 것이 있는지 확인
 */
export const isMatchingExactRoutes = (currentPath, routes) => {
	return (
		routes.find(route => {
			return (
				matchPath(currentPath, {
					path: route,
					exact: true,
				}) != null
			)
		}) != null
	)
}

/**
 * Route Path 목록에서 매칭되는 것이 있는지 확인
 */
export const isMatchingRoutes = (currentPath, routes) => {
	return (
		routes.find(route => {
			return (
				matchPath(currentPath, {
					path: route,
				}) != null
			)
		}) != null
	)
}

/**
 * Route Parameter 변경
 */
export const replaceParams = (path, params) => {
	return Object.keys(params).reduce((result, key) => {
		return result.replace(`:${key}`, params[key]).replace(`:${key}`, `${params[key]}?`)
	}, path)
}
