import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Modal, Icon } from 'semantic-ui-react'
import moment from 'moment'
import 'moment/locale/ko'
import DateInput from '../../common/basic/DateInput'
import DescriptionText from '../../common/basic/DescriptionText'

/**
 * 예약 설정 모달
 */
export default class NextScheduleModal extends PureComponent {
	static propTypes = {
		nextSchedule: PropTypes.shape({
			days: PropTypes.number,
			daysRange: PropTypes.number,
		}).isRequired,
		currentSchedule: PropTypes.shape({
			days: PropTypes.number,
		}).isRequired,
		onSubmit: PropTypes.func.isRequired,
		open: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
		loading: PropTypes.bool,
		initialVisitDate: PropTypes.string.isRequired,
	}

	constructor(props) {
		super(props)

		const { initialVisitDate, nextSchedule } = props
		try {
			this.state = {
				reserveDate: nextSchedule ? moment(initialVisitDate).add(nextSchedule.days, 'days') : null,
			}
		} catch (e) {
			alert('날짜 설정 중 오류가 발생했습니다.')
		}
	}

	handleChangeReserveDate = item => {
		this.setState({
			reserveDate: moment(item),
		})
	}

	handleSubmit = () => {
		const { onSubmit } = this.props
		const { reserveDate } = this.state

		onSubmit(reserveDate)
	}

	render() {
		const { open, onClose, nextSchedule, initialVisitDate, loading } = this.props
		const { reserveDate } = this.state

		return (
			<Modal size="tiny" open={open} closeIcon onClose={onClose} closeOnDimmerClick={false}>
				<Modal.Header>다음 조사일 지정</Modal.Header>
				<Modal.Content>
					<div>
						{nextSchedule && (
							<span style={{ marginLeft: 0 }}>
								<b style={{ paddingLeft: 10, paddingRight: 10 }}>
									{moment(initialVisitDate)
										.add(nextSchedule.days - nextSchedule.daysRange, 'days')
										.format('YYYY. MM. DD.')}
								</b>
								-
								<b style={{ paddingLeft: 10, paddingRight: 10 }}>
									{nextSchedule &&
										moment(initialVisitDate)
											.add(nextSchedule.days + nextSchedule.daysRange, 'days')
											.format('YYYY. MM. DD.')}
								</b>{' '}
								중 하루를 선택해 주세요.
							</span>
						)}
					</div>
					<Form>
						<Form.Field>
							<div className="df-container" style={{ marginTop: 5 }}>
								<DateInput
									locale="ko"
									selected={reserveDate}
									showMonthDropdown
									showYearDropdown
									scrollableYearDropdown
									disable
									placeholderText="클릭해서 날짜선택"
									dateFormat="YYYY-MM-DD"
									dropdownMode="select"
									dateFormatCalendar="YYYY MMMM"
									onChange={this.handleChangeReserveDate}
									onChangeRaw={event => {
										this.handleChangeReserveDate(event.target.value)
									}}
									// minDate={moment(visitedDate).add(afterDays - nextSchedule.daysRange, 'days')}
									// maxDate={moment(visitedDate).add(afterDays + nextSchedule.daysRange, 'days')}
								/>
							</div>
						</Form.Field>
					</Form>
					<DescriptionText style={{ marginTop: 10 }}>
						<Icon name="warning triangle" />
						조사일 지정 후 저장버튼을 누르셔야 내용이 저장됩니다.
					</DescriptionText>
				</Modal.Content>
				<Modal.Actions>
					<Button loading={loading} size="large" primary onClick={this.handleSubmit}>
						저장
					</Button>
				</Modal.Actions>
			</Modal>
		)
	}
}
