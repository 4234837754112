import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Card } from 'semantic-ui-react'
import { COLOR_TEXT_DARKGRAY } from '../../../constants/styles'

const CardWithSmallHeader = props => {
	const { header, content } = props
	return (
		<StyledCard>
			<Card.Content>
				{header != null && <CardHeader>{header}</CardHeader>}
				<div>{content}</div>
			</Card.Content>
		</StyledCard>
	)
}

CardWithSmallHeader.propTypes = {}

export default CardWithSmallHeader

const StyledCard = styled(Card)`
	.content {
		padding: 15px 20px !important;
	}
`

const CardHeader = styled.div`
	color: ${COLOR_TEXT_DARKGRAY};
	font-size: 14px;
	margin: 0px 0px 15px 0px;
`
