import React, { useMemo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import styled from 'styled-components'
import DatePicker from 'react-mobile-datepicker'
import useLocalMedInfo from '../../../hooks/useLocalMedInfo'

/**
 * 날짜 인풋
 */
const PatientSurveyDate = ({ medInfo }) => {
	const { id, medInfoType, value } = medInfo

	const { unit } = medInfoType
	const [, onUpdate] = useLocalMedInfo(id)

	const dateOptions = useMemo(() => {
		try {
			if (unit) {
				return JSON.parse(unit)
			} else {
				return {}
			}
		} catch (e) {
			return {}
		}
	}, [unit])

	const { timeOnly } = dateOptions

	const dateConfig = useMemo(() => {
		if (timeOnly === true) {
			return {
				hour: {
					format: 'hh',
					caption: '시',
					step: 1,
				},
				minute: {
					format: 'mm',
					caption: '분',
					step: 1,
				},
			}
		} else {
			return {
				year: {
					format: 'YYYY',
					caption: '년',
					step: 1,
				},
				month: {
					format: 'MM',
					caption: '월',
					step: 1,
				},
				date: {
					format: 'DD',
					caption: '일',
					step: 1,
				},
			}
		}
	}, [timeOnly])

	const onChange = useCallback(
		to => {
			setStateValue(to)
			onUpdate('value', moment(to))
		},
		[onUpdate]
	)

	const [stateValue, setStateValue] = useState(() => new Date(value), [value])

	return (
		<Container>
			<DatePicker
				value={stateValue}
				theme="ios"
				isPopup={false}
				showFooter={false}
				showHeader={false}
				showCaption
				dateConfig={dateConfig}
				onChange={onChange}
			/>
		</Container>
	)
}

PatientSurveyDate.propTypes = {
	medInfo: PropTypes.object,
}

export default PatientSurveyDate

const Container = styled.div`
	position: relative;
	width: 500px;
	height: 200px;

	.datepicker.ios .datepicker-scroll li {
		color: rgb(68, 149, 237);
	}
`
