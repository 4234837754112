import gql from 'graphql-tag'

export const NOTIFICATION_FRAGMENT = gql`
	fragment NotificationParts on Notification {
		id
		createdAt
		entity {
			... on Record {
				id
				patientId
				creator {
					id
				}
				template {
					id
					title
				}
				summary
			}
			... on Issue {
				id
				title
			}
			... on Patient {
				id
				name
			}
			... on MedInfoQuery {
				id
				message
				status
			}
			... on User {
				id
				name
			}
			... on SimpleCase {
				id
				creator {
					id
					nickname
				}
			}
			... on Comment {
				id
				text
				entity {
					... on Record {
						id
						patientId
						creator {
							id
						}
						researches {
							id
						}
					}
					... on Patient {
						id
						patientResearches {
							research {
								id
							}
						}
					}

					... on MedInfoQuery {
						id
						record {
							id
							patientId
							researches {
								id
							}
						}
					}
					... on Issue {
						id
					}
					... on SimpleCase {
						id
					}
				}
			}
		}
		event {
			actor {
				name
			}
			entity {
				... on Comment {
					id
					text
				}
				... on Issue {
					id
				}
				... on MedInfoQuery {
					id
					message
				}
				... on ResearchInvitation {
					id
					research {
						id
						title
						status
					}
					status
				}
			}
		}
		userReadStatus
	}
`
