import React, { useCallback, useState, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'react-apollo'

import moment from 'moment'
import { Icon, Modal, Button } from 'semantic-ui-react'
import { GET_RESEARCH_RECORD_INFO } from '../../../graphql/queries/record'
import { truncateString } from '../../../libs/valueHelper'
import { MiniBar } from '../../common/basic/MiniBar'
import { toCommonDateString } from '../../../libs/dateHelper'
import DescriptionText from '../../common/basic/DescriptionText'
import {
	ROUTE_PROJECT_DETAIL_DATA_DETAIL_UPDATE,
	ROUTE_PROJECT_DETAIL_PATIENT_DETAIL,
	ROUTE_PATIENT_DETAIL_DEFAULT,
	ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL,
	ROUTE_PATIENT_DETAIL_RECORD,
	ROUTE_PATIENT_DETAIL_RECORD_CHANGES,
	ROUTE_PATIENT_DETAIL_RECORD_CHANGE_DETAIL,
	ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL_CHANGE_DETAIL,
	ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL_CHANGES,
} from '../../../constants/routes'
import useToastMessage from '../../../hooks/useToastMessage'
import { UPDATE_RECORD_INFO } from '../../../graphql/mutations/record'
import {
	ALERT_SUCESS_EDIT_RECORD_DATE,
	ALERT_SEND_MMS,
	FAIL_SEND_MMS,
} from '../../../constants/alerts'
import { HeaderPlaceholder } from './HeaderPlaceholder'
import { VerticalFlex, FlexFill, AlignCenteredFlexDiv } from '../../common/basic/StyledBox'
import DateInput from '../../common/basic/DateInput'
import HeaderPageTitle from '../../common/basic/HeaderPageTitle'
import RecordVersionChecker from '../../record/RecordVersionChecker'
import { replaceParams, isMatchingRoutes } from '../../../libs/routeHelper'
import { COLOR_TEXT_DARKGRAY, BASIC_COLORS } from '../../../constants/styles'
import useUserInfo from '../../../hooks/useUserInfo'
import EllipsisDropdown from '../../common/EllipsisDropdown'
import useDeleteObject from '../../../hooks/useDeleteObject'
import DeleteObjectModal from '../../common/modal/DeleteObjectModal'
import { ObjectType, RecordType, TemplateJob } from '../../../enum'
import { GET_PATIENT_DETAIL_BY_ID } from '../../../graphql/queries/patient'
import ChangeModal from '../../common/modal/ChangeModal'
import { SEND_PATIENT_SURVEY } from '../../../graphql/mutations/survey'

/**
 * 기록 정보 헤더 내용
 */
const RecordHeader = ({ patientId, recordId, researchId, isOnResearchPage }) => {
	const history = useHistory()
	const { pathname } = useLocation()
	const userInfo = useUserInfo()
	const showToastMessage = useToastMessage()
	const { user: currentUser } = userInfo || {}
	const { loading, data, error } = useQuery(GET_RESEARCH_RECORD_INFO, {
		variables: {
			recordId,
			researchId,
		},
		fetchPolicy: 'cache-first',
	})

	const {
		recordDetail: {
			record: {
				visitedDate,
				createdAt,
				template,
				recordTemplateChangeId,
				patient: { registerer: patientRegisterer } = {},
				creator: {
					id: creatorId,
					institution: { name: institutionName } = {},
					name: creatorName,
				} = {},
				researches = [],
				type,
			} = {},
		} = {},
	} = data || {}
	const { data: { patientRecords: { items: recordList = [] } = {} } = {} } =
		useQuery(GET_PATIENT_DETAIL_BY_ID, {
			variables: {
				id: patientId,
				researchId,
			},
		}) || {}

	const canSendLink = useMemo(() => {
		if (
			researches &&
			researches.length > 0 &&
			researches[0].patientBasicInfo &&
			template &&
			template.job
		) {
			return (
				researches[0].patientBasicInfo.includes('phone') &&
				template.job === TemplateJob.SURVEY.value
			)
		}
		return false
	}, [researches, template])

	const { title } = template || {}

	const [updateRecordInfo] = useMutation(UPDATE_RECORD_INFO)
	const [sendPatientSurvey] = useMutation(SEND_PATIENT_SURVEY, {
		variables: {
			patientId,
			researchId,
			recordId,
		},
		onCompleted: ({ sendPatientSurvey: successSend }) => {
			if (successSend === true) {
				showToastMessage(ALERT_SEND_MMS)
			} else if (successSend === false) {
				showToastMessage(FAIL_SEND_MMS)
			}
		},
	})
	const { handleOpen, ...deleteModalProps } = useDeleteObject()

	const onOpenDeleteModal = useCallback(() => {
		handleOpen(ObjectType.RECORD.value, recordId, {
			onComplete: () => {
				const findedRecord = recordList.find(record => record.id !== recordId)
				if (findedRecord == null) {
					if (isOnResearchPage === true) {
						history.push(
							replaceParams(ROUTE_PROJECT_DETAIL_PATIENT_DETAIL, {
								id: researchId,
								subId: patientId,
							})
						)
					} else {
						history.replace(
							replaceParams(ROUTE_PATIENT_DETAIL_DEFAULT, {
								id: patientId,
							})
						)
					}
				} else {
					if (isOnResearchPage === true) {
						history.replace(
							replaceParams(ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL, {
								pid: researchId,
								id: patientId,
								subId: findedRecord.id,
							})
						)
					} else {
						history.replace(
							replaceParams(ROUTE_PATIENT_DETAIL_RECORD, {
								id: patientId,
								subId: findedRecord.id,
							})
						)
					}
				}
			},
			refetchQueries: [
				{
					query: GET_PATIENT_DETAIL_BY_ID,
					variables: {
						id: patientId,
						researchId: researchId,
					},
				},
			],
		})
	}, [handleOpen, history, isOnResearchPage, patientId, recordId, recordList, researchId])

	const onChangeDate = useCallback(
		date => {
			updateRecordInfo({
				variables: {
					recordId,
					recordInfo: {
						visitedDate: moment(date).format('YYYY-MM-DD HH:mm'),
					},
				},
				update: () => {
					showToastMessage(ALERT_SUCESS_EDIT_RECORD_DATE)
				},
			})
		},
		[recordId, showToastMessage, updateRecordInfo]
	)

	const [openSendLinkModal, setSendLinkModal] = useState(false)

	const isPatientCreator = patientRegisterer != null && patientRegisterer.id === creatorId
	const isCreator = currentUser.id === creatorId

	const changeModalOpen = isMatchingRoutes(pathname, [
		ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL_CHANGE_DETAIL,
		ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL_CHANGES,
		ROUTE_PATIENT_DETAIL_RECORD_CHANGES,
		ROUTE_PATIENT_DETAIL_RECORD_CHANGE_DETAIL,
	])

	let shouldShowCreator
	if (isOnResearchPage === true) {
		shouldShowCreator = isPatientCreator !== true
	} else {
		shouldShowCreator = isCreator !== true
	}

	if (loading === true || error != null) return <HeaderPlaceholder />

	return (
		<>
			<VerticalFlex>
				<FlexFill>
					<AlignCenteredFlexDiv>
						{type !== RecordType.DROP.value && (
							<>
								<HeaderPageTitle>{title}</HeaderPageTitle>
								<EllipsisDropdown
									direction="right"
									options={[
										{
											key: 'change',
											content: '수정내역',
											onClick: () => {
												history.replace(
													isOnResearchPage === true
														? replaceParams(
																ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL_CHANGES,
																{
																	pid: researchId,
																	id: patientId,
																	subId: recordId,
																}
														  )
														: replaceParams(ROUTE_PATIENT_DETAIL_RECORD_CHANGES, {
																id: patientId,
																subId: recordId,
														  })
												)
											},
										},
										{
											key: `psm-r-${recordId}-del`,
											content: '삭제',
											style: {
												color: BASIC_COLORS.red,
											},
											onClick: onOpenDeleteModal,
										},
									]}
								/>
								{canSendLink === true && (
									<SendLinkButton
										onClick={() => {
											setSendLinkModal(true)
										}}
									>
										<Icon name="send" />
									</SendLinkButton>
								)}
								<DateLabel>조사일</DateLabel>
								<DateInput
									inline
									locale="ko"
									selected={visitedDate && moment(visitedDate)}
									showMonthDropdown
									showYearDropdown
									onChangeRaw={event => {
										onChangeDate(event.target.value)
									}}
									isCompact
									showTimeSelect={false}
									scrollableYearDropdown
									disable
									placeholderText="클릭해서 날짜선택"
									dateFormat="YYYY-MM-DD"
									dropdownMode="select"
									dateFormatCalendar="YYYY MMMM"
									onChange={onChangeDate}
									maxDate={moment()}
								/>
							</>
						)}
						<StatusInfo>
							{template != null && (researchId != null || researches.length !== 0) && (
								<RecordVersionChecker
									templateUpdateURI={replaceParams(ROUTE_PROJECT_DETAIL_DATA_DETAIL_UPDATE, {
										id: researchId != null ? researchId : researches[0].id,
										subId: patientId,
										recordId,
									})}
									currentTemplateChangeId={recordTemplateChangeId}
									recentTemplateChangeId={
										template.lastChange != null ? template.lastChange.id : null
									}
								/>
							)}
						</StatusInfo>
					</AlignCenteredFlexDiv>
				</FlexFill>
				<SubInfo>
					{shouldShowCreator === true && (
						<UserInfo>
							<SubInfoIcon name="doctor" />
							<b>
								{creatorName}
								<MiniBar />
								{truncateString(institutionName, 15)}
							</b>
						</UserInfo>
					)}
					{createdAt && <DescriptionText>{toCommonDateString(createdAt)} 등록</DescriptionText>}
				</SubInfo>
				<DeleteObjectModal {...deleteModalProps} />
			</VerticalFlex>
			{changeModalOpen && (
				<ChangeModal
					open={changeModalOpen}
					objectType={ObjectType.RECORD.value}
					id={recordId}
					researchId={researchId}
					isOnResearchPage={isOnResearchPage}
					onClose={() => {
						history.replace(
							isOnResearchPage === true
								? replaceParams(ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL, {
										pid: researchId,
										id: patientId,
										subId: recordId,
								  })
								: replaceParams(ROUTE_PATIENT_DETAIL_RECORD, {
										id: patientId,
										subId: recordId,
								  })
						)
					}}
				/>
			)}
			{openSendLinkModal && (
				<Modal open={openSendLinkModal} size="mini" onClose={() => setSendLinkModal(false)}>
					<Modal.Content>환자에게 설문 링크를 보내시겠습니까?</Modal.Content>
					<Modal.Actions>
						<Button onClick={() => setSendLinkModal(false)}>취소</Button>
						<Button
							primary
							onClick={() => {
								setSendLinkModal(false)
								sendPatientSurvey()
							}}
						>
							보내기
						</Button>
					</Modal.Actions>
				</Modal>
			)}
		</>
	)
}

RecordHeader.propTypes = {
	patientId: PropTypes.number,
	recordId: PropTypes.number,
	researchId: PropTypes.number,
	isOnResearchPage: PropTypes.bool,
}

export default RecordHeader

/**
 * Styles
 */

const DateLabel = styled(DescriptionText).attrs({ inline: true })`
	margin-left: 30px;
	margin-right: 8px;
`

const SubInfo = styled.div`
	height: 20px;
	display: flex;
	align-items: center;
	margin-top: 5px;
`

const UserInfo = styled.div`
	color: ${COLOR_TEXT_DARKGRAY};
	font-size: 13px;
	margin-right: 15px;
	display: flex;
	align-items: center;
`

const SubInfoIcon = styled(Icon)`
	font-size: 15px !important;
`
const StatusInfo = styled.div`
	margin-left: 10px;
`

const SendLinkButton = styled.div`
	cursor: pointer;
	:hover {
		opacity: 0.5;
	}
`
