import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ROUTE_LOGIN } from '../../../constants/routes'
import { UISize } from '../../../enum'

/**
 * 로그인 버튼
 */
const HeaderLoginButton = ({ size }) => {
	return (
		<Button size={size} primary as={Link} to={ROUTE_LOGIN}>
			시작하기
		</Button>
	)
}

HeaderLoginButton.propTypes = {
	size: PropTypes.string,
}

HeaderLoginButton.defaultProps = {
	size: UISize.LARGE,
}

export default HeaderLoginButton
