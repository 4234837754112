/**
 * [Component] 프로젝트 목록
 *
 * 환자/진료기록 등록시 사용
 */

import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { SegmentGroup, Segment } from 'semantic-ui-react'
import { TemplateType, ResearchTemplateType } from '../../enum'
import { COLOR_BACKGROUND_GRAY, COLOR_TEXT_ORANGE } from '../../constants/styles'

/**
 * 프로젝트 선택 뷰 (Presentation)
 *
 * 선택하면 목록 중 선택된 항목만 남고,
 * 남은 항목을 다시 클릭하면 토글되어 목록이 나타남
 */
const ResearchSelectView = props => {
	const {
		disabled,
		shouldHideInst,
		items,
		record,
		selectedResearch,
		toggleSelectResearch,
		templateInfo,
	} = props

	const itemLength = items.length

	return (
		<SegmentGroup
			style={{
				boxShadow: !selectedResearch ? 'rgba(0, 0, 0, 0.03) 1px 1px 3px 0px' : 'none',
				marginTop: 0,
				maxHeight: '50vh',
				overflowY: !selectedResearch ? 'auto' : 'unset',
			}}
		>
			{items.map((item, index) => {
				let selected
				if (selectedResearch != null) {
					selected =
						record != null
							? item.research.id === selectedResearch.research.id
							: item.id === selectedResearch.id
				}

				return (
					<ResearchItem
						index={index}
						reverseIndex={itemLength - 1 - index}
						isVisible={selectedResearch == null || selected === true}
						key={item.id}
						item={item}
						shouldHideInst={shouldHideInst}
						record={record}
						toggleSelectResearch={disabled !== true ? toggleSelectResearch : () => {}}
						templateInfo={templateInfo}
						selected={selected === true}
					/>
				)
			})}
		</SegmentGroup>
	)
}

ResearchSelectView.propTypes = {
	items: PropTypes.array,
	record: PropTypes.bool,
	selectedResearch: PropTypes.object,
	toggleSelectResearch: PropTypes.func,
	templateInfo: PropTypes.object,
	disabled: PropTypes.bool,
	shouldHideInst: PropTypes.bool,
}

ResearchSelectView.defaultProps = {
	shouldHideInst: false,
}

export default ResearchSelectView

/**
 * 프로젝트 개별 항목 Presentation
 */
const ResearchItem = ({
	index,
	reverseIndex,
	isVisible,
	item,
	record,
	toggleSelectResearch,
	templateInfo,
	selected,
	shouldHideInst,
}) => {
	let research
	if (record) ({ research } = item)
	else research = item

	const { title, organizedBy, id, templateType } = research
	const shouldShowTemplateInfo =
		selected === true && templateInfo != null && templateType !== ResearchTemplateType.SELECT.value
	return (
		<ResearchItemContainer
			index={index}
			reverseIndex={reverseIndex}
			isVisible={isVisible}
			key={id}
			onClick={() => toggleSelectResearch(item)}
		>
			<Title>{title}</Title>
			{shouldShowTemplateInfo === true && (
				<span>
					: <TemplateInfo>{templateInfo.title}</TemplateInfo>
				</span>
			)}
			{shouldHideInst !== true && (
				<ResearchOrganizer>{organizedBy && `${organizedBy.name}`}</ResearchOrganizer>
			)}
		</ResearchItemContainer>
	)
}

ResearchItem.propTypes = {
	index: PropTypes.number,
	reverseIndex: PropTypes.number,
	isVisible: PropTypes.bool,
	item: PropTypes.shape({
		research: PropTypes.shape({
			title: PropTypes.string,
			organizedBy: PropTypes.shape({
				name: PropTypes.string,
			}),
		}),
	}),
	record: PropTypes.bool,
	toggleSelectResearch: PropTypes.func,
	templateInfo: PropTypes.shape({
		title: PropTypes.string,
	}),
	selected: PropTypes.bool,
	shouldHideInst: PropTypes.bool,
}

ResearchItem.defaultProps = {
	shouldHideInst: false,
}

const Title = styled.span`
	font-weight: 300;
	font-size: 16px;
`

const ResearchItemContainer = styled(Segment)`
	cursor: pointer;
	/* display: ${props => (props.isVisible === true ? 'block' : 'none')}; */
  opacity: ${props => (props.isVisible === true ? '1' : '0')};
  height: ${props => (props.isVisible === true ? 'auto' : '0px')};
  border-bottom: ${props => (props.isVisible === true ? '1px solid #e4e4e4' : 'none !important')};
  border-top: ${props => (props.isVisible === true ? '1px solid #e4e4e4' : 'none !important')};
  padding-top: ${props => (props.isVisible === true ? '1em' : '0px !important')};
  padding-bottom: ${props => (props.isVisible === true ? '1em' : '0px !important')};
  transition: opacity ${props =>
		props.isVisible === true ? props.reverseIndex * 0.02 : props.index * 0.02}s,
    height ${props => (props.isVisible === true ? props.reverseIndex * 0.02 : props.index * 0.02)}s,
    padding-top ${props =>
			props.isVisible === true ? props.reverseIndex * 0.02 : props.index * 0.02}s,
    padding-bottom ${props =>
			props.isVisible === true ? props.reverseIndex * 0.02 : props.index * 0.02}s;
	overflow: hidden;
	
	:hover {
		background: ${COLOR_BACKGROUND_GRAY};
	}


`

const ResearchOrganizer = styled.div`
	margin-top: 2px;
	color: ${COLOR_TEXT_ORANGE};
`

const TemplateInfo = styled.span`
	font-weight: 600;
	font-size: 16px;
`

const styles = {
	segSelected: {
		background: '#0D8EFF',
		color: 'white',
	},
	segUnselected: {
		color: '#555',
	},
	organizedBy: { float: 'right', marginTop: 2 },
}
