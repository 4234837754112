import React from 'react'

const PrepareView = ({ date, message }) => {
	return (
		<div style={{ marginTop: 100, textAlign: 'center' }}>
			<h4 style={{ fontWeight: 300 }}>현재 준비중 입니다.</h4>
			{date != null && <h6>{date} 오픈될 예정입니다.</h6>}
			{message && <div style={{ marginTop: 30, fontSize: 20 }}>{message}</div>}
		</div>
	)
}

export default PrepareView
