import { ENTITY_TYPE_HERB, ENTITY_TYPE_ACUPOINT } from './termLibrary'
import {
	GET_USER_PRESCRIPTIONS,
	GET_USER_ACUPUNCTURE_TREATMENTS,
} from '../graphql/queries/personalLib'
import {
	ADD_USER_PRESCRIPTION_TREATMENT,
	ADD_USER_ACUPUNCTURE_TREATMENT,
	UPDATE_USER_PRESCRIPTION_TREATMENT,
} from '../graphql/mutations/personalLib'

/**
 * 처방/침구 관리시 타입 정보
 */
export const PrescriptionTypes = {
	herbal: {
		key: 'herbal',
		itemTitle: '처방명',
		compositionTitle: '방제 구성',
		compositionName: '본초',
		compositionAddPlaceholder: '추가할 본초명을 입력해 주세요.',
		entityType: ENTITY_TYPE_HERB,
		query: GET_USER_PRESCRIPTIONS,
		queryName: 'userPrescriptionTreatments',
		addMutation: ADD_USER_PRESCRIPTION_TREATMENT,
		addMutationName: 'addPrescriptionTreatment',
		updateMutation: UPDATE_USER_PRESCRIPTION_TREATMENT,
		addButtonTitle: '새로운 처방 추가하기',
		compositionsKey: 'herbs',
		compositionIdKey: 'herbId',
		compositionNameKey: 'herbName',
		beforeSubmit: ({ __typename, ...item }) => {
			return {
				...item,
				herbs:
					item.herbs != null
						? item.herbs.map(herb => {
								const { __typename_, herbId, herbName, ...herbInfo } = herb
								return {
									...herbInfo,
									id: herbId,
									name: herbName,
								}
						  })
						: [],
			}
		},
	},
	acupuncture: {
		key: 'acupuncture',
		itemTitle: '침구 처방명',
		compositionTitle: '경혈 구성',
		compositionAddPlaceholder: '추가할 경혈명을 입력해 주세요.',
		compositionName: '경혈',
		entityType: ENTITY_TYPE_ACUPOINT,
		query: GET_USER_ACUPUNCTURE_TREATMENTS,
		queryName: 'userAcupunctureTreatments',
		addMutation: ADD_USER_ACUPUNCTURE_TREATMENT,
		addMutationName: 'addAcupunctureTreatment',
		addButtonTitle: '새로운 침구 처방 추가하기',
		compositionsKey: 'acupoints',
		compositionIdKey: 'acupointId',
		compositionNameKey: 'acupointName',
	},
}
