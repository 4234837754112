import { useApolloClient } from 'react-apollo'
import { TOGGLE_SUB_SIDE_MENU } from '../graphql/mutations/app'

/**
 * local state를 사용해 서브 사이드 메뉴 열고 닫는 것을 조종하는 hooks
 */
const useSubSideMenuControl = () => {
	const client = useApolloClient()

	const setSubSideMenu = toState => {
		client.mutate({
			mutation: TOGGLE_SUB_SIDE_MENU,
			variables: {
				shouldOpen: toState,
			},
		})
	}

	return setSubSideMenu
}

export default useSubSideMenuControl
