import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import compose from 'lodash.flowright'
import { graphql } from 'react-apollo'
import { Pagination } from 'semantic-ui-react'

import { GET_MED_INFO_QUERY } from '../../../../graphql/queries/medInfo'

import { DEFAULT_MED_INFO_QUERIES_PER_PAGE } from '../../../../constants'
import MedInfoQueryListView from './MedInfoQueryListView'
import { GET_USER_INFO } from '../../../../graphql/queries/user'

class MedInfoQueryList extends PureComponent {
	static propTypes = {
		medInfoId: PropTypes.number,
	}

	handlePageChange = (e, { activePage }) => {
		const { getMedInfoQuery, medInfoId } = this.props

		getMedInfoQuery.fetchMore({
			variables: {
				medInfoId,
				pagination: {
					page: activePage,
					perPage: DEFAULT_MED_INFO_QUERIES_PER_PAGE,
				},
			},
			updateQuery: (prev, { fetchMoreResult }) => {
				if (!fetchMoreResult) return prev
				return fetchMoreResult
			},
		})
	}

	render() {
		const { user, getMedInfoQuery } = this.props
		const { loading, error, refetch, medInfoQueries } = getMedInfoQuery

		let totalCount = 0,
			perPage = DEFAULT_MED_INFO_QUERIES_PER_PAGE,
			page = 1

		if (medInfoQueries) {
			;({ totalCount, perPage, page } = medInfoQueries)
		}

		return (
			<div>
				<MedInfoQueryListView
					{...this.props}
					{...getMedInfoQuery.medInfoQueries}
					loading={loading}
					error={error}
					onUpdate={refetch}
					onPageChange={this.hanldePageChange}
					user={user}
				/>
				{totalCount > perPage && (
					<div style={{ textAlign: 'center', marginTop: 15 }}>
						<Pagination
							style={{ margin: 'auto' }}
							activePage={page}
							totalPages={Math.ceil(totalCount / perPage)}
							onPageChange={this.handlePageChange}
						/>
					</div>
				)}
			</div>
		)
	}
}

export default compose(
	graphql(GET_USER_INFO, {
		props: ({
			data: {
				userInfo: { user },
			},
		}) => ({
			user,
		}),
	}),
	graphql(GET_MED_INFO_QUERY, {
		props: ({ data }) => {
			return {
				getMedInfoQuery: data,
			}
		},
		options: props => ({
			variables: {
				medInfoId: props.medInfoId,
				pagination: {
					page: 1,
					perPage: DEFAULT_MED_INFO_QUERIES_PER_PAGE,
				},
			},
		}),
	})
)(MedInfoQueryList)
