import styled from 'styled-components'

const ModalSubHeader = styled.div`
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 20px;
	margin-top: 10px;
`

export default ModalSubHeader
