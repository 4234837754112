import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import compose from 'lodash.flowright'
import { graphql } from 'react-apollo'
import { Card, Container } from 'semantic-ui-react'
import UserForgotAccountView from '../components/user/login/UserForgotAccountView'
import {
	FIND_USERNAME,
	FIND_PASSWORD,
	VERIFY_USERNAME_CODE,
	VERIFY_PASSWORD_CODE,
	RESET_FORGOTTEN_PASSWORD,
} from '../graphql/mutations/user'
import { matchErrorCode } from '../libs/errorHelper'
import errorTypes from '../errorTypes'
import PageContainer from '../components/common/container/PageContainer'
import SegmentToggle from '../components/common/basic/SegmentToggle'
import { ROUTE_LOGIN_FORGOT } from '../constants/routes'
import { replaceParams } from '../libs/routeHelper'

const defaultState = {
	isCodeVerified: false,
	isCodeSent: false,
	isPasswordChanged: false,
	usernameFound: '',
	email: '',
	username: '',
	authCode: '',
	name: '',
	password: '',
	passwordRepeat: '',
	phone: '',
}

class ForgotAccount extends PureComponent {
	static propTypes = {
		match: PropTypes.object,
		history: PropTypes.object,
		findUsername: PropTypes.func,
		findPassword: PropTypes.func,
		verifyUsernameCode: PropTypes.func,
	}

	constructor(props) {
		super(props)

		this.state = defaultState
	}

	handleChange = (e, { name, value }) => {
		this.setState({
			[name]: value,
		})
	}

	handleClickMenu = value => {
		const { history } = this.props
		this.setState(defaultState)
		history.push(
			replaceParams(ROUTE_LOGIN_FORGOT, {
				menu: value,
			})
		)
	}

	//TODO: VAlidation
	// 코드 받기 전이면 코드 전송
	// 코드 확인 후 코드 검사 및 결과
	// 비밀번호 확인일 경우, 코드 검사 이후 비밀번호 리셋까지 처리
	handleSubmit = async () => {
		const {
			match,
			findUsername,
			findPassword,
			verifyUsernameCode,
			verifyPasswordCode,
			resetForgottenPassword,
		} = this.props
		const { menu } = match.params

		const {
			isCodeSent,
			email,
			name,
			username,
			authCode,
			isCodeVerified,
			isPasswordChanged,
			password,
			passwordRepeat,
		} = this.state

		if (!isCodeSent) {
			try {
				if (menu === 'username') {
					await findUsername({ variables: { name, email } })
				} else if (menu === 'password') {
					await findPassword({ variables: { username, email } })
				}

				this.setState({
					isCodeSent: true,
				})
			} catch (e) {
				if (matchErrorCode(e, errorTypes.AUTHENTICATION_FAILED_ERROR)) {
					alert('해당 계정 정보가 존재하지 않습니다.')
				}

				throw e
			}
		} else {
			try {
				if (menu === 'username') {
					const { data } = await verifyUsernameCode({ variables: { name, email, code: authCode } })

					this.setState({
						isCodeVerified: true,
						usernameFound: data.verifyUsernameCode,
					})
				} else if (menu === 'password') {
					if (!isCodeVerified) {
						await verifyPasswordCode({ variables: { username, email, code: authCode } })

						this.setState({
							isCodeVerified: true,
						})
					} else {
						await resetForgottenPassword({
							variables: { username, email, code: authCode, password, passwordRepeat },
						})

						this.setState({
							isPasswordChanged: true,
						})
					}
				}
			} catch (e) {
				if (matchErrorCode(e, errorTypes.AUTHENTICATION_FAILED_ERROR)) {
					alert('잘못된 코드입니다.')
				}

				throw e
			}
		}
	}

	render() {
		const { match } = this.props
		const { menu } = match.params

		return (
			<PageContainer fixed mini hasMobile>
				<SegmentToggle
					style={{ marginTop: 20 }}
					active={menu}
					fluid
					items={[
						{
							text: '아이디 찾기',
							value: 'username',
						},
						{
							text: '비밀번호 찾기',
							value: 'password',
						},
					]}
					onChange={this.handleClickMenu}
				/>
				{/* <Card
						link
						style={{
							display: 'inline-block',
							margin: 20,
							...(menu === 'username' && {
								background: 'rgba(58, 196, 176, 0.06)',
							}),
						}}
						name="username"
						onClick={this.handleClickMenu}
						header="아이디 찾기"
						description="사용자 아이디를 잊어버렸을 경우"
					/>
					<Card
						link
						style={{
							display: 'inline-block',
							...(menu === 'password' && {
								background: 'rgba(58, 196, 176, 0.06)',
							}),
						}}
						name="password"
						onClick={this.handleClickMenu}
						header="비밀번호 찾기"
						description="사용자 아이디를 잊어버렸을 경우"
					/> */}
				{/* </div> */}
				<div style={{ marginTop: 20 }}>
					{menu && (
						<UserForgotAccountView
							menu={menu}
							onSubmit={this.handleSubmit}
							onChange={this.handleChange}
							{...this.state}
						/>
					)
					// menu && <UserFindIdView {...this.state} />
					}
				</div>
			</PageContainer>
		)
	}
}

export default withRouter(
	compose(
		graphql(FIND_USERNAME, {
			name: 'findUsername',
		}),
		graphql(FIND_PASSWORD, {
			name: 'findPassword',
		}),
		graphql(VERIFY_USERNAME_CODE, {
			name: 'verifyUsernameCode',
		}),
		graphql(VERIFY_PASSWORD_CODE, {
			name: 'verifyPasswordCode',
		}),
		graphql(RESET_FORGOTTEN_PASSWORD, {
			name: 'resetForgottenPassword',
		})
	)(ForgotAccount)
)
