import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal, Button } from 'semantic-ui-react'
import moment from 'moment'
import { withApollo } from 'react-apollo'
import DataTable from '../components/common/DataTable'
import LoadingView from '../components/common/loader'
import EllipsisDropdown from '../components/common/EllipsisDropdown'
import ToggledInput from '../components/common/ToggledInput'
import { SimpleInputModal } from '../components/common/modal/SimpleInputModal'
import { DEFAULT_PAGINATION_VARIABLES } from '../constants'

/**
 * 처방/침구 등 용어 라이브러리 관리
 */
const LibraryManager = ({
	query,
	queryName,
	addMutation,
	addMutationName,
	updateMutation,
	columns,
	renderDetail,
	addButtonTitle,
	onDelete,
	beforeSubmit,
	onAdd,
	client,
}) => {
	const [isDetailModalOpen, setDetailModalOpen] = useState(false)
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
	const [isAddModalOpen, setAddModalOpen] = useState(false)
	const [isAdding, setAdding] = useState(false)
	const [selectedRow, setSelectedRow] = useState(null)

	return (
		<div>
			<DataTable
				query={query}
				queryName={queryName}
				hasWidgetAddButton
				columns={columns}
				onClickRow={row => {
					setSelectedRow(row)
					setDetailModalOpen(true)
				}}
				addButtonTitle={addButtonTitle}
				onAddItem={() => setAddModalOpen(true)}
			/>
			{isDetailModalOpen === true && (
				<ItemDetailModal
					row={selectedRow}
					isOpen={isDetailModalOpen}
					renderDetail={renderDetail}
					isAdding={isAdding}
					onUpdate={updatingValues => {
						client.mutate({
							mutation: updateMutation,
							variables: beforeSubmit != null ? beforeSubmit(updatingValues) : updatingValues,
						})
					}}
					onClose={() => {
						setDetailModalOpen(false)
						setAdding(false)
					}}
					onDelete={() => setDeleteModalOpen(true)}
				/>
			)}
			<DeleteModal
				isOpen={isDeleteModalOpen}
				onClose={() => {
					setDeleteModalOpen(false)
				}}
				onSubmit={() => {
					onDelete(selectedRow.id)
					setDeleteModalOpen(false)
					setDetailModalOpen(false)
				}}
			/>
			{isAddModalOpen === true && (
				<AddModal
					isOpen={isAddModalOpen}
					onClose={() => setAddModalOpen(false)}
					onSubmit={async addedItemName => {
						const result = await client.mutate({
							mutation: addMutation,
							variables: {
								name: addedItemName,
							},
							refetchQueries: [
								{
									query,
									variables: {
										pagination: DEFAULT_PAGINATION_VARIABLES,
										filter: {},
									},
								},
							],
						})

						const { data } = result

						const { [addMutationName]: createdItem } = data
						setSelectedRow(createdItem)
						setAdding(true)
						setDetailModalOpen(true)
					}}
					title={addButtonTitle}
				/>
			)}
		</div>
	)
}

LibraryManager.propTypes = {
	query: PropTypes.string.isRequired,
	queryName: PropTypes.string.isRequired,
	addMutation: PropTypes.string,
	addMutationName: PropTypes.string,
	updateMutation: PropTypes.string,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
			uiOptions: PropTypes.shape({
				textAlign: PropTypes.string,
			}),
		})
	).isRequired,
	client: PropTypes.shape({
		mutate: PropTypes.func.isRequired,
	}).isRequired,
	renderDetail: PropTypes.func.isRequired,
	addButtonTitle: PropTypes.string.isRequired,
}

LibraryManager.defaultProps = {
	addMutation: null,
	addMutationName: null,
	updateMutation: null,
}

export default withApollo(LibraryManager)

/**
 * 항목 추가 Modal
 */
const AddModal = props => {
	return <SimpleInputModal {...props} placeholder="제목을 입력해 주세요." />
}

/**
 * 항목 상세보기
 */
const ItemDetailModal = ({
	isAdding = false,
	onDelete,
	onUpdate,
	renderDetail,
	row,
	isOpen,
	onClose,
}) => {
	let name, createdAt

	const [isEditing, setEditing] = useState(isAdding)
	const [itemState, setItemState] = useState(row)

	if (row != null) {
		;({ name, createdAt } = itemState)
	}

	const handleUpdate = (fieldName, value) => {
		setItemState({
			...itemState,
			[fieldName]: value,
		})
	}
	let detailView
	if (row == null) {
		detailView = <LoadingView />
	} else if (renderDetail != null) {
		detailView = renderDetail({
			...itemState,
			isEditing,
			onUpdate: handleUpdate,
		})
	} else detailView = <div />

	const DETAIL_ELLIPSIS_OPTIONS = [
		...(isEditing === false
			? [
					{
						key: 'edit',
						value: 'edit',
						text: '수정',
						onClick: () => setEditing(true),
					},
			  ]
			: []),
		{
			key: 'delete',
			value: 'delete',
			style: {
				color: 'red',
			},
			text: '삭제',
			onClick: onDelete,
		},
	]

	return (
		<Modal closeIcon closeOnDimmerClick={!isEditing} open={isOpen} size="small" onClose={onClose}>
			<Modal.Header>
				<ModalHeader>
					<ModalHeaderTitle>
						<ToggledInput
							isEditing={isEditing}
							placeholder="제목"
							name="name"
							onChange={handleUpdate}
							value={name}
							isHeader
							fluid
						/>
					</ModalHeaderTitle>
					<ModalSideMenu>
						<ModalSideEllipsis options={DETAIL_ELLIPSIS_OPTIONS} />
					</ModalSideMenu>
				</ModalHeader>
			</Modal.Header>
			<Modal.Content>
				{createdAt != null && <DateView>{moment(createdAt).calendar()} 등록</DateView>}
				{detailView}
			</Modal.Content>
			{isEditing && (
				<Modal.Actions>
					<Button
						onClick={() => {
							setEditing(false)
							setItemState(row)
						}}
					>
						취소
					</Button>
					<Button
						onClick={() => {
							onUpdate(itemState)
							setEditing(false)
						}}
						primary
					>
						수정 사항 저장
					</Button>
				</Modal.Actions>
			)}
		</Modal>
	)
}

const DateView = styled.div`
	font-size: 11px;
	color: #888;
	margin-bottom: 20px;
	margin-top: -3px;
`

const ModalSideMenu = styled.div`
	display: table-cell;
	width: 50px;
	vertical-align: middle;
`
const ModalHeader = styled.div`
	display: table;
	width: 100%;
`
const ModalHeaderTitle = styled.div`
	display: table-cell;
	vertical-align: middle;
`

const ModalSideEllipsis = props => {
	return <EllipsisDropdown isHorizontal {...props} style={{ fontSize: 15 }} />
}

const DeleteModal = ({ isOpen, onSubmit, onClose }) => {
	return (
		<Modal size="tiny" open={isOpen} onClose={onClose}>
			<Modal.Content>이 항목을 삭제하시겠습니까?</Modal.Content>
			<Modal.Actions>
				<Button onClick={onClose}>취소</Button>
				<Button
					color="red"
					onClick={() => {
						onSubmit()
						onClose()
					}}
				>
					삭제
				</Button>
			</Modal.Actions>
		</Modal>
	)
}
