import React from 'react'
import { Loader } from 'semantic-ui-react'

function LoadingView() {
	const height = 400
	return (
		<div style={{ height }}>
			<Loader active inline="centered" style={{ top: height ? height / 2 : 0, marginTop: -20 }} />
		</div>
	)
}

export default LoadingView
