/**
 * [Component] 연구자 세부 정보 설정하기
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import compose from 'lodash.flowright'
import { graphql } from 'react-apollo'
import { PlaceholderParagraph } from 'semantic-ui-react'

import ResearchMemberBasicSetting from './ResearchMemberBasicSettings'
import ResearcherInstitutionSettings from './ResearchMemberInstitutionSettings'

import { GET_RESEARCHER_INFO } from '../../../../graphql/queries/record'
import {
	UPDATE_RESEARCHER_INFO,
	UPDATE_RESEARCH_INSTITUTION_PERMISSION,
} from '../../../../graphql/mutations/record'

import {
	ROUTE_PROJECT_DETAIL_SETTINGS_MEMBERS_DETAIL_DEFAULT,
	ROUTE_PROJECT_DETAIL_SETTINGS_MEMBERS_DETAIL_INST,
} from '../../../../constants/routes'

import MenuContainer from '../../../common/container/MenuContainer'
import { COLOR_TEXT_LIGHTGRAY } from '../../../../constants/styles'
import { MiniBar } from '../../../common/basic/MiniBar'
import Placeholder from '../../../common/basic/Placeholder'
import { REMOVE_RESEARCHER } from '../../../../graphql/mutations/research'

/**
 * 연구자 상세 정보 설정
 */
class ResearchMemberSetting extends React.PureComponent {
	static propTypes = {
		match: PropTypes.object.isRequired,
		loadingResearcherInfo: PropTypes.bool,
		researcher: PropTypes.object,
	}

	render() {
		const {
			match: {
				params: { id, subId },
			},
			loadingResearcherInfo,
			researcher,
		} = this.props

		const loading = loadingResearcherInfo || !researcher

		let name
		let position
		let profileImage
		let institution
		let canAccess
		if (researcher != null) {
			;({ name, position, profileImage, institution } = researcher.user)
			;({ canAccess } = researcher.researcherInfo)
		}

		return (
			<React.Fragment>
				<HeaderContainer>
					{loading === true ? (
						<Placeholder>
							<PlaceholderParagraph />
						</Placeholder>
					) : (
						<React.Fragment>
							<ImageContainer>
								<ProfileImage src={profileImage} size="tiny" wrapped circular />
							</ImageContainer>
							<TitleContainer>
								<h6>{name}</h6>
								<TitleDesc>
									{institution.name}
									<MiniBar /> <span>{position}</span>
								</TitleDesc>
							</TitleContainer>
						</React.Fragment>
					)}
				</HeaderContainer>
				{loading !== true && (
					<MenuContainer
						menuItems={[
							{
								key: 'PR-ST-MB-BS',
								title: '기본 권한',
								to: ROUTE_PROJECT_DETAIL_SETTINGS_MEMBERS_DETAIL_DEFAULT,
								exact: true,
								component: ResearchMemberBasicSetting,
							},
							...(canAccess === true
								? [
										{
											key: 'PR-ST-MB-INST',
											title: '기관별 권한',
											to: ROUTE_PROJECT_DETAIL_SETTINGS_MEMBERS_DETAIL_INST,
											component: ResearcherInstitutionSettings,
										},
								  ]
								: []),
						]}
						basicProps={{
							...this.props,
							researcher,
						}}
						replaceParam={{
							id,
							subId,
						}}
					/>
				)}
			</React.Fragment>
		)
	}
}

export default compose(
	withRouter,
	graphql(GET_RESEARCHER_INFO, {
		options: props => {
			return {
				variables: {
					researchId: parseInt(props.match.params.id, 10),
					userId: parseInt(props.match.params.subId, 10),
				},
			}
		},
		props: ({ data: { researcher, refetch }, loading, error }) => {
			return {
				loadingResearcherInfo: loading,
				researcher,
				errorResearcherInfo: error,
				onRefreshResearcherInfo: refetch,
			}
		},
	}),
	graphql(UPDATE_RESEARCHER_INFO, {
		name: 'onUpdateResearcherInfo',
	}),
	graphql(UPDATE_RESEARCH_INSTITUTION_PERMISSION, {
		name: 'onUpdateResearchInstitutionPermission',
	}),
	graphql(REMOVE_RESEARCHER, {
		name: 'onRemoveResearcher',
	})
)(ResearchMemberSetting)

/**
 * Styles
 */

const HeaderContainer = styled.div`
	padding: 20px;
	padding-bottom: 5px;
	margin-bottom: -20px;
	display: flex;
`

const ImageContainer = styled.div`
	width: 100px;
`

const TitleContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
`

const TitleDesc = styled.div`
	color: ${COLOR_TEXT_LIGHTGRAY};
`

const ProfileImage = styled.div`
	border-radius: 80px;
	width: 80px;
	height: 80px;
	background: url(${props => props.src});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
`
