import React from 'react'
import PropTypes from 'prop-types'

import { Table } from 'semantic-ui-react'
import ErrorView from './PrepareView'
import MiniErrorView from './MiniErrorView'

const TableError = ({ isMini, size }) => {
	let height
	if (size === 'tall') height = 500
	else if (size === 'medium') height = 300
	else height = 200

	return (
		<React.Fragment>
			<Table.Row style={{ height, textAlign: 'center' }}>
				<Table.Cell colSpan={99}>
					<div style={{ margin: 'auto', marginTop: -20, top: '50%' }}>
						{isMini ? <MiniErrorView /> : <ErrorView />}
					</div>
				</Table.Cell>
			</Table.Row>
		</React.Fragment>
	)
}

TableError.propTypes = {
	size: PropTypes.oneOf(['tall', 'small', 'medium']),
	isMini: PropTypes.bool,
}

TableError.defaultProps = {
	isMini: true,
	size: 'small',
}

export default TableError
