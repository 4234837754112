export const ICON_IMAGES = {
	info: '/images/icons/info.svg',
	print: '/images/icons/print.png',
	folder: '/images/icons/folder.png',
	plusLarge: '/images/icons/plus-50.svg',
	close: '/images/icons/x.svg',
	settings: '/images/icons/cog.png',
	calendar: '/images/icons/cal.svg',
	excel: '/images/icons/excel.png',
	image: '/images/icons/img.svg',
}

export const ASSET_PATH_ICONS = '/images/icons/'
