import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { HEADER_MENU } from '../../../constants/menus'
import HeaderSideBar from './HeaderSideBar'
import { ExceptMobile, MobileOnly } from '../../common/basic/ResponsiveWrappers'

/**
 * 페이지 상단 헤더 메뉴
 *
 * 화면 크기에 따라 Mobile 용 접이식 사이드메뉴로 보여줌
 */
export const HeaderMenu = ({ isResearcher }) => {
	const menuItems = isResearcher === true ? [HEADER_MENU[2]] : HEADER_MENU
	return (
		<>
			<ExceptMobile flex style={{ flex: 1, justifyContent: 'flex-end' }}>
				<Menu>
					{menuItems.map(item => {
						return <HeaderMenuItem key={item.key} {...item} />
					})}
				</Menu>
			</ExceptMobile>
			<MobileOnly>
				<HeaderSideBar menuItems={menuItems} />
			</MobileOnly>
		</>
	)
}

HeaderMenu.propTypes = {
	isResearcher: PropTypes.bool.isRequired,
}

/**
 * 페이지 상단 헤더 메뉴 아이템
 */
const HeaderMenuItem = ({ to, title }) => {
	return <MenuLink to={to}>{title}</MenuLink>
}

HeaderMenuItem.propTypes = {
	to: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
}

/**
 * Styles
 */

const Menu = styled.div`
	margin-right: 30px;
	display: table-cell;
	text-align: right;
	vertical-align: middle;
`

const MenuLink = styled(Link)`
	font-weight: 600;
	margin-right: 40px;
	font-size: 16px;
`
