import React from 'react'
import PropTypes from 'prop-types'

import { Label } from 'semantic-ui-react'

const getItem = (status, set) => {
	for (let key in set) {
		if (key === status) {
			return set[key]
		}
	}
}

function StatusLabel({ status, set, item }) {
	let data = item || getItem(status, set)

	if (data) {
		return (
			<Label style={{ padding: '0.5em 6px' }} color={data.color}>
				{data.text}
			</Label>
		)
	} else {
		return <span />
	}
}

StatusLabel.propTypes = {
	status: PropTypes.string,
	set: PropTypes.object,
	item: PropTypes.shape({
		text: PropTypes.string,
		color: PropTypes.string,
	}),
}

StatusLabel.defaultProps = {
	set: {},
	status: null,
	item: null,
}
export default StatusLabel
