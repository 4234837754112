import gql from 'graphql-tag'
import { SYMPTOM_TYPE_FRAGMENT, PATIENT_FRAGMENT } from '../fragments/patient'

// 기록 추가하기
export const ADD_PATIENT = gql`
	mutation addPatient(
		$name: String!
		$birthDate: String!
		$sexMale: Boolean!
		$patientCode: String!
		$researchId: Int
		$phone: String
		$nameInitial: String
		$address: String
		$paymentSerialNumber: String
		$birthDateReal: String
		$inOut: Int
		$isTest: Boolean
		$agreementSignature: String
		$agreementType: AgreementType
		$hasAgreed: Boolean
		$agreedTermId: Int
	) {
		addPatient(
			name: $name
			birthDate: $birthDate
			sexMale: $sexMale
			patientCode: $patientCode
			researchId: $researchId
			phone: $phone
			nameInitial: $nameInitial
			address: $address
			paymentSerialNumber: $paymentSerialNumber
			birthDateReal: $birthDateReal
			inOut: $inOut
			isTest: $isTest
			agreementSignature: $agreementSignature
			agreementType: $agreementType
			agreementChecked: $hasAgreed
			agreedTermId: $agreedTermId
		) {
			...PatientParts
		}
	}
	${PATIENT_FRAGMENT}
`

// 기록 추가하기
export const UPDATE_PATIENT = gql`
	mutation updatePatient(
		$patientId: Int!
		$name: String
		$birthDate: String
		$sexMale: Boolean
		$patientCode: String
		$researchId: Int
		$phone: String
		$nameInitial: String
		$address: String
		$paymentSerialNumber: String
		$birthDateReal: String
		$symptomTypeId: Int
		$inOut: Int
	) {
		updatePatient(
			patientId: $patientId
			name: $name
			birthDate: $birthDate
			sexMale: $sexMale
			patientCode: $patientCode
			researchId: $researchId
			phone: $phone
			nameInitial: $nameInitial
			address: $address
			paymentSerialNumber: $paymentSerialNumber
			birthDateReal: $birthDateReal
			symptomTypeId: $symptomTypeId
			inOut: $inOut
		) {
			...PatientParts
			symptomType {
				...SymptomTypePart
			}
		}
	}
	${PATIENT_FRAGMENT}
	${SYMPTOM_TYPE_FRAGMENT}
`

export const UPDATE_USER_PATIENTS = gql`
	mutation updateUserPatients($patients: [Patient]) {
		updateUserPatients(patients: $patients) {
			...PatientParts
		}
	}

	${PATIENT_FRAGMENT}
`

export const DROP_RESEARCH_PATIENT = gql`
	mutation dropResearchPatient($patientId: Int!, $researchId: Int!, $reason: String) {
		dropResearchPatient(patientId: $patientId, researchId: $researchId, reason: $reason) {
			...PatientParts
			patientResearches {
				code
				status
			}
		}
	}

	${PATIENT_FRAGMENT}
`

export const ADD_PATIENT_RESEARCH = gql`
	mutation addPatientResearch($patientId: Int!, $researchId: Int!) {
		addPatientResearch(patientId: $patientId, researchId: $researchId) {
			...PatientParts
		}
	}

	${PATIENT_FRAGMENT}
`

export const DELETE_PATIENT = gql`
	mutation deletePatient($patientId: Int!, $password: String!) {
		deletePatient(patientId: $patientId, password: $password)
	}
`
