import gql from 'graphql-tag'

export const SUGGEST_INSTITUTIONS = gql`
	query suggestInstitutions($query: String) {
		suggestInstitutions(query: $query) {
			id
			name
			address
		}
	}
`
export const GET_INSTITUTION_DEPARTMENTS = gql`
	query insitutioninstitutionDepartments($institutionId: Int) {
		institutionDepartments(institutionId: $institutionId) {
			id
			name
		}
	}
`
