import gql from 'graphql-tag'

export const SEND_PATIENT_SURVEY = gql`
	mutation sendPatientSurvey($researchId: Int, $patientId: Int, $recordId: Int) {
		sendPatientSurvey(researchId: $researchId, patientId: $patientId, recordId: $recordId)
	}
`

export const ADD_PATIENT_SURVEY_AGREEMENT = gql`
	mutation addPatientSurveyAgreement($data: PatientSurveyAgreement) {
		addPatientSurveyAgreement(data: $data)
	}
`
