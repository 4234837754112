import Cookies from 'universal-cookie'
import {
	COOKIE_KEY_USER_INFO,
	COOKIE_KEY_PATIENT_SURVEY,
	COOKIE_KEY_READ_NOTICES,
	BASE_DOMAIN,
} from '../config'

const cookie = new Cookies()

export const getUserInfoFromCookie = () => {
	let loginData = cookie.get(COOKIE_KEY_USER_INFO, { domain: BASE_DOMAIN, path: '/' })
	if (typeof loginData === 'undefined') return
	// decode base64 & parse json
	try {
		return JSON.parse(decodeURIComponent(escape(atob(loginData))))
	} catch (e) {
		throw new Error('cookie error occured')
	}
}

export const setUserInfoCookie = userInfo => {
	if (userInfo && userInfo.__typename) {
		delete userInfo.__typename
		if (userInfo.user && userInfo.user.__typename) {
			delete userInfo.user.__typename
		}
		userInfo.user.id = parseInt(userInfo.user.id, 10)
	}

	cookie.set(COOKIE_KEY_USER_INFO, btoa(unescape(encodeURIComponent(JSON.stringify(userInfo)))), {
		domain: BASE_DOMAIN,
		path: '/',
	})
}

export const deleteUserInfoCookie = () => {
	cookie.remove(COOKIE_KEY_USER_INFO, { domain: BASE_DOMAIN, path: '/' })
}

/*
 * 읽은 공지 관련 함수
 */

export const getReadNoticesFromCookie = () => {
	const readNoticesId = cookie.get(COOKIE_KEY_READ_NOTICES)
	if (typeof readNoticesId === 'undefined') return
	try {
		return readNoticesId
	} catch (e) {
		throw new Error(e)
	}
}

export const setReadNoticesCookie = readNoticesId => {
	cookie.set(COOKIE_KEY_READ_NOTICES, readNoticesId, {
		domain: BASE_DOMAIN,
		path: '/',
		maxAge: 60 * 60 * 24 * 365,
	})
}
