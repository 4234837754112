import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-apollo'
import { Table } from 'semantic-ui-react'

import { GET_RESEARCH_RECORD_TEMPLATES } from '../../../graphql/queries/record'
import { TemplateJob } from '../../../enum'

import AddButton from '../../common/basic/AddButton'
import PaddedTable from '../../common/basic/PaddedTable'
import TemplateAddModal from '../../template/modals/TemplateAddModal'
import ResearchRecordTemplateItem from './ResearchRecordTemplateItem'
import TableLoader from '../../common/loader/TableLoader'
import TableError from '../../common/error/TableError'
import { replaceParams } from '../../../libs/routeHelper'
import { ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_DEFAULT } from '../../../constants/routes'

/**
 * 연구 목록 데이터 불러오기
 */
const ResearchTemplateList = ({ isScheduled, isPartial, researchId, history, hasScreening }) => {
	const [isAddModalOpen, setAddModalOpen] = useState(false)

	const title = useMemo(() => {
		if (isPartial) {
			if (isPartial === true) {
				return '부분양식'
			} else {
				return '양식'
			}
		} else {
			return '양식'
		}
	}, [isPartial])

	const onClickRow = id => () => {
		history.push(
			replaceParams(ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_DEFAULT, {
				id: researchId,
				subId: id,
				menu: isPartial === true ? 'forms' : 'templates',
			})
		)
	}

	const { data: { researchRecordTemplates: templates } = {}, data, loading, error } = useQuery(
		GET_RESEARCH_RECORD_TEMPLATES,
		{
			variables: {
				researchId,
				...(isPartial === true && {
					job: TemplateJob.ECRF.key,
				}),
			},
		}
	)

	return (
		<div>
			<div style={{ textAlign: 'right', marginTop: 20 }}>
				<AddButton onClick={() => setAddModalOpen(true)} title={`새로운 ${title}`} />
			</div>
			<PaddedTable selectable>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell textAlign="center">제목</Table.HeaderCell>
						{isScheduled && <Table.HeaderCell textAlign="center">조사시기</Table.HeaderCell>}
						<Table.HeaderCell textAlign="center">작성자</Table.HeaderCell>
						<Table.HeaderCell textAlign="center">등록일</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{loading === true && <TableLoader />}
					{error != null && <TableError error={error} />}
					{data != null &&
						(templates.length > 0 ? (
							templates.map(template => {
								return (
									<Table.Row key={`${template.id}`} onClick={onClickRow(template.id)}>
										<ResearchRecordTemplateItem
											isSchedule={isScheduled}
											researchId={researchId}
											{...template}
										/>
									</Table.Row>
								)
							})
						) : (
							<Table.Row>
								<Table.Cell colSpan={5}>
									<div
										style={{
											fontSize: 15,
											textAlign: 'center',
											color: '#999',
											margin: 10,
										}}
									>
										첫 {title} 을 등록해 보세요.
									</div>
								</Table.Cell>
							</Table.Row>
						))}
				</Table.Body>
			</PaddedTable>
			<TemplateAddModal
				isPartial={isPartial}
				researchId={researchId}
				open={isAddModalOpen}
				onClose={() => setAddModalOpen(false)}
				isSchedule={isScheduled}
				templateName={title}
				hasScreening={hasScreening}
			/>
		</div>
	)
}

ResearchTemplateList.propTypes = {
	researchId: PropTypes.number,
	isPartial: PropTypes.bool,
	isScheduled: PropTypes.bool,
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
	hasScreening: PropTypes.bool,
}

export default ResearchTemplateList
