import React from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import 'moment/locale/ko'

import ResearchTemplateHistoryDetailView from './ResearchTemplateHistoryDetailView'
import LoadingView from '../../common/loader'

import { GET_RECORD_TEMPLATE_CHANGE_DETAIL } from '../../../graphql/queries/record'

const ResearchTemplateHistoryDetail = props => {
	const { id, match } = props
	const versionCode = parseInt(match.params.subsubId)

	return (
		<Query query={GET_RECORD_TEMPLATE_CHANGE_DETAIL} variables={{ templateId: id, versionCode }}>
			{({ loading, data, error }) => {
				if (loading) {
					return <LoadingView />
				} else {
					const { recordTemplateChangeDetail: change } = data

					return <ResearchTemplateHistoryDetailView template {...props} {...change} />
				}
			}}
		</Query>
	)
}

ResearchTemplateHistoryDetail.propTypes = {}

export default ResearchTemplateHistoryDetail
