import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useQuery } from 'react-apollo'
import styled from 'styled-components'

import { GET_PATIENT_BY_ID } from '../../../graphql/queries/patient'
import { HeaderPlaceholder } from './HeaderPlaceholder'

import { HeaderBaseContent } from '../../common/basic/StyledBox'

import PatientInfoHeaderView from './PatientInfoHeaderView'

/**
 * 환자 정보 헤드
 */
const PatientInfoHeader = ({ patientId, researchId, isOnResearchPage }) => {
	const { loading, data, error } = useQuery(GET_PATIENT_BY_ID, {
		variables: {
			id: patientId,
			researchId,
		},
	})

	if (loading === true || error != null) return <HeaderPlaceholder />

	const { patient: { birthDate, name, sexMale, patientResearches } = {} } = {
		...data,
	}
	const {
		research: { title: researchName },
		code: researchCode,
	} = _.isEmpty(patientResearches) === true ? null : patientResearches[0]

	return (
		<PatientInfo>
			<PatientInfoHeaderView
				birthDate={birthDate}
				researchId={researchId}
				isOnResearchPage={isOnResearchPage}
				sexMale={sexMale}
				researchCode={researchCode}
				researchName={researchName}
				name={name}
			/>
		</PatientInfo>
	)
}

PatientInfoHeader.propTypes = {
	patientId: PropTypes.number.isRequired,
	researchId: PropTypes.number,
	isOnResearchPage: PropTypes.bool.isRequired,
}

PatientInfoHeader.defaultProps = {
	researchId: null,
}

export default PatientInfoHeader

const PatientInfo = styled(HeaderBaseContent)`
	width: 240px;
	overflow-x: hidden;
`
