import styled from 'styled-components'

export const InputMessageIcon = styled.img`
	margin-left: 5px;
	width: 15px;
	height: 15px;
`

export const FullWidthImage = styled.img`
	width: 100%;
`
