import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link, useParams, useHistory } from 'react-router-dom'
import { useQuery } from 'react-apollo'
import { PlaceholderImage, PlaceholderLine, Placeholder, Modal, Icon } from 'semantic-ui-react'
import { GET_PATIENT_DETAIL_BY_ID } from '../../../graphql/queries/patient'
import { RouteMenu } from '../../common/RouteMenu'
import {
	ROUTE_PATIENT_DETAIL_RECORD,
	ROUTE_PROJECT_DETAIL_DEFAULT,
	ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL,
	ROUTE_MY_PATIENTS,
	ROUTE_PROJECT_DETAIL_PATIENT_DETAIL,
	ROUTE_PATIENT_DETAIL_DEFAULT,
} from '../../../constants/routes'
import MiniErrorView from '../../common/error/MiniErrorView'
import {
	COLOR_TEXT_LIGHTGRAY,
	COLOR_BORDER_GRAY,
	COLOR_PRIMARY,
	BASIC_COLORS,
	COLOR_ERROR,
} from '../../../constants/styles'
import { replaceParams } from '../../../libs/routeHelper'
import { toLocalizedDateString } from '../../../libs/dateHelper'
import { truncateString, getAgeRange, convertGender } from '../../../libs/valueHelper'
import EllipsisDropdown from '../../common/EllipsisDropdown'
import { MiniBar } from '../../common/basic/MiniBar'
import AddButton from '../../common/basic/AddButton'
import RecordAddModal from './RecordAddModal'
import { ResearchSubjectStatus, RecordType, ResearchType, ObjectType } from '../../../enum'
import StatusDotLabel from '../../common/StatusDotLabel'
import PatientStats from './PatientStats'

import StatsIcon from '../../../assets/images/icons/graph.png'
import SettingsIcon from '../../../assets/images/icons/settings.png'
import InfoIcon from '../../../assets/images/icons/info.png'
import ImageButton from '../../common/basic/ImageButton'
import PatientEditModal from './PatientEditModal'
import PatientDetailModal from './PatientDetailModal'
import useUserInfo from '../../../hooks/useUserInfo'
import useDeleteObject from '../../../hooks/useDeleteObject'
import DeleteObjectModal from '../../common/modal/DeleteObjectModal'
/**
 * 환자 상세 페이지용 사이드 메뉴
 *
 * 환자 정보, 진료기록 목록 등 포함
 */
const PatientSideMenu = () => {
	const history = useHistory()
	const { pid, id } = useParams()

	const { user } = useUserInfo() || {}
	const [isRecordAddModalOpen, setRecordAddModalOpen] = useState(null)
	const [isStatsModalOpen, setStatsModalOpen] = useState(false)
	const [isEditModalOpen, setEditModalOpen] = useState(false)
	const [isDetailModalOpen, setDetailModalOpen] = useState(false)

	const { handleOpen, ...deleteModalProps } = useDeleteObject()

	const isOnResearchPage = pid != null
	const currentId = parseInt(id, 10)
	const propRId = pid != null ? parseInt(pid, 10) : null
	const { data: { patient, patientRecords: records } = {}, loading, error, refetch: onUpdate } =
		useQuery(GET_PATIENT_DETAIL_BY_ID, {
			variables: {
				id: currentId,
				researchId: propRId,
			},
		}) || {}

	const hasRecord = records != null && records.totalCount > 0

	useEffect(() => {
		if (records != null && hasRecord === false) {
			setRecordAddModalOpen(true)
		}
	}, [hasRecord, records])

	const { items: recordList = {} } = records || {}
	const { id: patientId, code, registerer, patientResearches, lastRecordId } = patient || {}
	const isOwner = registerer && registerer.id === user.id

	const recordRoute =
		isOnResearchPage === true
			? ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL
			: ROUTE_PATIENT_DETAIL_RECORD

	const patientResearch = useMemo(() => {
		if (patientResearches != null && patientResearches.length > 0) {
			if (propRId != null) {
				return patientResearches.find(i => i.research.id === propRId)
			} else {
				return patientResearches[0]
			}
		} else {
			return null
		}
	}, [patientResearches, propRId])

	const {
		research: { patientBasicInfo, title: researchTitle, id: researchId, type: researchType } = {},
		status,
		code: researchCode,
	} = patientResearch || {}

	return (
		<PatientSideMenuContainer>
			{loading === true && <Placeholders />}
			{error != null && <MiniErrorView error={error} />}
			{patient != null && (
				<>
					{isOnResearchPage === true && patientResearch != null && (
						<PatientResearchInfo
							patientId={currentId}
							hasTitle
							patientResearches={patientResearches}
							researchId={researchId}
							patientResearch={patientResearch}
							researchTitle={researchTitle}
							status={status}
							researchCode={researchCode}
							researchType={researchType}
							isOnResearchPage={isOnResearchPage}
						/>
					)}
					<PatientProfile
						{...patient}
						isOwner={isOwner}
						onOpenDeleteModal={handleOpen}
						onDeleteComplete={() => {
							history.push(ROUTE_MY_PATIENTS)
						}}
						setStatsModalOpen={setStatsModalOpen}
						setEditModalOpen={setEditModalOpen}
						setDetailModalOpen={setDetailModalOpen}
						researchCode={researchCode}
						researchId={researchId}
						isOnResearchPage={isOnResearchPage}
						patientResearch={patientResearch}
					/>
					{isOnResearchPage === false &&
						patientResearches != null &&
						patientResearches.length > 0 && (
							<PatientResearchInfo
								patientId={currentId}
								hasTitle
								patientResearches={patientResearches}
								researchId={researchId}
								patientResearch={patientResearch}
								researchTitle={researchTitle}
								status={status}
								researchCode={researchCode}
								researchType={researchType}
							/>
						)}
					<Records>
						<RecordsWrapper>
							<RecordAddButtonContainer>
								<AddButton
									style={styles.addButton}
									onClick={() => {
										setRecordAddModalOpen(true)
									}}
									title="새로운 기록"
								/>
							</RecordAddButtonContainer>
							{recordList != null && (
								<RouteMenu
									menuItems={recordList.map(r => ({
										key: `SSM-PT-R-${r.id}`,
										title: r.summary,
										to: replaceParams(recordRoute, {
											pid: researchId,
											id: currentId,
											subId: r.id,
										}),
										...r,
									}))}
									menuOptions={{
										vertical: true,
									}}
									renderMenuItem={item => {
										return (
											<RecordItem
												{...item}
												isOwner={user.id === item.creator.id}
												hasRecord={hasRecord}
												onDelete={() => {
													const r = recordList.find(i => i.id !== item.id)
													if (r == null) {
														if (isOnResearchPage === true) {
															history.replace(
																replaceParams(ROUTE_PROJECT_DETAIL_PATIENT_DETAIL, {
																	id: researchId,
																	subId: currentId,
																})
															)
														} else {
															history.replace(
																replaceParams(ROUTE_PATIENT_DETAIL_DEFAULT, {
																	id: currentId,
																})
															)
														}
													} else {
														history.replace(
															replaceParams(recordRoute, {
																pid: researchId,
																id: currentId,
																subId: r.id,
															})
														)
													}
													onUpdate()
												}}
												onOpenDeleteModal={handleOpen}
												isOnResearchPage={isOnResearchPage}
											/>
										)
									}}
								/>
							)}
						</RecordsWrapper>
					</Records>
					{isRecordAddModalOpen === true && (
						<RecordAddModal
							isOpen={
								isRecordAddModalOpen === true ||
								(isRecordAddModalOpen == null && lastRecordId == null)
							}
							onClose={() => {
								setRecordAddModalOpen(false)
							}}
							updateParent={onUpdate}
							userId={user.id}
							isFirst={lastRecordId == null}
							patientResearches={patientResearches}
							patientId={patientId}
							hasRecord={hasRecord}
							lastRecordId={lastRecordId}
							isOnResearchPage={isOnResearchPage}
							researchId={researchId}
						/>
					)}
					<PatientStatsModal
						isOpen={isStatsModalOpen}
						onClose={() => {
							setStatsModalOpen(false)
						}}
						patientId={patientId}
					/>
					<PatientEditModal
						{...patient}
						{...patientResearch}
						patientId={patientId}
						open={isEditModalOpen}
						onClose={() => setEditModalOpen(false)}
					/>
					<PatientDetailModal
						{...patientResearch}
						{...patient}
						patientBasicInfo={patientBasicInfo != null ? patientBasicInfo.split('|') : []}
						code={code}
						researchId={researchId}
						isOpen={isDetailModalOpen}
						onClose={() => setDetailModalOpen(false)}
					/>
					<DeleteObjectModal {...deleteModalProps} />
				</>
			)}
		</PatientSideMenuContainer>
	)
}

export default PatientSideMenu

/**
 * Sub Components
 */

/**
 * 환자 프로필 정보
 */
const PatientProfile = props => {
	const {
		isOwner,
		name,
		birthDate,
		sexMale,
		isOpen,
		id: patientId,
		patientResearch,
		onOpenDeleteModal,
		onDeleteComplete,
		patientCode,
		setStatsModalOpen,
		setEditModalOpen,
		setDetailModalOpen,
		isOnResearchPage,
		registerer,
		isHashed,
	} = props

	let isBlinded
	if (
		patientResearch != null &&
		patientResearch.research != null &&
		patientResearch.research.userResearcherInfo != null
	) {
		;({ isBlinded } = patientResearch.research.userResearcherInfo)
	}

	const hasName = isHashed === false && name != null

	return (
		<PatientProfileContainer hasBorder={!isOnResearchPage} isOpen={isOpen}>
			<HeaderContainer>
				<PatientName>{hasName === true ? name : '익명'}</PatientName>
				{isOwner === true && (
					// <PatientSettingMenu>
					<EllipsisDropdown
						direction="right"
						options={[
							{
								key: 'PP-DEL',
								content: '삭제',
								style: {
									color: BASIC_COLORS.red,
								},
								onClick: () =>
									onOpenDeleteModal(ObjectType.PATIENT.value, patientId, {
										onComplete: onDeleteComplete,
									}),
							},
						]}
					/>
					// </PatientSettingMenu>
				)}
				<PatientSubInfo>
					{getAgeRange(birthDate)} <MiniBar /> {convertGender(sexMale)}
				</PatientSubInfo>
			</HeaderContainer>
			<HeaderTools>
				{isOnResearchPage !== true ? (
					<PatientCode>{patientCode != null && `# ${patientCode}`}</PatientCode>
				) : (
					<PatientCode>
						<Icon name="doctor" />
						{registerer.name}
						<MiniBar />
						{registerer.institution.name}
					</PatientCode>
				)}
				<IconButton onClick={() => setStatsModalOpen(true)} src={StatsIcon} />
				{isOwner === true ? (
					<IconButton onClick={() => setEditModalOpen(true)} src={SettingsIcon} />
				) : (
					isBlinded === false && (
						<IconButton onClick={() => setDetailModalOpen(true)} src={InfoIcon} />
					)
				)}
			</HeaderTools>
		</PatientProfileContainer>
	)
}

PatientProfile.propTypes = {
	isOwner: PropTypes.bool.isRequired,
	name: PropTypes.string,
	birthDate: PropTypes.string.isRequired,
	sexMale: PropTypes.bool.isRequired,
	isOpen: PropTypes.bool,
	id: PropTypes.number.isRequired,
	patientResearch: PropTypes.shape({
		research: PropTypes.shape({
			userResearcherInfo: PropTypes.shape({
				isBlinded: PropTypes.bool,
			}),
		}),
	}),
	onOpenDeleteModal: PropTypes.func.isRequired,
	onDeleteComplete: PropTypes.func.isRequired,
	patientCode: PropTypes.string,
	setStatsModalOpen: PropTypes.func.isRequired,
	setEditModalOpen: PropTypes.func.isRequired,
	setDetailModalOpen: PropTypes.func.isRequired,
	isOnResearchPage: PropTypes.bool.isRequired,
	registerer: PropTypes.shape({
		name: PropTypes.string,
		institution: PropTypes.shape({
			name: PropTypes.string,
		}),
	}).isRequired,
	isHashed: PropTypes.bool.isRequired,
}

PatientProfile.defaultProps = {
	patientResearch: null,
	name: null,
}

/**
 * 환자 프로젝트 정보
 */
const PatientResearchInfo = props => {
	const {
		patientId,
		researchId,
		researchTitle,
		status,
		researchCode,
		researchType,
		isOnResearchPage,
	} = props

	const researchStatus = ResearchSubjectStatus[status]
	return (
		<div>
			<ResearchContainer hasBorder={isOnResearchPage}>
				<ResearchTitle
					to={replaceParams(ROUTE_PROJECT_DETAIL_DEFAULT, {
						id: researchId,
					})}
				>
					{researchTitle}
				</ResearchTitle>
				<SubjectInfo>
					<StatusContainer>
						<ResearchCode>
							<Link
								to={replaceParams(ROUTE_PROJECT_DETAIL_PATIENT_DETAIL, {
									id: researchId,
									subId: patientId,
								})}
							>
								{researchCode}
							</Link>
						</ResearchCode>
					</StatusContainer>
					{researchType === ResearchType.SCHEDULED.value && (
						<>
							<StatusDotLabel {...researchStatus} size="small" />
						</>
					)}
				</SubjectInfo>
			</ResearchContainer>
		</div>
	)
}

PatientResearchInfo.propTypes = {
	hasTitle: PropTypes.bool,
	researchId: PropTypes.number,
	researchTitle: PropTypes.string,
	status: PropTypes.string,
	researchCode: PropTypes.string,
	researchType: PropTypes.string,
	patientId: PropTypes.number,
	isOnResearchPage: PropTypes.bool,
}

PatientResearchInfo.defaultProps = {
	hasTitle: false,
	researchId: null,
	isOnResearchPage: false,
}

/**
 * 진료기록 목록 개별 항목
 */
const RecordItem = props => {
	const {
		id,
		summary,
		template,
		researches,
		visitedDate,
		onDelete,
		onOpenDeleteModal,
		isOwner,
		type,
		isOnResearchPage,
	} = props

	let research, templateName, title
	if (researches != null && researches.length > 0) {
		research = researches[0]
	}

	if (template != null) {
		;({ title: templateName } = template)
	}

	if (type === RecordType.DROP.value) {
		title = '탈락 처리'
	} else {
		title = summary || templateName || '차트 기록'
	}

	return (
		<div>
			{title}
			{research != null && isOnResearchPage !== true && (
				<ResearchInfo>{truncateString(research.title, 15)}</ResearchInfo>
			)}
			<RecordDate>{toLocalizedDateString(visitedDate)}</RecordDate>
			<DropdownMenu>
				{isOwner === true && type !== RecordType.DROP.value && (
					<EllipsisDropdown
						options={[
							{
								key: `psm-r-${id}-del`,
								content: '삭제',
								style: {
									color: COLOR_ERROR,
								},
								onClick: () => {
									onOpenDeleteModal(ObjectType.RECORD.value, id, {
										onComplete: onDelete,
									})
								},
							},
						]}
					/>
				)}
			</DropdownMenu>
		</div>
	)
}

RecordItem.propTypes = {
	id: PropTypes.number.isRequired,
	summary: PropTypes.string,
	template: PropTypes.shape({
		title: PropTypes.string,
	}),
	researches: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
		})
	),
	visitedDate: PropTypes.string,
	onOpenDeleteModal: PropTypes.func.isRequired,
	isOwner: PropTypes.bool.isRequired,
	type: PropTypes.string.isRequired,
	isOnResearchPage: PropTypes.bool.isRequired,
	onDelete: PropTypes.func.isRequired,
}

/**
 * 로딩 중 플레이스홀더
 */
const Placeholders = () => {
	return (
		<div>
			<Placeholder>
				<PlaceholderImage />
				<PlaceholderLine />
				<PlaceholderLine />
				<PlaceholderLine />
				<PlaceholderLine />
				<PlaceholderLine />
			</Placeholder>
		</div>
	)
}

/**
 * 환자 분석 정보 Modal
 */
const PatientStatsModal = ({ isOpen, onClose, patientId }) => {
	return (
		<Modal size="large" open={isOpen} onClose={onClose} closeIcon>
			<Modal.Header>진료 분석</Modal.Header>
			<Modal.Content>
				<PatientStats patientId={patientId} />
			</Modal.Content>
		</Modal>
	)
}

PatientStatsModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	patientId: PropTypes.number.isRequired,
}

/**
 * Styled Components
 */

const PatientSideMenuContainer = styled.div`
	text-align: left;
	display: flex;
	flex-direction: column;
	height: 100%;
`
const PatientProfileContainer = styled.div`
	padding: 10px 30px;
	border-bottom: ${props => (props.hasBorder === true ? `1px solid ${COLOR_BORDER_GRAY}` : 'none')};
	position: ${props => (props.isOpen === false ? 'absolute' : 'relative')};
	left: ${props => (props.isOpen === false ? '280px' : 'auto')};
	top: ${props => (props.isOpen === false ? '20px' : 'auto')};
	width: ${props => (props.isOpen === false ? '200px' : 'auto')};
	background: ${props => (props.isOpen === false ? 'white' : 'none')};
	box-shadow: ${props => (props.isOpen === false ? '1px 2px 5px rgba(0, 0, 0, 0.05)' : 'none')};
	border-radius: 2px;
	padding-bottom: 5px;
`

const HeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: baseline;
`

const PatientName = styled.h6`
	flex: 1;
`

const HeaderTools = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 5px;
`
const PatientCode = styled.div`
	font-weight: 500;
	line-height: 20px;
	flex: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

const Records = styled.div`
	overflow: hidden;
	flex: 1;
`

const RecordsWrapper = styled.div`
	height: 100%;
	overflow: auto;
`

const ResearchInfo = styled.div`
	font-size: 13px;
	color: ${COLOR_PRIMARY};
	font-weight: 300;
`

const RecordDate = styled.div`
	color: ${COLOR_TEXT_LIGHTGRAY};
	font-size: 12px;
	font-weight: 300;
`

const DropdownMenu = styled.div`
	position: absolute;
	right: 0px;
	top: 50%;
	margin-top: -18px;
`

const IconButton = styled(ImageButton)`
	width: 20px;
	height: 20px;
	box-sizing: content-box;
	padding: 3px;
`

const PatientSubInfo = styled.span`
	color: ${COLOR_TEXT_LIGHTGRAY};
	font-size: 13px;
	font-weight: 400;
`

const RecordAddButtonContainer = styled.div`
	padding: 10px 30px;
	width: 100%;
`

const ResearchTitle = styled(Link)`
	color: ${COLOR_PRIMARY};
	display: block;
	width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	font-size: 15px;
	font-weight: 400;

	:hover {
		color: ${COLOR_PRIMARY};
		opacity: 0.4;
	}
`

const ResearchContainer = styled.div`
	padding: 10px 30px;
	padding-bottom: 20px;

	border-bottom: ${props => (props.hasBorder === true ? `1px solid ${COLOR_BORDER_GRAY}` : 'none')};
`

const SubjectInfo = styled.div`
	margin-top: 3px;
	flex-direction: row;
	display: flex;
	align-items: center;
`

const StatusContainer = styled.div`
	display: flex;
	margin-right: 5px;
	flex: 1;
`
const ResearchCode = styled.span`
	font-weight: 500;
`

const styles = {
	addButton: {
		width: '100%',
	},
}
