import React from 'react'
import PropTypes from 'prop-types'
import { useLocation, NavLink, useParams } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'
import CaseFeed from './CaseFeed'
import { CaseListType } from '../../enum'
import { FONT_SIZE } from '../../constants/styles'
import { ROUTE_CASE_SEARCH, ROUTE_CASE_SEARCH_TYPE } from '../../constants/routes'
import { replaceParams } from '../../libs/routeHelper'
import TagList from './TagList'

const CaseSearch = props => {
	const { search } = useLocation()
	const { searchType } = useParams()
	const urlParams = new URLSearchParams(search)
	const searchQuery = urlParams.get('q')
	return (
		<div style={{ maxWidth: 800, margin: '0 auto' }}>
			<div style={{ marginBottom: 30 }}>
				<div style={{ fontSize: FONT_SIZE.h5, fontWeight: 'bold' }}>{`검색: ${searchQuery}`}</div>
			</div>
			<div style={{ marginBottom: 30 }}>
				<Menu pointing secondary>
					<Menu.Item
						as={NavLink}
						to={`${replaceParams(ROUTE_CASE_SEARCH_TYPE, {
							searchType: 'case',
						})}?q=${searchQuery}`}
					>
						<span>케이스</span>
					</Menu.Item>
					<Menu.Item
						as={NavLink}
						to={`${replaceParams(ROUTE_CASE_SEARCH_TYPE, {
							searchType: 'tag',
						})}?q=${searchQuery}`}
					>
						<span>태그</span>
					</Menu.Item>
				</Menu>
			</div>
			{searchType === 'case' && (
				<CaseFeed type={CaseListType.SEARCH.value} search={searchQuery} useAddButon={false} />
			)}
			{searchType === 'tag' && <TagList search={searchQuery} />}
		</div>
	)
}

CaseSearch.propTypes = {}

export default CaseSearch
