import React, { useState, createRef, useEffect } from 'react'
import styled from 'styled-components'
import { Modal, Input, Button, TextArea, Form } from 'semantic-ui-react'

export const SimpleInputModal = ({
  isOpen,
  placeholder,
  title,
  hasDescription,
  descrpitionPlaceholder,
  onClose,
  onSubmit,
}) => {
  const [inputValue, setInputValue] = useState(null)
  const [descriptionValue, setDescriptionValue] = useState(null)
  return (
    <Modal size="tiny" open={isOpen} closeIcon onClose={onClose}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            autoFocus
            placeholder={placeholder}
            fluid
            value={inputValue}
            onChange={(e, { value }) => setInputValue(value)}
          />
          {hasDescription === true && (
            <Form.TextArea
              placeholder={descrpitionPlaceholder}
              fluid
              value={descriptionValue}
              onChange={(e, { value }) => setInputValue(setDescriptionValue)}
            />
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          onClick={() => {
            setInputValue('')
            setDescriptionValue('')
            onSubmit(inputValue, descriptionValue)
            onClose()
          }}
        >
          확인
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
