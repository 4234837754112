import styled, { css } from 'styled-components'
import { COLOR_TEXT, COLOR_TEXT_LIGHTGRAY, COLOR_PRIMARY } from '../../../constants/styles'

export const ToolBoxTitle = styled.div`
  color: ${COLOR_TEXT}
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 700;
`

export const EmptyHeader = styled.div`
	font-size: ${props => props.fontSize || 24}px;
	color: ${COLOR_TEXT_LIGHTGRAY};
`

export const SmallTitle = styled.div`
	line-height: 20px;
	font-size: 14px;
`

export const CenteredText = styled.div`
	text-align: center;
`

export const MediumText = styled.div`
	font-size: 16px;
`
export const LargeText = styled.div`
	font-size: 18px;
`

export const ComponentLabel = styled.div`
	margin-bottom: 5px;
`

export const LinkColoredText = styled.div`
	color: ${COLOR_PRIMARY};
	cursor: pointer;
	display: ${props => (props.inline === true ? 'inline-block' : 'block')}

	:hover {
		opacity: 0.4;
		transition: opacity 0.2s;
	}
`

export const InputErrorMessage = styled.div`
	font-size: 13px;
	color: red;
	text-align: 'left';
`

export const InputMessage = styled.div`
	font-size: 13px;
	color: ${props => props.color};
	text-align: left;
	margin-top: -5px;
`

export const InputOuterMessage = styled(InputMessage)`
	margin-top: 0px;
`

export const PatientSurveySectionTitle = styled.h4`
	margin-bottom: 50px;
`

export const PatientSurveyDescription = styled.div`
	padding: 0px 22px;
	font-size: 20px;
	line-height: 24px;
	margin-top: 20px;
`

/**
 * css
 */

export const styleEllipsisOverflow = css`
	text-overflow: ellipsis;
	overflow-x: hidden;
	white-space: nowrap;
`
