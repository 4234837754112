import React from 'react'
import { Placeholder } from 'semantic-ui-react'

function PlaceholderShort() {
	return (
		<div
			style={{
				height: '100%',
				width: '100%',
				maxWidth: 300,
				margin: 'auto',
				textAlign: 'center',
			}}
		>
			<Placeholder fluid>
				<Placeholder.Paragraph>
					<Placeholder.Line length="long"></Placeholder.Line>
					<Placeholder.Line length="medium"></Placeholder.Line>
					<Placeholder.Line length="medium"></Placeholder.Line>
					<Placeholder.Line length="long"></Placeholder.Line>
				</Placeholder.Paragraph>
			</Placeholder>
		</div>
	)
}

export default PlaceholderShort
