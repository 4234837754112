import gql from 'graphql-tag'

export const GET_NOTICE_MODAL_OPEN = gql`
	query {
		isNoticeModalOpen @client
	}
`

export const GET_NOTICE_LOADED = gql`
	query {
		isNoticeLoaded @client
	}
`

export const GET_READ_NOTICE = gql`
	query {
		readNoticesId @client
	}
`

export const GET_NOTICES = gql`
	query {
		activeNotices {
			id
			title
			content
			startDate
			type
		}
	}
`
