import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'semantic-ui-react'
import MedInfoQueryList from './MedInfoQueryList'
import InfoPopup from '../../../common/popup/InfoPopup'

export default class MedInfoQueryModal extends PureComponent {
	static propTypes = {
		medInfoId: PropTypes.number,
		medInfoTypeName: PropTypes.string,
		open: PropTypes.bool,
		onClose: PropTypes.func,
		onUpdateSingleMedInfo: PropTypes.func,
		onOpenWriteModal: PropTypes.func,
		canWriteQuery: PropTypes.bool,
	}

	handleOpenWriteModal = () => {
		const { onOpenWriteModal, medInfoId, medInfoTypeName } = this.props
		onOpenWriteModal({
			medInfoId,
			medInfoTypeName,
		})
	}

	render() {
		const {
			open,
			onClose,
			medInfoTypeName,
			medInfoId,
			onUpdateSingleMedInfo,
			canWriteQuery,
		} = this.props

		if (!medInfoId || !medInfoTypeName) return <div />

		return (
			<Modal open={open} onClose={onClose} closeOnDimmerClick={false}>
				<Modal.Header>
					리뷰 메시지
					<InfoPopup
						style={{ marginLeft: 12 }}
						description="데이터 수정이 필요할 경우, 기록 작성자에게 수정을 요청할 수 있습니다."
					/>
					<span style={{ float: 'right', color: '#999', fontSize: 16, fontWeight: 300 }}>
						{medInfoTypeName}
					</span>
				</Modal.Header>
				<Modal.Content>
					<MedInfoQueryList medInfoId={medInfoId} onUpdateSingleMedInfo={onUpdateSingleMedInfo} />
				</Modal.Content>
				<Modal.Actions>
					{canWriteQuery && (
						<Button primary floated="left" onClick={this.handleOpenWriteModal}>
							리뷰 메시지 작성
						</Button>
					)}
					<Button onClick={onClose}>닫기</Button>
				</Modal.Actions>
			</Modal>
		)
	}
}
