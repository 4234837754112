import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Slider from 'rc-slider/lib/Slider'
import useLocalMedInfo from '../../../hooks/useLocalMedInfo'

const DEFAULT_VAS_RANGE = [
	{ min: null, max: 0, description: '통증이 없음' },
	{ min: 1, max: 2, description: '조금 아프다' },
	{ min: 3, max: 4, description: '아프다' },
	{ min: 5, max: 6, description: '많이 아프다' },
	{ min: 7, max: 8, description: '매우 아프다' },
	{ min: 9, max: null, description: '상상할 수 있는 가장 심한 정도로 아프다' },
]

const PatientSurveyVas = ({ medInfo, inBlock }) => {
	const { id, medInfoType, vasValue } = medInfo
	const { vasMax, unit } = medInfoType
	const [, onUpdate] = useLocalMedInfo(id)
	const numval = parseInt(vasValue)

	const vasRange = useMemo(() => {
		try {
			const vasRange = JSON.parse(unit).vasRange
			if (vasRange) return vasRange
			if (vasMax == null || vasMax === 10) return DEFAULT_VAS_RANGE
			return null
		} catch (e) {
			if (vasMax == null || vasMax === 10) {
				return DEFAULT_VAS_RANGE
			}
			return null
		}
	}, [unit, vasMax])

	const description = useCallback(
		value => {
			if (isNaN(value) || !vasRange) return 'ㅤ'

			for (let i = 0; i < vasRange.length; i++) {
				const min = vasRange[i].min
				const max = vasRange[i].max
				// debugger
				if (min != null && value < min) continue
				if (max != null && value > max) continue

				return vasRange[i].description
			}
			return 'ㅤ'
		},
		[vasRange]
	)

	return (
		<Container inBlock={inBlock}>
			<DisplayValue>
				<div className="value">{vasValue != null ? vasValue : 'ㅤ'}</div>
				<div className="description">{description(numval)}</div>
			</DisplayValue>
			<Slider
				min={0}
				max={vasMax || 10}
				value={numval}
				onChange={value => {
					onUpdate('vasValue', value)
				}}
				handleStyle={{ display: 'none' }}
				railStyle={{
					height: 12,
					background: 'linear-gradient(91deg, #f4f4f4, #e9e9e9)',
					border: 'solid 0.5px rgba(0, 0, 0, 0.1)',
				}}
				trackStyle={{ height: 12, background: 'linear-gradient(to bottom, #aee2ee, #7ecadf)' }}
			/>
			<Description>
				<span>{description(0)}</span>
				<span>{description(vasMax || 10)}</span>
			</Description>
		</Container>
	)
}

PatientSurveyVas.propTypes = {
	medInfo: PropTypes.object,
	inBlock: PropTypes.bool,
}

export default PatientSurveyVas

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	align-items: center;
	${props =>
		props.inBlock &&
		css`
			padding: 8px;
		`}
`
const DisplayValue = styled.div`
	text-align: center;
	margin: 4%;
	.value {
		font-size: 12vmin;
		font-weight: bold;
		line-height: 1.2;
	}
`

const Description = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	font-size: 2vmin;
`
