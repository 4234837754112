import 'core-js'
import 'regenerator-runtime/runtime'
import 'react-app-polyfill/ie11'
import 'semantic-ui-less/semantic.less'
import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from 'react-apollo'
import client from './apollo/apollo'
import * as serviceWorker from './serviceWorker'
import App from './App'

import ScrollToTop from './components/app/ScrollToTop'
import * as theme from './constants/styles'
import './index.css'

ReactDOM.render(
	<Router>
		<ScrollToTop>
			<ApolloProvider client={client}>
				<ThemeProvider theme={theme}>
					<App />
				</ThemeProvider>
			</ApolloProvider>
		</ScrollToTop>
	</Router>,
	document.getElementById('root')
)

serviceWorker.unregister()
