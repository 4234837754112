import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withFetch from '../../higherComponents/withFetch'
import { APP_SERVER_BASE } from '../../config'

class ErrorBoundary extends Component {
	static propTypes = {
		render: PropTypes.func,
		onFetch: PropTypes.func.isRequired,
		children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	}

	constructor(props) {
		super(props)
		this.state = { error: null }
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { error }
	}

	componentDidCatch(error, errorInfo) {
		// 프론트 에러 발생시 로그 전송
		const { onFetch } = this.props
		onFetch({
			url: `${APP_SERVER_BASE}/client/logs/error`,
			method: 'POST',
			contentType: 'application/json',
			body: JSON.stringify({
				message: error.message,
				stackTrace: errorInfo,
			}),
		})
	}

	render() {
		const { render } = this.props
		const { error } = this.state
		if (error != null) {
			// You can render any custom fallback UI
			if (render != null) {
				return render({ error })
			}
			return <div>오류 발생</div>
		}
		const { children } = this.props
		return children
	}
}

export default withFetch(ErrorBoundary)
