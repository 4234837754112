import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Icon, Popup } from 'semantic-ui-react'
import { SharingScopes } from '../../enum'

const ScopeLabel = ({ scope, style, popup, groupTitle }) => {
	const { icon, text } = SharingScopes[scope]
	if (popup === true) {
		return (
			<Popup
				content={text}
				trigger={
					<ScopeLabelBox style={style}>
						<Icon name={icon} />
					</ScopeLabelBox>
				}
			/>
		)
	}
	return (
		<ScopeLabelBox style={style}>
			<Icon name={icon} /> {scope === SharingScopes.GROUP.value ? groupTitle : text}
		</ScopeLabelBox>
	)
}

ScopeLabel.propTypes = {
	scope: PropTypes.string.isRequired,
	popup: PropTypes.bool,
	style: PropTypes.object,
}
ScopeLabel.defaultProps = {
	popup: true,
}

export default ScopeLabel

const ScopeLabelBox = styled.div`
	display: inline-block;
	cursor: pointer;
`
