import React from 'react'
import PropTypes from 'prop-types'
import { Radio, Input, Form, Grid } from 'semantic-ui-react'
import moment from 'moment'
import { InputType } from '../../enum'
import { PATIENT_INFO_FIELDS } from '../../constants'
import DateInput from '../common/basic/DateInput'
import SegmentToggle from '../common/basic/SegmentToggle'

/**
 * 환자 프로젝트 추가 정보 입력
 */
const PatientResearchForm = props => {
	const { researchFields, onChange, values, onChangeBirthDate } = props

	const renderItem = (fieldName, fieldInfo) => {
		if (fieldInfo != null) {
			if (fieldInfo.key === 'birthDateReal') {
				return (
					<Grid.Column>
						<Form.Field>
							<label>생년월일</label>
							<div className="full-width-wrapper">
								<DateInput
									locale="ko"
									selected={values[fieldName] && moment(values[fieldName])}
									openToDate={
										values[fieldName] ? moment(values[fieldName]) : moment().subtract({ years: 30 })
									}
									showMonthDropdown
									showYearDropdown
									scrollableYearDropdown
									disable
									placeholderText="클릭해서 날짜선택"
									dateFormat="YYYY-MM-DD"
									dropdownMode="select"
									dateFormatCalendar="YYYY MMMM"
									onChange={item => {
										onChange(null, { name: 'birthDateReal', value: item.format('YYYY-MM-DD') })
										onChangeBirthDate(null, { value: item.format('YYYY') })
									}}
									maxDate={moment()}
								/>
							</div>
						</Form.Field>
					</Grid.Column>
				)
			} else if (fieldInfo.inputType === InputType.RADIO.key) {
				return (
					<Grid.Column>
						<Form.Field key={fieldInfo.key} name={fieldName} value={values[fieldName]}>
							<label>{fieldInfo.text}</label>
							<Form.Group style={{ marginTop: 12 }}>
								{fieldInfo.options.map(option => (
									<Form.Field
										control={Radio}
										inline
										name={fieldInfo.key}
										onChange={onChange}
										key={option.value}
										label={option.text}
										value={option.value}
										checked={option.value === values[fieldName]}
									/>
								))}
							</Form.Group>
						</Form.Field>
					</Grid.Column>
				)
			} else if (fieldInfo.inputType === InputType.TOGGLE.key) {
				return (
					<Grid.Column>
						<Form.Field>
							<label>{fieldInfo.text}</label>
							<SegmentToggle
								fluid
								onChange={value => onChange(null, { value, name: fieldInfo.key })}
								active={values[fieldName]}
								items={fieldInfo.options}
							/>
						</Form.Field>
					</Grid.Column>
				)
			} else {
				return (
					<Grid.Column>
						<Form.Field
							placeholder={fieldInfo.placeholder}
							control={Input}
							key={fieldInfo.key}
							label={fieldInfo.text}
							name={fieldName}
							onChange={onChange}
							value={values[fieldName]}
						/>
					</Grid.Column>
				)
			}
		}
	}

	const items =
		researchFields != null
			? researchFields.map(field => {
					const fieldInfo = PATIENT_INFO_FIELDS[field]
					return renderItem(field, fieldInfo)
			  })
			: []
	return <React.Fragment>{items}</React.Fragment>
}

PatientResearchForm.propTypes = {
	researchFields: PropTypes.array,
	onChange: PropTypes.func.isRequired,
	onChangeBirthDate: PropTypes.func,
	values: PropTypes.object,
}

export default PatientResearchForm
