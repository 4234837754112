import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

/**
 * 버튼 중 하나 고르기
 */
const ButtonSelect = ({ label, style, className, options, onChange, value, name }) => {
	return (
		<Container className={className} style={style}>
			<label>{label}</label>
			<div>
				{options.map(o => {
					const { value: itemValue, text, key } = o
					const isActive = value === itemValue

					return (
						<ButtonItem
							key={key || itemValue}
							active={isActive}
							onClick={e => onChange(e, { value: itemValue, name })}
						>
							{text}
						</ButtonItem>
					)
				})}
			</div>
		</Container>
	)
}

ButtonSelect.propTypes = {
	label: PropTypes.string,
	style: PropTypes.object,
	className: PropTypes.string,
	options: PropTypes.array,
	onChange: PropTypes.func,
	value: PropTypes.string,
	name: PropTypes.string,
}

export default ButtonSelect

const ButtonItem = styled.div`
	display: inline-block;
	margin-right: 10px;
	font-weight: 400;
	padding: 10px;
	border-radius: 20px;
	color: ${props => (props.active === true ? 'white' : props.theme.COLOR_TEXT_DARKGRAY)};
	border: 1px solid
		${props =>
			props.active === true ? props.theme.COLOR_PRIMARY : props.theme.COLOR_BORDER_DARK_GRAY};
	background-color: ${props => (props.active === true ? props.theme.COLOR_PRIMARY : 'white')};
	margin: 3px 10px 3px 0px;
	transition: background-color 0.2s linear, border-color 0.2s linear, color 0.2s linear;
	cursor: pointer;

	:hover {
		opacity: 0.5;
	}

	:active {
		opacity: 1;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
	}
`

const Container = styled.div`
	margin-bottom: 30px;
`
