import React from 'react'
import { HashLink } from 'react-router-hash-link'
import { Icon, Header, Segment } from 'semantic-ui-react'

const NoEntityView = () => {
  return (
    <div>
      <div style={{ margin: 'auto', marginTop: 50, textAlign: 'center', maxWidth: 700 }}>
        <Header as="h1">
          <Icon name="warning sign" /> 존재하지 않는 항목입니다.
        </Header>
        <Segment style={{ textAlign: 'left', padding: 30, marginTop: 50, lineHeight: 1.3 }}>
          <Header as="h2">
            해당 항목이 존재하지 않거나 이미 삭제된 항목입니다. 뒤로가기 버튼을 눌러주세요!
          </Header>
        </Segment>
      </div>
    </div>
  )
}

export default NoEntityView
