import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
	Modal,
	Segment,
	Form,
	Button,
	Header,
	Grid,
	Checkbox,
	TextArea,
	Input,
} from 'semantic-ui-react'

// import DataField from '../../field/DataField'
import SelectOptionList from './SelectOptionList'
import MiniErrorView from '../../../common/error/MiniErrorView'

import { validateFields } from '../../../../libs/validationHelper'
import { InputSize, InputType } from '../../../../enum'
import { MED_INFO_TYPE_FIELDS } from '../../../../fields'
import { DEFAULT_MED_INFO_TYPE, MED_INFO_TYPE_FORMATS } from '../../../../constants'
import { COLOR_TEXT_LIGHTGRAY } from '../../../../constants/styles'

const INPUT_TYPES = Object.keys(InputType)
	.filter(key => key !== InputType.SECTION.key && InputType[key].notSelectable !== true)
	.map(key => {
		return InputType[key]
	})

const GRID_INPUT_TYPES = Object.keys(InputType)
	.filter(key => InputType[key].isGridColumn === true)
	.map(key => {
		return InputType[key]
	})

const INPUT_SIZE = Object.keys(InputSize).map(key => {
	const { value, text } = InputSize[key]
	return { value, text }
})

const TYPE_WITH_SELECT_OPTIONS = INPUT_TYPES.filter(i => i.select === true)
const TYPE_WITH_OPTIONS = INPUT_TYPES.filter(i => i.options === true || i.units === true)

const DEFAULT_OPTION = {
	value: '',
	text: '',
	min: null,
	max: null,
	format: '',
}

const initialState = {
	...DEFAULT_MED_INFO_TYPE,
	selectOptions: [
		{
			value: '',
			text: '옵션1',
			hasText: false,
		},
		{
			value: '',
			text: '옵션2',
			hasText: false,
		},
	],
	errors: {},
	options: [DEFAULT_OPTION],
	vasMax: 10,
	value: '',
}

/**
 * 필드정보 수정 모달
 */
export default class MedInfoTypeModal extends PureComponent {
	static propTypes = {
		open: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
		onOpen: PropTypes.func.isRequired,
		title: PropTypes.string.isRequired,
		onSubmit: PropTypes.func.isRequired,
		medInfoType: PropTypes.object,
		isEdit: PropTypes.bool,
		medInfoIndex: PropTypes.number,
		unit: PropTypes.string,
		readOnly: PropTypes.bool,
		parentType: PropTypes.string,
	}

	constructor(props) {
		super(props)

		this.state = {
			...initialState,
			...props.medInfoType,
			...(props.medInfoType &&
				props.medInfoType.unit && {
					unit: JSON.parse(props.medInfoType.unit),
				}),
			useOptions:
				props.medInfoType && props.medInfoType.options && props.medInfoType.options.length > 0,
		}
	}

	handleChange = name => event => {
		this.setState({
			[name]: event.target.value,
		})
	}

	handleChangeJSONOption = (e, { name, value }) => {
		this.setState(prev => {
			if (prev.unit != null)
				return {
					unit: {
						[name]: value,
					},
				}

			return {
				unit: {
					...prev.unit,
					[name]: value,
				},
			}
		})
	}

	handleChangeJSONOptionCheckbox = (e, { value, checked }) => {
		this.setState(prev => {
			if (prev.unit)
				return {
					unit: {
						[value]: checked,
					},
				}

			return {
				unit: {
					...prev.unit,
					[value]: checked,
				},
			}
		})
	}

	handleChangeTypeName = name => event => {
		this.setState({
			[name]: event.target.value,
			value: '',
		})
	}

	handleChangeInputType = (event, data) => {
		this.setState(prev => {
			let opt
			if (prev.inputType !== InputType.NUMBER.value && data.value === InputType.NUMBER.value) {
				opt = [DEFAULT_OPTION]
			}

			return {
				inputType: data.value,
				...(data.value === InputType.VAS_ONLY.key && {
					hasVAS: true,
				}),
				...(opt && {
					options: opt,
				}),
			}
		})
	}

	handleSelectChange = name => (event, data) => {
		this.setState({
			[name]: data.value,
		})
	}

	handleCheckboxChange = name => (event, data) => {
		this.setState({
			[name]: data.checked,
		})
	}

	handleAddSelectOption = (key, number) => () => {
		this.setState(prevState => {
			return {
				[key]: prevState[key].concat({
					text: '',
					value: '',
					hasText: false,
					...(number === true && {
						min: null,
						max: null,
					}),
				}),
			}
		})
	}

	handleRemoveSelectOption = key => index => () => {
		const { inputType } = this.state
		let minCount = key === 'options' ? 1 : 2
		if (inputType === 'CHECKBOX') minCount = 1
		else if (inputType === 'NUMBER') minCount = 0

		if (this.state[key].length > minCount) {
			this.setState({
				[key]: this.state[key].filter((item, idx) => {
					return index !== idx
				}),
			})
		} else {
			if (minCount > 0) alert(`${minCount}개 이상의 옵션이 있어야 합니다.`)
		}
	}

	handleSelectOptionChange = keyName => (index, key) => event => {
		this.setState({
			[keyName]: this.state[keyName].map((item, idx) => {
				if (index === idx) {
					return {
						...item,
						[key]: event.target.value,
					}
				}
				return item
			}),
		})
	}

	handleSelectOptionCbChange = keyName => (index, key) => (event, { value, checked }) => {
		this.setState({
			[keyName]: this.state[keyName].map((item, idx) => {
				if (index === idx) {
					return {
						...item,
						[key]:
							key === 'format' ? this.changeCheckboxFormat(value, checked, item.format) : checked,
					}
				}
				return item
			}),
		})
	}

	handleOptionUnitChange = index => event => {
		const { options } = this.state
		this.setState({
			options: options.map((item, idx) => {
				if (index === idx) {
					return {
						...item,
						text: event.target.value,
						value: event.target.value,
					}
				}
				return item
			}),
		})
	}

	handleFormatOptionChange = (e, { value, checked }) => {
		this.setState(prevState => {
			const prev = prevState.format ? prevState.format : ''
			const format = this.changeCheckboxFormat(value, checked, prev)
			return {
				format,
			}
		})
	}

	updateVasValue = vasValue => {
		this.setState({
			vasValue,
		})
	}

	handleRadioChange = (e, { value }) => {
		this.setState({
			value,
		})
	}

	validateOptions = selectOptions => {
		const values = []
		const texts = []

		for (let option of selectOptions) {
			if (!option.value || !option.text) {
				alert('선택지에 필수값을 모두 입력해주세요.')
				return false
			}

			values.push(option.value)
			texts.push(option.text)
		}

		if (new Set(values).size < values.length) {
			alert('선택지의 "영문변수값" 항목 중 중복 값을 제거해주세요.')
			return false
		}

		if (new Set(texts).size < texts.length) {
			alert('선택지의 "내용" 항목 중 중복 값을 제거해주세요.')
			return false
		}
	}

	validateFields = (hasSelectOptions, hasOptions) => {
		const { name, typeName, selectOptions, options, useOptions } = this.state
		const values = {
			name,
			typeName,
		}
		const errors = validateFields(values, MED_INFO_TYPE_FIELDS)

		if (errors) {
			this.setState({
				errors,
			})

			return false
		} else {
			if (hasSelectOptions) {
				const valid = this.validateOptions(selectOptions)
				if (valid === false) return false
			}

			if (useOptions && hasOptions && options.length > 0) {
				const valid = this.validateOptions(options)
				if (valid === false) return false
			}
		}

		return true
	}

	handleSubmit = async () => {
		// 새로운 항목 추가 또는 수정
		const { onSubmit, onClose, medInfoIndex } = this.props
		const { useOptions, value, vasValue, errors, ...medInfoType } = this.state

		const { name, typeName, inputType } = medInfoType

		const hasSelectOptions =
			TYPE_WITH_SELECT_OPTIONS.find(i => i.value === medInfoType.inputType) != null
		const hasOptions =
			(medInfoType.inputType === InputType.NUMBER.value || useOptions) &&
			TYPE_WITH_OPTIONS.find(i => i.value === medInfoType.inputType) != null

		const isValid = this.validateFields(hasSelectOptions, hasOptions)

		if (isValid === true) {
			await onSubmit(
				{
					medInfoType: {
						...medInfoType,
						...(!hasSelectOptions && {
							selectOptions: [],
						}),
						...(!hasOptions && {
							options: [],
						}),
						...(inputType === InputType.GRID.key &&
							(!medInfoType.childTypes || medInfoType.childTypes.length === 0) && {
								childTypes: [
									{
										inputType: InputType.GRID_ROW.key,
										name: name,
										typeName: typeName + 'Row',
									},
								],
							}),
						...(medInfoType.unit && {
							unit: JSON.stringify(medInfoType.unit),
						}),
					},
					value: '',
				},
				medInfoIndex
			)

			this.setState(initialState)
			onClose()
		}
	}

	changeCheckboxFormat = (value, checked, prev) => {
		return checked
			? (prev || '')
					.split('|')
					.concat([value])
					.join('|')
			: (prev || '')
					.split('|')
					.filter(item => item !== value)
					.join('|')
	}

	render() {
		const { open, onClose, onOpen, title, isEdit, parentType, readOnly } = this.props
		const {
			name,
			placeholder,
			inputType,
			typeName,
			description,
			hasVAS,
			vasMax,
			unit,
			selectOptions,
			format,
			size,
			options,
			entityType,
			errors,
			useOptions,
		} = this.state

		const specifics = InputType[inputType]

		if (!specifics) {
			return <MiniErrorView />
		}
		const OPTION_CHECKBOX = MED_INFO_TYPE_FORMATS.optionIsCheckbox

		const isGrid = parentType === InputType.GRID.value

		const inputTypes = isGrid === true ? GRID_INPUT_TYPES : INPUT_TYPES

		return (
			<Modal closeIcon open={open} onClose={onClose} onOpen={onOpen} closeOnDimmerClick={false}>
				<Modal.Header>
					필드 {readOnly === true ? '정보' : isEdit ? '수정하기' : '추가하기'}
					<SubTitle>
						{title} - {name}
					</SubTitle>
				</Modal.Header>
				<Modal.Content>
					<Form>
						<Grid>
							<Grid.Column width={16}>
								<Form.Group widths="equal">
									<Form.Field inline required>
										<label>필드 종류</label>
										<Form.Select
											options={inputTypes}
											onChange={this.handleChangeInputType}
											placeholder="필드 종류 선택"
											value={inputType}
										/>
									</Form.Field>
									<Form.Field inline required>
										<label>크기</label>
										<Form.Select
											options={INPUT_SIZE}
											onChange={this.handleSelectChange('size')}
											placeholder="크기 선택"
											value={size}
										/>
									</Form.Field>
								</Form.Group>
								<Form.Field required error={errors.name !== undefined}>
									<label>제목</label>
									<input
										onChange={this.handleChange('name')}
										placeholder="제목 입력하기"
										value={name}
									/>
								</Form.Field>
								<Form.Field required error={errors.typeName !== undefined}>
									<label>영문 변수 명</label>
									<input
										onChange={this.handleChange('typeName')}
										placeholder="데이터 추출시 사용할 변수명(영문, 숫자, 특수기호(_), (-)만 사용 가능)"
										value={typeName}
									/>
								</Form.Field>
								{InputType[inputType].hasPlaceholder === true && (
									<Form.Field>
										<label>플레이스 홀더</label>
										<input
											onChange={this.handleChange('placeholder')}
											placeholder="비어있을 때 표시할 메시지"
											value={placeholder}
										/>
									</Form.Field>
								)}
							</Grid.Column>
							<Grid.Column width={16}>
								<Form.Field>
									<label>추가설명</label>
									<TextArea
										onChange={this.handleChange('description')}
										value={description}
										placeholder="추가적인 설명이 필요하다면 적어주세요."
									/>
								</Form.Field>
							</Grid.Column>
							{Object.keys(specifics).length > 0 && (
								<Grid.Column width={16}>
									<Segment>
										<Header as="h4">세부 설정</Header>
										{specifics.vasScale === true && (
											<Form.Field>
												<Checkbox
													label="VAS 스케일 추가"
													onChange={this.handleCheckboxChange('hasVAS')}
													checked={hasVAS}
												/>
											</Form.Field>
										)}
										{hasVAS === true && (
											<Form.Field
												control={Input}
												label="슬라이더 최대값"
												type="number"
												value={vasMax}
												onChange={this.handleChange('vasMax')}
											/>
										)}
										{specifics.newline && (
											<Form.Field>
												<Checkbox
													label="선택지 줄바꿈"
													value="newline"
													onChange={this.handleFormatOptionChange}
													checked={format && format.includes('newline')}
												/>
											</Form.Field>
										)}
										{specifics.timeOnly === true && (
											<Form.Group>
												<label>입력 데이터</label>
												<Form.Checkbox
													label="날짜"
													name="timeOnly"
													value={false}
													checked={unit == null || unit.timeOnly !== true}
													onChange={this.handleChangeJSONOption}
												/>
												<Form.Checkbox
													label="시간"
													name="timeOnly"
													value
													checked={unit != null && unit.timeOnly === true}
													onChange={this.handleChangeJSONOption}
												/>
											</Form.Group>
										)}
										{inputType === InputType.DATE.value &&
											(unit == null || unit.timeOnly !== true) && (
												<Segment>
													{specifics.pastDate === true && (
														<Form.Field>
															<Checkbox
																label="과거 날짜만 선택"
																value="past"
																onChange={this.handleChangeJSONOptionCheckbox}
																checked={unit != null && unit.past}
															/>
														</Form.Field>
													)}
													{specifics.selectTime === true && (
														<Form.Field>
															<Checkbox
																label="시간 선택"
																value="selectTime"
																onChange={this.handleChangeJSONOptionCheckbox}
																checked={unit != null && unit.selectTime}
															/>
														</Form.Field>
													)}
												</Segment>
											)}
										{specifics.grid && (
											<Form.Field>
												<Checkbox
													label="행 고정"
													value="fixed"
													onChange={this.handleFormatOptionChange}
													checked={format && format.includes('fixed')}
												/>
												<Checkbox
													label="행 라벨 표시"
													value="labeled"
													onChange={this.handleFormatOptionChange}
													checked={format && format.includes('labeled')}
												/>
											</Form.Field>
										)}
										{specifics.search && (
											<Form.Field
												control={Input}
												value={entityType}
												label="용어 라이브러리 키"
												onChange={this.handleChange('entityType')}
											/>
										)}
										{specifics.options && (
											<Checkbox
												label="옵션 선택지 사용"
												value
												onChange={this.handleCheckboxChange('useOptions')}
												checked={useOptions}
											/>
										)}
										{specifics.options && useOptions && (
											<Checkbox
												style={{ marginLeft: 20, fontSize: '80%', fontWeight: '600' }}
												label="체크박스"
												name="radioGroup"
												value={OPTION_CHECKBOX}
												checked={format && format.includes(OPTION_CHECKBOX)}
												onChange={this.handleFormatOptionChange}
											/>
										)}

										{((specifics.options && useOptions) || specifics.select || specifics.units) && (
											<SelectOptionList
												items={specifics.options || specifics.units ? options : selectOptions}
												type={specifics.options || specifics.units ? 'options' : 'selectOptions'}
												onChange={this.handleSelectOptionChange}
												onCheckboxChange={this.handleSelectOptionCbChange}
												onUnitChange={this.handleOptionUnitChange}
												onDelete={this.handleRemoveSelectOption}
												onAddOption={this.handleAddSelectOption}
												checkbox={format && format.includes(OPTION_CHECKBOX)}
												number={specifics.units}
											/>
										)}
									</Segment>
								</Grid.Column>
							)}
						</Grid>
					</Form>
				</Modal.Content>
				{readOnly !== true && (
					<Modal.Actions>
						<Button primary onClick={this.handleSubmit}>
							{isEdit ? '수정하기' : '추가하기'}
						</Button>
					</Modal.Actions>
				)}
			</Modal>
		)
	}
}

const SubTitle = styled.div`
	display: inline-block;
	margin-left: 10px;
	font-weight: 300;
	font-size: 14px;
	color: ${COLOR_TEXT_LIGHTGRAY};
`
