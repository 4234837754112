import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-apollo'

import ResearchRecordChart from '../../../containers/ResearchRecordChart'
import ResearchRecordHistoryList from '../../../containers/ResearchRecordHistoryList'

import { GET_RESEARCH_RECORD_INFO } from '../../../graphql/queries/record'
import { FIXED_CONTAINER_MIN_WIDTH, FIXED_CONTAINER_MAX_WIDTH } from '../../../constants'
import { RecordType } from '../../../enum'
import ResearchRecordHistoryDetail from '../../../containers/ResearchRecordHistoryDetail'
import ErrorView from '../../common/error/ErrorView'
import NoEntityView from '../../common/error/NoEntityView'
import RecordUpdateTemplate from '../../record/RecordUpdateTemplate'
import {
	ROUTE_PROJECT_DETAIL_DATA_HISTORY,
	ROUTE_PROJECT_DETAIL_DATA_DETAIL_UPDATE,
	ROUTE_PROJECT_DETAIL_DATA_DETAIL_DEFAULT,
	ROUTE_PROJECT_DETAIL_DATA_HISTORY_DETAIL,
	ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL_CHANGE_DETAIL,
	ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL_CHANGES,
} from '../../../constants/routes'
import PageContainer from '../../common/container/PageContainer'
import MenuRouters from '../../common/route/MenuRouters'
import { PAGE_DEFAULT_MIN_WIDTH } from '../../../constants/styles'
import DropRecordView from '../../record/DropRecordView'

const menuItems = [
	{
		key: 'PR-R-CHR',
		value: null,
		title: '차트',
		exact: true,
		to: [
			ROUTE_PROJECT_DETAIL_DATA_DETAIL_DEFAULT,
			ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL_CHANGE_DETAIL,
			ROUTE_PROJECT_DETAIL_PATIENT_DETAIL_T_RECORD_DETAIL_CHANGES,
		],
		component: ResearchRecordChart,
	},
	{
		key: 'PR-R-HIS',
		value: 'history',
		title: '수정내역',
		to: ROUTE_PROJECT_DETAIL_DATA_HISTORY,
		component: ResearchRecordHistoryList,
	},
]

/**
 * 연구 기록 상세보기
 */
const ResearchRecordDetail = props => {
	const { researchId } = props
	const { recordId: sRID, subId: patientId, recordMenu: activeMenu } = useParams()
	const recordId = parseInt(sRID)

	const { data, loading, error } = useQuery(GET_RESEARCH_RECORD_INFO, {
		variables: {
			recordId,
			researchId,
		},
	})

	if (error != null) {
		return <ErrorView error={error} />
	} else if (loading !== true && data != null) {
		if (!data.recordDetail) {
			return <NoEntityView />
		}
	}

	const { recordDetail: { record } = {} } = data || {}
	const { type } = record || {}
	const isDropRecord = type === RecordType.DROP.value

	const basicProps = {
		...props,
		...record,
		loading,
		error,
		isOnResearchPage: true,
		recordId,
		patientId,
	}

	return (
		<Wrapper isApplyingMaxWidth={activeMenu !== 'update-template'}>
			<PageContainer fluid padded minWidth={PAGE_DEFAULT_MIN_WIDTH}>
				{isDropRecord === false ? (
					<MenuRouters
						basicProps={basicProps}
						menuItems={[
							...menuItems,
							{
								key: 'pr-rd-mn-rp',
								to: ROUTE_PROJECT_DETAIL_DATA_DETAIL_UPDATE,
								component: RecordUpdateTemplate,
							},
							{
								key: 'pr-rd-mn-hd',
								to: ROUTE_PROJECT_DETAIL_DATA_HISTORY_DETAIL,
								component: ResearchRecordHistoryDetail,
							},
						]}
						defaultItem={menuItems[0]}
					/>
				) : (
					<DropRecordView {...record} />
				)}
			</PageContainer>
		</Wrapper>
	)
}

ResearchRecordDetail.propTypes = {
	researchId: PropTypes.number.isRequired,
}

const Wrapper = styled.div`
	min-width: ${FIXED_CONTAINER_MIN_WIDTH}px;
	max-width: ${props => props.isApplyingMaxWidth && FIXED_CONTAINER_MAX_WIDTH}px;
	margin: auto;
	min-height: 500px;
	margin-bottom: 100px;
`

export default ResearchRecordDetail
