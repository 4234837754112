import styled from 'styled-components'

export const MiniBar = styled.span`
	margin-left: 4px;
	margin-right: 4px;
	height: 6px;
	width: 1px;
	background-color: #e4e4e4;
	display: inline-block;
`
