import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { LinkColoredText } from './StyledTexts'

/**
 * 더 보기 / 접기 토글
 */
const ShowMore = ({
	className,
	text = '더 보기',
	closeText = '접기',
	children,
	defaultShow = false,
	height,
	isOpen,
	onToggle,
	onTop = true,
}) => {
	const [isStateOpen, setStateOpen] = useState(defaultShow)

	const isShowing = isOpen != null ? isOpen : isStateOpen
	const ShowButton = (
		<ButtonTextBox>
			<ButtonText
				onClick={() => {
					if (onToggle != null) {
						onToggle()
					} else {
						setStateOpen(prev => !prev)
					}
				}}
			>
				{isShowing === true ? closeText : text}
			</ButtonText>
		</ButtonTextBox>
	)
	return (
		<div className={className}>
			{onTop === true && ShowButton}
			<ShowMoreContent height={height} isVisible={isShowing}>
				{children}
			</ShowMoreContent>
			{onTop === false && ShowButton}
		</div>
	)
}

ShowMore.propTypes = {
	className: PropTypes.string,
	text: PropTypes.string,
	closeText: PropTypes.string,
	children: PropTypes.node,
	defaultShow: PropTypes.bool,
	height: PropTypes.number,
	onToggle: PropTypes.func,
	isOpen: PropTypes.bool,
	onTop: PropTypes.bool,
}

export default ShowMore

const ShowMoreContent = styled.div`
	height: ${props => (props.isVisible === true ? props.height || 'auto' : '0px')};
	transition: height 0.3s;
	overflow: hidden;
`

const ButtonText = styled(LinkColoredText)`
	display: inline-block;
	padding: 10px;
`

const ButtonTextBox = styled.div`
	text-align: right;
`
