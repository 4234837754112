import React from 'react'
import PropTypes from 'prop-types'
import SearchTextInput from './SearchTextInput'
import { ReadOnlyValue, ReadOnlyValueNull } from './Styled'

/**
 * 검색 인풋
 */
const DataFieldSearch = ({
	value,
	readOnly,
	disabled,
	onKeydown,
	onUpdateMany,
	entityType,
	placeholder,
}) => {
	if (readOnly === true) {
		if (value == null && disabled !== true)
			return <ReadOnlyValueNull>입력값 없음</ReadOnlyValueNull>

		return <ReadOnlyValue disabled={disabled}>{value} </ReadOnlyValue>
	}

	return (
		<SearchTextInput
			// inputRef={inputRef}
			readOnly={readOnly}
			disabled={disabled}
			onKeyDown={onKeydown}
			onUpdateValues={onUpdateMany}
			value={value}
			entityType={entityType}
			placeholder={placeholder}
		/>
	)
}

DataFieldSearch.propTypes = {
	value: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	onKeydown: PropTypes.func,
	onUpdateMany: PropTypes.func,
	entityType: PropTypes.string,
	placeholder: PropTypes.string,
}

export default DataFieldSearch
