import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Card } from 'semantic-ui-react'
import { ShadowedBox } from '../common/basic/StyledBox'
import { CenteredText } from '../common/basic/StyledTexts'
import { InputType } from '../../enum'
import { BORDER_GRAY } from '../../constants/styles'
import { ASSET_PATH_ICONS } from '../../constants/assets'

const TYPES = Object.values(InputType)
const INPUT_TYPES_LAYOUT = TYPES.filter(i => i.isLayout === true)
const INPUT_TYPES_INPUTS = TYPES.filter(i => i.isLayout !== true && i.notSelectable !== true)

const TemplateSideBar = props => {
	return (
		<Box>
			<div>
				<DividedBox>
					<Title>레이아웃</Title>
					<div>
						{INPUT_TYPES_LAYOUT.map(i => {
							return <InputTypeItem key={i.value} {...i} />
						})}
					</div>
				</DividedBox>
				<DividedBox>
					<Title>입력도구</Title>
					<div>
						{INPUT_TYPES_INPUTS.map(i => {
							return <InputTypeItem key={i.value} {...i} />
						})}
					</div>
				</DividedBox>
			</div>
		</Box>
	)
}

TemplateSideBar.propTypes = {}

export default TemplateSideBar

const InputTypeItem = ({ value, text, icon }) => {
	return (
		<ItemContainer>
			<Card fluid>
				<Card.Content style={{ padding: 5 }}>
					<ItemImage src={`${ASSET_PATH_ICONS}${icon}`} />
					<Label>{text}</Label>
				</Card.Content>
			</Card>
		</ItemContainer>
	)
}

InputTypeItem.propTypes = {
	value: PropTypes.string,
	text: PropTypes.string,
	icon: PropTypes.string,
}

const Box = styled(ShadowedBox)`
	padding: 10px 16px;
	background: white;
`

const DividedBox = styled.div`
	padding-top: 5px;
	padding-bottom: 10px;

	:not(:first-child) {
		padding-top: 8px;
		border-top: ${BORDER_GRAY};
	}

	:last-child {
		padding-bottom: 5px;
	}
`

const ItemContainer = styled.div`
	display: inline-block;
	width: 50%;
	padding: 3px;
	text-align: center;

	:nth-child(odd) {
		padding-left: 0px;
	}
	:nth-child(even) {
		padding-right: 0px;
	}
`
const Title = styled.div`
	margin-bottom: 5px;
`
const ItemImage = styled.img`
	width: 30px;
	height: 30px;
	margin: auto;
`

const Label = styled(CenteredText)`
	font-size: 13px;
	line-height: 1;
`
