import React from 'react'
import PropTypes from 'prop-types'
import { ICON_IMAGES } from '../../../constants/assets'
import { ImageIcon } from './ImageIcon'

const MyIcon = ({ name, ...props }) => {
	return <ImageIcon src={ICON_IMAGES[name]} {...props} />
}

MyIcon.propTypes = {
	name: PropTypes.oneOf(Object.keys(ICON_IMAGES)).isRequired,
}

export default MyIcon
