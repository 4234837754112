import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'react-router-dom'

import SubSideMenuItem from '../components/common/SubSideMenuItem'
import SubSideMenuContainer from '../components/frame/SubSideMenuContainer'
import { RouteMenu } from '../components/common/RouteMenu'
import StatsDashboard from '../components/statistics/StatsDashboard'

import { ROUTE_MY_STATISTICS, ROUTE_STATISTICS_ANALYZE } from '../constants/routes'
import MenuRouters from '../components/common/route/MenuRouters'
import StatsAnalyze from '../components/statistics/StatsAnalyze'
import HeaderPageTitle from '../components/common/basic/HeaderPageTitle'

const MENU_ITEMS = [
	{
		key: 'ST_SS_DASHBOARD',
		title: '대시보드',
		to: ROUTE_MY_STATISTICS,
		exact: true,
		component: StatsDashboard,
	},
	{
		key: 'ST_SS_ANALYZE',
		title: '분석',
		to: ROUTE_STATISTICS_ANALYZE,
		exact: true,
		component: StatsAnalyze,
	},
]

const BREAD_CRUMB = {
	key: 'ST_BC',
	active: false,
	content: '통계',
	link: false,
}

export default class MyStatistics extends PureComponent {
	static propTypes = {}

	componentDidMount() {
		const { headerRef } = this.props

		if (headerRef.current != null) {
			headerRef.current.handleSetContent(<HeaderPageTitle>통계</HeaderPageTitle>)
			headerRef.current.handleSetLeftContent(null)
		}
	}

	render() {
		return (
			// <SubSideMenuContainer {...this.props} menuContent={<StatisticsSideMenuList />}>
			<MenuRouters
				menuItems={MENU_ITEMS}
				basicProps={{
					breadCrumbs: [BREAD_CRUMB],
				}}
			/>
			// </SubSideMenuContainer>
		)
	}
}

const StatisticsSideMenuList = () => {
	return (
		<React.Fragment>
			<RouteMenu
				menuItems={MENU_ITEMS}
				renderMenuItem={({ title }) => <SubSideMenuItem hasPadding>{title}</SubSideMenuItem>}
				menuOptions={{
					vertical: true,
					className: 'sub-side-menu',
				}}
			/>
		</React.Fragment>
	)
}

// import React from 'react'
// import PropTypes from 'prop-types'
// import moment from 'moment'
// import compose from 'lodash.flowright'
// import { graphql } from 'react-apollo'
// import { Grid, Header, Search, Menu } from 'semantic-ui-react/dist/commonjs'

// import PrepareView from '../components/common/error/PrepareView'
// import { GET_USER_INFO } from '../graphql/queries/user'
// import { InlineLoader } from '../components/common/loader/InlineLoader'
// import { GET_MY_STATISTICS } from '../graphql/queries/statistics'
// import RatioPieChart from '../components/statistics/RatioPieChart'
// import { DoctorStatisticsRequestType } from '../enum'
// import ErrorView from '../components/common/error/ErrorView'
// const colors = ['#56aa99', '#847dc1', '#e2b156', '#adbd33', '#23ddaa', '#dd33aa', '#9988cc']

// const dateTickFormatter = function(tickItem) {
// 	return moment(tickItem).format('YYYY-MM-DD')
// }

// const lineData = [
// 	{
// 		visitedDate: moment('2018-07-05').valueOf(),
// 		하지부종: 80,
// 		허리통증: 50,
// 		어깨결림: 40,
// 		치료: '청심연자음가미방/추나/침구',
// 	},
// 	{
// 		visitedDate: moment('2018-07-12').valueOf(),
// 		하지부종: 70,
// 		허리통증: 30,
// 		어깨결림: 30,
// 		치료: '청심연자음가미방/추나/침구',
// 	},
// 	{
// 		visitedDate: moment('2018-07-15').valueOf(),
// 		하지부종: 50,
// 		허리통증: 30,
// 		어깨결림: 30,
// 		치료: '청심연자음가미방/추나/침구',
// 	},
// 	{
// 		visitedDate: moment('2018-07-19').valueOf(),
// 		하지부종: 60,
// 		허리통증: 40,
// 		어깨결림: 20,
// 		치료: '청폐사간탕/추나/침구',
// 	},
// 	{
// 		visitedDate: moment('2018-07-24').valueOf(),
// 		하지부종: 70,
// 		편두통: 30,
// 		허리통증: 30,
// 		어깨결림: 20,
// 		치료: '청폐사간탕/추나/침구',
// 	},
// 	{
// 		visitedDate: moment('2018-07-30').valueOf(),
// 		하지부종: 40,
// 		편두통: 20,
// 		허리통증: 20,
// 		어깨결림: 20,
// 		치료: '청폐사간탕/추나/침구',
// 	},
// 	{
// 		visitedDate: moment('2018-08-05').valueOf(),
// 		하지부종: 50,
// 		편두통: 0,
// 		허리통증: 10,
// 		어깨결림: 10,
// 		치료: '조위승기탕/침구',
// 	},
// 	{
// 		visitedDate: moment('2018-08-15').valueOf(),
// 		하지부종: 30,
// 		허리통증: 0,
// 		어깨결림: 0,
// 		치료: '조위승기탕',
// 	},
// 	{
// 		visitedDate: moment('2018-08-25').valueOf(),
// 		하지부종: 20,
// 		허리통증: 10,
// 		어깨결림: 20,
// 		치료: '조위승기탕/추나',
// 	},
// 	{
// 		visitedDate: moment('2018-09-01').valueOf(),
// 		하지부종: 20,
// 		허리통증: 0,
// 		어깨결림: 10,
// 		치료: '조위승기탕/추나/침구',
// 	},
// ]

// const pieData1 = [
// 	{ name: '근골격계질환', value: 320 },
// 	{ name: '일반감기', value: 180 },
// 	{ name: '아토피', value: 130 },
// 	{ name: '역류성 식도염', value: 130 },
// 	{ name: '자가면역질환', value: 110 },
// 	{ name: '갱년기증후군', value: 80 },
// ]

// const pieData2 = [{ name: '남성', value: 420 }, { name: '여성', value: 580 }]

// const pieData3 = [
// 	{ name: '10대', value: 20 },
// 	{ name: '20대', value: 70 },
// 	{ name: '30대', value: 30 },
// 	{ name: '40대', value: 60 },
// 	{ name: '50대', value: 50 },
// 	{ name: '60대', value: 80 },
// 	{ name: '70대+', value: 120 },
// ]

// const barData2 = [
// 	{ name: '근골격계질환', 치료율: 30 },
// 	{ name: '일반감기', 치료율: 40 },
// 	{ name: '아토피', 치료율: 60 },
// 	{ name: '역류성 식도염', 치료율: 30 },
// 	{ name: '자가면역질환', 치료율: 40 },
// 	{ name: '갱년기증후군', 치료율: 70 },
// ]

// const barData = [
// 	{ name: '10대', 남성: 40, 여성: 50 },
// 	{ name: '20대', 남성: 30, 여성: 40 },
// 	{ name: '30대', 남성: 40, 여성: 50 },
// 	{ name: '40대', 남성: 20, 여성: 70 },
// 	{ name: '50대', 남성: 30, 여성: 80 },
// 	{ name: '60대', 남성: 50, 여성: 60 },
// 	{ name: '70대+', 남성: 60, 여성: 30 },
// ]

// const pieData5 = [
// 	{ name: '침구', value: 320 },
// 	{ name: '보험한약', value: 180 },
// 	{ name: '탕약', value: 130 },
// 	{ name: '추나', value: 130 },
// 	{ name: '봉침', value: 110 },
// 	{ name: '약침', value: 80 },
// ]

// const pieData6 = [
// 	{ name: '합곡', value: 320 },
// 	{ name: '족삼리', value: 180 },
// 	{ name: '상양', value: 130 },
// 	{ name: '후계', value: 130 },
// 	{ name: '용천', value: 110 },
// ]

// const pieData7 = [
// 	{ name: '당귀수산', value: 320 },
// 	{ name: '보중익기탕', value: 180 },
// 	{ name: '삼소음', value: 130 },
// 	{ name: '인삼패독산', value: 160 },
// 	{ name: '당귀보혈탕', value: 120 },
// ]

// const pieData8 = [
// 	{ name: '근골격계', value: 320 },
// 	{ name: '호흡계', value: 180 },
// 	{ name: '소화계', value: 130 },
// 	{ name: '면역계', value: 160 },
// 	{ name: '순환계', value: 50 },
// 	{ name: '생식계', value: 120 },
// ]

// const barData4 = [
// 	{ name: '~1개월', value: 40 },
// 	{ name: '1~2개월', value: 30 },
// 	{ name: '2~3개월 이내', value: 20 },
// 	{ name: '3~6개월 이내', value: 10 },
// 	{ name: '6개월~', value: 10 },
// ]
// const RADIAN = Math.PI / 180
// const renderCustomizedLabel = ({
// 	cx,
// 	cy,
// 	midAngle,
// 	innerRadius,
// 	outerRadius,
// 	percent,
// 	payload,
// }) => {
// 	const radius = innerRadius + (outerRadius - innerRadius) * 0.5
// 	const x = cx + radius * Math.cos(-midAngle * RADIAN) * 2
// 	const y = cy + radius * Math.sin(-midAngle * RADIAN) * 2

// 	return (
// 		<text
// 			x={x}
// 			y={y}
// 			textAnchor={x > cx ? 'start' : 'end'}
// 			style={{ color: '#8884d8' }}
// 			dominantBaseline="central"
// 		>
// 			{`${payload.name} ${(percent * 100).toFixed(0)}%`}
// 		</text>
// 	)
// }

// const styles = {
// 	chartTitle: {
// 		color: '#555',
// 		fontWeight: 600,
// 		marginTop: 60,
// 		marginBottom: 0,
// 		textAlign: 'center',
// 	},
// }
// class Statistics extends React.Component {
// 	constructor(props) {
// 		super(props)

// 		this.state = {
// 			activeItem: props.match.params.page ? props.match.params.page : 'patients',
// 		}
// 	}

// 	handleItemClick = (e, { name }) => {
// 		this.setState({
// 			activeItem: name,
// 		})

// 		this.props.history.push('/statistics/' + name)
// 	}

// 	render() {
// 		const { activeItem } = this.state
// 		const { loadingStats, statResults, userInfo, loadingUserInfo, errorStats } = this.props

// 		if (loadingUserInfo || !userInfo || loadingStats || !statResults) {
// 			return <InlineLoader height={400} />
// 		}

// 		if (errorStats) {
// 			return <ErrorView />
// 		}

// 		const genderRatioData = statResults.find(
// 			r => r.type === DoctorStatisticsRequestType.PATIENT_GENDER_RATIO.type
// 		)
// 		const generationrRatioData = statResults.find(
// 			r => r.type === DoctorStatisticsRequestType.PATIENT_GENERATION_RATIO.type
// 		)
// 		const symptomTypeRatioData = statResults.find(
// 			r => r.type === DoctorStatisticsRequestType.PATIENT_SYMPTOM_TYPE_RATIO.type
// 		)

// 		return (
// 			<div style={{ margin: '0 auto', width: 1500, height: 4000, padding: 30 }}>
// 				<div>
// 					<Menu secondary>
// 						<Menu.Item
// 							name="patients"
// 							active={activeItem === 'patients'}
// 							onClick={this.handleItemClick}
// 						>
// 							환자
// 						</Menu.Item>
// 						<Menu.Item
// 							name="disease"
// 							active={activeItem === 'disease'}
// 							onClick={this.handleItemClick}
// 						>
// 							증상/질환
// 						</Menu.Item>
// 						<Menu.Item
// 							name="treatment"
// 							active={activeItem === 'treatment'}
// 							onClick={this.handleItemClick}
// 						>
// 							치료
// 						</Menu.Item>
// 						<Menu.Item
// 							name="prescription"
// 							active={activeItem === 'prescription'}
// 							onClick={this.handleItemClick}
// 						>
// 							처방
// 						</Menu.Item>
// 						<Menu.Item
// 							name="acupuncture"
// 							active={activeItem === 'acupuncture'}
// 							onClick={this.handleItemClick}
// 						>
// 							침구
// 						</Menu.Item>
// 					</Menu>
// 					{activeItem === 'patients' && (
// 						<div>
// 							<Search style={{ width: 500 }} placeholder="환자 검색" />
// 							<Header style={{ textAlign: 'center' }} as="h1">
// 								{userInfo.name} 님의 환자 통계
// 							</Header>
// 							<Grid columns={15}>
// 								<Grid.Row>
// 									<Grid.Column width={5}>
// 										<h3 style={styles.chartTitle}>성별 분포</h3>
// 										<RatioPieChart
// 											data={genderRatioData && genderRatioData.ratios}
// 											width={420}
// 											height={400}
// 											innerRadius={40}
// 											outerRadius={90}
// 										/>
// 									</Grid.Column>
// 									<Grid.Column width={5}>
// 										<h3 style={styles.chartTitle}>질환 빈도</h3>
// 										<RatioPieChart
// 											data={symptomTypeRatioData && symptomTypeRatioData.ratios}
// 											width={450}
// 											height={400}
// 											innerRadius={40}
// 											outerRadius={90}
// 											color={'#8884d8'}
// 										/>
// 									</Grid.Column>
// 									<Grid.Column width={5}>
// 										<h3 style={styles.chartTitle}>연령대 분포</h3>
// 										<RatioPieChart
// 											data={generationrRatioData && generationrRatioData.ratios}
// 											width={420}
// 											height={400}
// 											innerRadius={40}
// 											outerRadius={90}
// 											color={'#6fc498'}
// 										/>
// 										{/* <PieChart width={400} height={400}>
//                         <Pie data={pieData3} dataKey={'value'}  cx={200} cy={170} innerRadius={40} outerRadius={90} fill="#6fc498" label={renderCustomizedLabel}/>
//                         <Tooltip />
//                       </PieChart> */}
// 									</Grid.Column>
// 								</Grid.Row>
// 							</Grid>
// 							{/* <Header style={{textAlign: 'center'}} as='h1'>환자군별 치료율 분석</Header>
//                 <Grid>
//                   <Grid.Row>
//                     <Grid.Column width={7}>
//                       <h3 style={styles.chartTitle}>연령별/성별 치료율 분석</h3>
//                       <BarChart width={600} height={400} data={barData}
//                         margin={{top: 5, right: 30, left: 20, bottom: 5}}>
//                         <CartesianGrid strokeDasharray="3 3"/>
//                         <XAxis dataKey="name"/>
//                         <YAxis/>
//                         <Tooltip/>
//                         <Legend />
//                         <Bar dataKey="남성" fill="#256bdb" />
//                         <Bar dataKey="여성" fill="#e2789b" />
//                       </BarChart>
//                     </Grid.Column>
//                     <Grid.Column width={8}>
//                       <h3 style={styles.chartTitle}>질환별 치료율 분석</h3>
//                       <BarChart width={700} height={400} data={barData2}
//                         margin={{top: 5, right: 30, left: 20, bottom: 5}}>
//                         <CartesianGrid strokeDasharray="3 3"/>
//                         <XAxis dataKey="name"/>
//                         <YAxis/>
//                         <Tooltip/>
//                         <Legend />
//                         <Bar dataKey="치료율" fill="#6fc498" />
//                       </BarChart>
//                     </Grid.Column>
//                   </Grid.Row>
//                 </Grid> */}
// 						</div>
// 					)}
// 					{/* {
//             activeItem === 'disease' &&
//               <div>
//                 <Search style={{width: 500}} placeholder="증상/질환"/>
//                 <Header style={{textAlign: 'center'}} as='h1'>증상/질환별 통계</Header>
//                 <Grid>
//                   <Grid.Row>
//                     <Grid.Column width={5}>
//                       <h3 style={styles.chartTitle}>질환 빈도</h3>
//                       <PieChart width={400} height={400}>
//                         <Pie data={pieData1} dataKey={'value'}  cx={200} cy={170} innerRadius={40} outerRadius={90} fill="#8884d8" label={renderCustomizedLabel}>
//                           <Cell fill="#382934"/>
//                         </Pie>
//                         <Tooltip />
//                       </PieChart>
//                     </Grid.Column>
//                     <Grid.Column width={5}>
//                       <h3 style={styles.chartTitle}>계통별 분포</h3>
//                       <PieChart width={400} height={400}>
//                         <Pie data={pieData8} dataKey={'value'}  cx={200} cy={170} innerRadius={40} outerRadius={90} fill="#6fc498" label={renderCustomizedLabel}/>
//                         <Tooltip />
//                       </PieChart>
//                     </Grid.Column>
//                     <Grid.Column width={5}>
//                       <h3 style={styles.chartTitle}>치료기간</h3>
//                       <PieChart width={400} height={400}>
//                         <Pie data={barData4} dataKey={'value'}  cx={200} cy={170} innerRadius={40} outerRadius={90} fill="#8884d8" label={renderCustomizedLabel}>
//                         </Pie>
//                         <Tooltip />
//                       </PieChart>
//                     </Grid.Column>
//                   </Grid.Row>
//                 </Grid>
//                 <Header style={{textAlign: 'center'}} as='h1'>질환별 경과 분석</Header>
//                 <Grid>
//                   <Grid.Row>
//                     <Grid.Column width={7}>
//                       <h3 style={styles.chartTitle}>연령별/성별 치료율 분석</h3>
//                       <BarChart width={600} height={400} data={barData}
//                         margin={{top: 5, right: 30, left: 20, bottom: 5}}>
//                         <CartesianGrid strokeDasharray="3 3"/>
//                         <XAxis dataKey="name"/>
//                         <YAxis/>
//                         <Tooltip/>
//                         <Legend />
//                         <Bar dataKey="남성" fill="#256bdb" />
//                         <Bar dataKey="여성" fill="#e2789b" />
//                       </BarChart>
//                     </Grid.Column>
//                     <Grid.Column width={8}>
//                       <h3 style={styles.chartTitle}>질환별 치료율 분석</h3>
//                       <BarChart width={700} height={400} data={barData2}
//                         margin={{top: 5, right: 30, left: 20, bottom: 5}}>
//                         <CartesianGrid strokeDasharray="3 3"/>
//                         <XAxis dataKey="name"/>
//                         <YAxis/>
//                         <Tooltip/>
//                         <Legend />
//                         <Bar dataKey="치료율" fill="#6fc498" />
//                       </BarChart>
//                     </Grid.Column>
//                   </Grid.Row>
//                 </Grid>
//               </div>
//           }
//           {
//             activeItem === 'treatment' &&
//               <div>
//                 <Search style={{width: 500}} placeholder="치료 검색"/>
//                 <Header style={{textAlign: 'center'}} as='h1'>이제마 님의 치료 패턴</Header>
//                 <Grid>
//                   <Grid.Row>
//                     <Grid.Column width={5}>
//                       <h3 style={styles.chartTitle}>치료방식 빈도</h3>
//                       <PieChart width={400} height={400}>
//                         <Pie data={pieData5} dataKey={'value'}  cx={200} cy={170} innerRadius={40} outerRadius={90} fill="#8884d8" label={renderCustomizedLabel}>
//                           <Cell fill="#382934"/>
//                         </Pie>
//                         <Tooltip />
//                       </PieChart>
//                     </Grid.Column>
//                     <Grid.Column width={5}>
//                       <h3 style={styles.chartTitle}>다빈도 처방</h3>
//                       <PieChart width={400} height={400}>
//                         <Pie data={pieData7} dataKey={'value'}  cx={200} cy={170} innerRadius={40} outerRadius={90} fill="#8884d8" label={renderCustomizedLabel}/>
//                         <Tooltip />
//                       </PieChart>
//                     </Grid.Column>
//                     <Grid.Column width={5}>
//                       <h3 style={styles.chartTitle}>다빈도 경혈</h3>
//                       <PieChart width={400} height={400}>
//                         <Pie data={pieData6} dataKey={'value'}  cx={200} cy={170} innerRadius={40} outerRadius={90} fill="#6fc498" label={renderCustomizedLabel}/>
//                         <Tooltip />
//                       </PieChart>
//                     </Grid.Column>
//                   </Grid.Row>
//                 </Grid>
//                 <Header style={{textAlign: 'center'}} as='h1'>치료 효율성 분석</Header>
//                 <Grid>
//                   <Grid.Row>
//                     <Grid.Column width={7}>
//                       <h3 style={styles.chartTitle}>연령별/성별 치료율 분석</h3>
//                       <BarChart width={600} height={400} data={barData}
//                         margin={{top: 5, right: 30, left: 20, bottom: 5}}>
//                         <CartesianGrid strokeDasharray="3 3"/>
//                         <XAxis dataKey="name"/>
//                         <YAxis/>
//                         <Tooltip/>
//                         <Legend />
//                         <Bar dataKey="남성" fill="#256bdb" />
//                         <Bar dataKey="여성" fill="#e2789b" />
//                       </BarChart>
//                     </Grid.Column>
//                     <Grid.Column width={8}>
//                       <h3 style={styles.chartTitle}>질환별 치료율 분석</h3>
//                       <BarChart width={700} height={400} data={barData2}
//                         margin={{top: 5, right: 30, left: 20, bottom: 5}}>
//                         <CartesianGrid strokeDasharray="3 3"/>
//                         <XAxis dataKey="name"/>
//                         <YAxis/>
//                         <Tooltip/>
//                         <Legend />
//                         <Bar dataKey="치료율" fill="#429155" />
//                       </BarChart>
//                     </Grid.Column>
//                   </Grid.Row>
//                 </Grid>
//               </div>
//           } */}
// 					{activeItem !== 'patients' && <PrepareView />}
// 				</div>
// 			</div>
// 		)
// 	}
// }

// export default compose(
// 	graphql(GET_USER_INFO, {
// 		props: ({
// 			data: {
// 				userInfo: { user },
// 			},
// 			loading,
// 			error,
// 		}) => ({
// 			userInfo: user,
// 			loadingUserInfo: loading,
// 			errorUserInfo: error,
// 		}),
// 	}),
// 	graphql(GET_MY_STATISTICS, {
// 		props: ({ data: { myStatistics, refetch }, loading, error }) => ({
// 			statResults: myStatistics,
// 			onUpdateStats: refetch,
// 			loadingStats: loading,
// 			errorStats: error,
// 		}),
// 	})
// )(Statistics)
