import gql from 'graphql-tag'
import { CATEGORY_INFO } from '../fragments/faq'
/*------------------------------ 목록 불러오기 ---------------------------------*/

// FAQ 전체 가져오기
export const GET_FAQS_ALL = gql`
	query getFaqs($pagination: Pagination, $filter: ListFilter) {
		faqs(pagination: $pagination, filter: $filter) {
			items {
				id
				question
				answer
				category {
					...CategoryInfo
				}
				createdAt
			}
			totalCount
		}
	}
	${CATEGORY_INFO}
`

export const GET_CATEGORIES = gql`
	query getCategories {
		appCategories {
			...CategoryInfo
		}
	}
	${CATEGORY_INFO}
`
