import React, { useReducer, useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from 'react-apollo'
import _ from 'lodash'
import { Table, Checkbox, Search, Icon, Form } from 'semantic-ui-react'
import { Select, Input } from 'semantic-ui-react/dist/commonjs'
import EllipsisDropdown from '../../common/EllipsisDropdown'
import { ReadOnlyValue } from '../../charting/field/inputs/Styled'
import { BASIC_COLORS, COLOR_VIVID_GREEN, COLOR_VIVID_RED } from '../../../constants/styles'
import ConditionButton from './ConditionButton'
import useSearch from '../../../hooks/useSearch'
import ConditionResultRenderer from './ConditionResultRenderer'
import { UPDATE_RESEARCH_CONDITION } from '../../../graphql/mutations/research'
import { GET_CONDITIONS } from '../../../graphql/queries/record'
import { truncateString } from '../../../libs/valueHelper'

export default function ConditionRow({
	id,
	subjectId: originSubjectId,
	targetId: originTargetId,
	operator: originOperator,
	value: originValue,
	scope: originScope,
	action: originAction,
	isRequired: originIsRequired,
	medInfoTypes,
	actions,
	scopes,
	operators,
	researchId,
	onOpenDeleteModal,
}) {
	const initialState = {
		subjectId: originSubjectId,
		targetId: originTargetId,
		operator: originOperator,
		value: originValue || '',
		scope: originScope,
		action: originAction,
		isRequired: originIsRequired,
	}

	const stateReducer = (state, action) => ({
		...state,
		[action.name]: action.value,
	})

	const [state, dispatch] = useReducer(stateReducer, initialState)
	const { subjectId, targetId, operator, value, scope, action, isRequired } = state

	const subject = medInfoTypes.find(mdt => mdt.value === subjectId)
	const target = medInfoTypes.find(mdt => mdt.value === targetId)
	const selectedOperator = operators.find(op => op.value === operator)
	const selectedScope = scopes.find(sc => sc.value === scope)
	const selectedAction = actions.find(ac => ac.value === action)

	const [isEdit, setEdit] = useState(false)

	const {
		value: sourceValue,
		text: sourceText,
		loading: sourceLoading,
		results: sourceResults,
		handleChange: handleSourceChange,
		handleSelect: handleSourceSelect,
	} = useSearch(medInfoTypes, subject)
	const {
		value: targetValue,
		text: targetText,
		loading: targetLoading,
		results: targetResults,
		handleChange: handleTargetChange,
		handleSelect: handleTargetSelect,
	} = useSearch(medInfoTypes, target)

	const [updateResearchCondition] = useMutation(UPDATE_RESEARCH_CONDITION, {
		variables: {
			researchId,
			condition: {
				...state,
				id,
				subjectId: sourceValue,
				targetId: targetValue,
			},
		},
		refetchQueries: [
			{
				query: GET_CONDITIONS,
				variables: {
					researchId,
				},
			},
		],
	})

	const [error, setError] = useState({})
	const sourceRef = useRef()

	const handleUpdate = useCallback(() => {
		if (sourceValue == null) {
			setError({
				source: true,
			})
			sourceRef.current.focus()
			return
		}
		updateResearchCondition()
		setEdit(false)
	}, [updateResearchCondition, sourceValue])

	const handleDelete = useCallback(() => {
		onOpenDeleteModal(id)
	}, [onOpenDeleteModal, id])

	const handleRollback = () => {
		Object.keys(initialState).forEach(key => {
			dispatch({ name: key, value: initialState[key] })
		})
		handleSourceSelect(null, { result: subject })
		handleTargetSelect(null, { result: target })
		setEdit(false)
	}

	return (
		<>
			<Table.Cell>
				{isEdit === true ? (
					<Form.Field error={error.source}>
						<Search
							fluid
							onSearchChange={_.debounce(handleSourceChange, 500, {
								leading: true,
							})}
							onResultSelect={handleSourceSelect}
							value={sourceText}
							results={sourceResults}
							resultRenderer={ConditionResultRenderer}
							loading={sourceLoading}
							input={{ ref: sourceRef }}
							noResultsMessage="필드를 찾지 못했습니다."
						/>
					</Form.Field>
				) : (
					<ReadOnlyValue>{truncateString(sourceText, 32)}</ReadOnlyValue>
				)}
			</Table.Cell>
			<Table.Cell>
				{isEdit === true ? (
					<Search
						fluid
						onSearchChange={_.debounce(handleTargetChange, 500, {
							leading: true,
						})}
						onResultSelect={handleTargetSelect}
						value={targetText}
						results={targetResults}
						resultRenderer={ConditionResultRenderer}
						loading={targetLoading}
						noResultsMessage="필드를 찾지 못했습니다."
					/>
				) : (
					<ReadOnlyValue>{truncateString(targetText, 32)}</ReadOnlyValue>
				)}
			</Table.Cell>
			<Table.Cell>
				{isEdit === true ? (
					<Select
						fluid
						options={operators}
						value={operator}
						onChange={(e, { value }) => {
							dispatch({ name: 'operator', value })
						}}
					/>
				) : (
					<ReadOnlyValue>{selectedOperator != null && selectedOperator.text}</ReadOnlyValue>
				)}
			</Table.Cell>
			<Table.Cell>
				{isEdit === true ? (
					<Input
						fluid
						value={value}
						onChange={(e, { value }) => {
							dispatch({ name: 'value', value })
						}}
					/>
				) : (
					<ReadOnlyValue>{truncateString(value, 20)}</ReadOnlyValue>
				)}
			</Table.Cell>
			<Table.Cell>
				{isEdit === true ? (
					<Select
						fluid
						options={scopes}
						value={scope}
						onChange={(e, { value }) => {
							dispatch({ name: 'scope', value })
						}}
					/>
				) : (
					<ReadOnlyValue>{selectedScope != null && selectedScope.text}</ReadOnlyValue>
				)}
			</Table.Cell>
			<Table.Cell>
				{isEdit === true ? (
					<Select
						fluid
						options={actions}
						value={action}
						onChange={(e, { value }) => {
							dispatch({ name: 'action', value })
						}}
					/>
				) : (
					<ReadOnlyValue>{selectedAction != null && selectedAction.text}</ReadOnlyValue>
				)}
			</Table.Cell>
			<Table.Cell textAlign="center">
				<Checkbox
					checked={isRequired}
					disabled={!isEdit}
					onChange={() => dispatch({ name: 'isRequired', value: !isRequired })}
				/>
			</Table.Cell>
			<Table.Cell textAlign="center">
				{isEdit === true ? (
					<>
						<ConditionButton color={COLOR_VIVID_GREEN} onClick={handleUpdate}>
							<Icon name="check circle" />
						</ConditionButton>

						<ConditionButton color={COLOR_VIVID_RED} onClick={handleRollback}>
							<Icon name="times circle" />
						</ConditionButton>
					</>
				) : (
					<EllipsisDropdown
						options={[
							{
								key: 'EDIT',
								content: '수정',
								onClick: () => {
									setEdit(true)
								},
							},
							{
								key: 'DELETE',
								content: '삭제',
								style: {
									color: BASIC_COLORS.red,
								},
								onClick: handleDelete,
							},
						]}
					/>
				)}
			</Table.Cell>
		</>
	)
}

ConditionRow.propTypes = {
	id: PropTypes.number,
	subjectId: PropTypes.number,
	targetId: PropTypes.number,
	operator: PropTypes.string,
	value: PropTypes.string,
	scope: PropTypes.string,
	action: PropTypes.string,
	isRequired: PropTypes.bool,
	medInfoTypes: PropTypes.array,
	scopes: PropTypes.array,
	operators: PropTypes.array,
	actions: PropTypes.array,
	researchId: PropTypes.number,
	onOpenDeleteModal: PropTypes.func,
}
