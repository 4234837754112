import { BASE_URL, APP_SERVER_BASE } from '../config'

export const URL_COMPANY = 'http://www.tesser.co.kr'
export const URL_APP = BASE_URL

export const URL_CERT_CHECK = `${APP_SERVER_BASE}/imp/certifications`

export const URL_DEFAULT_PROFILE_IMAGE =
	'https://kr.object.ncloudstorage.com/lepius-media-storage/uploads/images/user/default.png'

export const PHONE_NUMBER = 'tel:02-575-1593'
