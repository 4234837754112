import gql from 'graphql-tag'

export const GET_USER_PRESCRIPTIONS = gql`
	query getUserPrescriptions($pagination: Pagination, $filter: ListFilter) {
		userPrescriptionTreatments(pagination: $pagination, filter: $filter) {
			totalCount
			page
			perPage
			items {
				id
				name
				nameChinese
				sourceBookName
				description
				createdAt
				herbs {
					herbId
					herbName
					amount
					unit
					process
				}
			}
		}
	}
`

export const GET_USER_ACUPUNCTURE_TREATMENTS = gql`
	query getUserAcupunctureTreatments($pagination: Pagination, $filter: ListFilter) {
		userAcupunctureTreatments(pagination: $pagination, filter: $filter) {
			totalCount
			page
			perPage
			items {
				id
				name
				nameChinese
				sourceBookName
				description
			}
		}
	}
`
