import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import AddButtonIcon from '../../../assets/images/icons/plus-20.svg'

const ChartAddButtonView = ({ text, onClick }) => {
	return (
		<AddButtonButton onClick={onClick}>
			<AddButtonImg src={AddButtonIcon} />
			<AddButtonText>{text}</AddButtonText>
		</AddButtonButton>
	)
}

ChartAddButtonView.propTypes = {
	text: PropTypes.string,
	onClick: PropTypes.func,
}

ChartAddButtonView.defaultProps = {
	text: '새로운 필드',
	onClick: undefined,
}

export default ChartAddButtonView

const AddButtonButton = styled.div`
	cursor: pointer;
	text-align: center;
	height: 100%;
	padding: 8px 0px;
	width: 100%;
`

const AddButtonImg = styled.img`
	vertical-align: middle;
`
const AddButtonText = styled.div`
	font-size: 12px;
	display: inline-block;
	vertical-align: middle;
	margin-left: 10px;
`
