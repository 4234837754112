import styled from 'styled-components'
import { UISize } from '../../../enum'

const IMAGE_WIDTHS = Object.freeze({
	[UISize.MINI]: 16,
	[UISize.TINY]: 20,
	[UISize.SMALL]: 24,
	[UISize.LARGE]: 36,
	[UISize.HUGE]: 50,
	[UISize.MASSIVE]: 80,
})

export const ImageIcon = styled.img`
	max-width: ${props => IMAGE_WIDTHS[props.size || UISize.SMALL]}px;
	max-height: ${props => IMAGE_WIDTHS[props.size || UISize.SMALL]}px;
	width: ${props => IMAGE_WIDTHS[props.size || UISize.SMALL]}px;
	height: ${props => IMAGE_WIDTHS[props.size || UISize.SMALL]}px;
	vertical-align: middle;
	${props =>
		props.onClick != null
			? `
	cursor: pointer;

	: hover {
		opacity: 0.4;
	}
	`
			: ''};
`
