import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'semantic-ui-react'
import EllipsisDropdown from '../../common/EllipsisDropdown'
import { COLOR_ERROR } from '../../../constants/styles'
import { InputSize } from '../../../enum'
import MyIcon from '../../common/basic/MyIcon'

export default function DataFieldEditDropdown({
	medInfoId,
	onClickMenu,
	style,
	noCopy,
	noDelete,
	noSettings,
	canPrint,
	onResize,
	onPrint,
}) {
	// const options = []

	// if (noSettings !== true) {
	// 	options.push({
	// 		key: `DF_ED_${medInfoId}_ST`,
	// 		content: '설정',
	// 		value: 'edit',
	// 		icon: 'cog',
	// 		onClick: onClickMenu,
	// 	})

	// 	options.push({
	// 		key: `DF_ED_${medInfoId}_SZ`,
	// 		content: (
	// 			<Dropdown
	// 				text="크기 조절"
	// 				options={[
	// 					{
	// 						key: `DF_ED_${medInfoId}_SZ_L`,
	// 						content: '100%',
	// 					},
	// 				]}
	// 				direction="right"
	// 				pointing="right"
	// 			/>
	// 		),
	// 		value: 'size',
	// 		icon: 'resize horizontal',
	// 	})
	// }

	// if (noCopy !== true) {
	// 	options.push({
	// 		key: `DF_ED_${medInfoId}_CP`,
	// 		content: '복사',
	// 		value: 'copy',
	// 		icon: 'copy',
	// 		onClick: onClickMenu,
	// 	})
	// }

	// if (noDelete !== true) {
	// 	options.push({
	// 		key: `DF_ED_${medInfoId}_DE`,
	// 		content: '삭제',
	// 		value: 'delete',
	// 		icon: 'delete',
	// 		style: {
	// 			color: COLOR_ERROR,
	// 		},
	// 		onClick: onClickMenu,
	// 	})
	// }

	return (
		<EllipsisDropdown
			id={`data-field-edit-${medInfoId}`}
			className="data-field-edit"
			style={style}
			content={
				<>
					{noSettings !== true && (
						<Dropdown.Item text="설정" icon="cog" value="edit" onClick={onClickMenu} />
					)}

					{onResize != null && (
						<>
							<Dropdown.Divider />
							<Dropdown.Header icon="resize horizontal" content="크기 조정" />
							<Dropdown.Item
								text={InputSize.SMALL.text}
								value={InputSize.SMALL.value}
								onClick={onResize}
							/>
							<Dropdown.Item
								text={InputSize.MEDIUM.text}
								value={InputSize.MEDIUM.value}
								onClick={onResize}
							/>
							<Dropdown.Item
								text={InputSize.LARGE.text}
								value={InputSize.LARGE.value}
								onClick={onResize}
							/>
						</>
					)}

					{noCopy !== true && (
						<>
							<Dropdown.Divider />
							<Dropdown.Item icon="copy" value="copy" text="복사" onClick={onClickMenu} />
						</>
					)}
					{canPrint === true && (
						<>
							<Dropdown.Divider />
							<Dropdown.Item value="print" onClick={onClickMenu}>
								<MyIcon name="print" size="tiny" />
								프린트
							</Dropdown.Item>
						</>
					)}
					{noDelete !== true && (
						<>
							<Dropdown.Divider />
							<Dropdown.Item
								text="삭제"
								icon="delete"
								value="delete"
								onClick={onClickMenu}
								style={{
									color: COLOR_ERROR,
								}}
							/>
						</>
					)}
				</>
			}
		/>
	)
}

DataFieldEditDropdown.propTypes = {
	onClickMenu: PropTypes.func.isRequired,
	style: PropTypes.object,
	noCopy: PropTypes.bool,
}
