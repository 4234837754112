import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import PrepareView from '../common/error/PrepareView'
import PageContainer from '../common/container/PageContainer'

export default class StatsAnalyze extends PureComponent {
	static propTypes = {}

	render() {
		const { breadCrumbs } = this.props
		return (
			<PageContainer
				fluid
				title="분석"
				breadCrumbs={[
					...breadCrumbs,
					{
						key: 'ST_BC_AN',
						active: true,
						content: '분석',
						link: false,
					},
				]}
			>
				<PrepareView />
			</PageContainer>
		)
	}
}
