import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Sidebar, Menu } from 'semantic-ui-react'
import {
	SUB_SIDE_BAR_WIDTH,
	COLOR_LIGHT_BACKGROUND_PRIMARY,
	COLOR_BORDER_PRIMARY,
	SUB_SIDE_BAR_PADDING_RIGHT,
} from '../../constants/styles'
import LeftIcon from '../../assets/images/icons/left.svg'
import RightIcon from '../../assets/images/icons/right.svg'

/**
 * (UNUSED) 서브 사이드바를 사용하는 페이지 컨테이너
 * // TODO: 삭제 필요
 */
const SubSideMenuContainer = ({ menuContent, children }) => {
	const [isVisible, setVisible] = useState(true)

	return (
		<Sidebar.Pushable style={styles.container}>
			<Sidebar
				as={Menu}
				animation="push"
				icon="labeled"
				className="show-bit-sidebar"
				vertical
				visible={isVisible}
				width="thin"
				style={styles.sideBar}
			>
				<SidebarContent>
					<SidebarContentWrap className="side-bar-content" isVisible={isVisible}>
						{menuContent}
					</SidebarContentWrap>
				</SidebarContent>
				<ToggleButtonContainer>
					<ToggleButton
						onClick={() => {
							setVisible(!isVisible)
						}}
						isOpen={isVisible}
					/>
				</ToggleButtonContainer>
			</Sidebar>
			<Sidebar.Pusher>
				<ContentContainer isSidebarVisible={isVisible}>{children}</ContentContainer>
			</Sidebar.Pusher>
		</Sidebar.Pushable>
	)
}

SubSideMenuContainer.propTypes = {
	menuContent: PropTypes.element,
	children: PropTypes.arrayOf(PropTypes.element),
}

SubSideMenuContainer.defaultProps = {
	menuContent: null,
	children: null,
}

export default SubSideMenuContainer

/**
 * 메뉴 열고 닫는 토글 버튼
 */
const ToggleButton = ({ onClick, isOutside, isOpen }) => {
	return (
		<ToggleButtonBox isOutside={isOutside} onClick={onClick}>
			<img alt="toggle menu" src={isOpen === true ? LeftIcon : RightIcon} />
		</ToggleButtonBox>
	)
}

ToggleButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	isOutside: PropTypes.bool.isRequired,
}

/**
 * Styled Components
 */

const SidebarContent = styled.div`
	margin-right: 16px;
	height: 100%;
	width: ${SUB_SIDE_BAR_WIDTH - SUB_SIDE_BAR_PADDING_RIGHT};
	background: ${COLOR_LIGHT_BACKGROUND_PRIMARY};
	padding-top: 64px;
	box-shadow: 1px 0 0 0 #e8f1f1;
	display: flex;
	flex-direction: row;
	overflow: hidden;
`

const SidebarContentWrap = styled.div`
	display: ${props => (props.isVisible === true ? 'block' : 'none')};
	width: 100%;
	overflow-y: auto;
`

const ToggleButtonContainer = styled.div`
	position: absolute;
	right: 0px;
	top: 16px;
`

const ToggleButtonBox = styled.div`
	width: 32px;
	height: 32px;
	background: white;
	border: 1px solid ${COLOR_BORDER_PRIMARY};
	border-radius: 30px;
	padding: 7px 5px;
	cursor: pointer;

	:hover {
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
	}
`

const ContentContainer = styled.div`
	margin-top: 80px;
	margin-bottom: 120px;
`

const styles = {
	container: {
		minHeight: '100vh',
	},
	sideBar: {
		width: SUB_SIDE_BAR_WIDTH,
		position: 'relative',
	},
}
