import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal, Button, Icon, Select } from 'semantic-ui-react'
import LibraryManager from './LibraryManager'
import T1 from '../components/common/basic/T1'
import SearchTextInput from '../components/charting/field/inputs/SearchTextInput'
import { BOSA_OPTIONS, HERB_UNITS } from '../constants'

import { PrescriptionTypes } from '../constants/types'
import ToggledInput from '../components/common/ToggledInput'
import StyledTextArea from '../components/common/basic/StyledTextArea'

const PrescriptionManager = props => {
	const { type = PrescriptionTypes.herbal.key } = props

	const itemType = PrescriptionTypes[type]

	if (!itemType) return <div />
	const TABLE_COLUMNS = [
		{
			key: 'name',
			text: itemType.itemTitle,
			uiOptions: {
				textAlign: 'center',
			},
		},
		{
			key: 'description',
			text: '설명',
			uiOptions: {
				textAlign: 'left',
			},
		},
		{
			key: 'composition',
			text: itemType.compositionTitle,
			uiOptions: {
				textAlign: 'left',
			},
		},
		{
			key: 'createdAt',
			text: '등록일',
			uiOptions: {
				textAlign: 'center',
			},
		},
	]

	const { addButtonTitle } = itemType

	return (
		<LibraryManager
			columns={TABLE_COLUMNS}
			addButtonTitle={addButtonTitle}
			renderDetail={itemProps => {
				return <ItemDetail {...itemProps} itemType={itemType} />
			}}
			{...itemType}
		/>
	)
}

PrescriptionManager.propTypes = {}

export default PrescriptionManager

/**
 * 항목 상세보기 모달에서 세부내용
 */
const ItemDetail = props => {
	const { itemType, onUpdate, description, isEditing, sourceBookName } = props

	//..Query Component

	// const composition = [
	//   {
	//     id: 999,
	//     name: '당귀',
	//     amount: '99',
	//     unit: 'kg',
	//     process: '구증구포',
	//   },
	//   {
	//     id: 929,
	//     name: '당귀',
	//     amount: '99',
	//     unit: 'kg',
	//     process: '구증구포',
	//   },
	//   {
	//     id: 299,
	//     name: '당귀',
	//     amount: '99',
	//     unit: 'kg',
	//     process: '구증구포',
	//   },
	//   {
	//     id: 9933,
	//     name: '당귀',
	//     amount: '99',
	//     unit: 'kg',
	//     process: '구증구포',
	//   },
	// ]

	const [isAddCompositionModalOpen, setAddCompositionModalOpen] = useState(false)

	const {
		key,
		compositionTitle,
		compositionName,
		compositionsKey,
		compositionNameKey,
		compositionIdKey,
	} = itemType
	const { [compositionsKey]: compositions } = props

	const onUpdateComposition = index => (field, value) => {
		onUpdate(
			compositionsKey,
			compositions.map((item, idx) => {
				if (idx === index) {
					return {
						...item,
						[field]: value,
					}
				}

				return item
			})
		)
	}
	return (
		<div>
			<SubTitle>
				{(sourceBookName != null || isEditing === true) && <SubTitleLabel>출처</SubTitleLabel>}
				<ToggledInput
					isEditing={isEditing}
					style={{ marginTop: 20 }}
					onChange={onUpdate}
					name="sourceBookName"
					value={sourceBookName}
					placeholder="출처"
				/>
			</SubTitle>
			<Description>
				<ToggledInput
					input={StyledTextArea}
					isEditing={isEditing}
					fluid
					onChange={onUpdate}
					name="description"
					value={description}
					placeholder="설명을 적어주세요."
				/>
			</Description>
			<Section>
				<T1>{compositionTitle}</T1>
				<SectionContent>
					{compositions != null &&
						compositions.map((item, idx) => {
							return (
								<PrescriptionComposition
									isEditing={isEditing}
									key={item.id}
									index={idx}
									onUpdate={onUpdateComposition(idx)}
									itemTypeKey={key}
									{...item}
								/>
							)
						})}
					{isEditing === true && (
						<AddButton
							compositionName={compositionName}
							onClick={() => {
								setAddCompositionModalOpen(true)
							}}
						/>
					)}
				</SectionContent>
			</Section>
			{/* <Section>
        <T1>연관 증상/질환</T1>
        <SectionContent>
          {diseases != null
            ? diseases.map(disease => {
                return <PrescriptionDisease key={disease.id} {...disease} />
              })
            : '연관 증상이 없습니다.'}
        </SectionContent>
      </Section> */}

			<AddComposition
				isOpen={isAddCompositionModalOpen}
				itemType={itemType}
				onClose={() => setAddCompositionModalOpen(false)}
				onSubmit={(id, name) => {
					onUpdate(compositionsKey, [
						...(compositions instanceof Array ? compositions : []),
						{
							[compositionNameKey]: name,
							[compositionIdKey]: id,
						},
					])
					setAddCompositionModalOpen(false)
				}}
			/>
		</div>
	)
}

const PrescriptionComposition = ({
	isEditing,
	itemTypeKey,
	herbName,
	amount,
	unit,
	process,
	method,
	onUpdate,
}) => {
	return (
		<CompItemContainer>
			{/* <HoverMore
        menuItems={[
          {
            key: 'delete',
            content: '이 항목을 삭제',
            style: {
              color: 'red',
            },
          },
        ]}
        style={{
          borderRadius: 5,
        }}
        ellipsisStyle={{
          marginTop: -3,
        }}
      > */}
			<CompNameContainer>
				{itemTypeKey === PrescriptionTypes.herbal.key && <CompItemTitle>{herbName}</CompItemTitle>}
				{itemTypeKey === PrescriptionTypes.herbal.key && (
					<ToggledInput
						isEditing={isEditing}
						value={process}
						style={styles.subInfo}
						onChange={onUpdate}
						name="process"
						placeholder="포제"
					/>
				)}
			</CompNameContainer>
			{itemTypeKey === PrescriptionTypes.herbal.key && (
				<React.Fragment>
					<ToggledInput
						isEditing={isEditing}
						name="amount"
						style={styles.amountInfo}
						placeholder="수량"
						onChange={onUpdate}
						inputType="number"
						value={amount}
					/>
					<ToggledInput
						isEditing={isEditing}
						style={styles.unitInfo}
						input={Select}
						compact
						name="unit"
						placeholder="단위"
						options={HERB_UNITS}
						onChange={onUpdate}
						value={unit}
					/>
				</React.Fragment>
			)}
			{itemTypeKey === PrescriptionTypes.acupuncture.key && (
				<React.Fragment>
					<Select compact placeholder="보사 선택" options={BOSA_OPTIONS} value={method} />
				</React.Fragment>
			)}
			{/* </HoverMore> */}
		</CompItemContainer>
	)
}

PrescriptionComposition.propTypes = {}

const AddButton = ({ onClick, compositionName = '항목' }) => {
	return (
		<Button fluid primary basic onClick={onClick}>
			<Icon name="plus" />
			{compositionName} 추가
		</Button>
	)
}

AddButton.propTypes = {
	compositionName: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
}

const AddComposition = ({ itemType, isOpen, onClose, onSubmit }) => {
	const [selectedItem, setSelectedItem] = useState(null)
	const { compositionAddPlaceholder, entityType } = itemType

	return (
		<Modal size="mini" open={isOpen} onClose={onClose} closeIcon>
			<Modal.Content>
				<Flexbox>
					<SearchTextInput
						entityType={entityType}
						onUpdateValues={item => {
							setSelectedItem(item)
						}}
						placeholder={compositionAddPlaceholder}
					/>
				</Flexbox>
			</Modal.Content>
			<Modal.Actions>
				<Button
					primary
					onClick={() => {
						const name = selectedItem[0].value
						const id = selectedItem[1].value
						onSubmit(id, name)
					}}
				>
					확인
				</Button>
			</Modal.Actions>
		</Modal>
	)
}

AddComposition.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
}

// const PrescriptionDisease = ({ name, description }) => {
//   return (
//     <CompItemContainer>
//       <CompItemTitle>{name}</CompItemTitle>
//       <CompItemDesc>{description}</CompItemDesc>
//     </CompItemContainer>
//   )
// }

const Description = styled.div`
	margin-top: 20px;
	margin-bottom: 30px;
`
const SubTitleLabel = styled.div`
	font-weight: 600;
	margin-right: 10px;
	display: inline-block;
`
const SubTitle = styled.div`
	font-size: 13px;
`

const Section = styled.div`
	margin-bottom: 30px;
`
const CompItemContainer = styled.div`
	margin-right: 15px;
	:hover {
		box-shadow: 1px 2px 3px 1px rgba(0, 0, 0, 0.3);
	}
	padding: 5px;
	border-bottom: 1px solid #eee;
	margin-bottom: 10px;
`

const CompNameContainer = styled.div`
	display: inline-block;
	width: 250px;
`
const SectionContent = styled.div`
	margin-top: 10px;
`

const CompItemTitle = styled.span`
	font-size: 14px;
	font-weight: 600;
	margin-right: 20px;
	margin-top: 50px;
`

const Flexbox = styled.div`
	display: flex;
`

const styles = {
	subInfo: {
		fontSize: 12,
		fontWeight: 400,
		width: 120,
		color: '#999',
		verticalAlign: 'middle',
	},
	amountInfo: {
		width: 80,
		verticalAlign: 'middle',
		marginRight: 15,
	},
	unitInfo: {
		width: 80,
		minWidth: 70,
		verticalAlign: 'middle',
	},
}
