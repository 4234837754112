import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-apollo'
import { Link } from 'react-router-dom'
import { SEARCH_TAGS } from '../../graphql/queries/library'
import SimpleLabel from '../common/basic/SimpleLabel'
import { TAG_TYPES } from '../../enum'
import { ROUTE_CASE_TAG } from '../../constants/routes'
import { replaceParams } from '../../libs/routeHelper'
import { COLOR_TEXT_DARK_GRAY } from '../../constants/styles'
import { ListItem } from './ListItem'

const TagList = ({ search }) => {
	const { data: { searchCaseTags } = {} } = useQuery(SEARCH_TAGS, {
		variables: {
			query: search,
		},
	})
	return (
		<div>
			{searchCaseTags &&
				searchCaseTags.map(caseTag => (
					<ListItem
						key={`search_tag_${caseTag.id}`}
						style={{ borderBottom: '1px solid #eee', padding: '10px 20px', display: 'block' }}
						to={replaceParams(ROUTE_CASE_TAG, {
							tagId: caseTag.id,
						})}
					>
						<div style={{ fontWeight: 'bold', fontSize: 16 }}>
							{caseTag.textKorean}
							{caseTag.textChinese != null ? `(${caseTag.textChinese})` : ''}
							<SimpleLabel style={{ marginLeft: 10 }}>{TAG_TYPES[caseTag.tagType]}</SimpleLabel>
						</div>
						<div style={{ fontSize: 13 }}>{caseTag.description}</div>
						<div
							style={{
								marginRight: 10,
								display: 'inline-block',
								fontSize: 14,
								color: COLOR_TEXT_DARK_GRAY,
							}}
						>
							<b style={{ color: 'black' }}>{caseTag.caseCount}</b> 케이스
						</div>
					</ListItem>
				))}
		</div>
	)
}

TagList.propTypes = {}

export default TagList
