import gql from 'graphql-tag'
import { COMMENT_NAME_FRAGMENT, COMMENT_NICKNAME_FRAGMENT } from '../fragments/community'

/**
 * 닉네임을 사용해 댓글을 생성하는 리퀘스트
 */
export const WRITE_COMMENT_NICK = gql`
	mutation writeComment($commentInput: CommentInput!) {
		writeComment(commentInput: $commentInput) {
			...CommentNicknameParts
		}
	}
	${COMMENT_NICKNAME_FRAGMENT}
`

/**
 * 실명을 사용해 댓글을 생성하는 리퀘스트
 */
export const WRITE_COMMENT_NAME = gql`
	mutation writeComment($commentInput: CommentInput!) {
		writeComment(commentInput: $commentInput) {
			...CommentNameParts
		}
	}
	${COMMENT_NAME_FRAGMENT}
`

/**
 * 댓글 삭제 리퀘스트
 */
export const DELETE_COMMENT = gql`
	mutation deleteComment($commentId: Int!) {
		deleteComment(commentId: $commentId)
	}
`

/**
 * 댓글 수정하기(닉네임을 포함한 댓글 객체 반환)
 */
export const EDIT_COMMENT_NICK = gql`
	mutation editComment($text: String, $commentId: Int!) {
		editComment(text: $text, commentId: $commentId) {
			...CommentNicknameParts
		}
	}
	${COMMENT_NICKNAME_FRAGMENT}
`

/**
 * 댓글 수정하기(실명을 포함한 댓글 객체 반환)
 */
export const EDIT_COMMENT_NAME = gql`
	mutation editComment($text: String, $commentId: Int!) {
		editComment(text: $text, commentId: $commentId) {
			...CommentNameParts
		}
	}
	${COMMENT_NAME_FRAGMENT}
`
