import gql from 'graphql-tag'

/**
 * 양식 수정 내역 모달의 마지막 페이지들을 불러옴
 */
export const GET_TEMPLATE_CHANGE_MODAL_PAGE = gql`
	query {
		changeModalPage @client {
			template {
				templateId
				page
			}
		}
	}
`

/**
 * 기록 수정 내역 모달의 마지막 페이지들을 불러옴
 */
export const GET_RECORD_CHANGE_MODAL_PAGE = gql`
	query {
		changeModalPage @client {
			record {
				recordId
				page
			}
		}
	}
`
