import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form, Input, Checkbox } from 'semantic-ui-react'
import SegmentToggle from '../common/basic/SegmentToggle'
import { TemplateJob } from '../../enum'
/**
 * 양식 정보 설정
 */

const DAY_INPUT_PLACEHOLDER = 'Days'

const TemplateOptions = ({
	isSchedule,
	title,
	templateName,
	onChange,
	description,
	days,
	daysRange,
	isEdit,
	job,
	onClickJob,
	shouldReserveExist,
	shouldReserve,
	hasScreening,
}) => {
	const arrTemplateJob = useMemo(() => {
		if (hasScreening === false) {
			return Object.values(TemplateJob).filter(
				job => job.key !== TemplateJob.ECRF.key && job.key !== TemplateJob.SCREENING.key
			)
		} else {
			return Object.values(TemplateJob).filter(job => job.key !== TemplateJob.ECRF.key)
		}
	}, [hasScreening])
	return (
		<Form>
			<Form.Field
				label="제목"
				placeholder={
					templateName != null ? `${templateName} 제목을 입력해주세요.` : '제목을 입력해주세요.'
				}
				control={Input}
				value={title}
				onChange={onChange('title')}
			/>
			<Form.Field
				label="설명"
				placeholder="간단한 설명을 적어주세요."
				control={Input}
				value={description}
				onChange={onChange('description')}
			/>

			<Form.Field>
				<label>분류</label>
				<SegmentToggle
					fluid
					items={arrTemplateJob}
					active={job}
					disabled={isEdit}
					onChange={onClickJob}
				/>
			</Form.Field>

			{isSchedule === true && job !== TemplateJob.SCREENING.key && (
				<>
					<DaySetup>
						<Form.Field>
							<label>진행 시기</label>
							<DayInputContainer>
								<span>첫 조사부터 &nbsp;&nbsp;</span>
								<DayInput
									type="number"
									placeholder={DAY_INPUT_PLACEHOLDER}
									value={days}
									onChange={onChange('days')}
								/>
								일 후, <PlusMinus>±</PlusMinus>
								<DayInput
									type="number"
									placeholder={DAY_INPUT_PLACEHOLDER}
									value={daysRange}
									onChange={onChange('daysRange')}
								/>
								<span>일</span>
							</DayInputContainer>
						</Form.Field>
					</DaySetup>
					<ShouldReserveCheckBox
						label="다음 조사 일정 예약"
						checked={shouldReserveExist}
						onChange={onChange('shouldReserveExist')}
					/>
					{shouldReserveExist === true && (
						<ShouldReserveInput
							placeholder="예약 일정 안내 문구. 예) 3개월 후"
							value={shouldReserve}
							onChange={onChange('shouldReserve')}
						/>
					)}
				</>
			)}
		</Form>
	)
}

TemplateOptions.propTypes = {
	isSchedule: PropTypes.bool,
	title: PropTypes.string,
	onChange: PropTypes.func,
	description: PropTypes.string,
	days: PropTypes.number,
	daysRange: PropTypes.number,
	templateName: PropTypes.string,
	isEdit: PropTypes.bool,
	job: PropTypes.string,
	onClickJob: PropTypes.func,
	shouldReserveExist: PropTypes.bool,
	shouldReserve: PropTypes.string,
	hasScreening: PropTypes.bool,
}

TemplateOptions.defaultProps = {
	isEdit: false,
}

export default TemplateOptions

const DayInputContainer = styled.div`
	display: flex;
	align-items: center;
`

const DaySetup = styled.div`
	margin-top: 20px;
	margin-bottom: 20px;
`

const DayInput = styled(Input)`
	width: 100px !important;
	display: inline-block !important;
	margin-left: 5px;
	margin-right: 5px;
`

const PlusMinus = styled.span`
	display: inline-block;
	margin: 0px 10px;
	font-size: 18px;
`
const ShouldReserveCheckBox = styled(Checkbox)`
	margin-top: 12px;
	margin-bottom: 12px;
`

const ShouldReserveInput = styled(Input)`
	margin-left: 28px;
	width: 260px !important;
`
