import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

/**
 * 서브 사이드 메뉴 개별 아이템 래핑
 */
const SubSideMenuItem = ({ hasPadding, children, onClick }) => {
	return (
		<Container onClick={onClick} hasPadding={hasPadding}>
			{children}
		</Container>
	)
}

SubSideMenuItem.propTypes = {
	hasPadding: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	onClick: PropTypes.func,
}

SubSideMenuItem.defaultProps = {
	hasPadding: false,
	onClick: () => {},
}

export default SubSideMenuItem

const Container = styled.div`
	cursor: pointer;
	text-align: left;
	box-shadow: inset 0 -1px 0 0 #e8f1f1;
	line-height: 20px;
	padding: ${props => (props.hasPadding === true ? '10px 16px' : '0px')};
`
