import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Query, graphql } from 'react-apollo'
import compose from 'lodash.flowright'

import ResearchSettingsBasicForm from '../components/research/settings/ResearchSettingsBasicForm'

import { GET_RESEARCH_INFO } from '../graphql/queries/research'
import { UPDATE_RESEARCH } from '../graphql/mutations/research'
import ErrorView from '../components/common/error/ErrorView'
import LoadingView from '../components/common/loader/index'
import ResearchSettingsDetailForm from '../components/research/settings/ResearchSettingsDetailForm'

class ResearchSettingsBasic extends PureComponent {
	static propTypes = {
		researchId: PropTypes.number.isRequired,
		match: PropTypes.object,
	}
	render() {
		const {
			researchId,
			match: {
				params: { menu },
			},
		} = this.props

		return (
			<Query query={GET_RESEARCH_INFO} variables={{ id: researchId }}>
				{({ data = {}, loading, error }) => {
					if (loading) {
						return <LoadingView />
					}

					if (error) {
						return <ErrorView />
					}
					if (menu === '' || menu == null) {
						return (
							<ResearchSettingsBasicForm
								{...this.props}
								loading={loading}
								{...data.researchDetail}
							/>
						)
					} else if (menu === 'detail') {
						return (
							<ResearchSettingsDetailForm
								{...this.props}
								loading={loading}
								{...data.researchDetail}
							/>
						)
					} else {
						return <div />
					}
				}}
			</Query>
		)
	}
}

export default compose(
	graphql(UPDATE_RESEARCH, {
		name: 'onUpdateResearch',
	})
)(ResearchSettingsBasic)
