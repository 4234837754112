import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TextArea, Modal, Form, Button, Checkbox } from 'semantic-ui-react/dist/commonjs'
import { SectionSize, InputType } from '../../../enum'

const SECTION_UISize = Object.keys(SectionSize).map(key => {
	const { text, value } = SectionSize[key]
	return { text, value }
})

const GROUP_TYPES = Object.keys(InputType)
	.filter(key => InputType[key].isGroup === true)
	.map(key => InputType[key])

export default class SectionModal extends Component {
	static propTypes = {
		open: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
		onSubmit: PropTypes.func.isRequired,
		medInfoType: PropTypes.object,
		sectionIndex: PropTypes.number,
		isPartial: PropTypes.bool,
		onScroll: PropTypes.func,
	}
	constructor(props) {
		super(props)
		const { medInfoType } = props

		this.state = {
			title: medInfoType ? medInfoType.name : '',
			typeName: medInfoType ? medInfoType.typeName : '',
			size: medInfoType ? medInfoType.size : 'SMALL',
			inputType: InputType.BLOCK.value,
			description: medInfoType && medInfoType.description,
			format: medInfoType ? medInfoType.format : '',
		}
	}

	handleChange = key => event => {
		this.setState({
			[key]: event.target.value,
		})
	}

	handleSelectChange = name => (event, data) => {
		this.setState({
			[name]: data.value,
		})
	}

	handleSubmit = async () => {
		const { onSubmit, onClose, sectionIndex, onScroll } = this.props
		const { title, typeName, size, description, format } = this.state

		if (!title) {
			alert('제목을 입력해 주세요')
		} else {
			await onSubmit({
				title,
				typeName,
				size,
				sectionIndex,
				description,
				format,
			})

			this.setState({
				title: '',
				typeName: '',
				description: '',
				format: '',
			})
			onClose()
			onScroll()
		}
	}
	render() {
		const { open, onClose, medInfoType, isPartial } = this.props
		const { title, size, typeName, inputType, description, format } = this.state

		return (
			<Modal size="tiny" closeIcon open={open} onClose={onClose} closeOnDimmerClick={false}>
				<Modal.Header>
					{isPartial ? '그룹' : '섹션'} {medInfoType == null ? '추가' : '수정'}하기
				</Modal.Header>
				<Modal.Content>
					{/* {!isPartial && (
						<div>
							<Header as="h4" textAlign="center">
								미리보기
							</Header>
							<Segment compact style={styles.centerAlign}>
								<div style={{ ...styles.exampleTop }}>
									<div style={{ ...styles.title }}>{title || '제목 없음'}</div>
								</div>
							</Segment>
						</div>
					)} */}
					<Form>
						<Form.Field>
							<label>제목</label>
							<input
								value={title}
								onChange={this.handleChange('title')}
								placeholder="제목 입력하기"
							/>
						</Form.Field>
						<Form.Field>
							<label>변수명</label>
							<input
								value={typeName}
								onChange={this.handleChange('typeName')}
								placeholder="변수명(영문, 숫자, 특수기호(-), (_))"
							/>
						</Form.Field>
						<Form.Group>
							{/* {!isPartial && <Form.Field>
                <label>색상</label>
                <CirclePicker
                  color={color}
                  onChangeComplete={this.handleChangeColor}
                />
              </Form.Field>} */}
							<Form.Field>
								<label>크기</label>
								<Form.Select
									options={SECTION_UISize}
									onChange={this.handleSelectChange('size')}
									placeholder="크기 선택"
									value={size}
								/>
							</Form.Field>
							{isPartial && (
								<Form.Field style={{ width: 200 }}>
									<label>종류</label>
									<Form.Select
										options={GROUP_TYPES}
										onChange={this.handleSelectChange('inputType')}
										placeholder="크기 선택"
										value={inputType}
									/>
								</Form.Field>
							)}
						</Form.Group>
						<Form.Field
							control={TextArea}
							label="설명"
							value={description}
							onChange={this.handleChange('description')}
						/>
						{isPartial && (
							<Form.Field
								control={TextArea}
								label="설명"
								placeholder="설명이 필요할 경우 적어주세요."
							/>
						)}
						<Checkbox
							radio
							label="비활성화 항목 숨기기"
							checked={format && format.includes('hideDisabled')}
							onClick={() => {
								const isDisabled = typeof format === 'string' && format.includes('hideDisabled')
								const newFormat = isDisabled
									? format
											.split('|')
											.filter(f => f !== 'hideDisabled')
											.join('|')
									: format && format.split('|').length > 1
									? format + '|hideDisabled'
									: 'hideDisabled'

								this.setState({ format: newFormat })
							}}
						/>
					</Form>
				</Modal.Content>
				<Modal.Actions>
					<Button primary onClick={this.handleSubmit}>
						{medInfoType ? '수정하기' : '추가하기'}
					</Button>
				</Modal.Actions>
			</Modal>
		)
	}
}
