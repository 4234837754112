export const matchErrorCode = (e, type) => {
	const { graphQLErrors } = e
	let hasError
	let exceptions = []
	if (graphQLErrors && graphQLErrors.length > 0) {
		for (const err of graphQLErrors) {
			const errOptions = err.extensions
			const eCode = errOptions.code
			if (eCode === type.code) {
				hasError = true
				if (errOptions.exception) {
					exceptions.push(errOptions.exception)
				}
			}
		}
	}

	if (exceptions.length > 1) {
		return exceptions
	} else if (exceptions.length > 0) {
		return exceptions[0]
	}

	return hasError
}

export const isGraphQLError = e => {
	const { graphQLErrors } = e
	if (graphQLErrors && graphQLErrors.length > 0) {
		return true
	}
}
