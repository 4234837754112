import React from 'react'
import PropTypes from 'prop-types'
import { Modal, PlaceholderLine, Placeholder } from 'semantic-ui-react'
import { useQuery } from 'react-apollo'
import { FlexDiv, VerticalFlex, AlignCenteredFlexDiv, FlexFill } from '../common/basic/StyledBox'
import { GET_RECORD_DETAIL } from '../../graphql/queries/record'
import { convertGender, getAge } from '../../libs/valueHelper'
import Chart from '../../containers/Chart'
import DescriptionText from '../common/basic/DescriptionText'
import LinkColored from '../common/basic/LinkColored'
import { ROUTE_PROJECT_DETAIL_DEFAULT } from '../../constants/routes'
import { replaceParams } from '../../libs/routeHelper'
import useUserInfo from '../../hooks/useUserInfo'
import MyIcon from '../common/basic/MyIcon'
import { MiniBar } from '../common/basic/MiniBar'
import { toCommonDateString } from '../../libs/dateHelper'
import ErrorView from '../common/error/ErrorView'

/**
 * 레코드 하나를 열어보는 모달
 *
 */
const SingleRecordModal = ({ researchId, researchName, recordId, onClose, isOpen }) => {
	const { data, loading, error } = useQuery(GET_RECORD_DETAIL, {
		variables: {
			id: recordId,
		},
	})

	const {
		recordDetail: {
			record: {
				createdAt,
				patient: {
					sexMale,
					birthDate,
					name,
					patientCode,
					registerer: { name: registererName, profileImage } = {},
				} = {},
			} = {},
		} = {},
	} = data || {}

	const { user } = useUserInfo()

	return (
		<Modal size="fullscreen" closeOnDimmerClick={false} open={isOpen} onClose={onClose}>
			<Modal.Header>
				<FlexDiv>
					<FlexFill>
						{error != null && <ErrorView error={error} />}
						{loading === true ? (
							<Placeholder>
								<PlaceholderLine length="medium" />
								<PlaceholderLine length="short" />
							</Placeholder>
						) : (
							<VerticalFlex>
								<AlignCenteredFlexDiv>
									{name}&nbsp;&nbsp;({patientCode})&nbsp;&nbsp;&nbsp;&nbsp;
									<DescriptionText>
										{convertGender(sexMale)} <MiniBar /> {getAge(birthDate)}
									</DescriptionText>
								</AlignCenteredFlexDiv>
								<DescriptionText>
									<LinkColored to={replaceParams(ROUTE_PROJECT_DETAIL_DEFAULT, { id: researchId })}>
										{researchName}
									</LinkColored>
									<MiniBar />
									{toCommonDateString(createdAt)} 등록
								</DescriptionText>
							</VerticalFlex>
						)}
					</FlexFill>
					<MyIcon
						name="close"
						onClick={() => {
							onClose()
						}}
					/>
				</FlexDiv>
			</Modal.Header>
			<Modal.Content scrolling style={{ padding: 0 }}>
				<Chart isForModal recordId={recordId} currentUser={user} />
			</Modal.Content>
		</Modal>
	)
}

SingleRecordModal.propTypes = {
	researchId: PropTypes.number,
	researchName: PropTypes.string,
	recordId: PropTypes.number.isRequired,
	onClose: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
}

export default SingleRecordModal
