import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Dropdown, Icon } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import compose from 'lodash.flowright'

import { Query, withApollo, graphql } from 'react-apollo'
import PatientList from '../components/patient/my/PatientList'

import { DEFAULT_PATIENTS_PER_PAGE, RESEARCH_TAB_ID_ALL } from '../constants'
import { GET_USER_ACTIVE_RESEARCHES } from '../graphql/queries/research'
import { truncateString } from '../libs/valueHelper'
import PatientAddModal from '../components/patient/add/PatientAddModal'
import PageContainer from '../components/common/container/PageContainer'
import AddButton from '../components/common/basic/AddButton'
import { AlignCenteredFlexDiv } from '../components/common/basic/StyledBox'
import LinkColored from '../components/common/basic/LinkColored'
import { replaceParams } from '../libs/routeHelper'
import { ROUTE_PROJECT_DETAIL_DEFAULT } from '../constants/routes'
import { LinkColoredText } from '../components/common/basic/StyledTexts'
import SingleRecordModal from '../components/record/SingleRecordModal'
import { SET_RESEARCH_TAB_ID } from '../graphql/mutations/app'
import { GET_RESEARCH_TAB_ID } from '../graphql/queries/app'

/**
 * 내 환자 목록 페이지
 *
 *  환자 목록 검색하고 새로운 환자 등록
 */

class MyPatients extends PureComponent {
	static propTypes = {
		location: PropTypes.shape({
			pathname: PropTypes.string.isRequired,
		}).isRequired,
		history: PropTypes.shape({
			push: PropTypes.func,
		}).isRequired,
		researchTabId: PropTypes.number,
		setResearchTabId: PropTypes.func,
	}

	constructor(props) {
		super(props)

		this.state = {
			patientModalOpen: false,
			patientCount: null,
			singleRecordId: null,
			isSingleRecordModalOpen: false,
		}
	}

	handleCloseRegisterModal = () => this.setState({ patientModalOpen: false })

	handleOpenRegisterModal = () => this.setState({ patientModalOpen: true })

	handleOpenSingleRecordModal = singleRecordId => {
		this.setState({ isSingleRecordModalOpen: true, singleRecordId })
	}

	handleCloseSingleRecordModal = () => this.setState({ isSingleRecordModalOpen: false })

	handleSelectResearch = (e, { value }) => {
		const { setResearchTabId } = this.props
		setResearchTabId({
			variables: {
				id: value,
			},
		})
	}

	handleSetPatientCount = c => this.setState({ patientCount: c })

	tabRendering = activeResearches => {
		const { researchTabId } = this.state
		return activeResearches.map((research, idx) => {
			const isActive = researchTabId === research.id || (idx === 0 && researchTabId === null)

			return (
				<Dropdown.Item
					key={research.id}
					value={research.id}
					name={research.title}
					selected={isActive}
				>
					<span>{truncateString(research.title, 14)}</span>
				</Dropdown.Item>
			)
		})
	}

	render() {
		const {
			page,
			patientModalOpen,
			patientCount,
			isSingleRecordModalOpen,
			singleRecordId,
		} = this.state

		const { history, researchTabId, setResearchTabId } = this.props

		return (
			<Query
				query={GET_USER_ACTIVE_RESEARCHES}
				onCompleted={({ userResearches: { activeResearches } }) => {
					if (activeResearches.length > 0 && researchTabId === null) {
						setResearchTabId({ variables: { id: activeResearches[0].id } })
					}
				}}
			>
				{({ data: { userResearches: { activeResearches = [] } = {} } = {}, error }) => {
					const selectedResearch =
						activeResearches != null
							? activeResearches.find(item => item.id === researchTabId)
							: null
					let dropdownTrigger

					if (selectedResearch == null || researchTabId === RESEARCH_TAB_ID_ALL) {
						dropdownTrigger = <span>전체 보기</span>
					} else {
						dropdownTrigger = (
							<>
								<b>
									<LinkColoredText inline>{selectedResearch.title}</LinkColoredText>
								</b>
								<LinkColored
									to={replaceParams(ROUTE_PROJECT_DETAIL_DEFAULT, {
										id: researchTabId,
									})}
								>
									<Icon style={{ marginLeft: 10 }} name="external" />
								</LinkColored>
							</>
						)
					}

					return (
						<PageContainer title="내 데이터" fixed titleNumber={patientCount}>
							<ProjectSelectBox>
								<ProjectSelectLabel>프로젝트</ProjectSelectLabel>
								<Dropdown
									selection
									fluid
									value={researchTabId}
									onChange={this.handleSelectResearch}
									trigger={dropdownTrigger}
									options={activeResearches
										.map(research => {
											return {
												key: `mdrs-${research.id}`,
												value: research.id,
												text: truncateString(research.title, 30),
											}
										})
										.concat({
											key: `mdrs-${RESEARCH_TAB_ID_ALL}`,
											value: RESEARCH_TAB_ID_ALL,
											text: '전체 보기',
										})}
								/>
								<AddPatientButton onClick={this.handleOpenRegisterModal} />
							</ProjectSelectBox>
							{patientModalOpen && (
								<PatientAddModal
									open={patientModalOpen}
									onClose={this.handleCloseRegisterModal}
									history={history}
									researchTabId={researchTabId}
									onOpenSingleRecordModal={this.handleOpenSingleRecordModal}
								/>
							)}
							<ContentContainer>
								<div style={{ textAlign: 'center', position: 'relative' }}>
									<PatientList
										error={error}
										onClickAddPatient={this.handleOpenRegisterModal}
										onSetPatientCount={this.handleSetPatientCount}
										onOpenSingleRecordModal={this.handleOpenSingleRecordModal}
										researchTabId={researchTabId}
										page={page}
										perPage={DEFAULT_PATIENTS_PER_PAGE}
										onPageChange={this.handlePageChange}
										research={selectedResearch}
									/>
								</div>
							</ContentContainer>
							{isSingleRecordModalOpen === true && (
								<SingleRecordModal
									researchId={selectedResearch.id}
									researchName={selectedResearch.title}
									recordId={singleRecordId}
									isOpen={isSingleRecordModalOpen}
									onClose={this.handleCloseSingleRecordModal}
								/>
							)}
						</PageContainer>
					)
				}}
			</Query>
		)
	}
}

export default compose(
	withApollo,
	withRouter,
	graphql(GET_RESEARCH_TAB_ID, {
		props: ({ data: { researchTabId } }) => {
			return {
				researchTabId,
			}
		},
	}),
	graphql(SET_RESEARCH_TAB_ID, {
		name: 'setResearchTabId',
	})
)(MyPatients)

const AddPatientButton = ({ onClick }) => {
	return (
		<ButtonBox>
			<AddButton title="새환자 등록" onClick={onClick} />
		</ButtonBox>
	)
}

AddPatientButton.propTypes = {
	onClick: PropTypes.func.isRequired,
}

const ProjectSelectBox = styled(AlignCenteredFlexDiv)`
	margin-bottom: 20px;
`

const ProjectSelectLabel = styled.div`
	font-weight: 600;
	color: ${props => props.theme.COLOR_TEXT_DARKGRAY};
	width: 80px;
`

const ButtonBox = styled.div`
	width: 150px;
	margin-left: 20px;
`
const ContentContainer = styled.div`
	margin-bottom: 80px;
	position: relative;
`
