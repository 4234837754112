import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useRouteMatch, useParams, Switch, Route, useHistory } from 'react-router-dom'
import { useQuery } from 'react-apollo'
import { Icon, Label } from 'semantic-ui-react'
import {
	ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL,
	ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_CHANGES,
	ROUTE_PROJECT_DETAIL_TEMPLATES_DEFAULT,
	ROUTE_PROJECT_DETAIL_TEMPLATES_FORMS_DEFAULT,
} from '../../../constants/routes'
import { GET_RESEARCH_BASIC_INFO } from '../../../graphql/queries/research'

import HeaderPageTitle from '../../common/basic/HeaderPageTitle'
import DescriptionText from '../../common/basic/DescriptionText'

import { PAGES_PROJECT_SIMPLE_TITLES } from '../../../constants/pages'
import {
	FlexDiv,
	HeaderDetailContent,
	HeaderBaseContent,
	AlignCenteredFlexDiv,
} from '../../common/basic/StyledBox'

import { HeaderPlaceholder } from './HeaderPlaceholder'
import {
	GET_RECORD_TEMPLATE_INFO,
	GET_RESEARCH_RECORD_TEMPLATES,
} from '../../../graphql/queries/record'
import { toCommonDateString, researchPhaseTime } from '../../../libs/dateHelper'
import { ResearchType, ObjectType, TemplateJob } from '../../../enum'
import { styleEllipsisOverflow } from '../../common/basic/StyledTexts'
import { MiniBar } from '../../common/basic/MiniBar'
import EllipsisDropdown from '../../common/EllipsisDropdown'
import TemplateAddModal from '../../template/modals/TemplateAddModal'
import { replaceParams } from '../../../libs/routeHelper'
import { BASIC_COLORS } from '../../../constants/styles'
import DeleteObjectModal from '../../common/modal/DeleteObjectModal'
import useDeleteObject from '../../../hooks/useDeleteObject'

/**
 * 프로젝트 상세 페이지 헤더
 */
const ResearchHeader = () => {
	const {
		params: { id, menu },
	} = useRouteMatch()
	const researchId = parseInt(id)
	const { data, loading, error } = useQuery(GET_RESEARCH_BASIC_INFO, {
		variables: {
			id: researchId,
		},
		fetchPolicy: 'cache-first',
		skip: researchId == null,
	})

	const { title, organizedBy, type, hasScreening } = useMemo(() => {
		if (data != null && data.researchDetail != null) {
			return {
				...data.researchDetail,
			}
		}
		return {}
	}, [data])

	const hasSubContent = menu != null

	if (loading === true || error != null) return <HeaderPlaceholder />
	return (
		<>
			<ResearchInfo hasSubContent={hasSubContent}>
				<ResearchTitle>{title}</ResearchTitle>
				<DescriptionText>{organizedBy.name}</DescriptionText>
			</ResearchInfo>
			<HeaderDetailContent>
				<Switch>
					<Route
						path={ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL}
						render={p => (
							<TemplateHeader
								{...p}
								isSchedule={type === ResearchType.SCHEDULED.value}
								researchId={researchId}
								hasScreening={hasScreening}
							/>
						)}
					/>
					{PAGES_PROJECT_SIMPLE_TITLES.map(i => {
						return (
							<ResearchSimpleHeaderRoute
								path={i.path}
								key={i.key}
								title={i.title}
								subTitle={i.subTitle}
								researchId={researchId}
							/>
						)
					})}
				</Switch>
			</HeaderDetailContent>
		</>
	)
	// return <div />
}

ResearchHeader.propTypes = {}

export default ResearchHeader

/**
 * 간단한 페이지 제목만 보여줌
 */
const ResearchSimpleHeaderRoute = ({ path, title, subTitle }) => {
	return (
		<Route
			path={path}
			render={() => (
				<div>
					<HeaderPageTitle>
						{title}
						{subTitle != null && `- ${subTitle}`}
					</HeaderPageTitle>
				</div>
			)}
		/>
	)
}

ResearchSimpleHeaderRoute.propTypes = {
	path: PropTypes.string,
	title: PropTypes.string,
	subTitle: PropTypes.string,
}

/**
 * 양식 상세페이지에서 사용되는 헤더
 */
const TemplateHeader = ({ isSchedule, researchId, hasScreening }) => {
	const [isEditModalOpen, setEditModalOpen] = useState(false)
	const history = useHistory()

	const { subId } = useParams()
	const templateId = parseInt(subId, 10)
	const { data, loading, error } = useQuery(GET_RECORD_TEMPLATE_INFO, {
		variables: {
			templateId,
		},
	})
	const { handleOpen, ...deleteModalProps } = useDeleteObject()

	if (loading === true || error != null) return <HeaderPlaceholder />

	const {
		recordTemplate: { id, title, days, daysRange, description, createdAt, job, shouldReserve } = {},
	} = data || {}

	return (
		<>
			<HeaderPageTitle>
				<AlignCenteredFlexDiv>
					<Title>양식 편집 - {title}</Title>
					{isSchedule && (
						<TemplatePhaseLabel>
							<Icon name="calendar" className="outline" />
							{researchPhaseTime(days)} 시행
						</TemplatePhaseLabel>
					)}
					<EllipsisDropdown
						options={[
							{
								key: 'EDIT',
								value: 'EDIT',
								text: '설정',
								onClick: () => {
									setEditModalOpen(true)
								},
							},
							{
								key: 'CHANGE',
								value: 'CHANGE',
								text: '수정내역',
								onClick: () => {
									history.replace(
										replaceParams(ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_CHANGES, {
											id: researchId,
											menu: 'templates',
											subId: id,
										})
									)
								},
							},
							{
								key: 'DELETE',
								value: 'DELETE',
								text: '삭제',
								style: {
									color: BASIC_COLORS.red,
								},
								onClick: () =>
									handleOpen(ObjectType.TEMPLATE.value, templateId, {
										onComplete: () => {
											history.replace(
												job === TemplateJob.ECRF.key
													? replaceParams(ROUTE_PROJECT_DETAIL_TEMPLATES_FORMS_DEFAULT, {
															id: researchId,
													  })
													: replaceParams(ROUTE_PROJECT_DETAIL_TEMPLATES_DEFAULT, {
															id: researchId,
													  })
											)
										},
										refetchQueries: [
											{
												query: GET_RESEARCH_RECORD_TEMPLATES,
												variables: {
													researchId,
													job,
												},
											},
										],
									}),
							},
						]}
						direction="right"
					/>
				</AlignCenteredFlexDiv>
			</HeaderPageTitle>

			<TemplateInfo>
				<DescriptionText>{description}</DescriptionText>
				<MiniBar />
				<DateInfo>{toCommonDateString(createdAt)} 생성</DateInfo>
			</TemplateInfo>
			<TemplateAddModal
				open={isEditModalOpen}
				onClose={() => setEditModalOpen(false)}
				isEdit
				defaultTitle={title}
				defaultDescription={description}
				isSchedule={isSchedule}
				defaultJob={job}
				defaultDays={days}
				defaultDaysRange={daysRange}
				defaultShouldReserve={shouldReserve}
				templateId={id}
				hasScreening={hasScreening}
			/>
			<DeleteObjectModal {...deleteModalProps} />
		</>
	)
}

TemplateHeader.propTypes = {
	isSchedule: PropTypes.bool,
	researchId: PropTypes.number,
	hasScreening: PropTypes.bool,
}

/**
 * Styles
 */

const ResearchInfo = styled(HeaderBaseContent)`
	flex-direction: column;
	justify-content: center;
	align-items: start;

	${props => (props.hasSubContent === false ? 'flex: 1' : 'width: 200px')};
`

const DateInfo = styled(DescriptionText).attrs({ inline: true })``

const ResearchTitle = styled(HeaderPageTitle)`
	margin-bottom: 5px;
	width: 100%;
	overflow-y: hidden;
	${styleEllipsisOverflow}
`

const TemplateInfo = styled(FlexDiv)`
	margin-top: 5px;
`

const TemplatePhaseLabel = styled(Label).attrs({ color: 'teal' })`
	margin-left: 20px !important;
`

const Title = styled.span`
	display: inline-block;
	overflow: hidden;
	max-width: 300px;
	text-overflow: ellipsis;
`
