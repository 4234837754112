import React from 'react'
import styled from 'styled-components'
import { Placeholder } from 'semantic-ui-react'

export const HeaderPlaceholder = () => {
	return (
		<Container>
			<Placeholder>
				<Placeholder.Line length="long" />
				<Placeholder.Line length="short" />
				<Placeholder.Line length="short" />
			</Placeholder>
		</Container>
	)
}

const Container = styled.div`
	width: 200px;
`
