import { GET_USER_INFO } from '../../graphql/queries/user'
import { getUserInfoFromCookie } from '../../libs/cookieHelper'

// 쿠키에서 사용자 정보 불러옴
const cookieUserInfo = getUserInfoFromCookie()

const defaultUserInfo = {
	__typename: 'AuthPayload',
	user: {
		__typename: 'LoginUser',
		id: 0,
		username: '',
		email: '',
		name: '',
		nickname: '',
		userType: '',
		profileImage: '',
		institutionId: 0,
		// agreedTerms: {
		// 	privacyTerm: {
		// 		id: 0,
		// 	},
		// 	serviceTerm: {
		// 		id: 0,
		// 	},
		// },
	},
	accessToken: '',
	refreshToken: '',
	termUpdated: false, // 약관 갱신되어 새로 동의해야할 경우 true,
	noUser: true,
}

let userInfo

if (cookieUserInfo && cookieUserInfo.user) {
	userInfo = cookieUserInfo
	userInfo.termUpdated = false
	userInfo.noUser = false
	userInfo.__typename = 'AuthPayload'
	userInfo.user.__typename = 'LoginUser'
} else {
	userInfo = defaultUserInfo
}

// userInfo.user.__typename = 'AuthPayload'
// userInfo.agreedTerms.__typename = 'Terms'
// userInfo.agreedTerms.privacyTerm.__typename = 'Term'
// userInfo.agreedTerms.serviceTerm.__typename = 'Term'

export const defaults = {
	// userInfo: {
	// 	__typename: 'AuthPayload',
	// 	user: {
	// 		__typename: 'LoginUser',
	// 		id: 0,
	// 		username: '',
	// 		email: '',
	// 		name: '',
	// 		nickname: '',
	// 		userType: '',
	// 		profileImage: '',
	// 		agreedTerms: {
	// 			__typename: 'Terms',
	// 			privacyTerm: {
	// 				__typename: 'Term',
	// 				id: 0,
	// 			},
	// 			serviceTerm: {
	// 				__typename: 'Term',
	// 				id: 0,
	// 			},
	// 		},
	// 	},
	// 	accessToken: '',
	// 	refreshToken: '',
	// 	termUpdated: false, // 약관 갱신되어 새로 동의해야할 경우 true,
	// 	noUser: false,
	// },
	userInfo,
	latestTerms: {
		__typename: 'TermIds',
		serviceTermId: 0,
		privacyTermId: 0,
	},
}

export const resolvers = {
	Mutation: {
		updateUserInfo: (_, { authPayload }, { cache }) => {
			const previousState = cache.readQuery({ query: GET_USER_INFO })

			const userInfo = {
				__typename: 'AuthPayload',
				...previousState.userInfo,
				...authPayload,
				user: {
					__typename: 'LoginUser',
					...previousState.userInfo.user,
					...authPayload.user,

					...(authPayload.user.agreedTerms && {
						agreedTerms: {
							__typename: 'Terms',
							serviceTerm: authPayload.user.agreedTerms.serviceTerm && {
								__typename: 'Term',
								...authPayload.user.agreedTerms.serviceTerm,
							},
							privacyTerm: authPayload.user.agreedTerms.privacyTerm && {
								__typename: 'Term',
								...authPayload.user.agreedTerms.privacyTerm,
							},
						},
					}),
				},
				noUser: false,
			}
			const data = {
				...previousState,
				userInfo,
			}

			cache.writeData({ data })
			return userInfo
		},
		/**
		 * 사용자 로그아웃
		 */
		resetUserInfo: (_, __, { cache }) => {
			const previousState = cache.readQuery({ query: GET_USER_INFO })
			const toUserInfo = {
				...defaultUserInfo,
				noUser: true,
			}
			const data = {
				...previousState,
				userInfo: toUserInfo,
			}

			cache.writeData({ data })
			return toUserInfo
		},
		noAuthUser: (_, __, { cache }) => {
			const previousState = cache.readQuery({ query: GET_USER_INFO })

			const data = {
				...previousState,
				userInfo: {
					...previousState.userInfo,
					noUser: true,
				},
			}

			cache.writeData({ data })
			return true
		},
		agreeTerms: (_, { privacyTermId, serviceTermId }, { cache }) => {
			const previousState = cache.readQuery({ query: GET_USER_INFO })
			const data = {
				...previousState,
				userInfo: {
					...previousState,
					agreedTerms: {
						__typename: 'Terms',
						serviceTerm: {
							__typename: 'Term',
							id: privacyTermId,
						},
						privacyTerm: {
							__typename: 'Term',
							id: serviceTermId,
						},
					},
				},
				termsUpdated: false,
			}

			cache.writeData({ data })

			return {
				privacyTermId,
				serviceTermId,
			}
		},
	},
}
