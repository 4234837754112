import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Query } from 'react-apollo'
import ReactMarkdown from 'react-markdown'

import LoadingView from '../components/common/loader'
import ErrorView from '../components/common/error/ErrorView'
import { GET_TERMS } from '../graphql/queries/app'
import { TermType } from '../enum'
import PageContainer from '../components/common/container/PageContainer'

/**
 * 이용약관 보기
 */
class Terms extends PureComponent {
	static propTypes = {
		match: PropTypes.shape({
			params: PropTypes.shape({
				menu: PropTypes.string,
			}),
		}).isRequired,
	}

	render() {
		const { match } = this.props
		const subMenu = match.params.menu

		let termType

		if (subMenu === 'privacy') {
			termType = TermType.PRIVACY_POLICY.value
		} else {
			termType = TermType.SERVICE.value
		}

		return (
			<div>
				<Query query={GET_TERMS} variables={{ type: termType }}>
					{({ loading, error, data }) => {
						if (loading) {
							return <LoadingView />
						}

						if (error) {
							return <ErrorView />
						}

						if (data) {
							return (
								<PageContainer fixed width={800} align="center" hasMobile>
									<div style={{ textAlign: 'left', padding: 20, margin: '50px auto' }}>
										{data.terms && data.terms.text && (
											<ReactMarkdown className="markdown-body" source={data.terms.text} />
										)}
									</div>
								</PageContainer>
							)
						}

						return <div />
					}}
				</Query>
			</div>
		)
	}
}

export default withRouter(Terms)
