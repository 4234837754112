import React, { PureComponent } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Table, Divider } from 'semantic-ui-react'

import PrintModal from '../../common/modal/PrintModal'
import ChartContent from '../ChartContent'
import '../chart.css'
import MyIcon from '../../common/basic/MyIcon'
import { toCommonDateString } from '../../../libs/dateHelper'

/**
 * 차트 인쇄 미리보기 Modal
 */
export default class PrintChartModal extends PureComponent {
	static propTypes = {
		isOpen: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
		medInfos: PropTypes.array,
		// 인쇄할 섹션 ID. 없을 경우 전체 MedInfo 인쇄
		printingSectionId: PropTypes.number,
	}

	getProps = ['medInfos']

	render() {
		const { isOpen, onClose, medInfos, printingSectionId, ...props } = this.props

		return (
			<PrintModal isOpen={isOpen} onClose={onClose}>
				<PrintedChartInfo {...props} />
				<ChartContent
					style={{ width: 1200 }}
					forPrint
					noEdit
					isFixed
					readOnly
					medInfos={
						printingSectionId != null
							? medInfos.filter(item => item.id === printingSectionId)
							: medInfos
					}
				/>
			</PrintModal>
		)
	}
}

/**
 * 차트 정보/환자정보
 */
const PrintedChartInfo = ({
	templateTitle,
	visitedDate,
	researchTitle,
	patientName,
	patientAge,
	patientCode,
	patientGender,
	writerName,
	institutionName,
	hasPatientCode,
}) => {
	return (
		<>
			<Title>{templateTitle}</Title>
			<SubTitle>
				<ProjectInfo>
					<MyIcon name="folder" size="tiny" />
					&nbsp;&nbsp;{researchTitle}
				</ProjectInfo>
				<InstName>{institutionName}</InstName>
			</SubTitle>
			<MyTable celled>
				<Table.Body>
					<Table.Row>
						<LabelCell>
							이름&nbsp;&nbsp;&nbsp;{hasPatientCode === true && '/\u00a0\u00a0\u00a0차트번호'}
						</LabelCell>
						<DataCell>
							{patientName}
							{hasPatientCode === true && `\u00a0\u00a0\u00a0/\u00a0\u00a0\u00a0${patientCode}`}
						</DataCell>
						<LabelCell>성별&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;나이</LabelCell>
						<DataCell>
							{patientGender}&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;{patientAge}
						</DataCell>
					</Table.Row>
					<Table.Row>
						<LabelCell>날짜</LabelCell>
						<DataCell>{toCommonDateString(visitedDate)}</DataCell>
						<LabelCell>작성자</LabelCell>
						<DataCell>{writerName}</DataCell>
					</Table.Row>
				</Table.Body>
			</MyTable>
			<Divider />
		</>
	)
}

PrintedChartInfo.propTypes = {
	templateTitle: PropTypes.string,
	visitedDate: PropTypes.string,
	researchTitle: PropTypes.string,
	patientName: PropTypes.string,
	patientAge: PropTypes.string,
	patientCode: PropTypes.string,
	patientGender: PropTypes.string,
	writerName: PropTypes.string,
	institutionName: PropTypes.string,
	hasPatientCode: PropTypes.string,
}

/**
 * Styles
 */

const MyTable = styled(Table)`
	margin-top: 5px !important;
`

const SubTitle = styled.div`
	margin-top: 5px;
`

const ProjectInfo = styled.div`
	margin-bottom: 5px;
`
const LabelCell = styled(Table.Cell)`
	width: 120px;
	height: 30px;
`

const DataCell = styled(Table.Cell)`
	height: 30px;
	vertical-align: middle;
`

const Title = styled.h6`
	margin-top: 10px;
`

const InstName = styled.div`
	float: right;
	font-size: 14px;
	font-weight: 700;
`
