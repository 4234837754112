import gql from 'graphql-tag'
import { AUTH_PAYLOAD_FRAGMENT } from '../fragments/auth'

export const GET_USER_INFO = gql`
	query userInfo {
		userInfo @client {
			...AuthPayloadParts
			termUpdated
			noUser
		}
	}
	${AUTH_PAYLOAD_FRAGMENT}
`

export const GET_USER_INFO_NO_USER = gql`
	query userInfo {
		userInfo @client {
			noUser
		}
	}
`

export const SEARCH_USERS = gql`
	query searchUsers($searchString: String!) {
		searchUsers(searchString: $searchString) {
			id
			name
			username
			userType
			profileImage
		}
	}
`

// 유저 상세정보
export const GET_USER_DETAIL = gql`
	query {
		userDetail {
			id
			name
			phone
			email
			institution {
				id
				name
				address
			}
			profileImage
			userType
			username
			nickname
			position
		}
	}
`

// 동의하지 않은 약관 불러오기
export const GET_USER_LATEST_UNAGREED_TERMS = gql`
	query {
		userUnagreedLatestTerms {
			term
			type
		}
	}
`

/**
 * 회원가입 중복 확인
 */
export const CHECK_DUPLICATE_USERS = gql`
	query checkDuplicateUsers($username: String, $email: String, $nickname: String, $phone: String) {
		checkUsername: checkDuplicateUsers(fieldName: "username", value: $username)
		checkEmail: checkDuplicateUsers(fieldName: "email", value: $email)
		checkNickname: checkDuplicateUsers(fieldName: "nickname", value: $nickname)
		checkPhone: checkDuplicateUsers(fieldName: "phone", value: $phone)
	}
`

export const GET_DOCTOR_DEPARTMENTS = gql`
	query getDepartments($category: DepartmentCategory) {
		departments(countryCode: KR, category: $category) {
			id
			name
			certType
			isTraditional
			category
		}
	}
`
