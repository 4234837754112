import React from 'react'
import PropTypes from 'prop-types'

import { Input } from 'semantic-ui-react'
import { InputErrorMessage } from './StyledTexts'

/**
 * 하단에 에러메시지 표시가 가능한 텍스트 인풋
 */
const TextInputWithError = ({ style, control: Control, isInline, error, label, ...formProps }) => {
	return (
		<div
			className={`field ${error != null ? 'error' : ''}`}
			style={{ display: isInline ? 'inline-block' : 'block' }}
		>
			{label != null && <label>{label}</label>}
			<Control error={error != null} {...formProps} style={style} />
			{error && (
				<div style={{ marginTop: 5 }}>
					<InputErrorMessage>* {error && error.message}</InputErrorMessage>
				</div>
			)}
		</div>
	)
}

export default TextInputWithError

TextInputWithError.propTypes = {
	style: PropTypes.object,
	isInline: PropTypes.bool,
	error: PropTypes.shape({
		message: PropTypes.string,
	}),
	control: PropTypes.func,
	label: PropTypes.string,
}

TextInputWithError.defaultProps = {
	style: null,
	isInline: false,
	error: null,
	control: Input,
	label: null,
}
