import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import 'rmc-picker/assets/index.css'
import Picker from 'rmc-picker'
import useLocalMedInfo from '../../../hooks/useLocalMedInfo'

export default function PatientSurveyNumber({ medInfo }) {
	const { id, medInfoType, optionValue } = medInfo
	const { placeholder, options } = medInfoType

	const hasRange = useMemo(() => {
		if (options && options.length > 0 && options[0].min != null && options[0].max != null) {
			return true
		}
		return false
	}, [options])
	const range = useMemo(() => {
		const valueOption = []
		if (hasRange === true) {
			for (let i = options[0].min; i <= options[0].max; i++) {
				valueOption.push(i)
			}
		}
		return valueOption.reverse()
	}, [hasRange, options])

	const [, onUpdate] = useLocalMedInfo(id)

	const [value, setValue] = useState(
		medInfo.value || (range && range.length > 0 && range[parseInt(range.length / 2)]) || ''
	)

	const onChange = e => {
		if (e.target.validity.valid) {
			onUpdate('value', e.target.value)
			setValue(e.target.value)
		}
	}

	const onChangePicker = value => {
		onUpdate('value', value)
		setValue(value)
	}

	return (
		<Wrapper>
			{hasRange === true ? (
				<PickerWrapper valueIndex={range.findIndex(item => item === value)}>
					<Picker
						onValueChange={onChangePicker}
						selectedValue={value}
						style={{ height: 282 }}
						indicatorStyle={{ height: 56 }}
						itemStyle={{ fontSize: 48, height: 56, lineHeight: '48px' }}
					>
						{range.map(item => (
							<Picker.Item key={item} value={item}>
								{item}
							</Picker.Item>
						))}
					</Picker>
				</PickerWrapper>
			) : (
				<NumberInput
					width={3}
					type="tel"
					placeholder={placeholder}
					onChange={onChange}
					pattern="[0-9]*"
					value={value}
				/>
			)}
			<Unit>{hasRange === true ? options[0].value : optionValue}</Unit>
		</Wrapper>
	)
}

PatientSurveyNumber.propTypes = {
	medInfo: PropTypes.object,
}

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

const NumberInput = styled.input`
	width: 80%;
	max-width: 300px;
	font-size: 96px;
	background: none;
	border: none;
	text-align: right;
	margin-right: 20px;
	font-weight: bold;
`

const Unit = styled.div`
	font-size: 32px;
`

const PickerWrapper = styled.div`
	flex: 1 0 auto;
	.rmc-picker-mask {
		background-image: linear-gradient(
				to bottom,
				rgba(255, 255, 255, 0.95),
				rgba(255, 255, 255, 0.6)
			),
			linear-gradient(to top, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6));
	}
`
