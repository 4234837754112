import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Table, Image, Header } from 'semantic-ui-react'
import moment from 'moment'

import { researchPhaseTime } from '../../../libs/dateHelper'

const ResearchRecordTemplateItem = props => {
	const { researchId, creator, createdAt, title, order, days, id, isSchedule, isECRF } = props
	let daysString = researchPhaseTime(days)
	let urlparam
	if (isSchedule) {
		if (isECRF) {
			urlparam = 'schedules'
		} else {
			urlparam = 'forms'
		}
	} else {
		urlparam = 'templates'
	}

	return (
		<React.Fragment>
			{/* {isSchedule && (
				<Table.Cell style={{ color: '#777', width: 50, display: 'table-cell' }}>
					#{order}
				</Table.Cell>
			)} */}
			<Table.Cell>
				<Header
					className="hover-underline"
					as="h3"
					style={{ display: 'inline-block', marginTop: 0 }}
				>
					{title}
				</Header>
			</Table.Cell>
			{isSchedule && (
				<Table.Cell textAlign="center">
					<div style={{ color: '#777' }}>{daysString}</div>
				</Table.Cell>
			)}
			<Table.Cell textAlign="center">
				<div>
					<Image avatar src={creator.profileImage} /> <b>{creator.name}</b>
				</div>
			</Table.Cell>
			<Table.Cell textAlign="center" style={{ width: 180 }}>
				<div style={{ color: '#777' }}>{moment(createdAt).calendar()}</div>
			</Table.Cell>
		</React.Fragment>
	)
}

ResearchRecordTemplateItem.propTypes = {
	isSchedule: PropTypes.bool,
	isECRF: PropTypes.bool,
	researchId: PropTypes.number.isRequired,
	creator: PropTypes.object.isRequired,
	createdAt: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	order: PropTypes.number.isRequired,
	days: PropTypes.number.isRequired,
	id: PropTypes.number.isRequired,
}

export default ResearchRecordTemplateItem
