import React from 'react'
import PropTypes from 'prop-types'
import { Label } from 'semantic-ui-react'

const SimpleLabel = ({ children, ...props }) => {
	return (
		<Label basic color="teal" className="simple-label" {...props}>
			{children}
		</Label>
	)
}

SimpleLabel.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.string]),
}

export default SimpleLabel
