import React from 'react'
import PropTypes from 'prop-types'

import { Popup, Icon } from 'semantic-ui-react'

const InfoPopup = props => {
  const { description, style } = props
  return (
    <Popup
      content={description}
      trigger={
        <Icon
          name="info circle"
          color="grey"
          style={{
            fontSize: 20,
            ...(style && style),
          }}
        />
      }
    />
  )
}

InfoPopup.propTypes = {
  description: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.object,
}

export default InfoPopup
