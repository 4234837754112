import styled from 'styled-components'

const ConditionButton = styled.div`
	display: inline;
	transition: opacity 0.3s;
	font-size: 22px !important;
	cursor: pointer;
	color: ${props => props.color};
	:hover {
		opacity: 0.3 !important;
	}
`
export default ConditionButton
