import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router-dom'
import { PlaceholderLine } from 'semantic-ui-react'
import { useMutation, useQuery, useApolloClient } from 'react-apollo'

import SubSideMenuItem from '../common/SubSideMenuItem'
import { truncateString } from '../../libs/valueHelper'
import { RouteMenu } from '../common/RouteMenu'

import DownIcon from '../../assets/images/icons/down.svg'
import { COLOR_PRIMARY, COLOR_TEXT } from '../../constants/styles'
import Placeholder from '../common/basic/Placeholder'
import { GET_USER_ACTIVE_RESEARCHES_FOR_LIST } from '../../graphql/queries/research'
import { UISize } from '../../enum'
import EmptyResult from '../common/empty/EmptyResult'
import { ROUTE_NEW_PROJECT } from '../../constants/routes'

import { FullVerticalFlex, FlexFill, FlexDiv } from '../common/basic/StyledBox'
import AddButton from '../common/basic/AddButton'
import {
	SET_RESEARCH_SIDE_MENU,
	TOGGLE_OPEN_RESEARCH_SIDE_MENU_ITEM,
	HANDLE_PAGE_CHANGE_RESEARCH_SIDE_MENU,
} from '../../graphql/mutations/app'
import {
	GET_RESEARCH_SUB_SIDE_MENU_ITEM,
	GET_RESEARCH_SUB_SIDE_MENU,
} from '../../graphql/queries/app'
import ErrorView from '../common/error/ErrorView'

/**
 * 프로젝트 사이드 메뉴
 *
 * 연구 목록에서 메뉴를 열고 닫는 것을 처리하고
 * 현재 페이지를 보여줌.
 */
const ResearchSideMenu = () => {
	const params = useParams()
	const { id } = params
	const currentResearchId = parseInt(id, 10)

	const [setResearchSideMenu] = useMutation(SET_RESEARCH_SIDE_MENU)
	const [handlePageChange] = useMutation(HANDLE_PAGE_CHANGE_RESEARCH_SIDE_MENU, {
		variables: {
			currentId: currentResearchId,
		},
	})

	const [isLoaded, setLoaded] = useState(false)
	const [researches, setResearches] = useState(null)
	const { loading, error } = useQuery(GET_USER_ACTIVE_RESEARCHES_FOR_LIST, {
		onCompleted({ userResearches }) {
			let researches
			if (userResearches.activeResearches == null) return

			researches = userResearches.activeResearches

			setResearchSideMenu({
				variables: {
					researches,
					currentId: currentResearchId,
				},
			})
			setLoaded(true)
		},
	})

	const { data: { researchSideMenu: { items } = {} } = {} } =
		useQuery(GET_RESEARCH_SUB_SIDE_MENU) || {}

	useEffect(() => {
		handlePageChange()
	}, [id, handlePageChange])
	useEffect(() => {
		if (items != null) {
			setResearches(
				items.sort((a, b) => {
					if (a.id === currentResearchId) return -1
					if (b.id === currentResearchId) return 1
					if (a.id > b.id) return 1
					if (a.id === b.id) return 0
					if (a.id < b.id) return -1
				})
			)
		}
	}, [handlePageChange, currentResearchId, items])

	return (
		<ResearchSideMenuView
			researches={researches}
			loading={loading || !isLoaded}
			error={error}
			currentResearchId={currentResearchId}
		/>
	)
}

/**
 * 연구 정보를 받아서 항목을 그려줌
 */
const ResearchSideMenuView = ({ researches, loading, error, currentResearchId }) => {
	const history = useHistory()

	const menuItem = useMemo(() => {
		if (error != null) {
			return (
				<EmptyContainer>
					<ErrorView error={error} />
				</EmptyContainer>
			)
		} else if (loading === true) {
			return (
				<EmptyContainer>
					<Placeholder>
						<PlaceholderLine length="long" />
						<PlaceholderLine length="short" />
						<PlaceholderLine length="short" />
						<PlaceholderLine length="short" />
					</Placeholder>
				</EmptyContainer>
			)
		} else if (researches != null) {
			if (researches.length === 0) {
				return (
					<EmptyContainer>
						<EmptyResult size={UISize.TINY} message="프로젝트 없음" />
					</EmptyContainer>
				)
			} else {
				return researches.map(research => {
					return (
						<ResearchSideMenuItem
							key={`rsmi-${research.id}`}
							{...research}
							currentId={currentResearchId}
						/>
					)
				})
			}
		}
	}, [researches, loading, error, currentResearchId])

	return (
		<FullVerticalFlex>
			<FlexFill>{menuItem}</FlexFill>
			<ResearchBottomBox>
				<ResearchAddButton
					onClick={() => {
						history.push(ROUTE_NEW_PROJECT)
					}}
					title="새 프로젝트 생성"
				/>
			</ResearchBottomBox>
		</FullVerticalFlex>
	)
}

ResearchSideMenuView.propTypes = {
	loading: PropTypes.bool.isRequired,
	researches: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			title: PropTypes.string,
		})
	),
	currentResearchId: PropTypes.number,
	error: PropTypes.any,
}

ResearchSideMenuView.defaultProps = {
	researches: [],
}

export default ResearchSideMenu

/**
 * 프로젝트 서브 사이드메뉴 세부 항목
 * 상세 항목을 그려줌
 */
const ResearchSideMenuItem = React.memo(props => {
	const { currentId, title, id } = props
	const { data } = useQuery(GET_RESEARCH_SUB_SIDE_MENU_ITEM, {
		variables: {
			id,
		},
	})

	const client = useApolloClient()

	let isOpen = false
	let menuItems = []

	if (data != null && data.researchSideMenuItem != null) {
		;({ isOpen, menuItems } = data.researchSideMenuItem)
	}

	return (
		<SubSideMenuItem>
			<SubSideMenuTitle
				isOnPage={currentId == id}
				isOpen={isOpen}
				onClick={() =>
					client.mutate({
						mutation: TOGGLE_OPEN_RESEARCH_SIDE_MENU_ITEM,
						variables: {
							id,
						},
					})
				}
			>
				<SubSideMenuContent>{truncateString(title, 14)}</SubSideMenuContent>
				<SubSideMenuArrow src={DownIcon} isOpen={isOpen} />
			</SubSideMenuTitle>
			<SubSideMenuDetail isOpen={isOpen}>
				<RouteMenu
					menuItems={menuItems}
					renderMenuItem={renderMenuItem}
					replaceParam={{ id }}
					keyPrefix={`rsmi-m-${id}-`}
					menuOptions={{
						vertical: true,
					}}
				/>
			</SubSideMenuDetail>
		</SubSideMenuItem>
	)
})

ResearchSideMenuItem.propTypes = {
	currentId: PropTypes.number,
	title: PropTypes.string.isRequired,
	id: PropTypes.number,
}

ResearchSideMenuItem.defaultProps = {
	id: null,
	currentId: null,
}

/**
 * Styled Components
 */

const EmptyContainer = styled.div`
	width: 100%;
	text-align: center;
	padding: 10px;
`
const SubSideMenuTitle = styled.div`
	display: flex;
	padding: 10px 16px;
	color: ${props => (props.isOnPage === true ? COLOR_PRIMARY : COLOR_TEXT)};
	font-weight: ${props => (props.isOnPage === true ? 600 : 400)};
	transition: background-color 0.3s, color 0.3s;

	:hover {
		background-color: #e4eaea;
	}
`
const SubSideMenuContent = styled.div`
	flex-grow: 1;
	text-align: left;
`

const SubSideMenuArrow = styled.img`
	width: 16px;
	transform: ${props => (props.isOpen === true ? 'rotate(180deg)' : 'none')};
	transition: transform 100ms ease-out;
`

const SubSideMenuDetail = styled.div`
	display: ${props => (props.isOpen === true ? 'block' : 'none')};
`

const SubMenu = styled.div`
	display: flex;
	align-items: center;
`

const SubMenuIcon = styled.img`
	width: 20px;
	height: 20px;
	display: inline-block;
	margin-right: 10px;
`

const ResearchBottomBox = styled(FlexDiv)`
	padding: 20px;
`

const ResearchAddButton = styled(AddButton)`
	flex: 1;
`
/**
 * Render Functions
 */

// eslint-disable-next-line react/prop-types
const renderMenuItem = ({ imageIcon, title }) => {
	return (
		<SubMenu>
			<SubMenuIcon src={`/images/icons/${imageIcon}`} />
			{title}
		</SubMenu>
	)
}
