import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-apollo'
import { Icon, Input } from 'semantic-ui-react'
import { GET_CASE_FEEDS } from '../../graphql/queries/case'
import CaseItem from './CaseItem'
import { ROUTE_CASES_WRITE } from '../../constants/routes'
import AddButton from '../common/basic/AddButton'
import { CaseListType } from '../../enum'
import { COLOR_TEXT_GRAY, FONT_SIZE } from '../../constants/styles'
import SearchTextInput from '../charting/field/inputs/SearchTextInput'
import InputSearch from '../common/basic/InputSearch'

const CaseFeed = ({
	title,
	search,
	description,
	type,
	groupId,
	tagId,
	useAddButon = true,
	useChartNumSearch = false,
	subItem,
}) => {
	const [chartNum, setChartNum] = useState(null)
	const { data: { simpleCases: { items } = {} } = {} } = useQuery(GET_CASE_FEEDS, {
		variables: {
			type,
			groupId,
			tagId,
			search,
			chartNum,
		},
		fetchPolicy: 'cache-and-network',
	})
	const history = useHistory()

	return (
		<div style={{ textAlign: 'center' }}>
			<div style={{ paddingBottom: 80, textAlign: 'left', margin: '0 auto', maxWidth: 800 }}>
				{title && (
					<div style={{ marginBottom: 30 }}>
						<div style={{ fontSize: FONT_SIZE.h5, fontWeight: 'bold' }}>{title}</div>
						{description && <div style={{ marginTop: 15 }}>{description}</div>}
					</div>
				)}
				{subItem != null && subItem}
				<div style={{ display: 'flex', flexDirection: 'row', marginBottom: 30 }}>
					{useAddButon === true && (
						<div style={{ flex: 1 }}>
							<div style={{ marginBottom: 30 }}>
								<AddButton
									primary
									size="large"
									onClick={() =>
										history.push(
											`${ROUTE_CASES_WRITE}${groupId != null ? `?groupId=${groupId}` : ''}`
										)
									}
									title="새로운 케이스를 작성해 보세요."
								/>
							</div>
						</div>
					)}
					{useChartNumSearch === true && (
						<div>
							<InputSearch
								placeholder="차트번호 검색"
								value={chartNum}
								onChange={(_, { value }) => setChartNum(value)}
							/>
						</div>
					)}
				</div>
				{items != null &&
					items.map(c => (
						<CaseItem
							key={`case-feed-${c.id}`}
							caseId={c.id}
							title={c.title}
							tags={c.tags}
							creator={c.creator}
							age={c.age}
							sex={c.sex}
							createdAt={c.createdAt}
							updatedAt={c.updatedAt}
							sharingScope={c.sharingScope}
							groupId={c.groupId}
							groupName={c.group ? c.group.title : ''}
							commentCount={c.commentCount}
						/>
					))}
				{items != null && items.length === 0 && (
					<div style={{ fontSize: 18, color: COLOR_TEXT_GRAY }}>케이스가 없습니다.</div>
				)}
			</div>
		</div>
	)
}

CaseFeed.propTypes = {
	type: PropTypes.string,
	groupId: PropTypes.number,
	tagId: PropTypes.string,
	useAddButon: PropTypes.bool,
	title: PropTypes.string,
	description: PropTypes.string,
}

CaseFeed.defaultProps = {
	type: CaseListType.GROUP.value,
	groupId: null,
	tagId: null,
	useAddButon: true,
	title: null,
	description: null,
}

export default CaseFeed
