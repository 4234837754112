import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Search } from 'semantic-ui-react/dist/commonjs'
import { withApollo } from 'react-apollo'
import { GET_AUTOCOMPLETE_RESULTS } from '../../../../graphql/queries/library'

/**
 * 자동완성 라이브러리 검색하기
 */
class SearchTextInput extends Component {
	constructor(props) {
		super(props)

		this.state = {
			value: '',
			selected: null,
			isLoading: false,
			results: [],
		}
	}

	componentDidMount() {
		const { value } = this.props
		this.setState({
			value: value,
		})
	}

	//결과 선택
	handleResultSelect = async (e, { result }) => {
		const { onUpdateValues } = this.props
		if (result) {
			const value = result.title
			this.setState({
				value,
				selected: result.id,
			})

			onUpdateValues([
				{
					key: 'value',
					value,
				},
				{
					key: 'entityId',
					value: result.id,
				},
			])
		}
	}

	// 블러(포커스 아웃) 시, 선택된 항목이 입력값과 다를 경우 선택이 안된 것으로 처리함.
	handleBlur = () => {
		const { onUpdateValues } = this.props
		const { value, selected } = this.state
		if (selected) {
			if (value !== selected.title) {
				this.setState({
					selected: null,
				})
				onUpdateValues([
					{
						key: 'value',
						value,
					},
					{
						key: 'entityId',
						value: null,
					},
				])
			}
		} else {
			onUpdateValues([
				{
					key: 'value',
					value,
				},
				{
					key: 'entityId',
					value: null,
				},
			])
		}
	}

	//검색어 변경 시 search result 설정
	handleSearchResultChange = async ({ getAutoCompleteResults }, query) => {
		const { value } = this.state
		this.setState({
			...(query === value && {
				results: getAutoCompleteResults.map((entity, index) => ({
					key: index,
					id: entity.id,
					title: entity.title,
					description: entity.description,
				})),
			}),
			isLoading: false,
		})
	}

	/**
	 * 검색 결과 가져오기
	 */
	handleSearchChange = async value => {
		const { client, entityType } = this.props
		try {
			const { data } = await client.query({
				query: GET_AUTOCOMPLETE_RESULTS,
				variables: {
					entityType: entityType,
					searchString: value,
				},
			})

			this.handleSearchResultChange(data, value)
		} catch (e) {
			this.setState({ isLoading: false })
		}
	}

	render() {
		const { isLoading, results, value } = this.state
		const { readOnly, disabled, placeholder, inputRef, onKeyDown } = this.props

		return (
			<Search
				input={{
					fluid: true,
					disabled: disabled === true,
					ref: inputRef,
					onKeyDown,
					readOnly,
				}}
				loading={isLoading}
				onBlur={this.handleBlur}
				noResultsMessage="검색 결과가 없습니다."
				onResultSelect={(e, v) => {
					this.handleResultSelect(e, v)
				}}
				onSearchChange={(e, { value }) => {
					this.setState({ value })

					if (value !== '') {
						this.setState({ isLoading: true })
					}

					this.handleSearchChange(value)
				}}
				placeholder={placeholder}
				results={results}
				value={value}
				style={{ display: 'inline-block', flex: 1, maxWidth: 400 }}
			/>
		)
	}
}

SearchTextInput.propTypes = {
	entityType: PropTypes.string,
	value: PropTypes.string,
	disabled: PropTypes.bool,
	onUpdateValues: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	inputRef: PropTypes.object,
	onKeyDown: PropTypes.func,
	client: PropTypes.shape({
		query: PropTypes.func,
	}),
	readOnly: PropTypes.bool,
}

export default withApollo(SearchTextInput)
