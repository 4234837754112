import React from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import { Container } from 'semantic-ui-react/dist/commonjs'

import LoadingView from '../components/common/loader'
import ResearchRecordChangeItem from '../components/research/data/ResearchRecordChangeItem'

import { GET_RECORD_CHANGES } from '../graphql/queries/record'

const ResearchRecordHistoryList = props => {
	const { recordId, researchId, patientId, isBlinded } = props

	return (
		<Query
			query={GET_RECORD_CHANGES}
			variables={{ recordId, researchId, status: 'ACCEPTED' }}
			fetchPolicy="network-only"
		>
			{({ loading, data, error }) => {
				if (loading) {
					return <LoadingView />
				} else if (error) {
					return '..'
				} else {
					const { researchRecordChanges: changes } = data

					return (
						<Container style={{ marginTop: 10 }}>
							{changes.map(item => (
								<ResearchRecordChangeItem
									key={`${item.id}`}
									isBlinded={isBlinded}
									researchId={researchId}
									recordId={recordId}
									patientId={patientId}
									{...item}
								/>
							))}
						</Container>
					)
				}
			}}
		</Query>
	)
}

ResearchRecordHistoryList.propTypes = {
	recordId: PropTypes.number.isRequired,
	researchId: PropTypes.string.isRequired,
	isBlinded: PropTypes.bool,
}

export default ResearchRecordHistoryList
