import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { TextArea } from 'semantic-ui-react'
import useTextInput from './useTextInput'

/**
 * Data Field - TextArea
 *
 * 문단 입력을 위한 TextArea. 값 처리는 DataFieldTextInput과 동일
 */
const DataFieldTextArea = ({ valueKey = 'value', value, onUpdate, isTextArea, ...options }) => {
	const [stateValue, onChange, onBlur] = useTextInput(onUpdate, valueKey, value)

	return (
		<StyledTextArea rows={4} {...options} onChange={onChange} onBlur={onBlur} value={stateValue} />
	)
}

DataFieldTextArea.propTypes = {
	valueKey: PropTypes.string,
	value: PropTypes.string,
	onUpdate: PropTypes.func,
	isTextArea: PropTypes.bool,
}

export default DataFieldTextArea

const StyledTextArea = styled(TextArea)`
	padding: 8px;
	flex: 1;
	resize: none;
	border-radius: 5px;
	border-color: rgba(34, 36, 38, 0.15);
	min-height: 50px;
`
