import _ from 'lodash'
import { Operator, MedInfoValue, InputType, DataFieldFormat } from '../enum'
import { arraysEqual, filterInvalidInputFields, filterInvalidInputField } from './valueHelper'
import {
	MED_INFO_TYPE_INPUT_VALID_FIELDS,
	MED_INFO_SELECT_INPUT_VALID_FIELDS,
	CONDITION_ECRF_INPUT_VALID_FIELDS,
	MED_INFO_OPTION_INPUT_VALID_FIELDS,
	MED_INFO_INPUT_VALID_FIELDS,
	MED_INFO_CHECKED_INPUT_VALID_FIELDS,
} from '../constants'

const toCompare = ['value', 'vasValue', 'optionValue', 'parentId']

/**
 * 값 변경 비교
 * @param {*} before
 * @param {*} after
 */
const compareChange = (before, after) => {
	for (const key of toCompare) {
		if (before[key] !== after[key]) {
			return true
		}
		if (
			after.checkedItems &&
			before.checkedItems &&
			!arraysEqual(before.checkedItems, after.checkedItems)
		) {
			return true
		}
	}
}

/**
 * Operator를 이용한 값비교
 * @param {*} value1
 * @param {*} value2
 * @param {*} operator
 * @returns {Boolean} 성립여부
 */
export const compareValues = (value1, value2, operator, checkedItems) => {
	return Operator[operator].compare(value1, value2, checkedItems)
}

/**
 * medInfos의 특정 child를 업데이트 update(recursive)
 * @param {*} medInfos
 * @param {*} matcher
 * @param {*} prop
 */
export const findMedInfoTree = (medInfos, matcher, accum = []) => {
	for (const index in medInfos) {
		if (matcher(medInfos[index]) === true) {
			accum.push(medInfos[index])
			return accum
		}

		if (medInfos[index].children) {
			const rs = findMedInfoTree(medInfos[index].children, matcher)
			if (rs) {
				rs.push(medInfos[index])
				return rs
			}
		}
	}
}

/**
 * medInfos의 특정 child를 업데이트 update(recursive)
 * @param {*} medInfos
 * @param {*} matcher
 * @param {*} prop
 */
export const findOneMedInfo = (medInfos, matcher) => {
	for (const index in medInfos) {
		if (matcher(medInfos[index]) === true) {
			return medInfos[index]
		}

		if (medInfos[index].children) {
			const rs = findOneMedInfo(medInfos[index].children, matcher)
			if (rs) {
				return rs
			}
		}
	}
}

// 하위 medInfo가 있을 경우 하위 요소를 검사하는 재귀함수
// 없을 경우 검사 결과를 반환
export const checkMedInfoIsEmpty = medInfo => {
	if (medInfo.children && medInfo.children.length > 0) {
		const nulls = []
		for (const child of medInfo.children) {
			const nm = checkMedInfoIsEmpty(child)
			if (nm) nulls.push(nm)
		}

		if (nulls.length > 0) {
			return {
				text: medInfo.medInfoType.name,
				nullFields: nulls,
			}
		}
	} else if (
		medInfo.medInfoType.mustInclude &&
		(medInfo.disabled === false ||
			(medInfo.disabled === undefined && medInfo.medInfoType.defaultDisabled !== true))
	) {
		const musts = medInfo.medInfoType.mustInclude.split('|')

		const nullFields = []
		for (const i in musts) {
			const mustExist = medInfo[musts[i]]
			if (mustExist === null || mustExist === undefined || mustExist === '') {
				nullFields.push(MedInfoValue[musts[i]])
			}
		}

		if (nullFields.length > 0)
			return {
				text: medInfo.medInfoType.name,
				nullFields,
			}
	}
}

// medInfoSection을 돌면서 null 값을 체크
export const checkMedInfoHasEmptyValues = sections => {
	const nulls = []
	for (const section of sections) {
		const hasnull = checkMedInfoIsEmpty(section)

		if (hasnull) nulls.push(hasnull)
	}

	return nulls
}

/**
 * medInfos의 특정 child를 업데이트 update(recursive)
 * @param {*} medInfos
 * @param {*} matcher
 * @param {*} action
 */
export const updateChild = async (medInfos, matcher, action) => {
	for (const index in medInfos) {
		if (matcher(medInfos[index], index) === true) {
			medInfos[index] = await action(medInfos[index])

			return medInfos
		}

		if (medInfos[index].children) {
			const rs = await updateChild(medInfos[index].children.slice(0), matcher, action)
			if (rs) {
				medInfos[index].children = rs
				return medInfos
			}
		}
	}
}

/**
 * medInfo 특정 child 이하의 모든 children을 업데이트 update(recursive)
 * @param {*} medInfos
 * @param {*} matcher
 * @param {*} action
 */
export const updateChildBelow = async (medInfos, targetId, action, found) => {
	let found_ = found === true ? found : undefined
	for (let index = 0; index < medInfos.length; index += 1) {
		if (found_ === true) {
			medInfos[index] = await action(medInfos[index])
		} else if (medInfos[index].medInfoType.id === targetId) {
			found_ = true
		} else if (medInfos[index].children) {
			const rs = await updateChildBelow(medInfos[index].children.slice(0), targetId, action, found_)
			if (rs) {
				medInfos[index].children = rs
				found_ = true
			}
		}
	}

	if (found_) return medInfos
}

/**
 * medinfo index를 조건에 따라 찾기
 * @param {*} medInfos
 * @param {*} matcher
 * @param {*} result
 */
export const getIndexArray = (medInfos, matcher, result) => {
	for (const i in medInfos) {
		if (matcher(medInfos[i]) === true) {
			result.push(i)
			return true
		}
		if (medInfos[i].children && medInfos[i].children.length > 0) {
			const res = getIndexArray(medInfos[i].children, matcher, result)
			if (res) {
				result.unshift(i)
				return true
			}
		}
	}
}

/**
 * medInfos의 특정 child를 업데이트 update(recursive)
 * @param {*} medInfos
 * @param {*} matcher
 * @param {*} mutator
 * @param {*} mutator.key
 * @param {*} mutator.mutate
 */
export const mutateMedInfo = (medInfos, matcher, mutator) => {
	for (const index in medInfos) {
		if (matcher(medInfos[index]) === true) {
			const prop = {
				[mutator.key]: mutator.mutate(medInfos[index][mutator.key]),
			}

			medInfos[index] = {
				...medInfos[index],
				...prop,
			}

			return medInfos[index]
		}

		if (medInfos[index].children) {
			const rs = mutateMedInfo(medInfos[index].children, matcher, mutator)
			if (rs) return rs
		}
	}
}

/**
 * MedInfo Tree구조를 만드는 재귀함수
 * @param {Array} infos
 * @param {Number} id
 */
const findMedInfo = (infos, id) => {
	for (const idx in infos) {
		if (infos[idx].id === id) {
			return infos[idx]
		}
		if (infos[idx].children) {
			const res = findMedInfo(infos[idx].children, id)
			if (res) return res
		}
	}
}

// MedInfo를 children 항목을 통해 상 하 계층을 가진 Tree 구조로 변경
export const assembleMedInfos = medInfos => {
	const result = []
	for (const medInfo of medInfos) {
		if (!medInfo.children && InputType[medInfo.medInfoType.inputType].hasChild === true) {
			medInfo.children = []
		}

		if (medInfo.parentId) {
			const p = findMedInfo(medInfos, medInfo.parentId)
			if (p == null) {
				continue
			}

			if (!p.children) {
				p.children = [medInfo]
			} else {
				p.children.push(JSON.parse(JSON.stringify(medInfo)))
			}

			p.unresolvedMedInfoQueryCount += medInfo.unresolvedMedInfoQueryCount
		} else {
			result.push(medInfo)
		}
	}

	return result
}

const disassembleMedInfosLoop = (medInfo, result) => {
	if (medInfo.children && medInfo.children.length > 0) {
		medInfo.children.forEach(child => {
			disassembleMedInfosLoop(child, result)
		})
	}
	result.push(medInfo)
}

export const disassembleMedInfos = medInfo => {
	const result = []
	disassembleMedInfosLoop(medInfo, result)
	return result
}

// Tree 구조의 medInfo를 1차원 배열로 변경
const flattenMedInfos = medInfos => {
	for (const info of medInfos) {
		if (info.children) {
			medInfos.push(
				...info.children.map(item => {
					item.parentId = info.id
					return item
				})
			)
			delete info.children
		}
	}
}

export const calculateDiff = (originalMedInfos, editedMedInfos) => {
	const added = []
	const updated = []

	flattenMedInfos(editedMedInfos)
	for (const medInfo of editedMedInfos) {
		if (medInfo.id < 0) {
			added.push(medInfo)
		} else {
			const org = originalMedInfos.find(item => {
				return item.id === medInfo.id
			})

			if (org) {
				const hasChanges = compareChange(org, medInfo)
				if (hasChanges) {
					updated.push(medInfo)
				}

				originalMedInfos.splice(originalMedInfos.indexOf(org), 1)
			}
		}
	}

	const deleted = originalMedInfos

	return {
		added,
		updated,
		deleted,
	}
}

// Record의 데이터를 새로운 버전 template에 넣어서 diff 비교해보기
export const compareWithNewTemplates = (medInfos, templateMedInfos) => {
	if (medInfos == null || templateMedInfos == null) {
		return
	}

	let newId = -1
	let addCount = 0
	let removeCount = 0

	const newMedInfos = []
	const tempGridRows = templateMedInfos.filter(
		tMedInfo => tMedInfo.medInfoType.inputType === InputType.GRID_ROW.value
	)
	let tempGridCols = []
	const notAddGrid = {}

	// 양식 업데이트된 후의 medInfos 만들기
	templateMedInfos.forEach(tMedInfo => {
		const prevInfo = medInfos.find(
			m => m.medInfoType.medInfoTypeEntityId === tMedInfo.medInfoType.medInfoTypeEntityId
		)

		const parent = templateMedInfos.find(tm => tm.id === tMedInfo.parentId)
		let parentGrid
		if (parent != null) {
			parentGrid = templateMedInfos.find(tm => tm.id === parent.parentId)
		}

		if (prevInfo != null) {
			if (tMedInfo.medInfoType.inputType === InputType.GRID_ROW.value) {
				return
			} else if (tempGridRows.indexOf(tMedInfo.parentId) >= 0) {
				return
			} else if (parent != null && parent.medInfoType.inputType === InputType.GRID_ROW.value) {
				if (notAddGrid[parentGrid.medInfoType.medInfoTypeEntityId] === true) return
			}

			newMedInfos.push({
				...prevInfo,
				medInfoType: tMedInfo.medInfoType,
			})

			// 그리드 처리
			if (tMedInfo.medInfoType.inputType === InputType.GRID.value) {
				const isNewFixed =
					tMedInfo.medInfoType.format != '' &&
					tMedInfo.medInfoType.format.includes(DataFieldFormat.FIXED.key)
				const isPrevFixed =
					prevInfo.medInfoType.format != '' &&
					prevInfo.medInfoType.format.includes(DataFieldFormat.FIXED.key)
				const prevRows = medInfos.filter(medInfo => medInfo.parentId === prevInfo.id)
				tempGridCols = tempGridCols.concat(tMedInfo.medInfoType.childTypes)

				const newRowType = tMedInfo.medInfoType.childTypes.find(
					col => col.inputType === InputType.GRID_ROW.value
				)
				const newColumns = tMedInfo.medInfoType.childTypes.filter(
					col => col.inputType !== InputType.GRID_ROW.value
				)

				notAddGrid[tMedInfo.medInfoType.medInfoTypeEntityId] = true
				if (isNewFixed === true) {
					// 현재 fixed rows
					const newRows = templateMedInfos.filter(md => md.medInfoType.id === newRowType.id)
					if (isPrevFixed === true) {
						// 과거 fixed rows

						//TODO: 그리드 처리하기
						newRows.map((nRow, idx) => {
							let rowId

							const prevRow = prevRows[idx]
							// row 추가
							// columns 세팅
							let prevColumns
							if (prevRow != null) {
								//기존 row 있음
								newMedInfos.push({
									...prevRow,
									medInfoType: newRowType,
								})

								prevColumns = medInfos.filter(medInfo => medInfo.parentId === prevRow.id)
								rowId = prevRow.id
							} else {
								// 기존 row 없음
								newMedInfos.push({
									...nRow,
									id: newId,
									medInfoType: newRowType,
								})
								rowId = newId
								newId--
								addCount++

								prevColumns = []
							}

							newColumns.forEach(newColumn => {
								const prevCol = prevColumns.find(
									col => col.medInfoType.medInfoTypeEntityId === newColumn.medInfoTypeEntityId
								)

								if (prevCol != null) {
									newMedInfos.push({
										...prevCol,
										parentId: rowId,
										medInfoType: newColumn,
									})
								} else {
									newMedInfos.push({
										__typename: '',
										id: newId,
										optionValue: null,
										vasValue: null,
										parentId: rowId,
										medInfoType: newColumn,
										checkedItems: [],
										isAdded: true,
										readOnly: false,
									})
								}

								newId--
								addCount++
							})
						})
					} else {
						newRows.map(row => {
							newMedInfos.push({
								...row,
								id: newId,
								parentId: prevInfo.id,
								medInfoType: newRowType,
							})

							newId--

							newColumns.map(col => {
								newMedInfos.push({
									__typename: '',
									id: newId,
									optionValue: null,
									vasValue: null,
									parentId: row.id,
									medInfoType: col,
									checkedItems: [],
									isAdded: true,
									readOnly: false,
								})
								newId--
							})
						})
					}
				} else {
					// 현재 자유 rows
					prevRows.map(row => {
						newMedInfos.push({
							...row,
							medInfoType: newRowType,
						})

						const prevColumns = medInfos.filter(medInfo => medInfo.parentId === row.id)
						newColumns.forEach(newColumn => {
							const prevCol = prevColumns.find(
								col => col.medInfoType.medInfoTypeEntityId === newColumn.medInfoTypeEntityId
							)

							if (prevCol != null) {
								newMedInfos.push({
									...prevCol,
									medInfoType: newColumn,
								})
							} else {
								newMedInfos.push({
									__typename: '',
									id: newId,
									optionValue: null,
									vasValue: null,
									parentId: row.id,
									medInfoType: newColumn,
									checkedItems: [],
									isAdded: true,
									readOnly: false,
								})
								newId--
								addCount++
							}
						})
					})
				}
			}
		} else {
			// 새로운 MedInfoType이 있을 때
			// 부모ID가 있을 경우, 부모 항목이 있는지 확인하고 없으면 취소

			let parentId
			if (tMedInfo.parentId != null) {
				if (!parent) return

				let newParent
				if (parent.medInfoType.inputType === InputType.GRID_ROW.value) {
					if (notAddGrid[parentGrid.medInfoType.medInfoTypeEntityId] === true) {
						return
					}

					try {
						const newParents = newMedInfos.filter(
							nm => nm.medInfoType.medInfoTypeEntityId === parent.medInfoType.medInfoTypeEntityId
						)

						const tms = templateMedInfos.filter(nm => nm.medInfoType.id === parent.medInfoType.id)
						const rIdx = tms.findIndex(nn => nn.id === tMedInfo.parentId)
						newParent = newParents[rIdx]
					} catch (e) {
						console.log(e)
						return
					}
				} else {
					newParent = newMedInfos.find(
						nm => nm.medInfoType.medInfoTypeEntityId === parent.medInfoType.medInfoTypeEntityId
					)
				}

				if (newParent != null) parentId = newParent.id
				else return
			}

			newMedInfos.push({
				__typename: '',
				readOnly: false,
				...tMedInfo,
				id: newId,
				parentId,
				isAdded: true,
			})

			newId--
			addCount++
		}
	})

	const oldMedInfos = medInfos.map(medInfo => {
		const newInfo = templateMedInfos.find(
			m => m.medInfoType.medInfoTypeEntityId === medInfo.medInfoType.medInfoTypeEntityId
		)

		if (newInfo == null) {
			const newColInfo = tempGridCols.find(
				colType => colType.medInfoTypeEntityId === medInfo.medInfoType.medInfoTypeEntityId
			)

			if (newColInfo != null) {
				return medInfo
			}

			// 삭제된 medInfoType이 있을 때
			removeCount++

			return {
				...medInfo,
				isRemoved: true,
			}
		}

		return medInfo
	})

	return {
		newMedInfos,
		oldMedInfos,
		addCount,
		removeCount,
	}
}

export const cleanMedInfoType = medInfoType => {
	const cleanedMedInfoType = _.pick(medInfoType, MED_INFO_TYPE_INPUT_VALID_FIELDS)

	cleanedMedInfoType.selectOptions = filterInvalidInputFields(
		cleanedMedInfoType.selectOptions,
		MED_INFO_SELECT_INPUT_VALID_FIELDS
	)
	cleanedMedInfoType.options = filterInvalidInputFields(
		cleanedMedInfoType.options,
		MED_INFO_OPTION_INPUT_VALID_FIELDS
	)
	cleanedMedInfoType.conditions = filterInvalidInputFields(
		cleanedMedInfoType.conditions,
		CONDITION_ECRF_INPUT_VALID_FIELDS
	)
	cleanedMedInfoType.targetConditions = filterInvalidInputFields(
		cleanedMedInfoType.targetConditions,
		CONDITION_ECRF_INPUT_VALID_FIELDS
	)

	cleanedMedInfoType.childTypes =
		cleanedMedInfoType.childTypes != null
			? cleanedMedInfoType.childTypes.map(c => cleanMedInfoType(c))
			: []

	return cleanedMedInfoType
	// delete medInfoType.__typename
	// delete medInfoType.medInfoTypeEntityId
	// if (cleanMedInfoType.selectOptions instanceof Array) {
	// 	cleanedMedInfoType.selectOptions = cleanedMedInfoType.selectOptions.map(item =>
	// 		MED_INFO_SELECT_INPUT_VALID_FIELDS.
	// }
	// for (const optKey in cleadMedInfoType.selectOptions) {
	// 	delete medInfoType.selectOptions[optKey].id
	// 	delete medInfoType.selectOptions[optKey].__typename
	// }
	// for (const optKey in medInfoType.options) {
	// 	delete medInfoType.options[optKey].id
	// 	delete medInfoType.options[optKey].hasText
	// 	delete medInfoType.options[optKey].__typename
	// }
	// for (const condKey in medInfoType.conditions) {
	// 	delete medInfoType.conditions[condKey].__typename
	// 	delete medInfoType.conditions[condKey].isActive
	// 	if (medInfoType.conditions[condKey].values) delete medInfoType.conditions[condKey].values
	// }
	// for (const condKey in medInfoType.targetConditions) {
	// 	delete medInfoType.targetConditions[condKey].__typename
	// 	delete medInfoType.targetConditions[condKey].isActive
	// 	if (medInfoType.targetConditions[condKey].values)
	// 		delete medInfoType.targetConditions[condKey].values
	// }

	// if (medInfoType.childTypes) {
	// 	for (const ctKey in medInfoType.childTypes) {
	// 		cleanMedInfoType(medInfoType.childTypes[ctKey])
	// 	}
	// }
}

export const cleanMedInfos = ({ medInfos, templateMode, shouldKeepType }) => {
	return medInfos.map(medInfo => {
		const cleanedMedInfo = removeInvalidMedInfoInputValues(
			filterInvalidInputField(medInfo, MED_INFO_INPUT_VALID_FIELDS),
			templateMode,
			shouldKeepType
		)

		if (medInfo.disabled === true) {
			cleanedMedInfo.value = null
			cleanedMedInfo.checkedItems = []
			cleanedMedInfo.optionValue = null
		}

		if (cleanedMedInfo.value != null) {
			cleanedMedInfo.value = cleanedMedInfo.value + ''
		}

		if (_.isEmpty(cleanedMedInfo.checkedItems) === false) {
			cleanedMedInfo.checkedItems = filterInvalidInputFields(
				medInfo.checkedItems,
				MED_INFO_CHECKED_INPUT_VALID_FIELDS
			)
		}

		if (_.isEmpty(cleanedMedInfo.children) === false) {
			cleanedMedInfo.children = cleanMedInfos({
				medInfos: cleanedMedInfo.children,
				templateMode,
			})
		}

		return cleanedMedInfo
	})
}

export const extractMedInfoInputs = ({ medInfos, templateMode }) => {
	const copied = JSON.parse(JSON.stringify(medInfos))

	const d = cleanMedInfos({ medInfos: copied, templateMode })

	return d
}

// 자유차팅시 MedInfoTypes 트리구조로 정리하기
export const assembleMedInfoTypes = (types, medInfos) => {
	const findidx = (types, i) => {
		for (const idx in types) {
			if (types[idx].id === i) {
				return types[idx]
			}

			if (types[idx].subTypes) {
				const r = findidx(types[idx].subTypes, i)
				if (r) return r
			}
		}
	}

	for (let i = types.length - 1; i >= 0; i -= 1) {
		if (types[i].superTypeId) {
			const p = findidx(types, types[i].superTypeId)

			if (!p.subTypes) {
				p.subTypes = [types[i]]
			} else {
				p.subTypes.unshift(types[i])
			}

			types.splice(i, 1)
		}
	}

	for (const j in medInfos) {
		medInfos[j].medInfoType = types.find(t => medInfos[j].medInfoType.id === t.id)
	}
}

export const removeInvalidMedInfoInputValues = (medInfo, templateMode, shouldKeepType) => {
	const medInfoCopied = Object.assign({}, medInfo)
	if (medInfo.disabled === true) {
		medInfoCopied.value = null
		medInfoCopied.vasValue = null
		medInfoCopied.optionValue = null
		medInfoCopied.checkedItems = []
	} else {
		// option 기본값 설정(값이 없으면 1번으로 설정)
		if (
			medInfo.medInfoType.options &&
			medInfo.medInfoType.options.length > 0 &&
			medInfo.value != null &&
			(medInfo.optionValue == null || medInfo.optionValue === '')
		) {
			medInfoCopied.optionValue = medInfo.medInfoType.options[0].value
		}

		// 기본값 설정하기
		if (medInfo.medInfoType.defaultValue && medInfo.value === null) {
			medInfoCopied.value = medInfo.medInfoType.defaultValue
		}
	}
	// 양식일 경우
	if (templateMode === true) {
		if (medInfo.medInfoType.inputType !== InputType.NO_EDIT_STRING.value) {
			medInfoCopied.value = null
		}
		medInfoCopied.vasValue = null
		medInfoCopied.checkedItems = []

		medInfoCopied.medInfoType = cleanMedInfoType(medInfoCopied.medInfoType)
	} else {
		// 임상기록일 경우
		medInfoCopied.medInfoType.value =
			typeof medInfo.value === 'number' ? medInfo.value.toString() : medInfo.value
		medInfoCopied.medInfoTypeId = medInfoCopied.medInfoType.id
		if (shouldKeepType !== true) {
			delete medInfoCopied.medInfoType
		} else {
			medInfoCopied.medInfoType = cleanMedInfoType(medInfoCopied.medInfoType)
		}
	}

	return medInfoCopied
}

/**
 * 그리드 복사할 때 MedInfoTYpeId 배정
 */
export const processCopiedGridRows = (medInfos, types) => {
	for (const info of medInfos) {
		const type = types.find(t => info.medInfoType.id === t.previousId)
		if (type) {
			info.medInfoType = type
			if (info.children) {
				processCopiedGridRows(info.children, types)
			}
		} else {
			info.mustAddType = true
		}
	}
}

export const filterSurveyQuestions = medInfos => {
	return medInfos.filter(i => {
		const inputTypeInfo = InputType[i.medInfoType.inputType]
		return inputTypeInfo.isOnPatientSurvey === true && i.disabled !== true
	})
}

export const findEmptyMedInfo = medInfos => {
	for (const { children, value, stateValue, checkedItems, vasValue } of medInfos) {
		if (children != null && children.length > 0) {
			const hasNullChild = findEmptyMedInfo(children)
			if (hasNullChild === true) return hasNullChild
		} else {
			const hasNull =
				(value == null || value === '') &&
				(stateValue == null || stateValue === '') &&
				(checkedItems == null || checkedItems.length === 0) &&
				vasValue == null
			if (hasNull === true) return true
		}
	}
}
