import React from 'react'
import { ROUTE_MY_STATISTICS, ROUTE_STATISTICS_ANALYZE } from '../../constants/routes'
import StatsDashboard from './StatsDashboard'
import { RouteMenu } from '../common/RouteMenu'
import SubSideMenuItem from '../common/SubSideMenuItem'

const MENU_ITEMS = [
	{
		key: 'ST_SS_DASHBOARD',
		title: '대시보드',
		to: ROUTE_MY_STATISTICS,
		exact: true,
		component: StatsDashboard,
	},
	{
		key: 'ST_SS_ANALYZE',
		title: '분석',
		to: ROUTE_STATISTICS_ANALYZE,
		exact: true,
	},
]

const StatsSideMenu = () => {
	return (
		<RouteMenu
			menuItems={MENU_ITEMS}
			renderMenuItem={({ title }) => <SubSideMenuItem hasPadding>{title}</SubSideMenuItem>}
			menuOptions={{
				vertical: true,
				className: 'sub-side-menu',
			}}
		/>
	)
}

export default StatsSideMenu
