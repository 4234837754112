import styled from 'styled-components'
import { Grid } from 'semantic-ui-react'

const StatsGrid = styled(Grid)`
	.column > div:not(:first-child) {
		margin-top: 40px;
	}
`

export default StatsGrid
