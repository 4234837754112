import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import PageHeader from '../PageHeader'
import {
	PAGE_CONTENT_FIXED_WIDTH,
	PAGE_CONTENT_MAX_WIDTH,
	PAGE_CONTENT_SMALL_WIDTH,
	PAGE_DEFAULT_SIDE_PADDING,
	PAGE_CONTENT_MEDIUM_WIDTH,
	PAGE_CONTENT_MINI_WIDTH,
	PAGE_MIN_SIDE_PADDING,
} from '../../../constants/styles'
import Breadcrumb from '../basic/Breadcrumb'

/**
 * 페이지 내용 감싸는 Wrapper
 *
 * 옵션에 따라 페이지 전체를 체우거나 min or max width를 가지고
 * padding일 가짐
 */
const PageContainer = ({
	align,
	title,
	titleNumber,
	fluid,
	fixed,
	minMax,
	width: widthProp,
	mini,
	small,
	medium,
	full,
	children,
	breadCrumbs,
	padded,
	minWidth: minWidthProp,
	maxWidth: maxWidthProp,
	hasMobile,
}) => {
	let width, height, maxWidth, minWidth, textAlign, padding, flex

	if (fixed === true) {
		if (small === true) {
			width = PAGE_CONTENT_SMALL_WIDTH
		} else if (medium === true) {
			width = PAGE_CONTENT_MEDIUM_WIDTH
		} else if (mini === true) {
			width = PAGE_CONTENT_MINI_WIDTH
		} else {
			width = widthProp || PAGE_CONTENT_FIXED_WIDTH
		}
	} else if (fluid === true) {
		width = '100%'
		textAlign = 'left'
		flex = 1
		minWidth = minWidthProp || PAGE_CONTENT_SMALL_WIDTH
		if (padded === true) {
			padding = `0px ${PAGE_MIN_SIDE_PADDING}px`
		}
	} else if (minMax === true) {
		minWidth = minWidthProp || PAGE_CONTENT_SMALL_WIDTH
		maxWidth = maxWidthProp || PAGE_CONTENT_MAX_WIDTH
		padding = `0px ${PAGE_DEFAULT_SIDE_PADDING}px`
	} else if (full === true) {
		// width = '100%'
		minWidth = '100%'
		flex = 1
	} else {
		width = 'auto'
	}

	return (
		<Container
			minWidth={minWidth}
			maxWidth={maxWidth}
			hasMobile={hasMobile}
			fluid={fluid}
			style={{
				width,
				height,
				padding,
				justifyItems: align || 'left',
				textAlign: align || textAlign || 'left',
				flex,
			}}
		>
			{title != null && <PageHeader title={title} number={titleNumber} breadCrumbs={breadCrumbs} />}
			{title == null && breadCrumbs != null && <Breadcrumb sections={breadCrumbs} />}
			{children}
		</Container>
	)
}

export default PageContainer

PageContainer.propTypes = {
	align: PropTypes.string,
	title: PropTypes.string,
	titleNumber: PropTypes.number,
	fluid: PropTypes.bool,
	fixed: PropTypes.bool,
	minMax: PropTypes.bool,
	width: PropTypes.number,
	full: PropTypes.bool,
	medium: PropTypes.bool,
	small: PropTypes.bool,
	mini: PropTypes.bool,
	children: PropTypes.node.isRequired,
	breadCrumbs: PropTypes.arrayOf(
		PropTypes.shape({
			active: PropTypes.bool,
			link: PropTypes.bool,
			key: PropTypes.string,
			content: PropTypes.string,
			number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		})
	),
	hasMobile: PropTypes.bool,
	padded: PropTypes.bool,
	minWidth: PropTypes.number,
	maxWidth: PropTypes.number,
}

PageContainer.defaultProps = {
	full: false,
	width: null,
	minMax: null,
	fixed: false,
	fluid: false,
	align: 'left',
	title: null,
	titleNumber: null,
	breadCrumbs: null,
	hasMobile: false,
	padded: false,
	small: false,
	mini: false,
	medium: false,
	minWidth: null,
	maxWidth: null,
}

const Container = styled.div`
	margin: auto;
	margin-bottom: 50px;
	min-width: ${props => props.minWidth || 0}px;

	${props =>
		props.maxWidth
			? `
				max-width: ${props.maxWidth}px;
				`
			: ''};

	${props =>
		props.hasMobile === true
			? `
				@media (max-width: 768px) {
					width: 100% !important;
					min-width: 0px;
					${
						props.fluid !== true
							? `
									padding: 0px ${props.theme.PAGE_DEFAULT_MOBILE_PADDING}px;
								`
							: ''
					}
				}
			`
			: ''}
`
