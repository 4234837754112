import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'

import ResearchRecordList from './ResearchRecordList'
import { ROUTE_PROJECT_DETAIL_DATA_DEFAULT } from '../constants/routes'

const ResearchData = props => {
	const { type } = props

	return (
		<Switch>
			<Route
				exact
				path={ROUTE_PROJECT_DETAIL_DATA_DEFAULT}
				component={props_ => <ResearchRecordList {...props} {...props_} researchType={type} />}
			/>
		</Switch>
	)
}

ResearchData.propTypes = {
	type: PropTypes.string,
}

ResearchData.defaultProps = {
	type: null,
}

export default ResearchData
