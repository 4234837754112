import React, { PureComponent, createRef, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Header, Sticky, Icon } from 'semantic-ui-react'
import { compareWithNewTemplates } from '../../libs/medInfoHelper'
import RecordCompare from './RecordCompare'
import ErrorView from '../common/error/ErrorView'
import RecordUpdateTemplateHeader from './RecordUpdateTemplateHeader'
import './style.css'
import { UPDATE_RECORD_MED_INFOS } from '../../graphql/mutations/record'
import { replaceParams } from '../../libs/routeHelper'
import {
	ROUTE_PROJECT_DETAIL_DATA_DETAIL_DEFAULT,
	ROUTE_PATIENT_DETAIL_RECORD,
} from '../../constants/routes'

/**
 * 양식 업데이트 뷰
 */
class RecordUpdateTemplateView extends PureComponent {
	static propTypes = {
		loading: PropTypes.bool,
		medInfos: PropTypes.array.isRequired,
		templateMedInfos: PropTypes.array.isRequired,
		isOnResearchPage: PropTypes.bool,
		recordCode: PropTypes.string,
		client: PropTypes.shape({
			push: PropTypes.func,
		}),
		patientId: PropTypes.number,
		researchId: PropTypes.number,
		history: PropTypes.shape({
			push: PropTypes.func,
		}),
		onUpdate: PropTypes.func,
		refetchQuery: PropTypes.object,
		recordId: PropTypes.number,
		lastTemplateChangeId: PropTypes.number,
	}

	constructor(props) {
		super(props)

		if (props.medInfos != null && props.templateMedInfos != null) {
			const { oldMedInfos, newMedInfos, addCount, removeCount } = compareWithNewTemplates(
				props.medInfos,
				props.templateMedInfos
			)
			this.oldMedInfos = oldMedInfos
			this.newMedInfos = newMedInfos
			this.addCount = addCount
			this.removeCount = removeCount
		}

		this.contextRef = createRef()
		this.subContextRef = createRef()
		this.state = {
			isLoading: false,
		}
	}

	handleSubmit = medInfos => {
		const { recordId, lastTemplateChangeId } = this.props
		const {
			isOnResearchPage,
			client,
			patientId,
			researchId,
			history,
			recordCode,
			onUpdate,
			refetchQuery,
		} = this.props

		this.setState({
			isLoading: true,
		})
		client.mutate({
			mutation: UPDATE_RECORD_MED_INFOS,
			variables: {
				researchId,
				recordId,
				recordTemplateChangeId: lastTemplateChangeId,
				medInfos,
			},
			update: () => {
				this.setState({
					isLoading: false,
				})

				let link

				if (isOnResearchPage === true) {
					link = replaceParams(ROUTE_PROJECT_DETAIL_DATA_DETAIL_DEFAULT, {
						id: researchId,
						subId: patientId,
						recordId,
					})
				} else {
					link = replaceParams(ROUTE_PATIENT_DETAIL_RECORD, {
						id: patientId,
						subId: recordId,
					})
				}

				history.push(link)
			},
			// refetchQueries: [refetchQuery],
		})
	}

	render() {
		const { loading, onSubmit, updatingChartRef, template, patient, visitedDate } = this.props
		if (this.oldMedInfos == null || this.newMedInfos == null) {
			return <ErrorView />
		}
		const { isLoading } = this.state

		return (
			<div ref={this.contextRef}>
				<Sticky context={this.contextRef}>
					<div>
						<RecordUpdateTemplateHeader
							onSubmit={onSubmit}
							addCount={this.addCount}
							removeCount={this.removeCount}
							isSaveLoading={isLoading}
							templateTitle={template != null && template.title}
							patientName={patient != null && patient.name}
						/>
					</div>
				</Sticky>
				<div ref={this.subContextRef} style={styles.content}>
					<div style={styles.container}>
						<div style={{ ...styles.wrapper, background: '#fdeeee' }}>
							<Sticky offset={80} context={this.subContextRef}>
								<div style={styles.headerWrap}>
									<Header as="h2" textAlign="center" className="compare-header">
										업데이트 전
									</Header>
								</div>
							</Sticky>
							<RecordCompare
								isFixed
								readOnly
								visitedDate={visitedDate}
								medInfos={this.oldMedInfos}
								loading={loading}
							/>
						</div>
					</div>
					<div style={styles.container}>
						<div style={{ ...styles.wrapper, background: '#eefdee' }}>
							<Sticky offset={80} context={this.subContextRef}>
								<div style={styles.headerWrap}>
									<Header as="h2" textAlign="center" className="compare-header">
										업데이트 후
									</Header>
								</div>
							</Sticky>
							<RecordCompare
								ref={updatingChartRef}
								isFixed
								readOnly
								visitedDate={visitedDate}
								medInfos={this.newMedInfos}
								loading={loading}
								onSaveChart={this.handleSubmit}
							/>
						</div>
					</div>
					<span className="compare-arrow">
						<Icon name="arrow right" size="large" />
					</span>
				</div>
			</div>
		)
	}
}

const styles = {
	content: {
		position: 'relative',
	},
	container: {
		width: '50%',
		display: 'inline-block',
		padding: 20,
	},
	wrapper: {
		padding: '20px 10px',
		borderRadius: 10,
		border: '1px solid #eee',
	},
	// compareTitle: {
	// 	borderRadius: 15,
	// 	boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.3)',
	// 	display: 'inline-block',
	// 	margin: 'auto',
	// 	padding: 12,
	// },
	headerWrap: { textAlign: 'center', paddingBottom: 20 },
}

const forwarding = (props, ref) => {
	return <RecordUpdateTemplateView {...props} updatingChartRef={ref} />
}

export default forwardRef(forwarding)
