import gql from 'graphql-tag'

export const WRITE_SIMPLE_CASE = gql`
	mutation WriteSimpleCase(
		$title: String
		$charts: String
		$tags: String
		$age: Int
		$sex: String
		$chartNum: String
		$sharingScope: SharingScope
		$groupId: Int
		$uploadingImages: [Upload]
	) {
		writeSimpleCase(
			title: $title
			charts: $charts
			tags: $tags
			age: $age
			sex: $sex
			chartNum: $chartNum
			sharingScope: $sharingScope
			groupId: $groupId
			uploadingImages: $uploadingImages
		) {
			id
			title
			text
			tags
			age
			sex
			chartNum
			creator {
				id
				nickname
				profileImage
			}
			createdAt
			updatedAt
			sharingScope
			groupId
		}
	}
`

export const UPDATE_SIMPLE_CASE = gql`
	mutation UpdateSimpleCase(
		$caseId: Int
		$title: String
		$charts: String
		$tags: String
		$age: Int
		$sex: String
		$chartNum: String
		$sharingScope: SharingScope
		$groupId: Int
		$uploadingImages: [Upload]
	) {
		updateSimpleCase(
			caseId: $caseId
			title: $title
			charts: $charts
			tags: $tags
			age: $age
			sex: $sex
			chartNum: $chartNum
			sharingScope: $sharingScope
			groupId: $groupId
			uploadingImages: $uploadingImages
		) {
			id
			title
			text
			tags
			age
			sex
			chartNum
			creator {
				id
				nickname
				profileImage
			}
			createdAt
			updatedAt
			sharingScope
			groupId
		}
	}
`

export const DELETE_SIMPLE_CASE = gql`
	mutation deleteSimpleCase($caseId: Int) {
		deleteSimpleCase(caseId: $caseId)
	}
`
