import React from 'react'
import { Transition, Message } from 'semantic-ui-react'
import { useQuery } from 'react-apollo'
import { GET_TOAST_MESSAGES } from '../../graphql/queries/app'

/**
 * 토스트 메시지 보여주기
 *
 * 앱 전체에서 사용하는 토스트 메시지 화면
 * Global State로 관리
 */
const ToastMessages = () => {
	const {
		data: { toastMessages },
	} = useQuery(GET_TOAST_MESSAGES)

	return (
		<>
			{toastMessages.map(m => (
				<Transition key={m.id} visible={toastMessages.length > 0} animation="scale" duration={500}>
					<Message
						style={{
							position: 'fixed',
							bottom: 100,
							left: '50%',
							marginLeft: -100,
							zIndex: 9999,
						}}
						floating
						compact
						{...m}
					/>
				</Transition>
			))}
		</>
	)
}

ToastMessages.propTypes = {}

export default ToastMessages
