import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import useLocalMedInfo from '../../../hooks/useLocalMedInfo'
import { InputType } from '../../../enum'

const PatientSurveySelect = ({ medInfo, inBlock }) => {
	const { id, medInfoType, checkedItems } = medInfo
	const { inputType } = medInfoType
	const [localMedInfo, update] = useLocalMedInfo(id)

	const isCheckbox = inputType === InputType.CHECKBOX.value
	const { value, medInfoType: { selectOptions } = {} } = localMedInfo

	return (
		<Container inBlock={inBlock}>
			{selectOptions != null &&
				selectOptions.map(option => {
					const { text, value: optVal } = option
					const isActiveCheckbox = checkedItems.findIndex(i => i.value === optVal) !== -1
					return (
						<SelectItem
							key={`pss-opt-${optVal}`}
							text={text}
							isActive={isCheckbox !== true ? value === optVal : isActiveCheckbox}
							onClick={() => {
								if (isCheckbox !== true) update('value', optVal)
								else {
									let ret
									if (isActiveCheckbox === true) ret = checkedItems.filter(i => i.value !== optVal)
									else ret = checkedItems.concat({ value: optVal, freeWriting: null })
									update('checkedItems', ret)
								}
							}}
							inBlock={inBlock}
						/>
					)
				})}
		</Container>
	)
}

PatientSurveySelect.propTypes = { medInfo: PropTypes.object }

export default PatientSurveySelect

const SelectItem = ({ text, isActive, onClick, inBlock }) => {
	return (
		<SelectItemButon onClick={onClick} isActive={isActive} inBlock={inBlock}>
			{text}
		</SelectItemButon>
	)
}

SelectItem.propTypes = {
	text: PropTypes.string,
	isActive: PropTypes.bool,
	onClick: PropTypes.func,
}

const Container = styled.div`
	width: 100%;
	background-image: ${props => props.theme.PROGRESS_GRADIENT_GRAY};
	${props =>
		props.inBlock !== true
			? css`
					border-radius: 8px;
			  `
			: css`
					border-bottom-left-radius: 8px;
					border-bottom-right-radius: 8px;
			  `}
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
	border: solid 1px rgba(0, 0, 0, 0.05);
`

const SelectItemButon = styled.div`
	flex: 1;
	padding: 20px;
	font-size: 18px;
	${props =>
		props.inBlock !== true &&
		css`
			:first-child {
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
			}
		`}
	:last-child {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}
	:not(:last-child) {
		border-bottom: ${props => props.theme.BORDER_GRAY};
	}
	color: #747c7c;
	font-weight: 600;
	text-align: center;

	${props =>
		props.isActive === true
			? `
		    background: ${props.theme.COLOR_TEXT_NAVY};
		    color: white;
	    `
			: ''}
`
