/**
 * [Component] 사용자 프로필 수정 - 기본 정보 수정
 * [Pure]
 *
 * 사용자 개인 정보 불러오는 쿼리 호출 후 뷰 컴퍼넌트 렌더링
 */

import React, { PureComponent } from 'react'
import { Query, graphql } from 'react-apollo'
import compose from 'lodash.flowright'
import { GET_USER_DETAIL } from '../graphql/queries/user'

import LoadingView from '../components/common/loader'
import { UPDATE_USER_PROFILE } from '../graphql/mutations/user'
import UserProfileEditView from '../components/user/my/UserProfileEditView'
import MiniErrorView from '../components/common/error/MiniErrorView'

class UserProfileEditInfo extends PureComponent {
	render() {
		return (
			<Query query={GET_USER_DETAIL}>
				{({ loading, error, refetch, data }) => {
					if (loading) {
						return <LoadingView />
					}

					if (error) {
						return <MiniErrorView />
					}

					return <UserProfileEditView {...this.props} {...data.userDetail} onUpdate={refetch} />
				}}
			</Query>
		)
	}
}

export default compose(
	graphql(UPDATE_USER_PROFILE, {
		name: 'updateUserProfile',
	})
)(UserProfileEditInfo)
