import React from 'react'
import PropTypes from 'prop-types'

import { HashLink } from 'react-router-hash-link'
import { Icon, Header, Segment } from 'semantic-ui-react'
import { matchErrorCode } from '../../../libs/errorHelper'
import errorTypes from '../../../errorTypes'

const ErrorView = ({ error }) => {
	let title, content

	if (error) {
		if (matchErrorCode(error, errorTypes.ENTITY_NOT_EXISTS_ERROR)) {
			title = '존재하지 않는 항목입니다.'
			content = (
				<React.Fragment>
					<Header as="h2">해당 항목이 존재하지 않습니다.</Header>
					<br />
					삭제된 항목이거나, 잘못된 접근일 수 있습니다.
				</React.Fragment>
			)
		} else if (matchErrorCode(error, errorTypes.ACCESS_FORBIDDEN_ERROR)) {
			title = '접근 권한이 없습니다.'
			content = (
				<React.Fragment>
					<Header as="h2">해당 항목에 접근할 수 없습니다.</Header>
					<br />
					해당 항목의 열람을 위한 권한을 가지고 있지 않습니다.
				</React.Fragment>
			)
		}
	}

	if (!title) {
		title = '데이터를 불러오지 못했습니다.'
		content = (
			<React.Fragment>
				<Header as="h2">다시 시도해 주세요.</Header>
				1. F5 (macOS: cmd + R) 키를 눌러 새로고침 해 보시거나, <br />
				2. 로그아웃 후 재 로그인 해 보시거나
				<br />
				3. 캐시를 삭제 (ctrl + shift + delete 누른 후, 전체기간 선택 후 확인) 해 보세요.
				<br />
				<br />
				만약 계속 작동하지 않는다면, <HashLink to="/#contact">여기</HashLink> 를 클릭해 문의해
				주세요.
				<br />
				불편을 드려 죄송합니다.
			</React.Fragment>
		)
	}

	return (
		<div>
			<div style={{ margin: 'auto', marginTop: 50, textAlign: 'center', maxWidth: 700 }}>
				<Header as="h1">
					<Icon name="warning sign" /> {title}
				</Header>
				<Segment style={{ textAlign: 'left', padding: 30, marginTop: 50, lineHeight: 1.3 }}>
					{content}
				</Segment>
			</div>
		</div>
	)
}

ErrorView.propTypes = {
	error: PropTypes.object,
}

export default ErrorView
