import React, { useMemo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

function ProgressBar({ medInfos, currentIndex }) {
	const progressArray = useMemo(() => {
		const arr = []
		let ci = currentIndex
		for (let i = 0; i < medInfos.length; i++) {
			ci = ci - 1
			const children = medInfos[i].children
			if (children.length < ci) {
				ci = ci - children.length
				arr.push([{ key: `section-${i}`, done: true, active: false }])
			} else if (0 < ci) {
				arr.push([])
				for (let j = 0; j < children.length; j++) {
					ci = ci - 1
					if (ci > 0) arr[i].push({ key: `section-${i} field-${j}`, done: true, active: false })
					else if (ci < 0)
						arr[i].push({ key: `section-${i} field-${j}`, done: false, active: false })
					else arr[i].push({ key: `section-${i} field-${j}`, done: false, active: true })
				}
			} else {
				arr.push([{ key: `section-${i}`, done: false, active: false }])
			}
		}
		return arr
	}, [currentIndex, medInfos])

	// const progressArray = useMemo(() => {
	// 	const arr = []
	// 	for (let i = 0; i < sectionLength; i++) {
	// 		if (i < sectionIndex) arr.push([{ key: `section-${i}`, done: true, active: false }])
	// 		else if (i > sectionIndex) arr.push([{ key: `section-${i}`, done: false, active: false }])
	// 		else {
	// 			const arr2 = []
	// 			for (let j = 0; j < fieldLength; j++) {
	// 				if (j < fieldIndex) arr2.push({ key: `field-${j}`, done: true, active: false })
	// 				else if (j > fieldIndex) arr2.push({ key: `field-${j}`, done: false, active: false })
	// 				else arr2.push({ key: `field-${j}`, done: false, active: true })
	// 			}
	// 			arr.push(arr2)
	// 		}
	// 	}
	// 	return arr
	// }, [sectionIndex, sectionLength, fieldIndex, fieldLength])

	return (
		<Container>
			{progressArray.map(section => (
				<SquareWrapper key={`sectionWrapper-${section[0].key}`}>
					{section.map(field => (
						<Square key={field.key} done={field.done} active={field.active} />
					))}
				</SquareWrapper>
			))}
		</Container>
	)
}

ProgressBar.propTypes = {
	medInfos: PropTypes.array,
	currentIndex: PropTypes.number,
}

export default React.memo(ProgressBar)

const Container = styled.div`
	display: flex;
	position: absolute;
	top: 8vh;
	left: 50%;
	transform: translateX(-50%);
`

const Square = styled.div`
	width: 3vmin;
	height: 3vmin;
	background: ${props =>
		props.active === true ? '#87c8d7' : props.done === true ? '#575b5b' : '#eaeaea'};
	margin-right: 1px;
	transition: all 0.3s;
`

const SquareWrapper = styled.div`
	display: flex;
	margin-right: 8px;
	& > ${Square} {
		:first-child {
			border-top-left-radius: 24%;
			border-bottom-left-radius: 24%;
		}
		:last-child {
			border-top-right-radius: 24%;
			border-bottom-right-radius: 24%;
		}
	}
`
