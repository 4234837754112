import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ApolloConsumer } from 'react-apollo'
import { Dropdown } from 'semantic-ui-react'
import { GET_AUTOCOMPLETE_RESULTS } from '../../../../graphql/queries/library'

class SearchMultipleInput extends Component {
	static propTypes = {
		checkedItems: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.string,
				value: PropTypes.string,
				text: PropTypes.string,
				freeWriting: PropTypes.string,
			})
		).isRequired,
		readOnly: PropTypes.bool,
		entityType: PropTypes.string.isRequired,
		disabled: PropTypes.bool.isRequired,
		onUpdateValues: PropTypes.func.isRequired,
		placeholder: PropTypes.string.isRequired,
	}

	constructor(props) {
		super(props)

		let checked = []

		if (props.checkedItems) {
			checked = props.checkedItems.map(item => ({
				key: item.value,
				value: item.value,
				text: item.freeWriting,
				freeWriting: item.freeWriting,
			}))
		}

		this.state = {
			isLoading: false,
			results: checked,
			checkedItems: checked,
			values: props.checkedItems ? props.checkedItems.map(i => i.value) : [],
		}
	}

	handleResultSelect = async (e, { value }) => {
		const { onUpdateValues } = this.props
		const { results: rs } = this.state

		if (value) {
			const checkedItems = value.map(i => rs.find(r => r.value === i))

			this.setState({
				checkedItems,
				values: value,
				results: checkedItems,
			})

			onUpdateValues([
				{
					key: 'checkedItems',
					value: checkedItems,
				},
			])
		}
	}

	handleSearchChange = async ({ getAutoCompleteResults }, searchquery) => {
		let hasExact
		const atr = getAutoCompleteResults.map(entity => {
			if (searchquery === entity.title) hasExact = true
			return {
				key: entity.id,
				value: entity.id,
				text: entity.title,
				description: entity.description,
				freeWriting: entity.title,
			}
		})

		const { checkedItems } = this.state

		const results = [
			...atr,
			...(!hasExact
				? [
						{
							key: 'searchquery',
							value: searchquery,
							text: searchquery,
							description: '새로운 항목',
							freeWriting: searchquery,
						},
				  ]
				: []),
			...checkedItems,
		].filter((thing, index, self) => {
			return index === self.findIndex(t => t.value === thing.value)
		})

		this.setState({
			results,
			isLoading: false,
		})
	}

	render() {
		const { isLoading, results, values } = this.state
		const { entityType, disabled, placeholder, readOnly } = this.props

		return (
			<ApolloConsumer>
				{client => (
					<Dropdown
						disabled={disabled}
						fluid
						clearable
						placeholder={placeholder}
						loading={isLoading}
						onBlur={this.handleBlur}
						noResultsMessage="검색 결과가 없습니다."
						onChange={(e, v) => {
							if (readOnly !== true) {
								this.handleResultSelect(e, v, client)
							}
						}}
						onSearchChange={async (e, { searchQuery }) => {
							if (searchQuery.trim() !== '') {
								await this.setState(prev => ({
									isLoading: true,
									results: [
										{
											key: searchQuery,
											value: searchQuery,
											text: searchQuery,
										},
										...prev.checkedItems,
									],
								}))

								try {
									const { data } = await client.query({
										fetchPolicy: 'network-only',
										query: GET_AUTOCOMPLETE_RESULTS,
										variables: {
											entityType,
											searchString: searchQuery.trim(),
										},
									})

									this.handleSearchChange(data, searchQuery)
								} catch (e2) {
									this.setState({ isLoading: false })
								}
							} else {
								this.setState(prev => ({
									results: prev.checkedItems,
								}))
							}
						}}
						search={options => {
							return options
						}}
						multiple
						selection
						icon="search"
						options={results}
						value={values}
						// style={{ display: 'inline-block', flex: 1, maxWidth: 400 }}
					/>
				)}
			</ApolloConsumer>
		)
	}
}

export default SearchMultipleInput
