import React from 'react'
import PropTypes from 'prop-types'

import { Label } from 'semantic-ui-react'

const StatusDot = props => {
	const { size, color } = props
	let width
	if (size === 'mini') width = 10
	else if (size === 'tiny') width = 12
	else if (size === 'small') width = 14
	else if (size === 'large') width = 16
	else if (size === 'huge') width = 18
	return (
		<Label
			style={{ fontSize: 0, minWidth: width, width, minHeight: width, verticalAlign: 'middle' }}
			circular
			color={color}
		/>
	)
}

StatusDot.propTypes = {
	size: PropTypes.string,
	color: PropTypes.string,
}
StatusDot.defaultProps = {
	size: 'small',
	color: 'green',
}

export default StatusDot
