import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import { GET_RECORD_CHANGE_DETAIL } from '../graphql/queries/record'
import ResearchTemplateHistoryDetailView from '../components/research/templates/ResearchTemplateHistoryDetailView'
import ErrorView from '../components/common/error/ErrorView'
import LoadingView from '../components/common/loader'

export default class ResearchRecordHistoryDetail extends PureComponent {
	static propTypes = {
		recordCode: PropTypes.string,
		match: PropTypes.object,
	}

	render() {
		const { recordCode, match } = this.props
		const versionCode = match.params.recordChangeCode

		return (
			<Query
				query={GET_RECORD_CHANGE_DETAIL}
				variables={{ recordCode, recordChangeCode: versionCode }}
			>
				{({ loading, data, error }) => {
					if (loading) {
						return <LoadingView />
					}

					if (error) {
						return <ErrorView />
					}

					const { researchRecordChangeDetail: change } = data

					return (
						<ResearchTemplateHistoryDetailView
							{...this.props}
							{...change}
							template={false}
							versionCode={versionCode}
						/>
					)
				}}
			</Query>
		)
	}
}
