import gql from 'graphql-tag'

export const MED_INFO_FRAGMENT = gql`
	fragment MedInfoParts on MedInfo {
		id
		value
		optionValue
		vasValue
		entityId
		parentId
		medInfoEntityId
		checkedItems {
			value
			freeWriting
		}
		medInfoQueryCount
		unresolvedMedInfoQueryCount
	}
`

export const MED_INFO_DATA_FRAGMENT = gql`
	fragment MedInfoDataParts on MedInfo {
		id
		value
		optionValue
		vasValue
		entityId
		parentId
		medInfoEntityId
		checkedItems {
			value
			freeWriting
		}
	}
`
export const CONDITION_ECRF_FRAGMENT = gql`
	fragment ConditionParts on ConditionEcrf {
		id
		value
		operator
		action
		targetId
		subjectId
		message
		isRequired
		scope
	}
`

export const MED_INFO_TYPE_FRAGMENT = gql`
	fragment MedInfoTypeParts on MedInfoType {
		id
		name
		typeName
		inputType
		entityType
		color
		hasVAS
		vasMax
		placeholder
		unit
		format
		description
		size
		defaultDisabled
		mustInclude
		parentTypeId
		defaultValue
		medInfoTypeEntityId
		conditions {
			...ConditionParts
		}
		targetConditions {
			...ConditionParts
		}
		selectOptions {
			value
			text
			hasText
		}
		options {
			value
			text
			format
			min
			max
		}
	}
	${CONDITION_ECRF_FRAGMENT}
`

export const MED_INFO_QUERY_FRAGMENT = gql`
	fragment MedInfoQueryParts on MedInfoQuery {
		id
		writer {
			id
			name
			profileImage
		}
		status
		message
		updatedAt
		createdAt
	}
`

export const LOCAL_MED_INFO = gql`
	fragment LocalMedInfoParts on LocalMedInfo {
		id
		value
		optionValue
		vasValue
		entityId
		parentId
		medInfoEntityId
		checkedItems {
			value
			freeWriting
		}
		disabled
		medInfoType {
			id
			name
			typeName
			inputType
			entityType
			color
			hasVAS
			vasMax
			placeholder
			unit
			format
			description
			size
			defaultDisabled
			mustInclude
			parentTypeId
			defaultValue
			medInfoTypeEntityId
			conditions {
				id
				value
				operator
				action
				targetId
				subjectId
				message
				isRequired
				scope
			}
			targetConditions {
				id
				value
				operator
				action
				targetId
				subjectId
				message
				isRequired
				scope
			}
			selectOptions {
				value
				text
			}
			options {
				value
				text
				min
				max
			}
		}
	}
`
