import React from 'react'
import { Placeholder } from 'semantic-ui-react'

function PlaceholderContent() {
	return (
		<div
			style={{
				height: '100%',
				width: '100%',
				maxWidth: 960,
				margin: 'auto',
				marginTop: 50,
				textAlign: 'center',
			}}
		>
			<Placeholder fluid>
				<Placeholder.Paragraph>
					<Placeholder.Line length="long"></Placeholder.Line>
					<Placeholder.Line length="medium"></Placeholder.Line>
					<Placeholder.Line length="medium"></Placeholder.Line>
					<Placeholder.Line length="long"></Placeholder.Line>
					<Placeholder.Line></Placeholder.Line>
					<Placeholder.Line length="medium"></Placeholder.Line>
					<Placeholder.Line length="short"></Placeholder.Line>
					<Placeholder.Line length="medium"></Placeholder.Line>
					<Placeholder.Line></Placeholder.Line>
				</Placeholder.Paragraph>
				<Placeholder.Paragraph>
					<Placeholder.Line></Placeholder.Line>
					<Placeholder.Line length="long"></Placeholder.Line>
					<Placeholder.Line></Placeholder.Line>
					<Placeholder.Line length="medium"></Placeholder.Line>
					<Placeholder.Line length="medium"></Placeholder.Line>
					<Placeholder.Line length="medium"></Placeholder.Line>
					<Placeholder.Line></Placeholder.Line>
				</Placeholder.Paragraph>
				<Placeholder.Paragraph>
					<Placeholder.Line></Placeholder.Line>
					<Placeholder.Line></Placeholder.Line>
					<Placeholder.Line length="long"></Placeholder.Line>
					<Placeholder.Line length="medium"></Placeholder.Line>
					<Placeholder.Line length="medium"></Placeholder.Line>
					<Placeholder.Line length="medium"></Placeholder.Line>
					<Placeholder.Line></Placeholder.Line>
				</Placeholder.Paragraph>
			</Placeholder>
		</div>
	)
}

export default PlaceholderContent
