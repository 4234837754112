/**
 * [Component] MedInfo를 나타내는 상위 Component
 *
 * DataFieldGroup(MedInfo Group)과 DataField(MedInfo)를 공동으로
 * DragAndDrop 하기 위해 사용함 (TODO: HOC로 전환 고려)
 */

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { DragSource, DropTarget } from 'react-dnd'

import { DRAG_ITEM_TYPES } from '../../../constants'
import DataField from './DataField'
import DataFieldGroup from './DataFieldGroup'
import DataFieldGrid from './DataFieldGrid'

const makeTargetInfo = props => {
	return {
		medInfoIndex: props.medInfoIndex,
		medInfoId: props.medInfoId,
		isAdding: false,
	}
}

const dataFieldTarget = {
	drop(props, monitor) {
		if (monitor.isOver() === true) {
			return makeTargetInfo(props)
		}
	},
}

function collectTarget(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver({ shallow: true }),
		droppedItem: monitor.getItem(),
	}
}

const dataFieldSource = {
	beginDrag(props) {
		return {
			medInfoId: props.medInfoId,
			medInfo: props.medInfo,
		}
	},
	canDrag(props) {
		return props.isFixed !== true
	},
	endDrag(props, monitor) {
		if (!monitor.didDrop()) {
			return
		}

		const source = monitor.getItem()
		const target = monitor.getDropResult()

		if (
			source.medInfoId === target.medInfoId ||
			(source.isGroup === true && typeof target.groupIndex === 'number')
		) {
			return
		}

		props.onMoveMedInfo(source, target)
	},
}

function collectSource(connect, monitor) {
	return {
		connectDragSource: connect.dragSource(),
		connectDragPreview: connect.dragPreview(),
		isDragging: monitor.isDragging(),
	}
}

class DataFieldBox extends PureComponent {
	static propTypes = {
		medInfo: PropTypes.shape({
			value: PropTypes.string,
			medInfoType: PropTypes.shape({
				inputType: PropTypes.string,
			}),
			disabled: PropTypes.bool,
		}).isRequired,
		droppedItem: PropTypes.shape({
			medInfoId: PropTypes.number,
		}),
		connectDropTarget: PropTypes.func.isRequired,
		connectDragSource: PropTypes.func.isRequired,
		medInfoId: PropTypes.number.isRequired,
		isDragging: PropTypes.bool,
		isOver: PropTypes.bool,
		parentId: PropTypes.number,
		onGenerateMedInfo: PropTypes.func.isRequired,
		onInsertMedInfo: PropTypes.func.isRequired,
		medInfoIndex: PropTypes.number.isRequired,
		onOpenEditModal: PropTypes.func.isRequired,
		onDeleteMedInfo: PropTypes.func.isRequired,
		onUpdateMedInfo: PropTypes.func.isRequired,
		hideDisabled: PropTypes.bool,
		templateMode: PropTypes.bool,
	}

	static defaultProps = {
		isDragging: false,
		isOver: false,
	}

	// shouldComponentUpdate(props) {
	// 	const {
	// 		medInfos,
	// 		medInfo: { value, medInfoType },
	// 		disabled,
	// 	} = this.props
	// 	if (medInfos !== props.medInfos) {
	// 		console.log('mdinfos')
	// 		return true
	// 	}
	// 	try {
	// 		if (value !== props.medInfo.value) {
	// 			console.log('value', value, props.medInfo.value)
	// 			return true
	// 		}
	// 		if (disabled !== props.disabled) {
	// 			console.log('disabled', disabled, props.disabled)
	// 			return true
	// 		}
	// 		if (medInfoType != props.medInfoType) {
	// 			console.log('medInfoType', medInfoType, props.medInfo.medInfoType)
	// 			return true
	// 		}

	// 		if (value !== props.medInfo.value) {
	// 			return true
	// 		}
	// 	} catch (e) {
	// 		return false
	// 	}
	// 	return false
	// }

	handleCopy = async () => {
		const { parentId, medInfo, onGenerateMedInfo, onInsertMedInfo, medInfoIndex } = this.props
		const created = await onGenerateMedInfo(
			{ copyItem: JSON.parse(JSON.stringify(medInfo)) },
			parentId
		)
		onInsertMedInfo(created, medInfoIndex)
	}

	handleEdit = () => {
		const { medInfo, medInfoIndex, onOpenEditModal } = this.props

		onOpenEditModal({
			medInfoType: medInfo.medInfoType,
			medInfoIndex,
		})
	}

	handleDelete = () => {
		const { medInfoIndex, onDeleteMedInfo } = this.props
		onDeleteMedInfo(medInfoIndex)
	}

	handleResize = (e, { value }) => {
		const {
			medInfo: { medInfoType },
			onUpdateMedInfo,
			medInfoIndex,
		} = this.props

		onUpdateMedInfo(
			{
				medInfoType: {
					...medInfoType,
					size: value,
				},
			},
			medInfoIndex
		)
	}

	handleClickSettingMenu = (e, { value }) => {
		if (value === 'delete') {
			this.handleDelete()
		} else if (value === 'copy') {
			this.handleCopy()
		} else if (value === 'edit') {
			this.handleEdit()
		}
	}

	render() {
		const {
			medInfo,
			droppedItem,
			connectDropTarget,
			connectDragSource,
			connectDragPreview,
			medInfoId,
			isDragging,
			isOver,
			hideDisabled,
			templateMode,
		} = this.props

		const { value, medInfoType, disabled } = medInfo
		const { inputType } = medInfoType

		if (disabled === true && hideDisabled === true && templateMode !== true) {
			return <></>
		}

		let view
		if (inputType === 'BLOCK') {
			view = (
				<DataFieldGroup
					{...this.props}
					{...medInfo}
					{...medInfoType}
					value={value}
					// connectDragSource={connectDragSource}
					onClickSettingMenu={this.handleClickSettingMenu}
					onResize={this.handleResize}
				/>
			)
		} else if (inputType === 'GRID') {
			view = (
				<DataFieldGrid
					{...this.props}
					{...medInfo}
					{...medInfoType}
					connectDragSource={connectDragSource}
					onClickSettingMenu={this.handleClickSettingMenu}
					onResize={this.handleResize}
				/>
			)
		} else {
			view = (
				<DataField
					{...this.props}
					{...medInfo}
					{...medInfoType}
					// connectDragSource={connectDragSource}
					value={value}
					onClickSettingMenu={this.handleClickSettingMenu}
					onResize={this.handleResize}
				/>
			)
		}

		return connectDragSource(
			connectDropTarget(
				<div
					style={{
						background:
							droppedItem && droppedItem.medInfoId === medInfoId
								? 'rgba(0, 0, 0, 0.05)'
								: isOver && 'rgba(22,83,183,0.2)',
					}}
				>
					{
						<div
							style={{
								opacity: isDragging ? 0 : 1,
							}}
						>
							{view}
						</div>
					}
				</div>
			)
		)
	}
}

export default DropTarget(DRAG_ITEM_TYPES.DATA_FIELD, dataFieldTarget, collectTarget)(
	DragSource(DRAG_ITEM_TYPES.DATA_FIELD, dataFieldSource, collectSource)(DataFieldBox)
)
