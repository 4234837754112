import React from 'react'
import PropTypes from 'prop-types'
import LinkColored from '../common/basic/LinkColored'
import { COLOR_BLUE } from '../../constants/styles'
import { replaceParams } from '../../libs/routeHelper'
import { ROUTE_CASE_TAG } from '../../constants/routes'

const CaseTag = ({ style, tagId, text }) => {
	return (
		<LinkColored
			style={{
				color: COLOR_BLUE,
				display: 'inline-block',
				fontSize: 13,
				marginRight: 10,
				...style,
			}}
			to={replaceParams(ROUTE_CASE_TAG, { tagId })}
			key={`case-tag-${tagId}`}
		>
			{text}
		</LinkColored>
	)
}

CaseTag.propTypes = {
	// eslint-disable-next-line prettier/prettier
    style: PropTypes.object,
	tagId: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
}

export default CaseTag
