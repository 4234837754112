import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
	COLOR_BACKGROUND_GRAY,
	COLOR_TEXT_LIGHTGRAY,
	COLOR_PRIMARY,
	COLOR_BORDER_GRAY,
	COLOR_BACKGROUND_DARK_GRAY,
	COLOR_BORDER_INPUT_ERROR,
	COLOR_BACKGROUND_INPUT_ERROR,
} from '../../../constants/styles'
import DescriptionText from './DescriptionText'
import { InputErrorMessage } from './StyledTexts'

/**
 * 버튼 여러개 붙어있는 것을 선택하는 인풋 방식
 */
const SegmentToggle = ({ error, fluid, active, style, onChange, itemStyle, items, disabled }) => {
	const width = fluid === true ? `${100 / items.length}%` : 'auto'
	const activeItem = items.find(i => i.value === active)
	return (
		<div style={style}>
			{items.map(item => {
				return (
					<SegmentToggleItem
						key={item.key || item.value}
						active={item.value === active}
						style={itemStyle}
						onChange={disabled === true ? () => {} : onChange}
						width={width}
						error={error}
						disabled={disabled}
						{...item}
					/>
				)
			})}
			{activeItem && activeItem.description && (
				<DescriptionText>{activeItem.description}</DescriptionText>
			)}
			{error && error.message && <InputErrorMessage>* {error.message}</InputErrorMessage>}
		</div>
	)
}

SegmentToggle.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		})
	).isRequired,
	style: PropTypes.shape({}),
	active: PropTypes.string,
	itemStyle: PropTypes.shape({}),
	onChange: PropTypes.func.isRequired,
	error: PropTypes.shape({
		message: PropTypes.string,
	}),
	fluid: PropTypes.bool,
	disabled: PropTypes.bool,
}

SegmentToggle.defaultProps = {
	style: null,
	itemStyle: null,
	error: null,
	fluid: false,
	disabled: false,
}

export default SegmentToggle

/**
 * 개별 아이템
 */
const SegmentToggleItem = ({ error, width, active, style, value, text, onChange, disabled }) => {
	return (
		<SegmentToggleItemBox
			active={active}
			error={error}
			style={{ width, ...style }}
			onClick={() => {
				if (active !== true) {
					onChange(value)
				}
			}}
			disabled={disabled}
		>
			{text}
		</SegmentToggleItemBox>
	)
}

SegmentToggleItem.propTypes = {
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	active: PropTypes.bool.isRequired,
	style: PropTypes.object,
	value: PropTypes.string.isRequired,
	error: PropTypes.object,
	text: PropTypes.string,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
}

SegmentToggleItem.defaultProps = {
	width: null,
	style: null,
}

/**
 * Styles
 */

const SegmentToggleItemBox = styled.div`
	display: inline-block;
	padding: 10px;
	text-align: center;
	border-radius: 2px;
	cursor: ${props => (props.disabled === true ? 'not-allowed' : 'pointer')};
	background: ${props => {
		if (props.active === true) return 'white'
		else if (props.error != null) return COLOR_BACKGROUND_INPUT_ERROR
		else return COLOR_BACKGROUND_GRAY
	}};
	color: ${props => (props.active === true ? COLOR_PRIMARY : COLOR_TEXT_LIGHTGRAY)};
	border: 1px solid
		${props => {
			if (props.active === true) return COLOR_PRIMARY
			else if (props.error != null) return COLOR_BORDER_INPUT_ERROR
			else if (props.disabled === true) return COLOR_BACKGROUND_DARK_GRAY
			else return COLOR_BORDER_GRAY
		}};

	:hover {
		background: ${props =>
			props.disabled !== true &&
			(props.active === true ? COLOR_BACKGROUND_GRAY : COLOR_BACKGROUND_DARK_GRAY)};
	}
`
