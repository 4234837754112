import React from 'react'
import PropTypes from 'prop-types'

import { COLOR_TEXT_LIGHTGRAY } from '../../../constants/styles'
import { UISize } from '../../../enum'

const EmptyResult = ({ message, size }) => {
	let fontSize = 20
	if (size === UISize.LARGE) {
		fontSize = 18
	} else if (size === UISize.SMALL) {
		fontSize = 16
	} else if (size === UISize.TINY) {
		fontSize = 14
	} else if (size === UISize.MINI) {
		fontSize = 12
	}

	return <div style={{ textAlign: 'center', fontSize, color: COLOR_TEXT_LIGHTGRAY }}>{message}</div>
}

EmptyResult.propTypes = {
	message: PropTypes.string,
	size: PropTypes.string,
}
EmptyResult.defaultProps = {
	message: null,
	size: UISize.SMALL,
}

export default EmptyResult
