import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Card, Label } from 'semantic-ui-react'
import moment from 'moment'
import 'moment/locale/ko'

import { RecordChangeType } from '../../../enum'
import { replaceParams } from '../../../libs/routeHelper'
import {
	ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_HISTORY_DETAIL,
	ROUTE_PROJECT_DETAIL_DATA_HISTORY_DETAIL,
} from '../../../constants/routes'

moment.locale('ko')

const styles = {
	container: {
		width: '100%',
		padding: 10,
	},
	wrap: {
		display: 'table',
	},
	date: {
		width: 200,
		display: 'inline-block',
		color: '#999',
	},
	code: {
		width: 200,
		display: 'inline-block',
	},
	patient: {
		width: 130,
		display: 'inline-block',
		color: '#555',
		fontWeight: '600',
	},
	doctor: {
		width: 80,
		display: 'inline-block',
		color: '#555',
	},
	right: {
		width: 150,
		float: 'right',
		display: 'inline-block',
	},
	right2: {
		width: 120,
		verticalAlign: 'middle',
		display: 'table-cell',
	},
	message: {
		fontSize: 17,
		fontWeight: '600',
		color: '#333',
	},
	content: {
		display: 'table-cell',
	},
	titleLabel: {
		marginRight: 15,
	},
}

/**
 * 기록 수정 내역 항목
 */
const ResearchRecordChangeItem = props => {
	const {
		researchId,
		message,
		createdAt,
		templateId,
		versionCode,
		type,
		user,
		template,
		itemType,
		patientId,
		recordId,
	} = props
	const typeInfo = RecordChangeType[type]

	return (
		<Card
			as={Link}
			to={
				template
					? replaceParams(ROUTE_PROJECT_DETAIL_TEMPLATE_DETAIL_HISTORY_DETAIL, {
							id: researchId,
							menu: itemType,
							subId: templateId,
							subsubId: versionCode,
					  })
					: replaceParams(ROUTE_PROJECT_DETAIL_DATA_HISTORY_DETAIL, {
							id: researchId,
							subId: patientId,
							recordId: recordId,
							historyId: versionCode,
					  })
			}
			style={styles.container}
		>
			<div style={styles.wrap}>
				<div style={styles.content}>
					<div className="hover-underline" style={styles.message}>
						<Label style={styles.titleLabel} color={typeInfo.color}>
							{typeInfo.text}
						</Label>
						{type === RecordChangeType.INIT.key ? (
							<i style={{ fontSize: 15, fontWeight: '400', color: '#777' }}>(최초 등록)</i>
						) : (
							message
						)}
					</div>
					<div style={styles.date}>{moment(createdAt).format('YYYY년 MM월 DD일 LT')}</div>
					<div style={styles.doctor}>
						by&nbsp;<b>{user && user.name}</b>
					</div>
				</div>
				{/* {template  && <div style={styles.right2}>
          <Label style={{width: 95, textAlign: 'center'}} basic color="black">{
            `버전 ${versionCode}`
          }
          </Label>
        </div>
        } */}
			</div>
		</Card>
	)
}

ResearchRecordChangeItem.propTypes = {
	type: PropTypes.string.isRequired,
	createdAt: PropTypes.string.isRequired,
	versionCode: PropTypes.number.isRequired,
	user: PropTypes.object.isRequired,
	researchId: PropTypes.number.isRequired,
	templateId: PropTypes.number,
	message: PropTypes.string.isRequired,
	template: PropTypes.bool,
	patientId: PropTypes.number,
	recordId: PropTypes.number,
	itemType: PropTypes.string,
}

export default ResearchRecordChangeItem

// import React from 'react'
// import PropTypes from 'prop-types'
// import { Image, Card, Icon, Label } from 'semantic-ui-react'
// import moment from 'moment'
// import 'moment/locale/ko'

// import { RecordChangeType} from '../../enum'

// moment.locale('ko')

// const styles = {
//   container: {
//     width: '100%',
//     padding: 10,
//   },
//   wrap: {
//     display: 'table'
//   },
//   date: {
//     width: 200,
//     display: 'inline-block'
//   },
//   code: {
//     width: 200,
//     display: 'inline-block'
//   },
//   patient: {
//     width: 130,
//     display: 'inline-block',
//     color: '#555',
//     fontWeight: '600'
//   },
//   doctor: {
//     width: 80,
//     display: 'inline-block',
//     color: '#555'
//   },
//   right: {
//     width:150,
//     float: 'right',
//     display: 'inline-block'
//   },
//   right2: {
//     width:120,
//     float: 'right',
//     display: 'inline-block',
//   },
// }

// const ResearchRecordChangeItem = props => {
//   const { type, createdAt, recordCode, code, user, isBlinded, researchId } = props
//   const typeInfo = RecordChangeType[type]
//   return (
//     <Card href={`/researches/${researchId}/data/${recordCode}/history/${code}`} style={styles.container}>

//       <div style={styles.wrap}>

//         <div style={styles.date}>
//           {moment(createdAt).format('YYYY년 MM월 DD일 LT')}
//         </div>
//         <div style={styles.doctor}>
//           by&nbsp;<b>{isBlinded ?
//             user.researcherInfo.code
//             : user.name
//           }</b>
//         </div>
//         <Label color={typeInfo.color}>{typeInfo.text}</Label>
//         <div style={styles.right2}>
//           <Label basic color="black">{code}</Label>
//         </div>
//       </div>
//     </Card>
//   )
// }

// ResearchRecordChangeItem.propTypes = {
//   type: PropTypes.string.isRequired,
//   createdAt: PropTypes.string.isRequired,
//   recordCode: PropTypes.string.isRequired,
//   code: PropTypes.string.isRequired,
//   user: PropTypes.object.isRequired,
//   isBlinded: PropTypes.bool.isRequired,
//   researchId: PropTypes.number.isRequired,
// }

// export default ResearchRecordChangeItem
