/* eslint-disable no-restricted-globals */
import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { CaseListType } from '../../enum'
import CaseFeed from './CaseFeed'
import Page404 from '../../containers/Page404'

const GroupCases = props => {
	const { groupId } = useParams()
	const groupIdNum = parseInt(groupId, 10)
	if (isNaN(groupIdNum)) {
		return <Page404 />
	}
	return (
		<div>
			<CaseFeed type={CaseListType.GROUP.value} groupId={groupIdNum} />
		</div>
	)
}

GroupCases.propTypes = {}

export default GroupCases
