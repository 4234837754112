import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { useLazyQuery, useQuery } from 'react-apollo'
import { GET_DONGUIBOGAM_SUB_ITEMS } from '../../graphql/queries/library'
import { COLOR_TEXT_GRAY } from '../../constants/styles'
import { ROUTE_CASE_TAG } from '../../constants/routes'
import { replaceParams } from '../../libs/routeHelper'

const LineItem = ({ id, text, subItemCount, caseCount, subCaseSum, caseTagId }) => {
	const [isToggleOpen, setIsToggleOpen] = useState(false)

	const [sendQuery, { data: { donguibogamLineSubItems } = {} }] = useLazyQuery(
		GET_DONGUIBOGAM_SUB_ITEMS,
		{
			variables: {
				id,
			},
		}
	)

	return (
		<div>
			<LineItemBox
				to={caseTagId != null && replaceParams(ROUTE_CASE_TAG, { tagId: caseTagId })}
				style={{ fontSize: 16, marginBottom: 7 }}
				onClick={() => {
					if (isToggleOpen === false) sendQuery()
					setIsToggleOpen(!isToggleOpen)
				}}
			>
				{subItemCount > 0 ? (
					<Icon name={`triangle ${isToggleOpen === true ? 'down' : 'right'}`} />
				) : (
					<Icon name="" />
				)}
				{text}
				{(subCaseSum > 0 || caseCount > 0) && (
					<div
						style={{
							display: 'inline-block',
							marginLeft: 10,
							fontSize: 13,
							color: COLOR_TEXT_GRAY,
						}}
					>
						[{subCaseSum} ({caseCount})]
					</div>
				)}
			</LineItemBox>
			{isToggleOpen === true && donguibogamLineSubItems != null && (
				<div style={{ paddingLeft: 30 }}>
					{donguibogamLineSubItems.map(item => (
						<LineItem key={item.key} {...item} />
					))}
				</div>
			)}
		</div>
	)
}

LineItem.propTypes = {
	id: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	subItemCount: PropTypes.number.isRequired,
}

export default LineItem

const LineItemBox = styled(Link)`
	cursor: pointer;
	display: block;
	:hover {
		opacity: 0.7;
	}
`
