import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from 'react-apollo'
import { GET_PATIENT_SURVEY_INFO } from '../graphql/queries/survey'
import { SET_PATIENT_SURVEY_STATUS } from '../graphql/mutations/app'
import LoadingView from '../components/common/loader'
import PatientSurvey from './PatientSurvey'
import { ADD_PATIENT_SURVEY_AGREEMENT } from '../graphql/mutations/survey'
import Page404 from './Page404'

export default function Survey() {
	const { code } = useParams()
	const [setPatientSurveyStatus] = useMutation(SET_PATIENT_SURVEY_STATUS)
	const [addPatientSurveyAgreement] = useMutation(ADD_PATIENT_SURVEY_AGREEMENT)
	const [loadingSetStatus, setLoading] = useState(true)
	const { data, loading, error } = useQuery(GET_PATIENT_SURVEY_INFO, {
		variables: {
			secretCode: code,
		},
		onCompleted: ({ getPatientSurveyInfo: { record, patientId } = {} } = {}) => {
			if (record != null) {
				setPatientSurveyStatus({
					variables: {
						input: {
							recordId: record.id,
							patientId: patientId,
							medInfos: record.medInfos,
							templateName: record.template.title,
							templateDescription: record.template.description,
							researchId: record.researches[0].id,
						},
						isPatient: true,
					},
				}).then(() => {
					setLoading(false)
				})
			} else {
				setLoading(false)
			}
		},
		onError: () => {
			setLoading(false)
		},
	})

	if (loading === true || loadingSetStatus === true) return <LoadingView />
	else if (error != null) return <Page404 />
	const {
		getPatientSurveyInfo: {
			record: { researches, creator },
			patientId,
		},
	} = data || {}
	const onSubmitSign = ({ agreementSignature, agreementType, agreedTermId }) => {
		addPatientSurveyAgreement({
			variables: {
				data: {
					agreementType,
					agreementSignature,
					agreedTermId,
					researchId: researches[0].id,
					patientId,
					creatorId: creator.id,
				},
			},
		})
	}

	return <PatientSurvey isPatient secretCode={code} onSubmitSign={onSubmitSign} />
}
