import {
	ROUTE_MY_PROJECTS,
	ROUTE_MY_PATIENTS,
	ROUTE_PROJECT_DETAIL_STATS_DEFAULT,
	ROUTE_PROJECT_DETAIL_STATS_RELATIONS,
	ROUTE_PROJECT_DETAIL_STATS_SUBJECTS,
	ROUTE_MY_STATISTICS,
	ROUTE_STATISTICS_ANALYZE,
	ROUTE_NEW_PROJECT,
} from './routes'

export const PAGE_TITLE_PROJECT_OVERVIEW = '개요'
export const PAGE_TITLE_PROJECT_CALENDAR = '조사 일정'
export const PAGE_TITLE_PROJECT_STATISTICS = '통계'
export const PAGE_TITLE_PROJECT_SETTINGS = '설정'
export const PAGE_TITLE_PROJECT_SUBJECTS = '연구 대상자'
export const PAGE_TITLE_PROJECT_DATA = '데이터'
export const PAGE_TITLE_PROJECT_DOCTORS = '참여의료인'
export const PAGE_TITLE_MY_PROJECTS = '내 프로젝트'
export const PAGE_TITLE_NEW_PROJECT = '새로운 프로젝트'
export const PAGE_TITLE_MY_PATIENTS = '내 데이터'
export const PAGE_TITLE_MY_STATISTICS = '통계 - 대시보드'
export const PAGE_TITLE_MY_STATISTICS_ANALYSIS = '통계 - 분석'

export const PAGE_TITLE_PROJECT_STATISTICS_DASHBOARD = '현황판'
export const PAGE_TITLE_PROJECT_STATISTICS_RELATIONS = '분석'
export const PAGE_TITLE_PROJECT_STATISTICS_SUBJECTS = '대상자'

export const PAGES_BASIC = [
	{
		route: ROUTE_MY_PROJECTS,
		title: PAGE_TITLE_MY_PROJECTS,
	},
	{
		route: ROUTE_MY_PATIENTS,
		title: PAGE_TITLE_MY_PATIENTS,
	},
	{
		route: ROUTE_MY_STATISTICS,
		title: PAGE_TITLE_MY_STATISTICS,
	},
	{
		route: ROUTE_STATISTICS_ANALYZE,
		title: PAGE_TITLE_MY_STATISTICS_ANALYSIS,
	},
	{
		route: ROUTE_NEW_PROJECT,
		title: PAGE_TITLE_NEW_PROJECT,
	},
]

export const PAGES_PROJECT_SIMPLE_TITLES = [
	{
		key: 'ROUTE_PROJECT_STATS_DEFAULT',
		path: ROUTE_PROJECT_DETAIL_STATS_DEFAULT,
		title: PAGE_TITLE_PROJECT_STATISTICS,
		subTitle: PAGE_TITLE_PROJECT_STATISTICS_DASHBOARD,
	},
	{
		key: 'ROUTE_PROJECT_STATS_RELATIOINS',
		path: ROUTE_PROJECT_DETAIL_STATS_RELATIONS,
		title: PAGE_TITLE_PROJECT_STATISTICS,
		subTitle: PAGE_TITLE_PROJECT_STATISTICS_RELATIONS,
	},
	{
		key: 'ROUTE_PROJECT_STATS_SUBJECTS',
		path: ROUTE_PROJECT_DETAIL_STATS_SUBJECTS,
		title: PAGE_TITLE_PROJECT_STATISTICS,
		subTitle: PAGE_TITLE_PROJECT_STATISTICS_SUBJECTS,
	},
]
