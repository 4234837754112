import React from 'react'
import PropTypes from 'prop-types'
import StyledSVG from './StyledSVG'
import DownIcon from '../../../assets/images/icons/down.svg'

/**
 * 아코디언 열림/닫힘 화살표
 */
const Arrow = ({ isOpen }) => {
	return (
		<StyledSVG
			className="section-arrow"
			src={DownIcon}
			style={{
				position: 'absolute',
				right: 50,
				top: isOpen === true ? 15 : 20,
				transform: isOpen === true ? 'rotate(180deg)' : 'none',
			}}
		/>
	)
}

Arrow.propTypes = {
	isOpen: PropTypes.bool.isRequired,
}

export default Arrow
