import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// eslint-disable-next-line import/no-cycle
import PatientSurveyQuestion from '../PatientSurveyQuestion'

export default function PatientSurveyBlock({ medInfo }) {
	const { children } = medInfo
	return (
		<div>
			{children.map(question => {
				const { medInfoType } = question
				const { name } = medInfoType
				return (
					<QuestionWrapper key={`${question.id}`}>
						<div className="title">{name}</div>
						<PatientSurveyQuestion medInfo={question} inBlock />
					</QuestionWrapper>
				)
			})}
		</div>
	)
}

PatientSurveyBlock.propTypes = {
	medInfo: PropTypes.object,
}

const QuestionWrapper = styled.div`
	border-radius: 8px;
	background: linear-gradient(124deg, #f3f3f3, #fafafa 100%);
	.title {
		background: linear-gradient(95deg, #87c8d7, #88c7d6);
		color: #ffffff;
		padding: 12px 32px;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}
	margin: 20px 0px;
`
