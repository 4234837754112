import React, { useState, useEffect, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal, Button, Segment, Checkbox, Table } from 'semantic-ui-react'
import CanvasDraw from 'react-canvas-draw'
import ReactMarkdown from 'react-markdown'
import { scroller } from 'react-scroll'
import { useQuery, useMutation } from 'react-apollo'
import { ComponentLabel } from '../common/basic/StyledTexts'
import { CenteredModalActions } from '../common/basic/StyledBox'
import { GET_PATIENT_SIGN } from '../../graphql/queries/app'
import { UPDATE_PATIENT_SIGN } from '../../graphql/mutations/app'
import T2 from '../common/basic/T2'
import { ResearchAgreementCheckType } from '../../enum'
import { GET_RESEARCH_TERM } from '../../graphql/queries/research'
import { PATIENT_INFO_FIELDS } from '../../constants'

/**
 * 환자 서명 처리 모달
 */
const PatientSignModal = ({ onSubmit, noClose, researchId }) => {
	const {
		data: { patientSign },
	} = useQuery(GET_PATIENT_SIGN)
	const { data: { researchTerm } = {} } = useQuery(GET_RESEARCH_TERM, { variables: { researchId } })

	const [updateState] = useMutation(UPDATE_PATIENT_SIGN)
	const { didAgree, isOpen } = patientSign || {}
	const [isSignVisible, setSignVisible] = useState(didAgree)
	const [isSigned, setSigned] = useState(false)
	const signRef = useRef(null)

	useEffect(() => {
		setTimeout(() => setSignVisible(didAgree), 300)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [didAgree])

	const onUpdate = (key, value) => {
		updateState({
			variables: {
				[key]: value,
			},
		})
	}

	const handleSubmit = async variables => {
		await updateState({
			variables: {
				isOpen: false,
				didAgree: false,
			},
		})

		onSubmit(variables)
	}

	const patientBasicInfo = useMemo(() => {
		if (researchTerm == null || researchTerm.patientBasicInfo == null) {
			return []
		}
		return researchTerm.patientBasicInfo.split('|').map(item => PATIENT_INFO_FIELDS[item].text)
	}, [researchTerm])

	useEffect(() => {
		if (isSignVisible) {
			scroller.scrollTo('signCanvas', {
				duration: 800,
				delay: 0,
				smooth: true,
				containerId: 'modalContent',
			})
		}
	}, [isSignVisible])

	return (
		<Modal
			open={isOpen}
			onClose={() => {
				onUpdate('isOpen', false)
				setSigned(false)
			}}
			closeOnDimmerClick={!noClose}
		>
			<Modal.Header>개인정보 제공 동의 서명</Modal.Header>
			<Modal.Content scrolling id="modalContent">
				<span>
					* 스크롤을 내려 아래 내용을 반드시 모두 읽어보시고 하단에 동의여부를 체크해 주세요.
				</span>
				<Segment>
					<Markdown>
						<ReactMarkdown source={researchTerm != null ? researchTerm.text : ''} renderers={{}} />
					</Markdown>
					<T2>수집 정보</T2>
					<Table textAlign="center" celled fluid unstackable>
						<Table.Header textAlign="center">
							<Table.Row>
								<Table.HeaderCell>수집목적</Table.HeaderCell>
								<Table.HeaderCell>수집항목</Table.HeaderCell>
								<Table.HeaderCell>보유/이용기간</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							<Table.Row>
								<Table.Cell>진료 및 연구 시 활용</Table.Cell>
								<Table.Cell>차트 번호{patientBasicInfo.map(item => `, ${item}`)}</Table.Cell>
								<Table.Cell>최종 조사 후 3년</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>진료 및 연구 시 활용</Table.Cell>
								<Table.Cell>설문 조사 응답 내용</Table.Cell>
								<Table.Cell>최종 조사 3년 후 비식별 처리하여 보관</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
					<T2>개인정보 제3자 제공내역</T2>
					<Table textAlign="center" celled unstackable>
						<Table.Header textAlign="center">
							<Table.Row>
								<Table.HeaderCell>목적</Table.HeaderCell>
								<Table.HeaderCell>기관</Table.HeaderCell>
								<Table.HeaderCell>제공항목</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							<Table.Row>
								<Table.Cell>(주) 테서</Table.Cell>
								<Table.Cell>설문조사 데이터 위탁처리</Table.Cell>
								<Table.Cell>
									차트번호{patientBasicInfo.map(item => `, ${item}`)} 진료기관, 설문 응답 내용
								</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
				</Segment>
				<div>
					<LargeCheckbox
						onChange={() => {
							onUpdate('didAgree', !didAgree)
						}}
						checked={didAgree}
						label="위 약관에 동의합니다."
					/>
				</div>
				{didAgree === true && (
					<SignContainer isVisible={isSignVisible} id="signCanvas">
						<ComponentLabel>하단 서명란에 서명 후 동의 버튼을 눌러주세요.</ComponentLabel>
						<Button
							className="clear"
							onClick={() => {
								signRef.current.clear()
								setSigned(false)
							}}
						>
							지우기
						</Button>
						<SignCanvas
							brushRadius={4}
							brushColor="#333"
							ref={signRef}
							onChange={() => {
								setSigned(true)
							}}
							lazyRadius={5}
						/>
					</SignContainer>
				)}
			</Modal.Content>
			{didAgree === true && (
				<CenteredModalActions>
					<Button
						primary
						disabled={!isSigned}
						onClick={() =>
							handleSubmit({
								agreementSignature: signRef.current.getSaveData(),
								agreementType: ResearchAgreementCheckType.SIGNATURE.value,
								agreedTermId: researchTerm != null ? researchTerm.id : null,
							})
						}
					>
						개인정보 제공에 동의합니다
					</Button>
				</CenteredModalActions>
			)}
		</Modal>
	)
}

PatientSignModal.propTypes = {
	onSubmit: PropTypes.func,
	noClose: PropTypes.func,
	researchId: PropTypes.number,
}

export default PatientSignModal

const SignContainer = styled.div`
	position: relative;
	margin-top: 10px;
	transition: opacity 0.3s;
	opacity: ${props => (props.isVisible === true ? 1 : 0)};
	.clear {
		background-color: white;
		position: absolute;
		top: 24px;
		right: 0px;
		font-size: 12px;
		border-radius: 15px;
		z-index: 16;
		margin: 10px;
	}
`

const SignCanvas = styled(CanvasDraw)`
	width: 100% !important;
	position: relative;
	height: 200px !important;
	border: ${props => props.theme.BORDER_GRAY};
`
const LargeCheckbox = styled(Checkbox)`
	transform: scale(1.5);
	margin-left: 40px !important;
	margin-top: 20px !important;
	margin-bottom: 20px !important;
`

const Markdown = styled.div`
	& > h1 {
		font-size: 32px;
	}
	& > h2 {
		font-size: 28px;
	}
	& > h3 {
		font-size: 24px;
	}
	& > h4 {
		font-size: 20px;
	}
	& > p {
		font-size: 1.2em;
		line-height: 1.5;
	}
	& > hr {
		border: none;
		height: 1px;
		color: ${props => props.theme.COLOR_BORDER_GRAY}; /* IE */
		background-color: ${props =>
			props.theme.COLOR_BORDER_GRAY}; /* 크롬, 모질라 등, 기타 브라우저 */
		margin-bottom: 28px;
	}
	margin-bottom: 28px;
`
