import { useState } from 'react'

const useStateInput = initialValue => {
	const [value, setValue] = useState(initialValue)
	return [
		value,
		(e, { value }) => {
			setValue(value)
		},
	]
}

export default useStateInput
