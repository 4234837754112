import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Table } from 'semantic-ui-react'
import 'moment/locale/ko'

import { getAgeRange, truncateString } from '../../../libs/valueHelper'
import PatientCode from '../../common/PatientCode'

moment.locale('ko')

/**
 * 환자 목록 항목
 * 차트 번호 , 이름, 성별, 나이, 연구, 요약, 최근방문 등
 */
const PatientListItem = React.memo(
	({
		name,
		sexMale,
		birthDate,
		patientCode,
		updatedAt,
		patientResearches,
		isHashed,
		summary,
		research,
	}) => {
		// const {} = research
		let currentPatientResearch, hashPatient
		if (research != null) {
			currentPatientResearch = patientResearches.find(pr => pr.research.id === research.id)
		}

		if (research != null) {
			;({ hashPatient } = research)
		}

		return (
			<React.Fragment>
				<Table.Cell textAlign="left" style={{ width: 130 }}>
					<div style={styles.patientCode}># {patientCode}</div>
				</Table.Cell>
				{hashPatient !== true && (
					<Table.Cell style={{ width: 80 }}>
						<div style={styles.name}>{isHashed === true ? '###' : name}&nbsp;</div>
					</Table.Cell>
				)}
				<Table.Cell style={{ width: 50 }}>
					<span style={styles.subInfo}>{sexMale ? '남' : '여'}</span>
				</Table.Cell>
				<Table.Cell style={{ width: 120 }}>
					<span style={styles.subInfo}>{getAgeRange(birthDate)}</span>
				</Table.Cell>
				<Table.Cell textAlign="left" style={{ minWidth: 150 }}>
					{research != null ? (
						<PatientResearchInfo
							{...currentPatientResearch}
							researchTitle={research.title}
							summary={summary}
						/>
					) : patientResearches && patientResearches.length > 0 ? (
						<div style={styles.researcherInfo}>
							<b style={{ fontSize: '14px', cursor: 'pointer', color: '#0d8eff', height: '18px' }}>
								{truncateString(patientResearches[0].research.title, 20)}
							</b>
							<div style={styles.role}> {truncateString(summary, 50)}</div>
							{patientResearches.length > 1 && <span>외 {patientResearches.length - 1} 건</span>}
						</div>
					) : (
						<div style={styles.role}> {truncateString(summary, 50)}</div>
					)}
					{/* // summary */}
				</Table.Cell>
				<Table.Cell style={{ width: 200 }}>
					<span style={styles.role}>{moment(updatedAt).calendar()}</span>
				</Table.Cell>
			</React.Fragment>
		)
	}
)

PatientListItem.propTypes = {
	id: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	sexMale: PropTypes.bool.isRequired,
	birthDate: PropTypes.string.isRequired,
	patientCode: PropTypes.string,
	updatedAt: PropTypes.string.isRequired,
	isHashed: PropTypes.bool,
	patientResearches: PropTypes.arrayOf(PropTypes.object),
	research: PropTypes.shape({
		id: PropTypes.number,
		title: PropTypes.string,
	}),
	isTest: PropTypes.bool,
	summary: PropTypes.string,
}

PatientListItem.defaultProps = {
	research: null,
	patientResearches: null,
	summary: null,
	isHashed: false,
}

export default PatientListItem

/**
 * 환자 연구정보
 */
const PatientResearchInfo = ({ code, summary }) => {
	return (
		<div style={styles.researcher}>
			<div style={styles.patientResearchCode}>
				<PatientCode code={code} />
			</div>
			<div style={styles.patientResearchDesc}>{truncateString(summary, 50)}</div>
		</div>
	)
}

PatientResearchInfo.propTypes = {
	code: PropTypes.string,
	summary: PropTypes.string,
}

PatientResearchInfo.defaultProps = {
	code: null,
	summary: null,
}

const styles = {
	profileImage: {
		width: 30,
		height: 30,
		borderRadius: 50,
	},
	code: {
		fontSize: '14px',

		display: 'inline-block',
		fontWeight: '600',
	},
	subInfo: {
		fontSize: 13,
		opacity: '0.62',
		width: '61px',
		height: '18px',
		color: '#000000',
	},
	role: {
		fontSize: 14,
		color: '#999',
	},
	subContainer: {
		display: 'inline-block',
	},
	settingsBtn: {
		marginLeft: 12,
	},
	rightLabel: {
		display: 'inline-block',
		marginRight: 10,
		marginTop: 10,
		fontSize: 12,
		fontWeight: '600',
		color: '#555',
	},
	researcherInfo: {
		display: 'table-cell',
		verticalAlign: 'middle',
		fontSize: 14,
	},
	researcher: {
		whiteSpace: 'nowrap',
	},
	dateInfo: {
		display: 'table-cell',
		verticalAlign: 'middle',
		textAlign: 'right',
	},
	blinded: {
		fontSize: 14,
		color: '#999',
		width: 60,
		textAlign: 'center',
		display: 'inline-block',
	},
	description: {
		marginTop: 10,
		marginBottom: 10,
	},
	noRight: {
		fontSize: 14,
		color: '#999',
		width: 60,
		textAlign: 'center',
		display: 'inline-block',
	},
	label: {
		fontSize: 11,
		padding: 4,
		fontWeight: '400',
	},
	patientCode: {
		color: '#333',
		paddingLeft: 10,
		fontWeight: 600,
	},
	patientResearchCode: {
		display: 'inline-block',
		width: 100,
		marginRight: 20,
	},
	patientResearchDesc: {
		display: 'inline-block',
		color: '#444',
	},
}
