import React, { useReducer, useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery, useApolloClient } from 'react-apollo'
import { Modal, Button } from 'semantic-ui-react'

import moment from 'moment'
import gql from 'graphql-tag'
import {
	PATIENT_INFO_FIELDS,
	DEFAULT_PATIENTS_PER_PAGE,
	DEFAULT_RESEARCH,
	PATIENT_BASIC_REQUIRED_FIELDS,
} from '../../../constants'
import ERROR_TYPES from '../../../errorTypes'
import { validatePatient } from '../../../libs/simpleValidations'
import MiniErrorView from '../../common/error/MiniErrorView'
import { InlineLoader } from '../../common/loader/InlineLoader'
import { ADD_PATIENT } from '../../../graphql/mutations/patient'
import { GET_USER_ACTIVE_RESEARCHES } from '../../../graphql/queries/research'
import { GET_DOCTOR_PATIENTS } from '../../../graphql/queries/patient'
import ResearchSelectView from '../../common/ResearchSelectView'
import PatientForm from '../PatientForm'
import LightH3 from '../../common/basic/LightH3'
import { ResearchSamplingPlan, ResearchAgreementCheckType } from '../../../enum'
import { ADD_RECORD } from '../../../graphql/mutations/record'
import { GET_RESEARCH_RECORD_TEMPLATE_INFOS } from '../../../graphql/queries/record'
import PatientAgreementCheck from './PatientAgreementCheck'
import {
	AGREEMENT_NOT_REQUIRED_MESSAGE,
	AGREEMENT_WARNING_MESSAGE,
	AGREEMENT_ACTUAL_MESSAGE,
	AGREEMENT_CHECK_MESSAGE,
} from '../../../lang'
import { FadeInContainer } from '../../common/basic/StyledBox'
import useAddPatient from '../../../hooks/useAddPatient'

/**
 * 환자 등록 모달
 */
const PatientAddModal = ({ researchTabId, open, onClose, onOpenSingleRecordModal }) => {
	const history = useHistory()
	const client = useApolloClient()
	const {
		patientInfo,
		resetPatientInfo,
		researchId,
		hasAgreed,
		handleChange,
		handleChangeBirthDate,
		toggleSelectResearch,
		researchLoading,
		researchError,
		agreementMessage,
		hasCheckbox,
		researchFields,
		selectedResearch,
		setHasAgreed,
		researches,
		researchItems,
	} = useAddPatient({ defaultResearchId: researchTabId })

	const [addRecord] = useMutation(ADD_RECORD, {
		update(
			_,
			{
				data: {
					addRecord: { id: recordId },
				},
			}
		) {
			onOpenSingleRecordModal(recordId)
		},
	})
	/**
	 * 환자 생성 Mutation
	 *
	 * 환자 등록 후, 환자 목록을 갱신하고
	 * ONE_OFF sampling type의 연구일 경우 추가로 레코드를 생성시키고 상세 내용 팝업창을 띄움
	 *
	 * 아닐 경우 페이지 넘어가기
	 */
	const [addPatient] = useMutation(ADD_PATIENT, {
		refetchQueries: () => [
			{
				query: GET_DOCTOR_PATIENTS,
				variables: {
					pagination: {
						page: 1,
						perPage: DEFAULT_PATIENTS_PER_PAGE,
					},
					...(researchId != null && {
						filter: {
							fields: [
								{
									name: 'researchId',
									value: researchId.toString(),
								},
							],
						},
					}),
				},
			},
		],
		awaitRefetchQueries: true,
		onCompleted: async ({ addPatient }) => {
			if (addPatient == null) {
				alert('오류가 발생했습니다.')
				return
			}

			const patientId = addPatient.id

			resetPatientInfo()
			onClose()

			if (selectedResearch.samplingPlan === ResearchSamplingPlan.ONE_OFF.value) {
				const { data } = await client.query({
					query: GET_RESEARCH_RECORD_TEMPLATE_INFOS,
					variables: {
						researchId,
					},
				})

				const { researchRecordTemplates = [] } = data || {}

				if (researchRecordTemplates.length > 0) {
					// 단일 레코드만 사용하는 경우
					const rt = researchRecordTemplates[0].id
					addRecord({
						variables: {
							patientId,
							researchId,
							templateId: rt,
							isUsingLastRecord: false,
							visitedDate: moment().format('YYYY-MM-DD'),
						},
					})
				}
			} else {
				history.push(`/patients/${patientId}`)
			}
		},
	})

	/**
	 * 환자 등록하기
	 *
	 * 입력값 Validation 후 등록
	 */
	const handleSubmit = useCallback(
		async curState => {
			const requiredFields = [...PATIENT_BASIC_REQUIRED_FIELDS, ...researchFields]

			const validated = validatePatient(requiredFields, curState)

			if (validated === false) return

			try {
				await addPatient({
					variables: {
						...curState,
						...(researchId !== DEFAULT_RESEARCH.id && {
							researchId,
						}),
						hasAgreed,
					},
				})
			} catch (e) {
				if (e && e.graphQLErrors) {
					const errorCode = e.graphQLErrors[0].extensions.code
					if (errorCode === ERROR_TYPES.DUPLICATE_ERROR.code) {
						alert('이미 등록된 병록(차트)번호입니다.')
					} else if (errorCode === ERROR_TYPES.UNAUTHORIZED_ERROR.code) {
						alert('해당 연구에 환자 등록 권한이 없습니다.')
					} else {
						alert('오류가 발생했습니다.')
					}
				}
			}
		},
		[addPatient, researchFields, researchId, hasAgreed]
	)

	const canSubmit =
		selectedResearch != null &&
		((hasCheckbox === true && hasAgreed === true) || hasCheckbox === false)

	return (
		<Modal size="small" closeIcon open={open} onClose={onClose}>
			<Modal.Header>새로운 환자 등록하기</Modal.Header>
			<Modal.Content>
				{researchError && <MiniErrorView />}
				{(researchLoading || researches == null) && <InlineLoader height={250} />}

				<div style={{ padding: 20 }}>
					{researchItems && (
						<div>
							{selectedResearch == null && <Title>환자를 등록할 프로젝트를 선택해 주세요.</Title>}
							<ResearchSelectView
								selectedResearch={selectedResearch}
								items={researchItems}
								toggleSelectResearch={toggleSelectResearch}
							/>
						</div>
					)}
					{selectedResearch != null && (
						<div style={{ marginTop: 20 }}>
							<AgreementContainer>
								<PatientAgreementCheck
									message={agreementMessage}
									hasCheckbox={hasCheckbox}
									hasAgreed={hasAgreed}
									onChangeAgreed={setHasAgreed}
								/>
							</AgreementContainer>

							<FadeInContainer isVisible={canSubmit}>
								<PatientForm
									{...patientInfo}
									onChange={handleChange}
									onChangeBirthDate={handleChangeBirthDate}
									researchFields={researchFields}
								/>
							</FadeInContainer>
						</div>
					)}
				</div>
			</Modal.Content>
			<Modal.Actions>
				{canSubmit === true && (
					<Button primary onClick={() => handleSubmit(patientInfo)}>
						환자 등록
					</Button>
				)}
			</Modal.Actions>
		</Modal>
	)
}

PatientAddModal.propTypes = {
	researchTabId: PropTypes.number,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onOpenSingleRecordModal: PropTypes.func.isRequired,
}

PatientAddModal.propTypes = {
	researches: PropTypes.array.isRequired,
	addPatient: PropTypes.func.isRequired,
}

export default PatientAddModal

const Title = styled(LightH3)`
	font-weight: 300;
	font-size: 24px;
	margin-bottom: 40px;
`

const AgreementContainer = styled.div`
	margin-top: 20px;
	margin-bottom: 20px;
`
