import React from 'react'
import CaseFeed from './CaseFeed'
import { CaseListType } from '../../enum'

const MyCases = () => {
	return (
		<div>
			<CaseFeed type={CaseListType.MY.value} title="내 케이스" useChartNumSearch />
		</div>
	)
}

MyCases.propTypes = {}

export default MyCases
