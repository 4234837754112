import styled from 'styled-components'
import { Button } from 'semantic-ui-react'

export default styled(Button)`
	width: 64px;
	height: 64px;
	border-radius: 8px !important;
	box-shadow: ${props => props.theme.BOX_SHADOW_SURVEY_BUTTON}!important;
	border: ${props => props.theme.BORDER_SURVEY_BUTTON} !important;
	background: linear-gradient(135deg, #f4f4f4, #dfdfdf) !important;
	margin-right: 10px !important;
	& > .icon {
		margin: 0px !important;
	}
`
