import React from 'react'
import PropTypes from 'prop-types'

import { ROUTE_PROJECT_DETAIL_PATIENT_DETAIL } from '../constants/routes'
import { replaceParams } from '../libs/routeHelper'
import { toCommonDateString } from '../libs/dateHelper'
import DataTable from '../components/common/DataTable'
import PageContainer from '../components/common/container/PageContainer'

import { GET_RESEARCH_DOCTORS } from '../graphql/queries/research'

const PAGE_TITLE = '참여 의료인'
const COLUMNS = [
	{
		text: '참여 시작일',
		key: 'researcherInfo.createdAt',
		value: 'createdAt',
		isSortable: true,
		uiOptions: {
			textAlign: 'center',
		},
		getter: ({ researcherInfo }) => {
			return toCommonDateString(researcherInfo.createdAt)
		},
	},
	{
		text: '이름',
		key: 'user.name',
		value: 'name',
		isSortable: true,
		uiOptions: {
			textAlign: 'center',
		},
		render: ({ user: { name } }) => {
			return <b>{name}</b>
		},
	},
	{
		text: '소속',
		key: 'user.institution.name',
		value: 'institutionName',
		isSortable: true,
		uiOptions: {
			textAlign: 'center',
		},
	},
	{
		text: '직위',
		key: 'user.position',
		value: 'code',
		isSortable: true,
		uiOptions: {
			textAlign: 'center',
		},
	},
	{
		text: '환자 수',
		key: 'researcherInfo.patientCount',
		value: 'patientCount',
		isSortable: true,
		uiOptions: {
			textAlign: 'center',
			style: { width: 200 },
		},
	},
]

const ResearchDoctorList = props => {
	const { researchId, breadCrumbs } = props

	return (
		<DataTable
			celled
			hasSearch
			searchPlaceholder="검색"
			query={GET_RESEARCH_DOCTORS}
			defaultVars={{
				researchId,
			}}
			rowHeight={50}
			queryName="researchDoctors"
			emptyMessage="등록된 의료인이 없습니다."
			render={({ rendered, totalCount }) => {
				return (
					<PageContainer
						fixed
						title={PAGE_TITLE}
						breadCrumbs={breadCrumbs}
						titleNumber={totalCount}
					>
						{rendered}
					</PageContainer>
				)
			}}
			columns={COLUMNS}
		/>
	)
}

export default ResearchDoctorList

ResearchDoctorList.propTypes = {
	researchId: PropTypes.number,
	history: PropTypes.shape({
		push: PropTypes.func,
	}).isRequired,
	userInfo: PropTypes.shape({
		id: PropTypes.number,
	}),
	breadCrumbs: PropTypes.array,
}
