import gql from 'graphql-tag'
import {
	FRAGMENT_RESEARCH_SIDE_MENU_ITEM,
	FRAGMENT_RESEARCH_SIDE_MENU_ITEM_LIST,
} from '../fragments/app'
import { LOCAL_MED_INFO } from '../fragments/medInfo'

export const GET_TERMS = gql`
	query getTerms($type: String) {
		terms(type: $type) {
			id
			text
			version
		}
	}
`
export const GET_VERSION = gql`
	query getAppVersion($currentVersion: Int!, $appType: AppType!) {
		version(current: $currentVersion, type: $appType) {
			version
			shouldResetCache
		}
	}
`

export const GET_LATEST_TERM_IDS = gql`
	query {
		latestTerms {
			serviceTerm {
				id
			}
			privacyTerm {
				id
			}
		}
	}
`

export const GET_LATEST_TERMS = gql`
	query {
		latestTerms {
			serviceTerm {
				id
				type
				text
			}
			privacyTerm {
				id
				type
				text
			}
		}
	}
`

export const GET_CLIENT_LATEST_TERMS = gql`
	query {
		latestTerms @client {
			serviceTermId
			privacyTermId
		}
	}
`

export const GET_TOAST_MESSAGES = gql`
	query {
		toastMessages @client {
			id
			content
			success
			error
			autoDismiss
		}
	}
`

export const GET_PAGE_INFO = gql`
	query {
		pageInfo @client {
			isScrolled
		}
	}
`

/**
 * 스크롤 내려갔는지 여부 확인
 */
export const GET_SCROLL_STATUS = gql`
	query {
		isScrolledDown @client
	}
`

/**
 * 서브 사이드 메뉴 열림상태 불러오기
 */
export const GET_SUB_SIDE_MENU_OPEN = gql`
	query {
		isSubSideMenuOpen @client
	}
`

/**
 * 프로젝트 서브 사이브 메뉴의 상태를 불러옴
 */
export const GET_RESEARCH_SUB_SIDE_MENU = gql`
	query {
		researchSideMenu @client {
			id
			isLoaded
			items {
				...ResearchSideMenuItemParts
			}
		}
	}

	${FRAGMENT_RESEARCH_SIDE_MENU_ITEM}
`

/**
 * 연구 페이지 서브 사이브 메뉴의 상태를 불러옴
 */
export const GET_RESEARCH_SUB_SIDE_MENU_ITEM = gql`
	query($id: Int) {
		researchSideMenuItem(id: $id) @client {
			...ResearchSideMenuItemLists
		}
	}

	${FRAGMENT_RESEARCH_SIDE_MENU_ITEM_LIST}
`

/**
 * 환자 설문 진행중 여부 확인
 */
export const GET_PATIENT_SURVEY_OPEN = gql`
	query {
		isDoingSurvey @client {
			patient
			researcher
		}
	}
`

/**
 * 환자 설문 레코드 정보 불러오기
 */
export const GET_PATIENT_SURVEY_STATUS = gql`
	query {
		surveyStatus @client {
			patient {
				id
				recordId
				patientId
				patientName
				patientCode
				researchId
				templateName
				templateDescription
				medInfos {
					...LocalMedInfoParts
				}
			}
			researcher {
				id
				recordId
				patientId
				patientName
				patientCode
				researchId
				templateName
				templateDescription
				medInfos {
					...LocalMedInfoParts
				}
			}
		}

		patientSurveyCurrent @client {
			id
			index
		}

		isDoingSurvey @client {
			patient
			researcher
		}
	}

	${LOCAL_MED_INFO}
`

/**
 * 환자 서명 모달 정보
 */
export const GET_PATIENT_SIGN = gql`
	query {
		patientSign @client {
			patientId
			patientName
			recordId
			researchId
			didAgree
			isOpen
		}
	}
`

/**
 * 내 데이터 화면 연구 선택 항목
 */
export const GET_RESEARCH_TAB_ID = gql`
	query {
		researchTabId @client
	}
`
