import { DEPARTMENT_SECTION, TEMPLATE_SECTION } from './PatientSurveySection'
import React, { useCallback, useEffect } from 'react'

import ErrorView from '../common/error/ErrorView'
import LoadingView from '../common/loader'
import PatientSurveyButton from './PatientSurveyButton'
import { PatientSurveySectionTitle } from '../common/basic/StyledTexts'
import PropTypes from 'prop-types'
import ResearchSelectView from '../common/ResearchSelectView'
import useUserActiveResearches from '../../hooks/useUserActiveResearches'

const PatientSurveyProject = ({
	researchId,
	researchCount,
	setResearchId,
	setResearchCount,
	setSelectedTemplate,
	handleSection,
}) => {
	const { researches, loading: researchLoading, error: researchError } = useUserActiveResearches()
	// const researchCount = researches.length

	const selectedResearch = researches.find(i => i.id === researchId)

	const toggleSelectResearch = useCallback(
		({ id: rId } = {}) => {
			if (rId == null || rId === researchId) {
				setResearchId(null)
				setSelectedTemplate(null)
			} else {
				setResearchId(rId)
				handleSection(TEMPLATE_SECTION)
			}
		},
		[researchId, handleSection, setResearchId, setSelectedTemplate]
	)

	useEffect(() => {
		if (researches.length === 1) {
			setResearchId(researches[0].id)
			handleSection(TEMPLATE_SECTION)
		}
		setResearchCount(researches.length)
	}, [researches])

	if (researchLoading === true) return <LoadingView />
	if (researchError != null) return <ErrorView error={researchError} />

	return (
		researches.length !== 1 && (
			<>
				<PatientSurveySectionTitle>프로젝트를 선택하세요.</PatientSurveySectionTitle>
				<PatientSurveyButton click={() => handleSection(DEPARTMENT_SECTION)}>
					다른과 선택하기
				</PatientSurveyButton>
				{researches && researches.length > 0 ? (
					<ResearchSelectView
						items={researches}
						shouldHideInst
						selectedResearch={selectedResearch}
						toggleSelectResearch={
							researchCount > 1
								? toggleSelectResearch
								: ({ id }) => {
										setResearchId(id)
										handleSection(TEMPLATE_SECTION)
								  }
						}
					/>
				) : (
					'프로젝트가 존재하지 않습니다.'
				)}
			</>
		)
	)
}

PatientSurveyProject.propTypes = {
	researchId: PropTypes.number,
	researchCount: PropTypes.number,
	setResearchId: PropTypes.func,
	setSelectedTemplate: PropTypes.func,
	setResearchCount: PropTypes.func,
	handleSection: PropTypes.func,
}

export default PatientSurveyProject
