/**
 * [Helper] 날짜 관련 처리
 */

import moment from 'moment'
import 'moment/locale/ko'

moment.updateLocale('ko', {
	calendar: {
		lastDay: '어제 LT',
		sameDay: 'LT',
		nextDay: '내일 LT',
		lastWeek: 'ddd LT',
		nextWeek: 'ddd LT',
		sameElse: value => {
			if (moment().year() === value.year()) return 'M월 D일'

			return 'YYYY. MM. DD'
		},
	},
})

/**
 * 특정 날짜 이후 X 일/시간/분/초  값
 * @param {Object} options days, hours, minutes, seconds..
 * @param {Date} dateObj
 */
export const dateFromDate = (options, dateObj) => {
	const date = dateObj.getTime() / 1000
	if (options.days) {
		return date + 24 * 60 * 60 * options.days
	}
	if (options.hours) {
		return date + 60 * 60 * options.hours
	}
	if (options.minutes) {
		return date + 60 * options.minutes
	}
	if (options.seconds) {
		return date + options.seconds
	}
	return date
}

/**
 * 현재 이후 X 일/시간/분/초  값
 * @param {Object} options days, hours, minutes, seconds..
 */
export const dateFromNow = function(options) {
	return dateFromDate(options, new Date())
}

/**
 * 생일로 나이 계산하기
 * @param {String} dateString 날짜 String
 */
export const getAge = dateString => {
	const today = new Date()
	const birthDate = new Date(dateString)
	let age = today.getFullYear() - birthDate.getFullYear()

	// 만나이
	// var m = today.getMonth() - birthDate.getMonth();
	// if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
	//     age--;
	// }

	// 한국나이
	const bm = birthDate.getMonth()
	if (bm === 1 || bm === 2) {
		age += 1
	}
	return age
}

/**
 * 초를 timestamp string으로
 * @param {number} seconds
 */
export const secondsToTimestamp = seconds => {
	return new Date(seconds * 1000)
		.toISOString()
		.slice(0, 19)
		.replace('T', ' ')
}

/**
 * 초를 sql timestamp string으로
 * @param {number} seconds
 */
export const secondsToSQLTimestamp = seconds => {
	return new Date(seconds * 1000).toISOString()
}

/**
 * timestamp를 초로
 * @param {string} timestamp
 */
export const timestampToSeconds = timestamp => {
	return new Date(timestamp.slice(0, 19).replace('T', ' '))
}

/**
 * YYYY-MM-DD로 포맷
 * @param {Date} d 날짜
 */
export const formatDate = d => {
	let month = `${d.getMonth() + 1}`
	let day = `${d.getDate()}`
	const year = d.getFullYear()

	if (month.length < 2) month = `0${month}`
	if (day.length < 2) day = `0${day}`

	return [year, month, day].join('-')
}

/**
 * YYYY-MM-DD로 포맷
 * @param {Date} d 날짜
 */
export const formatDateOnList = d => {
	let month = `${d.getMonth() + 1}`
	let day = `${d.getDate()}`
	const year = d.getFullYear()

	if (month.length < 2) month = `0${month}`
	if (day.length < 2) day = `0${day}`

	return [year, month, day].join('-')
}

/**
 * hh:mm으로 시간 포맷
 * @param {Date} d 날짜
 */
export const formatTimeOnList = d => {
	let hour = `${d.getHours()}`
	let minute = `${d.getMinutes()}`

	if (hour.length < 2) hour = `0${hour}`
	if (minute.length < 2) minute = `0${minute}`

	return `${hour}:${minute}`
}

/**
 * 연구 phase별 시기 날짜값을 문자형으로
 */
export const researchPhaseTime = days => {
	let daysString
	if (days === 0) daysString = '첫 내원 시'
	else if (days < 30) daysString = `${Math.round(days / 7)} 주 이내`
	else if (days > 30) daysString = `${Math.round(days / 30)} 개월 이내`

	return daysString
}

export const toLocalizedDateString = value => {
	return moment(value).calendar()
}

export const toCommonDateString = value => {
	return moment(value).format('YYYY. MM. DD. ')
}

export const toCommonDateTimeString = value => {
	return moment(value).format('YYYY. MM. DD. LT')
}

export const getTodayString = () => {
	return moment().format('YYYY-MM-DD')
}

export const convertDateToPostStyle = date => {
	const momentCA = moment(date)
	const momentMgo = moment().subtract(1, 'months')
	const momentYgo = moment().subtract(1, 'year')

	let dstr
	if (momentCA.isBefore(momentYgo)) {
		dstr = momentCA.format('YYYY년 MM월 DD일')
	} else if (momentCA.isBefore(momentMgo)) {
		dstr = momentCA.format('MM월 DD일')
	} else {
		dstr = momentCA.fromNow()
	}

	return dstr
}
