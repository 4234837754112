import { useQuery } from 'react-apollo'
import { GET_USER_INFO } from '../graphql/queries/user'

/**
 * local state에 저장된 사용자 정보 불러오는 hook
 */
const useUserInfo = () => {
	const { data } = useQuery(GET_USER_INFO)

	if (data == null) return null
	return data.userInfo
}

export default useUserInfo
