/**
 * [Component] 연구대상자 상세정보
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
	Table,
	Button,
	Segment,
	Icon,
	CardGroup,
	Placeholder,
	PlaceholderLine,
} from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-apollo'
import moment from 'moment'
import 'moment/locale/ko'

import useToggle from '../../../hooks/useToggle.ts'
import useDeleteObject from '../../../hooks/useDeleteObject'
import { getAge, convertGender } from '../../../libs/valueHelper'
import {
	ResearchType,
	ResearchSubjectStatus,
	ReservationDateStatus,
	ObjectType,
} from '../../../enum'
import { ROUTE_PROJECT_DETAIL_DATA_DETAIL_DEFAULT } from '../../../constants/routes'
import { replaceParams } from '../../../libs/routeHelper'
import {
	COLOR_TEXT_LIGHTGRAY,
	COLOR_TEXT_LIGHTERGRAY,
	BORDER_GRAY,
} from '../../../constants/styles'
import { toCommonDateString } from '../../../libs/dateHelper'
import { GET_PATIENT_RECORDS } from '../../../graphql/queries/record'
import { DEFAULT_PATIENT_RECORDS_PER_PAGE } from '../../../constants'
import {
	GET_RESEARCH_PATIENT_DETAIL,
	GET_PATIENT_DETAIL_BY_ID,
} from '../../../graphql/queries/patient'

import ResearchRecordItem from '../data/ResearchRecordItem'
import StatusLabel from '../../common/StatusLabel'
import PatientDetailModal from '../../patient/detail/PatientDetailModal'
import TitleCount from '../../common/basic/TitleCount'
import TableLoader from '../../common/loader/TableLoader'
import PageContainer from '../../common/container/PageContainer'
import SubTitle from '../../common/basic/SubTitle'
import AddButton from '../../common/basic/AddButton'
import CardWithSmallHeader from '../../common/basic/CardWithSmallHeader'
import InfoIcon from '../../../assets/images/icons/info.svg'
import PatientStats from '../../patient/detail/PatientStats'
import ErrorView from '../../common/error/ErrorView'
import { MiniBar } from '../../common/basic/MiniBar'
import DeleteObjectModal from '../../common/modal/DeleteObjectModal'
import TableError from '../../common/error/TableError'
import ResearchPatientDropModal from './ResearchPatientDropModal'

const ResearchPatientDetail = ({
	onAddRecord,
	breadCrumbs,
	researchId,
	patientId: patientId_str,
	type,
	isBlinded,
	userInfo,
	patientBasicInfo,
}) => {
	const history = useHistory()
	const [detailModalOpen, toggleDetailModal] = useToggle(false)
	const [dropModalOpen, toggleDropModal] = useToggle(false)
	const { handleOpen, ...deleteModalProps } = useDeleteObject()
	const patientId = parseInt(patientId_str)
	const {
		data: { patient } = {},
		loading: isLoadingPatient,
		error: patientError,
		refetch: onRefetchPatient,
	} =
		useQuery(GET_RESEARCH_PATIENT_DETAIL, {
			variables: {
				researchId,
				patientId,
			},
		}) || {}
	const { name, sexMale, birthDate, code, createdAt, registerer, patientResearches } = patient || {}

	const {
		data: { patientRecords } = {},
		loading: isLoadingRecords,
		error: recordsError,
		refetch: onRefetchRecords,
	} =
		useQuery(GET_PATIENT_RECORDS, {
			variables: {
				patientId,
				researchId,
				pagination: {
					perPage: DEFAULT_PATIENT_RECORDS_PER_PAGE,
				},
			},
		}) || {}
	const { items: records, totalCount: recordCount } = patientRecords || {}

	const onUpdate = () => {
		onRefetchPatient()
		onRefetchRecords()
	}

	const handleAddRecord = () => {
		onAddRecord({
			variables: {
				researchId,
				patientId,
				visitedDate: moment().toISOString(),
				isUsingLastRecord: false,
			},
			update: () => {
				onUpdate()
			},
		})
	}

	const handleClickRecord = id => () => {
		history.push(
			replaceParams(ROUTE_PROJECT_DETAIL_DATA_DETAIL_DEFAULT, {
				id: researchId,
				subId: patientId,
				recordId: id,
			})
		)
	}

	const patientResearch = patientResearches != null && patientResearches[0]
	const { code: patientResearchCode, nextTemplate, nextReservation } = patientResearch || {}
	const isDropped =
		patientResearches != null && patientResearch.status === ResearchSubjectStatus.DROPPED.key
	const isScheduled = patientResearches != null && type === ResearchType.SCHEDULED.key
	const isActive =
		patientResearches != null && patientResearch.status === ResearchSubjectStatus.ACTIVE.key

	if (patientError != null) {
		return <ErrorView error={patientError} />
	}

	return (
		<PageContainer fixed breadCrumbs={breadCrumbs}>
			<Segment style={styles.profileContainer}>
				{isLoadingPatient === true ? (
					<Placeholder>
						<PlaceholderLine />
						<PlaceholderLine />
						<PlaceholderLine />
						<PlaceholderLine />
					</Placeholder>
				) : (
					<div>
						{isScheduled === true && (
							<div>
								<StatusLabel set={ResearchSubjectStatus} status={patientResearch.status} />
							</div>
						)}
						<h5>
							{patientResearchCode != null && `${patientResearchCode} (`}
							{name}
							{patientResearchCode != null && ')'}
						</h5>
						<div>
							{convertGender(sexMale)} <MiniBar /> {getAge(birthDate)}
							<div style={styles.subInfo}>
								<Icon style={styles.subInfoIcon} name="doctor" />
								{registerer.institution.name}
								<MiniBar />
								{isBlinded ? '***' : registerer.name}
							</div>
							<div style={styles.subInfo}>
								<div style={styles.description}>{toCommonDateString(createdAt)} 등록</div>
							</div>
							<Tools>
								{isBlinded !== true && (
									<ToolButton onClick={() => toggleDetailModal()}>
										<ToolButtonIcon src={InfoIcon} />
										<ToolButtonText>대상자 상세정보</ToolButtonText>
									</ToolButton>
								)}
								{isDropped !== true && (
									<ToolButton onClick={() => toggleDropModal()} color="red">
										<ToolButtonText>탈락 처리</ToolButtonText>
									</ToolButton>
								)}
							</Tools>
						</div>
					</div>
				)}
			</Segment>
			<CardGroup>
				{isActive === true && nextTemplate != null && (
					<NextReservationInfo
						templateTitle={nextTemplate.title}
						templateId={nextTemplate.id}
						{...nextReservation}
						onAddRecord={handleAddRecord}
					/>
				)}
			</CardGroup>
			<div>
				<SubTitle>
					진료 기록 <TitleCount>{recordCount}</TitleCount>
				</SubTitle>
				{/** TODO: Tools */}
				<Table selectable celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell textAlign="center">진료일</Table.HeaderCell>
							<Table.HeaderCell textAlign="center">
								{isScheduled ? '일정' : '양식'}
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="center">등록자</Table.HeaderCell>
							<Table.HeaderCell textAlign="center">등록일</Table.HeaderCell>
							{/* <Table.HeaderCell textAlign="center">코드</Table.HeaderCell> */}
							<Table.HeaderCell textAlign="center">상태</Table.HeaderCell>
							<Table.HeaderCell />
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{recordsError != null && <TableError />}
						{isLoadingRecords === true && <TableLoader />}
						{records != null &&
							records.map(record => (
								<Table.Row
									textAlign="center"
									onClick={handleClickRecord(record.id)}
									style={{ cursor: 'pointer' }}
									key={`R-${record.id}`}
								>
									<ResearchRecordItem
										onPatientPage
										forPatient
										researchId={researchId}
										isBlinded={isBlinded}
										{...record}
										userInfo={userInfo}
										OpenDeleteModal={() =>
											handleOpen(ObjectType.RECORD.value, record.id, {
												onComplete: onUpdate,
												refetchQueries: [
													{
														query: GET_PATIENT_DETAIL_BY_ID,
														variables: {
															id: patientId,
															researchId: researchId,
														},
													},
												],
											})
										}
									/>
								</Table.Row>
							))}
					</Table.Body>
				</Table>
				{recordCount > 0 && (
					<Section>
						<SubTitle>진료 분석</SubTitle>
						<PatientStats patientId={patientId} />
					</Section>
				)}
			</div>

			{/* 환자 상세 정보 보는 모달 창 */}
			<PatientDetailModal
				researchId={researchId}
				patientBasicInfo={patientBasicInfo != null ? patientBasicInfo.split('|') : []}
				isOpen={detailModalOpen}
				onClose={() => toggleDetailModal()}
				code={code}
			/>

			{/* 환자 탈락 모달 */}
			<ResearchPatientDropModal
				isOpen={dropModalOpen}
				onClose={() => toggleDropModal(false)}
				onUpdate={onUpdate}
				patientId={patientId}
				researchId={researchId}
				patientResearchCode={patientResearchCode}
				patientName={name}
			/>
			{/* 진료 기록 삭제 모달 */}
			<DeleteObjectModal {...deleteModalProps} />
		</PageContainer>
	)
}

ResearchPatientDetail.propTypes = {
	onDropPatient: PropTypes.func,
	onAddRecord: PropTypes.func,
	breadCrumbs: PropTypes.array,
	researchId: PropTypes.number,
	patientId: PropTypes.number,
	onShowToastMessage: PropTypes.func,
	type: PropTypes.string,
	isBlinded: PropTypes.bool,
	userInfo: PropTypes.object,
	patientBasicInfo: PropTypes.string,
}

export default ResearchPatientDetail

/**
 * Sub-Components
 */

/**
 * 다음 일정 정보
 */
const NextReservationInfo = props => {
	const { reservedDate, status, templateTitle, onAddRecord } = props

	const mdate = moment(reservedDate)
	const now = new Date()

	let realStatus
	if (status === ReservationDateStatus.WAITING.value) {
		const isToday = mdate.isSame(now, 'date')

		if (isToday === true) {
			realStatus = ReservationDateStatus.TODAY
		} else {
			const diffDays = mdate.diff(now, 'days')

			if (diffDays >= 0) {
				//미래
				if (diffDays < ReservationDateStatus.NEAR.days) {
					realStatus = Object.assign({}, ReservationDateStatus.NEAR)
				} else {
					realStatus = Object.assign({}, ReservationDateStatus.FUTURE)
				}
			} else {
				realStatus = Object.assign({}, ReservationDateStatus.PAST.value)
			}

			realStatus.text = mdate.fromNow()
		}
	} else {
		realStatus = ReservationDateStatus[status]
	}

	return (
		<CardWithSmallHeader
			header="다음 일정"
			content={
				<div>
					<CardTitle>
						<TitleText>{templateTitle}</TitleText>
						<AddReservationRecord title="기록 생성" onClick={onAddRecord} />
					</CardTitle>
					<NextDate>
						{reservedDate != null ? mdate.format('YYYY. MM. DD.') : '예약 없음'}
						<ReservationStatusLabel>
							{reservedDate != null && <StatusLabel item={realStatus} />}
						</ReservationStatusLabel>
					</NextDate>
				</div>
			}
		/>
	)
}

NextReservationInfo.propTypes = {
	reservedDate: PropTypes.string.isRequired,
	status: PropTypes.string.isRequired,
	templateTitle: PropTypes.string,
	onAddRecord: PropTypes.func,
}

/**
 * Styled Components
 */

const Tools = styled.div`
	margin-left: 30px !important;
	margin-top: -10px !important;
	margin-bottom: -10px !important;
	display: inline-block;
`
const ToolButton = styled(Button)`
	margin-left: 10px !important;
`

const ToolButtonIcon = styled.img`
	vertical-align: middle;
`
const ToolButtonText = styled.span`
	vertical-align: middle;
	line-height: 25px;
`

const CardTitle = styled.div`
	font-size: 22px;
`

const NextDate = styled.div`
	margin-top: 12px;
`

const ReservationStatusLabel = styled.div`
	margin-left: 10px;
	display: inline-block;
	position: absolute;
	top: 13px;
	right: 15px;
`
const TitleText = styled.div`
	vertical-align: middle;
	line-height: 30px;
	display: inline-block;
`
const AddReservationRecord = styled(AddButton)`
	/* margin-top: -3px !important;
	margin-right: -5px !important;
	float: right; */
	position: absolute;
	bottom: 10px;
	right: 15px;
`

const Section = styled.div`
	margin-top: 60px;
	border-top: ${BORDER_GRAY};
`

const styles = {
	profileContainer: {
		padding: 20,
	},
	subInfo: {
		fontSize: 14,
		display: 'inline-block',
		marginLeft: 20,
	},
	subInfoIcon: {
		fontSize: 18,
		color: COLOR_TEXT_LIGHTGRAY,
	},
	registererLabel: {
		color: COLOR_TEXT_LIGHTGRAY,
		marginRight: 10,
		display: 'inline-block',
	},
	description: {
		color: COLOR_TEXT_LIGHTGRAY,
		display: 'inline-block',
	},
	widgetSegment: {
		display: 'inline-block',
	},

	label: {
		fontSize: 12,
		color: '#999',
	},

	date: {
		display: 'inline-block',
		marginLeft: 10,
		position: 'absolute',
		bottom: 20,
		right: 30,
		color: COLOR_TEXT_LIGHTERGRAY,
	},
	patientInfo: {
		display: 'inline-block',
		verticalAlign: 'top',
	},
	name: {
		display: 'inline-block',
		marginRight: 10,
	},
	patientWrap: {
		paddingLeft: 10,
		display: 'table',
		width: '100%',
	},
	image: {
		display: 'inline-block',
		border: '1px solid #eee',
	},
	profile: {
		display: 'inline-block',
		verticalAlign: 'top',
		padding: 10,
		paddingLeft: 30,
	},
	doctor: {
		display: 'inline-block',
		marginRight: 20,
	},
	chartcount: {
		display: 'inline-block',
		marginBottom: 7,
		verticalAlign: 'bottom',
	},
	subInfoField: {
		marginRight: 20,
		display: 'inline-block',
	},

	patientDate: {
		fontSize: 16,
		fontWeight: 400,
		marginLeft: 10,
		marginRight: '1em',
	},
	patientSubInfo: {
		fontSize: 14,
		fontWeight: 400,
	},
	rightAlign: {
		float: 'right',
	},
	section1: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'spaceAround',
		alignItems: 'center',
		marginBottom: '20px',
	},
	modalDiv: {
		width: '100%',
	},
	modalBold: {
		fontWeight: 'bold',
		opacity: '0.89',
		marginRight: '10px',
	},
	modalSpan: {
		fontSize: '18px',
		color: '#4b4b4b',
	},
	modalP: {
		width: '50%',
	},
}
