import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, TextArea, Button } from 'semantic-ui-react/dist/commonjs'

import { ResearchStatus } from '../../../enum'
import SubContainer from '../../common/container/SubContainer'
import FieldsContainer from '../../common/container/FieldsContainer'

const SELECT_OPTIONS = Object.keys(ResearchStatus).reduce((opts, key) => {
	const item = ResearchStatus[key]
	return opts.concat({
		key,
		text: item.text,
		value: key,
		label: { color: item.color, empty: true, circular: true },
	})
}, [])

export default class ResearchSettingsBasicForm extends PureComponent {
	static propTypes = {
		title: PropTypes.string.isRequired,
		description: PropTypes.string,
		projectId: PropTypes.string.isRequired,
		status: PropTypes.string.isRequired,
		startDate: PropTypes.string.isRequired,
		endDate: PropTypes.string.isRequired,
		organizedBy: PropTypes.shape({
			name: PropTypes.string,
		}).isRequired,
		onUpdateResearch: PropTypes.func.isRequired,
		researchId: PropTypes.number.isRequired,
		onShowHeaderMessage: PropTypes.func.isRequired,
		onFinishUpdate: PropTypes.func.isRequired,
	}

	static defaultProps = {
		description: null,
	}

	constructor(props) {
		super(props)
		this.state = {
			title: props.title,
			projectId: props.projectId,
			status: props.status,
			description: props.description,
			isBtn1Loading: false,
		}
	}

	handleChangeValue = key => (e, { value }) => {
		this.setState({
			[key]: value,
		})
	}

	handleChangeSelectValue = key => (e, { data }) => {
		this.setState({
			[key]: data.value,
		})
	}

	handleSubmitBasic = async () => {
		const { onUpdateResearch, researchId, onShowHeaderMessage, onFinishUpdate } = this.props
		const { title, status, description } = this.state

		this.setState({
			isBtn1Loading: true,
		})

		try {
			await onUpdateResearch({
				variables: {
					title,
					status,
					description,
					researchId,
				},
			})
			onFinishUpdate()
			onShowHeaderMessage('변경사항이 저장되었습니다.', '', 'blue')
		} catch (e) {
			onShowHeaderMessage(
				'연구 기본정보 저장 중 오류가 발생했습니다.',
				'다시 한번 시도해 주세요.',
				'red'
			)
		}

		this.setState({
			isBtn1Loading: false,
		})
	}

	render() {
		const { title, projectId, status, description, isBtn1Loading } = this.state
		const { startDate, endDate, organizedBy } = this.props

		return (
			<SubContainer>
				<FieldsContainer>
					<Form>
						<Form.Field
							id="research-title"
							width={10}
							placeholder="연구 과제명"
							label="연구 과제명"
							value={title}
							control={Input}
							onChange={this.handleChangeValue('title')}
						/>
						<Form.Field
							label="주관기관"
							readOnly
							width={10}
							control={Input}
							value={organizedBy.name}
						/>
						<Form.Field
							id="research-project-id"
							width={10}
							label="과제코드"
							value={projectId}
							readOnly
							control={Input}
						/>

						<Form.Field>
							<label style={styles.inlineLabel}>연구기간</label>
							<div style={styles.inlineContent}>
								<input readOnly style={styles.inlineInput} value={startDate} /> 부터 &nbsp;&nbsp;
								<input readOnly style={styles.inlineInput} value={endDate} /> 까지
							</div>
						</Form.Field>
						<Form.Select
							id="research-status"
							width={5}
							placeholder="진행상태"
							label="진행상태"
							options={SELECT_OPTIONS}
							selection
							value={status}
							onChange={this.handleChangeValue('status')}
						/>
						<Form.Field
							id="research-description"
							width={12}
							placeholder="간단한 설명을 적어주세요"
							label="연구 설명"
							value={description}
							control={TextArea}
							onChange={this.handleChangeValue('description')}
						/>
					</Form>
				</FieldsContainer>
				<Button primary size="big" loading={isBtn1Loading} onClick={this.handleSubmitBasic}>
					변경 사항 저장
				</Button>
			</SubContainer>
		)
	}
}

const styles = {
	container: {
		width: '100%',
	},
	inlineLabel: {
		width: 100,
		verticalAlign: 'top',
	},
	inlineContent: {
		display: 'inline-block',
	},
	inlineInput: {
		width: 130,
		verticalAlign: 'middle',
	},
	header: {
		marginBottom: 20,
		paddingBottom: 10,
		borderBottom: '1px solid #eee',
	},
}
