import { TemplateJob, ResearchSubjectStatus, ResearcherRole } from '../enum'

export const checkNewRecordPossible = (selectedResearch, isOnResearchPage) => {
	let researchOpts = {}
	let disabledMsg = ''
	let isSubmitDisabled = false

	const { nextTemplate, status: subjectStatus } = selectedResearch

	if (nextTemplate) {
		// 스크리닝 먼저 해야할 경우
		researchOpts.needScreening =
			nextTemplate.job !== TemplateJob.SCREENING.key &&
			subjectStatus === ResearchSubjectStatus.SCREEN_WAIT.key

		// 다음 기록을 의료인이 직접 기록하는 것이 아닌 경우
		researchOpts.notForYou =
			nextTemplate.doneBy !== ResearcherRole.DOCTOR.key && isOnResearchPage !== true
	}

	// 스크리닝 실패한 경우
	researchOpts.notValid = subjectStatus === ResearchSubjectStatus.SCREEN_FAIL.key

	isSubmitDisabled = researchOpts.notValid || researchOpts.notForYou || researchOpts.needScreening
	researchOpts.isDropped = subjectStatus === ResearchSubjectStatus.DROPPED

	if (researchOpts.needScreening) disabledMsg = '스크리닝을 완료해주세요. '
	else if (researchOpts.notValid) disabledMsg = '연구 등록에 부적합한 환자입니다. '
	else if (researchOpts.notForYou) disabledMsg = '이후 기록은 담당 연구원에 의해 진행됩니다.'
	else if (researchOpts.isDropped) disabledMsg = '연구 탈락 처리된 환자입니다.'

	return {
		disabledMsg,
		isSubmitDisabled,
	}
}
