import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form, Button, Loader, TextArea, Input } from 'semantic-ui-react'
import { Query, Mutation } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { WRITE_ISSUE } from '../../../graphql/mutations/research'
import { GET_ISSUES_TYPES, GET_RESEARCH_ISSUES } from '../../../graphql/queries/research'
import MiniErrorView from '../../common/error/MiniErrorView'
import { ROUTE_PROJECT_DETAIL_ISSUES_DEFAULT } from '../../../constants/routes'
import { replaceParams } from '../../../libs/routeHelper'
import PageContainer from '../../common/container/PageContainer'
import { DEFAULT_PAGINATION_VARIABLES } from '../../../constants'
import { PAGE_CONTENT_SMALL_WIDTH } from '../../../constants/styles'
import UserInline from '../../common/UserInline'
import T1 from '../../common/basic/T1'
import ThumbnailSelect from '../../common/basic/ThumbnailSelect'

/**
 * 이슈 작성 폼
 */
class ResearchIssueWriteForm extends Component {
	static propTypes = {
		profileImage: PropTypes.string,
		userDetail: PropTypes.number,
		researchId: PropTypes.number,
		history: PropTypes.object,
	}
	constructor(props) {
		super(props)

		this.state = {
			title: '',
			text: '',
			type: null,
		}
	}

	handleTypeChange = value => {
		// console.log(value)
		this.setState({
			type: value,
		})
	}

	handleTitleChange = (e, { value }) => {
		this.setState({
			title: value,
		})
	}

	handleTextChange = (e, { value }) => {
		this.setState({
			text: value,
		})
	}

	handleSubmit = async e => {
		e.preventDefault()
	}

	render() {
		const { breadCrumbs, userInfo, issueTypes, researchId, history } = this.props
		const { text, title, type } = this.state
		return (
			<PageContainer
				fixed
				breadCrumbs={[
					...breadCrumbs,
					{
						key: 'PG_BC_PR_ISSUE',
						content: '게시판',
						link: true,
					},
					{
						key: 'PG_BC_PR_ISSUE_NEW',
						content: '새로운 이슈',
						link: false,
						active: true,
					},
				]}
				width={PAGE_CONTENT_SMALL_WIDTH}
			>
				{/* <Grid columns={2} divided>
					<Grid.Row>
						<Grid.Column width={8}> */}
				{/* <div style={styles.image}>
					<Image
						style={{ margin: 0 }}
						src={profileImage}
						circular
						size="mini"
						alt="profile image"
					/>
					<div style={{ marginTop: '4px', marginLeft: '4px' }}>
						<Header>{userDetail.username}</Header>
					</div>
				</div> */}

				<TypeContainer>
					{type == null && <Title>주제를 선택해 주세요.</Title>}
					{type != null && <TypeTitle>주제 선택</TypeTitle>}
					<div>
						<ThumbnailSelect
							options={issueTypes.map(t => {
								const { id, name, type } = t
								return {
									key: type,
									content: name,
									value: id,
								}
							})}
							active={type}
							onChange={this.handleTypeChange}
						/>
					</div>
				</TypeContainer>
				<Content isVisible={type != null}>
					<UserInfo>
						<UserInline {...userInfo} />
					</UserInfo>

					<Mutation
						mutation={WRITE_ISSUE}
						awaitRefetchQueries
						refetchQueries={[
							{
								query: GET_RESEARCH_ISSUES,
								variables: {
									id: researchId,
									pagination: DEFAULT_PAGINATION_VARIABLES,
								},
							},
						]}
					>
						{writeIssue => {
							return (
								<Form
									onSubmit={async e => {
										e.preventDefault()
										await writeIssue({
											variables: {
												issue: {
													text,
													title,
													issueTypeId: type,
													researchId,
												},
											},
										})
										this.setState({
											text: '',
											title: '',
										})
										history.push(
											replaceParams(ROUTE_PROJECT_DETAIL_ISSUES_DEFAULT, {
												id: researchId,
											})
										)
									}}
								>
									<Form.Field>
										<label style={{ display: 'none' }}>제목</label>
										<Input
											onChange={this.handleTitleChange}
											type="text"
											value={title}
											placeholder="제목"
										/>
									</Form.Field>

									<Form.Field width="100%">
										<label style={{ display: 'none' }}>내용</label>
										<TextArea
											onChange={this.handleTextChange}
											placeholder="내용을 작성해 주세요."
											value={text}
											style={{ minHeight: 100 }}
										/>
									</Form.Field>
									<ButtonContainer>
										<Button color="teal" type="submit" size="big">
											등록
										</Button>
									</ButtonContainer>
								</Form>
							)
						}}
					</Mutation>
				</Content>
				{/* </Grid.Column>
						<Grid.Column width={6}>
							<Segment>
								<Header as="h5">유사한 이슈</Header>
								<p>이미 올라온 주제와 중복되는 내용을 올리지 않도록 참고해 주세요</p>
								{/* 필터링 리스트로 쿼리 필터링해서 렌더링 */}
				{/* </Segment> */}
				{/* // 	</Grid.Column> */}
				{/* // </Grid.Row> */}
				{/* // </Grid> */}
			</PageContainer>
		)
	}
}

export default withRouter(ResearchIssueWriteForm)

const Title = styled.h4`
	margin-bottom: 30px;
	margin-top: 50px;
`

const TypeContainer = styled.div`
	margin-top: 40px;
`

const TypeTitle = styled.div`
	font-size: 16px;
	margin-bottom: 10px;
`

const UserInfo = styled.div`
	margin-top: 30px;
	margin-bottom: 10px;
`

const Content = styled.div`
	display: ${props => (props.isVisible === true ? 'block' : 'none')};

	margin-top: 20px;
`

const ButtonContainer = styled.div`
	margin-top: 30px;
`
