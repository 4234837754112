import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import { useMutation } from 'react-apollo'
import { Button } from 'semantic-ui-react'
import { VERIFY_REGISTER_TOKEN } from '../../../graphql/mutations/user'
import PageContainer from '../../common/container/PageContainer'
import LoadingView from '../../common/loader'
import { ROUTE_LOGIN } from '../../../constants/routes'
import { UISize } from '../../../enum'

/**
 * 이메일 인증 처리
 *  토큰 확인하고, 문제 없을 경우 카운트다운 후 로그인 화면으로 이동.
 * 실패일 경우 실패메시지 보여줌
 */
const EmailVerification = ({
	match: {
		params: { token },
	},
	history,
}) => {
	const [verifyToken, { loading }] = useMutation(VERIFY_REGISTER_TOKEN, {
		variables: {
			token,
		},
		onCompleted: () => {
			setResult(true)
		},
		onError: () => {
			setResult(false)
		},
	})

	const [result, setResult] = useState(null)
	const [countdown, setCountdown] = useState(10)

	useEffect(() => {
		verifyToken()
	}, [verifyToken])

	useEffect(() => {
		if (result === true) {
			const timer = setInterval(() => {
				setCountdown(c => {
					if (c > 0) {
						return c - 1
					} else {
						history.push(ROUTE_LOGIN)
						return 0
					}
				})
			}, 1000)

			return () => {
				clearInterval(timer)
			}
		}
	}, [result, history])

	return (
		<PageContainer fixed title="이메일 인증">
			<div>
				{result == null && <LoadingView />}
				{result === true && (
					<>
						<div>이메일 인증에 성공하였습니다! {countdown} 초 후 로그인 화면으로 이동합니다.</div>
						<ButtonBox>
							<Button as={Link} to={ROUTE_LOGIN} primary size={UISize.LARGE}>
								로그인 화면으로 이동
							</Button>
						</ButtonBox>
					</>
				)}
				{result === false &&
					'이메일 인증에 실패하였습니다. 링크가 만료되었거나, 잘못된 링크입니다.'}
			</div>
		</PageContainer>
	)
}

EmailVerification.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			token: PropTypes.string,
		}),
	}).isRequired,
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
}

export default withRouter(EmailVerification)

const ButtonBox = styled.div`
	margin-top: 20px;
`
