/* eslint-disable no-restricted-globals */
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Input, Button, Label, Modal, Dropdown, Icon } from 'semantic-ui-react'
import { useMutation, useQuery } from 'react-apollo'
import { useHistory, useLocation } from 'react-router-dom'
import StyledTextArea from '../common/basic/StyledTextArea'
import SearchMultipleInput from '../charting/field/inputs/SearchMutipleInput'
import { UPDATE_SIMPLE_CASE, WRITE_SIMPLE_CASE } from '../../graphql/mutations/case'
import {
	COLOR_BACKGROUND_DARK_GRAY,
	COLOR_BACKGROUND_LIGHT_GRAY,
	COLOR_SECONDARY_BLUE,
	COLOR_TEXT_DARKGRAY,
} from '../../constants/styles'
import useToastMessage from '../../hooks/useToastMessage'
import { ROUTE_CASES } from '../../constants/routes'
import {
	FAIL_CASE_UPLOAD_CHART_NUM,
	FAIL_CASE_UPLOAD_MMS,
	FAIL_CASE_UPLOAD_NO_AGE,
	FAIL_CASE_UPLOAD_NO_DATE,
	FAIL_CASE_UPLOAD_NO_SEX,
	FAIL_CASE_UPLOAD_NO_TEXT,
	FAIL_CASE_UPLOAD_NO_TITLE,
	FAIL_CASE_UPLOAD_WRONG_AGE,
	SUCCESS_CASE_UPDATE_MMS,
	SUCCESS_CASE_UPLOAD_MMS,
} from '../../constants/alerts'
import SegmentToggle from '../common/basic/SegmentToggle'
import { isNumeric } from '../../libs/stringHelper'
import { ExceptMobile } from '../common/basic/ResponsiveWrappers'
import AddButton from '../common/basic/AddButton'
import { SharingScopes } from '../../enum'
import { GET_MY_CASE_GROUPS } from '../../graphql/queries/case'
import { FILE_ACCEPT_OPTIONS } from '../../constants'

const ENTITY_TYPE_CASE_TAG = 'CaseTag'

const CaseWriter = ({
	isEditing = false,
	caseId,
	title: propTitle = '',
	charts: propCharts = [{ chart: '', date: '', id: 0, images: [] }],
	sex: propSex = '',
	tags: propTags = [],
	age: propAge = '',
	chartNum: propChartNum = '',
	groupId: propGroupId,
	sharingScope: propSharingScope = 'GROUP',
}) => {
	const { search } = useLocation()
	const groupId = parseInt(new URLSearchParams(search).get('groupId'), 10)

	let defaultGroupId
	if (propGroupId == null) {
		if (isNaN(groupId) === false) {
			defaultGroupId = groupId
		}
	} else if (isEditing === true) {
		defaultGroupId = propGroupId
	}
	const fileRef = useRef(null)
	const [changingImageIndex, setChangingImageIndex] = useState(null)
	const [deletingChartIndex, setDeletingChartIndex] = useState(null)
	const [title, setTitle] = useState(propTitle)
	const [charts, setCharts] = useState(propCharts)
	const [sharingScope, setSharingScope] = useState(propSharingScope)
	// TODO: 실제 유저 그룹으로 변경
	const [sharingGroupId, setSharingGroupId] = useState(defaultGroupId)
	const [sex, setSex] = useState(propSex)
	const [age, setAge] = useState(propAge)
	const [tags, setTags] = useState(propTags)
	const [chartNum, setChartNum] = useState(propChartNum)
	const showToastMessages = useToastMessage()
	const history = useHistory()
	const [uploadCase, { loading }] = useMutation(WRITE_SIMPLE_CASE, {
		onError: () => {
			showToastMessages(FAIL_CASE_UPLOAD_MMS)
		},
		onCompleted: () => {
			showToastMessages(SUCCESS_CASE_UPLOAD_MMS)
			history.push(ROUTE_CASES)
		},
	})

	const [updateCase, { loading: loadingUpdate }] = useMutation(UPDATE_SIMPLE_CASE, {
		onError: () => {
			showToastMessages(FAIL_CASE_UPLOAD_MMS)
		},
		onCompleted: () => {
			showToastMessages(SUCCESS_CASE_UPDATE_MMS)
			history.push(ROUTE_CASES)
		},
	})

	// TODO: 그룹공개일 경우 다른 조건 줄지 결정 필요
	// const isScopeDisabled = isEditing === true
	const isScopeDisabled = false

	const { data: { myCaseGroups = [] } = {} } = useQuery(GET_MY_CASE_GROUPS, {
		onCompleted: data => {
			if (isEditing === false) {
				if (data != null) {
					const { myCaseGroups: caseGroups } = data
					if (caseGroups != null && caseGroups.length > 0) {
						if (isNaN(groupId) === true) setSharingGroupId(caseGroups[0].id)
						else {
							const caseGroupIds = caseGroups.reduce((arr, c) => {
								return arr.concat([c.id])
							}, [])

							if (caseGroupIds.indexOf(groupId) < 0) setSharingGroupId(caseGroups[0].id)
						}
					}
				}
			}
		},
	})

	const submit = () => {
		/**
		 * 케이스 업로드하기
		 *
		 * input validation 후 mutation을 통해 업로드 진행
		 */
		if (title === '') showToastMessages(FAIL_CASE_UPLOAD_NO_TITLE)
		else if (sex === '') showToastMessages(FAIL_CASE_UPLOAD_NO_SEX)
		else if (age === '') showToastMessages(FAIL_CASE_UPLOAD_NO_AGE)
		else if (isNumeric(age) !== true) showToastMessages(FAIL_CASE_UPLOAD_WRONG_AGE)
		else if (parseInt(age, 10) > 150) showToastMessages(FAIL_CASE_UPLOAD_WRONG_AGE)
		else if (chartNum === '') showToastMessages(FAIL_CASE_UPLOAD_CHART_NUM)
		else {
			const ed = charts.filter(
				x =>
					x.date === '' ||
					/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(x.date) !== true
			)
			const ec = charts.filter(x => x.chart === '')

			if (ed.length > 0) showToastMessages(FAIL_CASE_UPLOAD_NO_DATE)
			else if (ec.length > 0) showToastMessages(FAIL_CASE_UPLOAD_NO_TEXT)
			else {
				// const text = charts.reduce((x, c) => {
				// 	return `${x}*${c.date}\n${c.chart}\n\n\n`
				// }, '')

				const uploadingImages = charts.reduce((arr, c) => {
					if (c.images)
						return [...arr, ...c.images.filter(i => i.uploading === true).map(i => i.file)]
					return arr
				}, [])

				const chartsText = JSON.stringify(charts)

				if (isEditing === false)
					uploadCase({
						variables: {
							title,
							charts: chartsText,
							age: parseInt(age, 10),
							sex,
							tags: JSON.stringify(tags),
							chartNum,
							sharingScope,
							groupId: sharingGroupId,
							uploadingImages,
						},
					})
				else {
					updateCase({
						variables: {
							caseId,
							title,
							charts: chartsText,
							age: parseInt(age, 10),
							sex,
							tags: JSON.stringify(tags),
							chartNum,
							sharingScope,
							groupId: sharingGroupId,
							uploadingImages,
						},
					})
				}
			}
		}
	}

	const onChangeImage = files => {
		// 차트에 이미지 추가하기
		const { images = [] } = charts[changingImageIndex]
		if (images.length > 5) {
			alert('이미지는 차트 당 최대 5장까지 올릴 수 있습니다.')
			return
		}
		const newImages = [...images]
		// eslint-disable-next-line no-plusplus
		for (let i = 0; i < files.length; i++) {
			newImages.push({ uploading: true, url: URL.createObjectURL(files[i]), file: files[i] })
		}

		setCharts(
			charts.map((cc, ii) => {
				if (ii === changingImageIndex)
					return {
						...cc,
						images: newImages,
					}
				return cc
			})
		)
	}

	return (
		<div style={{ textAlign: 'center' }}>
			<div style={{ margin: '0 auto', textAlign: 'left', paddingBottom: 200, maxWidth: 800 }}>
				<ExceptMobile>
					<div style={{ fontSize: 40, fontWeight: 600, marginBottom: 40 }}>
						{isEditing === false ? '새로운 케이스 쓰기' : '케이스 업데이트'}
					</div>
				</ExceptMobile>
				<div
					style={{
						background: COLOR_BACKGROUND_LIGHT_GRAY,
					}}
				>
					<div
						style={{
							padding: '10px 20px',
							color: COLOR_TEXT_DARKGRAY,
							marginBottom: 50,
							marginTop: 10,
							lineHeight: 1.6,
							background: COLOR_BACKGROUND_DARK_GRAY,
						}}
					>
						<ul style={{ listStyle: 'disc' }}>
							<li>
								재진 기록이 있을 경우, 날짜별로 진료 기록을 추가해 입력해 보세요. 하단의 &lsquo;재진
								기록 추가&rsquo; 버튼을 클릭해 보세요.
							</li>
							<li>
								추가적인 진료기록은 포스트 수정(케이스 업데이트)을 통해, 새로운 재진 기록을 추가하여
								작성하실 수 있습니다.
							</li>
						</ul>
					</div>
				</div>
				<div style={{ marginBottom: 10 }}>
					<div style={{ display: 'inline-block', marginRight: 20, marginBottom: 10 }}>
						<SegmentToggle
							width={30}
							active={sex}
							onChange={setSex}
							items={[
								{ key: 'M', value: 'M', text: '남자' },
								{ key: 'W', value: 'W', text: '여자' },
							]}
						/>
					</div>
					<Input
						label="나이"
						placeholder="초진 시 나이"
						value={age}
						onChange={(event, { value }) => setAge(value)}
					/>
					<Input
						style={{ marginLeft: 10 }}
						label="차트 번호"
						placeholder="차트 번호"
						value={chartNum}
						onChange={(event, { value }) => setChartNum(value)}
					/>
				</div>
				<div style={{ marginBottom: 20 }}>
					<Input
						fluid
						style={{ fontSize: 18 }}
						value={title}
						placeholder="케이스 제목을 입력해주세요."
						onChange={(event, { value }) => setTitle(value)}
					/>
				</div>
				<input
					type="file"
					multiple
					accept={FILE_ACCEPT_OPTIONS.image}
					hidden
					ref={fileRef}
					onChange={({ target: { validity, files } }) => {
						if (validity.valid) {
							onChangeImage(files)
						}
					}}
				/>
				<div style={{ marginBottom: 10 }}>
					{charts.map((c, i) => (
						<div
							key={`chart_${c.id}`}
							style={{
								paddingTop: 20,
							}}
						>
							<div style={{ marginBottom: 5 }}>
								{i > 0 && (
									<Button
										icon="close"
										style={{
											width: 30,
											height: 30,
											padding: '5px 0px',
											margin: 'auto',
											textAlign: 'center',
											boxShadow: 'none',
											border: 'none',
											color: COLOR_TEXT_DARKGRAY,
										}}
										circular
										basic
										onClick={() => {
											if (c.chart !== '') {
												setDeletingChartIndex(i)
											} else setCharts(charts.filter((x, ii) => ii !== i))
										}}
										className="option-button"
									/>
								)}
								{i === 0 ? <Label color="green">초진</Label> : <Label>재진</Label>}
								<Input
									value={c.date}
									style={{ marginLeft: 10, marginRight: 10, marginBottom: 5, fontSize: 12 }}
									label="날짜"
									placeholder="예: 2020-10-11"
									onChange={(event, { value }) =>
										setCharts(
											charts.map((cc, ii) => {
												if (ii === i)
													return {
														...cc,
														date: value,
													}
												return cc
											})
										)
									}
								/>
								<AddButton
									title="이미지 추가"
									onClick={() => {
										if (fileRef.current != null) {
											setChangingImageIndex(i)
											fileRef.current.click()
										}
									}}
								/>
							</div>
							<div>
								{c.images != null &&
									c.images.map((image, iImg) => (
										<ImageBox key={`image_write_prv_${image.url}`}>
											<CloseButton
												onClick={() => {
													const images = [...c.images]
													images.splice(iImg, 1)

													setCharts(
														charts.map((cc, ii) => {
															if (ii === i)
																return {
																	...cc,
																	images,
																}
															return cc
														})
													)
												}}
											>
												<Icon name="x" style={{ margin: 'auto' }} />
											</CloseButton>
											<img
												style={{
													width: 100,
													height: 100,
													objectFit: 'cover',
												}}
												src={image.url}
											/>
										</ImageBox>
									))}
							</div>
							<StyledTextArea
								fluid
								placeholder="함께 공유하고 싶은 케이스 내용을 자유롭게 기재해주세요."
								value={c.chart}
								style={{ height: 220 }}
								onChange={(event, { value }) =>
									setCharts(
										charts.map((cc, ii) => {
											if (ii === i)
												return {
													...cc,
													chart: value,
												}
											return cc
										})
									)
								}
							/>
							{/* <div style={{ marginTop: 15 }}>
								<MyIcon name="image" size={UISize.LARGE} />
							</div> */}
						</div>
					))}

					<div style={{ marginTop: 20, marginBottom: 50, display: 'flex', height: 50 }}>
						<AddButton
							style={{ flex: 1 }}
							title="재진 기록 추가"
							onClick={() => {
								setCharts(
									charts.concat([
										{
											id: charts.length,
											date: '',
											chart: '',
											images: [],
										},
									])
								)
							}}
						/>
					</div>
				</div>
				<div style={{ marginBottom: 20 }}>
					<div style={{ color: COLOR_TEXT_DARKGRAY, marginTop: 20, marginBottom: 10 }}>
						태그를 통해 환자의 병명, 증상, 처방명 등을 입력해주세요.
					</div>
					<SearchMultipleInput
						fluid
						checkedItems={tags}
						entityType={ENTITY_TYPE_CASE_TAG}
						placeholder="태그를 검색해 주세요"
						onUpdateValues={item => {
							const utags = item[0].value
							setTags(utags)
						}}
					/>
				</div>
				<div style={{ marginBottom: 50 }}>
					<div style={{ color: COLOR_TEXT_DARKGRAY, marginTop: 20, marginBottom: 10 }}>
						공개 범위를 설정해주세요.
					</div>
					<Dropdown
						style={{ width: 200 }}
						placeholder="공개 범위 설정"
						selection
						disabled={isScopeDisabled}
						value={sharingScope}
						onChange={(_, { value }) => {
							setSharingScope(value)
							if (value !== 'GROUP') setSharingGroupId(null)
						}}
						options={Object.values(SharingScopes).map(({ key, value, text, icon }) => ({
							key,
							value,
							text: (
								<>
									<Icon name={icon} style={{ padingLeft: 20 }} /> <span>{text}</span>
								</>
							),
						}))}
					/>
					{sharingScope === 'GROUP' && (
						<Dropdown
							style={{ minWidth: 200, marginLeft: 10 }}
							disabled={isScopeDisabled && sharingGroupId != null}
							placeholder="공유할 그룹 선택"
							selection
							value={sharingGroupId}
							onChange={(_, { value }) => {
								setSharingGroupId(value)
							}}
							options={myCaseGroups.map(item => ({
								key: `WRITE_SCOPE_GROUP_${item.id}`,
								value: item.id,
								text: item.title,
							}))}
						/>
					)}
				</div>
				<Button
					size="large"
					primary
					onClick={submit}
					loading={isEditing === false ? loading : loadingUpdate}
				>
					작성 완료
				</Button>

				<Modal
					open={deletingChartIndex != null}
					size="mini"
					content="작성중인 내용이 있습니다. 기록을 삭제하시겠습니까?"
					actions={[
						{
							key: 'cencel',
							content: '취소',
							onClick: () => setDeletingChartIndex(null),
						},
						{
							key: 'delete',
							content: '삭제',
							color: 'red',
							onClick: () => {
								setCharts(charts.filter((x, ii) => ii !== deletingChartIndex))
								setDeletingChartIndex(null)
							},
						},
					]}
				/>
			</div>
		</div>
	)
}

CaseWriter.propTypes = {}

export default CaseWriter

const CloseButton = styled.div`
	position: absolute;
	right: 5px;
	top: 5px;
	border-radius: 25px;
	text-align: center;
	background-color: rgba(255, 255, 255, 0.5);
	cursor: pointer;
	:hover {
		background-color: rgba(255, 255, 255, 0.8);
	}
`

const ImageBox = styled.div`
	display: inline-block;
	width: 100px;
	height: 100px;
	position: relative;
	cursor: pointer;
	box-sizing: content-box;
	border: 1px solid white;
	margin-right: 10px;
	:hover {
		border: 1px solid ${COLOR_SECONDARY_BLUE};
	}
`
