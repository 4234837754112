export default {
	UNAUTHORIZED_ERROR: {
		message: 'Unauthorized',
		code: 'UNAUTHORIZED',
		notThreat: true,
	},

	EXPIRED_ACCESS_TOKEN_ERROR: {
		message: 'Expired Access Token',
		code: 'EXPIRED_ACCESS_TOKEN',
		notThreat: true,
	},

	EXPIRED_REFRESH_TOKEN_ERROR: {
		message: 'Expired Refresh Token',
		code: 'EXPIRED_REFRESH_TOKEN',
		notThreat: true,
	},

	AUTHENTICATION_FAILED_ERROR: {
		message: 'Authentication Failed',
		code: 'AUTHENTICATION_FAILED',
		notThreat: true,
	},

	ACCOUNT_SUSPENDED_ERROR: {
		message: 'Account Suspended',
		code: 'ACCOUNT_SUSPENDED',
		notThreat: true,
	},

	USER_NOT_EXISTS_ERROR: {
		message: 'User Not Exists',
		code: 'USER_NOT_EXISTS',
		notThreat: false,
	},

	ACCESS_FORBIDDEN_ERROR: {
		message: 'Access Forbidden',
		code: 'ACCESS_FORBIDDEN',
		notThreat: true,
	},

	ENTITY_NOT_EXISTS_ERROR: {
		message: 'Entity Not Exists',
		code: 'ENTITY_NOT_EXISTS',
		notThreat: false,
	},

	DUPLICATE_ERROR: {
		message: 'Duplicate',
		code: 'DUPLICATE',
		notThreat: true,
	},

	INVALID_VALUE_ERROR: {
		message: 'Invalid Value',
		code: 'INVALID_VALUE_ERROR',
		notThreat: true,
	},

	ACCOUNT_VALIDATION_WAITING_ERROR: {
		message: 'Account Validation Waiting',
		code: 'ACCOUNT_VALIDATION_WAITING',
		notThreat: true,
	},

	ACCOUNT_VALIDATION_FAILED_ERROR: {
		message: 'Acount Validation Failed',
		code: 'ACCOUNT_VALIDATION_FAILED',
		notThreat: true,
	},

	OBJECT_LOCKED_ERROR: {
		message: 'Object Locked',
		code: 'OBJECT_LOCKED',
		notThreat: true,
	},

	TERM_AGREEMENT_REQUIRED: {
		message: 'Term Agreement Required',
		code: 'TERM_AGREEMENT_REQUIRED',
		notThreat: true,
	},

	NOT_LATEST_RECORD: {
		message: 'Not Latest Record',
		code: 'NOT_LATEST_RECORD',
		notThreat: true,
	},

	USER_ALREADY_JOINED_ERROR: {
		message: 'User Already Joined',
		code: 'USER_ALREADY_JOINED_ERROR',
		notThreat: true,
	},

	OBJECT_REFERENCED_ERROR: {
		message: 'Object Referenced',
		code: 'OBJECT_REFERENCED',
		notThreat: true,
	},

	ALREADY_JOINED_RESEARCH_ERROR: {
		message: 'Already Joined Research Error',
		code: 'ALREADY_JOINED_RESEARCH_ERROR',
		notThreat: true,
	},

	RESEARCH_LIMIT_EXCEED_ERROR: {
		message: 'Research Limit Exceed Error',
		code: 'RESEARCH_LIMIT_EXCEED_ERROR',
		notThreat: true,
	},

	RESEARCH_INVITATION_ERROR: {
		message: 'Research Invitation Error',
		code: 'RESEARCH_INVITATION_ERROR',
		notThreat: true,
	},
}
