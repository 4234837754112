import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal, Button, Checkbox, Input, Dropdown, Popup, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { withApollo, useMutation } from 'react-apollo'
import JSDL from 'js-file-download'
import moment from 'moment'

import { EXPORT_RESEARCH_DATA } from '../../../graphql/mutations/research'
import { makeRandomKey } from '../../../libs/hashHelper'
import { AUTO_GENERATE_EXPORT_ENC_KEY_LENGTH, EXPORT_FILE_TYPES } from '../../../constants'

import './styles.css'
import withFetch from '../../../higherComponents/withFetch'
import ExportDataPasswordModal from './ExportDataPasswordModal'
import { UISize } from '../../../enum'
import DescriptionText from '../../common/basic/DescriptionText'
import MyIcon from '../../common/basic/MyIcon'
import { ROUTE_PROJECT_DETAIL_SETTINGS_SECURITY } from '../../../constants/routes'
import { replaceParams } from '../../../libs/routeHelper'
import ProgressLoader from '../../common/basic/ProgressLoader'
import { COLOR_TEXT_DARKGRAY } from '../../../constants/styles'
// import FloatingView from '../../common/basic/FloatingView'

const FILE_TYPES = Object.values(EXPORT_FILE_TYPES)

const setFileName = ({ researchTitle, fileType, isEncrypted }) => {
	const d = moment().format('YYYY-MM-DD')
	return `${d}_${researchTitle}${isEncrypted === true ? '_암호화' : ''}.${fileType}`
}

/**
 * 데이터 파일 추출 modal
 */
const ExportDataModal = props => {
	const [shouldEncrypt, setShouldEncrypt] = useState(false)
	const [shouldMask, setShouldMask] = useState(true)
	const [isLoading, setLoading] = useState(false)
	const [isFloatVisible, setFloatVisible] = useState(false)
	const [hasError, setHasError] = useState(false)
	const [shouldCodeColumns, setShouldCodeColumns] = useState(true) //영문변수로 표시

	const [encryptionKey, setEncryptionKey] = useState(
		makeRandomKey(AUTO_GENERATE_EXPORT_ENC_KEY_LENGTH)
	) //파일 암호화 키

	const [fileType, setFileType] = useState(EXPORT_FILE_TYPES.CSV.value)
	const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false)
	const { isOpen, onClose, researchId, researchTitle, usePassword, onFetch } = props

	const typeInfo = EXPORT_FILE_TYPES[fileType]

	const [requestExportData] = useMutation(EXPORT_RESEARCH_DATA)

	/**
	 * 파일 다운로드 처리
	 * @param {String} researchPassword 프로젝트 보안키
	 */
	const downloadData = useCallback(
		async researchPassword => {
			try {
				setLoading(true)
				setFloatVisible(true)

				onClose()

				const {
					data: { exportResearchData },
				} = await requestExportData({
					variables: {
						researchId: parseInt(researchId),
						encryptionKey,
						shouldEncrypt,
						shouldMask,
						fileType: typeInfo.value,
						researchPassword,
						useTypeName: shouldCodeColumns,
					},
				})

				setTimeout(async () => {
					const response = await onFetch({
						url: exportResearchData,
						responseType: 'blob',
					})

					if (response) {
						JSDL(
							await response.blob(),
							setFileName({
								researchTitle,
								isEncrypted: shouldEncrypt,
								fileType: typeInfo.export,
							})
						)

						setLoading(false)
						setTimeout(() => {
							setFloatVisible(false)
						}, 2000)
					} else {
						setHasError(true)
					}
				}, 1000)
			} catch (e) {
				setHasError(true)
			}
		},
		[
			encryptionKey,
			onClose,
			onFetch,
			requestExportData,
			researchId,
			researchTitle,
			shouldCodeColumns,
			shouldEncrypt,
			shouldMask,
			typeInfo.export,
			typeInfo.value,
		]
	)

	return (
		// <Mutation mutation={}>
		// 	{mutate => {
		// return (
		<>
			<Modal open={isOpen} size="small" onClose={onClose}>
				<Modal.Header>데이터 다운로드</Modal.Header>
				<Modal.Content>
					{usePassword !== true && (
						<div>
							<Warning>
								<MyIcon name="info" size={UISize.SMALL} />
								<WarningText>
									프로젝트 보안키가 설정되지 않았습니다. 열람권한이 있는 모든 사용자가 데이터를
									다운받을 수 있습니다. 더 안전하게 데이터를 관리하시려면{' '}
									<Link
										to={replaceParams(ROUTE_PROJECT_DETAIL_SETTINGS_SECURITY, {
											id: researchId,
										})}
									>
										보안키를 설정
									</Link>
									해주세요.
								</WarningText>
							</Warning>
						</div>
					)}
					<div style={styles.container}>
						{typeInfo.noEncryption !== true && (
							<InputRow>
								<InputLabel>파일 암호화</InputLabel>
								<Checkbox
									toggle
									style={styles.checkbox}
									onClick={() => {
										if (shouldMask !== false) {
											setShouldEncrypt(!shouldEncrypt)
										}
									}}
									checked={shouldEncrypt}
									disabled={shouldMask === false}
								/>

								<EncryptionWrap visible={shouldEncrypt}>
									<EncryptionInput
										placeholder="파일 암호키"
										size="tiny"
										onChange={(e, { value }) => {
											setEncryptionKey(value)
										}}
										value={encryptionKey}
									/>
									<CreateButton
										color="blue"
										size="tiny"
										onClick={() => {
											const x = makeRandomKey(AUTO_GENERATE_EXPORT_ENC_KEY_LENGTH)
											setEncryptionKey(x)
										}}
									>
										자동생성
									</CreateButton>
									{shouldEncrypt === true && (
										<DescriptionText>
											위에 입력한 암호키를 사용하여 복호화를 거친 후 열람이 가능해 집니다.
											<Popup
												content="AES 256 방식을 통해 암호화되어, AES Crypt 등의 복호화 소프트웨어를 사용해 복호화할 수 있습니다."
												trigger={<Icon name="info circle" />}
											/>
										</DescriptionText>
									)}
								</EncryptionWrap>
							</InputRow>
						)}
						<InputRow>
							<InputLabel>개인정보 마스킹</InputLabel>

							<Checkbox
								toggle
								style={styles.checkbox}
								onClick={() => {
									if (shouldMask === false) {
										setShouldMask(true)
									} else {
										setShouldMask(false)
										setShouldEncrypt(true)
									}
								}}
								checked={shouldMask}
							/>
							<FieldDesc>
								{shouldMask === true
									? '환자 식별정보를 제거합니다. 이름은 마스킹되고(예: 김**) 생년월일 등 기타 식별정보는 제거됩니다.'
									: '환자 실명, 생년월일 등의 식별 정보를 포함합니다. 암호화 한 데이터만 다운로드 할 수 있습니다.'}
							</FieldDesc>
						</InputRow>
						<InputRow>
							<InputLabel>칼럼명 코딩</InputLabel>

							<Checkbox
								toggle
								style={styles.checkbox}
								checked={shouldCodeColumns}
								onClick={() => {
									setShouldCodeColumns(!shouldCodeColumns)
								}}
							/>
							<FieldDesc>
								{shouldCodeColumns === true
									? '영문 및 숫자로 이루어진 변수명으로 코딩된 칼럼명을 사용합니다. 예: headache_pain_scale'
									: '입력시 보여지는 항목명을 그대로 칼럼명으로 사용합니다. 예: 두통 통증 VAS 스케일'}
							</FieldDesc>
						</InputRow>
						<InputRow>
							<InputLabel>파일형</InputLabel>
							<div style={styles.checkbox}>
								<Dropdown
									style={styles.select}
									value={fileType}
									options={FILE_TYPES}
									onChange={(e, { value }) => {
										setFileType(value)
									}}
								/>
							</div>
						</InputRow>
					</div>
					<div style={styles.buttonWrap}>
						<Button
							primary
							size="large"
							onClick={async () => {
								if (usePassword === true) {
									setIsPasswordModalOpen(true)
								} else {
									downloadData()
								}
							}}
						>
							다운로드
						</Button>
					</div>
				</Modal.Content>
			</Modal>
			<ExportDataPasswordModal
				isOpen={isPasswordModalOpen}
				onClose={() => setIsPasswordModalOpen(false)}
				onSubmit={researchPassword => {
					downloadData(researchPassword)
					setIsPasswordModalOpen(false)
					onClose()
				}}
			/>
			<Modal
				open={isFloatVisible}
				onClose={() => setFloatVisible(false)}
				closeIcon={hasError}
				closeOnDimmerClick={false}
				size="mini"
			>
				<Modal.Content>
					<LoaderContainer>
						<ResearchTitle>{researchTitle}</ResearchTitle>
						<ProgressLoader
							isError={hasError}
							isSuccess={hasError === false && isLoading === false}
							isLoading={isLoading}
							color={isLoading}
						>
							{isLoading === true ? '데이터 파일 생성중..' : hasError === true ? '에러' : '완료'}
						</ProgressLoader>
						{hasError && <DescriptionText>파일 생성중 에러가 발생했습니다.</DescriptionText>}
					</LoaderContainer>
				</Modal.Content>
			</Modal>
		</>
	)
}

ExportDataModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	researchId: PropTypes.number.isRequired,
	researchTitle: PropTypes.string,
	usePassword: PropTypes.bool,
	onFetch: PropTypes.func.isRequired,
}

export default withFetch(ExportDataModal)

const InputRow = styled.div`
	display: flex;
	margin-bottom: 25px;
	align-items: center;
`

const InputLabel = styled.div`
	width: 100px;
	font-size: 13px;
`

const FieldDesc = styled.div`
	font-size: 12px;
	color: ${COLOR_TEXT_DARKGRAY};
	flex: 1;
`
const Warning = styled.div`
	display: flex;
	align-items: flex-start;
	margin-bottom: 20px;
`

const WarningText = styled(DescriptionText)`
	flex: 1;
	padding-left: 10px;
`
const LoaderContainer = styled.div`
	width: 200px;
	margin: auto;
`

const ResearchTitle = styled.div`
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-bottom: 5px;
`

const EncryptionWrap = styled.div`
	visibility: ${props => (props.visible === true ? 'visible' : 'hidden')};
	opacity: ${props => (props.visible === true ? '1' : '0')};
	transition: opacity 0.3s linear, visibility 0.3s;
	flex: 1;
`

const EncryptionInput = styled(Input)`
	input {
		padding: 5px !important;
	}
`

const CreateButton = styled(Button)`
	margin-left: 10px !important;
`

const styles = {
	buttonWrap: {
		textAlign: 'center',
	},
	checkbox: {
		marginBottom: 0,
		paddingBottom: 0,
		width: 100,
	},
	container: {
		marginTop: 20,
		marginBottom: 20,
	},
	encButton: {
		marginLeft: 12,
		verticalAlign: 'middle',
	},
	select: {
		width: 80,
	},
}
