import gql from 'graphql-tag'

/**
 * 양식 수정 내역 모달의 페이지 저장
 */
export const SET_TEMPLATE_CHANGE_MODAL_PAGE = gql`
	mutation setTemplateChangeModalPage($templateId: Int!, $page: Int!) {
		setTemplateChangeModalPage(templateId: $templateId, page: $page) @client
	}
`

/**
 * 기록 수정 내역 모달의 페이지 저장
 */
export const SET_RECORD_CHANGE_MODAL_PAGE = gql`
	mutation setRecordChangeModalPage($recordId: Int!, $page: Int!) {
		setRecordChangeModalPage(recordId: $recordId, page: $page) @client
	}
`
