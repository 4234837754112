import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import compose from 'lodash.flowright'
import { graphql } from 'react-apollo'
import { SUBMIT_CONTACT } from '../graphql/mutations/app'
import ContactView from '../components/other/ContactView'
import { validateFields } from '../libs/validationHelper'
import { CONTACT_FIELDS } from '../fields'

const OPTIONS = [
	{
		key: 'multicenter',
		value: 'multicenter',
		text: '다기관 임상 연구',
	},
	{
		key: 'chart-review',
		value: 'chart-review',
		text: '후향적 연구',
	},
	{
		key: 'analyze',
		value: 'analyze',
		text: '데이터 통계 / 분석',
	},
	{
		key: 'data-cleanse',
		value: 'data-cleanse',
		text: '차트 데이터 정리',
	},
	{
		key: 'contract',
		value: 'contract',
		text: '이용 계약',
	},
	{
		key: 'service',
		value: 'service',
		text: '서비스 관련',
	},
	{
		key: 'etc',
		value: 'etc',
		text: '기타',
	},
]

/**
 * 문의 페이지
 */
class Contact extends PureComponent {
	static propTypes = {
		submitContact: PropTypes.func,
	}

	constructor(props) {
		super(props)

		this.state = {
			name: '',
			institution: '',
			contact: '',
			email: '',
			subject: '',
			text: '',
			type: OPTIONS[0].value,
			hasAgreed: false,
			errors: {},
		}
	}

	handleSetAgreed = hasAgreed => this.setState({ hasAgreed })

	handleSubmit = async () => {
		const { submitContact } = this.props
		const { errors, hasAgreed, ...fields } = this.state
		try {
			const errors = validateFields(fields, CONTACT_FIELDS)

			if (errors != null) {
				this.setState({
					errors,
				})

				return
			}

			if (hasAgreed !== true) {
				alert('개인정보 제공에 동의해 주세요.')
				return
			}

			await submitContact({
				variables: {
					contactInput: {
						...fields,
					},
				},
			})

			this.setState({
				name: '',
				institution: '',
				contact: '',
				email: '',
				subject: '',
				text: '',
				type: null,
				hasAgreed: false,
				errors: {},
			})

			alert(
				'문의가 성공적으로 전송되었습니다. 최대한 빠른 시일 내에 기재하신 이메일 또는 연락처로 답변 드리겠습니다.'
			)
		} catch (e) {
			alert('문의가 전송되지 않았습니다. 예상치 못한 오류가 발생했습니다.')
		}
	}

	handleChange = (e, { name, value }) => {
		this.setState({
			[name]: value,
		})
	}

	render() {
		return (
			<ContactView
				{...this.state}
				onSubmit={this.handleSubmit}
				onChange={this.handleChange}
				setAgreed={this.handleSetAgreed}
				options={OPTIONS}
			/>
		)
	}
}

export default compose(
	graphql(SUBMIT_CONTACT, {
		name: 'submitContact',
	})
)(Contact)
