import React from 'react'
import styled from 'styled-components'
import SegmentSlim from '../common/basic/SegmentSlim'
import { COLOR_BORDER_GRAY } from '../../constants/styles'

const StatItem = props => {
	const { title, subInfo, content } = props
	return (
		<SegmentSlim>
			<Title>
				{title}
				<SubInfo>{subInfo}</SubInfo>
			</Title>
			<div>{content}</div>
		</SegmentSlim>
	)
}

export default StatItem

const Title = styled.div`
	height: 56px;
	padding: 16px 20px;
	border-bottom: 1px solid ${COLOR_BORDER_GRAY};
	font-size: 16px;
	line-height: 24px;
`

const SubInfo = styled.div`
	display: inline-block;
	float: right;
`
