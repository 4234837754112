import gql from 'graphql-tag'
import {
	RESEARCH_FRAGMENT,
	RESEARCHER_FRAGMENT,
	RESEARCH_BASIC_INFO_FRAGMENT,
	RESEARCH_BASIC_FRAGMENT,
} from '../fragments/research'
import { NOTIFICATION_FRAGMENT } from '../fragments/notification'

// 연구 정보 수정
export const UPDATE_RESEARCH = gql`
	mutation updateResearch(
		$researchId: Int!
		$title: String
		$description: String
		$status: String
		$overview: String
	) {
		updateResearch(
			researchId: $researchId
			title: $title
			description: $description
			status: $status
			overview: $overview
		) {
			...ResearchParts
		}
	}
	${RESEARCH_FRAGMENT}
`

// 연구 정보 수정
export const UPDATE_RESEARCH_OVERVIEW = gql`
	mutation updateResearch($researchId: Int!, $overview: String) {
		updateResearch(researchId: $researchId, overview: $overview) {
			id
			overview
		}
	}
`

// 연구원 정보 수정
export const UPDATE_RESEARCHER = gql`
	mutation updateResearcherInfo(
		$researchId: Int!
		$userId: Int!
		$researcherInfo: ResearcherInfoInput!
	) {
		updateResearcherInfo(
			researchId: $researchId
			userId: $userId
			researcherInfo: $researcherInfo
		) {
			...ResearcherParts
		}
	}
	${RESEARCHER_FRAGMENT}
`

// 사용자 초대하기
export const INVITE_RESEARCH_USER = gql`
	mutation inviteResearchUser($researchId: Int!, $userId: Int, $role: String!, $email: String) {
		inviteResearchUser(researchId: $researchId, userId: $userId, role: $role, email: $email) {
			id
		}
	}
`

// 연구원 제거
export const REMOVE_RESEARCHER = gql`
	mutation removeResearcher($researchId: Int!, $userId: Int!) {
		removeResearcher(researchId: $researchId, userId: $userId) {
			...ResearchParts
		}
	}
	${RESEARCH_FRAGMENT}
`

// issueInput
// text - string!
// title - string!
// issueTypeId - Int!
// researchId - int!
// ??

/**
 * 프로젝트 이슈 작성
 */
export const WRITE_ISSUE = gql`
	mutation writeIssue($issue: IssueInput!) {
		writeIssue(issue: $issue) {
			# title text typeId(int)
			createdAt
			title
			text
			isClosed
			comments {
				totalCount
			}
			type {
				name
				category
			}
			writer {
				name
				username
				profileImage
			}
		}
	}
`

/**
 * 연구 데이터 추출하기
 */
export const EXPORT_RESEARCH_DATA = gql`
	mutation exportResearchData(
		$researchId: Int!
		$shouldEncrypt: Boolean
		$shouldMask: Boolean
		$encryptionKey: String
		$fileType: FileType
		$researchPassword: String
		$useTypeName: Boolean
	) {
		exportResearchData(
			researchId: $researchId
			shouldEncrypt: $shouldEncrypt
			shouldMaskPrivacy: $shouldMask
			encryptionKey: $encryptionKey
			fileType: $fileType
			researchPassword: $researchPassword
			useTypeName: $useTypeName
		)
	}
`

/**
 * 연구 보안 비밀번호 업데이트
 */
export const UPDATE_RESEARCH_PASSWORD = gql`
	mutation updateResearchPassword($researchId: Int!, $oldPassword: String, $newPassword: String) {
		updateResearchPassword(
			researchId: $researchId
			oldPassword: $oldPassword
			newPassword: $newPassword
		) {
			id
			usePassword
		}
	}
`

/**
 * 연구 초대 요청 수락하기
 */
export const ACCEPT_RESEARCH_INVITATION = gql`
	mutation acceptResearchInvitation($invitationId: Int!, $notifId: Int!, $readStatus: ReadStatus!) {
		acceptResearchInvitation(researchInvitationId: $invitationId) {
			id
			research {
				id
			}
			status
		}

		markNotificationReadStatus(id: $notifId, readStatus: $readStatus) {
			...NotificationParts
		}
	}
	${NOTIFICATION_FRAGMENT}
`

/**
 * 연구 초대 요청 거절하기
 */
export const DECLINE_RESEARCH_INVITATION = gql`
	mutation declineResearchInvitation(
		$invitationId: Int!
		$notifId: Int!
		$readStatus: ReadStatus!
	) {
		declineResearchInvitation(researchInvitationId: $invitationId) {
			id
			research {
				id
			}
			status
		}

		markNotificationReadStatus(id: $notifId, readStatus: $readStatus) {
			...NotificationParts
		}
	}
	${NOTIFICATION_FRAGMENT}
`

/**
 * 연구 생성하기
 */
export const CREATE_RESEARCH = gql`
	mutation createResearch(
		$title: String!
		$startDate: String
		$endDate: String
		$description: String
		$overview: String
		$scope: ResearchScope
		$status: ResearchStatus
		$projectId: String
		$hasScreening: Boolean
		$type: ResearchType
		$requiresAgreement: Boolean
		$agreementCheckType: AgreementCheckType
		$templateType: TemplateSelectType
		$estSubjects: Int
		$isOfficial: Boolean
		$patientBasicInfo: [PatientBasicInfo]
		$hideName: Boolean
		$hashPatient: Boolean
		$hasPartialTemplate: Boolean
		$usePassword: Boolean
		$isTraditional: Boolean
		$cohortType: ResearchCohortType
		$caseType: ResearchCaseType
		$samplingPlan: ResearchSamplingPlan
		$purpose: ResearchPurpose
		$institutionInvolve: ResearchInstitutionInvolveType
	) {
		createResearch(
			researchInput: {
				title: $title
				startDate: $startDate
				endDate: $endDate
				description: $description
				overview: $overview
				scope: $scope
				status: $status
				projectId: $projectId
				hasScreening: $hasScreening
				type: $type
				requiresAgreement: $requiresAgreement
				agreementCheckType: $agreementCheckType
				templateType: $templateType
				estSubjects: $estSubjects
				isOfficial: $isOfficial
				patientBasicInfo: $patientBasicInfo
				hideName: $hideName
				hashPatient: $hashPatient
				hasPartialTemplate: $hasPartialTemplate
				usePassword: $usePassword
				isTraditional: $isTraditional
				cohortType: $cohortType
				caseType: $caseType
				samplingPlan: $samplingPlan
				purpose: $purpose
				institutionInvolve: $institutionInvolve
			}
		) {
			...ResearchBasicParts
		}
	}
	${RESEARCH_BASIC_FRAGMENT}
`

/**
 * 연구 등록 문의 전송하기
 */
export const SUBMIT_RESEARCH_APPLICATION = gql`
	mutation submitResearchApplication($researchApplication: ResearchApplicationInput!) {
		submitResearchApplication(researchApplication: $researchApplication) {
			id
		}
	}
`

/**
 * 연구 초대 취소하기
 */
export const CANCEL_RESEARCH_INVITATION = gql`
	mutation cancelResearchInvitation($id: Int!) {
		cancelResearchInvitation(researchInvitationId: $id) {
			id
		}
	}
`

export const ADD_RESEARCH_CONDITION = gql`
	mutation addResearchCondition($condition: ConditionEcrfInput!, $researchId: Int) {
		addResearchCondition(condition: $condition, researchId: $researchId) {
			id
		}
	}
`

export const UPDATE_RESEARCH_CONDITION = gql`
	mutation updateResearchCondition($condition: ConditionEcrfInput!, $researchId: Int) {
		updateResearchCondition(condition: $condition, researchId: $researchId) {
			id
		}
	}
`

export const DELETE_RESEARCH_CONDITION = gql`
	mutation deleteResearchCondition($condition: ConditionEcrfInput!, $researchId: Int) {
		deleteResearchCondition(condition: $condition, researchId: $researchId)
	}
`
