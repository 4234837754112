import React, { Fragment } from 'react'

export const isEmptyString = str => {
	if (str === null || str === '' || str === undefined) return true
	return false
}

export const isTooMuchString = str => {
	// 1000글자가 넘어가면 에러
	if (str.length >= 1000) return true
	return false
}
export const makeRandomId = length => {
	let result = ''
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
	const charactersLength = characters.length

	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength))
	}

	return result
}

/**
 * 조사를 붙여 반환해줌
 */
export const appendJosa = (str, option) => {
	// 마지막 글자
	const lastChar = str.charCodeAt(str.length - 1)

	// 마지막 글자가 받침을 가지는지
	const hasFinalConsonant = (lastChar - 0xac00) % 28 > 0

	switch (option) {
		case '을/를':
			return str + (hasFinalConsonant ? '을' : '를')
		case '은/는':
			return str + (hasFinalConsonant ? '은' : '는')
		case '이/가':
			return str + (hasFinalConsonant ? '이' : '가')
		default:
			return str
	}
}

export const newLineBreak = str => {
	if (typeof str === 'string')
		return str.split('\n').map((line, idx) => (
			// eslint-disable-next-line react/no-array-index-key
			<Fragment key={`${line}-${idx}`}>
				{line}
				<br />
			</Fragment>
		))
	return str
}

export const isNumeric = str => {
	if (typeof str !== 'string') return false // we only process strings!
	return (
		!isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
	) // ...and ensure strings of whitespace fail
}
