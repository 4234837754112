import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-apollo'
import ChartDragLayer from '../components/charting/ChartDragLayer'
import { ChartingSection } from '../components/charting/ChartingSection'
import { GET_RECORD_TEMPLATE } from '../graphql/queries/record'
import { assembleMedInfos } from '../libs/medInfoHelper'
import ChartContent from '../components/charting/ChartContent'
import Page404 from './Page404'

const Test = props => {
	// const { data } = useQuery(GET_RECORD_TEMPLATE, {
	// 	variables: {
	// 		templateId: 17,
	// 	},
	// })

	// let { recordTemplate: { medInfos = [] } = {} } = data || {}

	// const medInfosAs = useMemo(() => {
	// 	return assembleMedInfos(medInfos)
	// }, [medInfos])
	// let secFo = medInfosAs.length > 0 ? medInfosAs[1] : null
	// let secFo2 = medInfosAs.length > 0 ? medInfosAs[2] : null
	// let secFo3 = medInfosAs.length > 0 ? medInfosAs[3] : null
	return (
		<Page404 />
		// <div>
		// 	<div>
		// 		{secFo && (
		// 			<ChartingSection
		// 				medInfos={secFo.children}
		// 				name={secFo.medInfoType.name}
		// 				templateMode
		// 				medInfoId={secFo.id}
		// 			/>
		// 		)}{' '}
		// 		{secFo && (
		// 			<ChartingSection
		// 				medInfos={secFo2.children}
		// 				name={secFo2.medInfoType.name}
		// 				templateMode
		// 				medInfoId={secFo2.id}
		// 			/>
		// 		)}
		// 		{/* {medInfosAs.length > 0 ? (
		// 			[medInfosAs[6]].map(secFo4 => (
		// 				<ChartingSection
		// 					key={secFo4.id}
		// 					medInfos={secFo4.children}
		// 					name={secFo4.medInfoType.name}
		// 					templateMode
		// 					medInfoId={secFo4.id}
		// 				/>
		// 			))
		// 		) : (
		// 			<div />
		// 		)} */}
		// 		{/* {secFo3 && (
		// 			<ChartingSection
		// 				medInfos={secFo3.children}
		// 				name={secFo3.medInfoType.name}
		// 				templateMode
		// 				medInfoId={secFo3.id}
		// 			/>
		// 		)} */}
		// 		{/* <ChartContent templateMode medInfos={medInfosAs} /> */}
		// 	</div>
		// 	<ChartDragLayer />
		// </div>
	)
}

Test.propTypes = {}

export default Test
