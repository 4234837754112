import { useQuery } from 'react-apollo'
import { GET_USER_ACTIVE_RESEARCHES } from '../graphql/queries/research'

/**
 * 유저 현재 연구 목록 불러오는 Hooks
 */
const useUserActiveResearches = () => {
	const {
		data: { userResearches: { activeResearches: researches = [] } = {} } = {},
		loading,
		error,
		refetch,
	} = useQuery(GET_USER_ACTIVE_RESEARCHES)

	return { loading, researches, error, refetch }
}

export default useUserActiveResearches
