import React from 'react'
import { Icon, Header } from 'semantic-ui-react'
import { matchErrorCode } from '../../../libs/errorHelper'
import errorTypes from '../../../errorTypes'

const MiniErrorView = ({ error, height }) => {
	let text = '데이터를 불러오지 못했습니다'
	if (error) {
		if (matchErrorCode(error, errorTypes.ENTITY_NOT_EXISTS_ERROR)) {
			text = '존재하지 않는 항목입니다.'
		}
	}
	return (
		<div
			style={{
				height: height || 70,
				margin: 'auto',
				marginTop: 50,
				textAlign: 'center',
				maxWidth: 700,
			}}
		>
			<Header as="h4">
				<Icon name="warning sign" />
				{text}
			</Header>
		</div>
	)
}

export default MiniErrorView
