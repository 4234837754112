import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'semantic-ui-react'
import useTextInput from './useTextInput'
import { ReadOnlyValue, ReadOnlyValueNull } from './Styled'

/**
 * Data Field Text Input
 *
 * 기본 텍스트 인풋
 */
const DataFieldTextInputHover = ({ valueKey = 'value', value, onUpdate, ...options }) => {
	const { disabled, readOnly } = options
	const [stateValue, onChange, onBlur] = useTextInput(onUpdate, valueKey, value)

	if (readOnly === true) {
		if (value == null && disabled !== true)
			return <ReadOnlyValueNull>입력값 없음</ReadOnlyValueNull>

		return <ReadOnlyValue disabled={disabled}>{value} </ReadOnlyValue>
	}

	return (
		<Input
			className="text-only-edit"
			{...options}
			fluid
			onChange={onChange}
			onBlur={onBlur}
			value={stateValue}
		/>
	)
}

DataFieldTextInputHover.propTypes = {
	valueKey: PropTypes.string,
	value: PropTypes.string,
	onUpdate: PropTypes.func,
}

export default DataFieldTextInputHover
