import React from 'react'
import StatusDot from './StatusDot'
import { FONT_SIZE, BASIC_COLORS } from '../../constants/styles'

const StatusDotLabel = ({ style, color, textColor, text, size = 'mini' }) => {
	return (
		<div style={style}>
			<StatusDot color={color} size={size} />
			<span
				style={{
					verticalAlign: 'middle',
					marginLeft: 10,
					color: BASIC_COLORS[textColor || color],
					fontSize: FONT_SIZE[size],
				}}
			>
				{text}
			</span>
		</div>
	)
}

export default StatusDotLabel
