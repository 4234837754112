import React, { useMemo } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

const DataFieldNoEditString = ({ value }) => {
	const text = useMemo(() => {
		const numbered = parseFloat(value)
		if (_.isNaN(numbered) === false) {
			return numbered.toFixed(3)
		} else {
			return value
		}
	}, [value])
	return <div>{text}</div>
}

DataFieldNoEditString.propTypes = {
	value: PropTypes.string,
}

export default DataFieldNoEditString
